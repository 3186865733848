import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import { onMounted, ref, watch } from "vue";


export default {
  name: "DesignAndConstruction",
  props: ["id"],
  setup(props) {
    const milestones = ref([]);
    let stateProjects = ref([]);
    let fedProjects = ref([]);
    const isLoading = ref(true);

    watch(
      () => [props.id],
      () => {
        if (props.id) {
          id.value = props.id;
        }
      },
      { deep: true }
    );

    onMounted(async () => {
      await designAccessCheck();
      if (isUserDesignAccess.value.read){
        id.value = props.id;
        await fetchData();
      }
    
    });

    const isUserDesignAccess = ref({
      read: false,
    });

    const userRoleBasedAccessService = userRoleBasedAccessStore();

    const designAccessCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.PROJECTDESIGNCONSTRUCTION_READ_ACCESS
      );
      isUserDesignAccess.value.read = readResponse || false;
      isLoading.value = false;
    };

    async function fetchData() {
      axios
        .get(CONFIG_ENDPOINT.DESIGN_AND_CONSTRUCTION + id.value)
        .then((response) => {
          milestones.value = response.data;
          stateProjects.value = response.data.stateprojectnumber;
          fedProjects.value = response.data.fedprojectnumber;

          // Design

            design.value[0].pe = response.data.peFhwa4232approved;

            design.value[1].fd = response.data.fdFhwa4232approved;

          // ROW & Utilities

          rowAndUtilities.value[0].row = response.data.rowFhwa4232approved;
          rowAndUtilities.value[1].con = response.data.finalutilityclearance;
          rowAndUtilities.value[2].con = response.data.finalrowclearance;

          // Let Date
  
            letDate.value[0].con = response.data.conFhwa4232approved;
          letDate.value[1].con = response.data.awardcontract;
          letDate.value[2].con = response.data.pse;

          // Construction
          construction.value[0].con = response.data.letestimated;
          construction.value[1].con = response.data.letactual;
          construction.value[2].con = response.data.noticetoproceedestimated;
          construction.value[3].con = response.data.noticetoproceedactual;
          construction.value[4].con = response.data.opentotrafficestimated;
          construction.value[5].con = response.data.opentotrafficactual;
          construction.value[6].con = response.data.workcompleteestimated;
          construction.value[7].con = response.data.workcompleteactual;
        })
        .catch((err) => {
          console.log(err);
          milestones.value = [];
          stateProjects.value = [];
          fedProjects.value = [];
        });
    }

    const design = ref([
      {
        milestone: "PE 4232 Approved by FHWA",
        pe: "",
        fd: "",
        row: "",
        con: "",
      },
      {
        milestone: "Final Design 4232 Approved by FHWA",
        pe: "",
        fd: "",
        row: "",
        con: "",
      },
    ]);
    const rowAndUtilities = ref([
      {
        milestone: "Right of Way 4232 Approved by FHWA",
        pe: "",
        fd: "",
        row: "",
        con: "",
      },
      {
        milestone: "Final Utility Clearance",
        pe: "",
        fd: "",
        row: "",
        con: "",
      },
      {
        milestone: "Final ROW Clearance",
        pe: "",
        fd: "",
        row: "",
        con: "",
      },
    ]);
    const letDate = ref([
      {
        milestone: "Construction 4232 Approved by FHWA",
        pe: "",
        fd: "",
        row: "",
        con: "",
      },
      {
        milestone: "Award Contract",
        pe: "",
        fd: "",
        row: "",
        con: "",
      },
      {
        milestone: "PS&E Date",
        pe: "",
        fd: "",
        row: "",
        con: "",
      },
    ]);
    const construction = ref([
      {
        milestone: "Estimated Let Date",
        pe: "",
        fd: "",
        row: "",
        con: "",
      },
      {
        milestone: "Actual Let Date",
        pe: "",
        fd: "",
        row: "",
        con: "",
      },
      {
        milestone: "Estimated Notice to Proceed",
        pe: "",
        fd: "",
        row: "",
        con: "",
      },
      {
        milestone: "Actual Notice to Proceed",
        pe: "",
        fd: "",
        row: "",
        con: "",
      },
      {
        milestone: "Estimated Open to Traffic",
        pe: "",
        fd: "",
        row: "",
        con: "",
      },
      {
        milestone: "Actual Open to Traffic",
        pe: "",
        fd: "",
        row: "",
        con: "",
      },
      {
        milestone: "Estimated Physical Work Complete",
        pe: "",
        fd: "",
        row: "",
        con: "",
      },
      {
        milestone: "Actual Physical Work Complete",
        pe: "",
        fd: "",
        row: "",
        con: "",
      },
    ]);

    let id = ref();
    watch(
      () => [props.id],
      () => {
        if (props.id) {
          id.value = props.id;
          retrievePA1();
        }
      },
      { deep: true }
    );
    onMounted(() => {
      id.value = props.id;
    });
    return {
      design,
      rowAndUtilities,
      letDate,
      construction,
      id,
      milestones,
      stateProjects,
      fedProjects,
    };
  },
};
