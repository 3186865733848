import useDateField from "@/composables/dateField.js";import FeatureTable from "@/modules/shared/components/FeatureTable.vue";
import { useDocumentStore } from "@/stores/document";
import { onMounted, ref } from "vue";
import { CONFIG_ENDPOINT } from "../../../constants/Endpoints";

export default {
  name: "View Dcoument",
  components: { FeatureTable },
  emits: ["uploadFilesCall"],
  props: {
    saveClicked: { type: Boolean, default: null },
    mpmsNumber: String,
    docType: String,
    railRoadID: String,
  },
  setup(props) {
    const documentStore = useDocumentStore();

    const inventorylist = ref([]);
    const loading = ref(false);
    const mpmsNo = ref([]);
    const county = ref([]);
    const srSec = ref([]);
    const headers = ref([
      {
        title: "File Name",
        icon: "fileName",
        key: "fileName",
        sortable: false,
        columnType: "icon",
        justifyContent: "start",
        isLink: true,
        isDownload: true,
        width: "20%",
      },
      { title: "Version", key: "version", sortable: false, width: "5%" },
      {
        title: "Document Type",
        key: "documentTypeId",
        sortable: false,
        width: "18%",
      },
      {
        title: "Date Uploaded",
        key: "documentUploadDate",
        sortType: "date",
        width: "5%",
      },
      {
        title: "File Size (MB)",
        key: "fileSize",
        sortable: false,
        width: "5%",
      },
      { title: "Railroad", key: "railRoadName", sortable: false, width: "10%" },
      { title: "DOT#", key: "dotNumber", sortable: false, width: "7%" },
      { title: "Notes", key: "notes", sortable: false, width: "20%" },
    ]);
    const paginationData = ref({
      page: 1,
      items: 15,
      total: 0,
    });
    const sortData = ref({
      key: "documentUploadDate",
      order: "asc",
    });
    const textFilter = ref([]);
    const multipleFilter = ref([]);
    const lookupData = ref(null);
    const { getFormattedDateStringNoTime } = useDateField();

    const getDate = (date) => {
      return getFormattedDateStringNoTime(date);
    };
    onMounted(async () => {
      await fetchData();
    });
    async function fetchData() {
      let response = await documentStore.getExternalDocumentLists(
        props.mpmsNumber,
        props.docType,
        props.railRoadID,
        sortData.value
      );
      if (response) {
        let data = response.data || [];

        data?.forEach((item) => {
          item.documentUploadDate = getDate(item.documentUploadDate);
          item.fileName =
            "<i class='fa fa-download' aria-hidden='true' style='size: 18px; padding-right: 10px; color:#003e7e;'></i><span>" +
            item.fileName +
            "</span>";
          mpmsNo.value = item.mpmsNumber;
          county.value = item.county;
          srSec.value = item.srSec;
        });
        inventorylist.value = data;
      } else {
        inventorylist.value = [];
        paginationData.value = {
          page: 1,
          items: paginationData.value.items,
          total: 0,
        };
      }
    }
    const updatePaginationPerPage = (event) => {
      paginationData.value.page = 1;
      paginationData.value.items = event;
      fetchData();
    };
    const pageChange = (event) => {
      paginationData.value.page = event;
      fetchData();
    };
    const sortUpdate = (event) => {
      sortData.value = {
        key: event[0].key,
        order: event[0].order,
      };
      fetchData();
    };

    const clearAllFilter = (event) => {
      textFilter.value = [];
      multipleFilter.value = [];
      fetchData();
    };
    const textFilterChange = (event) => {
      let index = textFilter.value?.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value) {
          textFilter.value[index] = event;
        } else {
          textFilter.value.splice(index, 1);
        }
      } else {
        textFilter.value.push(event);
      }
    };
    const multipleFiltersChange = (event) => {
      let index = multipleFilter?.value?.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value?.length) {
          multipleFilter.value[index] = event;
        } else {
          multipleFilter.value.splice(index, 1);
        }
      } else {
        multipleFilter.value.push(event);
      }
    };
    const downloadFile = (fileDetails) => {
      let docId = fileDetails.value?.escDocumentId;
      let endpoint = CONFIG_ENDPOINT.DOWNLOAD_EXTERNAL_FILE;
      let id = props.mpmsNumber;
      documentStore.downloadFile(docId, id, endpoint);
    };

    return {
      downloadFile,
      headers,
      inventorylist,
      loading,
      updatePaginationPerPage,
      pageChange,
      paginationData,
      sortUpdate,
      multipleFiltersChange,
      textFilterChange,
      lookupData,
      clearAllFilter,
      fetchData,
      multipleFilter,
      mpmsNo,
      county,
      srSec,
    };
  },
};
