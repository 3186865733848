<template>
  <v-card-text>
    <RailroadPlanReview
      @BannerUpdate="$emit('BannerUpdate', $event)"
    ></RailroadPlanReview>
  </v-card-text>
</template>
  <script>
import RailroadPlanReview from "@/modules/manageproject/components/RailroadPlanReview.vue";

export default {
  name: "ViewRailroadPlanReview-page",
  components: {
    RailroadPlanReview: RailroadPlanReview,
  },
};
</script>