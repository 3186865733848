import useDateField from "@/composables/dateField.js";import { ref } from "vue";
export default function () {
  const files = ref([]);
  const { getFormattedDateStringNoTime } = useDateField();
  const getDate = (date) => {
    return getFormattedDateStringNoTime(date);
  };
  function addFiles(newFiles) {
    let newUploadableFiles = [...newFiles]
      .map((file) => UploadableFile(file))
      .filter((file) => !fileExists(file.id));
    newUploadableFiles.forEach(async (file, index) => {
      await getFileBase64(file.file).then((base64) => {
        newUploadableFiles[index].payloadData.content = base64;
      });
    });
    files.value = files.value.concat(newUploadableFiles);
  }

  function UploadableFile(file) {
    return {
      file: file,
      id: `${file.name}-${file.size}-${file.lastModified}-${file.type}`,
      url: URL.createObjectURL(file),
      status: null,
      payloadData: {
        fileName: file.name,
        fileSize: calculateFileSize(file.size),
        docType: "",
        contentType: "",
        documentUploadDate: getDate(new Date()),
        notes: "",
        projectDocumentId: "",
        mpmsNumber: "",
        countyName: "",
        muniName: "",
        stateRoute: "",
        section: "",
        content: "",
        railroad: "",
        dot: "",
      },
    };
  }
  function calculateFileSize(sizeInBytes) {
    return (sizeInBytes / (1024 * 1024)).toFixed(2);
  }
  async function getFileBase64(file) {
    return await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  }

  function fileExists(otherId) {
    return files.value.some(({ id }) => id === otherId);
  }

  function removeFile(file) {
    const index = files.value.indexOf(file);

    if (index > -1) files.value.splice(index, 1);
  }

  function updateFiles(files) {
    files.value = files;
  }

  return { files, addFiles, removeFile, updateFiles };
}
