<template>
  <div v-if="isUserPa1Access.update || isUserPa1Access.read">
    <v-row cols="12">
      <v-col>
        <div class="env-header mt-20">
          <h2 class="locationHeaderText">PA1 Call</h2>
        </div>
      </v-col>
    </v-row>

    <v-row cols="12">
      <v-col>
        <VueTable
          :tableData="pa1Details"
          :columnData="headers"
          :tableHeader="''"
          :showAddRow="isUserPa1Access.update ? true : false"
          :addButtonText="'ADD PA1 CALL'"
          @editClicked="openDialog('Edit', $event)"
          @addClicked="openDialog('Add')"
          @deleteClicked="deletePA1Call($event)"
        ></VueTable>
      </v-col>
    </v-row>
  </div>
  <!-- Include the dialog component -->
  <DialogComponent ref="pa1DialogComponent"></DialogComponent>
</template>

<script>
import PA1CallDetails from "@/modules/projectinformation/scripts/PA1CallDetails.js";
export default PA1CallDetails;
</script>
<style>
@import "@/assets/css/main.scss";
@import "@/assets/css/color.scss";
@import "@/assets/css/style.scss";
</style>
