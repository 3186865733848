<template>
    <v-card-text>
      <RailroadWorkSummaryTabs
      ></RailroadWorkSummaryTabs>
    </v-card-text>
  </template>
  <script>
  import RailroadWorkSummaryTabs from "@/modules/manageproject/components/RailroadWorkSummaryTabs.vue";
  
  export default {
    name: "ViewRailroadWorkSummaryTabs-page",
    components: {
        RailroadWorkSummaryTabs: RailroadWorkSummaryTabs,
    },
  };
  </script>
  