<template>
  <div class="expanderOverLay">
    <MpmsLayout></MpmsLayout>
  </div>
</template>
<script>
import MpmsLayout from "@/modules/project/components/MpmsProjectDetails.vue";
export default {
  name: "Mpms-page",
  components: {
    MpmsLayout: MpmsLayout,
  },
};
</script>
