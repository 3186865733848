<template>
  <v-card-text>
    <GradeInventoryList></GradeInventoryList>
  </v-card-text>
</template>
<script>
import GradeInventoryList from "@/modules/grade-crossing-and-bridges/components/GradeInventoryList.vue";
export default {
  name: "GradeInventoryList-page",
  components: {
    GradeInventoryList: GradeInventoryList,
  },
};
</script>
