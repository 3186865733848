import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import sharedUserService from "@/modules/shared/service/sharedUserService";
import axios from "axios";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
export const userRoleBasedAccessStore = defineStore(
  "userRoleBasedAccess",
  () => {
    const state = {
      isCreateAccess: ref(false),
      error: ref(null),
      isLoading: ref(false),
      userMap: ref(new Map()),
    };

    const getUserData = {
      userData: computed(() => {
        return sharedUserService.getSharedData();
      }),
    };

    const checkUserAccess = async (inputFeatureCode) => {
      if (state.userMap.value.has(inputFeatureCode)) {
        return state.userMap.value.get(inputFeatureCode);
      } else {
        await createUserMap();
        if (state.userMap.value.has(inputFeatureCode)) {
          return state.userMap.value.get(inputFeatureCode);
        }
      }
    };

    const createUserMap = async () => {
      state.userMap.value.clear();
      let url = CONFIG_ENDPOINT.GET_USER_PERMISSIONS;

      try {
        const response = await axios.get(url); // Await the API call
        const temp = response.data.data;
        for (const [key, value] of Object.entries(temp)) {
          state.userMap.value.set(key, value);
        }
      } catch (err) {
        state.error.value = err; // Handle error
        console.error("Error fetching user permissions:", err);
      }
    };

    return {
      state,
      getUserData,
      checkUserAccess,
      createUserMap,
    };
  }
);
