import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import { useProjectServiceStore } from "@/stores/projectService";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";


export default {
  name: "ConsultantCompanyInfo",
  components: {
  },
  emits: ["BannerUpdate", "saveClicked"],
  setup(props, { emit }) {
    const route = useRoute();
    let tab = ref("companyInformation");
    const companyName = route.params.id;
    const companyObject = ref({});
    const projectServiceStore = useProjectServiceStore();
    const companyInfo = ref({});

    const isUserConsultantAccess = ref({
      read: false,
    });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const consultantAccessCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.ADMIN_CONSULTANT_LIST_PAGE_READ_ACCESS
      );

      isUserConsultantAccess.value.read = readResponse || false;
    };

    onMounted(async () => {
      await consultantAccessCheck();
      if (isUserConsultantAccess.value.read){
        fetchData();

      }
    });
   
    const fetchData = async () => {

        let map = await projectServiceStore.consultingCompanyMap;

        if (map){
            companyObject.value = map[companyName];
        }
    };
   

    function createDebounce() {
      let timeout = null;
      return function (fnc, delayMs) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fnc();
        }, delayMs || 1000);
      };
    }

    return {
        createDebounce,
        fetchData,
        tab,
        companyObject,
        route,
        companyName,
        companyInfo,
    
    };
  },
};
