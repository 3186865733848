import { CONFIG_ENDPOINT } from "@/constants/Endpoints";import DialogComponent from "@/modules/gcmsmain/admin/submenu/components/flaggerActivityDialog.vue";
import VueTable from "@/modules/shared/components/VueTable.vue";
import axios from "axios";
import { onMounted, ref } from "vue";
import { RBAC_CONTROLLER } from "../../../../../constants/rbacconstants";
import { userRoleBasedAccessStore } from "../../../../../stores/userRoleBasedAccess";

export default {
  components: {
    VueTable,
    DialogComponent,
    userRoleBasedAccessStore,
  },
  name: "flaggerActivityAdmin",
  props: {
    id: { type: String, default: null },
  },
  setup() {
    let flaggerAdminActivityDialog = ref(null);
    let itemIndex = ref(null);
    let flaggerActivityData = ref([]);
    let formData = ref({ activity: "", status: "" });
    let columnData = ref([
      {
        name: "Activity Name",
        key: "activity",
        isBold: true,
        columnWidth: "40",
      },
      { name: "Status", key: "status" },
      {
        name: "Action",
        key: "",
        isAction: true,
        isAdd: false,
        isEdit: true,
        isDelete: false,
      },
    ]);
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    let flaggerAccess = ref(false);
    onMounted(async () => {
      await accessChecks();
      if (flaggerAccess.value) {
        getFlaggerActivityData();
      } else {
        flaggerActivityData.value = [];
      }
    });
    const accessChecks = async (key) => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER?.ADDEDIT_FLAGGERACTIVITIES_CRTUPD_ACCESS
      );
      flaggerAccess.value = readResponse || false;
      if (!flaggerAccess.value) {
        columnData[2].isEdit = false;
      }
    };
    async function getFlaggerActivityData() {
      axios.get(CONFIG_ENDPOINT.GCMS_MAIN_FLAGGER_ACTIVITY).then((response) => {
        flaggerActivityData.value = response?.data;
        sortFlagger();
      });
    }
    function sortFlagger() {
      flaggerActivityData.value?.sort((a, b) => {
        if (a?.activity?.toLowerCase() < b?.activity?.toLowerCase()) return -1;
        if (a?.activity?.toLowerCase() > b?.activity?.toLowerCase()) return 1;
        return 0;
      });
    }
    const handleChangesSaved = (value) => {
      if (value?.type === "Add") {
        flaggerActivityData.value.push(value?.data);
        sortFlagger();
      } else if (value?.type === "Edit") {
        flaggerActivityData.value[value?.index] = value?.data;
        sortFlagger();
      }
    };
    const openDialog = (action, item) => {
      if (action === "Add") {
        formData.value = { activity: "", status: "Active" };
        flaggerAdminActivityDialog.value
          .open("Add Flagger Activity", "Add", formData.value)
          .then((resolve) => {
            if (resolve) {
              handleChangesSaved(resolve);
            }
          });
      } else if (action === "Edit") {
        itemIndex.value = item.index;
        formData.value = { ...item.item };
        flaggerAdminActivityDialog.value
          .open(
            "Edit Flagger Activity",
            "Edit",
            formData.value,
            itemIndex.value
          )
          .then((resolve) => {
            if (resolve) {
              handleChangesSaved(resolve);
            }
          });
      }
    };
    return {
      flaggerActivityData,
      formData,
      columnData,
      flaggerAdminActivityDialog,
      openDialog,
      flaggerAccess,
    };
  },
};
