<template>
  <v-form v-model="mpmsIdFormValid" v-if="true" v-click-outside="resetSearch">
    <v-app-bar class="dashboardstyle">
      <v-btn
        class="dashboardMenuBar"
        @click="goDashboard"
        style="margin-right: 10px"
      >
        <i class="headerIcon fa fa-home iconcolor"></i>DASHBOARD
      </v-btn>

      <ProjectCreateMenu
        v-if="isCreateAccess"
        @click="resetSearch"
        class="dashboardMenuBar"
        @resetError="resetSearch"
      ></ProjectCreateMenu>

      <AdminMenuPage
        @click="resetSearch"
        class="dashboardMenuBar"
        @resetError="resetSearch"
      ></AdminMenuPage>
      <DistrictMenuPage
        @click="resetSearch"
        class="dashboardMenuBar"
        @resetError="resetSearch"
      ></DistrictMenuPage>
      <v-toolbar-title
        @click="resetSearch"
        class="dashboardMenuBar"
        @resetError="resetSearch"
      ></v-toolbar-title>
      <router-link
        v-if="isGCInventory"
        @click="resetSearch"
        class="dashboardMenuBar font-normal f-size14 color-blue p-20"
        style="margin-right: 10px; font-weight: normal"
        :to="gradeinventoryRoute"
        ><i class="headerIcon fa fa-arrow-circle-right"></i>View GC
        Inventory</router-link
      >

      <div class="form-wrapper">
        <div class="form-elements">
          <div class="d-flex justify-content-around">
            <div>
              <v-text-field
                placeholder="MPMS#"
                v-model="mpmsValue"
                :rules="[mpmsRequiredRule, mpmsLengthRule, numericRule]"
                @keydown="keydownAllowNumberOnly($event)"
                @keydown.enter="submitForm"
                :maxlength="9"
                class="small-text-field"
              ></v-text-field>
            </div>

            <div>
              <v-btn
                type="submit"
                @click="submitForm"
                :disabled="
                  !mpmsIdFormValid || !mpmsValue || mpmsValue?.length === 0
                "
                placeholder="MPMS ID"
                class="btn-custom"
              >
                Go
              </v-btn>
            </div>
          </div>
        </div>
        <div
          class="invalid-mpms"
          v-show="showInvalidMPMS && lastEnteredMpmsValue"
        >
          MPMS# {{ lastEnteredMpmsValue }} does not exist
        </div>
      </div>
      <router-link
        @click="resetSearch"
        class="dashboardMenuBar font-normal f-size14 color-blue p-20"
        style="font-weight: normal"
        :to="advancedRoute"
        >Advanced Search<i
          class="headerIcon fa fa-search"
          style="transform: rotate(90deg); margin-left: 5px"
        ></i
      ></router-link>
    </v-app-bar>
  </v-form>
</template>

<script src="../scripts/gcmsMain.js"></script>
<style>
@import "@/assets/css/style.scss";
</style>
