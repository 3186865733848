<template>
  <v-row cols="12" justify="start">
    <SecondaryButton
      class="m-20"
      :buttonText="'Project Priority'"
      @buttonClicked="openPriorityProject"
    ></SecondaryButton>
  </v-row>
  <PriorityProjectDialogComponent
    ref="priorityProjectDialogRef"
  ></PriorityProjectDialogComponent>
</template>
<script>
import PriorityProject from "@/modules/projectinformation/scripts/PriorityProject.js";
export default PriorityProject;
</script>
<style>
@import "@/assets/css/style.scss";
</style>