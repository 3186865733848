import {
  reasonForUpdateRequiredRule,
  requiredRule,
} from "@/composables/validationRules";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import DatePicker from "@/modules/shared/components/DatePicker.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import VueAutoComplete from "@/modules/shared/components/VueAutoComplete.vue";
import sharedUserService from "@/modules/shared/service/sharedUserService";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import moment from "moment";
import { computed, onMounted, ref, watch } from "vue";

import {
  AUTO_COMPLETE_NO_DATA,
  DROPDOWN_NO_DATA,
} from "@/constants/displayTexts";
import { onBeforeRouteLeave } from "vue-router";

export default {
  name: "PennDotCrossingInfo",
  emits: ["showBanner"],
  props: {
    dotId: { default: null },
  },
  components: {
    DatePicker,
    PrimaryButton,
    SecondaryButton,
    VueAutoComplete,
  },

  setup(props, context) {
    const today = new Date().toLocaleDateString("fr-ca");
    let auxilaryTracksSurfaceData = ref([]);
    let originalAuxilaryTracksSurfaceData = ref([]);

    //in DB yes = 1, no = 2
    const yesOrNoList = ref(["Yes", "No"]);

    let isFormValid = ref(false);
    let auxMaterialsValid = ref(false);
    let tempVariables = ref([]);
    let prevValues = ref([]);
    let PennDotCrossingInfo = ref(null);
    let originalValue = ref(null);
    let isInitialised = ref(null);
    let isValueChanged = ref(false);
    const auxChanged = ref(0);

    const isUserGradeCrossingAccess = ref({
      read: false,
      update: false,
    });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const gradeCrossingAccessCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.GRADE_CROSSING_RECORD_READ_ACCESS
      );
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.GRADE_CROSSING_RECORD_CRTUPD_ACCESS
      );
      isUserGradeCrossingAccess.value.read = readResponse || false;
      isUserGradeCrossingAccess.value.update = updateResponse || false;
    };

    onMounted(async () => {
      await gradeCrossingAccessCheck();
      if (isUserGradeCrossingAccess.value.read) {
        await getPennDotCrossingInfo();
      }
    });
    async function getPennDotCrossingInfo() {
      await axios
        .get(`${CONFIG_ENDPOINT.PENNDOT}${props.dotId}`)
        .then((response) => {
          PennDotCrossingInfo.value = formatData(response?.data);
          originalValue.value = JSON.parse(
            JSON.stringify(PennDotCrossingInfo.value?.pennDotCrossingDTO)
          );
          originalAuxilaryTracksSurfaceData.value = JSON.parse(
            JSON.stringify(auxilaryTracksSurfaceData.value)
          );
          setTimeout(() => {
            isInitialised.value = true;
          }, 1000);
        })
        .catch((err) => {});
    }
    watch(
      () => [PennDotCrossingInfo.value?.pennDotCrossingDTO],
      () => {
        if (
          isInitialised.value &&
          (!objectsAreSame(
            originalValue.value,
            PennDotCrossingInfo.value.pennDotCrossingDTO
          ) ||
            auxChanged.value > 1))
         {
          isValueChanged.value = true;
        } else {
          isValueChanged.value = false;
    
        }
      },
      { deep: true }
    );
    watch(
      () => [auxilaryTracksSurfaceData.value],
      () => {
      
        let temp = 0;
        for (let index in auxilaryTracksSurfaceData.value){
          if(auxilaryTracksSurfaceData.value[index].sfMaterial.length < 1){
            temp += 1;
          }
        }
        if (temp == 0){
          auxMaterialsValid.value = true;
        } else {
          auxMaterialsValid.value = false;
        }
        if (auxChanged.value > 1){
          isValueChanged.value = true;
        }
      
      },
      { deep: true }
    );
    const arraysEqual = (a, b) => {
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      for (let i = 0; i < a.length; ++i) {
        if (!objectsAreSame(a[i], b[i])) return false;
      }
      return true;
    };
    const objectsAreSame = (x, y) => {
      let objectsAreSame = true;
      let keysToCheck = [
        "pucStatus",
        "railBank",
        "noActiveTracks",
        "noTravelLanes",
        "surfaceCdtn",
        "advWrnSgnAdqe",
        "newSigns",
        "signsRelocated",
        "pvmtMrkAdqe",
        "trackName",
        "surOther",
        "surfaceCondition",
        "crossingComments",
      ];
      for (let key of keysToCheck) {
        if (x[key] !== y[key]) {
          objectsAreSame = false;
          break;
        }
      }
      return objectsAreSame;
    };
    const dotId = computed({
      get: () => {
        return props?.dotId;
      },
    });

    const formatData = (data) => {
      if (data?.pennDotCrossingDTO.surfaceInstallDate) {
        data.pennDotCrossingDTO.surfaceInstallDate = moment(
          data?.pennDotCrossingDTO.surfaceInstallDate
        )
          .add(1, "d")
          .format("YYYY-MM-DD");
      }

      if (data?.pennDotCrossingDTO.pvmtMrkDate) {
        data.pennDotCrossingDTO.pvmtMrkDate = moment(
          data?.pennDotCrossingDTO.pvmtMrkDate
        )
          .add(1, "d")
          .format("YYYY-MM-DD");
      }

      if (data?.pennDotCrossingDTO.railBank == "Yes") {
        data.pennDotCrossingDTO.railBank = true;
      } else {
        data.pennDotCrossingDTO.railBank = false;
      }

      let index = 0;
      if (data?.pennDotCrossingDTO?.auxTracks) {
        for (let track of data.pennDotCrossingDTO.auxTracks) {
          auxilaryTracksSurfaceData.value.push(track);
          for (let material of track.sfMaterial) {
            if (!tempVariables.value[index]) {
              tempVariables.value[index] = [
                {
                  crossingSrfCode: material.crossingSrfCode,
                  surfaceId: material.surfaceId,
                },
              ];
            } else {
              tempVariables.value[index].push({
                crossingSrfCode: material.crossingSrfCode,
                surfaceId: material.surfaceId,
              });
            }
          }
          index++;
        }
      }
      auxilaryTracksSurfaceData.value = data?.pennDotCrossingDTO.auxTracks;
      return data;
    };

    const activeTracksChange = (event) => {
      const selectedActiveTrack = Number(event.split(" ")[0]) - 1;

      if (!auxilaryTracksSurfaceData.value?.length && selectedActiveTrack) {
        auxilaryTracksSurfaceData.value = [];
        for (let i = 0; i < selectedActiveTrack; i++) {
          let object = {
            penndotCrossingId: null,
            dotnumber: dotId.value,
            trackName: "",
            surfaceCondition: "",
            crossingComments: "",
            sfMaterial: [],
            surOther: "",
          };
          auxilaryTracksSurfaceData.value.push(object);
        }
      } else if (
        auxilaryTracksSurfaceData.value?.length < selectedActiveTrack
      ) {
        const auxilaryLength = auxilaryTracksSurfaceData.value?.length;
        for (let i = 0; i < selectedActiveTrack - auxilaryLength; i++) {
          let object = {
            penndotCrossingId: null,
            dotnumber: dotId.value,
            trackName: "",
            surfaceCondition: "",
            crossingComments: "",
            sfMaterial: [],
            surOther: "",
          };
          auxilaryTracksSurfaceData.value.push(object);
        }
      } else if (
        auxilaryTracksSurfaceData.value?.length > selectedActiveTrack
      ) {
        auxilaryTracksSurfaceData.value.length = selectedActiveTrack;
      }
    };

    const createReturnObject = () => {
      let tempRailBank;
      if (PennDotCrossingInfo.value.pennDotCrossingDTO.railBank) {
        tempRailBank = 1;
      } else {
        tempRailBank = 2;
      }

      let temp = {
        pennDotCrossingDTO: {
          advWrnSgnAdqe:
            PennDotCrossingInfo.value.pennDotCrossingDTO.advWrnSgnAdqe,
          auxTracks: auxilaryTracksSurfaceData.value,
          dotnumber: dotId.value,
          newSigns: PennDotCrossingInfo.value.pennDotCrossingDTO.newSigns,
          noActiveTracks:
            PennDotCrossingInfo.value.pennDotCrossingDTO.noActiveTracks,
          noTravelLanes:
            PennDotCrossingInfo.value.pennDotCrossingDTO.noTravelLanes,
          penndotXSurface:
            PennDotCrossingInfo.value.pennDotCrossingDTO.penndotXSurface,
          pucStatus: PennDotCrossingInfo.value.pennDotCrossingDTO.pucStatus,
          pvmtMrkAdqe: PennDotCrossingInfo.value.pennDotCrossingDTO.pvmtMrkAdqe,
          pvmtMrkDate: PennDotCrossingInfo.value.pennDotCrossingDTO.pvmtMrkDate,
          planningOrganization:
            PennDotCrossingInfo.value.pennDotCrossingDTO.planningOrganization,
          railBank: tempRailBank,
          signsRelocated:
            PennDotCrossingInfo.value.pennDotCrossingDTO.signsRelocated,
          surfaceCdtn: PennDotCrossingInfo.value.pennDotCrossingDTO.surfaceCdtn,
          surfaceInstallDate:
            PennDotCrossingInfo.value.pennDotCrossingDTO.surfaceInstallDate,
          userID: userData.value?.userID,
        },
      };
      return temp;
    };
    const userData = computed({
      get: () => {
        return sharedUserService.getSharedData();
      },
    });
    const saveClicked = () => {
      let object = createReturnObject();

      axios
        .put(CONFIG_ENDPOINT.PENNDOT + dotId.value, object)
        .then(() => {
          tempVariables.value = [];

          isValueChanged.value = false;
          isInitialised.value = false;
          getPennDotCrossingInfo();
          context.emit("showBanner", 'Saved Successfully.');
        })
        .catch((err) => {
          context.emit("showBanner", err.response.data.businessMessage);
        });
    };
    window.onbeforeunload = function () {
      if (isInitialised.value && isValueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };

    onBeforeRouteLeave((to, from, next) => {
      if (isInitialised.value && isValueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          isValueChanged.value = false;
          next();
        } else {
          next(false);
        }
      } else {
        isValueChanged.value = false;
        next();
      }
    });
    const cancelClicked = () => {
      auxChanged.value = 0 
      getPennDotCrossingInfo();
    };
    const datePavelemntMarketingChange = (value) => {
      PennDotCrossingInfo.value.gradeCrossingPennDotCrossingDTO.datePavementMarkingsReplaced =
        value;
    };
    const surfaceInstalledDateChange = (value) => {
      PennDotCrossingInfo.value.gradeCrossingPennDotCrossingDTO.surfaceInstalledDateChange =
        value;
    };
    const removeItem = (aux, item, index) => {
      if (item.crossingSrfCode == "Other (Specify)") {
        aux.surOther = "";
      }
      aux.sfMaterial.splice(aux.sfMaterial.indexOf(item), 1);
      tempVariables.value[index] = aux.sfMaterial;
    };
    const sfMaterials = computed(() => {
      return PennDotCrossingInfo.value.pennDotCrossingLookupData.surfaceMaterial.map(
        (surfaceItem) => {
          return { crossingSrfCode: surfaceItem, surfaceId: null };
        }
      );
    });

    const setSfDetails = (aux, index) => {
      aux.sfMaterial = tempVariables.value[index];
    };
    const pavamentMarkingsReplacedDateChange = (event) => {
      if (event?.target?.value) {
        PennDotCrossingInfo.value.pennDotCrossingDTO.pvmtMrkDate =
          event.target.value;
      } else if (event) {
        PennDotCrossingInfo.value.pennDotCrossingDTO.pvmtMrkDate = event;
      }
    };
    const surfaceInstalledDatePickerChange = (event) => {
      if (event?.target?.value) {
        PennDotCrossingInfo.value.pennDotCrossingDTO.surfaceInstallDate =
          event.target.value;
      } else if (event) {
        PennDotCrossingInfo.value.pennDotCrossingDTO.surfaceInstallDate = event;
      }
    };
    const isDateValidated = ref(false);
    const isSurfaceDateValidated = ref(false);
    const checkDateValidation = (value) => {
      isDateValidated.value = value;
    };
    const checkSurfaceDateValidation = (value) => {
      isSurfaceDateValidated.value = value;
    };
    const checkFormValidation = computed({
      get: () => {
        return (          
          isFormValid.value && isDateValidated.value && isSurfaceDateValidated.value && auxMaterialsValid.value
        );
      },
    });

    return {
      checkFormValidation,
      checkSurfaceDateValidation,
      checkDateValidation,
      surfaceInstalledDatePickerChange,
      pavamentMarkingsReplacedDateChange,
      datePavelemntMarketingChange,
      surfaceInstalledDateChange,
      auxilaryTracksSurfaceData,
      dotId,
      PennDotCrossingInfo,
      today,
      activeTracksChange,
      isFormValid,
      saveClicked,
      cancelClicked,
      requiredRule,
      reasonForUpdateRequiredRule,
      yesOrNoList,
      removeItem,
      sfMaterials,
      tempVariables,
      setSfDetails,
      prevValues,
      createReturnObject,
      formatData,
      DROPDOWN_NO_DATA,
      AUTO_COMPLETE_NO_DATA,
      isUserGradeCrossingAccess,
      auxMaterialsValid,
      isValueChanged,
      auxChanged,
      arraysEqual,
    };
  },
};
