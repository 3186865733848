<template>
  <v-card-text>
    <EditAddConsultantAgreement></EditAddConsultantAgreement>
  </v-card-text>
</template>
  <script>
import EditAddConsultantAgreement from "@/modules/gcmsmain/admin/submenu/components/EditAddConsultantAgreement.vue";

export default {
  name: "viewEditAddConsultantAgreement-page",
  components: {
    EditAddConsultantAgreement: EditAddConsultantAgreement,
  },
};
</script>
  