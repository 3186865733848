import EstimatedProjectFundingInfo from "@/modules/cost-and-funding/components/EstimatedProjectFundingInfo.vue";
import ProgramFundingInfo from "@/modules/cost-and-funding/components/ProgramFundingInfo.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import { reactive, toRefs } from "vue";

export default {
  components: {
    PrimaryButton,
    SecondaryButton,
    EstimatedProjectFundingInfo,
    ProgramFundingInfo,
  },
  props: {
    id: { type: Number, default: null },
  },
  name: "ViewFundingDialog",
  setup(props) {
    const state = reactive({
      dialog: false,
      resolve: null,
      reject: null,
      id: null,
    });

    const open = (id) => {
      state.dialog = true;
      state.id = id;
      return new Promise((resolve, reject) => {
        state.resolve = resolve;
        state.reject = reject;
      });
    };
    const closeDialog = () => {
      state.resolve(false);
      state.dialog = false;
    };

    return {
      ...toRefs(state),
      closeDialog,
      open,
    };
  },
};
