import { defineStore } from "pinia";
import { ref } from "vue";
export const useErrorHandlingStore = defineStore("errorHandling", () => {
  let showError = ref(false);
  let errorMessage = ref("");
  const setErrorHandling = (showErr, errMsg) => {
    showError.value = showErr;
    errorMessage.value = errMsg;
  };
  return {
    setErrorHandling,
    showError,
    errorMessage,
  };
});
