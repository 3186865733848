<template>
  <div v-if="showBanner">
    <BannerMessage
      :successBanner="true"
      :message="bannerMessage"
      @hideBanner="hideBannerMessage"
    ></BannerMessage>
  </div>
  <div>
    <v-row>
      <!-- Left corner element -->
      <v-col class="ml-20">
        <router-link :to="`/GCMS/${id}`">
          &lt; Return to Project Information Page
        </router-link>
      </v-col>
      <!-- Centered element -->
      <v-col class="page-title">
        <h1>PUC Communications</h1>
      </v-col>
      <v-col> </v-col>
    </v-row>
    <div>
      <v-card class="pucCard">
        <v-form v-model="isFormValid">
          <br /><br />
          <div v-for="(item, index) in pucCommunications" :key="index">
            <v-row cols="12" class="pucCommunicationsRow">
              <v-col cols="1"></v-col>
              <v-col cols="7"
                ><b style="font-size: 15px">{{ item.pucCommName }}</b></v-col
              >
              <v-col cols="2">
                <DatePicker
                  id="txt_id"
                  :value="item.pucCommDate"
                  :invalidMsg="''"
                  :isPucDocket="false"
                  @input="(event) => dateChange(event, index, item)"
                  @dateValidation="
                    (isValid) => checkDateValidation(isValid, index)
                  "
                />
              </v-col>
              <v-col cols="2"></v-col>
            </v-row>
            <br /><br />
          </div>
        </v-form>
      </v-card>
      <br />
    </div>
    <br />
    <div
      class="d-flex justify-content-end align-items-center"
      style="padding-right: 150px !important"
    >
      <PrimaryButton
        :buttonText="'Save'"
        @buttonClicked="postData"
        :disabled="!checkFormValidation"
      ></PrimaryButton>
      <SecondaryButton
        :buttonText="'Cancel'"
        @buttonClicked="fetchData"
        class="ml-10"
      ></SecondaryButton>
    </div>
    <div class="env-header mb-20">
      <h2 class="locationHeaderText">Project PUC Documents</h2>
    </div>
    <v-grid>
      <v-row justify="center">
        <v-col cols="11">
          <DocumentsList
            :heading="'Document List'"
            :doctype="'/PUC_VWE_DT'"
            :id="id"
            :updateDocumentList="updateDocumentList"
            @updateDocumentListFlag="updateDocumentListFlag"
            @otherButtonClicked="updateButtonClicked($event)"
          >
          </DocumentsList>
        </v-col>
      </v-row>
    </v-grid>
    <br />
    <v-grid>
      <v-row justify="center">
        <v-col cols="11">
          <h2
            class="f-size18 f-montserrat text-left mt-50"
            style="font-size: 18px; padding-bottom: 0.5em; color: #003e7e"
          >
            Upload Documents
          </h2>
          <p class="text-left" style="padding-bottom: 1px; padding-left: 10px">
            Maximum allowable file size is 500 MB.
          </p>
          <UploadScopingFieldViewDocument
            v-if="!UpdateModalOpened"
            @uploadFilesCall="uploadFiles"
            :isDynamicDocumentType="true"
            :isRailroadDropdownDisabled="true"
            :showPreviewRailroadColumn="true"
            :checkMaximumFilesUploaded="true"
            :checkMaxFileSize="true"
            :previewDocTypeUrl="'GET_PUC_COMMUNICATIONS_DOCTYPE_LIST'"
            :mpmsId="id"
            :isPageUpload="true"
          ></UploadScopingFieldViewDocument>
        </v-col>
      </v-row>
    </v-grid>
    <br />
  </div>
  <LoadingOverlay :isLoading="loading"> </LoadingOverlay>
  <DialogComponent ref="railroadEstimatesDocumentsDialogRef"></DialogComponent>
</template>
<script>
import PucCommunications from "@/modules/manageproject/scripts/pucCommunications.js";
export default PucCommunications;
</script>
<style>
@import "@/modules/projectinformation/assets/projectinfostyle.scss";
@import "@/assets/css/style.scss";
</style>