import { phoneRule, requiredRule } from "@/composables/validationRules";import { computed, ref } from "vue";
export default {
  name: "PhoneInput",
  props: {
    modelValue: { default: "" },
  },
  setup(props, context) {
    const stoppedTyping = ref(false);
    const isRequired = ref(props?.isRequired);

    const computedValue = computed({
      get: () => {
        if (!props.modelValue) {
          return "";
        }

        if (
          props.modelValue.length <= 5 &&
          (props.modelValue.includes("(") || props.modelValue.includes(")"))
        ) {
          return props.modelValue;
        }
        if (props.modelValue.length == 3 && !props.modelValue.includes(")")) {
          return "(" + props.modelValue + ") ";
        } else if (
          props.modelValue.length == 5 &&
          !props.modelValue.includes(")")
        ) {
          return (
            props.modelValue.slice(0, 4) + ") " + props.modelValue.slice(4, 5)
          );
        } else if (props.modelValue.length == 6) {
          return (
            props.modelValue.slice(0, 5) + " " + props.modelValue.slice(5, 6)
          );
        } else if (
          (props.modelValue.length == 9 && !props.modelValue.includes("-")) ||
          (props.modelValue.length == 10 && !props.modelValue.includes("-"))
        ) {
          return (
            props.modelValue.slice(0, 9) + "-" + props.modelValue.slice(9, 10)
          );
        }
        return props.modelValue;
      },

      set: (modifiedValue) => {
        context.emit("update:modelValue", modifiedValue);
      },
    });
    const getPhoneFieldRule = computed({
      get: () => {
        return stoppedTyping.value;
      },
    });
    const fieldValueChange = () => {
      stoppedTyping.value = false;
    };
    const setFieldRule = () => {
      stoppedTyping.value = true;
    };

    function createDebounce() {
      let timeout = null;
      return function (fnc, delayMs) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fnc();
        }, delayMs || 1000);
      };
    }

    return {
      computedValue,
      fieldValueChange,
      setFieldRule,
      getPhoneFieldRule,
      requiredRule,
      debounce: createDebounce(),
      isRequired,
      phoneRule,
    };
  },
};
