<template>
  <div
    v-if="
      isUserRailroadWorkSummaryAccess.update ||
      isUserRailroadWorkSummaryAccess.read
    "
  >
    <BannerMessage
      v-if="showSaveBannerMessage"
      :successBanner="true"
      :message="'Successfully Saved Railroad Work Summary.'"
      @hideBanner="hideBanner"
    ></BannerMessage>
   <br>
    <div v-if="selectedRailroadDetails">
      <v-form v-model="isFormValid" class="projectInvolvement">
        <div>
          <div class="env-header mb-20">
            <h2 class="locationHeaderText">Project Details</h2>
          </div>
        </div>
        <div>
          <v-row>
            <v-col cols="1"></v-col>
            <v-col cols="2">
              <b>Work Type(s): </b>
            </v-col>
            <v-col cols="5">
              <v-chip
                v-for="(
                  item, index
                ) in selectedRailroadDetails.selectedWorkTypes"
                :key="item"
                class="ma-2"
                closable
                @click:close="removeWorkType(selectedRailroadDetails, index)"
              >
                {{ item }}
              </v-chip>
              <v-autocomplete
                class="w-50"
                v-model="selectedRailroadDetails.selectedWorkTypes"
                :items="selectedRailroadDetails.workTypes"
                multiple
                item-title="workType"
                item-value="workType"
              >
              </v-autocomplete>
            </v-col>
            <v-col style="padding-left:75px !important"> <v-label
              class="dashboardMenuBar font-normal f-size14 color-blue"
              style="margin-right: 10px; font-weight: normal;font-size:14px; opacity: initial !important; text-decoration: underline;cursor:pointer"
              @click="routeTo('Project Plan Review')"
              ><i class="headerIcon fa fa-arrow-circle-right"></i>Project Plan Review</v-label
            >  <v-label
              class="dashboardMenuBar font-normal f-size14 color-blue"
              style="margin-right: 10px; font-weight: normal; font-size:14px; opacity: initial !important; text-decoration: underline; cursor:pointer"
              @click="routeTo('Estimate')"
              ><i class="headerIcon fa fa-arrow-circle-right"></i>Add/Edit Estimate</v-label
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="1"></v-col>
            <v-col cols="2">
              <b for="railroadProjectIdentifier">
                Railroad Project Identifier:
              </b>
            </v-col>
            <v-col cols="3">
              <v-text-field
                id="railroadProjectIdentifier"
                v-model="selectedRailroadDetails.railroadProjectIdentifier"
                :maxlength="20"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1"></v-col>
            <v-col cols="2">
              <b for="crossingScope">Crossing Scope:</b>
            </v-col>
            <v-col cols="6">
              <span id="crossingScope">
                {{ selectedRailroadDetails.crossingScopeDesc }}
              </span>
            </v-col>
            <v-col cols="3">      
             </v-col>
          </v-row>
          <br><br>
          <v-row>
            <v-col cols="1"></v-col>
            <v-col cols="2">
              <b for="estimatedConstructionStartDate">
                Construction Start Date:
              </b>
            </v-col>
            <v-col cols="3">
              <span id="estimatedConstructionStartDate">
                {{ selectedRailroadDetails.estimatedConstructionStartDate }}
              </span>
            </v-col>
            <v-col cols="3">      
             </v-col>
          </v-row>
          <br><br>
        </div>
        <br />
        <div>
          <div class="env-header mb-20">
            <h2 class="locationHeaderText">Crossing Details</h2>
          </div>
        </div>
        <div v-if="crossingDetails.length != 0">
          <v-row cols="12">
            <v-col col="10"></v-col>
            <v-col cols="2">

              <v-label
              class="dashboardMenuBar font-normal f-size14 color-blue"
              style="font-weight: normal; font-size:14px; opacity: initial !important; text-decoration: underline;cursor:pointer"
              @click="routeTo('Flagger Needs')"
              ><i class="headerIcon fa fa-arrow-circle-right"></i>Add/Edit Flagger Needs</v-label
            >
          </v-col>
          </v-row>

          <v-table class="mt-20" style="width: 100%">
            <thead>
              <tr>
                <th scope="col" style="width: 15%">Crossing</th>
                <th scope="col" style="width: 7%">Crossing Type</th>
                <th scope="col" style="width: 7%">Milepost</th>
                <th scope="col" style="width: 10%">Local Road</th>
                <th scope="col" style="width: 10%">County</th>
                <th scope="col" style="width: 10%">Branch or Line Name</th>
                <th scope="col" style="width: 10%">Map Link</th>
                <th scope="col" style="width: 17%">Planned Work</th>
                <th scope="col" style="width: 12%">Work Order</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="crossing of crossingDetails" :key="crossing">
                <td style="width: 15%">{{ crossing.dotNumber }}</td>
                <td style="width: 7%">{{ crossing.crossingType }}</td>
                <td style="width: 7%">{{ crossing.milePost }}</td>
                <td style="width: 10%">{{ crossing.localRoad }}</td>
                <td style="width: 10%">{{ crossing.county }}</td>
                <td style="width: 10%">{{ crossing.branch }}</td>
                <td style="width: 10%">
                  <a
                    class="color-blue pointer"
                    @click="navigateToGISMap('crossing', crossing.dotNumber)"
                    >View On Map</a
                  >
                </td>
                <td style="width: 17%">
                  <v-select style=" margin-top: 10px !important; margin-bottom:-8px !important"
                    :items="crossing.plannedWorks"
                    v-model="crossing.plannedWork"
                    :rules="[requiredRule]"
                  ></v-select>
                </td>
                <td style="width: 12%" class="notes-field">
                  <v-text-field style=" margin-top: 10px !important; margin-bottom:-8px !important"
                    variant="outlined"
                    type="text"
                    v-model="crossing.workOrderNumber"
                  ></v-text-field>
                </td>
              </tr>
            </tbody>
          </v-table>
        </div>
        <div v-else>
          <v-table class="mt-20" style="width: 100%">
            <thead>
              <tr>
                <th scope="col" style="width: 7%">Crossing</th>
                <th scope="col" style="width: 7%">Crossing Type</th>
                <th scope="col" style="width: 7%">Milepost</th>
                <th scope="col" style="width: 10%">Local Road</th>
                <th scope="col" style="width: 10%">County</th>
                <th scope="col" style="width: 10%">Branch or Line Name</th>
                <th scope="col" style="width: 10%">Map Link</th>
                <th scope="col" style="width: 17%">Planned Work</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td :colspan="getNoDataColspan" class="text-center">
                  No Crossings Found.
                </td>
              </tr>
            </tbody>
          </v-table>
        </div>
        <br /><br />
        <div>
          
          <div v-if="selectedRailroad">
            <div class="env-header mb-20">
              <h2 class="locationHeaderText">
                Project Tasks
              </h2>
            </div>
            <div
          v-if="teamMessage"
          class="railroadError"
          style="width: 100%; margin-left:-1px"
        >
          <v-icon icon="mdi-alert"></v-icon>
          {{ teamMessage }} <v-label
              style="padding-left: 5px; font-size:14px; opacity: initial !important; text-decoration: underline;cursor:pointer"
              @click="routeTo('Project Team')"
              > Project Team.</v-label
            >
        </div>
            <div v-if="projectTasks">
              <v-table class="mt-20" style="width: 100%">
            <thead>
              <tr>
                <th scope="col" style="width: 15%">Task Name</th>
                <th scope="col" style="width: 7%">Due Date</th>
                <th scope="col" style="width: 7%">Assigned User</th>
                <th scope="col" style="width: 10%">Completed Date</th>
                <th scope="col" style="width: 10%">Days Remaning</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="projectTask of projectTasks" :key="projectTask" @click="routeTo(projectTask.navigateTo)">

                <td v-if="projectTask.completedDate" style="width: 15%; font-style: italic;">{{ projectTask.taskName}}</td>
                <td v-else style="width: 15%; text-decoration: underline; color:blue;">{{ projectTask.taskName}}</td>

                <td v-if="projectTask.completedDate" style="width: 10%; font-style: italic;"><i v-if="projectTask.redBellIcon" class='tableIcons fas fa-bell' aria-hidden='true' style='color: #C70000;'></i> {{ projectTask.dueDate }}</td>
                <td v-else style="width: 10%"><i v-if="projectTask.redBellIcon" class='tableIcons fas fa-bell' aria-hidden='true' style='color: #C70000;'></i> {{ projectTask.dueDate }}</td>

                <td v-if="projectTask.completedDate" style="width: 10%; font-style: italic;">{{ projectTask.assignedUser }}</td>
                <td v-else style="width: 10%">{{ projectTask.assignedUser }}</td>

                <td v-if="projectTask.completedDate" style="width: 10%; font-style: italic;">{{ projectTask.completedDate }}</td>
                <td v-else style="width: 10%">{{ projectTask.completedDate }}</td>

                <td v-if="projectTask.completedDate" style="width: 10%; font-style: italic;">{{ projectTask.daysRemaining }}</td>
                <td v-else style="width: 10%">{{ projectTask.daysRemaining }}</td>

              </tr>
            </tbody>
          </v-table>
            </div>
          <div v-else>
          <v-table class="mt-20" style="width: 100%">
            <thead>
              <tr>
                <th scope="col" style="width: 15%">Task Name</th>
                <th scope="col" style="width: 7%">Due Date</th>
                <th scope="col" style="width: 7%">Assigned User</th>
                <th scope="col" style="width: 10%">Completed Date</th>
                <th scope="col" style="width: 10%">Days Remaning</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td :colspan="getNoDataColspan" class="text-center">
                  No Project Tasks to Display.
                </td>
              </tr>
            </tbody>
          </v-table>
        </div>
        </div>
      </div>

        <br>
        
        <div class="action">
          <SecondaryButton
              :buttonText="'Cancel'"
              @buttonClicked="cancelSave"
              :disabled="!isUserRailroadWorkSummaryAccess.update"
            ></SecondaryButton>
            <PrimaryButton
              @buttonClicked="saveClicked"
              :buttonText="'Save'"
              class="ml-2"
              :disabled="
                !selectedRailroad ||
                !isUserRailroadWorkSummaryAccess.update ||
                !dataChanged
              "
            ></PrimaryButton>
          </div>
        <br />
      
      </v-form>
    </div>
  </div>
</template>
<script>
import RailroadWorkSummary from "@/modules/manageproject/scripts/RailroadWorkSummary.js";
export default RailroadWorkSummary;
</script>
<style>
@import "@/assets/css/style.scss";
v-field__input {
  overflow: hidden !important;
}
.v-autocomplete .v-field .v-text-field__prefix,
.v-autocomplete .v-field .v-text-field__suffix,
.v-autocomplete .v-field .v-field__input,
.v-autocomplete .v-field.v-field {
  overflow: hidden !important;
}
</style>