<template>
    <v-card-text>
      <RailroadWorkSummary
        @BannerUpdate="$emit('BannerUpdate', $event)"
      ></RailroadWorkSummary>
    </v-card-text>
  </template>
  <script>
  import RailroadWorkSummary from "@/modules/manageproject/components/RailroadWorkSummary.vue";
  
  export default {
    name: "ViewRailroadWorkSummary-page",
    components: {
        RailroadWorkSummary: RailroadWorkSummary,
    },
  };
  </script>
  