import useDateField from "@/composables/dateField.js";import useSharedFunctions from "@/composables/shared";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import FeatureTable from "@/modules/shared/components/FeatureTable.vue";
import sharedUserService from "@/modules/shared/service/sharedUserService";
import axios from "axios";
import { computed, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "InspectionsR2Table",
  components: { FeatureTable },
  props: {
    tab: { type: String, default: null },
    dotId: { type: String, default: null },
    userAccess: { default: { read: false, update: false } },
  },
  emits: ["buttonClicked"],
  setup(props, { emit }) {
    const lookupData = ref(null);
    const inspectionsList = ref([]);
    const loading = ref(true);
    const router = useRouter();
    const userData = computed({
      get: () => {
        return sharedUserService.getSharedData();
      },
    });
    let headers = ref([
      {
        title: "Crossing Inspection Date",
        filter: null,
        key: "inspectionDate",
      },
      {
        title: "Inspection Type ",
        filter: null,
        filterType: "multi-select",
        filterSort: "text",
        key: "inspectionType",
      },
      {
        title: "MPMS#",
        filter: null,
        filterType: "single-auto-complete",
        key: "mpmsNumber",
        isLink: true,
      },
      {
        title: "Crossing Changes",
        filter: null,
        key: "crossingChanges",
      },
      {
        title: "Inspected By",
        filter: null,
        key: "inspectedBy",
        width: "30% !important",
      },
    ]);
    const paginationData = ref({
      page: 1,
      items: 10,
      total: 0,
    });
    const sortData = ref({
      key: "",
      order: "",
    });
    const textFilter = ref([]);
    const multipleFilter = ref([]);
    const { getFormattedDateStringNoTime } = useDateField();
    const currentFilters = ref(null);
    const { ExportToCSV } = useSharedFunctions();
    const isInitiatedTableData = ref(false);
    const getDate = (date) => {
      return getFormattedDateStringNoTime(date);
    };
    onMounted(async () => {
      console.log("userAccess", props?.userAccess);
      if (props?.userAccess.update || props?.userAccess.read) {
        headers.value.push({
          title: "Action",
          key: "action",
          columnType: "action",
          isAction: true,
          width: "20% !important",
          isEdit: props?.userAccess.update,
          isOtherButton: props?.userAccess.read,
          otherButtonText: "View",
        });
      }
      await fetchData();
      isInitiatedTableData.value = true;
    });
    watch(
      () => [props.tab],
      () => {
        if (props.tab === "InspectionsR2") {
          fetchData();
        }
      },
      { deep: true }
    );

    const getLookupData = (filterText, Filter) => {
      let filterText1 =
        "?page=" +
        (paginationData.value.page - 1) +
        "&size=" +
        paginationData.value.items +
        "&userID=" +
        userData.value?.userID +
        "&isUserProject=" +
        true;
      if (sortData.value) {
        filterText1 =
          filterText1 +
          "&sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order;
      }

      axios
        .post(
          CONFIG_ENDPOINT.DOT_INSPECTIONS_LOOKUP_LIST_R2 +
            props?.dotId +
            filterText1,
          Filter
        )
        .then((response) => {
          lookupData.value = response.data;
          headers.value.forEach((header, index) => {
            headers.value[index] = {
              ...header,
              sortable: !checkFieldDisabled(header.key, header?.filterType),
            };
          });
        })
        .catch((err) => {
          lookupData.value = null;
        });
    };
    async function fetchData() {
      let Filter = {
        mpmsNumber: null,
        inspectionType: null,
      };
      textFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter.key) && filter.value) {
          Filter[filter.key] = filter.value;
        }
      });
      multipleFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter?.key) && filter.value) {
          Filter[filter.key] = filter.value;
        }
      });
      let filterText =
        "?page=" +
        (paginationData.value.page - 1) +
        "&size=" +
        paginationData.value.items +
        "&userID=" +
        userData.value?.userID;
      if (sortData.value) {
        filterText =
          filterText +
          "&sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order;
      }
      loading.value = true;
      axios
        .post(
          CONFIG_ENDPOINT.DOT_INSPECTIONS_LIST_R2 + props?.dotId + filterText,
          Filter
        )
        .then((response) => {
          setInspections(response, Filter, filterText);
        })
        .catch((err) => {
          loading.value = false;
          inspectionsList.value = [];
          paginationData.value = {
            page: 1,
            items: paginationData.value.items,
            total: 0,
          };
        });
    }

    const setInspections = (response, Filter, filterText) => {
      loading.value = false;
      inspectionsList.value = response.data.data;
      inspectionsList.value.forEach((item) => {
        item.inspectionDate = getDate(item.inspectionDate);
      });
      paginationData.value.total = 0;
      paginationData.value.items = 0;
      currentFilters.value = Filter;
      getLookupData(filterText, Filter);
    };

    const exportButtonClicked = () => {
      let filterText = "?page=" + 0 + "&size=" + paginationData.value.total;
      if (sortData.value) {
        filterText =
          filterText +
          "&sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order +
          "&userID=" +
          userData.value?.userID;
      }
      axios
        .post(
          CONFIG_ENDPOINT.GCMS_MAIN_ACTIVE_PROJECTS + filterText,
          currentFilters.value
        )
        .then((response) => {
          setInspectionsExportDetails(response);
        })
        .catch((err) => {});
    };
    const setInspectionsExportDetails = (response) => {
      loading.value = false;
      let exportDataList = response.data.data;
      exportDataList.forEach((item) => {
        item.inspectionDate = getDate(item.inspectionDate);
      });
      ExportToCSV(headers.value, exportDataList, "Active Projects");
    };
    const checkFieldDisabled = (key, filterType) => {
      if (
        key === "inspectionDate" ||
        key === "inspectionType" ||
        key === "mpmsNumber" ||
        key === "crossingChanges" ||
        key === "inspectedBy" ||
        key === "action"
      ) {
        return true;
      }
      if (
        filterType != "auto-complete" ||
        filterType != "multi-select" ||
        filterType != "single-auto-complete"
      ) {
        return false;
      }
      if (
        lookupData.value[key]?.length === 1 &&
        lookupData.value[key][0] === null
      ) {
        return true;
      }
      return !lookupData.value[key]?.length;
    };
    const updatePaginationPerPage = (event) => {
      paginationData.value.page = 1;
      paginationData.value.items = event;
      fetchData();
    };
    const pageChange = (event) => {
      paginationData.value.page = event;
      fetchData();
    };
    const sortUpdate = (event) => {
      if (event.length) {
        sortData.value = {
          key: event[0].key,
          order: event[0].order,
        };
      } else {
        sortData.value = null;
      }
      fetchData();
    };
    const clearAllFilter = (event) => {
      textFilter.value = [];
      multipleFilter.value = [];
      fetchData();
    };
    const textFilterChange = (event) => {
      let index = textFilter?.value?.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value && event.value.length > 0) {
          // Ensure value is not empty
          textFilter.value[index] = event;
        } else {
          textFilter.value.splice(index, 1);
        }
      } else if (event.value && event.value.length > 0) {
        textFilter.value.push(event);
      }
      fetchData(); // Continue to make API call only if the filter has valid values
    };
    const multipleFiltersChange = (event) => {
      let index = multipleFilter.value?.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value?.length) {
          multipleFilter.value[index] = event;
        } else {
          multipleFilter.value.splice(index, 1);
        }
      } else {
        multipleFilter.value.push(event);
      }
      fetchData();
    };

    const otherButtonClicked = (event) => {
      emit("buttonClicked", {
        addOrEdit: "view",
        selectedDOT: props?.dotId,
        selectedCrossingInspectionId: event?.crossingInspectionId,
      });
    };
    const editButtonClicked = (event) => {
      emit("buttonClicked", {
        addOrEdit: "edit",
        selectedDOT: props?.dotId,
        selectedCrossingInspectionId: event?.item?.crossingInspectionId,
      });
    };
    const linkClicked = (event) => {
      window.scrollTo(0, 0);
      router.push({ path: "/GCMS/" + event.value });
    };
    return {
      inspectionsList,
      loading,
      headers,
      updatePaginationPerPage,
      pageChange,
      sortUpdate,
      paginationData,
      textFilterChange,
      clearAllFilter,
      multipleFiltersChange,
      multipleFilter,
      lookupData,
      currentFilters,
      exportButtonClicked,
      userData,
      isInitiatedTableData,
      otherButtonClicked,
      editButtonClicked,
      linkClicked,
    };
  },
};
