import { CONFIG_ENDPOINT } from "@/constants/Endpoints";import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import DialogComponent from "@/modules/manageproject/components/PartiesOfRecordDialog.vue";
import DialogComponent2 from "@/modules/manageproject/components/deleteRailroadEstimateModal.vue";
import FeatureTable from "@/modules/shared/components/FeatureTable.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import sharedUserService from "@/modules/shared/service/sharedUserService";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "PartiesOfRecord",
  components: {
    FeatureTable,
    DialogComponent,
    DialogComponent2,
    PrimaryButton,
  },
  emits: ["updatePaginationPerPage", "pageChange", "sortUpdate"],
  setup() {
    const partiesOfRecord = ref([]);
    const addOrEdit = ref("");
    const dialogData = ref(null);
    const PartiesOfRecordDialog = ref(null);
    const deletePartyOfRecordDialogRef = ref(null);
    const route = useRoute();
    const id = route.params.id;
    const lookupData = ref(null);
    const userData = computed({
      get: () => {
        return sharedUserService.getSharedData();
      },
    });
    const headers = ref([
      {
        title: "First Name",
        sortType: "text",
        key: "firstName",
      },
      {
        title: "Last Name",
        filter: [],
        filterType: "auto-complete",
        filterSort: "text",
        sortType: "text",
        key: "lastName",
      },
      {
        title: "Organization",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
        key: "organization",
      },
      {
        title: "Title",
        key: "title",
        sortable: false,
      },
      {
        title: "Address1",
        key: "address1",
        sortable: false,
      },
      {
        title: "City",
        key: "city",
        sortable: false,
      },
      {
        title: "State",
        key: "stateCode",
        sortable: false,
      },
      {
        title: "ZIP",
        key: "zipcode",
        sortable: false,
      },
      {
        title: "Email",
        key: "email",
        sortable: false,
      },
      {
        title: "Action",
        key: "action",
        sortable: false,
        columnType: "action",
        isEdit: false,
        isDelete: false,
        width: "10%",
      },
    ]);
    const paginationData = ref({
      page: 1,
      items: 15,
      total: 0,
    });
    const sortData = ref({
      key: "firstName",
      order: "asc",
    });
    const textFilter = ref([]);
    const multipleFilter = ref([]);
    const currentFilters = ref(null);
    const isPartiesRecordUpdateAccess = ref({ read: false, update: false });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    onMounted(async () => {
      await getPartiesRecordAccess();
      if (isPartiesRecordUpdateAccess.value.read) {
        await fetchData();
      } else {
        partiesOfRecord.value = [];
        lookupData.value = null;
      }
    });
    const getPartiesRecordAccess = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.PARTIES_RECORD_PAGE_READ_ACCESS
      );
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.PARTIES_RECORD_PAGE_CRTUPD_ACCESS
      );
      isPartiesRecordUpdateAccess.value.read = readResponse;
      isPartiesRecordUpdateAccess.value.update = updateResponse;
      headers.value[headers.value.length - 1].isEdit =
        isPartiesRecordUpdateAccess.value.update;
      headers.value[headers.value.length - 1].isDelete =
        isPartiesRecordUpdateAccess.value.update;
    };
    const fetchLookupData = (filterText, Filter) => {
      axios
        .post(
          CONFIG_ENDPOINT.LOOKUPDATA_PARTIES_OF_RECORD + id + filterText,
          Filter
        )
        .then((response) => {
          lookupData.value = response.data;
          headers.value.forEach((header, index) => {
            headers.value[index] = {
              ...header,
              sortable: !checkFieldDisabled(header.key, header.filterType),
            };
          });
        })
        .catch((err) => {
          lookupData.value = null;
        });
    };
    async function fetchData() {
      let Filter = {
        lastName: null,
        organization: null,
      };
      textFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter.key) && filter.value) {
          Filter[filter.key] = filter.value;
        }
      });
      multipleFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter.key) && filter.value?.length) {
          Filter[filter.key] = filter.value;
        }
      });
      let filterText =
        "?page=" +
        (paginationData.value.page - 1) +
        "&size=" +
        paginationData.value.items;
      if (sortData.value) {
        filterText =
          filterText +
          "&sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order;
      }
      axios
        .post(CONFIG_ENDPOINT.GET_PARTIES_OF_RECORD + id + filterText, Filter)
        .then((response) => {
          partiesOfRecord.value = response.data.data;
          paginationData.value.total = response.data.totalElements;
          currentFilters.value = Filter;
          fetchLookupData(filterText, Filter);
        })
        .catch((err) => {
          console.log(err);
          partiesOfRecord.value = [];
          paginationData.value = {
            page: 1,
            items: paginationData.value.items,
            total: 0,
          };
        });
    }
    const updatePaginationPerPage = (event) => {
      paginationData.value.page = 1;
      paginationData.value.items = event;
      fetchData();
    };
    const pageChange = (event) => {
      paginationData.value.page = event;
      fetchData();
    };
    const sortUpdate = (event) => {
      if (event.length) {
        sortData.value = {
          key: event[0].key,
          order: event[0].order,
        };
      } else {
        sortData.value = null;
      }
      fetchData();
    };
    const clearAllFilter = (event) => {
      textFilter.value = [];
      multipleFilter.value = [];
      fetchData();
    };
    const editButtonClicked = (event) => {
      addOrEdit.value = "edit";
      let clone = {};
      clone = { ...event.item, ...clone };
      dialogData.value = clone;
      openDialog("Edit");
    };
    const deletepartiesOfRecord = (event) => {
      axios
        .delete(
          CONFIG_ENDPOINT.DELETE_PARTIES_OF_RECORD + event.partyOfRecordId
        )
        .then((response) => {
          fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const deleteButtonClicked = (event) => {
      let title = "Delete Party of Record";
      let message = "Are you sure you want to delete this Party of Record?";
      deletePartyOfRecordDialogRef.value
        .open(title, message, event)
        .then((resolve) => {
          if (resolve) {
            deletepartiesOfRecord(event);
          }
        });
    };
    const addButtonClicked = () => {
      addOrEdit.value = "add";
      dialogData.value = {
        firstName: "",
        lastName: "",
        middleName: "",
        organization: "",
        title: "",
        address1: "",
        address2: "",
        city: "",
        stateCode: "",
        zipcode: "",
        email: "",
        userID: userData.value?.userID,
      };
      openDialog("Add");
    };
    const openDialog = (dialogType) => {
      PartiesOfRecordDialog.value
        .open(
          dialogType + " Parties of Record",
          dialogData.value,
          dialogType,
          partiesOfRecord.value,
          id
        )
        .then((resolve) => {
          fetchData();
        });
    };
    const textFilterChange = (event) => {
      let index = textFilter?.value?.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value) {
          textFilter.value[index] = event;
        } else {
          textFilter.value.splice(index, 1);
        }
      } else {
        textFilter.value.push(event);
      }
    };
    const multipleFiltersChange = (event) => {
      let index = multipleFilter.value?.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value?.length) {
          multipleFilter.value[index] = event;
        } else {
          multipleFilter.value.splice(index, 1);
        }
      } else {
        multipleFilter.value.push(event);
      }
    };
    const checkFieldDisabled = (key, filterType) => {
      if (key === "action") {
        return true;
      }
      if (filterType != "auto-complete" || filterType != "multi-select") {
        return false;
      }
      return !lookupData.value[key]?.length;
    };
    return {
      partiesOfRecord,
      headers,
      updatePaginationPerPage,
      pageChange,
      sortUpdate,
      paginationData,
      textFilterChange,
      clearAllFilter,
      multipleFiltersChange,
      multipleFilter,
      addOrEdit,
      addButtonClicked,
      PartiesOfRecordDialog,
      id,
      openDialog,
      deleteButtonClicked,
      editButtonClicked,
      lookupData,
      userData,
      currentFilters,
      fetchLookupData,
      deletePartyOfRecordDialogRef,
      isPartiesRecordUpdateAccess,
    };
  },
};
