<template>
  <div class="d-flex flex-column date-picker">
   
    <v-text-field
      class="datePicker"
      variant="outlined"
      light
      :max="maxDate"
      :min="minDate"
      type="date"
      v-model="dateValue"
      @input="updateDate"
      :disabled="disabled"
    ></v-text-field>
    <div v-if="invalidDate && getInvalidMsg" class="error2 text-left w-100">
      <div>{{ getInvalidMsg }}</div>
    </div>
  </div>
</template>
<script>
import DatePicker from "@/modules/shared/scripts/DatePicker.js";
export default DatePicker;
</script>
<style>
@import "@/assets/css/style.scss";
</style>;
