import { CONFIG_ENDPOINT } from "@/constants/Endpoints";import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import { computed, onMounted, ref } from "vue";
let PartIIDetails = ref(null);

export default {
  name: "PartIIRailRoadInfo",
  props: {
    dotId: { default: null },
  },
  setup(props) {
    const isUserGradeCrossingAccess = ref({
      read: false,
    });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const gradeCrossingAccessCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.GRADE_CROSSING_RECORD_READ_ACCESS
      );
      isUserGradeCrossingAccess.value.read = readResponse || false;
    };

    onMounted(async () => {
      await gradeCrossingAccessCheck();
      if (isUserGradeCrossingAccess.value.read) {
        await getPartIIDetails();
      }
    });
    async function getPartIIDetails() {
      await axios
        .get(`${CONFIG_ENDPOINT.PARTII}${dotId.value}`)
        .then((response) => {
          setPartIIRailRoadInfo(response);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    const setPartIIRailRoadInfo = (response) => {
      PartIIDetails.value = response?.data;
      PartIIDetails.value.sections.forEach((section, index) => {
        section.data.forEach((fields, fieldIndex) => {
          PartIIDetails.value.sections[index].data[fieldIndex] = {
            ...PartIIDetails.value.sections[index].data[fieldIndex],
            number: fields?.label?.includes(". ")
              ? fields.label.split(". ")[0] + "."
              : "",
            label: fields?.label?.includes(". ")
              ? fields.label.split(". ")[1]
              : fields.label,
          };
        });
      });
    };
    const dotId = computed({
      get: () => {
        return props?.dotId;
      },
    });

    return {
      dotId,
      PartIIDetails,
      isUserGradeCrossingAccess,
    };
  },
};
