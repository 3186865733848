<template>
  <div class="expanderOverLay main-content">
    <div
      v-if="assignDistrictData && assignDistrictData.length > 0"
      class="railroadError"
      style="width: 100%; margin: 0"
    >
      <v-icon icon="mdi-alert"></v-icon>
      {{ assignDistrictData }}
    </div>
    <div class="w-full d-flex">
      <OnlineHelpPOC :helpKey="'DASH_HELP'"></OnlineHelpPOC>
    </div>
    <router-view></router-view>

    <div class="mt-20">
      <ProjectIdentifierLegend></ProjectIdentifierLegend>
    </div>

    <MyTasks></MyTasks>

    <RecentProjects></RecentProjects>
    <ActiveProjects></ActiveProjects>
  </div>
</template>
<script>
import RecentProjects from "@/modules/gcmsmain/dashboard/components/RecentProjects.vue";
import ActiveProjects from "@/modules/gcmsmain/dashboard/components/ActiveProjects.vue";
import MyTasks from "@/modules/gcmsmain/dashboard/components/MyTasks.vue";
import ProjectIdentifierLegend from "@/modules/shared/components/ProjectIdentifierLegend.vue";
import OnlineHelpPOC from "@/modules/shared/components/OnlineHelpPOC.vue";
import { onMounted, ref } from "vue";
import axios from "axios";
import { CONFIG_ENDPOINT } from "../constants/Endpoints";
export default {
  name: "DashboardView",

  components: {
    ActiveProjects: ActiveProjects,
    RecentProjects: RecentProjects,
    MyTasks: MyTasks,
    ProjectIdentifierLegend: ProjectIdentifierLegend,
    OnlineHelpPOC: OnlineHelpPOC,
  },
  setup() {
    const assignDistrictData = ref(null);
    onMounted(() => {
      axios
        .get(CONFIG_ENDPOINT.ASSIGN_DISTRICTS)
        .then((res) => {
          console.log("CONFIG_ENDPOINT.ASSIGN_DISTRICTS", res);
          assignDistrictData.value = res.data.data;
        })
        .catch((err) => {});
    });
    return {
      assignDistrictData,
    };
  },
};
</script>
