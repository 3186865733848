<template>
  <v-card class="p-64 v-card-text">
    <v-row>
      <v-col class="ml-20">
        <div @click="resetToTable" class="router-style">
          &lt; Return to DOT Details
        </div>
      </v-col>

      <v-col cols="3"></v-col>
    </v-row>
    <div class="env-infp mt-20">
      <div class="env-header">
        <h2 class="locationHeaderText">
          {{ toTitleCase(addOrEdit) }} Crossing Inspection Details
        </h2>
      </div>
      <v-form v-model="isFormValid" class="pa1Form env-content">
        <v-row class="mt-10">
          <v-col cols="8">
            <div class="mpms-env-label">
              <v-row>
                <v-col class="inspectionColumn" cols="3">
                  <b class="textColumn required"
                    ><span>Inspection Date: </span></b
                  >
                </v-col>
                <v-col cols="2">
                  <span v-if="addOrEdit === 'view'">{{
                    InspectionDetails.viewInspectionDate || "-"
                  }}</span>
                  <DatePicker
                    v-if="addOrEdit != 'view'"
                    :value="InspectionDetails.inspectionDate"
                    @input="dateChange($event)"
                    :invalidMsg="'Inspection Date'"
                    @dateValidation="checkDateValidation($event)"
                    :rules="['requiredRule', 'validDateFutureDate_Inspections']"
                  ></DatePicker>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-10">
          <v-col class="inspectionColumn" cols="2">
            <b class="textColumn required"><span>Inspection Type: </span></b>
          </v-col>
          <v-col
            class="mt20"
            :style="{ 'margin-left': addOrEdit === 'view' ? 0 : '10px' }"
            cols="2"
          >
            <span v-if="addOrEdit === 'view'">{{
              InspectionDetails.inspectionType || "-"
            }}</span>
            <v-row v-if="addOrEdit != 'view'">
              <v-select
              class="select-fields"
                outlined
                :rules="[requiredRule]"
                v-model="InspectionDetails.inspectionType"
              item-props="props"
              :items="[
                { title: 'Annual', value: 'Annual', props: { disabled: false } },
                { title: 'Construction', value: 'Construction', props: { disabled: InspectionLookup?.mpmsNumber.length === 0 } },
              ]"
              ></v-select>
            
            </v-row>
          </v-col>
          <v-col
            class="inspectionColumn"
            :class="{
              required: InspectionDetails.inspectionType === 'Construction',
            }"
            cols="2"
          >
            <b class="textColumn"><span>MPMS#: </span></b>
          </v-col>
          <v-col class="mt20" cols="1">
            <span v-if="addOrEdit === 'view'">{{
              InspectionDetails.mpmsNumber || "-"
            }}</span>
            <v-row v-if="addOrEdit != 'view'">
              <v-select
                class="select-fields"
                outlined
                v-model="InspectionDetails.mpmsNumber"
                :items="InspectionLookup?.mpmsNumber"
                :disabled="InspectionDetails.inspectionType != 'Construction'"
              ></v-select>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mt-10">
          <v-col cols="8">
            <div class="mpms-env-label">
              <v-row>
                <v-col class="inspectionColumn" cols="3">
                  <b class="textColumn required"
                    ><span>Crossing Changes: </span></b
                  >
                </v-col>
                <v-col cols="5">
                  <span v-if="addOrEdit === 'view'">{{
                    InspectionDetails.crossingChanges || "-"
                  }}</span>
                  <v-row v-if="addOrEdit != 'view'">
                    <v-radio-group
                      inline
                      v-model="InspectionDetails.crossingChanges"
                      :rules="[requiredRule]"
                    >
                      <v-radio
                        :label="crossingChange"
                        :value="crossingChange"
                        :key="crossingChange"
                        v-for="crossingChange in InspectionLookup?.crossingChanges"
                      ></v-radio>
                    </v-radio-group>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-10">
          <v-col cols="8">
            <div class="mpms-env-label">
              <v-row>
                <v-col class="inspectionColumn" cols="3">
                  <b class="textColumn required"><span>Inspected By: </span></b>
                </v-col>
                <v-col cols="5">
                  <span v-if="addOrEdit === 'view'">{{
                    InspectionDetails.inspectedBy || "-"
                  }}</span>
                  <v-text-field
                    v-if="addOrEdit != 'view'"
                    v-model="InspectionDetails.inspectedBy"
                    maxlength="75"
                    :rules="[requiredRule]"
                    @keydown="preventNumericInput($event)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-10">
          <v-col cols="12">
            <div class="mpms-env-label">
              <v-row>
                <v-col
                  class="inspectionColumn"
                  :class="{
                    required: InspectionDetails.crossingChanges === 'Yes',
                  }"
                  cols="2"
                >
                  <b class="textColumn"><span>Comments: </span></b>
                </v-col>
                <v-col cols="8" class="inspectionComment">
                  <v-container
                    class="white-textarea comment-box"
                    v-if="
                      addOrEdit != 'view' &&
                      InspectionDetails.crossingChanges != 'Yes'
                    "
                    ><v-textarea
                      :counter="commentMaxLength"
                      :maxlength="commentMaxLength"
                      :persistent-counter="true"
                      v-model="InspectionDetails.comments"
                      :spellcheck="true"
                      variant="solo"
                    ></v-textarea>

                    <span
                      v-if="InspectionDetails.comments || addOrEdit != 'view'"
                      >{{
                        commentMaxLength -
                        (InspectionDetails.comments?.length || 0)
                      }}
                      characters remaining.</span
                    > </v-container
                  ><v-container
                    class="white-textarea comment-box"
                    v-if="
                      addOrEdit != 'view' &&
                      InspectionDetails.crossingChanges === 'Yes'
                    "
                    ><v-textarea
                      :counter="commentMaxLength"
                      :maxlength="commentMaxLength"
                      :persistent-counter="true"
                      v-model="InspectionDetails.comments"
                      :rules="[requiredRule]"
                      :spellcheck="true"
                      variant="solo"
                    ></v-textarea>

                    <span
                      v-if="InspectionDetails.comments || addOrEdit != 'view'"
                      >{{
                        commentMaxLength -
                        (InspectionDetails.comments?.length || 0)
                      }}
                      characters remaining.</span
                    >
                  </v-container>
                  <span v-if="addOrEdit === 'view'">{{
                    InspectionDetails.comments || "-"
                  }}</span>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <div class="action" v-if="addOrEdit != 'view'">
            <PrimaryButton
              @buttonClicked="saveData"
              :disabled="!valueChanged || !finalValidation"
              :buttonText="'Save'"
            ></PrimaryButton>
            <SecondaryButton
              @buttonClicked="cancelData"
              :buttonText="'Cancel'"
              :disabled="!valueChanged"
              class="ml-2"
            ></SecondaryButton>
          </div>
        </v-row>
      </v-form>
    </div>
    <div>
      <div>
        <div class="env-infp">
          <div class="env-header">
            <h2 class="locationHeaderText">Inspection Documents</h2>
          </div>
        </div>
        <br /><br />

        <v-row cols="12">
          <v-col cols="12">
            <div class="view-upload-section" v-if="addOrEdit != 'view'">
              <span
                class="color-blue underline cursor-pointer"
                @click="otherButtonClicked()"
                >Upload Grade Crossing Inspection Documents</span
              >
            </div>
          </v-col>
        </v-row>
        <v-grid>
          <v-row justify="center">
            <v-col cols="11">
              <DocumentsList
                :heading="'Grade Crossing Inspection Document List'"
                :doctype="'/INSP_SHEET'"
                :id="id"
                :updateDocumentList="updateDocumentList"
                :hideAction="addOrEdit === 'view'"
                @updateDocumentListFlag="updateDocumentListFlag"
                @otherButtonClicked="updateButtonClicked($event)"
              ></DocumentsList>
            </v-col>
          </v-row>
        </v-grid>
        <br />
      </div>
    </div>
    <LoadingOverlay :isLoading="loading"> </LoadingOverlay>
    <DialogComponent
      ref="railroadEstimatesDocumentsDialogRef"
    ></DialogComponent>
  </v-card>
</template>

<script>
import InspectionR2AddEdit from "@/modules/dot-details/scripts/InspectionR2AddEdit.js";
export default InspectionR2AddEdit;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
</style>
