<template>
  <OnlineHelpPOC :helpKey="'DASH_HELP'"></OnlineHelpPOC>
  <div class="allProjectsHeading">Grade Crossing Inventory List</div>

  <v-card class="p-64">
    <v-form class="mpmsSubheader">
      <v-row class="createRow">
        <v-col>
          <label class="createLabel">Search DOT# : </label>
          <div class="createRow">
            <v-text-field
              v-model="dotValue"
              :rules="[mpmsRequiredRule, mpmsLengthRule]"
              @keydown="allowAlphanumericOnly($event)"
              @keydown.enter.prevent="submitForm"
              @keyup="removeError()"
              :maxlength="9"
              class="small-text-field2"
            ></v-text-field>
            <PrimaryButton
              style="padding-left: 15px !important"
              :buttonText="'Search'"
              @click="submitForm"
              :disabled="!dotValue || dotValue?.length === 0"
            ></PrimaryButton>
          </div>
          <div class="project-invalid-mpms mpmsError" v-show="showInvalidDot">
            DOT# {{ lastEnteredDotValue }} does not exist
          </div>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col>
        <FeatureTable
          :headers="headers"
          :items="inventorylist"
          :showExportButton="true"
          :exportFilName="'Grade Inventory List'"
          @linkClicked="linkClicked($event)"
          :clickable="true"
          :isGradeInventory="true"
          :itemsPerPage="paginationData?.items"
          :linkedPagination="true"
          :totalItems="paginationData?.total"
          :lookupData="lookupData"
          :isDynamicExport="true"
          @updatePaginationPerPage="updatePaginationPerPage"
          @pageChange="pageChange"
          @sortUpdate="sortUpdate"
          :showClearFilterButton="true"
          @textFilterChange="textFilterChange"
          @multipleFiltersChange="multipleFiltersChange"
          @clearAllFilter="clearAllFilter"
          :oldMultipleFilter="multipleFilter"
          :alignContentTopVertically="true"
          @exportButtonClicked="exportButtonClicked"
          :noDataAvailableText="noDataAvailableText"
        >
        </FeatureTable>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import GradeInventoryList from "@/modules/grade-crossing-and-bridges/scripts/GradeInventoryList.js";
export default GradeInventoryList;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
</style>
