<template>
  <v-card-text>
    <ConstructionPhase
      @BannerUpdate="$emit('BannerUpdate', $event)"
    ></ConstructionPhase>
  </v-card-text>
</template>
  <script>
import ConstructionPhase from "@/modules/manageproject/components/ConstructionPhase.vue";

export default {
  name: "ViewConstructionPhase-page",
  components: {
    ConstructionPhase: ConstructionPhase,
  },
};
</script>
  