<template>
  <v-dialog
    persistent
    @afterLeave="afterModalLeave"
    v-model="dialog"
    max-width="950px"
    max-height="3000px"
  >
    <div class="dialogheading">
      <div class="w-100 d-flex justify-content-between">
        <div class="w-90 text-center f-size24">
          {{ title }}
        </div>
        <div>
          <v-icon @click="closeDialog" class="cursor-pointer" small
            >mdi-close
          </v-icon>
        </div>
      </div>
      <v-card>
        <v-card-text>
          <v-form v-model="isFormValid">
            <v-row style="display: flex; align-items: center">
              <v-col>
                <v-label class="mpms-env-label" style="opacity:initial !important; padding-right:10px"
                ><b>Awarded Contractor Name:   </b> </v-label>
              </v-col>

              <v-col>
                <v-text-field v-model="formData.contractorName"></v-text-field>
              </v-col>

              <v-row
                style="display: flex; align-items: center; padding-right: 30px"
              >
                <v-col>
                  <v-label class="mpms-env-label" style="opacity:initial !important; padding-right:10px"
                  ><b>Awarded Contractor Cost:   </b> </v-label>
                </v-col>

                <v-col style="margin-bottom: 28px">
                  <CurrencyInput
                    class="estimateTextField"
                    v-model="formData.contractorCost"
                    maxlength="12"
                    :rules="[railroadEstimateRule]"
                  ></CurrencyInput>
                </v-col>
              </v-row>
            </v-row>
            <v-row style="display: flex; align-items: center" v-if="showError">
              <v-col></v-col><v-col></v-col>
              <v-row
                style="display: flex; align-items: center; padding-right: 30px"
              >
                <v-col></v-col>
                <v-col
                  style="margin-bottom: 30px; padding-left: 15px !important"
                  cols="8"
                >
                  <div
                    class="errorRow"
                    style="padding: 15px 0px 0px 40px !important"
                  >
                    <div class="error">
                      <div>Must be less than</div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-row>
            <v-row style="display: flex; align-items: center" v-if="showError">
              <v-col></v-col>
              <v-col></v-col>
              <v-row
                style="display: flex; align-items: center; padding-right: 30px"
              >
                <v-col> </v-col>
                <v-col
                  style="margin-bottom: 30px; padding-left: 12px !important"
                  cols="8"
                >
                  <div
                    class="errorRow"
                    style="padding: 12px 0px 0px 40px !important"
                  >
                    <div class="error">
                      <div>$999,999,999.99</div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <div class="action">
            <PrimaryButton
              @buttonClicked="saveData"
              :buttonText="'Save'"
              :disabled="
                !formData.contractorName ||
                !formData.contractorCost ||
                showError
              "
            ></PrimaryButton>
            <SecondaryButton
              @buttonClicked="closeDialog"
              :buttonText="'Cancel'"
              class="ml-2"
            ></SecondaryButton>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>
  <script>
import ContractorBidDialog from "@/modules/manageproject/scripts/ContractorBidDialog.js";

export default ContractorBidDialog;
</script>
  <style>
@import "@/modules/cost-and-funding/assets/ScopingRailRoadCostsDialog.scss";
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
@import "@/modules/manageproject/assets/projectFlaggerNeedsDialog.scss";
</style>
  