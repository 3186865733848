import { requiredRule } from "@/composables/validationRules";import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import LoadingOverlay from "@/modules/shared/components/LoadingOverlay.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import axios from "axios";
import { reactive, ref, toRefs, watch } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "AddUserToProjectDialog",
  components: { PrimaryButton, SecondaryButton, LoadingOverlay, requiredRule },
  setup(props) {
    let valueChanged = ref(false);
    const isInitialised = ref(false);
    const loading = ref(false);
    const route = useRoute();
    const id = route.params.id || props.id;
    const displayName = ref("");
    const state = reactive({
      dialog: false,
      resolve: null,
      reject: null,
    });
    const userNameList = ref([]);
    const showError = ref(false);
    const errorMessage = ref("");
    const open = () => {
      displayName.value = "";
      state.dialog = true;
      fetchLookupData();
      return new Promise((resolve, reject) => {
        state.resolve = resolve;
        state.reject = reject;
      });
    };
    watch(
      () => [displayName.value],
      () => {
        if (isInitialised.value && displayName.value) {
          valueChanged.value = true;
        } else {
          valueChanged.value = false;
        }
      }
    );
    const fetchLookupData = async () => {
      loading.value = true;
      await axios
        .get(CONFIG_ENDPOINT.ADD_USER_TO_PROJECT_LOOKUP)
        .then((response) => {
          userNameList.value = response.data.data;
          loading.value = false;
          setTimeout(() => {
            isInitialised.value = true;
          }, 1000);
        })
        .catch((err) => {
          userNameList.value = [];
          setTimeout(() => {
            isInitialised.value = true;
          }, 1000);
          loading.value = false;
        });
    };
    const addUser = async () => {
      showError.value = false;
      loading.value = true;
      const selectedUserDetails = userNameList.value.find(
        (user) => user.userId === displayName.value
      );
      console.log("selectedUserDetails", selectedUserDetails);
      const payload = { ...selectedUserDetails, mpmsNumber: id };
      axios
        .post(`${CONFIG_ENDPOINT.ADD_USER_TO_PROJECT}`, payload)
        .then((response) => {
          state.resolve(response);
          state.dialog = false;
          loading.value = false;
        })
        .catch((error) => {
          console.error("Error in addUser:", error);
          loading.value = false;
          showError.value = true;
          errorMessage.value =
            error.response.data.businessMessage || "Failed to add user";
        });
    };
    const closeDialog = () => {
      if (isInitialised.value && valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          state.resolve(false);
          state.dialog = false;
          valueChanged.value = false;
        }
      } else {
        state.resolve(false);
        state.dialog = false;
      }
    };
    const afterModalLeave = () => {
      valueChanged.value = false;
      isInitialised.value = false;
    };
    window.onbeforeunload = function () {
      if (isInitialised.value && valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };
    const getDropdownItemsMaxLength = () => {
      let list = userNameList.value?.map((item) => item?.displayName?.length);
      return Math.max(...list) * 8 <= 280
        ? "280px"
        : Math.max(...list) * 8 + "px";
    };
    return {
      ...toRefs(state),
      userNameList,
      getDropdownItemsMaxLength,
      open,
      addUser,
      closeDialog,
      loading,
      showError,
      errorMessage,
      requiredRule,
      id,
      displayName,
      afterModalLeave,
      valueChanged,
    };
  },
};
