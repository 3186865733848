<template>
  <v-card class="p-64 mb-50">
    <DataTable
      :headers="headers"
      :items="recentlyAccessedProjects"
      :hideFilter="true"
      :showExportButton="false"
      :showClearFilterButton="false"
      :hidePagination="true"
      :tableHeader="'My Most Recently Accessed Projects'"
      :navigateToMpmsDetails="true"
      :clickable="true"
      :navigationKey="'mpmsNumber'"
    >
    </DataTable>
  </v-card>
</template>

<script>
import RecentProjects from "@/modules/gcmsmain/dashboard/scripts/RecentProjects.js";
export default RecentProjects;
</script>

