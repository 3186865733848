import { default as isNumber } from "@/composables/fieldEntry";import useSharedFunctions from "@/composables/shared";
import {
  greaterThanEqualToZero,
  maxLengthRule1,
  maxLengthRule2,
  maxLengthRule999,
  minLengthRule1,
  minimumRule,
  numericRule,
  phoneRule,
  requiredRule,
  validateEmail,
  zipRule,
} from "@/composables/validationRules";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import AddressInput from "@/modules/shared/components/AddressInput.vue";
import BannerMessage from "@/modules/shared/components/BannerMessage.vue";
import EmailInput from "@/modules/shared/components/EmailInput.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import VueAutoComplete from "@/modules/shared/components/VueAutoComplete.vue";
import sharedUserService from "@/modules/shared/service/sharedUserService";
import { useProjectServiceStore } from "@/stores/projectService";
import axios from "axios";
import { computed, onMounted, reactive, ref, toRefs, watch } from "vue";
export default {
  components: {
    PrimaryButton,
    SecondaryButton,
    VueAutoComplete,
    BannerMessage,
    AddressInput,
    EmailInput,
  },
  props: {
    formData: {
      type: Object,
      default: {
        firstName: "",
        lastName: "",
        middleName: "",
        organization: "",
        title: "",
        address1: "",
        address2: "",
        city: "",
        stateCode: "",
        zipcode: "",
        email: "",
        userID: "",
        partyOfRecordId: "",
      },
    },
    id: { type: String, default: null },
    item: Object, // Item to be edited (if in 'edit' mode)    projectDetails: Object,
    itemIndex: "",
  },
  setup() {
    onMounted(async () => {
      await fetchStates();
    });

    const { checkAddressInputValidation, checkValidEmailcode } =
      useSharedFunctions();

    let isFormValid = ref(false);
    const projectServiceStore = useProjectServiceStore();
    const state = reactive({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      dialogType: null,
      formData: {},
      lookupData: null,
      partiesOfRecord: null,
    });
    let valueChanged = ref(false);
    const originalValue = ref();
    const isInitialised = ref(false);
    const open = (title, dialogFormData, dialogType, partiesOfRecord, mpms) => {
      state.dialog = true;
      state.title = title;
      state.formData = dialogFormData;
      originalValue.value = JSON.parse(JSON.stringify(dialogFormData));
      setTimeout(() => {
        isInitialised.value = true;
      }, 1000);
      state.dialogType = dialogType;
      state.partiesOfRecord = partiesOfRecord;
      id.value = mpms;
      fetchStates();
      return new Promise((resolve, reject) => {
        state.resolve = resolve;
        state.reject = reject;
      });
    };
    const id = ref(null);
    const showErrorBanner = ref(false);
    const states = ref([]);
    const userData = computed({
      get: () => {
        return sharedUserService.getSharedData();
      },
    });

    async function fetchStates() {
      let response = projectServiceStore.stateList;
      if (!response) {
        await projectServiceStore.retrieveStateList();
        response = projectServiceStore.stateList;
      }
      states.value = response?.data;
    }
    const isAddressFormValid = ref(false);
    const isEmailFormValid = ref(false);

    watch(
      () => [state.formData],
      () => {
        if (checkAddressInputValidation(state.formData)) {
          isAddressFormValid.value = true;
        } else {
          isAddressFormValid.value = false;
        }
        if (checkValidEmailcode(state.formData)) {
          isEmailFormValid.value = true;
        } else {
          isEmailFormValid.value = false;
        }
        if (isInitialised.value) {
          valueChanged.value = true;
        } else {
          valueChanged.value = false;
        }
      },
      { deep: true }
    );

    watch(
      () => [state.formData.email],
      () => {
        if (showErrorBanner) {
          hideBanner();
        }
      },
      { deep: true }
    );

    const saveData = () => {
      let object = {};
      if (state.dialogType == "Edit") {
        object = {
          firstName: state.formData.firstName,
          lastName: state.formData.lastName,
          middleName: state.formData.middleName,
          organization: state.formData.organization,
          title: state.formData.title,
          address1: state.formData.address1,
          address2: state.formData.address2,
          city: state.formData.city,
          stateCode: state.formData.stateCode,
          zipcode: state.formData.zipcode,
          email: state.formData.email,
          userID: userData.value.userID,
          partyOfRecordId: state.formData.partyOfRecordId,
          mpmsnumber: id.value,
        };
        UpdateParty(object);
      } else {
        object = {
          firstName: state.formData.firstName,
          lastName: state.formData.lastName,
          middleName: state.formData.middleName,
          organization: state.formData.organization,
          title: state.formData.title,
          address1: state.formData.address1,
          address2: state.formData.address2,
          city: state.formData.city,
          stateCode: state.formData.stateCode,
          zipcode: state.formData.zipcode,
          email: state.formData.email,
          userID: userData.value.userID,
          mpmsnumber: id.value,
        };
        AddParty(object);
      }
    };
    const AddParty = (value) => {
      axios
        .post(CONFIG_ENDPOINT.ADD_PARTIES_OF_RECORD + id.value, value)
        .then(() => {
          showErrorBanner.value = false;
          state.resolve({
            type: state.message,
            index: state.itemIndex,
          });

          state.dialog = false;
        })
        .catch((err) => {
          if (
            err.response.data.businessMessage ==
            "Party of Record email must be unique to the Parties of Record on the project"
          ) {
            showErrorBanner.value = true;
          }
        });
    };

    const UpdateParty = (value) => {
      axios
        .post(CONFIG_ENDPOINT.UPDATE_PARTIES_OF_RECORD + id.value, value)
        .then(() => {
          showErrorBanner.value = false;
          state.resolve({
            type: state.message,
            index: state.itemIndex,
          });

          state.dialog = false;
        })
        .catch((err) => {
          if (
            err.response.data.businessMessage ==
            "Party of Record email must be unique to the Parties of Record on the project"
          ) {
            showErrorBanner.value = true;
          }
        });
    };
    const closeDialog = () => {
      hideBanner();
      if (isInitialised.value && valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          valueChanged.value = false;
          state.resolve(false);
          state.dialog = false;
        }
      } else {
        state.resolve(false);
        state.dialog = false;
        valueChanged.value = false;
      }
    };

    const maskZip = () => {
      if (
        state.formData.zipcode.length == 6 &&
        !state.formData.zipcode.includes("-")
      ) {
        state.formData.zipcode =
          state.formData.zipcode.slice(0, 5) +
          "-" +
          state.formData.zipcode.slice(5, 6);
      }
    };

    const hideBanner = () => {
      showErrorBanner.value = false;
    };

    let typingValues = ref({
      zip: { stoppedTyping: null },
      email: { stoppedTyping: null },
    });

    const fieldValueChange = (field) => {
      typingValues.value[field].stoppedTyping = false;
    };
    const setFieldRule = (field) => {
      typingValues.value[field].stoppedTyping = true;
    };
    const getZipFieldRule = computed({
      get: () => {
        return typingValues.value["zip"].stoppedTyping;
      },
    });

    function createDebounce() {
      let timeout = null;
      return function (fnc, delayMs) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fnc();
        }, delayMs || 1000);
      };
    }

    const filterObject = (item, queryText, itemText) => {
      return item?.toLowerCase()?.startsWith(queryText?.toLowerCase());
    };

    function allowAlphaOnly(event) {
      const blockSpecialCharacterRegex = /^[A-Z\s]+$/i;
      let key = event.key ? event.key : String.fromCharCode(event.code);
      if (!blockSpecialCharacterRegex.test(key)) {
        event.preventDefault();
        return false;
      }
    }

    const afterModalLeave = () => {
      valueChanged.value = false;
      isInitialised.value = false;
      originalValue.value = null;
    };
    window.onbeforeunload = function () {
      if (isInitialised.value && valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };
    return {
      ...toRefs(state),
      fieldValueChange,
      setFieldRule,
      getZipFieldRule,
      debounce: createDebounce(),
      isFormValid,
      maxLengthRule1,
      maxLengthRule2,
      maxLengthRule999,
      minimumRule,
      minLengthRule1,
      requiredRule,
      numericRule,
      greaterThanEqualToZero,
      saveData,
      AddParty,
      UpdateParty,
      closeDialog,
      open,
      isNumber,
      maskZip,
      validateEmail,
      hideBanner,
      phoneRule,
      zipRule,
      id,
      showErrorBanner,
      states,
      userData,
      projectServiceStore,
      filterObject,
      AddressInput,
      allowAlphaOnly,
      isAddressFormValid,
      isEmailFormValid,
      afterModalLeave,
      valueChanged,
    };
  },
};
