import isNumber from "@/composables/fieldEntry";import useSharedFunctions from "@/composables/shared";
import {
  greaterThanEqualToZero,
  maxLengthRule1,
  maxLengthRule2,
  maxLengthRule999,
  minLengthRule1,
  minimumRule,
  numericRule,
  phoneRule,
  requiredRule,
  validateEmail,
  zipRule,
} from "@/composables/validationRules";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import EmailInput from "@/modules/shared/components/EmailInput.vue";
import PhoneInput from "@/modules/shared/components/PhoneInput.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import VueAutoComplete from "@/modules/shared/components/VueAutoComplete.vue";
import ZIPInput from "@/modules/shared/components/ZIPInput.vue";

import axios from "axios";
import { reactive, ref, toRefs, watch } from "vue";
export default {
  components: {
    PrimaryButton,
    SecondaryButton,
    VueAutoComplete,
    EmailInput,
    PhoneInput,
    ZIPInput,
  },
  props: {
    formData: {
      type: Object,
      default: {
        districtOfficeId: "",
        address1: "",
        address2: "",
        city: "",
        displayDistrict: "",
        notificationEmail: "",
        phone: "",
        zipcode: "",
        emails: [],
        dcmEmail1: "",
        dcmEmail2: "",
        dcmEmail3: "",
      },
    },
    id: { type: String, default: null },
    item: Object, // Item to be edited (if in 'edit' mode)    projectDetails: Object,
    itemIndex: "",
  },
  setup() {
    const { checkValidZipcode, districtOfficeEmailCheck, checkValidPhoneCode } =
      useSharedFunctions();
    let isFormValid = ref(false);
    const state = reactive({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      itemIndex: null,
      formData: {},
    });
    let valueChanged = ref(false);
    const originalValue = ref(null);
    const isInitialised = ref(false);
    const open = (title, formData, itemIndex) => {
      state.dialog = true;
      state.title = title;
      state.formData = formData;
      originalValue.value = JSON.parse(JSON.stringify(state.formData));
      setTimeout(() => {
        isInitialised.value = true;
      }, 1000);
      state.itemIndex = itemIndex;
      return new Promise((resolve, reject) => {
        state.resolve = resolve;
        state.reject = reject;
      });
    };

    const saveData = () => {
      let object = {
        districtOfficeId: state.formData.districtOfficeId,
        districtNumber: state.formData.districtOfficeId,
        address1: state.formData.address1,
        address2: state.formData.address2,
        city: state.formData.city,
        state: "PA",
        zipcode: state.formData.zipcode,
        notificationEmail: state.formData.notificationEmail,
        emails: [
          {
            districtOfficeEmailId: state.formData.emails[0]
              ? state.formData.emails[0].districtOfficeEmailId
              : -1,
            dcmEmail: state.formData.emails[0]
              ? state.formData.emails[0].dcmEmail
              : state.formData.dcmEmail1,
          },
          {
            districtOfficeEmailId: state.formData.emails[1]
              ? state.formData.emails[1].districtOfficeEmailId
              : -1,
            dcmEmail: state.formData.emails[1]
              ? state.formData.emails[1].dcmEmail
              : state.formData.dcmEmail2,
          },
          {
            districtOfficeEmailId: state.formData.emails[2]
              ? state.formData.emails[2].districtOfficeEmailId
              : -1,
            dcmEmail: state.formData.emails[2]
              ? state.formData.emails[2].dcmEmail
              : state.formData.dcmEmail3,
          },
        ],
        emailStrings: [
          null,
          "MANDREAS@pa.gov",
          "smoehler@pa.gov",
          "ecarll@pa.gov",
        ],
        phone: state.formData.phone,
        displayDistrict: null,
        createdBy: null,
        createdTs: null,
        lastModifiedBy: null,
        lastModifiedTs: null,
      };
      PutData(object);
    };
    const PutData = (value) => {
      axios
        .put(
          CONFIG_ENDPOINT.EDIT_DISTRICT_OFFICE_ADMIN +
            state.formData.districtOfficeId,
          value
        )
        .then(() => {
          state.resolve({
            type: state.message,
            index: state.itemIndex,
          });

          state.dialog = false;
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const closeDialog = () => {
      if (isInitialised.value && valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          state.resolve(false);
          state.dialog = false;
          valueChanged.value = false;
        }
      } else {
        state.resolve(false);
        state.dialog = false;
        valueChanged.value = false;
      }
    };
    const isAddressFormValid = ref(false);
    const isEmailFormValid = ref(false);
    const isPhoneFormValid = ref(false);

    watch(
      () => [state.formData],
      () => {
        if (checkValidZipcode(state.formData)) {
          isAddressFormValid.value = true;
        } else {
          isAddressFormValid.value = false;
        }
        if (districtOfficeEmailCheck(state.formData)) {
          isEmailFormValid.value = true;
        } else {
          isEmailFormValid.value = false;
        }
        if (checkValidPhoneCode(state.formData.phone)) {
          isPhoneFormValid.value = true;
        } else {
          isPhoneFormValid.value = false;
        }
        if (
          isInitialised.value &&
          !objectsAreSame(originalValue.value, state.formData)
        ) {
          valueChanged.value = true;
        } else {
          valueChanged.value = false;
        }
      },
      { deep: true }
    );
    const objectsAreSame = (x, y) => {
      let objectsAreSame = true;
      let keysToCheck = [
        "address1",
        "address2",
        "city",
        "zipcode",
        "notificationEmail",
        "phone",
        "emails[0].dcmEmail",
        "dcmEmail1",
        "emails[1].dcmEmail",
        "dcmEmail2",
        "emails[2].dcmEmail",
        "dcmEmail3",
      ];
      for (let key of keysToCheck) {
        if (x[key] !== y[key]) {
          objectsAreSame = false;
          break;
        }
      }
      return objectsAreSame;
    };
    const afterModalLeave = () => {
      valueChanged.value = false;
      isInitialised.value = false;
      originalValue.value = JSON.parse(
        JSON.stringify({
          districtOfficeId: state.formData?.districtOfficeId,
          address1: state.formData?.address1,
          address2: state.formData?.address2,
          city: state.formData?.city,
          displayDistrict: state.formData?.displayDistrict,
          notificationEmail: state.formData?.notificationEmail,
          phone: state.formData?.phone,
          zipcode: state.formData?.zipcode,
          emails: state.formData?.emails,
          dcmEmail1: state.formData?.dcmEmail1,
          dcmEmail2: state.formData?.dcmEmail2,
          dcmEmail3: state.formData?.dcmEmail3,
        })
      );
    };
    window.onbeforeunload = function () {
      if (isInitialised.value && valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };

    return {
      ...toRefs(state),
      isFormValid,
      maxLengthRule1,
      maxLengthRule2,
      maxLengthRule999,
      minimumRule,
      minLengthRule1,
      requiredRule,
      numericRule,
      greaterThanEqualToZero,
      saveData,
      PutData,
      closeDialog,
      open,
      isNumber,
      validateEmail,
      phoneRule,
      zipRule,
      isAddressFormValid,
      isEmailFormValid,
      isPhoneFormValid,
      afterModalLeave,
    };
  },
};
