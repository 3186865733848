import useFieldEntry from "@/composables/fieldEntry";
import useSharedFunctions from "@/composables/shared";
import {
  greaterThanEqualToZero,
  maxLengthRule1,
  maxLengthRule2,
  maxLengthRule999,
  minLengthRule1,
  minimumRule,
  numericRule,
  phoneRule,
  requiredRule,
  validateEmail,
  zipRule,
} from "@/composables/validationRules";
import DialogComponent from "@/modules/gcmsmain/admin/submenu/components/AddEditSAPSuffixesModal.vue";
import DialogComponent2 from "@/modules/manageproject/components/deleteRailroadEstimateModal.vue";
import AddressInput from "@/modules/shared/components/AddressInput.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import VueTable from "@/modules/shared/components/VueTable.vue";
import axios from "axios";
import { computed, onMounted, ref, watch } from "vue";
import { onBeforeRouteLeave, useRoute } from "vue-router";
import { CONFIG_ENDPOINT } from "../../../../../constants/Endpoints";

export default {
  name: "RailroadCompanyInformationForm",
  components: {
    VueTable,
    PrimaryButton,
    SecondaryButton,
    DialogComponent,
    DialogComponent2,
    AddressInput,
  },
  emits: ["BannerUpdate", "saveClicked"],
  setup(props, { emit }) {
    const { checkAddressInputValidation } = useSharedFunctions();
    const deleteSapSuffixDialogRef = ref(null);
    const { keydownAllowNumberOnly } = useFieldEntry();
    const route = useRoute();
    const addOrEdit = route.params.action;
    const railroadId = route.params.id;
    const dialogData = ref(null);
    let sapDetails = ref([]);
    const AddEditSAP = ref(null);
    const formData = ref({
      railroadCompanyId: null,
      railroadCompanyCode: null,
      federalId: null,
      name: null,
      sapVendorId: null,
      address1: null,
      address2: null,
      city: null,
      stateCode: null,
      zipcode: null,
      isActive: "Y",
      isBusinessPartner: null,
      isPermDelete: null,
      bpid: null,
      notificationResourceAccount: null,
    });
    let valueChanged = ref(false);
    const originalValue = ref({
      railroadCompanyId: null,
      railroadCompanyCode: null,
      federalId: null,
      name: null,
      sapVendorId: null,
      address1: null,
      address2: null,
      city: null,
      stateCode: null,
      zipcode: null,
      isActive: "Y",
      isBusinessPartner: null,
      isPermDelete: null,
      bpid: null,
      notificationResourceAccount: null,
    });
    const isInitialised = ref(false);
    const isFormValid = ref(false);
    const lookupData = ref([]);
    let tab = ref("railroadInformation");

    let headers = ref([
      {
        name: "SAP Suffix",
        key: "suffix",
        value: "suffix",
      },
      {
        name: "Address 1",
        key: "address1",
        value: "address1",
      },
      { name: "Address 2", key: "address2", value: "address2" },
      { name: "City", key: "city", value: "city" },
      {
        name: "State",
        key: "stateCode",
        value: "stateCode",
      },
      {
        name: "ZIP",
        key: "zipCode",
        value: "zipCode",
      },
      {
        name: "Action",
        key: "",
        isAction: true,
        isAdd: false,
        isEdit: true,
        isDelete: true,
      },
    ]);

    const isAddressFormValid = ref(false);

    watch(
      () => [formData.value],
      () => {
        if (checkAddressInputValidation(formData.value)) {
          isAddressFormValid.value = true;
        } else {
          isAddressFormValid.value = false;
        }
        if (
          isInitialised.value &&
          !objectsAreSame(originalValue.value, formData.value)
        ) {
          valueChanged.value = true;
        } else {
          valueChanged.value = false;
        }
      },
      { deep: true }
    );

    const objectsAreSame = (x, y) => {
      let objectsAreSame = true;
      let checkKeys = [
        "railroadCompanyId",
        "railroadCompanyCode",
        "federalId",
        "name",
        "sapVendorId",
        "address1",
        "address2",
        "city",
        "stateCode",
        "zipcode",
        "isActive",
        "isBusinessPartner",
        "isPermDelete",
        "bpid",
        "notificationResourceAccount",
      ];
      for (let i of checkKeys){
        if (x?.[i] !== y?.[i]) {
          objectsAreSame = false;
          break;
        }
      }
      return objectsAreSame;
    };
    onMounted(() => {
      axios.get(CONFIG_ENDPOINT.RAILROAD_COMPANY_LOOKUP_DATA).then((res) => {
        lookupData.value = res.data;
      });
      if (route.params.action === "edit") {
        editSetup();
      } else {
        setTimeout(() => {
          isInitialised.value = true;
        }, 1000);
      }
    });
    const editSetup = () => {
      formData.value = {
        railroadCompanyId: null,
        railroadCompanyCode: null,
        federalId: null,
        name: null,
        sapVendorId: null,
        address1: null,
        address2: null,
        city: null,
        stateCode: null,
        zipcode: null,
        isActive: "Y",
        isBusinessPartner: null,
        isPermDelete: null,
        bpid: null,
        notificationResourceAccount: null,
      };
      originalValue.value = JSON.parse(
        JSON.stringify({
          railroadCompanyId: null,
          railroadCompanyCode: null,
          federalId: null,
          name: null,
          sapVendorId: null,
          address1: null,
          address2: null,
          city: null,
          stateCode: null,
          zipcode: null,
          isActive: "Y",
          isBusinessPartner: null,
          isPermDelete: null,
          bpid: null,
          notificationResourceAccount: null,
        })
      );
      fetchData();
    };

    const fetchData = () => {
      axios
        .get(CONFIG_ENDPOINT.GET_RAILROAD_LIST + "/" + route.params.id)
        .then((res) => {
          if (res) {
            formData.value = res.data;
            originalValue.value = JSON.parse(JSON.stringify(res.data));
            sapDetails.value = res.data.sapSuffix;
            setTimeout(() => {
              isInitialised.value = true;
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const openDialog = (action, item) => {
      if (action == "Add") {
        dialogData.value = {
          sapSuffixId: "",
          sapVendorId: formData.value.sapVendorId,
          railroadcompanyid: formData.value.railroadCompanyId,
          suffix: "",
          address1: "",
          address2: "",
          city: "",
          stateCode: "",
          zipcode: "",
        };
        AddEditSAP.value
          .open("Add SAP Suffix", dialogData.value, "Add")
          .then((resolve) => {
            fetchData();
          });
      } else {
        dialogData.value = {
          sapSuffixId: item.item.sapSuffixId,
          sapVendorId: formData.value.sapVendorId,
          railroadcompanyid: formData.value.railroadCompanyId,
          suffix: item.item.suffix,
          address1: item.item.address1,
          address2: item.item.address2,
          city: item.item.city,
          stateCode: item.item.stateCode,
          zipcode: item.item.zipCode,
        };
        AddEditSAP.value
          .open("Edit SAP Suffix", dialogData.value, "Edit")
          .then((resolve) => {
            fetchData();
          });
      }
    };

    const maskZip = () => {
      if (
        formData.value.zipcode.length == 6 &&
        !formData.value.zipcode.includes("-")
      ) {
        formData.value.zipcode =
          formData.value.zipcode.slice(0, 5) +
          "-" +
          formData.value.zipcode.slice(5, 6);
      }
    };
    const saveButtonClicked = () => {
      emit("BannerUpdate", {
        bannerType: "success",
        bannerMessage: "Saved Successfully.",
        showBanner: false,
      });
      emit("BannerUpdate", {
        bannerType: "error",
        bannerMessage: "",
        showBanner: false,
        hasList: false,
        errorList: [],
      });
      if (route.params.action === "edit") {
        axios
          .put(CONFIG_ENDPOINT.EDIT_NEW_RAILROAD_COMPANY, formData.value)
          .then((response) => {
            scroll(0, 0);
            emit("BannerUpdate", {
              bannerType: "success",
              bannerMessage: "Saved Successfully.",
              showBanner: true,
            });
          })
          .catch((err) => {
            console.log(err);
            scroll(0, 0);
            emit("BannerUpdate", {
              bannerType: "error",
              bannerMessage: err.response.data.businessMessage,
              showBanner: true,
              hasList: false,
              errorList: [],
            });
          });
      } else {
        axios
          .post(CONFIG_ENDPOINT.ADD_NEW_RAILROAD_COMPANY, formData.value)
          .then((response) => {
            scroll(0, 0);
            emit("saveClicked", true);
            emit("BannerUpdate", {
              bannerType: "success",
              bannerMessage: "Saved Successfully.",
              showBanner: true,
            });
          })
          .catch((err) => {
            console.log(err);
            scroll(0, 0);
            emit("BannerUpdate", {
              bannerType: "error",
              bannerMessage: err.response.data.businessMessage,
              showBanner: true,
              hasList: false,
              errorList: [],
            });
          });
      }
      valueChanged.value = false;
      isInitialised.value = false;
    };
    const CancelButtonClicked = () => {
      emit("BannerUpdate", {
        bannerType: "success",
        bannerMessage: "Saved Successfully.",
        showBanner: false,
      });
      emit("BannerUpdate", {
        bannerType: "error",
        bannerMessage: "",
        showBanner: false,
        hasList: false,
        errorList: [],
      });
      formData.value = {
        railroadCompanyId: null,
        railroadCompanyCode: null,
        federalId: null,
        name: null,
        sapVendorId: null,
        address1: null,
        address2: null,
        city: null,
        stateCode: null,
        zipcode: null,
        isActive: "Y",
        isBusinessPartner: null,
        isPermDelete: null,
        bpid: null,
        notificationResourceAccount: null,
      };
      valueChanged.value = false;
      isInitialised.value = false;
      if (route.params.action === "edit") {
        editSetup();
      }
    };
    const deleteSapSuffixCall = (event) => {
      let title = "Delete SAP Suffix";
      let message = "Are you sure you want to delete this SAP Suffix?";

      deleteSapSuffixDialogRef.value.open(title, message).then((resolve) => {
        if (resolve) {
          deleteSapSuffix(sapDetails.value[event]);
        }
      });
    };
    const filterObject = (item, queryText, itemText) => {
      return item?.toLowerCase()?.startsWith(queryText?.toLowerCase());
    };
    let typingValues = ref({
      zip: { stoppedTyping: null },
      email: { stoppedTyping: null },
    });
    const fieldValueChange = (field) => {
      typingValues.value[field].stoppedTyping = false;
    };
    const setFieldRule = (field) => {
      typingValues.value[field].stoppedTyping = true;
    };
    const getZipFieldRule = computed({
      get: () => {
        return typingValues.value["zip"].stoppedTyping;
      },
    });

    function createDebounce() {
      let timeout = null;
      return function (fnc, delayMs) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fnc();
        }, delayMs || 1000);
      };
    }

    const deleteSapSuffix = (event) => {
      axios
        .delete(CONFIG_ENDPOINT.DELETE_SAP_SUFFIXES + event.sapSuffixId)
        .then((response) => {
          fetchData();
        });
    };
    onBeforeRouteLeave((to, from, next) => {
      if (isInitialised.value && valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          next();
          valueChanged.value = false;
        } else {
          next(false);
        }
      } else {
        next();
        valueChanged.value = false;
      }
    });
    window.onbeforeunload = function () {
      if (isInitialised.value && valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };
    return {
      filterObject,
      addOrEdit,
      railroadId,
      tab,
      headers,
      openDialog,
      deleteSapSuffixCall,
      saveButtonClicked,
      CancelButtonClicked,
      requiredRule,
      greaterThanEqualToZero,
      maxLengthRule1,
      maxLengthRule2,
      maxLengthRule999,
      minLengthRule1,
      minimumRule,
      numericRule,
      phoneRule,
      validateEmail,
      zipRule,
      formData,
      isFormValid,
      maskZip,
      keydownAllowNumberOnly,
      lookupData,
      debounce: createDebounce(),
      setFieldRule,
      getZipFieldRule,
      fieldValueChange,
      sapDetails,
      dialogData,
      AddEditSAP,
      deleteSapSuffixDialogRef,
      isAddressFormValid,
    };
  },
};
