<template>
  <div class="expanderOverLay">
    <DistrictOfficeList></DistrictOfficeList>
  </div>
</template>
<script>
import DistrictOfficeList from "@/modules/gcmsmain/admin/submenu/components/DistrictOfficeList.vue";
export default {
  name: "ViewDistrictOfficeList",
  components: {
    DistrictOfficeList: DistrictOfficeList,
  },
};
</script>
