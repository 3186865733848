<template>
  <v-dialog
    id="dialog"
    persistent
    @afterLeave="afterModalLeave"
    v-model="dialog"
    max-width="950px"
    max-height="4000px"
  >
    <div class="dialogheading">
      <div class="w-100 d-flex justify-content-between">
        <div class="w-90 text-center f-size24">
          {{ title }}
        </div>
        <div>
          <v-icon @click="closeDialog" class="cursor-pointer" small
            >mdi-close
          </v-icon>
        </div>
      </div>
      <v-card v-if="formData">
        <v-card-text style="max-height: 500px; overflow-y: scroll">
          <v-form v-model="isFormValid" class="district-office-form">
            <v-grid>
              <br />

              <v-row class="dialogRowParty" cols="12">
                <v-col class="dialogLabeltextParty" cols="2">
                  <label>SAP Vendor: </label>
                </v-col>
                <v-col class="dialogLabeltextParty" cols="4">
                  <label> {{ formData.sapVendorId }}</label>
                </v-col>
              </v-row>
              <br /><br />
              <v-row class="dialogRowParty" cols="12">
                <v-col class="dialogLabeltextParty" cols="2">
                  <label>SAP Suffix: </label>
                  <label class="asterisk">*</label>
                </v-col>
                <v-col class="dialogLabeltextParty" cols="4">
                  <v-text-field
                    v-model="formData.suffix"
                    :rules="[requiredRule]"
                    @keydown="allowAlphanumericOnly($event)"
                    type="text"
                    min="0"
                    maxlength="3"
                  >
                  </v-text-field>
                  <v-row v-if="showErrorBanner">
                    <v-col cols="12" class="ml-2">
                      <div
                        class="errorRow"
                        style="padding: 16px 8px 0px 5px !important"
                      >
                        <div class="error">
                          <div>SAP Suffix must be unique to Railroad</div>
                        </div>
                      </div>
                      <br /> </v-col
                  ></v-row>
                </v-col>
              </v-row>
              <AddressInput
                :formData="formData"
                :lookupData="states"
                :isPage="false"
              >
              </AddressInput>
            </v-grid>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <div class="action">
            <PrimaryButton
              @buttonClicked="saveData"
              :buttonText="'Save'"
              :disabled="!isFormValid || !isAddressFormValid"
            ></PrimaryButton>
            <SecondaryButton
              @buttonClicked="closeDialog"
              :buttonText="'Cancel'"
              class="ml-2"
            ></SecondaryButton>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>
  <script>
import AddEditSAPSuffixesModal from "@/modules/gcmsmain/admin/submenu/scripts/AddEditSAPSuffixesModal.js";
export default AddEditSAPSuffixesModal;
</script>
  <style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
</style>
  