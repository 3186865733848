import { defineStore } from "pinia";
import { ref } from "vue";
export const useRefreshDataStore = defineStore("refreshData", () => {
  let refreshTabsData = ref(false);
  const setRefreshData = (value) => {
    refreshTabsData.value = value;
  };
  return {
    setRefreshData,
    refreshTabsData,
  };
});
