<template>
  <div class="d-flex justify-content-center flex-column">
    <div class="card1 w-100 flex justify-center">
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center w-50">
          <div class="w-7">
            <v-checkbox
              v-model="NoRailroadInvolvement"
              :disabled="disableCheckbox"
              @update:modelValue="updateNoRailroadInvolvement($event)"
            ></v-checkbox>
          </div>
          <span>No Railroad Involvement </span>
        </div>
        <div class="w-50 text-right">
          <router-link :to="`/GCMS/` + id + `/addRailroads`">
            Add Railroads to Impacted Crossings
          </router-link>
        </div>
      </div>
      <div class="d-flex justify-content-start">
        <div class="m-10">
          <h2 class="f-size18 f-montserrat tableHeader">{{ table1Title }}</h2>
        </div>
      </div>
      <div class="indicator-text" v-if="instruction1">
        <span>{{ instruction1 }}</span>
      </div>
      <div class="indicator-text" v-if="linkIndicator">
        <span><i class="fa fa-link"></i>&nbsp; {{ linkIndicator }}</span>
      </div>
      <v-table class="design-table">
        <thead>
          <tr>
            <th
              scope="col"
              v-for="header in table1Header"
              :key="header.name"
              :class="{
                ['w-' + (column?.columnWidth ? column?.columnWidth : '15')]:
                  column?.isAction,
              }"
            >
              {{ header.name }}
            </th>
          </tr>
        </thead>
        <draggable
          tag="tbody"
          item-key="name"
          class="dragArea list-group w-full"
          :list="list1"
          :group="{ name: 'people', pull: 'clone', put: true }"
          :sort="true"
          @change="logList1"
          :move="checkMove"
          draggable=".allowItemDrag"
          preventOnFilter="true"
        >
          <DataTableRowHandler
            :list="list1"
            :tableHeader="table1Header"
            :dragKey="dragKey"
            :dragDisableCheck="dragDisableCheck"
            :isFirstList="true"
            @addButtonClicked="$emit('addToList2', $event)"
            @deleteButtonClicked="$emit('deleteList1Clicked', $event)"
            @linkTextClicked="$emit('linkClicked', $event)"
          ></DataTableRowHandler>
        </draggable>
      </v-table>
    </div>
    <div class="card1 w-100 flex justify-center">
      <div class="m-10">
        <h2 class="f-size18 f-montserrat tableHeader">{{ table2Title }}</h2>
      </div>
      <div class="instruction-text" v-if="instruction2">{{ instruction2 }}</div>
      <v-table class="design-table">
        <thead>
          <tr>
            <th
              scope="col"
              v-for="header in table2Header"
              :key="header.name"
              :class="{ 'w-15': header?.isAction }"
            >
              {{ header.name }}
            </th>
          </tr>
        </thead>
        <draggable
          tag="tbody"
          item-key="name"
          class="dragArea list-group w-full"
          :list="list2"
          group="people"
          @change="logList2"
          :move="checkMoveList2"
          preventOnFilter="true"
        >
          <DataTableRowHandler
            :list="list2"
            :tableHeader="table2Header"
            :dragKey="dragKey"
            :dragDisableCheck="dragDisableCheck"
            :isFirstList="false"
            @addButtonClicked="$emit('addToList1', $event)"
            @linkTextClicked="$emit('linkClicked', $event)"
          ></DataTableRowHandler>
        </draggable>
      </v-table>
    </div>
  </div>
  <!-- Include the dialog component -->
  <DialogComponent ref="checkboxConfirmationDialog"></DialogComponent>
</template>
<script>
import DraggableTable from "@/modules/shared/scripts/DraggableTable.js";
export default DraggableTable;
</script>
<style>
@import "@/modules/shared/assets/draggableTable.scss";
@import "@/assets/css/style.scss";
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>