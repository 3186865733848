

<template>
    <div class="env-infp mb-20">
      <div class="env-header">
        <h2 class="locationHeaderText">Company Information</h2>
      </div>
    </div>

      <div class="mt-20">
        <br>
        <v-row cols="12" align="center" class="ml-0">
          <v-col cols="2"
            ><span>Company Name: </span></v-col
          >
          <v-col cols="3">
           <v-label class="vlabelText">{{ companyObject.companyName }}</v-label>
          </v-col>
        
        </v-row>
      </div>
      <div class="mt-20">
        <v-row cols="12" align="center" class="ml-0">
          <v-col cols="2"
            ><span>Address 1: </span></v-col
          >
          <v-col cols="3">
            <v-label class="vlabelText"> {{ companyObject.address1 }}</v-label>

          </v-col>
          <v-col cols="2"
            ><span>Address 2: </span></v-col
          >
          <v-col cols="3">
           <v-label class="vlabelText">{{ companyObject.address2 }}</v-label>
          </v-col>
        
        </v-row>
      </div> <div class="mt-20 mb-100">
        <v-row cols="12" align="center" class="ml-0">
          <v-col cols="2"
            ><span>City: </span></v-col
          >
          <v-col cols="3">
            <v-label class="vlabelText">{{ companyObject.city }}</v-label>

          </v-col>
          <v-col cols="2"
            ><span>State: </span></v-col
          >
          <v-col cols="2">
           <v-label class="vlabelText">{{ companyObject.state }}</v-label>
          </v-col>
          <v-col cols="1"
            ><span>ZIP: </span></v-col
          >
          <v-col cols="2">
            <v-label class="vlabelText">{{ companyObject.zip }}</v-label>

          </v-col>
        </v-row>
      </div>

      <br><br><br>



  </template>
  
  <script>
  import ConsultantCompanyInfo from "@/modules/gcmsmain/admin/submenu/scripts/ConsultantCompanyInfo.js";
  export default ConsultantCompanyInfo;
  </script>

  