import useDateField from "@/composables/dateField.js";
import { validDateFutureDate_Inspections } from "@/composables/validationRules";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import DatePicker from "@/modules/shared/components/DatePicker.vue";
import { useDocumentStore } from "@/stores/document";
import { useProjectServiceStore } from "@/stores/projectService";
import { onMounted, ref, watch } from "vue";
import { onBeforeRouteLeave, useRoute } from "vue-router";
import { MAX_DATE, MIN_DATE } from "../../../constants/dates";

export default {
  name: "ContractDevelopmentMilestones",
  components: { DatePicker },
  props: ["id", "refreshData"],
  emits: ["saveMilestone", "updateRefreshFlag"],

  setup(props, { emit }, _context) {
    const { getFormattedDateStringNoTime } = useDateField();
    const getDate = (date) => {
      return getFormattedDateStringNoTime(date);
    };
    const id = ref();
    const documentStore = useDocumentStore();
    const projectServiceStore = useProjectServiceStore();
    const route = useRoute();
    const milestones = ref({});
    let maxDate = ref(new Date(MAX_DATE).toLocaleDateString("fr-ca"));
    let minDate = ref(new Date(MIN_DATE).toLocaleDateString("fr-ca"));
    const allAgreementsResponseObject = ref({
      displayName: "All Agreements Executed",
      checkBoxApproved: false,
      railRoads: [],
    });

    watch(
      () => [props.id],
      () => {
        if (props.id) {
          id.value = props.id;
        }
      },
      { deep: true }
    );

    watch(
      () => [props.refreshData],
      () => {
        if (props.refreshData) {
          valueChanged.value = false;
          fetchData();
          emit("updateRefreshFlag", false);
        }
      },
      { deep: true }
    );
    onMounted(async () => {
      id.value = props.id;
      fetchData.value = props.fetchData;
      await fetchData();
    });

    const fetchDataHelper = (milestone) => {
      for (let rail in milestones.value[milestone].railRoads) {
        if (milestones.value[milestone].railRoads[rail].approvedDate) {
          milestones.value[milestone].railRoads[rail].approvedDate =
            milestones.value[milestone].railRoads[rail].approvedDate.replace(
              /(\d\d)\/(\d\d)\/(\d{4})/,
              "$3-$1-$2"
            );
        }
      }
    };

    async function fetchData() {
      let response =
        await projectServiceStore.fetchContractDevelopmentMilestones(
          route.params.id
        );

      if (response) {
        milestones.value = response.data;
        for (let milestone in milestones.value) {
          if (milestones.value[milestone].approvedDate) {
            milestones.value[milestone].approvedDate = milestones.value[
              milestone
            ].approvedDate.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2");
          }
          if (milestones.value[milestone].railRoads && milestones.value[milestone].displayName != 'NTP for Construction Issued ') {
            fetchDataHelper(milestone);
          }
        }
      }
    }
    const isDateValidated = ref(false);
    const checkDateValidation = (value) => {
      isDateValidated.value = value;
    };

    const constructPostObject = (temp, event) => {
      let name = temp.displayName;
      let newValue = true;

      for (let index in allAgreementsResponseObject.value.railRoads) {
        if (
          allAgreementsResponseObject.value.railRoads[index].displayName == name
        ) {
          let formatDate = getDate(event);
          allAgreementsResponseObject.value.railRoads[index].approvedDate =
            formatDate;
          newValue = false;
        }
      }

      if (newValue) {
        let formatDate = getDate(event);
        temp.approvedDate = formatDate;
        allAgreementsResponseObject.value.railRoads.push(temp);
      }
    };

    const dateChangeHelper = (event, index) => {
      if (event == "") {
        milestones.value[index].approvedDate = null;
        sendObject(event, index, "");
      } else {
        milestones.value[index].approvedDate = event;
        let temp = getDate(milestones.value[index].approvedDate);
        sendObject(event, index, temp);
      }
    };
    let valueChanged = ref(false);

    const dateChange = (event, index, isRailroad) => {
      if (isRailroad) {
        console.log("in there")
        console.log(milestones.value);
        console.log(event,index,isRailroad)
        if (event == "") {
          milestones.value["allAgreementsExecutedData"].railRoads[
            index
          ].approvedDate = null;
          let clone = {
            ...milestones.value["allAgreementsExecutedData"].railRoads[index],
          };
          constructPostObject(clone, event);
          sendObject(event, "agreements", "");
        } else {
          milestones.value["allAgreementsExecutedData"].railRoads[
            index
          ].approvedDate = event;

          let formatDate = getDate(event);

          let clone = {
            ...milestones.value["allAgreementsExecutedData"].railRoads[index],
          };
          constructPostObject(clone, formatDate);
          sendObject(event, "agreements", "");
        }
      } else {
        dateChangeHelper(event, index);
      }

      valueChanged.value = true;
    };
    onBeforeRouteLeave((to, from, next) => {
      if (valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          valueChanged.value = false;
          next();
        } else {
          next(false);
        }
      } else {
        valueChanged.value = false;
        next();
      }
    });
    window.onbeforeunload = function () {
      if (valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };

    const sendObject = (event, index, temp) => {
      let object = {};
      if (index == "sitandCirEfiledtoPUC") {
        object = {
          sitCircuitEfiledDate: !event ? "" : temp,
        };
      } else if (index == "sitandCirApprovedbyPUC") {
        object = {
          sitCircuitApprovedPUCDate: !event ? "" : temp,
        };
      } else if (index == "finalTrafficControlPlanApp"){
        object = {
          finalTrafficCtrllPlanAppDate: !event ? "" : temp,
        };
      }
      else if (index == "agreements") {
        object = {
          allAgrrementsExcecutedData: allAgreementsResponseObject.value,
        };
      }
      emit("saveMilestone", object);
    };

    const download = (docId) => {
      let endpoint = CONFIG_ENDPOINT.DOWNLOAD_SCOPING_FILE;
      let id = route.params.id;
      documentStore.downloadFile(docId, id, endpoint);
    };

    return {
      id,
      milestones,
      validDateFutureDate_Inspections,
      checkDateValidation,
      dateChange,
      getDate,
      download,
      sendObject,
      maxDate,
      minDate,
      allAgreementsResponseObject,
    };
  },
};
