<template>
  <div class="expanderOverLay">
    <AddUpdateRailroadCompany></AddUpdateRailroadCompany>
  </div>
</template>
<script>
import AddUpdateRailroadCompany from "@/modules/gcmsmain/admin/submenu/components/AddUpdateRailroadCompany.vue";
export default {
  name: "ViewAddUpdateRailroadCompany",
  components: {
    AddUpdateRailroadCompany: AddUpdateRailroadCompany,
  },
};
</script>
