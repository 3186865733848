<template>
  <div class="primary-button">
    <v-btn
      variant="text"
      @click="buttonClicked(item)"
      class="color-blue"
      :disabled="disabled"
      >{{ buttonText }}
      <v-icon small class="mr-2" v-if="iconButton"
        >{{ iconName }}
      </v-icon></v-btn
    >
  </div>
</template>
<script>
import PrimaryButton from "@/modules/shared/scripts/PrimaryButton.js";
export default PrimaryButton;
</script>
<style>
@import "@/modules/shared/assets/PrimaryButton.scss";
@import "@/assets/css/style.scss";
</style>
