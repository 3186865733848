import { CONFIG_ENDPOINT } from "@/constants/Endpoints";import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import BannerMessage from "@/modules/shared/components/BannerMessage.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import { computed, onMounted, ref, watch } from "vue";
import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
export default {
  name: "ConstructionPhase",
  components: { SecondaryButton, PrimaryButton, BannerMessage },
  props: ["dotId"],
  setup(props, { emit }) {
    const router = useRouter();
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const readOnlyAccess = ref(false);
    const updateAccess = ref(false);
    const showBannerMessage = ref(false);
    const checkAccess = async () => {
      readOnlyAccess.value = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.PROJECT_CROSSING_INVENTORY_UPDATES_READ_ACCESS
      );
      updateAccess.value = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.PROJECT_CROSSING_INVENTORY_UPDATES_CRTUPD_ACCESS
      );
    };
    const isButtonDisabled = computed(() => {
      return !valueChanged.value || readOnlyAccess.value || !updateAccess.value;
    });
    const isCheckboxDisabled = computed(() => {
      return readOnlyAccess.value || !updateAccess.value;
    });
    const constructionList = ref([]);
    const originalValue = ref([]);
    const valueChanged = ref(false);
    const isInitialised = ref(false);
    const loading = ref(true);
    const route = useRoute();
    const id = route.params.id;
    onMounted(async () => {
      await checkAccess();
      await fetchData();
    });
    watch(
      () => constructionList.value,
      () => {
        if (isInitialised.value) {
          const hasChanges = constructionList.value.some(
            (item, index) =>
              item.inventoryUpdated !==
              originalValue.value[index].inventoryUpdated
          );
          valueChanged.value = hasChanges;
        }
      },
      { deep: true }
    );
    async function fetchData() {
      loading.value = true;
      axios
        .get(CONFIG_ENDPOINT.GET_CONSTRUCTION_PHASE + id)
        .then((response) => {
          constructionList.value = response.data.data;
          originalValue.value = JSON.parse(JSON.stringify(response.data.data));
          isInitialised.value = true;
          valueChanged.value = false;
        })
        .catch((err) => {
          console.error("Error fetching construction data", err);
        })
        .finally(() => {
          loading.value = false;
        });
    }
    const saveData = () => {
      const payload = constructionList.value.filter(
        (item, index) =>
          item.inventoryUpdated !== originalValue.value[index].inventoryUpdated
      );
      if (payload.length) {
        axios
          .put(CONFIG_ENDPOINT.UPDATE_CONSTRUCTION_PHASE + id, payload)
          .then((res) => {
            const bannerMessage = res.data?.data;
            showBannerMessage.value = true;
            emit("BannerUpdate", {
              bannerType: "success",
              bannerMessage: bannerMessage,
              showBanner: showBannerMessage.value,
            });
            window.scrollTo(0, 0);
            fetchData();
          });
      }
    };
    const cancelClicked = () => {
      window.scrollTo(0, 0);
      constructionList.value = JSON.parse(JSON.stringify(originalValue.value));
      valueChanged.value = false;
      fetchData();
    };
    const hideBanner = () => {
      showBannerMessage.value = false;
      emit("BannerUpdate", {
        bannerType: "success",
        bannerMessage: "Saved Successfully.",
        showBanner: showBannerMessage.value,
      });
    };
    onBeforeRouteLeave((to, from, next) => {
      if (isInitialised.value && valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          valueChanged.value = false;
          showBannerMessage.value = false;
          emit("BannerUpdate", {
            bannerType: "success",
            bannerMessage: "",
            showBanner: showBannerMessage.value,
          });
          next();
        } else {
          next(false);
        }
      } else {
        valueChanged.value = false;
        showBannerMessage.value = false;
        emit("BannerUpdate", {
          bannerType: "success",
          bannerMessage: "",
          showBanner: showBannerMessage.value,
        });
        next();
      }
    });
    window.onbeforeunload = function () {
      if (isInitialised.value && valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };
    const dotLinkClicked = (dotNumber) => {
      window.scrollTo(0, 0);
      router.push({ path: "/GCMS/DOT/" + dotNumber });
    };
    return {
      dotLinkClicked,
      isButtonDisabled,
      isCheckboxDisabled,
      saveData,
      cancelClicked,
      constructionList,
      loading,
      valueChanged,
      id,
      readOnlyAccess,
      showBannerMessage,
      hideBanner,
    };
  },
};
