import useDateField from "@/composables/dateField.js";import useSharedFunctions from "@/composables/shared";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import FeatureTable from "@/modules/shared/components/FeatureTable.vue";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import { computed, onMounted, ref, watch } from "vue";

export default {
  name: "GradeCrossingHistory",
  components: { FeatureTable },
  props: {
    dotId: { default: null },
    tab: { default: "" },
  },
  setup(props) {
    let id = ref();
    watch(
      () => [props.id],
      () => {
        if (props.id) {
          id.value = props.id;
        }
      },
      { deep: true }
    );
    watch(
      () => [props.tab],
      () => {
        if (props.tab === "CrossingHistory") {
          fetchData();
        }
      },
      { deep: true }
    );
    onMounted(() => {
      id.value = props.id;
    });
    const lookupData = ref(null);
    const { formatDateTimewithSeconds } = useDateField();
    const getDate = (historyDate) => {
      return formatDateTimewithSeconds(historyDate);
    };
    let inventoryHistorylist = ref([]);
    let loading = ref(true);
    const headers = ref([
      {
        title: "Date/Time",
        filter: [],
        filterType: "multi-select",
        filterSort: "date",
        filterWith: "yearMonth",
        sortType: "date",
        key: "historyDate",
      },
      {
        title: "Action",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        filterWith: "startWith",
        sortType: "text",
        key: "action",
      },
      {
        title: "New Data",
        filter: [],
        filterType: "multi-select",
        filterSort: "number",
        sortType: "number",
        key: "newData",
      },
      {
        title: "User",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
        key: "user",
      },
      {
        title: "User Role",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
        key: "userRole",
      },
      {
        title: "Company ",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
        key: "company",
      },
    ]);

    const paginationData = ref({
      page: 1,
      items: 15,
      total: 0,
    });
    const sortData = ref({
      key: "historyDate",
      order: "desc",
    });
    const textFilter = ref([]);
    const multipleFilter = ref([]);
    const currentFilters = ref(null);
    const { ExportToCSV } = useSharedFunctions();

    const isUserGradeCrossingAccess = ref({
      read: false,
    });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const gradeCrossingAccessCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.GRADE_CROSSING_RECORD_READ_ACCESS
      );

      isUserGradeCrossingAccess.value.read = readResponse || false;
    };

    onMounted(async () => {
      await gradeCrossingAccessCheck();
      if (isUserGradeCrossingAccess.value.read) {
        await fetchData();
      } else {
        loading.value = false;
        activeProjects.value = [];
        paginationData.value = {
          page: 1,
          items: paginationData.value.items,
          total: 0,
        };
      }
    });
    const getLookupData = (filterText, Filter) => {
      axios
        .post(
          CONFIG_ENDPOINT.CROSSINGS_HISTORY_LOOKUP + dotId.value + filterText,
          Filter
        )
        .then((response) => {
          lookupData.value = response.data;
          headers.value.forEach((header, index) => {
            headers.value[index] = {
              ...header,
              sortable: !checkFieldDisabled(header.key),
            };
          });
        })
        .catch((err) => {
          console.log(err);
        });
    };
    async function fetchData() {
      let Filter = {
        historyDate: null,
        action: null,
        newData: null,
        user: null,
        userRole: null,
        company: null,
      };
      textFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter.key)) {
          Filter[filter.key] = filter.value;
        }
      });
      multipleFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter.key)) {
          Filter[filter.key] = filter.value;
        }
      });
      let filterText =
        "?page=" +
        (paginationData.value.page - 1) +
        "&size=" +
        paginationData.value.items;
      if (sortData.value) {
        filterText =
          filterText +
          "&sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order;
      }
      loading.value = true;
      axios
        .post(
          CONFIG_ENDPOINT.CROSSINGS_HISTORY + dotId.value + filterText,
          Filter
        )
        .then((response) => {
          loading.value = false;
          inventoryHistorylist.value = response.data.data;
          inventoryHistorylist.value.forEach((item) => {
            item.historyDate = getDate(item.historyDate);
          });

          paginationData.value.total = response.data.totalElements;
          currentFilters.value = Filter;
          getLookupData(filterText, Filter);
        })
        .catch((err) => {
          loading.value = false;
          inventoryHistorylist.value = [];

          paginationData.value = {
            page: 1,
            items: paginationData.value.items,
            total: 0,
          };
        });
    }
    const exportButtonClicked = () => {
      let filterText = "?page=" + 0 + "&size=" + paginationData.value.total;
      if (sortData.value) {
        filterText =
          filterText +
          "&sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order;
      }
      axios
        .post(
          CONFIG_ENDPOINT.CROSSINGS_HISTORY + dotId.value + filterText,
          currentFilters.value
        )
        .then((response) => {
          loading.value = false;
          let exportDataList = response.data.data;
          exportDataList.forEach((item) => {
            item.historyDate = getDate(item.historyDate);
          });
          ExportToCSV(headers.value, exportDataList, "Grade Crossing History");
        })
        .catch((err) => {});
    };
    const checkFieldDisabled = (key) => {
      if (key === "action") {
        return true;
      }
      let availableData = inventoryHistorylist.value.filter(
        (data) => !data[key]
      );
      return availableData.length === inventoryHistorylist.value.length;
    };
    const dotId = computed({
      get: () => {
        return props?.dotId;
      },
    });
    const updatePaginationPerPage = (event) => {
      paginationData.value.page = 1;
      paginationData.value.items = event;
      fetchData();
    };
    const pageChange = (event) => {
      paginationData.value.page = event;
      fetchData();
    };
    const sortUpdate = (event) => {
      if (event.length) {
        sortData.value = {
          key: event[0].key,
          order: event[0].order,
        };
      } else {
        sortData.value = null;
      }
      fetchData();
    };
    const clearAllFilter = (event) => {
      textFilter.value = [];
      multipleFilter.value = [];
      fetchData();
    };
    const textFilterChange = (event) => {
      let index = textFilter?.value?.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value) {
          textFilter.value[index] = event;
        } else {
          textFilter.value.splice(index, 1);
        }
      } else {
        textFilter.value.push(event);
      }
    };
    const multipleFiltersChange = (event) => {
      let index = multipleFilter.value?.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value?.length) {
          multipleFilter.value[index] = event;
        } else {
          multipleFilter.value.splice(index, 1);
        }
      } else {
        multipleFilter.value.push(event);
      }
    };

    return {
      inventoryHistorylist,
      loading,
      headers,
      dotId,
      lookupData,
      paginationData,
      sortData,
      textFilter,
      multipleFilter,
      getLookupData,
      updatePaginationPerPage,
      pageChange,
      sortUpdate,
      getDate,
      clearAllFilter,
      textFilterChange,
      multipleFiltersChange,
      exportButtonClicked,
      isUserGradeCrossingAccess,
    };
  },
};
