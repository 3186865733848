import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import axios from "axios";
import { reactive, ref, toRefs, watch } from "vue";
import { useRoute } from "vue-router";
import { CONFIG_ENDPOINT } from "../../../constants/Endpoints";
export default {
  components: { PrimaryButton, SecondaryButton },
  setup() {
    const route = useRoute();
    const state = reactive({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      event: null,
    });
    let valueChanged = ref(false);
    const originalValue = ref();
    const isInitialised = ref(false);
    const open = (title, event) => {
      state.dialog = true;
      state.title = title;
      state.event = event;
      getLookupList(event);
      return new Promise((resolve, reject) => {
        state.resolve = resolve;
        state.reject = reject;
      });
    };
    const getLookupList = (event) => {
      axios
        .get(
          CONFIG_ENDPOINT.VIEW_RAILROAD_CONTACTS +
            event.railroadCompanyId +
            "?sort=lastName&direction=asc&mpmsnumber=" +
            route.params.id
        )
        .then((res) => {
          console.log("state.avent", state.event);
          let existingContacts = state?.event?.existingContact?.map(
            (contact) => contact?.railroadContactId
          );
          console.log("existingContacts", existingContacts);
          state.event = {
            ...event,
            contact: res?.data?.contact.map((currentContact) => {
              return {
                ...currentContact,
                checked: existingContacts?.includes(
                  currentContact?.railroadContactId
                ),
                projectRailroadInvolvementId: existingContacts?.includes(
                  currentContact?.railroadContactId
                )
                  ? getProjectRailroadInvolvementId(
                      currentContact?.railroadContactId
                    )
                  : currentContact?.projectRailroadInvolvementId,
              };
            }),
          };
          originalValue.value = JSON.parse(
            JSON.stringify(
              state.event?.contact
                ?.filter((contacts) => contacts?.checked)
                ?.map((contacts) => contacts.railroadContactId)
                ?.join(",")
            )
          );
          setTimeout(() => {
            isInitialised.value = true;
          }, 1000);
        })
        .catch((err) => {});
    };
    const getProjectRailroadInvolvementId = (railroadContactId) => {
      return state?.event?.existingContact?.filter(
        (contact) => contact?.railroadContactId === railroadContactId
      )?.[0]?.projectRailroadInvolvementId;
    };
    watch(
      () => [state?.event?.contact],
      () => {
        if (
          isInitialised.value &&
          originalValue.value !=
            state.event.contact
              ?.filter((contacts) => contacts?.checked)
              ?.map((contacts) => contacts.railroadContactId)
              ?.join(",")
        ) {
          valueChanged.value = true;
        } else {
          valueChanged.value = false;
        }
      },
      { deep: true }
    );
    const saveData = () => {
      console.log("saveData", state.event);
      let payload = state.event.contact.filter((res) => res.checked);
      axios
        .post(
          CONFIG_ENDPOINT.ADD_RAILROAD_CONTACTS_DIALOG +
            state.event.railroadCompanyId +
            "/" +
            route.params.id,
          payload
        )
        .then((res) => {
          state.resolve({ success: true });
          state.dialog = false;
          getLookupList(state.event);
        })
        .catch((err) => {
          state.resolve({ success: false, errorMsg: err });
          state.dialog = false;
        });
    };
    const closeDialog = () => {
      if (isInitialised.value && valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          state.resolve(false);
          state.dialog = false;
          valueChanged.value = false;
        }
      } else {
        state.resolve(false);
        state.dialog = false;
        valueChanged.value = false;
      }
    };
    const afterModalLeave = () => {
      valueChanged.value = false;
      isInitialised.value = false;
      originalValue.value = null;
    };
    window.onbeforeunload = function () {
      if (isInitialised.value && valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };
    return {
      ...toRefs(state),
      saveData,
      closeDialog,
      open,
      afterModalLeave,
    };
  },
};
