<template>
  <v-dialog
    persistent
    @afterLeave="afterModalLeave"
    v-model="dialog"
    max-width="90vw"
    max-height="3000px"
  >
    <div class="dialogheading">
      <div class="w-100 d-flex justify-content-between">
        <div
          class="w-90 text-center f-size24"
          id="list-section"
          v-if="!isUpdate"
        >
          {{ title }}
        </div>
        <div
          class="w-90 text-center f-size24"
          id="list-section"
          v-if="isUpdate"
        >
          {{
            title === "Upload Project Plan Documents"
              ? "Update Project Plan Document"
              : "Update Railroad Response Document"
          }}
        </div>
        <div>
          <v-icon @click="saveData" class="cursor-pointer" small
            >mdi-close
          </v-icon>
        </div>
      </div>
      <v-card>
        <v-card-text style="max-height: 80vh; overflow-y: scroll">
          <v-form v-model="isFormValid" class="pa1Form priorityForm">
            <v-grid>
              <v-row justify="center">
                <v-col cols="10">
                  <h2
                    v-if="!isUpdate"
                    class="f-size18 f-montserrat text-left mt-50"
                    style="
                      font-size: 18px;
                      padding-bottom: 0.5em;
                      color: #003e7e;
                    "
                  >
                    Upload Railroad Plan Review Documents
                  </h2>
                  <p
                    class="text-left"
                    style="padding-bottom: 1px; padding-left: 10px"
                  >
                    Maximum allowable file size is 500 MB.
                  </p>
                  <UploadScopingFieldViewDocument
                    v-if="isUpdate"
                    @uploadFilesCall="uploadFiles"
                    :isDynamicDocumentType="false"
                    :staticDocumentTypeList="[updateEvent.documentType]"
                    :staticDocumentTypeValue="updateEvent.documentType"
                    :maximumFilesUploadRange="1"
                    :checkMaximumFilesUploaded="true"
                    :checkMaxFileSize="true"
                    :previewDocTypeUrl="'GET_RAILROAD_PLAN_REVIEW_DOCTYPE_LIST'"
                    :mpmsId="id"
                    :showDocTypeText="true"
                    :isRailroadDropdownStatic="true"
                    :staticRailroadDropdownList="[updateEvent.railRoadName]"
                    :staticRailroadDropdownValue="updateEvent.railRoadName"
                    :showRailroadTypeText="true"
                    @valueChangedEvent="valueChangedEvent($event)"
                  />
                  <UploadScopingFieldViewDocument
                    v-if="
                      title === 'Upload Project Plan Documents' && !isUpdate
                    "
                    @uploadFilesCall="uploadFiles"
                    :showPreviewRailroadColumn="false"
                    :isDynamicDocumentType="true"
                    :checkMaximumFilesUploaded="true"
                    :checkMaxFileSize="true"
                    :previewDocTypeUrl="'GET_RAILROAD_PLAN_REVIEW_DOCTYPE_LIST'"
                    :mpmsId="id"
                    @valueChangedEvent="valueChangedEvent($event)"
                  ></UploadScopingFieldViewDocument>

                  <UploadScopingFieldViewDocument
                    v-if="
                      title === 'Upload Railroad Plan Review Documents' &&
                      !isUpdate
                    "
                    @uploadFilesCall="uploadFiles"
                    :isDynamicDocumentType="true"
                    :showPreviewRailroadColumn="true"
                    :checkMaximumFilesUploaded="true"
                    :maximumFilesUploadRange="isUpdate ? 1 : 5"
                    :checkMaxFileSize="true"
                    :previewDocTypeUrl="'GET_RAILROAD_RESPONSES_REVIEW_DOCTYPE_LIST'"
                    :mpmsId="id"
                    @valueChangedEvent="valueChangedEvent($event)"
                  ></UploadScopingFieldViewDocument>
                </v-col>
              </v-row>
            </v-grid>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </v-dialog>
  <LoadingOverlay :isLoading="loading"> </LoadingOverlay>
</template>
<script>
import UploadRailroadProjectPlansDialog from "@/modules/manageproject/scripts/UploadRailroadProjectPlansDialog.js";
export default UploadRailroadProjectPlansDialog;
</script>
<style>
@import "@/modules/projectinformation/assets/projectinfostyle.scss";
@import "@/assets/css/style.scss";
</style>
