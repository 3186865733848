import { reasonForUpdateRequiredRule } from "@/composables/validationRules";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import { computed, ref, watch } from "vue";

import { DROPDOWN_NO_DATA } from "@/constants/displayTexts";
export default {
  name: "ReasonForUpdate",
  props: ["isFormValid", "reasonToUpdateList", "reasonForUpdate", "isDisabled"],
  components: { PrimaryButton, SecondaryButton },
  setup(props, { emit }) {
    const reasonForUpdate = ref(props?.reasonForUpdate);
    const updatedDataFlagger = ref(props?.updatedDataFlagger);
    const isDisabled = ref(props?.isDisabled);
    watch(
      () => [props],
      () => {
        console.log("IS DISABLED:");
        updatedDataFlagger.value = props?.updatedDataFlagger;
        reasonForUpdate.value = props.reasonForUpdate;
        isDisabled.value = props?.isDisabled;
        console.log(isDisabled.value)
        console.log(props.isDisabled);

      },
      { deep: true }
    );
    const isFormValid = computed({
      get: () => {
        return props?.isFormValid;
      },
    });
    const reasonToUpdateList = computed({
      get: () => {
        return props?.reasonToUpdateList?.filter(
          (value) => value && value != "" && value.trim() != ""
        );
      },
    });
    const updateResonForUpdate = (event) => {
      emit("valueChange", event);
    };
    return {
      reasonForUpdateRequiredRule,
      PrimaryButton,
      SecondaryButton,
      isFormValid,
      reasonForUpdate,
      reasonToUpdateList,
      updateResonForUpdate,
      updatedDataFlagger,
      DROPDOWN_NO_DATA,
      isDisabled,
    };
  },
};
