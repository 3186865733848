import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import DialogComponent from "@/modules/dot-details/components/UpdateGradeCrossingDocuments.vue";
import UploadScopingFieldViewDocument from "@/modules/scoping-field-view/components/UploadScopingFieldViewDocument.vue";
import DocumentsList from "@/modules/shared/components/DocumentsList.vue";
import LoadingOverlay from "@/modules/shared/components/LoadingOverlay.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import { useDocumentStore } from "@/stores/document";
import { onMounted, ref } from "vue";
import { onBeforeRouteLeave, useRoute } from "vue-router";
import { RBAC_CONTROLLER } from "../../../constants/rbacconstants";
import { userRoleBasedAccessStore } from "../../../stores/userRoleBasedAccess";
export default {
  components: {
    UploadScopingFieldViewDocument: UploadScopingFieldViewDocument,
    DocumentsList,
    LoadingOverlay,
    PrimaryButton,
    SecondaryButton,
    DialogComponent,
  },
  name: "GradeCrossingDocuments",
  props: ["dotId"],
  emits: ["previousValue", "updatedValues"],
  setup(props, { emit }) {
    const railroadEstimatesDocumentsDialogRef = ref(null);
    const documentStore = useDocumentStore();
    const route = useRoute();
    const id = route.params.id || props.id;
    const loading = ref(false);
    let UpdateModalOpened = ref(false);

    const isProjectDocumentUpdateAccess = ref(false);
    const userRoleBasedAccessService = userRoleBasedAccessStore();

    onMounted(() => {
      fetchProjectDocumentsData();
      getProjectDocumentsUpdateAccess();
    });
    const getProjectDocumentsUpdateAccess = async () => {
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.PROJECT_DOCUMENTS_PAGE_CRTUPD_ACCESS
      );
      isProjectDocumentUpdateAccess.value = updateResponse;
    };
   
    const filesList = ref([]);
    const updateDocumentList = ref(false);
    function fetchProjectDocumentsData() {
      updateDocumentList.value = true;
    }
    const updateDocumentListFlag = () => {
      updateDocumentList.value = false;
    };
    const uploadFiles = async (files) => {
      loading.value = true;
      let docType = "GC_UPLD_DT";
      let endpoint = CONFIG_ENDPOINT.UPLOAD_SCOPING_DOCUMENTS;
      document.getElementById("list-section")?.scrollIntoView();
      await documentStore.uploadDocuments(
        id,
        files,
        docType,
        endpoint,
        true,
        true,
        false
      );
      loading.value = false;
      fetchProjectDocumentsData();
    };
 
    const valueChanged = ref(false);

 
    onBeforeRouteLeave((to, from, next) => {
      if (valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          valueChanged.value = false;
          next();
        } else {
          next(false);
        }
      } else {
        valueChanged.value = false;
        next();
      }
    });
    window.onbeforeunload = function () {
      if (valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };

    const updateButtonClicked = (event) => {
      console.log('in update button clicked');
      if (!isProjectDocumentUpdateAccess.value) return; // Prevent update if no access
      openDialog(event);
    };
    const openDialog = (event) => {
      UpdateModalOpened.value = true;
      railroadEstimatesDocumentsDialogRef.value
        .open("Update Documents", id, event)
        .then((resolve) => {
          if (resolve) {
            fetchProjectDocumentsData();
            UpdateModalOpened.value = false;
          }
        })
        .catch(() => {
          UpdateModalOpened.value = false;
        });
    };
    return {
      documentStore,
      filesList,
      updateDocumentListFlag,
      uploadFiles,
      id,
      fetchProjectDocumentsData,
     updateDocumentList,
      updateButtonClicked,
      railroadEstimatesDocumentsDialogRef,
      UpdateModalOpened,
      isProjectDocumentUpdateAccess,
      loading,
    };
  },
};