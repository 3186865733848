import axios from "axios";import { computed, onMounted, ref } from "vue";
import { CONFIG_ENDPOINT } from "../../../constants/Endpoints";
export default {
  name: "PhoneInput",
  props: {
    helpKey: { default: "" },
    showMargin: { default: false },
  },
  setup(props, context) {
    const helpData = ref(null);
    const helpKey = computed({
      get: () => {
        return props?.helpKey;
      },
    });
    const showMargin = computed({
      get: () => {
        return props?.showMargin;
      },
    });
    onMounted(() => {
      getHelpList();
    });
    const getHelpList = () => {
      axios
        .get(CONFIG_ENDPOINT.ONLINE_HELP_POC)
        .then((res) => {
          console.log("ONLINE_HELP_POC", res);
          helpData.value = res.data.data;
        })
        .catch((err) => err);
    };
    return { helpKey, helpData, showMargin };
  },
};
