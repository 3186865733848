<template>
  <v-card class="p-64">
    <FeatureTable
      :headers="headers"
      :items="activeProjects"
      :showExportButton="false"
      :exportFilName="'HOP Location'"
      :showClearFilterButton="true"
      :tableHeader="''"
      :itemsPerPage="paginationData?.items"
      :linkedPagination="true"
      :totalItems="paginationData?.total"
      :lookupData="lookupData"
      :oldMultipleFilter="multipleFilter"
      @updatePaginationPerPage="updatePaginationPerPage"
      @pageChange="pageChange"
      @sortUpdate="sortUpdate"
      @textFilterChange="textFilterChange"
      @multipleFiltersChange="multipleFiltersChange"
      @clearAllFilter="clearAllFilter"
    >
    </FeatureTable>
  </v-card>
</template>

<script>
import HopLocationInfo from "@/modules/locationInformation/scripts/HopLocationInfo.js";
export default HopLocationInfo;
</script>
<style>
.container {
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
  padding-top: 20px;
}
</style>
