<template>
   <div v-if="showBanner">
    <BannerMessage
      v-if="isSuccess"
      :successBanner="true"
      :message="bannerMessage"
      @hideBanner="hideBannerMessage()"
    ></BannerMessage>
    <BannerMessage
      v-if="!isSuccess"
      :errorBanner="true"
      :message="bannerMessage"
      @hideBanner="hideBannerMessage()"
    ></BannerMessage>
  </div>
    <v-row style="margin-top:10px !important;">
<v-col cols="3" class="ml-20">
<router-link :to="`/GCMS/${id}`">
  &lt; Return to Project Information Page
</router-link>
</v-col>
<v-col class="page-title" cols="6">
<h1>Railroad Work Summary</h1>
</v-col>
<v-col> </v-col>
</v-row>
<div style="display:flex !important; align-content: center; margin-top:-20px !important" >
<v-col cols="4"></v-col>
<v-row class="m-20 estimateRow">
<v-col cols="1"> Railroad: </v-col>
<v-col cols="5" v-if="staticRailroad">
<span id="selectedRailroad">{{ selectedRailroad }}</span>
</v-col>
<v-col cols="5" v-else>
<v-select
  :items="railroadList"
  v-model="selectedRailroad"
  :rules="[requiredRule]"
></v-select>
</v-col>
</v-row>
</div>
<br><br>
<div v-if="selectedRailroad">
<v-tabs :value="getCurrentTab" align-tabs="start">
<v-tab
  :class="{
    'v-slide-group-item--active': getCurrentTab === 'railroadWorkSummary',
    'v-tab--selected': getCurrentTab === 'railroadWorkSummary',
  }"
  @click="
    $event.preventDefault();
    onTabChange('railroadWorkSummary');
  "
  value="railroadWorkSummary"
  >Railroad Work Summary</v-tab
>
<v-tab
  :class="{
    'v-slide-group-item--active': getCurrentTab === 'railroadDocuments',
    'v-tab--selected': getCurrentTab === 'railroadDocuments',
  }"
  @click="
    $event.preventDefault();
    onTabChange('railroadDocuments');
  "
  value="railroadDocuments"
  >Railroad Documents</v-tab
>
</v-tabs>

<v-card-text>
<div v-if="getCurrentTab === 'railroadWorkSummary'">
  <RailroadWorkSummary 
  :railroad="selectedRailroad" 
  :railroadMap="railroadMap" 
  @showBanner="showBannerMessage"

  >
</RailroadWorkSummary>
  </div>
<div v-if="getCurrentTab === 'railroadDocuments'">
  <RailroadDocuments  
  :railroad="selectedRailroad" 
  :railroadMap="railroadMap" 
  :railroadList="railroadList">
</RailroadDocuments>
</div>
</v-card-text>
</div>
</template>

<script>
import RailroadWorkSummaryTabs from "@/modules/manageproject/scripts/RailroadWorkSummaryTabs.js";
export default RailroadWorkSummaryTabs;
</script>