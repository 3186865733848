import { reactive, ref, toRefs } from "vue";
export default {
  components: {},
  setup() {
    const state = reactive({
      dialog: false,
      resolve: null,
      reject: null,
    });
    const docketNum = ref(null);
    const links = ref(null);
    const linksArray = ref([]);
    const labelArray = ref([]);
    const pucNotExist = ref(false);
    const count = ref(0);
    const error = ref("This PUC Docket# does not exist");

    const open = (docketNumber, data) => {
      linksArray.value = [];
      state.dialog = true;
      pucNotExist.value = false;
      docketNum.value = docketNumber;
      links.value = data;
      
      linksArray.value = data;
      
      if (linksArray.value[0].documentName == "No Results") {
        linksArray.value[0] = "This PUC Docket# does not exist";
        pucNotExist.value = true;
      }
      return new Promise((resolve, reject) => {
        state.resolve = resolve;
        state.reject = reject;
      });
    };

    const closeDialog = () => {
      state.resolve(false);
      state.dialog = false;
      pucNotExist.value = false;
    };
    const clicked = (data) => {
      console.log('clicked');
      console.log(data);
      window.open(data, "_blank");
    };
    return {
      closeDialog,
      ...toRefs(state),
      open,
      docketNum,
      linksArray,
      clicked,
      pucNotExist,
      error,
      labelArray,
      count,
    };
  },
};
