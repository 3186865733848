import useDateField from "@/composables/dateField.js";
import { requiredRule } from "@/composables/validationRules";
import { DROPDOWN_NO_DATA } from "@/constants/displayTexts";
import DatePicker from "@/modules/shared/components/DatePicker.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import IconButton from "@/modules/shared/scripts/IconButton.js";
import axios from "axios";
import { computed, onMounted, ref } from "vue";
import { CONFIG_ENDPOINT } from "../../../constants/Endpoints";
import {
  ACCEPTED_DOT_DROPDOWN_DOC_TYPE,
  ACCEPTED_RAILROAD_DROPDOWN_DOC_TYPE,
} from "../../../constants/constantValues";

export default {
  name: "UploadedFilePreview",
  components: { PrimaryButton, SecondaryButton, DatePicker, IconButton },
  props: {
    files: { type: Array, required: true },
    tag: { type: String, default: "li" },
    mpmsId: { type: String, default: "" },
    showPreviewRailroadColumn: { type: Boolean, default: false },
    showPreviewDOTColumn: { type: Boolean, default: false },
    isDynamicDocumentType: { type: Boolean, default: false },
    staticDocumentTypeList: { default: [] },
    staticDocumentTypeValue: { type: String, default: "" },
    isRailroadDropdownStatic: { type: Boolean, default: false },
    staticRailroadDropdownList: { default: [] },
    staticRailroadDropdownValue: { type: String, default: "" },
    staticDOTDropdownList: { default: [] },
    staticDOTDropdownValue: { type: String, default: "" },
    previewDocTypeUrl: { type: String, default: "" },
    showDocTypeText: { type: Boolean, default: false },
    showRailroadTypeText: { type: Boolean, default: false },
    isRailroadDropdownDisabled: { type: Boolean, default: false },
    isRailroadWorkSummary: { type: Boolean, default: false },
    isRailroadWorkSummaryDOTList: { default: [] },
    isContractorBidSafetyMilestone: { type: Boolean, default: false },
  },
  emits: ["remove", "updateFiles"],
  setup(props, context) {
    let isFormValid = ref(false);
    const { getFormattedDateStringNoTime } = useDateField();
    const documentTypeList = ref([]);
    const selectedDocType = ref();
    const railroadDropdownList = ref([]);
    const safetyMilestoneDropdownList = ref([]);
    const dotDropdownList = ref([]);
    const acceptedRailroadDropdownDocTypes = ref(
      ACCEPTED_RAILROAD_DROPDOWN_DOC_TYPE
    );
    const acceptedDotDropdownDocType = ref(ACCEPTED_DOT_DROPDOWN_DOC_TYPE);

    onMounted(() => {
      let doctypeUrl =
        props?.previewDocTypeUrl === "GET_RAILROAD_PLAN_REVIEW_DOCTYPE_LIST"
          ? CONFIG_ENDPOINT[props?.previewDocTypeUrl] +
            props?.mpmsId +
            "/PP_UPLD_DT"
          : CONFIG_ENDPOINT[props?.previewDocTypeUrl];
      axios.get(doctypeUrl).then((res) => {
        documentTypeList.value = res.data;
      });

      if (
        props?.showPreviewRailroadColumn &&
        props?.isContractorBidSafetyMilestone
      ) {
        axios
          .get(CONFIG_ENDPOINT.CONTRACTOR_BID_GET + props?.mpmsId)
          .then((res) => {
            for (let index in res.data.data) {
              safetyMilestoneDropdownList.value.push(
                res.data.data[index].railroadCompany
              );
            }
          })
          .catch((err) => {
            safetyMilestoneDropdownList.value = [];
          });
      }
      if (props?.showPreviewRailroadColumn) {
        axios
          .get(
            CONFIG_ENDPOINT.UPLOAD_SCOPING_MILESTONE_RAILROADS + props?.mpmsId
          )
          .then((res) => {
            railroadDropdownList.value = res.data.map(
              (railroad) => railroad.railroadCompany
            );
          })
          .catch((err) => {
            railroadDropdownList.value = [];
          });
      }
      if (props?.showPreviewDOTColumn && props?.isRailroadWorkSummary) {
        axios
          .get(CONFIG_ENDPOINT.UPLOAD_SCOPING_MILESTONE_DOT + props?.mpmsId)
          .then((res) => {
            dotDropdownList.value = props?.isRailroadWorkSummaryDOTList;
          })
          .catch((err) => {
            dotDropdownList.value = [];
          });
      } else if (props?.showPreviewDOTColumn) {
        axios
          .get(CONFIG_ENDPOINT.UPLOAD_SCOPING_MILESTONE_DOT + props?.mpmsId)
          .then((res) => {
            dotDropdownList.value = res.data.projectCrossingDTO.map(
              (crossing) => crossing.dotNumber
            );
          })
          .catch((err) => {
            dotDropdownList.value = [];
          });
      }
    });

    const showPreviewRailroadColumn = computed({
      get: () => {
        return props?.showPreviewRailroadColumn;
      },
    });
    const showPreviewDOTColumn = computed({
      get: () => {
        return props?.showPreviewDOTColumn;
      },
    });
    const isDynamicDocumentType = computed({
      get: () => {
        if (documentTypeList.value.length == 1) {
          props?.files?.forEach((file) => {
            updatePayload(documentTypeList.value[0], file, "docType");
          });
        }
        return props?.isDynamicDocumentType;
      },
    });
    const staticDocumentTypeList = computed({
      get: () => {
        return props?.staticDocumentTypeList;
      },
    });
    const isRailroadWorkSummaryDOTList = computed({
      get: () => {
        return props?.isRailroadWorkSummaryDOTList;
      },
    });

    const isContractorBidSafetyMilestone = computed({
      get: () => {
        return props?.isContractorBidSafetyMilestone;
      },
    });

    const showDocTypeText = computed({
      get: () => {
        return props?.showDocTypeText;
      },
    });
    const staticDocumentTypeValue = computed({
      get: () => {
        return props?.staticDocumentTypeValue;
      },
    });
    const isRailroadDropdownStatic = computed({
      get: () => {
        if (props?.isRailroadDropdownStatic) {
          props?.files?.forEach((file) => {
            updatePayload(
              props?.staticRailroadDropdownList[0],
              file,
              "railroad"
            );
          });
        }
        return props?.isRailroadDropdownStatic;
      },
    });
    const staticRailroadDropdownList = computed({
      get: () => {
        return props?.staticRailroadDropdownList;
      },
    });
    const staticRailroadDropdownValue = computed({
      get: () => {
        return props?.staticRailroadDropdownValue;
      },
    });

    const staticDOTDropdownList = computed({
      get: () => {
        return props?.staticDOTDropdownList;
      },
    });
    const staticDOTDropdownValue = computed({
      get: () => {
        return props?.staticDOTDropdownValue;
      },
    });
    const previewDocTypeUrl = computed({
      get: () => {
        return props?.previewDocTypeUrl;
      },
    });

    const showRailroadTypeText = computed({
      get: () => {
        return props?.showRailroadTypeText;
      },
    });

    const isRailroadDropdownDisabled = computed({
      get: () => {
        return props?.isRailroadDropdownDisabled;
      },
    });

    const isRailroadWorkSummary = computed({
      get: () => {
        return props?.isRailroadWorkSummary;
      },
    });

    function showRailroadDropdown(file, docType) {
      if (
        !props?.staticRailroadDropdownValue &&
        props?.showPreviewRailroadColumn &&
        acceptedRailroadDropdownDocTypes.value.includes(docType) &&
        railroadDropdownList.value.length == 1
      ) {
        updatePayload(railroadDropdownList.value[0], file, "railroad");
      }
      return acceptedRailroadDropdownDocTypes.value.includes(docType);
    }
    function showDOTDropdown(file, docType) {
      if (
        props?.showPreviewDOTColumn &&
        acceptedDotDropdownDocType.value.includes(docType) &&
        dotDropdownList.value.length == 1
      ) {
        updatePayload(dotDropdownList.value[0], file, "dot");
      }
      return acceptedDotDropdownDocType.value.includes(docType);
    }
    const onNoteChange = (event, file) => {
      file.file.notes = event;
      const index = props.files.findIndex(
        (propFile) => propFile.id === file.id
      );
      if (index >= 0) {
        props.files[index].payloadData.notes = event.target.value;
      }
      context.emit("updateFiles", props.files);
    };

    const updatePayload = (event, file, field) => {
      file.file[field] = event;
      const index = props.files.findIndex(
        (propFile) => propFile.id === file.id
      );
      if (index >= 0) {
        props.files[index].payloadData[field] = event;
      }
      context.emit("updateFiles", props.files);
    };
    const getDate = (date) => {
      return getFormattedDateStringNoTime(date);
    };
    const getNoDataColspan = computed({
      get: () => {
        let totalColumns = 8;
        return totalColumns;
      },
    });
    return {
      documentTypeList,
      onNoteChange,
      updatePayload,
      getDate,
      DROPDOWN_NO_DATA,
      selectedDocType,
      showRailroadDropdown,
      showDOTDropdown,
      railroadDropdownList,
      requiredRule,
      isFormValid,
      dotDropdownList,
      showPreviewRailroadColumn,
      showPreviewDOTColumn,
      isDynamicDocumentType,
      staticDocumentTypeList,
      staticDocumentTypeValue,
      previewDocTypeUrl,
      getNoDataColspan,
      isRailroadDropdownStatic,
      staticRailroadDropdownList,
      staticRailroadDropdownValue,
      showDocTypeText,
      showRailroadTypeText,
      isRailroadDropdownDisabled,
      staticDOTDropdownList,
      staticDOTDropdownValue,
      isRailroadWorkSummary,
      isRailroadWorkSummaryDOTList,
      isContractorBidSafetyMilestone,
      safetyMilestoneDropdownList,
    };
  },
};
