<template>
  <div class="env-infp">
    <div class="env-content">
      <v-row>
        <v-col cols="12">
          <label class="mpms-env-label"
            ><v-row>
              <v-col cols="6">
                <label class="mpms-env-label">
                  <v-row>
                    <v-col cols="6">
                      <b class="d-flex justify-content-start text-left required" style="padding-left:50px !important"
                        ><span class="reason">Reason for Update: </span></b
                      >
                    </v-col>
                    <v-col cols="5">
                      <v-select style="padding-left:5px !important"
                        :rules="
                          updatedDataFlagger
                            ? [reasonForUpdateRequiredRule]
                            : []
                        "
                        @update:modelValue="updateResonForUpdate"
                        :items="reasonToUpdateList"
                        v-model="reasonForUpdate"
                        outlined
                        :disabled="isDisabled"
                      ></v-select>
                    </v-col>
                  </v-row>
                </label>
              </v-col>
              <v-col cols="6" class="d-flex justify-content-end">
                <PrimaryButton
                  @buttonClicked="$emit('saveClicked')"
                  :buttonText="'Save'"
                  :disabled="!isFormValid || !reasonForUpdate || isDisabled"
                ></PrimaryButton>
                <SecondaryButton
                  @buttonClicked="$emit('cancelClicked')"
                  :buttonText="'Cancel'"
                  class="ml-10"
                  :disabled="isDisabled"

                ></SecondaryButton>
              </v-col>
            </v-row>
          </label>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import ReasonForUpdate from "@/modules/dot-details/scripts/ReasonForUpdate";
export default ReasonForUpdate;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
</style>
