<template>
  <tr
    class="bg-gray-300 m-1 p-3 rounded-md text-center"
    :class="{ allowItemDrag: checkDraggable(item) }"
    v-for="(item, index) in list"
    :key="item[dragKey]"
  >
    <td
      v-for="column in tableHeader"
      :key="column.name"
      :class="{
        ['w-' + (column?.columnWidth ? column?.columnWidth : '15')]:
          column?.isAction,
      }"
    >
      <div
        v-if="!column?.isAction"
        :class="{
          'is-link': column?.isLink,
          'is-underline': column?.isUnderline,
        }"
        class="td-text"
        @dblclick="doubleClickEvent(item?.[column.key])"
        @click="
          column?.isLink
            ? $emit('linkTextClicked', {
                key: column.key,
                value: item,
              })
            : ''
        "
      >
        <span v-if="column.key === 'milepost'">|</span>
        <span v-if="column.key !== 'streetViewLink'">{{
          item?.[column.key]
        }}</span>
        <span v-if="column.key === 'milepost'">|</span>
        <span
          v-if="
            column.key === 'dotNumber' &&
            item?.impacted &&
            item?.railroadCompanyCount > 0
          "
          class="indicator-text-row"
        >
          <i class="fa fa-link"></i>
        </span>
        <span v-if="column.key === 'streetViewLink'" class="icon-container">
          <i
            class="fas fa-map-marker"
            style="color: #00a651 !important; font-size: 24px"
          ></i>
        </span>
      </div>
      <div v-if="column?.isAction">
        <div class="d-flex justify-content-center align-items-center">
          <IconButton
            v-if="
              column?.isAdd &&
              (!isFirstList || (isFirstList && checkDraggable(item)))
            "
            @buttonClicked="$emit('addButtonClicked', item)"
            :buttonText="column?.addbuttonText"
            :iconButton="true"
            :iconName="'mdi-plus'"
          ></IconButton>
          <IconButton
            v-if="column?.isEdit"
            :disabled="!iconButtonsDisabled(item)"
            @buttonClicked="
              $emit('editButtonClicked', { item: item, index: index })
            "
            :buttonText="'Edit'"
            :iconButton="true"
            :iconName="'mdi-pencil'"
          ></IconButton>
          <IconButton
            v-if="column?.isDelete && !hideWithinVicinityDeleteButton(item)"
            :disabled="!iconButtonsDisabled(item)"
            @buttonClicked="$emit('deleteButtonClicked', index)"
            :buttonText="'Remove'"
            :iconButton="true"
            :iconName="'mdi-delete'"
            class="pl-0"
          ></IconButton>
        </div>
      </div>
    </td>
  </tr>
  <tr v-if="list?.length == 0">
    <td class="text-center" :colspan="tableHeader?.length">
      No Data Available
    </td>
  </tr>
  <v-snackbar v-model="snackbar" :timeout="2000">Text Copied </v-snackbar>
</template>
<script>
import DataTableRowHandler from "@/modules/shared/scripts/DataTableRowHandler.js";
export default DataTableRowHandler;
</script>
<style>
@import "@/assets/css/style.scss";
.td-text {
  display: flex;
  align-items: center;
}
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.indicator-text-row {
  font-size: 14px !important;
  margin-left: 10px;
}
.indicator-text-row .fa {
  color: #b00020;
  font-size: 14px !important;
}
</style>