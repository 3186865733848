<template>
  <v-card v-if="!isEdit" class="dot-part-form">
    <v-card-text v-if="PartIIIDetails">
      <v-form v-model="isFormValid" ref="partIIIForm">
        <div class="page-title">
          <h1>Traffic Control Device Information</h1>
        </div>

        <div class="env-header mb-20">
          <h2 class="locationHeaderText">Traffic Control Device Information</h2>
        </div>

        <div class="env-infp">
          <div class="env-content">
            <v-row class="center">
              <v-col cols="12">
                <label class="mpms-env-label">
                  <v-row>
                    <v-col cols="3">
                      <b><span>Are there Signs or Signals? </span></b>
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        class="select-fields"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .signsOrSignals
                        "
                        :items="PartIIILookupData?.signsOrSignals || []"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-select>
                    </v-col>
                  </v-row>
                </label>
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="env-header mb-20">
          <h2 class="locationHeaderText">
            2. Types of Passive Traffic Control Devices associated with the
            Crossing
          </h2>
        </div>
        <div class="env-infp">
          <div class="env-content">
            <v-row>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">2.A.</v-col>
                    <v-col cols="4">
                      <b class="textColumn required"
                        ><span> Crossbuck Assemblies (Count): </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        :maxLength="2"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .crossbuckAssembliesCount
                        "
                        :rules="[requiredRule]"
                        @keydown="keydownAllowNumberOnly($event)"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      >
                      </v-text-field> </v-col></v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2"> 2.B. </v-col>
                    <v-col cols="4">
                      <b class="textColumn required"
                        ><span>STOP Signs (R1-1): </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        :maxLength="1"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .stopSignsCount
                        "
                        :rules="[requiredRule]"
                        @keydown="keydownAllowNumberOnly($event)"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-text-field> </v-col></v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">2.C. </v-col>
                    <v-col cols="4">
                      <b class="textColumn required"
                        ><span>YIELD Signs (R1-2): </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        :maxLength="1"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .yieldSignsCount
                        "
                        :rules="[requiredRule]"
                        @keydown="keydownAllowNumberOnly($event)"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      >
                      </v-text-field> </v-col></v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <v-row>
                  <v-col cols="2">2.D.</v-col>
                  <v-col cols="4">
                    <label class="mpms-env-label"
                      ><b class="textColumn required"
                        >Advance Warning Signs:
                      </b></label
                    >
                  </v-col>
                  <v-col cols="5" v-if="AdvanceWarningSigns.length">
                    <v-row
                      v-for="sign in AdvanceWarningSigns"
                      :key="sign.label"
                      align="center"
                    >
                      <v-col
                        cols="2"
                        class="d-flex justify-content-center w-100"
                      >
                        <v-checkbox-btn
                          v-if="sign.label != null"
                          v-model="sign.enabled"
                          @change="checkboxUpdated(sign)"
                          class="pe-2"
                          :disabled="!isUserGradeCrossingAccess.update"
                        >
                        </v-checkbox-btn>
                      </v-col>
                      <v-col cols="3">{{ sign.label }}</v-col>
                      <v-col cols="7">
                        <v-text-field
                          v-if="sign.label != null && sign.label != 'None'"
                          :disabled="
                            !sign.enabled || !isUserGradeCrossingAccess.update
                          "
                          :maxlength="1"
                          :rules="sign.enabled ? [minimumRule] : []"
                          v-model="sign.value"
                          @keydown="keydownAllowNumberOnly($event)"
                          hide-details=""
                        ></v-text-field>
                      </v-col>
                    </v-row> </v-col
                ></v-row>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">2.E.</v-col>
                    <v-col cols="4">
                      <b class="textColumn">
                        <span>Low Ground Clearance Sign (W10-5): </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        class="select-fields"
                        :items="lowGroundClearance"
                        item-title="label"
                        item-value="label"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .lowGroundClearanceSign
                        "
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-select>
                      <v-text-field
                        hide-details
                        :maxlength="1"
                        :rules="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .lowGroundClearanceSign === 'Yes'
                            ? [requiredRule]
                            : []
                        "
                        @keydown="keydownAllowNumberOnly($event)"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .lowGroundClearanceSignCount
                        "
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-text-field>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">2.F. </v-col>
                    <v-col cols="4">
                      <b class="textColumn required"
                        ><span>Pavement Markings: </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        class="select-fields"
                        :items="PartIIILookupData?.pavementMarkings || []"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .pavementMarkings
                        "
                        :rules="[arrayRequiredRule]"
                        multiple
                        @update:modelValue="
                          checkNoneSelected($event, 'pavementMarkings')
                        "
                        :disabled="!isUserGradeCrossingAccess.update"
                      >
                      </v-select>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">2.G. </v-col>
                    <v-col cols="4">
                      <b class="textColumn"
                        ><span>Channelization Devices/Medians: </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        class="select-fields"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .channelizationDevicesMedians
                        "
                        :items="
                          PartIIILookupData?.channelizationDevicesMedians || []
                        "
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-select>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">2.H.</v-col>
                    <v-col cols="4">
                      <b class="textColumn required"
                        ><span>EXEMPT Sign (R15-3): </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        class="select-fields"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO.exemptSign
                        "
                        :rules="[requiredRule]"
                        :items="PartIIILookupData?.exemptSign || []"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-select>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">2.I.</v-col>
                    <v-col cols="4">
                      <b class="textColumn required"
                        ><span>ENS Sign (I-13) Displayed: </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        class="select-fields"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .ensSignDisplayed
                        "
                        :rules="[requiredRule]"
                        :items="PartIIILookupData?.ensSignDisplayed || []"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-select>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">2.J. </v-col>
                    <v-col cols="4">
                      <b class="textColumn"><span>Other MUTCD Signs: </span></b>
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        class="select-fields"
                        :maxLength="2"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .otherMUTCDSigns
                        "
                        :items="PartIIILookupData?.otherMUTCDSigns || []"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-select>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2"></v-col>
                    <v-col cols="4">
                      <b
                        class="textColumn"
                        :class="{
                          required:
                            PartIIIDetails.gradeCrossingPartThreeDTO
                              .otherMUTCDSigns === 'Yes',
                        }"
                      >
                        <span>Specify Type: </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO.otherDesc1
                        "
                        :rules="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .otherMUTCDSigns === 'Yes'
                            ? [requiredRule]
                            : []
                        "
                        :maxlength="10"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      >
                      </v-text-field>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2"></v-col>
                    <v-col cols="4">
                      <b
                        class="textColumn"
                        :class="{
                          required:
                            PartIIIDetails.gradeCrossingPartThreeDTO
                              .otherMUTCDSigns === 'Yes',
                        }"
                      >
                        <span>Count: </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO.otherSign1
                        "
                        :rules="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .otherMUTCDSigns === 'Yes'
                            ? [requiredRule]
                            : []
                        "
                        @keydown="keydownAllowNumberOnly($event)"
                        :maxlength="2"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      >
                      </v-text-field>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2"></v-col>
                    <v-col cols="4">
                      <b
                        class="textColumn"
                        :class="{
                          required:
                            PartIIIDetails.gradeCrossingPartThreeDTO
                              .otherMUTCDSigns === 'Yes',
                        }"
                      >
                        <span>Specify Type: </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO.otherDesc2
                        "
                        :rules="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .otherMUTCDSigns === 'Yes'
                            ? [requiredRule]
                            : []
                        "
                        :maxlength="10"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      >
                      </v-text-field>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2"></v-col>
                    <v-col cols="4">
                      <b
                        class="textColumn"
                        :class="{
                          required:
                            PartIIIDetails.gradeCrossingPartThreeDTO
                              .otherMUTCDSigns === 'Yes',
                        }"
                      >
                        <span>Count: </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO.otherSign2
                        "
                        :rules="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .otherMUTCDSigns === 'Yes'
                            ? [requiredRule]
                            : []
                        "
                        @keydown="keydownAllowNumberOnly($event)"
                        :maxlength="2"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      >
                      </v-text-field>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2"></v-col>
                    <v-col cols="4">
                      <b
                        class="textColumn"
                        :class="{
                          required:
                            PartIIIDetails.gradeCrossingPartThreeDTO
                              .otherMUTCDSigns === 'Yes',
                        }"
                      >
                        <span>Specify Type: </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO.otherDesc3
                        "
                        :rules="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .otherMUTCDSigns === 'Yes'
                            ? [requiredRule]
                            : []
                        "
                        :maxlength="10"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      >
                      </v-text-field>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2"></v-col>
                    <v-col cols="4">
                      <b
                        class="textColumn"
                        :class="{
                          required:
                            PartIIIDetails.gradeCrossingPartThreeDTO
                              .otherMUTCDSigns === 'Yes',
                        }"
                      >
                        <span>Count: </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO.otherSign3
                        "
                        :rules="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .otherMUTCDSigns === 'Yes'
                            ? [requiredRule]
                            : []
                        "
                        @keydown="keydownAllowNumberOnly($event)"
                        :maxlength="2"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      >
                      </v-text-field>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">2.K.</v-col>
                    <v-col cols="4">
                      <b class="textColumn"
                        ><span>Private Crossing Signs (If private): </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        class="select-fields"
                        :items="PartIIILookupData?.privateCrossingSigns || []"
                        item-value="value"
                        item-title="label"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .privateCrossingSignsCount
                        "
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-select>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">2.L.</v-col>
                    <v-col cols="4">
                      <b class="textColumn"
                        ><span>LED Enhanced Signs (List Types): </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-textarea
                        :maxLength="200"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .ledEnhancedSigns
                        "
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-textarea> </v-col></v-row
                ></label>
              </v-col>
            </v-row>
          </div>
        </div>

        <div class="env-header mb-20">
          <h2 class="locationHeaderText">
            3. Types of Train Activated Warning Devices at the Grade Crossing
            <br />
            &nbsp; &nbsp; (Specify count of each device for all that apply)
          </h2>
        </div>
        <div class="env-infp">
          <div class="env-content">
            <div class="v-row center">
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">3.A. </v-col>
                    <v-col cols="4">
                      <b class="textColumn">Gate Arms:</b>
                    </v-col>
                    <v-col cols="6">
                      <span></span>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6"> </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2"></v-col>
                    <v-col cols="4">
                      <b class="textColumn required"> <span>Roadway: </span></b>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        :maxLength="1"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .gateArmsRoadwayCount
                        "
                        :rules="[requiredRule]"
                        @keydown="keydownAllowNumberOnly($event)"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      >
                      </v-text-field>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2"></v-col>
                    <v-col cols="4">
                      <b class="textColumn required">
                        <span>Pedestrian: </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        :maxLength="1"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .gateArmsPedestrianCount
                        "
                        :rules="[requiredRule]"
                        outlined
                        @keydown="keydownAllowNumberOnly($event)"
                        :disabled="!isUserGradeCrossingAccess.update"
                      >
                      </v-text-field>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">3.B.</v-col>
                    <v-col cols="4">
                      <b class="textColumn required"
                        ><span>Gate Configuration: </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        class="select-fields"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .gateConfigurationQuad
                        "
                        :rules="[requiredRule]"
                        :items="PartIIILookupData?.gateConfigurationQuad || []"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-select>
                      <v-select
                        class="select-fields"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .gateConfiguration
                        "
                        :rules="[requiredRule]"
                        :items="PartIIILookupData?.gateConfiguration || []"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-select>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">3.C.</v-col>
                    <v-col cols="4">
                      <b class="textColumn required"
                        ><span
                          >Cantilevered (or Bridged) Flashing Light Structures:
                        </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        class="select-fields"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .cantileveredFlashingLightStructures
                        "
                        :rules="[requiredRule]"
                        :items="
                          PartIIILookupData?.cantileveredFlashingLightStructures ||
                          []
                        "
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-select>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6"></v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2"></v-col>
                    <v-col cols="4">
                      <b class="textColumn required"
                        ><span>Over Traffic Lane: </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        :maxLength="1"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .cantileveredFlashingLightStructuresOverTrafficLaneCount
                        "
                        outlined
                        :rules="[requiredRule]"
                        @keydown="keydownAllowNumberOnly($event)"
                        :disabled="!isUserGradeCrossingAccess.update"
                      >
                      </v-text-field>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6"></v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2"></v-col>
                    <v-col cols="4">
                      <b class="textColumn required"
                        ><span> Flashing Light Structure Type: </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        class="select-fields"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .flashingLightStructureType
                        "
                        :rules="[requiredRule]"
                        :items="
                          PartIIILookupData?.flashingLightStructureType || []
                        "
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-select>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6"></v-col>

              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2"></v-col>
                    <v-col cols="4">
                      <b class="textColumn required">
                        <span> Not Over Traffic Lane: </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        :maxLength="1"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .cantileveredFlashingLightStructuresNotOverTrafficLaneCount
                        "
                        outlined
                        :rules="[requiredRule]"
                        @keydown="keydownAllowNumberOnly($event)"
                        :disabled="!isUserGradeCrossingAccess.update"
                      >
                      </v-text-field>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">3.D.</v-col>
                    <v-col cols="4">
                      <b class="textColumn required"
                        ><span
                          >Mast Mounted Flashing Lights (Count of masts):
                        </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        :maxLength="1"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .mastMountedFlashingLightsCount
                        "
                        @input="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .mastMountedFlashingLightsCount == 0
                            ? (PartIIIDetails.gradeCrossingPartThreeDTO.mastMountedFlashingLightsType =
                                'None')
                            : ''
                        "
                        outlined
                        :rules="[requiredRule]"
                        @keydown="keydownAllowNumberOnly($event)"
                        :disabled="!isUserGradeCrossingAccess.update"
                      >
                      </v-text-field>

                      <v-select
                        class="select-fields"
                        :disabled="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .mastMountedFlashingLightsCount == 0 ||
                          !isUserGradeCrossingAccess.update
                        "
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .mastMountedFlashingLightsType
                        "
                        :rules="[requiredRule]"
                        :items="
                          PartIIILookupData?.mastMountedFlashingLights || []
                        "
                        outlined
                      ></v-select>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">3.E.</v-col>
                    <v-col cols="4">
                      <b class="textColumn required"
                        ><span>Total Count of Flashing Light Pairs: </span>
                      </b>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        :maxlength="2"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .totalFlashingLightPairsCount
                        "
                        :rules="[requiredRule]"
                        @keydown="keydownAllowNumberOnly($event)"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      >
                      </v-text-field>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2"></v-col>
                    <v-col cols="4">
                      <b class="textColumn required">
                        <span>Back Lights Included: </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        class="select-fields"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .backLightsIncluded
                        "
                        :rules="[requiredRule]"
                        :items="PartIIILookupData?.backLightsIncluded || []"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-select>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6"></v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2"></v-col>
                    <v-col cols="4">
                      <b class="textColumn required">
                        <span>Side Lights Included: </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        class="select-fields"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .sideLightsIncluded
                        "
                        :rules="[requiredRule]"
                        :items="PartIIILookupData?.sideLightsIncluded || []"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-select>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6"></v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">3.F.</v-col>
                    <v-col cols="4">
                      <b class="textColumn required"
                        ><span
                          >Installation date of Current Active Warning Devices
                          (MM/YYYY):
                        </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <MonthYearPicker
                        class="date-picker"
                        :value="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .installationDateOfCurrentActiveWarningDevices
                        "
                        :disabled="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .installationDateRequired ||
                          !isUserGradeCrossingAccess.update
                        "
                        :rules="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .installationDateRequired
                            ? []
                            : ['requiredRule']
                        "
                        @dateValidation="
                          checkValidationInstallationDate($event)
                        "
                        :invalidMsg="'Installation date of Current Active Warning Devices'"
                        @input="InstallationDateChange($event)"
                      />

                      <v-row align="center" class="mb-20">
                        <v-col cols="3">
                          <v-checkbox-btn
                            v-model="
                              PartIIIDetails.gradeCrossingPartThreeDTO
                                .installationDateRequired
                            "
                            class="pe-2"
                            :disabled="!isUserGradeCrossingAccess.update"
                          ></v-checkbox-btn>
                        </v-col>
                        <v-col cols="9"> Not Required</v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">3.G.</v-col>
                    <v-col cols="4">
                      <b class="textColumn required"
                        ><span>Wayside Horn: </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <span>
                        <v-select
                          class="select-fields"
                          v-model="
                            PartIIIDetails.gradeCrossingPartThreeDTO.waysideHorn
                          "
                          :rules="[requiredRule]"
                          :items="PartIIILookupData?.waysideHorn || []"
                          item-value="value"
                          item-title="label"
                          outlined
                          :disabled="!isUserGradeCrossingAccess.update"
                        ></v-select>
                        Installed on (MM/YYYY):
                        <MonthYearPicker
                          class="date-picker"
                          :value="
                            PartIIIDetails.gradeCrossingPartThreeDTO
                              .waysideHornInstallationDate
                          "
                          @dateValidation="checkIsWaysideHornDateValid($event)"
                          :invalidMsg="'Installed on Date'"
                          :rules="['requiredRule']"
                          @input="waysideHornDateChange($event)"
                          :disabled="!isUserGradeCrossingAccess.update"
                        />
                      </span>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">3.H.</v-col>
                    <v-col cols="4">
                      <b class="textColumn required"
                        ><span
                          >Highway Traffic Signals Controlling Crossing:
                        </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        class="select-fields"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .highwayTrafficSignalsControllingCrossing
                        "
                        :rules="[requiredRule]"
                        :items="
                          PartIIILookupData?.highwayTrafficSignalsControllingCrossing ||
                          []
                        "
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-select>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6"> </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">3.I.</v-col>
                    <v-col cols="4">
                      <b class="textColumn required">
                        <span>Bells (Count): </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        :maxlength="2"
                        v-model="PartIIIDetails.gradeCrossingPartThreeDTO.bells"
                        :rules="[requiredRule]"
                        @keydown="keydownAllowNumberOnly($event)"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      >
                      </v-text-field>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">3.J.</v-col>
                    <v-col cols="4">
                      <b class="textColumn"
                        ><span>Non Train Active Warning: </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        class="select-fields"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .nonTrainActiveWarning
                        "
                        :items="PartIIILookupData?.nonTrainActiveWarning || []"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-select>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">3.K.</v-col>
                    <v-col cols="4">
                      <b class="textColumn"
                        ><span
                          >Other Flashing Lights or Warning Devices:
                        </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        :maxlength="2"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .otherFlashingLightsOrWarningDevices
                        "
                        @keydown="keydownAllowNumberOnly($event)"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      >
                      </v-text-field>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">4.A.</v-col>
                    <v-col cols="4">
                      <b class="textColumn"
                        ><span>
                          Does nearby Hwy intersection have traffic signals?
                        </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        class="select-fields"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .nearbyHwyIntersectionHasTrafficSignals
                        "
                        :items="
                          PartIIILookupData?.nearbyHwyIntersectionHasTrafficSignals ||
                          []
                        "
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-select>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">4.B.</v-col>
                    <v-col cols="4">
                      <b class="textColumn"
                        ><span>Hwy Traffic Signal Interconnection: </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        class="select-fields"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .hwyTrafficSignalInterconnection
                        "
                        :items="
                          PartIIILookupData?.hwyTrafficSignalInterconnection ||
                          []
                        "
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-select> </v-col></v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">4.C.</v-col>
                    <v-col cols="4">
                      <b
                        class="textColumn"
                        :class="{
                          required:
                            PartIIIDetails.gradeCrossingPartThreeDTO
                              .hwyTrafficSignalInterconnection !=
                            'Not Interconnected',
                        }"
                        ><span>Hwy Traffic Signal Preemption</span></b
                      >
                    </v-col>
                    <!---->
                    <v-col
                      cols="5"
                      v-if="
                        PartIIIDetails.gradeCrossingPartThreeDTO
                          .hwyTrafficSignalInterconnection !=
                        'Not Interconnected'
                      "
                    >
                      <v-select
                        class="select-fields"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .hwyTrafficSignalPreemption
                        "
                        :rules="[requiredRule]"
                        :items="
                          PartIIILookupData?.hwyTrafficSignalPreemption || []
                        "
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="5"
                      v-if="
                        PartIIIDetails.gradeCrossingPartThreeDTO
                          .hwyTrafficSignalInterconnection ==
                        'Not Interconnected'
                      "
                    >
                      <v-select
                        class="select-fields"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .hwyTrafficSignalPreemption
                        "
                        :items="
                          PartIIILookupData?.hwyTrafficSignalPreemption || []
                        "
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-select>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6"> </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2"></v-col>
                    <v-col cols="4">
                      <b class="textColumn"><span></span></b>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6"> </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">5.</v-col>
                    <v-col cols="4">
                      <b class="textColumn"
                        ><span>Hwy Traffic Pre-Signals: </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        class="select-fields"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .hwyTrafficPreSignals
                        "
                        :items="PartIIILookupData?.hwyTrafficPreSignals || []"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-select>
                      <v-col
                        cols="12"
                        :class="{
                          required:
                            PartIIIDetails.gradeCrossingPartThreeDTO
                              .hwyTrafficPreSignals === 'Yes',
                        }"
                      >
                        <span>Storage Distance: </span
                        ><v-text-field
                          :maxLength="2"
                          v-model="
                            PartIIIDetails.gradeCrossingPartThreeDTO
                              .storageDistance
                          "
                          :rules="
                            PartIIIDetails.gradeCrossingPartThreeDTO
                              .hwyTrafficPreSignals === 'Yes'
                              ? [requiredRule]
                              : []
                          "
                          outlined
                          @keydown="keydownAllowNumberOnly($event)"
                          :disabled="!isUserGradeCrossingAccess.update"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        v-if="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .hwyTrafficPreSignals
                        "
                        :class="{
                          required:
                            PartIIIDetails.gradeCrossingPartThreeDTO
                              .hwyTrafficPreSignals === 'Yes',
                        }"
                      >
                        <span>Stop Line Distance: </span>
                        <v-text-field
                          :maxLength="2"
                          v-model="
                            PartIIIDetails.gradeCrossingPartThreeDTO
                              .stopLineDistance
                          "
                          :rules="
                            PartIIIDetails.gradeCrossingPartThreeDTO
                              .hwyTrafficPreSignals === 'Yes'
                              ? [requiredRule]
                              : []
                          "
                          outlined
                          @keydown="keydownAllowNumberOnly($event)"
                          :disabled="!isUserGradeCrossingAccess.update"
                        ></v-text-field>
                      </v-col>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">6.</v-col>
                    <v-col cols="4">
                      <b class="textColumn"
                        ><span>Hwy Monitoring Devices: </span></b
                      >
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        class="select-fields"
                        :items="MonitoringDevice"
                        v-model="
                          PartIIIDetails.gradeCrossingPartThreeDTO
                            .hwyMonitoringDevices
                        "
                        multiple
                        item-title="label"
                        item-value="label"
                        outlined
                        @update:modelValue="
                          checkNoneSelected($event, 'hwyMonitoringDevices')
                        "
                        :disabled="!isUserGradeCrossingAccess.update"
                      ></v-select>
                    </v-col> </v-row
                ></label>
              </v-col>
            </div>
          </div>
        </div>
        <ReasonForUpdate
          :class="{ PartIIIDetails: true }"
          :isFormValid="checkFormValidation"
          :reasonToUpdateList="PartIIILookupData?.reasonForUpdate || []"
          :reasonForUpdate="
            PartIIIDetails?.gradeCrossingPartThreeDTO.reasonForUpdate
          "
          @valueChange="valueChange($event)"
          :isDisabled="!isUserGradeCrossingAccess.update || !valueChanged || !isFormValid"
          @saveClicked="saveClicked()"
          @cancelClicked="cancelClicked()"
          :updatedDataFlagger="updatedDataFlagger"
        ></ReasonForUpdate>
        <div class="env-infp">
          <div class="env-content">
            <v-row>
              <v-col cols="12">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="6">
                      <label class="mpms-env-label">
                        <v-row>
                          <v-col cols="6"> </v-col>
                          <v-col
                            cols="5"
                            v-if="
                              updatedDataFlagger &&
                              !PartIIIDetails.gradeCrossingPartThreeDTO
                                .reasonForUpdate
                            "
                          >
                            <label class="mpms-env-label"
                              ><v-row>
                                <v-col cols="12" class="ml-2">
                                  <div
                                    class="errorRow"
                                    style="padding: 0px 5px 0px 5px !important"
                                  >
                                    <div class="error">
                                      <div>Required</div>
                                    </div>
                                  </div>
                                </v-col></v-row
                              ></label
                            >
                          </v-col>
                        </v-row>
                      </label>
                    </v-col>
                  </v-row>
                </label>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import PartIIITrafficControl from "@/modules/dot-details/scripts/PartIIITrafficControl.js";
export default PartIIITrafficControl;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";

.v-input__details .v-counter {
  display: none;
}
</style>
