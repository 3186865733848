<template>
  <v-autocomplete
    v-model="selectedItems"
    :items="items"
    :label="label"
    :placeholder="placeholder"
    multiple
    @input="handleInput"
    :no-data-text="AUTO_COMPLETE_NO_DATA"
  >
    <template #selection="{ values }">
      <v-chip v-for="(item, index) in values" :key="index"></v-chip> </template
  ></v-autocomplete>
</template>
<script>
import VueAutoComplete from "@/modules/shared/scripts/VueAutoComplete.js";
export default VueAutoComplete;
</script>
