import { DROPDOWN_NO_DATA } from "@/constants/displayTexts";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import { reactive, ref, toRefs } from "vue";
export default {
  components: { PrimaryButton, SecondaryButton },
  setup() {
    let title = ref("");
    let confirmationMessage = ref("");
    const state = reactive({
      dialog: false,
      resolve: null,
      reject: null,
    });

    const open = (message1, message2) => {
      state.dialog = true;
      title.value = message1;
      confirmationMessage.value = message2;

      return new Promise((resolve, reject) => {
        state.resolve = resolve;
        state.reject = reject;
      });
    };

    const closeDialog = () => {
      state.resolve(false);
      state.dialog = false;
    };

    const confirmAction = () => {
      state.resolve(true);
      state.dialog = false;
    };
    return {
      closeDialog,
      ...toRefs(state),
      open,
      confirmAction,
      title,
      confirmationMessage,
      ref,
      DROPDOWN_NO_DATA,
    };
  },
};
