<template>
    <v-card-text>
      <railroadEstimates></railroadEstimates>
    </v-card-text>
  </template>
  <script>
  import railroadEstimates from "@/modules/manageproject/components/railroadEstimates.vue";
  
  export default {
    name: "ViewRailroadEstimates-page",
    components: {
      railroadEstimates: railroadEstimates,
    },
  };
  </script>
  