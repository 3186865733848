import { computed } from "vue";export default {
  name: "PrimaryButton",

  props: ["disabled", "buttonText", "iconButton", "iconName"],
  setup(props, context) {
    const disabled = computed({
      get: () => {
        return props?.disabled;
      },
    });
    const buttonText = computed({
      get: () => {
        return props?.buttonText;
      },
    });
    const iconButton = computed({
      get: () => {
        return props?.iconButton;
      },
    });
    const iconName = computed({
      get: () => {
        return props?.iconName;
      },
    });
    const buttonClicked = (item) => {
      context.emit("buttonClicked", item);
    };
    return {
      disabled,
      buttonText,
      iconButton,
      iconName,
      buttonClicked,
    };
  },
};
