<template>
    <div>
      <v-row>
        <v-col cols="12" class="page-title">
          <h1>Railroad Documents</h1>
        </v-col>
        <v-col> </v-col>
      </v-row>
    </div>
<div>
    <div style="margin-top:-50px !important">
      <v-row cols="12">
        <v-col cols="12">
          <div class="view-upload-section">
            <span
              class="color-blue underline cursor-pointer"
              @click="otherButtonClicked()"
              :disabled="!isUserRailroadWorkSummaryAccess.update"
              >Upload Railroad Work Summary Documents</span
            >
          </div>
        </v-col>
      </v-row>
      <v-grid>
        <v-row justify="center" style="margin-top:-30px !important">
          <v-col cols="11">
            <DocumentsList
              :heading="'Railroad Document List'"
              :doctype="'/WRK_SUM_VW'"
              :id="id"
              :updateDocumentList="updateDocumentList"
              :isRailroadWorkSummary="true"
              @updateDocumentListFlag="updateDocumentListFlag"
              @otherButtonClicked="updateButtonClicked($event)"
              :railroadCompanyId="railroadId"
            ></DocumentsList>
          </v-col>
        </v-row>
      </v-grid>
      <br><br>
    </div>
  </div>
  <DialogComponent1
          ref="railroadWorkSummaryDocumentDialogRef"
          @updateRailroadWorkSummary="handleChangesSaved"
          :disabled="!isUserRailroadWorkSummaryAccess.update"
        ></DialogComponent1>
</template>

<script>
import RailroadDocuments from "@/modules/manageproject/scripts/RailroadDocuments.js";
export default RailroadDocuments;
</script>