<template>
  <div class="expanderOverLay">
    <InspectionR2AddEdit></InspectionR2AddEdit>
  </div>
</template>
<script>
import InspectionR2AddEdit from "@/modules/dot-details/components/InspectionR2AddEdit.vue";
export default {
  name: "InspectionR2",
  components: {
    InspectionR2AddEdit: InspectionR2AddEdit,
  },
};
</script>
