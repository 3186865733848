import useDateField from "@/composables/dateField.js";import DocumentsVersionDialogComponent from "@/modules/shared/components/DocumentsVersionDialog.vue";
import FeatureTable from "@/modules/shared/components/FeatureTable.vue";
import LoadingOverlay from "@/modules/shared/components/LoadingOverlay.vue";
import { useDocumentStore } from "@/stores/document";
import axios from "axios";
import { onMounted, ref, watch } from "vue";
import { CONFIG_ENDPOINT } from "../../../constants/Endpoints";
export default {
  name: "DocumentListWithFilter",
  components: {
    FeatureTable,
    LoadingOverlay,
    DocumentsVersionDialogComponent,
  },
  props: {
    id: { type: Number, default: null },
    item: Object,
    heading: { type: String, default: "" },
    doctype: { type: String, default: "" },
    updateDocumentList: { type: Boolean, default: false },
    isUpdateActionButton: { type: Boolean, default: false },
  },
  emits: ["updateDocumentListFlag", "filesLength"],
  setup(props, { emit }) {
    const priorityProjectDialogRef = ref(null);
    const documentStore = useDocumentStore();
    const filesList = ref([]);
    const loading = ref(false);
    const heading = ref(props?.heading);
    const headers = ref([
      {
        title: "File Name",
        icon: "fileName",
        key: "fileName",
        sortable: false,
        columnType: "icon",
        justifyContent: "start",
        isLink: true,
        isDownload: true,
        width: "20% !important",
      },
      {
        title: "Version",
        key: "version",
        sortable: false,
        width: "4%!important",
        isLink: true,
      },
      {
        title: "Document Type",
        key: "documentType",
        sortType: "text",
        width: "20%",
        sortable: true,
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
      },
      {
        title: "Date Uploaded",
        key: "documentUploadDate",
        sortType: "date",
        width: "5%!important",
        sortable: true,
      },
      {
        title: "File Size (MB)",
        key: "fileSize",
        sortable: false,
        width: "5%",
      },
      {
        title: "Railroad",
        key: "railRoadName",
        sortable: false,
        width: "15%!important",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
      },
      {
        title: "DOT#",
        key: "dotNumber",
        sortable: false,
        width: "10%",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
      },
      { title: "Notes", key: "notes", sortable: false, width: "20%" },
    ]);
    const paginationData = ref({
      page: 1,
      items: 15,
      total: 0,
    });

    const textFilter = ref([]);
    const multipleFilter = ref([]);
    const { getFormattedDateStringNoTime } = useDateField();
    const getDate = (date) => {
      return getFormattedDateStringNoTime(date);
    };
    let lookupData = ref([]);

    watch(
      () => [props.updateDocumentList],
      () => {
        if (props.updateDocumentList) {
          fetchData();
          emit("updateDocumentListFlag", false);
        }
      },
      { deep: true }
    );

    onMounted(() => {
      headers.value.push({
        title: "Action",
        key: "action",
        sortable: false,
        columnType: "action",
        width: "4%!important",
        isOtherButton: true,
        otherButtonText: "Update",
        hideOtherButtonKey: "hideOtherButtonKey",
      });

      fetchData();
    });
    const sortUpdate = (event) => {
      if (event.length) {
        sortData.value = {
          key: event[0].key,
          order: event[0].order,
        };
      } else {
        sortData.value = null;
      }
      fetchData();
    };
    const clearAllFilter = (event) => {
      textFilter.value = [];
      multipleFilter.value = [];
      fetchData();
    };
    const textFilterChange = (event) => {
      let index = textFilter?.value?.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value) {
          textFilter.value[index] = event;
        } else {
          textFilter.value.splice(index, 1);
        }
      } else {
        textFilter.value.push(event);
      }
      fetchData();
    };
    const multipleFiltersChange = (event) => {
      let index = multipleFilter.value?.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value?.length) {
          multipleFilter.value[index] = event;
        } else {
          multipleFilter.value.splice(index, 1);
        }
      } else {
        multipleFilter.value.push(event);
      }
      fetchData();
    };
    const sortData = ref({
      key: "documentUploadDate",
      order: "desc",
    });
    const getLookupData = (Filter) => {
      axios
        .post(
          `${CONFIG_ENDPOINT.GET_PROJECT_DOCUMENTS_LOOKUP_LIST}/${props.id}`,
          Filter
        )
        .then((response) => {
          console.log("lookupData", response.data);
          lookupData.value = response.data;

          headers.value.forEach((header, index) => {
            headers.value[index] = {
              ...header,
            };
          });
        })
        .catch((err) => {
          lookupData.value = null;
        });
    };
    async function fetchData() {
      let Filter = {
        documentType: null,
        railRoadName: null,
        dotNumber: null,
      };
      textFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter.key) && filter.value) {
          Filter[filter.key] = filter.value;
        }
      });
      multipleFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter?.key) && filter.value) {
          Filter[filter.key] = filter.value;
        }
      });
      let filterText =
        "?page=" +
        (paginationData.value.page - 1) +
        "&size=" +
        paginationData.value.items;
      if (sortData.value) {
        filterText =
          filterText +
          "&sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order;
      }
      let doctype = props?.doctype;
      let response = await documentStore.getDocumentsWithFilter(
        props.id,
        doctype,
        sortData.value,
        Filter,
        filterText
      );
      if (response) {
        getLookupData(Filter);
        let data = response.data || [];
        data?.forEach((item) => {
          item.documentUploadDate = getDate(item.documentUploadDate);
          item.hideOtherButtonKey = item?.source?.toLowerCase() === "system";
          item.fileName =
            "<i class='tableIcons fa fa-download' aria-hidden='true' style='color:#003e7e;'></i><span>" +
            item.fileName +
            "</span>";

          item.hideLink = item?.version <= 1;
        });
        filesList.value = data;
        emit("filesLength", filesList.value.length);
      } else {
        filesList.value = [];
        emit("filesLength", filesList.value.length);
        paginationData.value = {
          page: 1,
          items: paginationData.value.items,
          total: 0,
        };
      }
    }
    const downloadFile = (fileDetails) => {
      let docId = fileDetails.value?.escDocumentId;
      let endpoint = CONFIG_ENDPOINT.DOWNLOAD_SCOPING_FILE;
      documentStore.downloadFile(docId, props.id, endpoint);
    };
    const linkClicked = (event) => {
      if (event.key === "fileName") {
        downloadFile(event);
      } else if (event.key === "version") {
        openPriorityProject(event);
      }
    };
    const openPriorityProject = (event) => {
      priorityProjectDialogRef.value
        .open(
          "View Project Versioning",
          {},
          props?.id,
          event.value.parentDocumentId
        )
        .then(() => {});
    };

    const deleteButtonClicked = (event) => {
      console.log("event", event);
    };
    const otherButtonClicked = (event) => {
      emit("otherButtonClicked", event);
    };
    return {
      deleteButtonClicked,
      downloadFile,
      linkClicked,
      sortUpdate,
      headers,
      filesList,
      loading,
      fetchData,
      paginationData,
      heading,
      otherButtonClicked,
      DocumentsVersionDialogComponent,
      priorityProjectDialogRef,
      lookupData,
      clearAllFilter,
      textFilterChange,
      multipleFiltersChange,
      multipleFilter,
    };
  },
};
