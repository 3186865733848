import {  requiredRule,
  validDateFutureDate_Inspections,
  validDateFutureRule,
} from "@/composables/validationRules";
import moment from "moment";
import { computed, onMounted, ref, watch } from "vue";
export default {
  name: "DatePicker",
  props: [
    "maxDate",
    "minDate",
    "value",
    "rules",
    "invalidMsg",
    "isPucDocket",
    "disabled",
  ],
  emits: ["input", "dateValidation"],
  watch: {
    value(value) {
      if (this.isValidLocalDate({ value: value })) {
        this.dateValue = value;
      }
    },
  },
  setup(props, context) {
    let maxYear = ref(new Date());
    let minYear = ref(new Date() - 10);
    let dateValue = ref("");
    let invalidDate = ref(false);

    onMounted(() => {
      console.log("date changed", props?.value);
      context.emit("dateValidation", isValidLocalDate(props));
    });
    watch(
      () => [props?.value],
      () => {
        console.log("date changed", props?.value);
        dateValue.value = props?.value;
      },
      { deep: true }
    );
    watch(
      () => [props?.rules],
      () => {
        if (props?.rules?.length && props?.isPucDocket) {
          updateDate({ target: dateValue });
        }
      },
      { deep: true }
    );
    const maxDate = computed({
      get: () => {
        return props?.maxDate || new Date().toLocaleDateString("fr-ca");
      },
    });
    const minDate = computed({
      get: () => {
        let today = new Date();
        let numberOfDaysToSubtract = 10;
        let tenYearsMinus = today.setFullYear(
          today.getFullYear() - numberOfDaysToSubtract
        );
        return (
          props?.minDate || new Date(tenYearsMinus).toLocaleDateString("fr-ca")
        );
      },
    });
    const disabled = computed({
      get: () => {
        if (props?.disabled) {
          invalidDate.value = false;
          dateValue.value = "";
        }
        return props?.disabled;
      },
    });
    const value = computed({
      get: () => {
        if (props?.value) {
          dateValue.value = props?.value;
        }
        return props?.value;
      },
    });
    const rules = computed({
      get: () => {
        let fieldRules = props?.rules?.map((rule) => rule.name);
        return fieldRules;
      },
    });
    const getInvalidMsg = computed({
      get: () => {
        let currentValue = dateValue.value;

        if (!currentValue) {
          return props?.rules?.includes("requiredRule") ? "Required" : "";
        }
        if (currentValue) {
          if (currentValue?.length != 10) {
            return "Invalid Date";
          }
          let selectedDate = new Date(currentValue).setHours(0, 0, 0, 1);

          let maxDate = props?.maxDate
            ? new Date(props?.maxDate).setHours(0, 0, 0, 1)
            : new Date().setHours(0, 0, 0, 1);
          let minDateValue = new Date(props?.minDate).setHours(0, 0, 0, 1);
          if (!moment(currentValue, "YYYY-MM-DD", true).isValid()) {
            return "Invalid Date";
          } else if (selectedDate > maxDate) {
            return /* props?.invalidMsg  */ "Date cannot be in the future";
          } else if (minDateValue && selectedDate < minDateValue) {
            return /* props?.invalidMsg  */ "Date cannot be in past";
          } else {
            return "";
          }
        }
        return "";
      },
    });
    const updateDate = (event) => {
      dateValue.value = event.target.value;
      let isDateValid = isValidLocalDate(dateValue);
      context.emit("dateValidation", isDateValid);
      if (isDateValid) {
        invalidDate.value = false;
        context.emit("input", event.target.value);
      } else {
        invalidDate.value = true;
      }
    };
    const isValidLocalDate = (date) => {
      if (props?.disabled) {
        return true;
      }
      if (!date.value) {
        return props?.rules?.includes("requiredRule") ? false : true;
      }
      if (date.value?.length != 10) {
        return false;
      }
      let selectedDate = new Date(dateValue.value).setHours(0, 0, 0, 1);
      let maxDate = props?.maxDate
        ? new Date(props?.maxDate).setHours(0, 0, 0, 1)
        : new Date().setHours(0, 0, 0, 1);
      let minDateValue = new Date(props?.minDate).setHours(0, 0, 0, 1);
      let returnValue =
        moment(date.value, "YYYY-MM-DD", true).isValid() &&
        selectedDate < maxDate;
      if (minDateValue) {
        return returnValue && selectedDate > minDateValue;
      }
      return returnValue;
    };
    return {
      maxYear,
      minYear,
      dateValue,
      maxDate,
      minDate,
      value,
      rules,
      updateDate,
      isValidLocalDate,
      invalidDate,
      requiredRule,
      validDateFutureDate_Inspections,
      validDateFutureRule,
      getInvalidMsg,
      disabled,
    };
  },
};
