<template>
  <v-card-text>
    <ViewScopingFieldView
      @valueChangedEvent="$emit('valueChangedEvent', $event)"
    ></ViewScopingFieldView>
  </v-card-text>
</template>
<script>
import ViewScopingFieldView from "@/modules/scoping-field-view/components/ViewScopingFieldView.vue";
export default {
  name: "ScopingFieldView-page",
  components: {
    ViewScopingFieldView: ViewScopingFieldView,
  },
};
</script>
