<template>
  <div>
    <v-row>
      <v-col class="ml-20">
        <router-link :to="`/GCMS/${id}`">
          &lt; Return to Project Information Page
        </router-link>
      </v-col>
      <v-col class="page-title">
        <h1>Railroad Estimates</h1>
      </v-col>
      <v-col> </v-col>
    </v-row>
  </div>
  <div>
    <v-card class="p-64">
      <FeatureTable
        :headers="headers"
        :items="railroadEstimatesList"
        :linkedPagination="false"
        :hidePagination="true"
        :hideFilter="true"
        :itemsPerPage="paginationData?.items"
        :totalItems="paginationData?.total"
        @sortUpdate="sortUpdate"
        @editButtonClicked="editButtonClicked"
        @otherButtonClicked="otherButtonClicked"
        @deleteButtonClicked="deleteButtonClicked"
        tableName="railroadEstimates"
        :isEditDisabled="!isUserEditRailroadEstimateAccess.read"
      >
      </FeatureTable>
    </v-card>

    <div class="d-flex w-100 p-20">
      <PrimaryButton
        :buttonText="'Add'"
        @buttonClicked="addButtonClicked"
        :disabled="!isUserEditRailroadEstimateAccess.update"
      ></PrimaryButton>
    </div>
  </div>
  <DialogComponent1 ref="railroadEstimatesDocumentsDialogRef"></DialogComponent1>
  <DialogComponent2 ref="deleteEstimateDialogRef"></DialogComponent2>

</template>
<script>
import railroadEstimates from "@/modules/manageproject/scripts/railroadEstimates.js";
export default railroadEstimates;
</script>

<style>
@import "@/assets/css/style.scss";
</style>
