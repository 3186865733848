<template>
  <div class="expanderOverLay">
    <flaggerActivityAdmin></flaggerActivityAdmin>
  </div>
</template>
<script>
import flaggerActivityAdmin from "@/modules/gcmsmain/admin/submenu/components/flaggerActivityAdmin.vue";
export default {
  name: "ViewFlaggerActivityAdmin-page",
  components: {
    flaggerActivityAdmin: flaggerActivityAdmin,
  },
};
</script>
