<template>
  <v-footer>
    <div class="messageBannerExternal" style="height: fit-content; width: 100%">
      <div class="messageRow">
        <span class="p-10"
          ><h3 style="color: white"><b>GCMS is Coming Soon!</b></h3>
          <p class="mt-5">
            With PennDOT’s new Grade Crossing Management System, you will be
            able to:
          </p>
          <div class="ml-40">
            <ul>
              <li>Manage and access detailed project information</li>
              <li>
                Record, predict and manage project flagger needs across the
                Commonwealth
              </li>
              <li>Submit and manage Force Account Reimbursement Requests</li>
              <li>
                Manage, access and maintain statewide Grade Crossing Inventory
                data and documents
              </li>
            </ul>
          </div>
        </span>
      </div>
    </div>
  </v-footer>
</template>
<script>
export default {
  name: "ExternalBanner",
  /*  setup() {
    const bannerImageSrc = ref(null);
    const showBanner = ref(false);
    const checkShowBanner = () => {
      console.log("Making API call to fetch banner");
      axios
        .get(CONFIG_ENDPOINT.CHECK_EXTERNAL_BANNER)
        .then((response) => {
          console.log("API response:", response);
          setImage(response);
        })
        .catch((error) => {
          console.error("Failed to load banner image:", error);
          showBanner.value = false;
        });
    };
    const setImage = (response) => {
      if (
        response.data?.responseCode === "1000" &&
        response.data?.data?.data?.content
      ) {
        let file = response.data.data.data;
        if (file.filename && file.content) {
          const fileTypeMapping = {
            ".pdf": "application/pdf",
            ".csv": "text/csv",
            ".xlsx":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ".png": "image/png",
            ".jpg": "image/jpg",
            ".txt": "text/plain",
            ".PNG": "image/PNG",
            ".JPG": "image/JPG",
          };

          let fileExtension = file.filename.slice(
            file.filename.lastIndexOf(".")
          );
          let contentType = fileTypeMapping[fileExtension];
          bannerImageSrc.value = `data:${contentType};base64,${file.content}`;
          showBanner.value = true;
          console.log(
            "Banner image source set successfully",
            bannerImageSrc.value
          );
        } else {
          showBanner.value = false;
          console.error("Invalid response or no data in response");
        }
      }
    };
    onMounted(() => {
      console.log("Component mounted, triggering API call");
      checkShowBanner();
    });
    const showBannerImg = computed({
      get: () => {
        return bannerImageSrc.value;
      },
    });
    return { bannerImageSrc, showBanner, showBannerImg };
  }, */
};
</script>
<style>
@import "@/assets/css/main.scss";
@import "@/assets/css/color.scss";
@import "@/assets/css/style.scss";
</style>