<template>
  <v-card-text>
    <ManageFlaggerAndRailroads></ManageFlaggerAndRailroads>
  </v-card-text>
</template>
<script>
import ManageFlaggerAndRailroads from "@/modules/manageproject/components/ManageFlaggerAndRailroads.vue";
export default {
  name: "viewManageFlaggerAndRailroads-page",
  components: {
    ManageFlaggerAndRailroads: ManageFlaggerAndRailroads,
  },
};
</script>
