import { DROPDOWN_NO_DATA } from "@/constants/displayTexts";import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import { reactive, ref, toRefs } from "vue";
export default {
  components: { PrimaryButton, SecondaryButton },
  setup() {
    let lastCrossingMessage = ref("");
    let message = ref("");
    const state = reactive({
      dialog: false,
      resolve: null,
      reject: null,
    });

    const open = (message1, message2) => {
      state.dialog = true;
      lastCrossingMessage.value = message1;
      message.value = message2;

      return new Promise((resolve, reject) => {
        state.resolve = resolve;
        state.reject = reject;
      });
    };

    const closeDialog = () => {
      state.resolve(false);
      state.dialog = false;
    };

    const removeRailroad = () => {
      state.resolve(true);
      state.dialog = false;
    };
    return {
      closeDialog,
      ...toRefs(state),
      open,
      removeRailroad,
      lastCrossingMessage,
      message,
      ref,
      DROPDOWN_NO_DATA,
    };
  },
};
