import { CONFIG_ENDPOINT } from "@/constants/Endpoints";import DialogComponent from "@/modules/gcmsmain/admin/submenu/components/DistrictOfficeDialog.vue";
import OnlineHelpPOC from "@/modules/shared/components/OnlineHelpPOC.vue";
import VueTable from "@/modules/shared/components/VueTable.vue";
import axios from "axios";
import { onMounted, ref } from "vue";
import { RBAC_CONTROLLER } from "../../../../../constants/rbacconstants";
import { userRoleBasedAccessStore } from "../../../../../stores/userRoleBasedAccess";
export default {
  name: "RecentProjects",
  components: { VueTable, DialogComponent, OnlineHelpPOC },
  setup() {
    let formData = ref(null);
    const districtOfficeDialog = ref(null);
    const districts = ref([]);
    let itemIndex = ref(null);
    const loading = ref(true);
    let errorMessage = ref(" ");
    const columnData = ref([
      {
        name: "District",
        key: "displayDistrict",
      },
      {
        name: "District Address",
        key: "address1",
      },
      {
        name: "District Address 2",
        key: "address2",
      },
      {
        name: "City",
        key: "city",
      },
      {
        name: "State",
        key: "state",
      },
      {
        name: "ZIP",
        key: "zipcode",
      },
      {
        name: "DCM Email(s) ",
        key: "emailStrings",
      },
      {
        name: "Action",
        key: "",
        isAction: true,
        isAdd: false,
        isEdit: true,
        editDisable: { key: "railroad", value: null },
        isDelete: false,
        columnWidth: "6",
      },
    ]);
    const isDistrictOfficeUpdateAccess = ref(false);
    const userRoleBasedAccessService = userRoleBasedAccessStore();

    onMounted(async () => {
      getDistrictOfficesUpdateAccess();
      await fetchData();
    });
    const getDistrictOfficesUpdateAccess = async () => {
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.DISTRICT_OFFICE_CRTUPD_ACCESS
      );
      isDistrictOfficeUpdateAccess.value = updateResponse;
      columnData.value[columnData.value.length - 1].isEdit =
        isDistrictOfficeUpdateAccess.value;
    };
    async function fetchData() {
      loading.value = true;
      axios
        .get(CONFIG_ENDPOINT.DISTRICT_OFFICE_ADMIN)
        .then((response) => {
          loading.value = false;
          districts.value = response.data;
          for (let district in districts.value) {
            districts.value[district].emailStrings =
              districts.value[district].emailStrings.join(", ");
          }
          errorMessage.value = "No Data Available";
          sortList(districts);
        })
        .catch((err) => {
          loading.value = false;
          districts.value = [];
          errorMessage.value = "No Data Available";
        });
    }

    const sortList = (list) => {
      list.value?.sort((a, b) => {
        if (a?.districtOfficeId > b?.districtOfficeId) return 1;
        if (a?.districtOfficeId < b?.districtOfficeId) return -1;
        return 0;
      });
    };
    const openDialog = (item) => {
      fetchData();
      itemIndex.value = item.index;
      formData.value = { ...item.item };
      districtOfficeDialog.value
        .open("District Office Details", formData.value, itemIndex.value)
        .then((resolve) => {
          if (resolve) {
            fetchData();
          }
        });
    };

    return {
      districts,
      loading,
      columnData,
      formData,
      districtOfficeDialog,
      itemIndex,
      openDialog,
      sortList,
      errorMessage,
    };
  },
};
