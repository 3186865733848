<template>
  <div>
    <VueTable
      class="costAndFundingTable"
      :tableData="WBSInfoData"
      :columnData="columnData"
      :tableHeader="'WBS Information'"
    ></VueTable>
  </div>
</template>
<script>
import WbsInfo from "@/modules/cost-and-funding/scripts/WbsInfo.js";
export default WbsInfo;
</script>
<style>
@import "@/assets/css/style.scss";
</style>
