import { CONFIG_ENDPOINT } from "@/constants/Endpoints";import VueTable from "@/modules/shared/components/VueTable.vue";
import axios from "axios";
import { onMounted, ref, watch } from "vue";

const programFundingInfo = ref([]);
const columnData = ref([
  {
    name: "Phase",
    key: "mpmsPhaseCode",
    isBold: true,
    alignLeft: true,
    columnWidth: "10",
  },
  { name: "Total", key: "totalPhaseCost", columnWidth: "18" },
  {
    name: "Federal",
    key: "fedPhaseCost",
    secondLineKey: "fedPhaseCostPercent",
    columnWidth: "18",
  },
  {
    name: "State",
    key: "statePhaseCost",
    secondLineKey: "statePhaseCostPercent",
    columnWidth: "18",
  },
  {
    name: "Local",
    key: "localPhaseCost",
    secondLineKey: "localPhaseCostPercent",
    columnWidth: "18",
  },
  {
    name: "Other",
    key: "otherPhaseCost",
    secondLineKey: "otherPhaseCostPercent",
    columnWidth: "18",
  },
]);

export default {
  name: "ProgramFundingInfo",
  components: { VueTable },
  props: { id: { type: Number, default: null } },

  setup(props) {
    onMounted(async () => {
      await getProgramFundingInfoData();
    });

    watch(
      () => [props.id],
      () => {
        if (props.id) {
          getProgramFundingInfoData();
        }
      },
      { deep: true }
    );

    async function getProgramFundingInfoData() {
      await axios
        .get(
          CONFIG_ENDPOINT.COSTANDFUNDING_ESTIMATED_PROGRAM_FUNDING_INFO +
            props.id
        )
        .then((response) => {
          programFundingInfo.value = response.data;

          programFundingInfo.value.forEach((project, index) => {
            programFundingInfo.value[index] = {
              ...project,
              totalPhaseCost:
                project?.fedPhaseCost +
                project?.statePhaseCost +
                project?.localPhaseCost +
                project?.otherPhaseCost,
            };
          });

          programFundingInfo.value.forEach((project, index) => {
            programFundingInfo.value[index] = {
              ...project,
              totalPhaseCost:
                "$" +
                parseFloat(project?.totalPhaseCost)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              fedPhaseCost:
                "$" +
                parseFloat(project?.fedPhaseCost)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              fedPhaseCostPercent:
                " (" +
                ((project?.fedPhaseCost / project?.totalPhaseCost) * 100
                  ? (project?.fedPhaseCost / project?.totalPhaseCost) * 100
                  : 0
                ).toFixed(1) +
                "%)",
              statePhaseCost:
                "$" +
                parseFloat(project?.statePhaseCost)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              statePhaseCostPercent:
                " (" +
                ((project?.statePhaseCost / project?.totalPhaseCost) * 100
                  ? (project?.statePhaseCost / project?.totalPhaseCost) * 100
                  : 0
                ).toFixed(1) +
                "%)",
              localPhaseCost:
                "$" +
                parseFloat(project?.localPhaseCost)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              localPhaseCostPercent:
                " (" +
                ((project?.localPhaseCost / project?.totalPhaseCost) * 100
                  ? (project?.localPhaseCost / project?.totalPhaseCost) * 100
                  : 0
                ).toFixed(1) +
                "%)",
              otherPhaseCost:
                "$" +
                parseFloat(project?.otherPhaseCost)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              otherPhaseCostPercent:
                " (" +
                ((project?.otherPhaseCost / project?.totalPhaseCost) * 100
                  ? (project?.otherPhaseCost / project?.totalPhaseCost) * 100
                  : 0
                ).toFixed(1) +
                "%)",
            };
          });
        })
        .catch((err) => {
          programFundingInfo.value = [];
        });
    }
    return {
      programFundingInfo,
      columnData,
    };
  },
};
