import DataTableRowHandler from "@/modules/shared/components/DataTableRowHandler.vue";import DialogComponent from "@/modules/shared/components/SharedConfirmationDialog.vue";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { VueDraggableNext } from "vue-draggable-next";
export default defineComponent({
  name: "DraggableTable",
  components: {
    draggable: VueDraggableNext,
    DataTableRowHandler,
    DialogComponent,
  },
  props: [
    "dragDisableCheck",
    "dragKey",
    "table1Title",
    "table2Title",
    "list1",
    "list2",
    "table1Header",
    "table2Header",
    "instruction1",
    "instruction2",
    "linkIndicator",
    "id",
    "disableCheckbox",
    "NoRailroadInvolvement",
  ],
  setup(props, context) {
    const checkboxConfirmationDialog = ref(null);
    let enabled = ref(true);
    let dragging = ref(false);
    let id = ref(props?.id);
    let NoRailroadInvolvement = ref(props?.NoRailroadInvolvement);
    onMounted(() => {
      NoRailroadInvolvement.value = props?.NoRailroadInvolvement;
      console.log("NoRailroadInvolvement", NoRailroadInvolvement);
    });
    watch(
      () => [props?.NoRailroadInvolvement],
      () => {
        NoRailroadInvolvement.value = props?.NoRailroadInvolvement;
        console.log("NoRailroadInvolvement", NoRailroadInvolvement);
      },
      { deep: true }
    );
    let disableCheckbox = computed({
      get: () => {
        return props?.disableCheckbox;
      },
    });
    let dragDisableCheck = computed({
      get: () => {
        return props?.dragDisableCheck || {};
      },
    });
    let dragKey = computed({
      get: () => {
        return props?.dragKey || "";
      },
    });
    let table1Title = computed({
      get: () => {
        return props?.table1Title || "";
      },
    });
    let table2Title = computed({
      get: () => {
        return props?.table2Title || "";
      },
    });
    let list1 = computed({
      get: () => {
        return props?.list1 || [];
      },
    });
    let list2 = computed({
      get: () => {
        return props?.list2 || [];
      },
    });
    let table1Header = computed({
      get: () => {
        return props?.table1Header || [];
      },
    });
    let table2Header = computed({
      get: () => {
        return props?.table2Header || [];
      },
    });
    let instruction1 = computed({
      get: () => {
        return props?.instruction1 || "";
      },
    });
    let instruction2 = computed({
      get: () => {
        return props?.instruction2 || "";
      },
    });

    let linkIndicator = computed({
      get: () => {
        return props?.linkIndicator || "";
      },
    });
    const add = () => {
      console.log("add");
    };
    const replace = () => {
      console.log("replace");
    };
    const checkMoveList2 = (event) => {
      return dragDisableCheck.value?.[0]?.value.includes(
        event?.draggedContext?.element?.[dragDisableCheck.value?.[0]?.key]
      );
    };
    const checkMove = (event) => {
      let disableCheck = null;
      dragDisableCheck.value.map((dragOption) => {
        let isDisabled = false;
        if (dragOption.key === "sourceid") {
          isDisabled = dragOption.value.includes(
            event?.draggedContext?.element?.[dragOption.key]
          );
        } else {
          isDisabled =
            event?.draggedContext?.element?.[dragOption.key] ===
            dragOption.value;
        }

        if (disableCheck == null) {
          disableCheck = isDisabled;
        } else {
          disableCheck = disableCheck && isDisabled;
        }
      });
      return disableCheck;
    };
    const logList1 = (event) => {
      const { moved, added } = event;

      if (moved) {
        console.log("moved", moved.element[dragKey.value]);
      }
      if (added) {
        context.emit("addToList1", added.element);
      }
    };
    const logList2 = (event) => {
      const { moved, added } = event;

      if (moved) {
        console.log(moved);
      }
      if (added) {
        let index = list1.value.findIndex(
          (item) => item[dragKey.value] == added.element[dragKey.value]
        );
        if (index >= 0) {
          // eslint-disable-next-line vue/no-mutating-props
          list1.value.splice(index, 1);
          context.emit("addToList2", added.element);
        }
      }
    };
    const updateNoRailroadInvolvement = (event) => {
      if (event) {
        checkboxConfirmationDialog.value
          .open("Warning", [
            "WARNING: By selecting “No Railroad Involvement” you are certifying there are no railroad crossings and/or railroad tracks impacted by the limits of the project. A letter certifying there is no railroad involvement will be generated and sent to your District Contract Management Unit for inclusion into the ECMS contract.",
            "Click OK to continue and set the Project Status to Completed.",
            "Click Cancel to return to the page and clear the No Railroad Involvement checkbox.",
          ])
          .then((resolve) => {
            if (resolve) {
              context.emit("yesClicked", true);
            }
          });
      }
    };

    return {
      enabled,
      dragging,
      dragDisableCheck,
      dragKey,
      table1Title,
      table2Title,
      list1,
      list2,
      table1Header,
      table2Header,
      instruction1,
      instruction2,
      linkIndicator,
      add,
      replace,
      checkMove,
      checkMoveList2,
      logList1,
      logList2,
      id,
      updateNoRailroadInvolvement,
      NoRailroadInvolvement,
      checkboxConfirmationDialog,
      disableCheckbox,
    };
  },
});
