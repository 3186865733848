<template>
  <div
    v-if="tableHeader || showClearFilterButton || showExportButton"
    class="d-flex align-items-center justify-content-between primary-fill-buttons p-10"
  >
    <h1 class="font-normal f-size24 color-blue">{{ tableHeader }}</h1>
    <div>
      <router-link
        class="font-normal f-size14 color-blue p-20"
        :to="viewAllProjectRoute"
        v-if="routerText"
      >
        <i
          class="fa fa-arrow-circle-right"
          style="color: #2a578d; font-size: 20px; margin: 8px"
        ></i
        >{{ routerText }}</router-link
      >
      <v-btn
        v-if="showClearFilterButton"
        :disabled="!filteredHeaders?.length"
        @click="clearAllFilters()"
        variant="text"
        style="color: #2a578d !important; background-color: white !important"
        >Clear All Filters</v-btn
      >
      <v-btn
        v-if="showExportButton"
        :disabled="
          linkedPagination ? !filteredData?.length : !tableData?.length
        "
        @click="exportList(exportType)"
        variant="outlined"
        >Export List</v-btn
      >
    </div>
  </div>
  <v-data-table
    :page="page"
    v-model:sort-by="sortBy"
    :headers="headers"
    :items="linkedPagination ? filteredData : tableData"
    :items-per-page="itemsPerPage"
    :search="search"
    :hide-default-footer="hidePagination"
    :items-per-page-text="'Rows per page'"
    item-key="id"
    id="list"
    class="elevation-1 w-100 p-10"
    :class="{ 'hide-pagination': hidePagination }"
    must-sort="true"
  >
    <template v-slot:body>
      <tr class="header" v-if="items?.length && !hideFilter">
        <td v-for="(header, headerIndex) in headers" :key="header">
          <v-select
            :menu="focusTextKey && focusTextKey === header.key"
            bg-color="white"
            v-if="header.filterType === 'multi-select'"
            v-model="header.filter"
            :placeholder="header.title"
            :items="getItems(header.key, header.filterSort)"
            item-title="value"
            item-value="value"
            :disabled="
              checkFieldDisabled(
                header?.key,
                'multi-select',
                header?.filterSort
              )
            "
            @update:modelValue="focusTextKey = header.key"
            multiple
            ><template #prepend-item>
              <div
                class="w-100 d-flex align-items-center justify-content-end select-prepend-item"
              >
                <IconButton
                  @buttonClicked="
                    filterValues(
                      header?.filter,
                      header.key,
                      header.filterType,
                      header?.filterWith
                    );
                    resetFocusTextKey();
                  "
                  :buttonText="'Apply'"
                  :iconButton="true"
                  :iconName="'mdi-check'"
                ></IconButton>
                <IconButton
                  v-if="!checkIfOldFilterIsPresent(header?.key)"
                  @buttonClicked="
                    resetOldFilter(header?.key, headerIndex);
                    resetFocusTextKey();
                    focusTextKey = null;
                  "
                  :buttonText="'Cancel'"
                  :iconButton="true"
                  :iconName="'mdi-close'"
                ></IconButton>
                <IconButton
                  v-if="checkIfOldFilterIsPresent(header?.key)"
                  @buttonClicked="
                    resetOldFilter(header?.key, headerIndex, true);
                    resetFocusTextKey();
                    focusTextKey = null;
                  "
                  :buttonText="'Clear'"
                  :iconButton="true"
                  :iconName="'mdi-close'"
                ></IconButton>
              </div> </template
          ></v-select>
          <v-autocomplete
            :menu="focusTextKey && focusTextKey === header.key"
            bg-color="white"
            v-if="header.filterType === 'auto-complete'"
            v-model="header.filter"
            :placeholder="header.title"
            :items="getItems(header.key, header.filterSort)"
            item-title="value"
            item-value="value"
            :disabled="
              checkFieldDisabled(
                header?.key,
                'auto-complete',
                header?.filterSort
              )
            "
            @update:modelValue="focusTextKey = header.key"
            multiple
            ><template #prepend-item>
              <div
                class="w-100 d-flex align-items-center justify-content-end select-prepend-item"
              >
                <IconButton
                  @buttonClicked="
                    filterValues(
                      header.filter,
                      header.key,
                      header.filterType,
                      header?.filterWith
                    );
                    resetFocusTextKey();
                  "
                  :buttonText="'Apply'"
                  :iconButton="true"
                  :iconName="'mdi-check'"
                ></IconButton>
                <IconButton
                  v-if="!checkIfOldFilterIsPresent(header?.key)"
                  @buttonClicked="
                    resetOldFilter(header?.key, headerIndex);
                    resetFocusTextKey();
                    focusTextKey = null;
                  "
                  :buttonText="'Cancel'"
                  :iconButton="true"
                  :iconName="'mdi-close'"
                ></IconButton>
                <IconButton
                  v-if="checkIfOldFilterIsPresent(header?.key)"
                  @buttonClicked="
                    resetOldFilter(header?.key, headerIndex, true);
                    resetFocusTextKey();
                    focusTextKey = null;
                  "
                  :buttonText="'Clear'"
                  :iconButton="true"
                  :iconName="'mdi-close'"
                ></IconButton>
              </div> </template
          ></v-autocomplete>
          <v-text-field
            v-if="header.filterType === 'textbox'"
            bg-color="white"
            :placeholder="header.title"
            v-model="header.filter"
            :autofocus="focusTextKey && focusTextKey === header.key"
            :disabled="
              checkFieldDisabled(header?.key, 'textbox', header?.filterSort)
            "
            @keyup="
              textFieldFilter(
                $event,
                header.key,
                header.filterType,
                header.filterWith
              )
            "
          ></v-text-field>
        </td>
      </tr>
      <tr
        v-for="item in linkedPagination ? filteredData : tableData"
        :key="item"
        @mouseover="clickable ? selectItem(item) : ''"
        @click="clickable ? createRouter(item) : ''"
        :class="{
          selectedRow: clickable && item === selectedItem,
        }"
      >
        <td
          v-for="header in headers"
          :key="header"
          @mouseover="clickable ? selectItem(item) : ''"
          :class="{
            selectedRow: clickable && item === selectedItem,
            'is-link': header?.isLink,
          }"
        >
          <span v-if="header?.columnType != 'icon'">
            <span v-if="!header?.isLink"
              >{{ header.key != "clear" ? item[header.key] : "" }}
            </span>
            <span
              v-if="header?.isLink"
              @click="linkClicked(header.key, item[header.key])"
              >{{ header.key != "clear" ? item[header.key] : "" }}</span
            >
          </span>
          <div class="left" v-if="header.columnType === 'icon'">
            <div class="d-flex icon-alignment" v-html="item[header.icon]"></div>
          </div>
        </td>
      </tr>
      <tr v-if="linkedPagination ? !filteredData?.length : !tableData?.length">
        <td :colspan="headers?.length" class="text-center">
          {{ noDataDisplayText || "No Data Available" }}
          <span
            v-if="noDataDisplayText && noDataDisplayText.includes('Loading')"
          >
            <i
              class="fa fa-hourglass-half"
              aria-hidden="true"
              style="size: 18px; margin-right: 10px; color: #000000"
            ></i>
          </span>
        </td>
      </tr>
    </template>
    <template v-slot:bottom v-if="!hidePagination">
      <div
        class="text-center pt-2 d-flex justify-content-end"
        v-if="getPaginationLength > 0 && !linkedPagination"
        id="paginationId"
      >
        <div class="d-flex align-items-center w-11">
          <span class="mr-20 pagination-labels">Rows per page</span>
          <div class="w-40">
            <v-select
              v-model="itemsPerPage"
              :items="[5, 10, 15, 20, 30, 50, 100]"
            ></v-select>
          </div>
        </div>
        <div
          class="d-flex align-items-center w-14 ml-10 justify-content-around"
        >
          <span class="mr-20 pagination-labels" pagination-labels>Page</span>
          <div
            class="d-flex flex-column justify-content-end"
            style="width: 80px !important"
          >
            <v-text-field
              style="width: 80px !important"
              v-model.number="jumpPage"
              @keyup="jumpToPage"
              outlined
              type="number"
            >
            </v-text-field>
            <h6
              v-if="!jumpPage || jumpPage < 1 || jumpPage > getPaginationLength"
              class="color-red text-left pagination-labels"
            >
              Page Range (1 - {{ getPaginationLength }})
            </h6>
          </div>
          <span class="w-30 text-left pagination-labels"
            >of {{ getPaginationLength }}</span
          >
        </div>
        <div class="pagination-div">
          <v-pagination
            v-model="pageNumber"
            :length="getPaginationLength"
            rounded="0"
          ></v-pagination>
        </div>
      </div>
      <div
        class="text-center pt-2 d-flex justify-content-end"
        v-if="getPaginationLengthLinked > 0 && linkedPagination"
        id="paginationId"
      >
        <div class="d-flex align-items-center w-11">
          <span class="mr-20 pagination-labels">Rows per page</span>
          <div class="w-40">
            <v-select
              v-model="itemsPerPage"
              :items="[5, 10, 15, 20, 30, 50, 100]"
              @update:modelValue="updatePaginationPerPage($event)"
            ></v-select>
          </div>
        </div>
        <div
          class="d-flex align-items-center w-14 ml-10 justify-content-around"
        >
          <span class="mr-20 pagination-labels">Page</span>
          <div
            class="d-flex flex-column justify-content-end"
            style="width: 80px !important"
          >
            <v-select
              v-model="jumpPage"
              :items="getPagesList()"
              @update:modelValue="jumpToPageLinkedPagination($event)"
            ></v-select>
            <h6
              v-if="
                !jumpPage ||
                jumpPage < 1 ||
                jumpPage > getPaginationLengthLinked
              "
              class="color-red text-left pagination-labels"
            >
              Page Range (1 - {{ getPaginationLengthLinked }})
            </h6>
          </div>
          <span class="w-30 text-left pagination-labels"
            >of {{ getPaginationLengthLinked }}</span
          >
        </div>
        <div class="pagination-div">
          <v-pagination
            v-model="pageNumber"
            :length="getPaginationLengthLinked"
            rounded="0"
          ></v-pagination>
        </div>
      </div>
    </template>
  </v-data-table>
</template>
<script>
import DataTable from "@/modules/shared/scripts/DataTable.js";
export default DataTable;
</script>
<style>
@import "@/modules/shared/assets/dataTable.scss";
@import "@/assets/css/style.scss";
</style>
