<template>
  <v-row>
    <v-col cols="3" class="ml-20">
      <router-link :to="`/GCMS/${id}`">
        &lt; Return to Project Information Page
      </router-link>
    </v-col>
    <v-col cols="7" class="page-title">
      <h1>Project Crossing Inventory Updates</h1>
    </v-col>
    <v-col> </v-col>
  </v-row>
  <div>
    <div class="env-header mb-20">
      <h2 class="locationHeaderText">Crossing Updates</h2>
    </div>
  </div>
  <div
    v-if="constructionList.length"
    class="p-64 d-flex justify-content-center flex-column align-items-center"
  >
    <div class="d-flex align-items-center w-90">
      <h4 class="w-30 d-flex justify-content-start"><b>DOT#</b></h4>
      <h4 class="w-50"><b> Inventory Updated? </b></h4>
    </div>
    <div
      class="d-flex align-items-center w-90"
      v-for="item of constructionList"
      :key="item"
    >
      <span class="w-30 d-flex justify-content-start"
        ><p
          class="color-blue underline cursor-pointer text-left"
          @click="dotLinkClicked(item?.dotNumber)"
        >
          {{ item?.dotNumber }}
        </p></span
      >
      <div class="w-50">
        <v-checkbox
          color="#2a578d"
          v-model="item.inventoryUpdated"
          :disabled="isCheckboxDisabled"
        ></v-checkbox>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <PrimaryButton
        @buttonClicked="saveData"
        :disabled="!valueChanged || isCheckboxDisabled"
        :buttonText="'Save'"
      ></PrimaryButton>
      <SecondaryButton
        @buttonClicked="cancelClicked"
        :disabled="!valueChanged || isCheckboxDisabled"
        :buttonText="'Cancel'"
        class="ml-2"
      ></SecondaryButton>
    </div>
  </div>
  <div
    v-else
    class="d-flex justify-content-center align-items-center no-data-message"
  >
    <p class="text-center">No crossings require inventory update.</p>
  </div>
</template>
<script>
import ConstructionPhase from "@/modules/manageproject/scripts/ConstructionPhase.js";
export default ConstructionPhase;
</script>
<style>
@import "@/assets/css/style.scss";
.text-center {
  text-align: center;
}
.no-data-message {
  margin-bottom: 50px;
}
</style>