import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import sharedUserService from "@/modules/shared/service/sharedUserService";
import axios from "axios";
import { computed, reactive, ref, toRefs, watch } from "vue";
import { CONFIG_ENDPOINT } from "../../../constants/Endpoints";
export default {
  components: { PrimaryButton, SecondaryButton },
  setup() {
    const state = reactive({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      event: null,
    });
    let valueChanged = ref(false);
    const originalValue = ref();
    const isInitialised = ref(false);
    const selectedValue = ref(null);
    const lookupData = ref([]);
    const open = (title, event) => {
      state.dialog = true;
      state.title = title;
      console.log("event", event);
      getLookupList(event);
      return new Promise((resolve, reject) => {
        state.resolve = resolve;
        state.reject = reject;
      });
    };
    const userData = computed(() => {
      return sharedUserService.getSharedData();
    });
    const getLookupList = (event) => {
      axios
        .get(
          CONFIG_ENDPOINT.CHANGE_STATUS_GET_LOOKUP +
            "userID=" +
            userData.value?.userID +
            "&statusCode=" +
            event?.projectStatusWFDTO?.projectStatusCode
        )
        .then((res) => {
          console.log("Res", res.data);
          lookupData.value = res.data.data?.data || [];
          state.event = {
            ...event,
            lookup: lookupData.value.map((item) => item.projectStatusName),
          };
          setTimeout(() => {
            isInitialised.value = true;
          }, 1000);
        })
        .catch((err) => {});
    };
    watch(selectedValue, (newValue, oldValue) => {
      if (isInitialised.value && newValue !== oldValue) {
        valueChanged.value = true;
      } else {
        valueChanged.value = false;
      }
    });
    const saveData = () => {
      const selectedStatus = lookupData.value.find(
        (item) => item.projectStatusName === selectedValue.value
      );
      axios
        .put(
          CONFIG_ENDPOINT.UPDATE_PROJECT_STATUS +
            state.event?.mpmsNumber +
            "?userID=" +
            userData.value?.userID +
            "&status=" +
            selectedStatus?.projectStatusCode
        )
        .then((res) => {
          state.resolve({ success: true });
          state.dialog = false;
        })
        .catch((err) => {
          state.resolve({ success: false, errorMsg: err });
          state.dialog = false;
        });
    };
    const closeDialog = () => {
      if (isInitialised.value && valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          state.resolve(false);
          state.dialog = false;
          valueChanged.value = false;
        }
      } else {
        state.resolve(false);
        state.dialog = false;
      }
    };
    const afterModalLeave = () => {
      valueChanged.value = false;
      isInitialised.value = false;
      originalValue.value = null;
    };
    window.onbeforeunload = function () {
      if (isInitialised.value && valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };
    return {
      ...toRefs(state),
      saveData,
      closeDialog,
      open,
      afterModalLeave,
      selectedValue,
      valueChanged,
    };
  },
};
