raws <template>
  <v-card>
    <FeatureTable
      :headers="headers"
      :items="activeProjects"
      :showExportButton="true"
      :exportFilName="'Upcoming Tasks'"
      :showClearFilterButton="true"
      :tableHeader="'My Upcoming Tasks '"
      :clickable="true"
      :itemsPerPage="paginationData?.items"
      :linkedPagination="true"
      :totalItems="paginationData?.total"
      :lookupData="lookupData"
      :oldMultipleFilter="multipleFilter"
      :isDynamicExport="true"
      :routerText2="'View All Tasks'"
      :isRowClickedRouteEvent="true"
      :noDataAvailableText="noDataAvailableText"
      @rowClicked="rowClicked($event)"
      @updatePaginationPerPage="updatePaginationPerPage"
      @pageChange="pageChange"
      @sortUpdate="sortUpdate"
      @textFilterChange="textFilterChange"
      @multipleFiltersChange="multipleFiltersChange"
      @clearAllFilter="clearAllFilter"
      @exportButtonClicked="exportButtonClicked"
    >
    </FeatureTable>
  </v-card>
</template>

<script>
import MyUpcomingTasks from "@/modules/gcmsmain/dashboard/scripts/MyTasks.js";
export default MyUpcomingTasks;
</script>
<style>
@import "@/assets/css/style.scss";
.container {
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
  padding-top: 20px;
}
</style>
