<template>
  <v-card class="mt-20 color-lightGrey">
    <v-card-title class="export-section"
      ><v-row
        ><v-col cols="12"
          ><v-row class="pt-l20"
            ><v-col cols="8"
              ><h4>
                <b>{{ sectionTitle }}</b>
              </h4></v-col
            >
            <v-col cols="4" class="primary-buttons justify-content-end d-flex"
              ><v-btn variant="outlined" @click="exportComment(exportType)"
                >Export Comments</v-btn
              ></v-col
            >
          </v-row>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <div class="comment-card">
        <div :id="exportType">
          <v-card class="mt-20">
            <v-card-text
              class="comment-data"
              v-for="comment in projectComments"
              :key="comment"
            >
              <h3>{{ comment.username }}, {{ comment.userRole }}</h3>
              <h4>
                {{
                  getformatDate(comment.rowCrtTs) 
                  
                }}
              </h4>
              <h4>{{ comment.comment }}</h4>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import PreviousComments from "@/modules/projectinformation/scripts/PreviousComments.js";
export default PreviousComments;
</script>
<style>
@import "@/modules/projectinformation/assets/previousComments.scss";
</style>
