import "@mdi/font/css/materialdesignicons.css";import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { VDataTable } from "vuetify/labs/VDataTable";
import * as labsComponents from "vuetify/labs/components";
import "vuetify/styles";
// Vuetify
import { createVuetify } from "vuetify";

export default createVuetify({
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  components: { ...components, ...labsComponents, VDataTable },
  directives,
});
