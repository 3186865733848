import useDateField from "@/composables/dateField.js";import {
  arrayRequiredRule,
  onlyFutureDateAccepted,
  requiredRule,
} from "@/composables/validationRules";
import BannerMessage from "@/modules/shared/components/BannerMessage.vue";
import CurrencyInput from "@/modules/shared/components/CurrencyInput.vue";
import DatePicker from "@/modules/shared/components/DatePicker.vue";
import DocumentsList from "@/modules/shared/components/DocumentsList.vue";
import LoadingOverlay from "@/modules/shared/components/LoadingOverlay.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import DialogComponent from "@/modules/shared/components/UploadUpdateRailroadDotDocumentDialog.vue";
import VueAutoComplete from "@/modules/shared/components/VueAutoComplete.vue";
import sharedUserService from "@/modules/shared/service/sharedUserService";
import router from "@/router";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import { computed, onMounted, ref, watch } from "vue";
import { onBeforeRouteLeave, useRoute } from "vue-router";
import { MAX_DATE } from "../../../../../constants/dates";
import { AUTO_COMPLETE_NO_DATA } from "../../../../../constants/displayTexts";
import { CONFIG_ENDPOINT } from "../../../../../constants/Endpoints";
import { RBAC_CONTROLLER } from "../../../../../constants/rbacconstants";
export default {
  components: {
    PrimaryButton,
    SecondaryButton,
    BannerMessage,
    CurrencyInput,
    DialogComponent,
    DocumentsList,
    DatePicker,
    VueAutoComplete,
    LoadingOverlay,
  },
  name: "EditAddConsultantAgreement",
  props: ["dotId"],
  setup(props) {
    const loading = ref(false);
    const userData = computed({
      get: () => {
        return sharedUserService.getSharedData();
      },
    });
    let isFormValid = ref(false);
    const route = useRoute();
    const isAdd = route.params.type === "add";
    const id = ref(
      route.params.railroadConsultantAgreementId &&
        route.params.railroadConsultantAgreementId != "null"
        ? route.params.railroadConsultantAgreementId
        : ""
    );
    const minDate = ref();
    const maxDate = ref();
    const consultantAgreementQuery = ref({
      railroadName: "",
      consultantCompany: "",
      selectedProjects: "",
      projectScope: "",
    });
    const consultantAgreementLookup = ref({
      railroadName: [],
      consultantCompany: [],
      selectedProjects: [],
      projectScope: [],
    });
    const consultantAgreementForm = ref({
      railroadName: "",
      railroadCompanyId: null,
      consultantCompany: "",
      selectedProjects: [],
      consultant: [],
      projectScope: "",
      endDate: null,
      isActive: "Y",
      status: "",
    });
    const consultantDetails = ref();
    const uploadUpdateDocumentsRef = ref();
    const valueChanged = ref(false);
    const isInitialised = ref(false);
    const originalConsultantAgreementForm = ref({
      railroadName: "",
      railroadCompanyId: null,
      consultantCompany: "",
      selectedProjects: [],
      consultant: [],
      projectScope: "",
      endDate: null,
      isActive: "Y",
      status: "",
    });
    const dateChange = (event) => {
      if (event?.target?.value) {
        consultantAgreementForm.value.endDate = getDate(event.target.value);
      } else if (event) {
        consultantAgreementForm.value.endDate = getDate(event);
      }
    };
    const isDateValidated = ref(false);
    const checkDateValidation = (value) => {
      isDateValidated.value = value;
    };
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const isConsultantAgreementAccess = ref({
      read: false,
      update: false,
    });
    watch(
      () => [route.params.railroadConsultantAgreementId],
      () => {
        if (
          route.params.railroadConsultantAgreementId &&
          route.params.railroadConsultantAgreementId != "null"
        ) {
          id.value = route.params.railroadConsultantAgreementId;
        }
      }
    );
    const accessCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.GRADE_CROSSING_RECORD_READ_ACCESS
      );
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.GRADE_CROSSING_RECORD_CRTUPD_ACCESS
      );
      isConsultantAgreementAccess.value.read = readResponse || false;
      isConsultantAgreementAccess.value.update = updateResponse || false;
    };
    onMounted(async () => {
      await accessCheck();
      maxDate.value = new Date(MAX_DATE).toLocaleDateString("fr-ca");
      minDate.value = new Date().toLocaleDateString("fr-ca");
      if (id.value) {
        isInitialised.value = false;
        if (isConsultantAgreementAccess.value.read) {
          loading.value = true;
          fetchConsultantAgreementData();
        }
      } else {
        loading.value = false;
        isInitialised.value = true;
      }
    });
    const fetchConsultantAgreementData = () => {
      isInitialised.value = false;
      valueChanged.value = false;

      if (
        route.params.railroadConsultantAgreementId &&
        route.params.railroadConsultantAgreementId != "null"
      ) {
        id.value = route.params.railroadConsultantAgreementId;
      }
      axios
        .get(CONFIG_ENDPOINT.CONSULTANT_AGREEMENT_GET_BY_ID + id.value)
        .then((res) => {
          setEditData(res?.data?.data);
          consultantDetails.value = res?.data?.data;
        })
        .catch((err) => {
          loading.value = false;
        });
    };

    const setEditData = async (data) => {
      consultantAgreementQuery.value.railroadName = data?.railroadCompanyName;
      consultantAgreementQuery.value.consultantCompany =
        data?.consultantCompaniesDTO?.companyName;
      await autoComplete("railroadName");
      await autoComplete("consultantCompany");
      consultantAgreementForm.value.railroadName = data?.railroadCompanyName;
      consultantAgreementForm.value.consultantCompany =
        data?.consultantCompaniesDTO?.orgID;
      await valueSelected("railroadName");

      await valueSelected("consultantCompany");

      consultantAgreementForm.value.consultant =
        data?.consultantCompaniesUsersDTO || [];

      consultantAgreementForm.value.projectScope = data?.scopeOfProject;
      consultantAgreementForm.value.selectedProjects = data?.selectedProjects;
      consultantAgreementForm.value.endDate =
        data?.endDate?.toLocaleDateString("fr-ca");
      consultantAgreementForm.value.isActive = data?.isActive;
      originalConsultantAgreementForm.value = {
        ...JSON.parse(JSON.stringify(consultantAgreementForm.value)),
      };
      isInitialised.value = true;
      loading.value = false;
    };

    watch(
      () => [consultantAgreementForm],
      () => {
        if (
          isInitialised.value &&
          !objectsAreSame(
            originalConsultantAgreementForm.value,
            consultantAgreementForm.value
          )
        ) {
          valueChanged.value = true;
        } else {
          valueChanged.value = false;
        }
      },
      { deep: true }
    );
    const getDate = (date) => {
      if (date && typeof date === "object") {
        return getFormattedDateStringNoTime(date);
      } else if (date?.includes("-")) {
        return getFormattedDateStringNoTime(new Date(date));
      } else {
        return date;
      }
    };
    const { getFormattedDateStringNoTime } = useDateField();
    const arraysEqual = (a, b) => {
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;
      for (let i = 0; i < a.length; ++i) {
        if (!b.includes(a[i])) return false;
      }
      return true;
    };
    const arrayOfObjectsEqual = (a, b) => {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a?.length !== b?.length) return false;

      for (let i = 0; i < a.length; ++i) {
        if (!objectsAreSame(a[i], b[i])) return false;
      }
      return true;
    };
    const objectsAreSame = (x, y) => {
      let objectsAreSame = true;
      for (let propertyName in x) {
        if (propertyName === "selectedProjects") {
          if (!arraysEqual(x[propertyName], y[propertyName])) {
            objectsAreSame = false;
            break;
          }
        } else if (propertyName === "consultant") {
          if (!arrayOfObjectsEqual(x[propertyName], y[propertyName])) {
            objectsAreSame = false;
            break;
          }
        } else if (x[propertyName] !== y[propertyName]) {
          objectsAreSame = false;
          break;
        }
      }
      return objectsAreSame;
    };
    onBeforeRouteLeave((to, from, next) => {
      if (isInitialised.value && valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          valueChanged.value = false;
          next();
        } else {
          next(false);
        }
      } else {
        valueChanged.value = false;
        next();
      }
    });
    window.onbeforeunload = function () {
      if (isInitialised.value && valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };

    const cancelSave = () => {
      isInitialised.value = false;
      valueChanged.value = false;
      router.push({
        path: "/GCMS/consultant-agreement",
      });
    };

    const filesList = ref([]);
    const otherButtonClicked = () => {
      openDialog(false);
    };
    const updateButtonClicked = (event) => {
      openDialog(true, event);
    };
    const formData = ref([]);
    const updateDocumentList = ref(false);
    const openDialog = (isUpdate, event) => {
      formData.value = [];
      let uploadUpdateConfig = isUpdate
        ? {
            isDynamicDocumentType: false,
            staticDocumentTypeList: "Consultant Agreement"
              ? ["Consultant Agreement"]
              : [],

            staticDocumentTypeValue: "Consultant Agreement"
              ? "Consultant Agreement"
              : "",

            isRailroadDropdownStatic: true,
            staticRailroadDropdownList: event.railRoadName
              ? [event.railRoadName]
              : [],

            staticRailroadDropdownValue: event.railRoadName
              ? event.railRoadName
              : "",
            staticDOTDropdownValue: event.dotNumber ? event.dotNumber : "",
            previewDocTypeUrl: "'CONSULTANT_AGREEMENT_DOCUMENTS_DOCTYPE_LIST'",
            mpmsId: id.value,
            isRailroadConsultantAgreementId: true,
            maximumFilesUploadRange: 1,
            showDocTypeText: true,
            showRailroadTypeText: true,
            checkMaximumFilesUploaded: true,
            checkMaxFileSize: true,
          }
        : {
            isDynamicDocumentType: false,
            staticDocumentTypeValue: "Consultant Agreement",
            previewDocTypeUrl: "CONSULTANT_AGREEMENT_DOCUMENTS_DOCTYPE_LIST",
            mpmsId: id.value,
            isRailroadConsultantAgreementId: true,
          };
      uploadUpdateDocumentsRef.value
        .open(
          isUpdate
            ? "Update Railroad Estimate Document"
            : "View/Upload Railroad Estimate Documents",
          id.value,
          isUpdate,
          event,
          uploadUpdateConfig,
          { docType: "Consultant Agreement" }
        )
        .then((resolve) => {
          if (resolve) {
            // refresh data of required
            fetchEstimateData();
          }
        });
    };
    async function fetchEstimateData() {
      updateDocumentList.value = true;
    }
    const updateDocumentListFlag = () => {
      updateDocumentList.value = false;
    };
    const autoComplete = async (field) => {
      if (
        (field === "railroadName" || field === "consultantCompany") &&
        consultantAgreementQuery.value?.[field]
      ) {
        await fetchLookup(field, consultantAgreementQuery.value?.[field]);
      } else {
        consultantAgreementQuery.value[field] = [];
      }
    };
    const valueSelected = async (field) => {
      if (field === "railroadName") {
        await axios
          .post(CONFIG_ENDPOINT.CONSULATNT_AGREEMENT_SELECTED_PROJECT_SOP, {
            railroadCompanyName: consultantAgreementForm.value?.[field],
          })
          .then((res) => {
            consultantAgreementForm.value["selectedProjects"] = [];
            consultantAgreementForm.value["projectScope"] = "";
            consultantAgreementLookup.value["selectedProjects"] =
              res.data.data.selecttedProjects || [];
            consultantAgreementLookup.value["projectScope"] =
              res.data.data.scopeOfProject || [];
          })
          .catch((err) => {
            consultantAgreementForm.value["selectedProjects"] = [];
            consultantAgreementForm.value["projectScope"] = "";
            consultantAgreementLookup.value["selectedProjects"] = [];
            consultantAgreementLookup.value["projectScope"] = [];
          });
      } else if (field === "consultantCompany") {
        let selectedConsultantCompany = consultantAgreementLookup.value[
          field
        ]?.filter((consultant) => {
          return consultant.orgID === consultantAgreementForm.value?.[field];
        })?.[0];
        if (selectedConsultantCompany) {
          await axios
            .post(
              CONFIG_ENDPOINT.CONSULATNT_AGREEMENT_CONSULTANT_lOOKUP,
              selectedConsultantCompany
            )
            .then((res) => {
              consultantAgreementForm.value["consultant"] = [];
              consultantAgreementLookup.value["consultant"] =
                res.data.data || [];
            })
            .catch((err) => {
              consultantAgreementForm.value["consultant"] = [];
              consultantAgreementLookup.value["consultant"] = [];
            });
        }
      } else if (field === "projectScope") {
        consultantAgreementForm.value["selectedProjects"] = [];
      }
    };
    const fetchLookup = async (field, searchText) => {
      if (field === "railroadName") {
        await axios
          .post(CONFIG_ENDPOINT.CONSULATNT_AGREEMENT_RAILROAD_LIST_LOOKUP, {
            railroadName: searchText,
          })
          .then((res) => {
            consultantAgreementLookup.value[field] =
              res.data.railroadName || [];
          })
          .catch((err) => {
            consultantAgreementLookup.value[field] = [""];
          });
      } else if (field === "consultantCompany") {
        await axios
          .get(
            CONFIG_ENDPOINT.CONSULATNT_AGREEMENT_CONSULTANT_COMPANY_lOOKUP +
              searchText
          )
          .then((res) => {
            consultantAgreementLookup.value[field] = res.data.data || [];
          })
          .catch((err) => {
            consultantAgreementLookup.value[field] = [];
          });
      }
    };
    const uploadedFilesLength = ref(0);
    const filesLength = (length) => {
      uploadedFilesLength.value = length;
    };
    const getPayload = () => {
      let selectedConsultantCompany = consultantAgreementLookup.value[
        "consultantCompany"
      ]?.filter((consultant) => {
        return (
          consultant.orgID ===
          consultantAgreementForm.value?.["consultantCompany"]
        );
      })?.[0];
      let selectedConsultantConsultants = consultantAgreementLookup.value[
        "consultant"
      ]?.filter((consultant) => {
        return consultantAgreementForm.value?.["consultant"]?.includes(
          consultant.userID
        );
      });
      const payload = {
        railroadConsultantAgreementId: isAdd ? null : id.value,
        railroadCompanyName: consultantAgreementForm.value?.railroadName,
        scopeOfProject: consultantAgreementForm.value?.projectScope,
        selectedProjects: consultantAgreementForm.value?.selectedProjects?.map(
          (project) => "" + project
        ),
        endDate: getDate(consultantAgreementForm.value?.endDate),
        isActive: consultantAgreementForm.value?.isActive,
        consultantCompaniesDTO: selectedConsultantCompany,
        consultantCompaniesUsersDTO: selectedConsultantConsultants,
      };
      return payload;
    };
    const bannerDetails = ref({
      showBanner: false,
      isSuccess: false,
      message: "",
    });

    const hideBanner = () => {
      setBannerDetails(false, false, "");
    };
    const setBannerDetails = (showBanner, isSuccess, message) => {
      bannerDetails.value = {
        showBanner: showBanner,
        isSuccess: isSuccess,
        message: message,
      };
      if (showBanner) {
        window.scrollTo(0, 0);
      }
    };
    const saveConsultantAgreement = async () => {
      loading.value = true;
      const payload = getPayload();

      await axios
        .post(CONFIG_ENDPOINT.CONSULTANT_AGREEMENT_ADD, payload)
        .then((res) => {
          setBannerDetails(true, true, res?.data?.data?.businessMessage);
          loading.value = false;
          isInitialised.value = false;
          valueChanged.value = false;
          if (!id.value && res?.data?.data?.railroadConsultantAgreementId) {
            if (res?.data?.data?.railroadConsultantAgreementId) {
              id.value = res?.data?.data?.railroadConsultantAgreementId;
            }
            router.push({
              path:
                "/GCMS/consultant-agreement/add/" +
                res?.data?.data?.railroadConsultantAgreementId,
            });
          } else if (isAdd && id.value) {
            if (res?.data?.data?.railroadConsultantAgreementId) {
              id.value = res?.data?.data?.railroadConsultantAgreementId;
            }
            router.push({
              path:
                "/GCMS/consultant-agreement/edit/" +
                res?.data?.data?.railroadConsultantAgreementId,
            });
          }
          fetchConsultantAgreementData();
        })
        .catch((err) => {
          loading.value = false;
        });
    };
    const submitConsultantAgreement = async () => {
      loading.value = true;
      const payload = getPayload();
      submitAPICall(payload);
    };
    const submitAPICall = async (payload) => {
      await axios
        .post(CONFIG_ENDPOINT.CONSULTANT_AGREEMENT_SUBMIT, payload)
        .then((res) => {
          setBannerDetails(true, true, res?.data?.data?.businessMessage);
          loading.value = false;
          isInitialised.value = false;
          valueChanged.value = false;
          router.push({
            path: "/GCMS/consultant-agreement",
          });
        })
        .catch((err) => {
          loading.value = false;
        });
    };
    const removeConsultant = (index) => {
      consultantAgreementForm.value.consultant?.splice(index, 1);
    };
    const getConsultantName = (id) => {
      return consultantAgreementLookup.value.consultant?.filter(
        (consultant) => consultant.userID === id
      )?.[0]?.fullName;
    };
    return {
      getConsultantName,
      removeConsultant,
      bannerDetails,
      submitConsultantAgreement,
      saveConsultantAgreement,
      valueSelected,
      consultantAgreementForm,
      uploadUpdateDocumentsRef,
      otherButtonClicked,
      updateDocumentList,
      getDate,
      updateDocumentListFlag,
      updateButtonClicked,
      isFormValid,
      consultantDetails,
      id,
      arrayRequiredRule,
      requiredRule,
      onlyFutureDateAccepted,
      dateChange,
      checkDateValidation,
      valueChanged,
      minDate,
      maxDate,
      consultantAgreementQuery,
      autoComplete,
      consultantAgreementLookup,
      isDateValidated,
      uploadedFilesLength,
      filesLength,
      isAdd,
      cancelSave,
      hideBanner,
      userData,
      filesList,
      loading,
      AUTO_COMPLETE_NO_DATA,
      isInitialised,
      isConsultantAgreementAccess,
    };
  },
};
