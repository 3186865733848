import BridgeImage from "@/assets/images/Highway_Bridge.png";import SafetyImage from "@/assets/images/pnghero_train-rail-transport-level-crossing-traffic-sign-road.png";
import useDateField from "@/composables/dateField.js";
import useFieldEntry from "@/composables/fieldEntry";
import useIconsFunctions from "@/composables/projectIdentifiersIcons";
import useSharedFunctions from "@/composables/shared";
import {
  mpmsLengthRule,
  mpmsRequiredRule,
} from "@/composables/validationRules";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import DraggableTable from "@/modules/shared/components/DraggableTable.vue";
import FeatureTable from "@/modules/shared/components/FeatureTable.vue";
import LoadingOverlay from "@/modules/shared/components/LoadingOverlay.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import axios from "axios";
import { computed, onMounted, ref, watch } from "vue";

import OnlineHelpPOC from "@/modules/shared/components/OnlineHelpPOC.vue";
import sharedUserService from "@/modules/shared/service/sharedUserService";
import { onBeforeRouteLeave, useRouter } from "vue-router";
export default {
  name: "App",
  components: {
    FeatureTable,
    PrimaryButton,
    DraggableTable,
    LoadingOverlay,
    OnlineHelpPOC,
  },
  setup() {
    const { getIdentifierIcon } = useIconsFunctions();
    const inventorylist = ref([]);
    const loading = ref(true);
    const mpmsIdFormValid = ref(false);
    const lastEnteredMpmsValue = ref("");
    const mpmsValue = ref("");
    const showInvalidMPMS = ref(false);
    const userData = computed({
      get: () => {
        return sharedUserService.getSharedData();
      },
    });
    const headers = ref([
      {
        title: "",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
        key: "projectIdentifier",
        columnType: "icon",
        icon: "projectIdentifierIcon",
        sortable: false,
      },
      {
        title: "MPMS#",
        filter: null,
        filterType: "single-auto-complete",
        sortType: "number",
        key: "mpmsNumber",
        isLink: true,
      },
      {
        title: "Project Title",
        filter: null,
        filterType: "single-auto-complete",
        sortType: "text",
        key: "projectTitle",
      },
      {
        title: "Project Class",
        filter: null,
        filterType: "single-auto-complete",
        sortType: "text",
        key: "projectClass",
      },
      {
        title: "SR",
        filter: [],
        filterType: "multi-select",
        filterSort: "number",
        sortType: "text",
        key: "stateRoute",
      },
      {
        title: "SEC",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
        key: "srSection",
      },
      {
        title: "County",
        filter: [],
        filterType: "auto-complete",
        filterSort: "text",
        sortType: "text",
        key: "county",
      },
      {
        title: "Municipality",
        filter: [],
        filterType: "auto-complete",
        filterSort: "text",
        sortType: "text",
        key: "municipality",
      },
      {
        title: "PS&E Date",
        filter: [],
        filterType: "multi-select",
        filterSort: "date",
        sortType: "date",
        key: "pseDate",
      },
      {
        title: "Let Date",
        filter: [],
        filterType: "multi-select",
        filterSort: "date",
        filterWith: "yearMonth",
        sortType: "date",
        key: "letDate",
        initialSort: true,
        sortOrder: "asc",
      },
      {
        title: "Notice to Proceed Date",
        filter: [],
        filterType: "multi-select",
        filterSort: "date",
        sortType: "date",
        key: "noticeToProceedDate",
      },
    ]);
    const route = useRouter();
    const { keydownAllowNumberOnlyWithEnter } = useFieldEntry();
    const { getFormattedDateStringNoTime } = useDateField();
    const paginationData = ref({
      page: 1,
      items: 15,
      total: 0,
    });
    const sortData = ref({
      key: "letDate",
      order: "asc",
    });
    let noDataAvailableText = ref(" ");
    const textFilter = ref([]);
    const multipleFilter = ref([]);
    const lookupData = ref(null);
    const currentFilters = ref(null);
    const { ExportToCSV } = useSharedFunctions();
    const errorMessage = ref("");
    const getDate = (date) => {
      return getFormattedDateStringNoTime(date);
    };
    onBeforeRouteLeave((to, from, next) => {
      showInvalidMPMS.value = false;
      lastEnteredMpmsValue.value = "";
      next();
    });
    onMounted(async () => {
      await fetchData();
    });
    watch(
      () => [mpmsValue.value],
      () => {
        if (mpmsValue.value) {
          let regex = /^\d\d*$/;
          lastEnteredMpmsValue.value = mpmsValue.value.trim();
          if (!regex.test(lastEnteredMpmsValue.value)) {
            mpmsValue.value = "";
            lastEnteredMpmsValue.value = "";
          } else {
            showInvalidMPMS.value = false;
          }
        }
      },
      { deep: true }
    );
    const getLookupData = (filterText, Filter) => {
      axios
        .post(
          CONFIG_ENDPOINT.GCMS_MAIN_CREATE_PROJECT_LOOKUP_DATA + filterText,
          Filter
        )
        .then((response) => {
          lookupData.value = response.data;
          headers.value.forEach((header, index) => {
            headers.value[index] = {
              ...header,
              sortable: !checkFieldDisabled(header.key, header?.filterType),
            };
          });
        })
        .catch((err) => {
          lookupData.value = null;
        });
    };
    async function fetchData() {
      loading.value = true;
      let Filter = {
        projectIdentifier: null,
        mpmsNumber: null,
        projectTitle: null,
        projectClass: null,
        stateRoute: null,
        srSection: null,
        county: null,
        municipality: null,
        pseDate: null,
        letDate: null,
        noticeToProceedDate: null,
      };
      textFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter.key) && filter.value) {
          Filter[filter.key] = filter.value;
        }
      });
      multipleFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter.key) && filter.value) {
          Filter[filter.key] = filter.value;
        }
      });
      let filterText =
        "?page=" +
        (paginationData.value.page - 1) +
        "&size=" +
        paginationData.value.items;
      if (sortData.value) {
        filterText =
          filterText +
          "&sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order;
      }
      filterText = filterText + "&userID=" + userData.value?.userID;

      axios
        .post(CONFIG_ENDPOINT.GCMS_MAIN_ALL_MPMS_PROJECTS + filterText, Filter)
        .then((response) => {
          setMPMSProjectDetails(response, Filter, filterText);
        })
        .catch((err) => {
          inventorylist.value = [];
          paginationData.value = {
            page: 1,
            items: paginationData.value.items,
            total: 0,
          };
          noDataAvailableText.value = "No Data Available";
          loading.value = false;
        });
    }
    const setMPMSProjectDetails = (response, Filter, filterText) => {
      let data = response.data.data;
      paginationData.value.total = response.data.totalElements;
      data?.forEach((item) => {
        let icons = "";
        item.projectIdentifier?.forEach((icon) => {
          icons = icons + getIdentifierIcon(icon);
        });
        item.pseDate = getDate(item?.pseDate);
        item.letDate = getDate(item?.letDate);
        item.noticeToProceedDate = getDate(item?.noticeToProceedDate);
        item.projectIdentifierIcon = icons;
      });
      inventorylist.value = data;
      currentFilters.value = Filter;
      getLookupData(filterText, Filter);
      noDataAvailableText.value = "No Data Available";
      loading.value = false;
    };

    const exportButtonClicked = () => {
      let filterText = "?page=" + 0 + "&size=" + paginationData.value.total;
      if (sortData.value) {
        filterText =
          filterText +
          "&sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order;
      }
      axios
        .post(
          CONFIG_ENDPOINT.GCMS_MAIN_ALL_MPMS_PROJECTS + filterText,
          currentFilters.value
        )
        .then((response) => {
          setMPMSExportDetails(response);
        })
        .catch((err) => {});
    };
    const setMPMSExportDetails = (response) => {
      let exportDataList = response.data.data;
      exportDataList?.forEach((item) => {
        let icons = "";
        item.projectIdentifier?.forEach((icon) => {
          icons = icons + getIdentifierIcon(icon);
        });
        item.pseDate = getDate(item?.pseDate);
        item.letDate = getDate(item?.letDate);
        item.noticeToProceedDate = getDate(item?.noticeToProceedDate);
        item.projectIdentifierIcon = icons;
      });
      ExportToCSV(headers.value, exportDataList, "MPMS Projects");
    };
    const checkFieldDisabled = (key, filterType) => {
      if (key === "projectIdentifier") {
        return true;
      }
      if (filterType != "auto-complete" || filterType != "multi-select") {
        return false;
      }
      return !lookupData.value[key]?.length;
    };

    const checkIfMpmsIsValid = (enteredValue) => {
      axios
        .get(
          CONFIG_ENDPOINT.PROJECT_INFO_INFO +
            enteredValue +
            "?createGradeCrossing=true"
        )
        .then((response) => {
          let projectDetails = response.data;
          // Using MpmsNumber from backend to check invalid scenario
          if (!projectDetails.mpmsNumber) {
            showInvalidMPMS.value = true;
            errorMessage.value =
              "MPMS# " + lastEnteredMpmsValue.value + " does not exist";
          } else if (projectDetails.mpmsNumber) {
            showInvalidMPMS.value = false;
            route.push({ path: `/GCMS/createProject/${enteredValue}` });
          }
        })
        .catch((err) => {
          if (err.response.data.businessMessage) {
            showInvalidMPMS.value = true;
            errorMessage.value = err.response.data.businessMessage;
          }
        });
    };

    const submitForm = () => {
      if (mpmsIdFormValid.value) {
        showInvalidMPMS.value = false;
        lastEnteredMpmsValue.value = mpmsValue.value.trim();
        let enteredValue = mpmsValue.value.trim();
        mpmsValue.value = "";
        checkIfMpmsIsValid(enteredValue);
      } else {
      }
    };

    const updatePaginationPerPage = (event) => {
      paginationData.value.page = 1;
      paginationData.value.items = event;
      fetchData();
    };
    const pageChange = (event) => {
      paginationData.value.page = event;
      fetchData();
    };
    const sortUpdate = (event) => {
      sortData.value = {
        key: event[0].key,
        order: event[0].order,
      };
      fetchData();
    };
    const clearAllFilter = (event) => {
      textFilter.value = [];
      multipleFilter.value = [];
      fetchData();
    };
    const textFilterChange = (event) => {
      let index = textFilter.value?.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value) {
          textFilter.value[index] = event;
        } else {
          textFilter.value.splice(index, 1);
        }
      } else {
        textFilter.value.push(event);
      }
    };
    const multipleFiltersChange = (event) => {
      let index = multipleFilter.value?.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value?.length) {
          multipleFilter.value[index] = event;
        } else {
          multipleFilter.value.splice(index, 1);
        }
      } else {
        multipleFilter.value.push(event);
      }
    };
    function keydownAllowNumberOnly(event) {
      if (!event.keyCode != 86 && !event.ctrlKey) {
        if (isLeftUpRightDownBackspaceDeleteTab(event)) {
          return;
        }

        let regex = /\d/;

        if (!regex.test(event.key)) {
          event.preventDefault();
          return false;
        }
      }
    }
    function isLeftUpRightDownBackspaceDeleteTab(event) {
      let key = event.key || event.code;
      return (
        key === "ArrowLeft" ||
        key === 37 ||
        key === "ArrowUp" ||
        key === 38 ||
        key === "ArrowRight" ||
        key === 39 ||
        key === "ArrowDown" ||
        key === 40 ||
        key === "Backspace" ||
        key === 8 ||
        key === "Delete" ||
        key === 46 ||
        key === "Tab" ||
        key === 9
      );
    }
    return {
      inventorylist,
      loading,
      mpmsIdFormValid,
      lastEnteredMpmsValue,
      mpmsValue,
      showInvalidMPMS,
      headers,
      submitForm,
      checkIfMpmsIsValid,
      keydownAllowNumberOnlyWithEnter,
      mpmsLengthRule,
      mpmsRequiredRule,
      updatePaginationPerPage,
      pageChange,
      paginationData,
      sortUpdate,
      multipleFiltersChange,
      textFilterChange,
      lookupData,
      clearAllFilter,
      multipleFilter,
      keydownAllowNumberOnly,
      exportButtonClicked,
      BridgeImage,
      SafetyImage,
      errorMessage,
      noDataAvailableText,
    };
  },
};
