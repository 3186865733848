import { computed, ref } from "vue";

export default {
  name: "CurrencyInput",
  props: {
    modelValue: { default: "" }
},
  setup(props, context) {

    const isInputActive = ref(false);


    const computedValue = computed({
      get: () => {
        if (!props.modelValue){
            return "";
        }
        if (isInputActive.value) {
            if(typeof props.modelValue == "string"){
              return parseFloat(props.modelValue.replace(/[^\d.]/g, ""));
            }else{
              return props.modelValue
            }
        } else {

            if (typeof props.modelValue === 'string'){
                if (props.modelValue?.[0] == '$'){
                    return props.modelValue
                }
                return "$ " + parseFloat(props.modelValue)?.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d{2})?$)/g, "$1,")

            }
            
            return "$ " + props.modelValue?.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d{2})?$)/g, "$1,")
        }
       
      },

      set: (modifiedValue) => {

        let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ""))

        if (isNaN(newValue)) {
            newValue = 0
        }
    
        context.emit("update:modelValue", newValue)
       
      },
    });

    
   

    return {
        isInputActive,
        computedValue,
    };
  },
};
