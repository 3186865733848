<template>
  <div class="d-flex align-items-center">
    <div
      class="mr-20"
      v-for="identifier of projectIdentifierList"
      :key="identifier"
    >
      <span class="ml-10" v-html="getIdentifierIcon(identifier)"></span
      ><span
        ><b>{{ identifier }}</b></span
      >
    </div>
  </div>
</template>
  <script>
import ProjectIdentifierLegend from "@/modules/shared/scripts/ProjectIdentifierLegend.js";
export default ProjectIdentifierLegend;
</script>
  