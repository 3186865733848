import { reactive } from "vue";
const sharedUserService = reactive({
  userData: null,
  setSharedUserData(data) {
    sharedUserService.userData = data;
  },
  getSharedData() {
    return sharedUserService.userData;
  },
});
export default sharedUserService;
