import useDateField from "@/composables/dateField.js";import useIconsFunctions from "@/composables/projectIdentifiersIcons";
import { DROPDOWN_NO_DATA } from "@/constants/displayTexts";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import BannerMessage from "@/modules/shared/components/BannerMessage.vue";
import ExpandedTable from "@/modules/shared/components/ExpandedTable.vue";
import LoadingOverlay from "@/modules/shared/components/LoadingOverlay.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import { onMounted, ref, watch } from "vue";
import { onBeforeRouteLeave, useRoute } from "vue-router";
import { CONFIG_ENDPOINT } from "../../../constants/Endpoints";
export default {
  components: {
    ExpandedTable,
    LoadingOverlay,
    BannerMessage,
    PrimaryButton,
    SecondaryButton,
  },
  title: "ProjectMilestones",
  emits: ["linkClicked", "showBanner"],
  setup(props, { emit }) {
    const route = useRoute();
    const id = route.params.id;
    const { getIdentifierIcon } = useIconsFunctions();
    const subHeaders = ref([
      { name: "Task Name", key: "taskName", alignLeft: true },
      { name: "Due Date", key: "dueDate", alignLeft: true },
      { name: "Assigned User", key: "assignedUser", alignLeft: true },
      { name: "Completed Date", key: "completedDate", alignLeft: true },
      {
        name: "Days Remaining",
        key: "daysRemaining",
        alignLeft: true,
      },
    ]);
    const dataHeaders = ref([
      {
        title: "Project Milestone (opened/closed)",
        key: "milestoneNameWithOpenAndClosedTasks",
        hideFilter: true,
        sortable: false,
        width: "23% !important",
      },
      {
        title: "ASTA Suggested Schedule",
        showButton: true,
        hideFilter: true,
        buttonText: "Use ASTA Schedule",
        key: "astaSuggestedSchedule",
        sortable: false,
        width: "23% !important",
        iconType: "tick",
        showIcon: false,
      },
      {
        title: "MPMS Suggested Schedule",
        buttonText: "Use MPMS Schedule",
        showButton: true,
        hideFilter: true,
        key: "mpmsSuggestedSchedule",
        sortable: false,
        width: "23% !important",
        iconType: "tick",
        showIcon: false,
      },
      {
        title: "Project Schedule",
        hideFilter: true,
        key: "projectSchedule",
        sortable: false,
        columnType: "datePicker",
        showDatePickerKey: "showDatePicker",
        iconKey: "projectScheduleKey",
        width: "15% !important",
        disabled: false, // Will update based on access control
      },
      {
        title: "Actual Dates",
        key: "actualDate",
        sortable: false,
        colorKey: "actualDateColor",
        width: "13%!important",
      },
      { title: " ", key: "data-table-expand", sortable: false, width: "3%" },
    ]);
    const expanded = ref([]);
    const data = ref([]);
    const lookupData = ref(null);
    const loading = ref(true);
    const bannerData = ref();
    const paginationData = ref({
      page: 1,
      items: 15,
      total: 0,
    });
    const railroadList = ref([]);
    const selectedRailroad = ref(null);
    const suggestedSchedule = ref(null);
    const valueChanged = ref(null);
    const isInitialised = ref(null);
    const originalValue = ref(null);
    const isUserAccess = ref({
      read: false,
      update: false,
    });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const accessCheck = async () => {
      const readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.PROJECT_MILESTONES_PAGE_READ_ACCESS
      );
      const updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.PROJECT_MILESTONES_PAGE_CRTUPD_ACCESS
      );
      isUserAccess.value.read = readResponse || false;
      isUserAccess.value.update = updateResponse || false;
      // Dynamically update table headers based on access
      dataHeaders.value.forEach((header) => {
        if (header.key === "projectSchedule") {
          header.disabled = !isUserAccess.value.update;
        }
      });
      // Disable datePicker if only read access
      data.value.forEach((item) => {
        item.showDatePicker = isUserAccess.value.update;
      });
    };
    onMounted(async () => {
      await accessCheck();
      await fetchData();
    });
    const addNewProp = (resp) => {
      let data = resp.data;
      let updatedProjectMilestoneList = resp?.data?.projectMilestoneList?.map(
        (projectMileStone) => {
          return {
            ...projectMileStone,
            milestoneNameWithOpenAndClosedTasks:
              projectMileStone?.panelsRequired === "true"
                ? `${projectMileStone.milestoneName} (${
                    projectMileStone?.noOfOpenTasks ?? "-"
                  }/${projectMileStone?.noOfClosedTasks ?? "-"}) `
                : `${projectMileStone.milestoneName}`,
          };
        }
      );
      return {
        ...resp,
        data: { ...data, projectMilestoneList: updatedProjectMilestoneList },
      };
    };

    async function fetchDataHelper(resp) {
      let res = addNewProp(JSON.parse(JSON.stringify(resp)));
      loading.value = false;
      bannerData.value = res.data.bannerMsg;
      suggestedSchedule.value = res.data.suggestedSchedule;
      if (res.data.suggestedSchedule === "ASTA") {
        dataHeaders.value[1].showIcon = true;
        dataHeaders.value[2].showIcon = false;
      } else if (res.data.suggestedSchedule === "MPMS") {
        dataHeaders.value[2].showIcon = true;
        dataHeaders.value[1].showIcon = false;
      }
      if (res.data?.projectMilestoneList.length) {
        let tableData = res.data?.projectMilestoneList;
        tableData?.forEach((dataItem, index) => {
          tableData[index] = updateTableFields(dataItem, res);
        });
        data.value = tableData;
        let availableButtons = [
          { key: "astaSuggestedSchedule", index: 1 },
          { key: "mpmsSuggestedSchedule", index: 2 },
        ];
        availableButtons.forEach((item) => {
          let availableButtonItems = data.value.filter(
            (milestone) => milestone?.[item.key]
          );
          if (availableButtonItems.length > 0) {
            dataHeaders.value[item.index].buttonDisabled = false;
          } else {
            dataHeaders.value[item.index].buttonDisabled = true;
          }
        });
      } else {
        data.value = [];
      }
      originalValue.value = JSON.parse(JSON.stringify([...data.value]));
      setTimeout(() => {
        isInitialised.value = true;
      }, 1000);
    }
    async function fetchData() {
      loading.value = true;
      isInitialised.value = false;
      valueChanged.value = false;
      emit(
        "MilestonesTabValueChanged",
        isInitialised.value && valueChanged.value
      );
      axios
        .get(CONFIG_ENDPOINT.GET_PROJECT_MILESTONES_LIST + id)
        .then(async (resp) => {
          await fetchDataHelper(resp);
        })
        .catch((err) => {
          loading.value = false;
          data.value = [];
        });
    }
    watch(
      () => [data.value],
      () => {
        if (
          isInitialised.value &&
          !arraysEqual(data.value, originalValue.value)
        ) {
          valueChanged.value = true;
          emit(
            "MilestonesTabValueChanged",
            isInitialised.value && valueChanged.value
          );
        } else {
          valueChanged.value = false;
          emit(
            "MilestonesTabValueChanged",
            isInitialised.value && valueChanged.value
          );
        }
      },
      { deep: true }
    );
    window.onbeforeunload = function () {
      if (isInitialised.value && valueChanged.value) {
        return "You have unsaved data. Are you sure you want to leave the page?";
      }
    };
    onBeforeRouteLeave((to, from, next) => {
      if (isInitialised.value && valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure you want to leave the page?"
        );
        if (answer) {
          next();
          valueChanged.value = false;
          emit(
            "MilestonesTabValueChanged",
            isInitialised.value && valueChanged.value
          );
        } else {
          next(false);
        }
      } else {
        next();
        valueChanged.value = false;
        emit(
          "MilestonesTabValueChanged",
          isInitialised.value && valueChanged.value
        );
      }
    });
    const updateTableFields = (dataItem, res) => {
      let datepickerValidMilestones = [
        "Scoping",
        "Authorization",
        "Coordination",
        "Contract Development",
        "Closeout",
        "Railroad Construction",
      ];
      let updatedDataItem = dataItem;
      if (updatedDataItem.milestoneName === "PUC Closeout") {
        updatedDataItem.disableRow = !res?.data?.pucCloseoutEnabled;
      } else if (updatedDataItem?.panelsRequired != "true") {
        updatedDataItem.hideCollapse = true;
      }
      updatedDataItem.showDatePicker =
        isUserAccess.value.update &&
        datepickerValidMilestones.includes(dataItem.milestoneName);
      updatedDataItem.projectSchedule = formatDatAsPerProjectScheduled(
        dataItem,
        "projectSchedule"
      );
      updatedDataItem.projectScheduleKey =
        updatedDataItem.projectSchedule && updatedDataItem.bellIcon
          ? getDateIcon()
          : "";
      updatedDataItem.taskPanels = updatedDataItem?.taskPanels?.sort((a, b) => {
        if (a.taskStatus === "Completed" && b.taskStatus !== "Completed") {
          return 1;
        } else if (
          a.taskStatus !== "Completed" &&
          b.taskStatus === "Completed"
        ) {
          return -1;
        } else {
          return 0;
        }
      });
      return updatedDataItem;
    };
    const getDateIcon = () => {
      return "<i class='tableIcons fas fa-bell' aria-hidden='true' style='color: #C70000;'></i>";
    };
    const formatDatAsPerProjectScheduled = (dataItem, key) => {
      if (dataItem[key]) {
        if (dataItem.showDatePicker) {
          return new Date(dataItem[key]).toLocaleDateString("fr-ca");
        } else {
          return dataItem[key];
        }
      } else return null;
    };
    const setDate = (date) => {
      return date ? getDate(date) : date;
    };
    const { getFormattedDateStringNoTime } = useDateField();
    const getDate = (date) => {
      return getFormattedDateStringNoTime(date);
    };
    const expandRow = (item, event) => {
      if (event.isExpanded) {
        let index = expanded.value.findIndex((i) => i === item);
        expanded.value.splice(index, 1);
      } else {
        expanded.value.push(item.name);
      }
    };
    const updateRailroad = () => {
      fetchData();
    };
    const hideBanner = () => {
      bannerData.value = "";
    };
    const clickHeaderButton = (key) => {
      if (!isUserAccess.value.update) return; // Prevent actions if no update access
      data.value.forEach((res, index) => {
        data.value[index] = {
          ...res,
          projectSchedule: formatDatAsPerProjectScheduled(res, key),
        };
      });
      if (key === "astaSuggestedSchedule") {
        dataHeaders.value[1].showIcon = true;
        dataHeaders.value[2].showIcon = false;
        suggestedSchedule.value = "ASTA";
      } else if (key === "mpmsSuggestedSchedule") {
        dataHeaders.value[2].showIcon = true;
        dataHeaders.value[1].showIcon = false;
        suggestedSchedule.value = "MPMS";
      }
    };
    const getMilestoneDate = (milestoneName) => {
      let value = data.value.filter(
        (value) => value.milestoneName === milestoneName
      );
      if (value[0]?.projectSchedule?.includes("-")) {
        let dateArray = value[0]?.projectSchedule.split("-");
        return dateArray[1] + "/" + dateArray[2] + "/" + dateArray[0];
      }
      return value[0]?.projectSchedule;
    };
    const saveButtonClick = () => {
      if (!isUserAccess.value.update) return; // Prevent save if no update access
      let payload = {
        scopingDate: getMilestoneDate("Scoping"),
        authorizationDate: getMilestoneDate("Authorization"),
        coordinationDate: getMilestoneDate("Coordination"),
        contractDevelopmentDate: getMilestoneDate("Contract Development"),
        pseDate: getMilestoneDate("PS&E"),
        letDate: getMilestoneDate("Let Date"),
        constructionNoticeToProceedDate: getMilestoneDate(
          "Construction Notice to Proceed"
        ),
        constructionCompleteDate: getMilestoneDate("Construction Complete"),
        pucCloseoutDate: getMilestoneDate("PUC Closeout"),
        closeoutDate: getMilestoneDate("Closeout"),
        gcmsProjectClosedDate: getMilestoneDate("GCMS Project Closed"),
        railroadConstructionDate: getMilestoneDate("Railroad Construction"),
        suggestedSchedule: suggestedSchedule.value,
      };
      axios
        .post(CONFIG_ENDPOINT.GET_PROJECT_MILESTONES_LIST + id, payload)
        .then((res) => {
          window.scrollTo(0, 0);
          emit("showBanner", true);
          isInitialised.value = false;
          valueChanged.value = false;
          emit(
            "MilestonesTabValueChanged",
            isInitialised.value && valueChanged.value
          );
          fetchData();
        })
        .catch((err) => {
          isInitialised.value = false;
          valueChanged.value = false;
          emit(
            "MilestonesTabValueChanged",
            isInitialised.value && valueChanged.value
          );
          fetchData();
        });
    };
    const dateChange = (event) => {
      if (!isUserAccess.value.update) return; // Prevent changes if no update access
      let index = data.value.findIndex(
        (res) => res.milestoneName === event.item.milestoneName
      );
      if (index >= 0) {
        data.value[index] = {
          ...data.value[index],
          projectSchedule: event.updatedValue,
        };
      }
    };
    const arraysEqual = (a, b) => {
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;
      for (let i = 0; i < a.length; ++i) {
        if (!objectsAreSame(a[i], b[i])) return false;
      }
      return true;
    };
    const objectsAreSame = (x, y) => {
      let objectsAreSame = true;
      let checkKeys = [
        "milestoneNameWithOpenAndClosedTasks",
        "astaSuggestedSchedule",
        "mpmsSuggestedSchedule",
        "projectSchedule",
        "actualDate",
      ];
      for (const key of checkKeys) {
        if (
          ((key === "astaSuggestedSchedule" ||
            key === "mpmsSuggestedSchedule" ||
            key === "projectSchedule" ||
            key === "actualDate") &&
            getDate(x["pa1CallDate"]) !== getDate(y["pa1CallDate"])) ||
          x[key] !== y[key]
        ) {
          objectsAreSame = false;
          break;
        }
      }
      return objectsAreSame;
    };
    return {
      dateChange,
      saveButtonClick,
      objectsAreSame,
      expanded,
      getDateIcon,
      valueChanged,
      getIdentifierIcon,
      singleExpand: true,
      expandRow,
      subHeaders,
      dataHeaders,
      data,
      loading,
      paginationData,
      lookupData,
      railroadList,
      selectedRailroad,
      updateRailroad,
      setDate,
      DROPDOWN_NO_DATA,
      bannerData,
      hideBanner,
      clickHeaderButton,
      fetchData,
      isUserAccess, // To be used in the Vue component for controlling access
    };
  },
};
