<template>
  <BannerMessage
    v-if="bannerDetails?.showBanner"
    :successBanner="bannerDetails?.isSuccess"
    :errorBanner="!bannerDetails?.isSuccess"
    :message="bannerDetails?.message"
    @hideBanner="hideBanner"
  ></BannerMessage>
  <v-row>
    <!-- Left corner element -->
    <v-col class="ml-20">
      <router-link :to="`/GCMS/consultant-agreement`">
        &lt; Return to Consultant Agreement List Page
      </router-link>
    </v-col>
    <!-- Centered element -->
    <v-col cols="6" class="page-title">
      <h1>{{ isAdd ? "Add" : "Edit" }} Consultant Agreement</h1>
    </v-col>
    <v-col> </v-col>
  </v-row>
  <div>
    <v-form
      v-model="isFormValid"
      class="projectInvolvement"
      v-if="consultantAgreementForm"
    >
      <div>
        <div class="env-header mb-20 d-flex justify-content-between">
          <h2 class="locationHeaderText">Consultant Agreement Details</h2>
          <h2 class="locationHeaderText">
            Status: {{ consultantDetails?.status }}
          </h2>
          <h2 class="locationHeaderText">Last Review Date:</h2>
        </div>
      </div>
      <div class="w-100">
        <v-row class="m-20">
          <v-col cols="2">
            <b class="textColumn required"
              ><span>Railroad Name: </span></b
            ></v-col
          >
          <v-col cols="3">
            <v-autocomplete
              :no-data-text="AUTO_COMPLETE_NO_DATA"
              class="select-fields"
              outlined
              v-model="consultantAgreementForm.railroadName"
              :disabled="!isConsultantAgreementAccess.update"
              :items="consultantAgreementLookup.railroadName"
              v-model:search="consultantAgreementQuery.railroadName"
              @update:search="autoComplete('railroadName')"
              @update:modelValue="valueSelected('railroadName')"
              :rules="[requiredRule]"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="m-20">
          <v-col cols="2">
            <b class="textColumn required"
              ><span>Consultant Company: </span></b
            ></v-col
          >
          <v-col cols="3">
            <v-autocomplete
              :no-data-text="AUTO_COMPLETE_NO_DATA"
              class="select-fields"
              outlined
              v-model="consultantAgreementForm.consultantCompany"
              :items="consultantAgreementLookup.consultantCompany"
              v-model:search="consultantAgreementQuery.consultantCompany"
              @update:search="autoComplete('consultantCompany')"
              item-value="orgID"
              item-title="companyName"
              @update:modelValue="valueSelected('consultantCompany')"
              :rules="[requiredRule]"
              :disabled="!isConsultantAgreementAccess.update"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="m-20">
          <v-col cols="2"
            ><b class="textColumn required"><span>Consultant: </span></b></v-col
          >

          <v-col cols="3">
            <div v-if="consultantAgreementLookup.consultant?.length">
              <v-chip
                v-for="(
                  consultant, index
                ) in consultantAgreementForm.consultant"
                :key="consultant"
              >
                {{ consultant?.fullName || getConsultantName(consultant) }}
                <template #close>
                  <v-icon
                    icon="mdi-close-circle"
                    @click.stop="removeConsultant(index)"
                  />
                </template>
              </v-chip>
            </div>
            <v-select
              :items="consultantAgreementLookup.consultant"
              v-model="consultantAgreementForm.consultant"
              :disabled="!isConsultantAgreementAccess.update"
              :rules="isInitialised ? [arrayRequiredRule] : []"
              multiple
              :clearable="true"
              item-value="userID"
              item-title="fullName"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="m-20">
          <v-col cols="2"
            ><b class="textColumn required"><span>Scope of Projects: </span></b>
          </v-col>

          <v-col cols="3">
            <v-select
              :items="consultantAgreementLookup.projectScope"
              v-model="consultantAgreementForm.projectScope"
              :rules="[requiredRule]"
              @update:modelValue="valueSelected('projectScope')"
              :disabled="!isConsultantAgreementAccess.update"
            ></v-select>
          </v-col>
          <v-col></v-col>
          <v-col
            v-if="
              consultantAgreementForm?.projectScope &&
              consultantAgreementForm?.projectScope != 'All Projects'
            "
            cols="2"
            ><b class="textColumn required"
              ><span>Selected Projects: </span></b
            ></v-col
          >
          <v-col
            v-if="
              consultantAgreementForm?.projectScope &&
              consultantAgreementForm?.projectScope != 'All Projects'
            "
            cols="3"
          >
            <v-card>
              <v-card-item class="selected-projects-card">
                <v-checkbox
                  v-for="item in consultantAgreementLookup.selectedProjects"
                  :key="item"
                  :disabled="
                    consultantAgreementForm.projectScope === 'All Projects' ||
                    !isConsultantAgreementAccess.update
                  "
                  v-model="consultantAgreementForm.selectedProjects"
                  :label="item"
                  :value="item"
                ></v-checkbox>
                <span v-if="!consultantAgreementLookup.selectedProjects.length"
                  >No Projects Found</span
                >
              </v-card-item>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="m-20">
          <v-col cols="2"
            ><b class="textColumn required"><span>End Date: </span></b></v-col
          >
          <v-col cols="3">
            <DatePicker
              :value="consultantAgreementForm.endDate"
              @input="dateChange($event)"
              :invalidMsg="'End Date'"
              :minDate="minDate"
              :maxDate="maxDate"
              @dateValidation="checkDateValidation($event)"
              :rules="['onlyFutureDateAccepted']"
              :disabled="!isConsultantAgreementAccess.update"
            ></DatePicker>
          </v-col>
        </v-row>

        <v-row class="m-20">
          <v-col cols="2"
            ><b class="textColumn required"><span>Is Active: </span></b></v-col
          >
          <v-col cols="3">
            <v-radio-group
              class="alignRadioButton"
              inline
              :rules="[requiredRule]"
              v-model="consultantAgreementForm.isActive"
              :disabled="!isConsultantAgreementAccess.update"
            >
              <v-radio label="Yes" :value="'Y'"></v-radio>
              <v-radio label="No" :value="'N'"></v-radio> </v-radio-group
          ></v-col>
        </v-row>
      </div>
    </v-form>

    <br /><br />
    <div>
      <div class="env-header mb-20">
        <h2 class="locationHeaderText">Consultant Agreement Documents</h2>
      </div>
      <v-row cols="12">
        <v-col cols="12">
          <div class="view-upload-section">
            <span
              class="color-blue underline cursor-pointer"
              @click="
                isConsultantAgreementAccess.update ? otherButtonClicked() : ''
              "
              >Upload Consultant Agreement Document</span
            >
          </div>
        </v-col>
      </v-row>
      <v-grid v-if="isConsultantAgreementAccess.read">
        <v-row justify="center">
          <v-col cols="11">
            <DocumentsList
              :heading="'Consultant Agreements Document List'"
              :doctype="'/CNSLT_AGR'"
              :id="id"
              :updateDocumentList="updateDocumentList"
              :isRailroadConsultantAgreement="true"
              @updateDocumentListFlag="updateDocumentListFlag"
              @otherButtonClicked="updateButtonClicked($event)"
              @filesLength="filesLength($event)"
            >
            </DocumentsList>
          </v-col>
        </v-row>
        <br /><br />
      </v-grid>
    </div>

    <DialogComponent ref="uploadUpdateDocumentsRef"></DialogComponent>
  </div>

  <div class="w-100 d-flex justify-content-end mt-20 mb-20">
    <PrimaryButton
      :buttonText="'Save'"
      :disabled="
        !isConsultantAgreementAccess.update ||
        !valueChanged ||
        (consultantAgreementForm.endDate && !isDateValidated)
      "
      class="ml-2"
      @buttonClicked="saveConsultantAgreement"
    ></PrimaryButton>
    <PrimaryButton
      :buttonText="'Submit'"
      class="ml-2"
      :disabled="
        !isConsultantAgreementAccess.update ||
        !isFormValid ||
        !isDateValidated ||
        (consultantAgreementForm?.projectScope === 'Select Projects' &&
          !consultantAgreementForm.selectedProjects?.length)
      "
      @buttonClicked="submitConsultantAgreement"
    ></PrimaryButton>
    <SecondaryButton
      :buttonText="'Cancel'"
      class="ml-2"
      @buttonClicked="cancelSave"
    ></SecondaryButton>
  </div>
  <LoadingOverlay :isLoading="loading"> </LoadingOverlay>
</template>
  <script>
import EditAddConsultantAgreement from "@/modules/gcmsmain/admin/submenu/scripts/EditAddConsultantAgreement.js";
export default EditAddConsultantAgreement;
</script>
  
  <style>
@import "@/assets/css/style.scss";
@import "@/modules/manageproject/assets/railroadEstimates.scss";
.selected-projects-card {
  max-height: 150px;
  overflow-y: scroll;
}
.projectInvolvement .selected-projects-card .v-input__details {
  display: none !important;
}
</style>
  