<template>
  <div class="allProjectsHeadingTabs m-20 p-20">Project Milestones</div>
  <v-card class="p-64">
    <div v-if="bannerData" class="w-99 m-20">
      <div class="infopMessageBanner">
        <div class="messageRow">
          <i
            class="tableIcons fa fa-exclamation-circle"
            style="margin-left: 18px"
          ></i>
          <span>{{ bannerData }} </span>
        </div>
      </div>
    </div>
    <ExpandedTable
      :tableDescription="'Suggested schedules are based on the ASTA Let Date or the MPMS Let Date.'"
      :headers="dataHeaders"
      :items="data"
      :showExportButton="false"
      :exportFileName="'MilestonesTab'"
      :showClearFilterButton="false"
      :expandKey="'milestoneName'"
      :hidePagination="true"
      :itemsPerPage="paginationData?.items"
      :linkedPagination="true"
      :totalItems="paginationData?.total"
      :lookupData="lookupData"
      :subHeaders="subHeaders"
      :subTableNoDataFound="'No tasks created for this milestone.'"
      :subTableKey="'taskPanels'"
      :expanded="expanded"
      :selectedRailroad="selectedRailroad"
      :showItalics="true"
      :isFixedHeaderWithScroll="false"
      @clickHeaderButton="clickHeaderButton($event)"
      @dateChange="dateChange($event)"
      :disabled="!isUserAccess.update"
    >
    </ExpandedTable>
  </v-card>
  <div class="action mt-20" v-if="isUserAccess.update">
    <PrimaryButton
      @buttonClicked="saveButtonClick"
      :buttonText="'Save'"
    ></PrimaryButton>
    <SecondaryButton
      @buttonClicked="fetchData"
      :buttonText="'Cancel'"
      class="ml-10"
    ></SecondaryButton>
  </div>
  <LoadingOverlay :isLoading="loading"> </LoadingOverlay>
</template>
<script>
import MilestonesTab from "@/modules/projectinformation/scripts/MilestonesTab.js";
export default MilestonesTab;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
</style>