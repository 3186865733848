import { vfs } from "@/assets/vfs_fonts.js";
import useDateField from "@/composables/dateField.js";
import pdfMake from "pdfmake/build/pdfmake";
export default {
  name: "PreviousComments",
  props: {
    projectComments: { type: Array, default: () => [] },
    exportType: { default: "", type: String },
    sectionTitle: { default: "", type: String },
  },
  setup(props) {
    const { getFormattedDateStringNoTime } = useDateField();
    const { formatDate } = useDateField();
    const getDate = (date) => {
      return getFormattedDateStringNoTime(date);
    };

    const getformatDate = (date) => {
      return formatDate(date);
    };

    const exportComment = (exportType) => {
      let commentData = [];
      props.projectComments.forEach((comment) => {
        commentData.push({
          text: comment.username + "," + comment.userRole,
          style: "header",
        });
        if (comment.rowCrtTs) {
          commentData.push(getformatDate(comment.rowCrtTs));
        }
        commentData.push(comment.comment);
      });
      // playground requires you to assign document definition to a variable called dd
      pdfMake.vfs = vfs;

      pdfMake.fonts = {
        Roboto: {
          normal: "Roboto-Regular.ttf",
          bold: "Roboto-Medium.ttf",
          italics: "Roboto-Italic.ttf",
          bolditalics: "Roboto-MediumItalic.ttf",
        },
        FontAwesomeSolid: {
          normal: "fa-solid-900.ttf",
          bold: "fa-solid-900.ttf",
          italics: "fa-solid-900.ttf",
          bolditalics: "fa-solid-900.ttf",
        },
        CustomFontAwesome: {
          normal: "fontawesome-regular-checkboxes.ttf",
          bold: "fontawesome-regular-checkboxes.ttf",
          italics: "fontawesome-regular-checkboxes.ttf",
          bolditalics: "fontawesome-regular-checkboxes.ttf",
        },
      };
      let content = {
        content: commentData,
        styles: {
          header: {
            fontSize: 12,
            bold: true,
            marginTop: 10,
          },
        },
      };
      pdfMake.createPdf(content).open();
      pdfMake.createPdf(content).download(exportType + ".pdf");
    };
    return {
      exportComment,
      getDate,
      getformatDate,
    };
  },
};
