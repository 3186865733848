<template>
  <v-card class="p-64">
    <v-card-text>
      <div class="page-title">
        <h1>Grade Crossing History</h1>
      </div>

      <FeatureTable
        :headers="headers"
        :items="inventoryHistorylist"
        :showExportButton="true"
        :showClearFilterButton="true"
        :exportFilName="'Grade Crossing History'"
        :navigateToMpmsDetails="false"
        :clickable="true"
        :itemsPerPage="paginationData?.items"
        :linkedPagination="true"
        :totalItems="paginationData?.total"
        :lookupData="lookupData"
        :isDynamicExport="true"
        :oldMultipleFilter="multipleFilter"
        @updatePaginationPerPage="updatePaginationPerPage"
        @pageChange="pageChange"
        @sortUpdate="sortUpdate"
        @textFilterChange="textFilterChange"
        @multipleFiltersChange="multipleFiltersChange"
        @clearAllFilter="clearAllFilter"
        @exportButtonClicked="exportButtonClicked"
      >
      </FeatureTable>
    </v-card-text>
  </v-card>
</template>

<script>
import GradeCrossingHistory from "@/modules/dot-details/scripts/GradeCrossingHistory.js";
export default GradeCrossingHistory;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
</style>
