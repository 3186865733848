<template>
  <div
    v-if="tableHeader || showClearFilterButton || showExportButton"
    class="d-flex align-items-center justify-content-between primary-fill-buttons p-10"
  >
    <h1 class="font-normal f-size24 color-blue">{{ tableHeader }}</h1>
    <div>
      <router-link
        class="font-normal f-size14 color-blue p-20"
        :to="viewAllProjectRoute"
        v-if="routerText"
      >
        <i
          class="fa fa-arrow-circle-right"
          style="color: #2a578d; font-size: 20px; margin-right: 8px"
        ></i
        >{{ routerText }}</router-link
      >
      <router-link
        class="font-normal f-size14 color-blue p-20"
        :to="viewAllTasksRoute"
        v-if="routerText2"
      >
        <i
          class="fa fa-arrow-circle-right"
          style="color: #2a578d; font-size: 20px; margin-right: 8px"
        ></i
        >{{ routerText2 }}</router-link
      >
      <v-btn
        v-if="showClearFilterButton"
        :disabled="!checkIfFilterExists()?.length"
        @click="
          clearAllFilters();
          resetFocusTextKey();
        "
        variant="text"
        style="color: #2a578d !important; background-color: white !important"
        >Clear All Filters</v-btn
      >
      <v-btn
        v-if="showExportButton"
        :disabled="!filteredData?.length || disableExportButton"
        @click="exportList(exportType)"
        variant="outlined"
        >Export List</v-btn
      >
    </div>
  </div>
  <div
    v-if="showInformationText"
    class="indicator-text m-10 w-95 d-flex flex-row align-items-center"
  >
    <i style="margin-left"></i>
    <div>
      <div class="messageRow">
        <img
          alt="Bridge"
          :src="BridgeImage"
          class="tableIdentifierIcons"
        /><span>
          <b
            >MPMS Projects that have a Let Date of 12 years or less into the
            future; Let Date cannot be blank.</b
          >
          <br />
        </span>
      </div>
      <div class="messageRow mt-1">
        <img alt="Safety" :src="SafetyImage" class="tableIdentifierIcons" />
        <span>
          <b
            >MPMS Projects where Project Class equals "Rail Highway Grade
            Crossing"</b
          >
        </span>
      </div>
    </div>
  </div>
  <div
    v-if="showFlagIndicator"
    class="indicator-text w-full d-flex flex-row justify-content-start align-items-center"
  >
    <div>
      <div class="messageRow mt-1">
        <i
          class="tableIcons fas fa-flag"
          aria-hidden="true"
          style="color: #f8a311"
        ></i
        ><span>
          Indicates Railroads that do not have FID/ Railroad Code/ Corporate
          Address/ Contacts Identified.&nbsp;&nbsp;</span
        >
        <br />
      </div>
    </div>
  </div>
  <ProjectIdentifierLegend
    v-if="showLegends"
    :showHistorical="showHistorical"
  />

  <v-data-table
    :loading="loading"
    :page="page"
    v-model:sort-by="sortBy"
    :headers="headers"
    :items="filteredData"
    :items-per-page="itemsPerPage"
    :search="search"
    :hide-default-footer="hidePagination"
    :items-per-page-text="'Rows per page'"
    item-key="id"
    id="list"
    must-sort="true"
    class="elevation-1 w-100 p-10"
    :class="{ 'hide-pagination': hidePagination }"
  >
    <template v-slot:body>
      <tr class="header" v-if="!hideFilter">
        <td
          v-for="(header, headerIndex) in headers"
          :key="header"
          :style="{
            'min-width': header?.columnWidth,
          }"
        >
          <v-select
            :menu="focusTextKey && focusTextKey === header.key"
            bg-color="white"
            v-if="header.filterType === 'multi-select'"
            v-model="header.filter"
            :disabled="header?.disableFilter || loading"
            :placeholder="header.title"
            :items="getItems(header.key, header.filterSort)"
            item-title="label"
            item-value="value"
            @update:modelValue="filterValues($event, header.key)"
            @blur="resetOldFilter(header?.key, headerIndex)"
            multiple
            :menu-props="{ width: 'max-content' }"
          >
            <template #prepend-item>
              <div
                class="w-100 d-flex align-items-center justify-content-end select-prepend-item"
              >
                <IconButton
                  @buttonClicked="
                    updateMultipleFilter(header?.key);
                    resetFocusTextKey();
                  "
                  :buttonText="'Apply'"
                  :iconButton="true"
                  :iconName="'mdi-check'"
                ></IconButton>
                <IconButton
                  v-if="!checkIfOldFilterIsPresent(header?.key)"
                  @buttonClicked="
                    resetOldFilter(header?.key, headerIndex);
                    resetFocusTextKey();
                  "
                  :buttonText="'Cancel'"
                  :iconButton="true"
                  :iconName="'mdi-close'"
                ></IconButton>
                <IconButton
                  v-if="checkIfOldFilterIsPresent(header?.key)"
                  @buttonClicked="
                    resetOldFilter(header?.key, headerIndex, true);
                    resetFocusTextKey();
                  "
                  :buttonText="'Clear'"
                  :iconButton="true"
                  :iconName="'mdi-close'"
                ></IconButton>
              </div>
            </template>
          </v-select>
          <v-autocomplete
            :menu="focusTextKey && focusTextKey === header.key"
            bg-color="white"
            v-if="header.filterType === 'auto-complete'"
            v-model="header.filter"
            :disabled="header?.disableFilter || loading"
            :placeholder="header.title"
            :items="getItems(header.key, header.filterSort)"
            item-title="label"
            item-value="value"
            @update:modelValue="filterValues($event, header.key)"
            multiple
            :no-data-text="AUTO_COMPLETE_NO_DATA"
            @blur="resetOldFilter(header?.key, headerIndex)"
            :menu-props="{
              minWidth: getDropdownItemsMaxLength(
                header?.key,
                header?.filterSort
              ),
              width: getDropdownItemsMaxLength(header?.key, header?.filterSort),
            }"
          >
            <template #prepend-item>
              <div
                class="w-100 d-flex align-items-center justify-content-end select-prepend-item"
              >
                <IconButton
                  @buttonClicked="
                    updateMultipleFilter(header?.key);
                    resetFocusTextKey();
                  "
                  :buttonText="'Apply'"
                  :iconButton="true"
                  :iconName="'mdi-check'"
                ></IconButton>
                <IconButton
                  v-if="!checkIfOldFilterIsPresent(header?.key)"
                  @buttonClicked="
                    resetOldFilter(header?.key, headerIndex);
                    resetFocusTextKey();
                  "
                  :buttonText="'Cancel'"
                  :iconButton="true"
                  :iconName="'mdi-close'"
                ></IconButton>
                <IconButton
                  v-if="checkIfOldFilterIsPresent(header?.key)"
                  @buttonClicked="
                    resetOldFilter(header?.key, headerIndex, true);
                    resetFocusTextKey();
                  "
                  :buttonText="'Clear'"
                  :iconButton="true"
                  :iconName="'mdi-close'"
                ></IconButton>
              </div> </template
          ></v-autocomplete>
          <v-autocomplete
            bg-color="white"
            v-if="
              header.title === 'MPMS#' &&
              header.filterType === 'single-auto-complete'
            "
            v-model="header.filter"
            :disabled="header?.disableFilter || loading"
            :placeholder="header.title"
            :items="getItems(header.key, header.filterSort)"
            item-title="label"
            item-value="value"
            @update:modelValue="
              filterValues($event, header.key, 'single-auto-complete')
            "
            :no-data-text="AUTO_COMPLETE_NO_DATA"
            @blur="resetOldFilter(header?.key, headerIndex)"
            :menu-props="{
              minWidth: getDropdownItemsMaxLength(
                header?.key,
                header?.filterSort
              ),
              width: getDropdownItemsMaxLength(header?.key, header?.filterSort),
            }"
            :custom-filter="getListByStartsWith"
          >
          </v-autocomplete>
          <v-autocomplete
            bg-color="white"
            v-if="
              header.title != 'MPMS#' &&
              header.filterType === 'single-auto-complete'
            "
            v-model="header.filter"
            :disabled="header?.disableFilter || loading"
            :placeholder="header.title"
            :items="getItems(header.key, header.filterSort)"
            item-title="label"
            item-value="value"
            @update:modelValue="
              filterValues($event, header.key, 'single-auto-complete')
            "
            :no-data-text="AUTO_COMPLETE_NO_DATA"
            @blur="resetOldFilter(header?.key, headerIndex)"
            :menu-props="{
              minWidth: getDropdownItemsMaxLength(
                header?.key,
                header?.filterSort
              ),
              width: getDropdownItemsMaxLength(header?.key, header?.filterSort),
            }"
          >
          </v-autocomplete>
          <v-text-field
            v-if="header.filterType === 'textbox'"
            bg-color="white"
            :placeholder="header.title"
            v-model="header.filter"
            :autofocus="focusTextKey && focusTextKey === header.key"
            :disabled="header?.disableFilter || loading"
            @input="
              debounce(() => {
                header.filter = $event.target.value;
                textFieldFilter(
                  $event,
                  header.key,
                  header.filterType,
                  header.filterWith
                );
              })
            "
          ></v-text-field>
        </td>
      </tr>
      <tr
        v-for="(item, index) in filteredData"
        :key="item"
        @mouseover="
          clickable && checkIfLinkActive(item, header) ? selectItem(item) : ''
        "
        @click="
          clickable && checkIfLinkActive(item, header)
            ? createRouter(item, index)
            : ''
        "
        :class="{
          selectedRow: clickable && item === selectedItem,
          rowHover: clickable,
        }"
      >
        <td
          v-for="header in headers"
          :key="header"
          @mouseover="
            clickable && checkIfLinkActive(item, header) ? selectItem(item) : ''
          "
          :class="{
            selectedRow: clickable && item === selectedItem,
            'is-link': header?.isLink && checkIfLinkActive(item, header),
            'table-data-vertical-align': alignContentTopVertically,
          }"
          :style="{
            'min-width': header?.columnWidth,
          }"
        >
          <span
            v-if="
              header?.columnType != 'icon' &&
              header?.columnType != 'action' &&
              header?.columnType != 'array'
            "
          >
            <span v-if="!header?.isLink"
              >{{ header.key != "clear" ? item[header.key] : "" }}
            </span>
            <span
              v-if="header?.isLink && !header?.isDownload"
              @click="
                header?.isLink && checkIfLinkActive(item, header)
                  ? linkClicked(header, item[header.key], item)
                  : ''
              "
              >{{ header.key != "clear" ? item[header.key] : "" }}</span
            >
            <span
              v-if="header?.isLink && header.isDownload"
              @click.stop="
                header?.isLink && checkIfLinkActive(item, header)
                  ? linkClicked(header, item[header.key], item)
                  : ''
              "
              >{{ header.key != "clear" ? item[header.key] : "" }}</span
            >
          </span>
          <span v-if="header?.columnType === 'array'">
            <span v-for="arrayItem in item?.[header?.key]" :key="arrayItem">
              <span
                v-for="keyObj in header?.arrayKeys"
                :key="arrayItem?.[keyObj?.key]"
                :class="{
                  'bold-text': keyObj?.isBold,
                }"
              >
                <p v-if="arrayItem?.[keyObj?.key]">
                  {{ arrayItem?.[keyObj?.key] || "" }}
                </p></span
              >
              <span
                @click="
                  $emit('removeArrayItemClicked', {
                    item: item,
                    arrayItem: arrayItem,
                  })
                "
                v-if="header?.showRemove"
                class="color-blue underline cursor-pointer"
                >Remove</span
              >
              <span
                ><p class="mb-10">{{ " " }}</p></span
              >
            </span>
          </span>
          <div class="left" v-if="header.columnType === 'icon'">
            <div
              class="w-100 d-flex justify-content-center"
              v-if="
                header?.isYesNoIcon &&
                (item[header.key] === 'Y' ||
                  item[header.key] === 'Yes' ||
                  item[header.key] === 'y' ||
                  item[header.key] === 'yes')
              "
            >
              <i class="fa fa-check" style="color: green"></i>
            </div>
            <div
              v-if="!header?.isYesNoIcon && !header?.textWithIcon"
              class="d-flex textWithIcon"
              :style="{
                'justify-content': header?.justifyContent
                  ? header?.justifyContent
                  : 'flex-end',
              }"
              @click="
                header?.isLink && checkIfLinkActive(item, header)
                  ? linkClicked(header, item[header.key], item)
                  : ''
              "
              v-html="item[header.icon]"
            ></div>
            <div
              v-if="header?.textWithIcon"
              class="d-flex textWithIcon"
              :style="{
                'justify-content': header?.justifyContent
                  ? header?.justifyContent
                  : 'flex-end',
              }"
              @click="
                header?.isLink && checkIfLinkActive(item, header)
                  ? linkClicked(header, item[header.key], item)
                  : ''
              "
            >
              <span
                v-if="showFlagIndicator"
                style="width: 30px"
                v-html="item[header.icon]"
              ></span
              ><span v-if="!showFlagIndicator" v-html="item[header.icon]"></span
              >{{ item[header.key] }}
            </div>
          </div>
          <div v-if="header?.columnType === 'action'">
            <div class="d-flex justify-content-center align-items-center">
              <IconButton
                v-if="header?.isAdd"
                @buttonClicked="$emit('addButtonClicked', item)"
                :buttonText="'Add'"
                :iconButton="true"
                :iconName="'mdi-plus'"
              ></IconButton>
              <IconButton
                v-if="header?.isEdit"
                @buttonClicked="
                  $emit('editButtonClicked', { item: item, index: index })
                "
                :buttonText="'Edit'"
                :iconButton="true"
                :iconName="'mdi-pencil'"
                :disabled="isEditDisabled"
              ></IconButton>
              <IconButton
                v-if="header?.isDelete && !item.disableDelete"
                @buttonClicked="$emit('deleteButtonClicked', item)"
                :buttonText="'Delete'"
                :iconButton="true"
                :iconName="'mdi-delete'"
                class="pl-0"
                :disabled="disableDeleteCheck(item)"
              ></IconButton>
              <IconButton
                v-if="header?.isDelete && item.disableDelete"
                @buttonClicked="$emit('deleteButtonClicked', item)"
                :buttonText="'Delete'"
                :iconButton="true"
                :iconName="'mdi-delete'"
                class="pl-0"
                :disabled="true"
              ></IconButton>

              <IconButton
                v-if="
                  header?.isOtherButton && !item?.[header?.hideOtherButtonKey]
                "
                @buttonClicked="$emit('otherButtonClicked', item)"
                :buttonText="header?.otherButtonText"
                class="pl-0"
                :disabled="'disableUpdate' in item && item.disableUpdate || item.source == 'System'"
              ></IconButton>
            </div>
          </div>
        </td>
      </tr>
      <tr v-if="!filteredData?.length">
        <td :colspan="headers?.length" class="text-center">
          {{ noDataAvailableText || "No Data Available" }}
        </td>
      </tr>
    </template>
    <template v-slot:bottom>
      <div class="text-center mt-2" id="paginationId">
        <v-row
          align="center"
          justify="center"
          v-if="getPaginationLengthLinked > 0"
        >
          <v-col cols="4"></v-col>
          <v-col cols="2">
            <div class="d-flex align-items-center">
              <span class="pagination-labels mr-20">Rows per page</span>
              <div>
                <v-select
                  v-model="itemsPerPage"
                  :items="[5, 10, 15, 20, 30, 50, 100]"
                  @update:modelValue="updatePaginationPerPage($event)"
                ></v-select>
              </div>
            </div>
          </v-col>
          <v-col cols="2">
            <div class="d-flex align-items-center ml-10 justify-content-around">
              <span class="pagination-labels mr-20">Page</span>
              <div
                class="d-flex flex-column justify-content-end"
                style="width: 80px !important"
              >
                <v-select
                  v-model="jumpPage"
                  :items="getPagesList()"
                  @update:modelValue="jumpToPageLinkedPagination($event)"
                ></v-select>
                <h6
                  v-if="
                    !jumpPage ||
                    jumpPage < 1 ||
                    jumpPage > getPaginationLengthLinked
                  "
                  class="color-red text-left pagination-labels"
                >
                  Page Range (1 - {{ getPaginationLengthLinked }})
                </h6>
              </div>
              <span class="w-30 text-left pagination-labels"
                >of {{ getPaginationLengthLinked }}</span
              >
            </div>
          </v-col>
          <v-col cols="4">
            <v-pagination
              v-model="pageNumber"
              :length="getPaginationLengthLinked"
              rounded="0"
            ></v-pagination>
          </v-col>
        </v-row>
      </div> </template
  ></v-data-table>
</template>
<script>
import FeatureTable from "@/modules/shared/scripts/FeatureTable.js";
export default FeatureTable;
</script>
<style>
@import "@/modules/shared/assets/FeatureTable.scss";
@import "@/assets/css/style.scss";
.textWithIcon {
  word-break: break-all;
}
</style>
