<template>
  <v-card class="p-64">
    <FeatureTable
      v-if="isInitiatedTableData"
      :headers="headers"
      :items="inspectionsList"
      :showExportButton="false"
      :exportFilName="'Active Projects'"
      :showClearFilterButton="true"
      :itemsPerPage="paginationData?.items"
      :linkedPagination="true"
      :totalItems="paginationData?.total"
      :lookupData="lookupData"
      :oldMultipleFilter="multipleFilter"
      :isDynamicExport="true"
      @updatePaginationPerPage="updatePaginationPerPage"
      @pageChange="pageChange"
      @sortUpdate="sortUpdate"
      @textFilterChange="textFilterChange"
      @multipleFiltersChange="multipleFiltersChange"
      @clearAllFilter="clearAllFilter"
      @exportButtonClicked="exportButtonClicked"
      @otherButtonClicked="otherButtonClicked"
      @editButtonClicked="editButtonClicked"
      @linkClicked="linkClicked($event)"
    >
    </FeatureTable>
  </v-card>
</template>

<script>
import InspectionsR2Table from "@/modules/dot-details/scripts/InspectionsR2Table.js";
export default InspectionsR2Table;
</script>
<style>
.container {
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
  padding-top: 20px;
}
</style>
