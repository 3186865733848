import {  requiredRule,
  validPUCDocketNumber,
} from "@/composables/validationRules";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import AddComments from "@/modules/projectinformation/components/AddComments.vue";
import PreviousComments from "@/modules/projectinformation/components/PreviousComments.vue";
import PriorityProject from "@/modules/projectinformation/components/PriorityProject.vue";
import ScopingViewDialogComponent from "@/modules/projectinformation/components/ScopingViewDialog.vue";
import DatePicker from "@/modules/shared/components/DatePicker.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import { computed, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { TABLE_NO_DATA_AVAILABLE } from "../../../constants/displayTexts";
export default {
  name: "MpmsEnvironmentInfo",
  components: {
    PreviousComments,
    AddComments,
    DatePicker,
    PrimaryButton,
    PriorityProject,
    ScopingViewDialogComponent,
  },
  props: [
    "id",
    "create",
    "srSec",
    "county",
    "municipality",
    "saveClicked",
    "cancelClicked",
  ],
  emits: ["previousValue", "updatedValues", "commentUpdate", "refreshMpmsData"],
  setup(props, { emit }) {
    const roleBasedAccessController = ref("");
    const isProjectPriorityAccess = ref(false);
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const scopingViewDialogComponent = ref(null);
    const router = useRouter();
    let panel = ref([0]);
    const isExpanded = ref(true);
    const commentMaxLength = ref(850);
    const isScopifiedCommentsFormValid = ref(false);
    const pucDocketNumber = ref("");
    const pucDocketNumberDate = ref("");
    const mpmsEnvironment = ref(null);
    const projectComments = ref([]);
    const projectScopeFieldComments = ref([]);
    const tab = ref(null);
    const parentChildData = ref(null);
    const comment = ref("");
    const scopeFieldComment = ref("");
    const id = ref();
    const maxDate = ref(new Date().toLocaleDateString("fr-ca"));
    const previousMPMSValue = ref(null);
    const stoppedTyping = ref(false);
    const section130Lookup = ref([]);
    const saveClicked = computed({
      get: () => {
        return props?.saveClicked;
      },
    });
    const cancelClicked = computed({
      get: () => {
        return props?.cancelClicked;
      },
    });
    const create = computed({
      get: () => {
        return props?.create;
      },
    });
    const srSec = computed({
      get: () => {
        return props?.srSection;
      },
    });
    const county = computed({
      get: () => {
        return props?.county;
      },
    });
    const municipality = computed({
      get: () => {
        return props?.municipality;
      },
    });
    watch(
      () => [props],
      () => {
        if (props?.saveClicked || props?.cancelClicked) {
          getProjectInfoData();
        }
        if (props.id) {
          id.value = props.id;
          getProjectInfoData();
          if (!props.create) {
            getParentChildData();
          }
          if (isSection130Access.read) {
            getCommentsData();
          }
        }
      },
      { deep: true }
    );
    const isUserCommentAccess = ref({
      read: false,
      update: false,
    });
    const isSection130Access = ref({ read: false, update: false });
    const section130AccessCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.COMMON_PROJECT_INFORMATION_READ_ACCESS
      );
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.COMMON_PROJECT_INFORMATION_CRTUPD_ACCESS
      );
      isSection130Access.value.read = readResponse || false;
      isSection130Access.value.update = updateResponse || false;
    };
    const commentAccessCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.PROJECTCMNTS_SCOPINGFIELDVIEWCOMMENTS_READ_ACCESS
      );
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.PROJECTCMNTS_SCOPINGFIELDVIEWCOMMENTS_CRTUPD_ACCESS
      );
      isUserCommentAccess.value.read = readResponse || false;
      isUserCommentAccess.value.update = updateResponse || false;
    };
    const selectedField = ref(null);
    const fields = ref([]);
    const fetchFields = async () => {
      try {
        const response = await axios.get(
          CONFIG_ENDPOINT.PROJECT_INFO_SECTION130 + id.value
        );
        section130Lookup.value = response.data?.data;

        setSelectedFieldfor130();
      } catch (error) {
        console.error("Error fetching fields:", error);
      }
    };
    const setSelectedFieldfor130 = () => {
      let selectedAssociationCode = section130Lookup.value.filter?.(
        (field) => field?.associationPresent
      );
      if (selectedAssociationCode.length) {
        selectedField.value =
          selectedAssociationCode[0]?.section130projecttypeCode;
      }
    };
    const updateSelectedFieldfor130 = () => {
      emit("updatedValues", {
        pucDocketNumber: pucDocketNumber.value || "",
        pucDocketNumberDate: mpmsEnvironment.value.pucDocketNumberDate,
        crossingScopeDesc: mpmsEnvironment.value.crossingScopeDesc,
        section130projecttypeCode: selectedField.value,
      });
    };
    onMounted(async () => {
      await priorityAccessCheck();
      await commentAccessCheck();
      await section130AccessCheck();
      id.value = props.id;
      if (id.value) {
        if (isSection130Access.value.read || isSection130Access.value.update) {
          await fetchFields();
        }
        getProjectInfoData();
        if (!props.create) {
          getParentChildData();
          getCommentsData();
        }
      }
    });
    const getCommentsData = () => {
      if (isUserCommentAccess.value.read || isUserCommentAccess.value.update) {
        getProjectCommentsData();
      }
    };
    const priorityAccessCheck = async () => {
      roleBasedAccessController.value =
        RBAC_CONTROLLER.MAINTAIN_PROJECT_PRIORITY_CRTUPD_ACCESS;
      let response = await userRoleBasedAccessService.checkUserAccess(
        roleBasedAccessController.value
      );
      isProjectPriorityAccess.value = response;
    };
    async function getProjectInfoData() {
      if (!props.create) {
        await axios
          .get(CONFIG_ENDPOINT.PROJECT_INFO_INFO + id.value)
          .then((response) => {
            mpmsEnvironment.value = {
              ...response.data,
              pucDocketNumberDate: response?.data?.pucDocketDate
                ? new Date(response?.data?.pucDocketDate).toLocaleDateString(
                    "fr-ca"
                  )
                : "",
              crossingScopeDesc: response?.data?.gcmsProject?.crossingScopeDesc,
            };
            pucDocketNumberDate.value = response?.data?.pucDocketDate
              ? new Date(response?.data?.pucDocketDate).toLocaleDateString(
                  "fr-ca"
                )
              : "";
            pucDocketNumber.value = response?.data?.pucDocketNumber;
            previousMPMSValue.value = {
              pucDocketNumberDate: response?.data?.pucDocketDate
                ? new Date(response?.data?.pucDocketDate).toLocaleDateString(
                    "fr-ca"
                  )
                : "",
              pucDocketNumber: response?.data?.pucDocketNumber || "",
              crossingScopeDesc: response?.data?.gcmsProject?.crossingScopeDesc,
              section130projecttypeCode: selectedField.value,
            };
            emit("previousValue", previousMPMSValue.value);
          })
          .catch((err) => {
            mpmsEnvironment.value = null;
          });
      } else {
        axios
          .get(
            CONFIG_ENDPOINT.PROJECT_INFO_INFO +
              id.value +
              "?createGradeCrossing=true"
          )
          .then((response) => (mpmsEnvironment.value = response.data))
          .catch((err) => {
            mpmsEnvironment.value = null;
          });
      }
    }
    async function getParentChildData() {
      axios
        .get(CONFIG_ENDPOINT.PROJECT_INFO_HIERARCHY + id.value)
        .then((response) => {
          parentChildData.value = response.data;
        })
        .catch((err) => {
          parentChildData.value = null;
        });
    }
    async function getProjectCommentsData() {
      axios
        .get(CONFIG_ENDPOINT.PROJECT_INFO_COMMENTS + id.value)
        .then((response) => {
          projectComments.value = response.data?.projectComment || [];
          projectScopeFieldComments.value = response.data?.scopingComment || [];
        })
        .catch((err) => {
          projectComments.value = [];
        });
    }
    const addComment = (response) => {
      let array = [...[response], ...projectComments.value];
      projectComments.value = array;
    };
    const addScopeFieldComment = (response) => {
      let array = [...[response], ...projectScopeFieldComments.value];
      projectScopeFieldComments.value = array;
    };
    const keydownAllowAlphaNumericOnly = (event) => {
      let regex = /[^A-Za-z0-9-]/;
      if (regex.test(event.key)) {
        event.preventDefault();
        return false;
      }
    };
    const updateCrossingScopeValue = (event) => {
      mpmsEnvironment.value.crossingScopeDesc = event;
      emit("updatedValues", {
        pucDocketNumber: pucDocketNumber.value || "",
        pucDocketNumberDate: mpmsEnvironment.value.pucDocketNumberDate,
        crossingScopeDesc: mpmsEnvironment.value.crossingScopeDesc,
        section130projecttypeCode: selectedField.value,
      });
    };
    const docketNumberChange = (event) => {
      pucDocketNumber.value = event;
      stoppedTyping.value = false;
      emit("updatedValues", {
        pucDocketNumber: event || "",
        pucDocketNumberDate: !event
          ? ""
          : mpmsEnvironment.value.pucDocketNumberDate,

        crossingScopeDesc: mpmsEnvironment.value.crossingScopeDesc,
        section130projecttypeCode: selectedField.value,
      });
    };
    const dateChange = (response) => {
      mpmsEnvironment.value.pucDocketNumberDate = response;
      emit("updatedValues", {
        pucDocketNumberDate: isDateValid.value ? response : null,
        pucDocketNumber: pucDocketNumber.value || "",
        crossingScopeDesc: mpmsEnvironment.value.crossingScopeDesc,
        section130projecttypeCode: selectedField.value,
      });
    };
    const isDateValid = ref(false);
    const checkDateValidation = (value) => {
      isDateValid.value = value;
      emit("updatedValues", {
        pucDocketNumberDate: isDateValid.value
          ? mpmsEnvironment.value.pucDocketNumberDate
          : null,
        pucDocketNumber: pucDocketNumber.value || "",
        crossingScopeDesc: mpmsEnvironment.value.crossingScopeDesc,
        section130projecttypeCode: selectedField.value,
      });
    };
    const navigateToMPMSDetails = (mpms) => {
      if (mpms) {
        window.scrollTo(0, 0);
        router.push({
          path: `/GCMS/${mpms}`,
        });
      }
    };
    const openScopingModal = () => {
      scopingViewDialogComponent.value
        .open("Scoping Field View Documents", "Add", props.id)
        .then((resolve) => {
          if (resolve) {
            id.value = props.id;
            if (id.value) {
              getProjectInfoData();
              if (!props.create) {
                getParentChildData();
                getCommentsData();
              }
            }
          }
        });
    };
    const commentUpdateToTab = (value) => {
      emit("commentUpdate", {
        commentType: value.commentType,
        comment: value.comment,
      });
    };

    const setPUCDocketRule = (event) => {
      stoppedTyping.value = true;
      pucDocketNumber.value = event;
    };
    const getPUCDocketRule = computed({
      get: () => {
        return stoppedTyping.value;
      },
    });

    function createDebounce() {
      let timeout = null;
      return function (fnc, delayMs) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fnc();
        }, delayMs || 1000);
      };
    }
    return {
      selectedField,
      fields,
      id,
      create,
      srSec,
      county,
      municipality,
      isExpanded,
      commentMaxLength,
      isScopifiedCommentsFormValid,
      pucDocketNumber,
      pucDocketNumberDate,
      mpmsEnvironment,
      projectComments,
      projectScopeFieldComments,
      tab,
      parentChildData,
      comment,
      scopeFieldComment,
      addComment,
      addScopeFieldComment,
      keydownAllowAlphaNumericOnly,
      dateChange,
      panel,
      requiredRule,
      navigateToMPMSDetails,
      maxDate,
      openScopingModal,
      docketNumberChange,
      checkDateValidation,
      saveClicked,
      commentUpdateToTab,
      TABLE_NO_DATA_AVAILABLE,
      cancelClicked,
      scopingViewDialogComponent,
      validPUCDocketNumber,
      debounce: createDebounce(),
      setPUCDocketRule,
      getPUCDocketRule,
      stoppedTyping,
      updateCrossingScopeValue,
      isProjectPriorityAccess,
      isUserCommentAccess,
      section130Lookup,
      updateSelectedFieldfor130,
      isSection130Access,
    };
  },
};
