<script>
import router from "@/router";
export default {
  name: "CallbackPage",
  setup() {
    const originalUrl = localStorage.getItem("originalUrl");
    let url = "";
    if (originalUrl) {
      try {
        url = new URL(originalUrl);
        if (
          url.pathname.indexOf("callback") >= 0 ||
          url.pathname == "/" || url.pathname == "/GCMS/" || url.pathname == "/GCMS"
        ) {
          router.push({
              path: "/dashboard",
          });
        } else {
          router.push({
            path: url.pathname,
          });
        }
      } catch (e) {
      
        router.push({
              path: "/dashboard",
          });
      }
      localStorage.removeItem("originalUrl");
    } else {
      router.push({
              path: "/dashboard",
          });
    }
  },
};
</script>