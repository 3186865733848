<template>
  <div>
    <div class="d-flex flex-column mt-10">
      <div>
        <h1 class="locationHeaderText">Add a Crossing Outside the Vicinity</h1>
      </div>
      <div class="w-30 m-20 d-flex justify-content-start align-items-center">
        <v-text-field
          placeholder="Search DOT#"
          v-model="searchDotValue"
          :maxlength="7"
          @input="changeDotValue"
          @keydown="allowAlphanumericOnly($event)"
          :disabled="!isUserAccess.update"
        ></v-text-field>
        <SecondaryButton
          :disabled="
            !searchDotValue ||
            searchDotValue?.length != 7 ||
            !isUserAccess.update
          "
          @buttonClicked="searchDot()"
          :buttonText="'Search'"
          class="ml-10"
        ></SecondaryButton>
      </div>
    </div>
    <VueTable
      v-if="searchClicked"
      :class="{ successMessage: successMessage }"
      :errorMessage="errorMessage"
      :invalidErrorClass="true"
      :tableData="ImapctedDotList"
      :columnData="columnData"
      :tableHeader="''"
    ></VueTable>
    <div class="d-flex" v-if="searchClicked">
      <PrimaryButton
        @buttonClicked="addToImpactedList()"
        :buttonText="'Add To Impacted List'"
        :disabled="!ImapctedDotList?.length || !isUserAccess.update"
        class="ml-10"
      ></PrimaryButton>
      <PrimaryButton
        @buttonClicked="addToNearbyList()"
        :buttonText="'Add To Nearby List'"
        :disabled="!ImapctedDotList?.length || !isUserAccess.update"
        class="ml-10"
      ></PrimaryButton>
      <SecondaryButton
        @buttonClicked="clear()"
        :buttonText="'Clear'"
        class="ml-10"
        :disabled="!isUserAccess.update"
      ></SecondaryButton>
    </div>
  </div>
</template>
<script>
import AddToImpactedList from "@/modules/grade-crossing-and-bridges/scripts/AddToImpactedList.js";
export default AddToImpactedList;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/modules/grade-crossing-and-bridges/assets/AddToImpactedList.scss";
</style>