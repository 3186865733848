<template>
  <v-row>
    <!-- Left corner element -->
    <v-col class="ml-20">
      <router-link :to="`/GCMS/${id}`">
        &lt; Return to Project Information Page
      </router-link>
    </v-col>
    <!-- Centered element -->
    <v-col class="page-title">
      <h1>Add Railroads To Project</h1>
    </v-col>
    <v-col> </v-col>
  </v-row>

  <v-card class="p-64">
    <FeatureTable
      :headers="headers"
      :items="formattedRailroads"
      :hideFilter="true"
      :showExportButton="false"
      :showClearFilterButton="false"
      :hidePagination="true"
      :linkedPagination="true"
      :tableHeader="'Railroads on Project'"
      :noDataText="ADD_RAILROAD_NO_DATA_FOUND"
      :itemsPerPage="paginationData?.items"
      :totalItems="paginationData?.total"
      @otherButtonClicked="otherButtonClicked"
      :alignContentTopVertically="true"
      @removeArrayItemClicked="removeArrayItemClicked"
    >
    </FeatureTable>

    <br />

    <div class="railroadError" v-if="crossingFlag">
      <div>
        <v-icon icon="mdi-alert"></v-icon>
        One or more Crossings do not yet have a Railroad associated with it. You
        must add an owner or an operator for each crossing.
      </div>
    </div>
    <br />
    <br />
    <div>
      <div class="env-header mb-20">
        <h2 class="locationHeaderText">Add Railroads by Crossing</h2>
      </div>
      <label class="subHeaderText">Add One or More Railroads by Crossing</label>
    </div>
    <br />
    <div v-if="crossings?.length">
      <v-col v-for="(crossing, index) in crossings" :key="index">
        <div class="impactedCrossing">
          <div v-if="ownerAndOperatorList[crossing.dotNumbers] == 0">
            <v-icon class="impactedFlagger" icon="mdi-alert"></v-icon>
          </div>
          <div v-else>
            <v-icon icon="noflag"></v-icon>
          </div>
          <label class="estimatedCostSubHeader">
            {{ crossing.dotNumbers }}</label
          >
        </div>
        <br />
        <br />
        <div class="impactedRow">
          <v-row>
            <v-col cols="1">
              <b>Owner:</b>
            </v-col>
            <v-col cols="3" style="min-width: 450px !important;">
              {{ crossing.owner }}
            </v-col>
            <v-col
              v-if="
                railroadMap.hasOwnProperty(crossing.owner) &&
                (railroadMap[crossing.owner].includes(crossing.dotNumbers.replace('Nearby DOT# ', '')) || 
                railroadMap[crossing.owner].includes(crossing.dotNumbers.replace('DOT# ', '')))
              "
            >
              <PrimaryButton
                @buttonClicked="
                  removeDialog(crossing.dotNumbers, crossing.owner, false)
                "
                :buttonText="'Remove from List'"
              ></PrimaryButton>
            </v-col>
            <v-col
              v-else-if="
                railroadMap.hasOwnProperty(crossing.owner) &&
                (!railroadMap[crossing.owner].includes(crossing.dotNumbers.replace('Nearby DOT# ', '')) || 
                !railroadMap[crossing.owner].includes(crossing.dotNumbers.replace('DOT# ', '')))
              ">
              <PrimaryButton
                @buttonClicked="addToList(crossing.dotNumbers, crossing.owner)"
                :buttonText="'Add to List'"
                :disabled="!crossing.owner || crossing.owner === 'Unknown'"
              ></PrimaryButton>
            </v-col>
            <v-col
              v-else-if="crossing.owner == 'N/A' || crossing.owner == null"
            >
            </v-col>
            <v-col v-else>
              <PrimaryButton
                @buttonClicked="addToList(crossing.dotNumbers, crossing.owner)"
                :buttonText="'Add to List'"
                :disabled="!crossing.owner || crossing.owner === 'Unknown'"
              ></PrimaryButton>
            </v-col>
          </v-row>
        </div>
        <br />
        <br />
        <div class="impactedRow">
          <v-row>
            <v-col cols="1">
              <b>Operator:</b>
            </v-col>
            <v-col cols="3" style="min-width: 450px !important;">
              {{ crossing.operator }}
            </v-col>
            <v-col v-if="crossing.operator == crossing.owner"> </v-col>
            <v-col
              v-else-if="
                railroadMap.hasOwnProperty(crossing.operator) &&
                (railroadMap[crossing.operator].includes(crossing.dotNumbers.replace('DOT# ', '')) ||
                railroadMap[crossing.operator].includes(crossing.dotNumbers.replace('Nearby DOT# ', '')))
              "
            >
              <PrimaryButton
                @buttonClicked="
                  removeDialog(crossing.dotNumbers, crossing.operator, false)
                "
                :buttonText="'Remove from List'"
              ></PrimaryButton>
            </v-col>
            <v-col
              v-else-if="
                railroadMap.hasOwnProperty(crossing.operator) &&
                ((!railroadMap[crossing.operator].includes(crossing.dotNumbers.replace('DOT# ', ''))) ||
                (!railroadMap[crossing.operator].includes(crossing.dotNumbers.replace('Nearby DOT# ', ''))))
              "
            >
              <PrimaryButton
                @buttonClicked="
                  addToList(crossing.dotNumbers, crossing.operator)
                "
                :disabled="
                  !crossing.operator || crossing.operator === 'Unknown'
                "
                :buttonText="'Add to List'"
              ></PrimaryButton>
            </v-col>

            <v-col
              v-else-if="
                crossing.operator == 'N/A' || crossing.operator == null
              "
            >
            </v-col>
            <v-col v-else>
              <PrimaryButton
                @buttonClicked="
                  addToList(crossing.dotNumbers, crossing.operator)
                "
                :disabled="
                  !crossing.operator || crossing.operator === 'Unknown'
                "
                :buttonText="'Add to List'"
              ></PrimaryButton>
            </v-col>
          </v-row>
        </div>
        <br />
        <br />
        <div class="impactedRow">
          <v-row>
            <v-col cols="1">
              <b>Additional Railroads:</b>
            </v-col>
            <v-col cols="3" style="min-width: 450px !important;">
              <v-chip
                v-for="item in crossing.selectedAdditionalRailroad"
                :key="item"
              >
                {{ item }}
                <template #close>
                  <v-icon
                    icon="mdi-close-circle"
                    @click.stop="removeDialog(crossing, item, true)"
                  />
                </template>
              </v-chip>
              <VueAutoComplete
                v-model="crossing.selectedAdditionalRailroad"
                :items="railroadList"
                :menu-props="{ width: maxRailroadWidth * 9 }"
              ></VueAutoComplete>
            </v-col>
            <v-col
              v-if="
                crossing.selectedAdditionalRailroad?.length == 0 ||
                crossing.selectedAdditionalRailroad?.length ==
                  additionalRailroadList[crossing.dotNumbers]?.length
              "
            >
              <PrimaryButton
                :buttonText="'Update List'"
                :disabled="true"
              ></PrimaryButton>
            </v-col>

            <v-col
              v-else-if="
                crossing.selectedAdditionalRailroad?.length !=
                additionalRailroadList[crossing.dotNumbers]?.length
              "
            >
              <PrimaryButton
                @buttonClicked="
                  updateList(
                    crossing.dotNumbers,
                    crossing.selectedAdditionalRailroad
                  )
                "
                :buttonText="'Update List'"
              ></PrimaryButton>
            </v-col>
          </v-row>
        </div>
        <br />
      </v-col>
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
  </v-card>
  <DialogComponent ref="dialogComponent"></DialogComponent>
  <DialogComponent2 ref="dialogComponentUpdateContacts"></DialogComponent2>
</template>
<script>
import AddRailroads from "@/modules/manageproject/scripts/AddRailroads.js";
export default AddRailroads;
</script>

<style>
@import "@/assets/css/style.scss";
</style>
