import useSharedFunctions from "@/composables/shared";
import {
  requiredRule,
  validDateFutureDate_Inspections,
} from "@/composables/validationRules";
import DialogComponent from "@/modules/dot-details/components/UpdateInspectionsDocuments.vue";
import UploadScopingFieldViewDocument from "@/modules/scoping-field-view/components/UploadScopingFieldViewDocument.vue";
import BannerMessage from "@/modules/shared/components/BannerMessage.vue";
import DatePicker from "@/modules/shared/components/DatePicker.vue";
import DocumentsList from "@/modules/shared/components/DocumentsList.vue";
import LoadingOverlay from "@/modules/shared/components/LoadingOverlay.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import { useDocumentStore } from "@/stores/document";
import axios from "axios";
import { computed, onMounted, ref, watch } from "vue";
import { onBeforeRouteLeave, useRoute } from "vue-router";
import { CONFIG_ENDPOINT } from "../../../constants/Endpoints";
import { MAX_DATE } from "../../../constants/dates";
import { RBAC_CONTROLLER } from "../../../constants/rbacconstants";
import { userRoleBasedAccessStore } from "../../../stores/userRoleBasedAccess";
export default {
  name: "InspectionR2AddEdit",
  components: {
    BannerMessage,
    DatePicker,
    PrimaryButton,
    SecondaryButton,
    UploadScopingFieldViewDocument: UploadScopingFieldViewDocument,
    DocumentsList,
    LoadingOverlay,
    DialogComponent,
  },
  props: ["dotId", "crossingInspectionId", "addOrEdit"],
  emits: ["resetToTable"],
  setup(props, { emit }) {
    const { toTitleCase } = useSharedFunctions();
    const route = useRoute();
    const addOrEdit = ref(props?.addOrEdit);
    const dotId = ref(props?.id);
    const crossingInspectionId = ref(props?.crossingInspectionId);
    const SuccessBannerData = ref(null);
    const ErrorBannerData = ref(null);
    const savedButtonClicked = ref(false);
    const commentMaxLength = ref(500);
    const InspectionDetails = ref({
      inspectionDate: null,
      inspectionType: null,
      mpmsNumber: null,
      crossingChanges: null,
      inspectedBy: null,
      comments: null,
    });
    let valueChanged = ref(false);
    const originalValue = ref({
      inspectionDate: null,
      inspectionType: null,
      mpmsNumber: null,
      crossingChanges: null,
      inspectedBy: null,
      comments: null,
    });
    const isInitialised = ref(false);
    const isFormValid = ref(false);
    const InspectionLookup = ref({
      mpmsNumber: ["1111", "22222"],
      inspectionType: ["Annual", "Construction"],
      crossingChanges: ["Yes", "No"],
    });
    const railroadEstimatesDocumentsDialogRef = ref(null);
    const documentStore = useDocumentStore();
    const id = route.params.id || props.id;
    const loading = ref(false);
    let UpdateModalOpened = ref(false);

    const isProjectDocumentUpdateAccess = ref(false);
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const finalValidation = ref(false);
    let maxDate1 = ref(new Date(MAX_DATE).toLocaleDateString("fr-ca"));
    let minDate1 = ref(new Date().toLocaleDateString("fr-ca"));
    const resetToTable = () => {
      if (addOrEdit.value != "view" && valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          valueChanged.value = false;
          emit("resetToTable", true);
        } /*  else {
          next(false);
        } */
      } else {
        valueChanged.value = false;
        emit("resetToTable", true);
      }
    };
    onMounted(() => {
      addOrEdit.value = props?.addOrEdit;
      dotId.value = props?.dotId;
      crossingInspectionId.value = props?.crossingInspectionId;
      fetchProjectDocumentsData();
      getProjectDocumentsUpdateAccess();
      getInspectionCrossingDetails();
      getInspectionLookupData();
    });
    const fromMinDate = computed({
      get: () => {
        return minDate1.value;
      },
    });
    const fromMaxDate = computed({
      get: () => {
        return maxDate1.value;
      },
    });
    watch(
      () => [InspectionDetails.value],
      () => {
        if (
          InspectionDetails.value.crossingChanges == "Yes" &&
          InspectionDetails.value.inspectedBy &&
          InspectionDetails.value.inspectionDate &&
          InspectionDetails.value.comments &&
          ((InspectionDetails.value.inspectionType &&
            InspectionDetails.value.inspectionType === "Construction" &&
            InspectionDetails.value.mpmsNumber) ||
            (InspectionDetails.value.inspectionType &&
              InspectionDetails.value.inspectionType != "Construction")) &&
          isDateValidated
        ) {
          finalValidation.value = true;
        } else if (
          InspectionDetails.value.crossingChanges == "No" &&
          InspectionDetails.value.inspectedBy &&
          InspectionDetails.value.inspectionDate &&
          ((InspectionDetails.value.inspectionType &&
            InspectionDetails.value.inspectionType === "Construction" &&
            InspectionDetails.value.mpmsNumber) ||
            (InspectionDetails.value.inspectionType &&
              InspectionDetails.value.inspectionType != "Construction")) &&
          isDateValidated
        ) {
          finalValidation.value = true;
        } else {
          finalValidation.value = false;
        }
        if (
          isInitialised.value &&
          !objectsAreSame(originalValue.value, InspectionDetails.value)
        ) {
          valueChanged.value = true;
        } else {
          valueChanged.value = false;
        }
      },
      { deep: true }
    );

    watch(
      () => [InspectionDetails.value],
      () => {
        if (InspectionDetails.value.inspectionType == 'Annual'){
          InspectionDetails.value.mpmsNumber = "";
        }
        },
      { deep: true }
    );

    const objectsAreSame = (x, y) => {
      let objectsAreSame = true;
      let checkKeys = [
        "inspectionDate",
        "inspectionType",
        "mpmsNumber",
        "crossingChanges",
        "inspectedBy",
        "comments",
      ];
      for (let i of checkKeys) {
        if (x?.[i] !== y?.[i]) {
          objectsAreSame = false;
          break;
        }
      }
      return objectsAreSame;
    };
    const getInspectionLookupData = () => {
      axios
        .get(CONFIG_ENDPOINT.GET_INSPECTION_R2_GET_LOOKUP + dotId.value)
        .then((res) => {
          InspectionLookup.value = res.data;
        })
        .catch((err) => {
          console.log("err", err);
        });
    };
    const getInspectionCrossingDetails = () => {
      originalValue.value = JSON.parse(
        JSON.stringify({
          inspectionDate: null,
          inspectionType: null,
          mpmsNumber: null,
          crossingChanges: null,
          inspectedBy: null,
          comments: null,
        })
      );
      if (addOrEdit.value === "edit" || addOrEdit.value === "view") {
        axios
          .get(
            CONFIG_ENDPOINT.GET_INSPECTION_R2_GET_BY_ID +
              crossingInspectionId.value
          )
          .then((res) => {
            InspectionDetails.value = {
              ...InspectionDetails.value,
              ...res.data.data,
              inspectionDate: res?.data?.data?.inspectionDate
                ? new Date(res?.data?.data?.inspectionDate).toLocaleDateString(
                    "fr-ca"
                  )
                : "",
              viewInspectionDate: res.data.data.inspectionDate,
            };
            originalValue.value = JSON.parse(
              JSON.stringify({
                ...res.data.data,
                inspectionDate: res?.data?.data?.inspectionDate
                  ? new Date(
                      res?.data?.data?.inspectionDate
                    ).toLocaleDateString("fr-ca")
                  : "",
                viewInspectionDate: res.data.data.inspectionDate,
              })
            );
            isInitialised.value = true;
          })
          .catch((err) => {
            console.log("err");
          });
      } else {
        isInitialised.value = true;
      }
    };
    const BannerUpdate = (event) => {
      if (event?.bannerType === "success") {
        SuccessBannerData.value = event;
        emit("showBanner", event.bannerMessage);
      } else if (event?.bannerType === "error") {
        ErrorBannerData.value = event;
        emit("showBanner", event.bannerMessage);
      }
    };
    const hideSuccessBanner = () => {
      SuccessBannerData.value = null;
    };

    const hideErrorBannerMessage = () => {
      ErrorBannerData.value = null;
    };

    const saveClicked = (clicked) => {
      savedButtonClicked.value = clicked;
    };
    const dateChange = (event) => {
      if (event?.target?.value) {
        InspectionDetails.value.inspectionDate = event.target.value;
      } else if (event) {
        InspectionDetails.value.inspectionDate = event;
      }
    };
    const isDateValidated = ref(false);
    const checkDateValidation = (value) => {
      isDateValidated.value = value;
    };
    const checkFormValidation = computed({
      get: () => {
        return isDateValidated.value;
      },
    });
    const preventNumericInput = ($event) => {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode > 47 && keyCode < 58) {
        $event.preventDefault();
      }
    };
    const saveData = () => {
      let inspDate = "";
      if (InspectionDetails.value.inspectionDate) {
        let dateSplit = InspectionDetails.value.inspectionDate?.split("-");
        inspDate = dateSplit[1] + "/" + dateSplit[2] + "/" + dateSplit[0];
      }
      const payload = {
        ...InspectionDetails.value,
        inspectionDate: inspDate,
        dotNumber: dotId.value,
      };
      if (payload.viewInspectionDate) {
        delete payload.viewInspectionDate;
      }
      if (addOrEdit.value === "add") {
        axios
          .post(
            CONFIG_ENDPOINT.GET_INSPECTION_R2_ADD_UPDATE_BY_ID +
              "add/" +
              dotId.value,
            payload
          )
          .then(() => {
            getInspectionCrossingDetails();
            BannerUpdate({
              bannerType: "success",
              bannerMessage: "Saved Successfully.",
              showBanner: true,
            });
          })
          .catch((err) => {
            BannerUpdate({
              bannerType: "error",
              bannerMessage: "Something went wrong",
              showBanner: true,
              hasList: false,
              errorList: [],
            });
          });
      } else if (addOrEdit.value === "edit") {
        axios
          .put(
            CONFIG_ENDPOINT.GET_INSPECTION_R2_ADD_UPDATE_BY_ID +
              "update/" +
              crossingInspectionId.value,
            payload
          )
          .then(() => {
            getInspectionCrossingDetails();
            BannerUpdate({
              bannerType: "success",
              bannerMessage: "Saved Successfully.",
              showBanner: true,
            });
          })
          .catch((err) => {
            BannerUpdate({
              bannerType: "error",
              bannerMessage: "Something went wrong",
              showBanner: true,
              hasList: false,
              errorList: [],
            });
          });
      }

      valueChanged.value = false;
      isInitialised.value = false;
    };
    const cancelData = () => {
      if (addOrEdit.value === "add") {
        InspectionDetails.value = {
          inspectionDate: null,
          inspectionType: null,
          mpmsNumber: null,
          crossingChanges: null,
          inspectedBy: null,
          comments: null,
        };
      } else {
        InspectionDetails.value = {
          inspectionDate: null,
          inspectionType: null,
          mpmsNumber: null,
          crossingChanges: null,
          inspectedBy: null,
          comments: null,
        };
        getInspectionCrossingDetails();
      }
      valueChanged.value = false;
      isInitialised.value = false;
    }; /* Upload Functionality */

    const getProjectDocumentsUpdateAccess = async () => {
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.PROJECT_DOCUMENTS_PAGE_CRTUPD_ACCESS
      );
      isProjectDocumentUpdateAccess.value = updateResponse;
    };

    const filesList = ref([]);
    const updateDocumentList = ref(false);
    function fetchProjectDocumentsData() {
      updateDocumentList.value = true;
    }
    const updateDocumentListFlag = () => {
      updateDocumentList.value = false;
    };
    const uploadFiles = async (files) => {
      loading.value = true;
      let docType = "INSP_SHEET";
      let endpoint = CONFIG_ENDPOINT.UPLOAD_SCOPING_DOCUMENTS;
      document.getElementById("list-section")?.scrollIntoView();
      await documentStore.uploadDocuments(
        id,
        files,
        docType,
        endpoint,
        true,
        true,
        false
      );
      loading.value = false;
      fetchProjectDocumentsData();
    };

    onBeforeRouteLeave((to, from, next) => {
      if (addOrEdit.value != "view" && valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          valueChanged.value = false;
          next();
        } else {
          next(false);
        }
      } else {
        valueChanged.value = false;
        next();
      }
    });
    window.onbeforeunload = function () {
      if (addOrEdit.value != "view") {
        if (valueChanged.value) {
          return "You have unsaved data. Are you sure to leave the page?";
        }
      }
    };
    const otherButtonClicked = () => {
      openDialog(false);
    };
    const updateButtonClicked = (event) => {
      if (!isProjectDocumentUpdateAccess.value) return; // Prevent update if no access
      openDialog(true, event);
    };
    const openDialog = (isUpdate, event) => {
      UpdateModalOpened.value = true;
      railroadEstimatesDocumentsDialogRef.value
        .open(
          isUpdate
            ? "Update Grade Crossing Inspection Document"
            : "Upload Grade Crossing Inspection Documents",
          id,
          event,
          isUpdate
        )
        .then((resolve) => {
          if (resolve) {
            fetchProjectDocumentsData();
            UpdateModalOpened.value = false;
          }
        })
        .catch(() => {
          UpdateModalOpened.value = false;
        });
    };
    return {
      documentStore,
      filesList,
      updateDocumentListFlag,
      uploadFiles,
      id,
      fetchProjectDocumentsData,
      updateDocumentList,
      updateButtonClicked,
      railroadEstimatesDocumentsDialogRef,
      UpdateModalOpened,
      isProjectDocumentUpdateAccess,
      isDateValidated,
      checkDateValidation,
      checkFormValidation,
      addOrEdit,
      dotId,
      BannerUpdate,
      SuccessBannerData,
      hideSuccessBanner,
      hideErrorBannerMessage,
      ErrorBannerData,
      saveClicked,
      savedButtonClicked,
      InspectionLookup,
      requiredRule,
      validDateFutureDate_Inspections,
      InspectionDetails,
      dateChange,
      preventNumericInput,
      commentMaxLength,
      saveData,
      cancelData,
      toTitleCase,
      loading,
      isFormValid,
      finalValidation,
      valueChanged,
      fromMinDate,
      fromMaxDate,
      resetToTable,
      otherButtonClicked,
    };
  },
};
