export default () => {
  const navigateToGISMap = (type, id) => {
    let url;

    if (type === "project") {
      url = "https://gis.penndot.pa.gov/onemap/?mapId=266&projectId=" + id;
    } else if (type === "crossing") {
      url =
        "https://gis.penndot.pa.gov/onemap/?mapId=266&crossingId=" +
        id +
        "&showStreetView=true";
    }

    window.open(url, "_blank");
  };
  return {
    navigateToGISMap,
  };
};
