<template>
  <v-card-text>
    <projectFlaggerNeeds></projectFlaggerNeeds>
  </v-card-text>
</template>
<script>
import projectFlaggerNeeds from "@/modules/manageproject/components/projectFlaggerNeeds.vue";
export default {
  name: "ViewProjectFlaggerNeeds-page",
  components: {
    projectFlaggerNeeds: projectFlaggerNeeds,
  },
};
</script>
