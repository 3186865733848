<template>
  <div
    v-if="tableHeader || showClearFilterButton || showExportButton"
    class="d-flex align-items-center justify-content-between primary-fill-buttons p-10"
  >
    <h1 class="font-normal f-size24 color-blue">{{ tableHeader }}</h1>

    <div>
      <router-link
        class="font-normal f-size14 color-blue p-20"
        :to="viewAllProjectRoute"
        v-if="routerText"
      >
        <i
          class="fa fa-arrow-circle-right"
          style="color: #2a578d; font-size: 20px; margin: 8px"
        ></i
        >{{ routerText }}</router-link
      >
      <v-btn
        v-if="showClearFilterButton"
        :disabled="!checkIfFilterExists()?.length"
        @click="
          clearAllFilters();

          resetFocusTextKey();
        "
        variant="text"
        style="color: #2a578d !important; background-color: white !important"
        >Clear All Filters</v-btn
      >
      <v-btn
        v-if="showExportButton"
        :disabled="!filteredData?.length"
        @click="exportList(exportType)"
        variant="outlined"
        >Export List</v-btn
      >
    </div>
  </div>
  <div class="d-flex justify-content-between">
    <div class="ml-20">{{ tableDescription || "" }}</div>
    <div>
      <span class="expand-toggle" @click="expandToggle('expand')"
        >Expand All</span
      >
      <span class="ml-10">|</span>
      <span class="expand-toggle ml-10 mr-10" @click="expandToggle('collapse')"
        >Collapse All</span
      >
    </div>
  </div>
  <ProjectIdentifierLegend v-if="showLegends" />
  <div :class="{ isFixedHeaderWithScroll: isFixedHeaderWithScroll }">
    <v-data-table-server
      :loading="loading"
      :page="page"
      v-model:sort-by="sortBy"
      :headers="headers"
      :items="filteredData"
      :items-per-page="itemsPerPage"
      :search="search"
      :hide-default-footer="hidePagination"
      :items-per-page-text="'Rows per page'"
      :item-key="expandKey"
      must-sort="true"
      id="list"
      class="elevation-1 w-100 p-10"
      :class="{ 'hide-pagination': hidePagination }"
      v-model:expanded="expanded"
      :show-expand="true"
      :item-value="expandKey"
      :fixed-header="true"
      :height="isFixedHeaderWithScroll ? '400px' : 'auto'"
    >
      <template v-slot:[`colgroup`]>
        <tr class="header fixedheader1" v-if="!hideFilter">
          <td
            v-for="(header, headerIndex) in headers"
            :key="header"
            :class="{
              'min-width': header?.columnWidth,
            }"
          >
            <v-select
              :menu="focusTextKey && focusTextKey === header.key"
              bg-color="white"
              v-if="header.filterType === 'multi-select'"
              v-model="header.filter"
              :placeholder="header.title"
              :items="getItems(header.key, header.filterSort)"
              item-title="label"
              item-value="value"
              :disabled="
                checkFieldDisabled(
                  header?.key,
                  'multi-select',
                  header?.filterSort
                ) || loading
              "
              @update:modelValue="filterValues($event, header.key)"
              @blur="resetOldFilter(header?.key, headerIndex)"
              multiple
              ><template #prepend-item>
                <div
                  class="w-100 d-flex align-items-center justify-content-end select-prepend-item"
                >
                  <IconButton
                    @buttonClicked="
                      updateMultipleFilter(header?.key);

                      resetFocusTextKey();
                    "
                    :buttonText="'Apply'"
                    :iconButton="true"
                    :iconName="'mdi-check'"
                  ></IconButton>
                  <IconButton
                    v-if="!checkIfOldFilterIsPresent(header?.key)"
                    @buttonClicked="
                      resetOldFilter(header?.key, headerIndex);

                      resetFocusTextKey();
                    "
                    :buttonText="'Cancel'"
                    :iconButton="true"
                    :iconName="'mdi-close'"
                  ></IconButton>
                  <IconButton
                    v-if="checkIfOldFilterIsPresent(header?.key)"
                    @buttonClicked="
                      resetOldFilter(header?.key, headerIndex, true);

                      resetFocusTextKey();
                    "
                    :buttonText="'Clear'"
                    :iconButton="true"
                    :iconName="'mdi-close'"
                  ></IconButton>
                </div> </template
            ></v-select>

            <v-autocomplete
              :menu="focusTextKey && focusTextKey === header.key"
              bg-color="white"
              v-if="header.filterType === 'auto-complete'"
              v-model="header.filter"
              :disabled="header?.disableFilter || loading"
              :placeholder="header.title"
              :items="getItems(header.key, header.filterSort)"
              item-title="label"
              item-value="value"
              @update:modelValue="filterValues($event, header.key)"
              multiple
              :no-data-text="AUTO_COMPLETE_NO_DATA"
              @blur="resetOldFilter(header?.key, headerIndex)"
              :menu-props="{
                minWidth: getDropdownItemsMaxLength(
                  header?.key,
                  header?.filterSort
                ),
                width: getDropdownItemsMaxLength(
                  header?.key,
                  header?.filterSort
                ),
              }"
            >
              <template #prepend-item>
                <div
                  class="w-100 d-flex align-items-center justify-content-end select-prepend-item"
                >
                  <IconButton
                    @buttonClicked="
                      updateMultipleFilter(header?.key);

                      resetFocusTextKey();
                    "
                    :buttonText="'Apply'"
                    :iconButton="true"
                    :iconName="'mdi-check'"
                  ></IconButton>
                  <IconButton
                    v-if="!checkIfOldFilterIsPresent(header?.key)"
                    @buttonClicked="
                      resetOldFilter(header?.key, headerIndex);

                      resetFocusTextKey();
                    "
                    :buttonText="'Cancel'"
                    :iconButton="true"
                    :iconName="'mdi-close'"
                  ></IconButton>
                  <IconButton
                    v-if="checkIfOldFilterIsPresent(header?.key)"
                    @buttonClicked="
                      resetOldFilter(header?.key, headerIndex, true);

                      resetFocusTextKey();
                    "
                    :buttonText="'Clear'"
                    :iconButton="true"
                    :iconName="'mdi-close'"
                  ></IconButton>
                </div> </template
            ></v-autocomplete>
            <v-autocomplete
              :menu="focusTextKey && focusTextKey === header.key"
              bg-color="white"
              v-if="header.filterType === 'single-auto-complete'"
              v-model="header.filter"
              :disabled="header?.disableFilter || loading"
              :placeholder="header.title"
              :items="getItems(header.key, header.filterSort)"
              item-title="label"
              item-value="value"
              @update:modelValue="
                filterValues($event, header.key, 'single-auto-complete');
                resetFocusTextKey();
              "
              :no-data-text="AUTO_COMPLETE_NO_DATA"
              @blur="resetOldFilter(header?.key, headerIndex)"
              :menu-props="{
                minWidth: getDropdownItemsMaxLength(
                  header?.key,
                  header?.filterSort
                ),
                width: getDropdownItemsMaxLength(
                  header?.key,
                  header?.filterSort
                ),
              }"
            >
            </v-autocomplete>
            <v-text-field
              v-if="header.filterType === 'textbox'"
              bg-color="white"
              :placeholder="header.title"
              v-model="header.filter"
              :disabled="
                checkFieldDisabled(
                  header?.key,
                  'textbox',
                  header?.filterSort
                ) || loading
              "
              :autofocus="focusTextKey && focusTextKey === header.key"
              @input="
                debounce(() => {
                  header.filter = $event.target.value;
                  textFieldFilter(
                    $event,
                    header.key,
                    header.filterType,
                    header.filterWith
                  );
                })
              "
            ></v-text-field>
          </td>
        </tr>
        <tr class="header fixedheader2">
          <td
            v-for="header in headers"
            :key="header"
            :style="{
              'min-width': header?.columnWidth,
            }"
          >
            <IconButton
              v-if="header.showButton && header?.showIcon"
              @buttonClicked="clickHeaderButton(header.key)"
              :buttonText="header?.buttonText"
              :iconButton="header?.showIcon"
              :iconName="'mdi-check'"
              :variant="'elevated'"
              :disabled="header?.buttonDisabled"
              :boldTick="true"
            ></IconButton>
            <IconButton
              v-if="header.showButton && !header?.showIcon"
              @buttonClicked="clickHeaderButton(header.key)"
              :buttonText="header?.buttonText"
              :variant="'elevated'"
              :disabled="header?.buttonDisabled"
            ></IconButton>
          </td>
        </tr>
      </template>
      <template v-slot:item="{ item }">
        <tr
          @mouseover="clickable ? selectItem(item) : ''"
          @click="clickable ? createRouter(item) : ''"
          :class="{
            selectedRow: clickable && item === selectedItem,
            disableRow: item.disableRow,
          }"
        >
          <td
            v-for="header in headers"
            :key="header"
            @mouseover="clickable ? selectItem(item) : ''"
            :style="{
              'min-width': header?.columnWidth,
            }"
            :class="{
              selectedRow: clickable && item === selectedItem,
              'is-link': header?.isLink,
            }"
          >
            <span v-if="header.key === 'data-table-expand' && !item.disableRow">
              <v-icon
                @click="expandRow(item[expandKey])"
                v-if="!expanded.includes(item[expandKey])"
                >mdi-chevron-down</v-icon
              >
              <v-icon
                @click="expandRow(item[expandKey])"
                v-if="expanded.includes(item[expandKey])"
                >mdi-chevron-up</v-icon
              >
            </span>
            <span
              v-if="
                header?.columnType != 'icon' &&
                header?.columnType != 'datePicker'
              "
            >
              <span
                :style="{
                  color: header.colorKey ? item?.[header.colorKey] : 'black',
                }"
                v-if="!header?.isLink"
                >{{ header.key != "clear" ? item?.[header.key] : "" }}
              </span>
              <span
                :style="{
                  color: header.colorKey ? item?.[header.colorKey] : '',
                }"
                v-if="header?.isLink"
                @click="linkClicked(header.key, item?.[header.key])"
                >{{ header.key != "clear" ? item?.[header.key] : "" }}</span
              >
            </span>
            <span
              v-if="header?.columnType === 'datePicker'"
              class="d-flex justify-content"
            >
              <span
                style="width: 35px"
                v-if="item[header.iconKey]"
                v-html="item[header.iconKey]"
              ></span>
              <span v-if="!item[header.iconKey]" style="width: 35px"></span>
              <div
                class="d-flex flex-column date-picker"
                v-if="item?.[header?.showDatePickerKey]"
              >
                <v-text-field
                  class="datePicker"
                  variant="outlined"
                  light
                  type="date"
                  v-model="item[header?.key]"
                ></v-text-field>
                <div
                  v-if="
                    !isDateValid(item[header?.key]) &&
                    item?.[header?.showDatePickerKey]
                  "
                  class="error2 text-left w-100"
                >
                  <div>Invalid Date</div>
                </div>
              </div>
              <span v-if="!item?.[header?.showDatePickerKey]"
                >{{ item?.[header.key] }}
              </span></span
            >
            <div class="left" v-if="header.columnType === 'icon'">
              <div
                v-if="header?.textWithIcon"
                class="d-flex textWithIcon"
                :style="{
                  'justify-content': header?.justifyContent
                    ? header?.justifyContent
                    : 'flex-end',
                }"
                @click="
                  header?.isLink
                    ? linkClicked(header, item[header.key], item)
                    : ''
                "
              >
                <span v-html="item[header.icon]"></span>{{ item[header.key] }}
              </div>
              <div
                v-if="!header?.textWithIcon"
                class="d-flex icon-alignment"
                v-html="item?.[header.icon]"
              ></div>
            </div>
          </td>
        </tr>

        <tr v-if="!filteredData?.length">
          <td :colspan="headers?.length" class="text-center">
            No Data Available
          </td>
        </tr>
      </template>
      <template v-slot:expanded-row="{ columns, item }">
        <tr>
          <td :colspan="columns?.length">
            <div
              class="mb-50 mt-10"
              :style="{
                width: reduceSubTableWidth ? '90vw' : '100%',
              }"
            >
              <VueTable
                :tableData="item?.[subTableKey]"
                :columnData="subHeaders"
                :errorMessage="subTableNoDataFound"
                :showItalics="showItalics"
                @linkClicked="dotLinkClicked($event)"
              ></VueTable>
            </div>
          </td>
        </tr>
      </template>
      <template v-slot:bottom v-if="!hidePagination">
        <div class="text-center mt-2" id="paginationId">
          <v-row
            align="center"
            justify="center"
            v-if="getPaginationLengthLinked > 0"
          >
            <v-col cols="4"></v-col>
            <v-col cols="2">
              <div class="d-flex align-items-center">
                <span class="mr-20 pagination-labels">Rows per page</span>
                <div>
                  <v-select
                    v-model="itemsPerPage"
                    :items="[5, 10, 15, 20, 30, 50, 100]"
                    @update:modelValue="updatePaginationPerPage($event)"
                  ></v-select>
                </div>
              </div>
            </v-col>
            <v-col cols="2">
              <div
                class="d-flex align-items-center ml-10 justify-content-around"
              >
                <span class="mr-20 pagination-labels">Page</span>
                <div
                  class="d-flex flex-column justify-content-end"
                  style="width: 80px !important"
                >
                  <v-select
                    v-model="jumpPage"
                    :items="getPagesList()"
                    @update:modelValue="jumpToPageLinkedPagination($event)"
                  ></v-select>
                  <h6
                    v-if="
                      !jumpPage ||
                      jumpPage < 1 ||
                      jumpPage > getPaginationLengthLinked
                    "
                    class="color-red text-left pagination-labels"
                  >
                    Page Range (1 - {{ getPaginationLengthLinked }})
                  </h6>
                </div>
                <span class="w-30 text-left pagination-labels"
                  >of {{ getPaginationLengthLinked }}</span
                >
              </div>
            </v-col>
            <v-col cols="4">
              <v-pagination
                v-model="pageNumber"
                :length="getPaginationLengthLinked"
                rounded="0"
              ></v-pagination>
            </v-col>
          </v-row>
        </div>
      </template>
    </v-data-table-server>
  </div>
</template>
<script>
import ExpandedTable from "@/modules/shared/scripts/ExpandedTable.js";
export default ExpandedTable;
</script>
<style>
@import "@/modules/shared/assets/FeatureTable.scss";
@import "@/modules/shared/assets/ExpandedTable.scss";
@import "@/assets/css/style.scss";
</style>