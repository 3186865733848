<template>
  <div>
    <v-btn
      :variant="variant"
      @click="buttonClicked(item)"
      class="color-blue"
      :disabled="disabled"
      >{{ buttonText }}
      <v-icon small class="mr-2" v-if="iconButton && !boldTick"
        >{{ iconName }} </v-icon
      ><i
        class="fa fa-check"
        v-if="iconButton && boldTick"
        style="color: green; margin-left: 10px"
      ></i
    ></v-btn>
  </div>
</template>
<script>
import IconButton from "@/modules/shared/scripts/IconButton.js";
export default IconButton;
</script>
<style>
@import "@/assets/css/style.scss";
</style>
