import useFieldEntry from "@/composables/fieldEntry";import useSharedFunctions from "@/composables/shared";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import FeatureTable from "@/modules/shared/components/FeatureTable.vue";
import OnlineHelpPOC from "@/modules/shared/components/OnlineHelpPOC.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import router from "@/router";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import { onMounted, ref } from "vue";

export default {
  name: "App",
  components: { FeatureTable, PrimaryButton, OnlineHelpPOC },
  emits: ["linkClicked", "updatePaginationPerPage", "pageChange", "sortUpdate"],
  setup() {
    const lookupData = ref(null);
    let inventorylist = ref([]);
    let loading = ref(true);
    let dotValue = ref("");
    let lastEnteredDotValue = ref("");
    let showInvalidDot = ref(false);
    let noDataAvailableText = ref(" ");
    const { allowAlphanumericOnly } = useFieldEntry();
    const headers = ref([
      {
        title: "DOT#",
        filter: null,
        filterType: "single-auto-complete",
        sortType: "text",
        key: "dotnumber",
        isLink: true,
      },
      {
        title: "District ",
        filter: [],
        filterType: "multi-select",
        filterSort: "number",
        sortType: "number",
        width: "4%",
        key: "district",
      },
      {
        title: "SR",
        filter: [],
        filterType: "multi-select",
        filterSort: "number",
        sortType: "number",
        key: "sr",
      },
      {
        title: "Segment",
        filter: [],
        filterType: "multi-select",
        filterSort: "number",
        sortType: "number",
        width: "5%",
        key: "segment",
      },
      {
        title: "Local Road",
        filter: [],
        filterType: "auto-complete",
        filterSort: "text",
        sortType: "text",
        width: "12%",
        key: "street",
      },
      {
        title: "County ",
        filter: [],
        filterType: "auto-complete",
        filterSort: "text",
        sortType: "text",
        width: "9%",
        key: "county",
      },
      {
        title: "City ",
        filter: [],
        filterType: "auto-complete",
        filterSort: "text",
        sortType: "text",
        width: "9%",
        key: "city",
      },
      {
        title: "BR-Key",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
        width: "6%",
        key: "bmsKey",
      },

      {
        title: "Crossing Position",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
        width: "6%",
        key: "crossingPosition",
      },
      {
        title: "Crossing Type",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
        width: "6%",
        key: "crossingType",
      },
      {
        title: "Crossing Protection",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
        key: "crossingProtection",
      },
      {
        title: "Crossing Status ",
        filter: ["Open"],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
        width: "4%",
        key: "crossingstatus",
      },
      {
        title: "Railroad Operator",
        filter: [],
        filterType: "auto-complete",
        filterSort: "text",
        sortType: "text",
        key: "railroadoperator",
      },
      {
        title: "Crossing Owner  ",
        filter: [],
        filterType: "auto-complete",
        filterSort: "text",
        sortType: "text",
        key: "crossingOwner",
      },
    ]);
    const paginationData = ref({
      page: 1,
      items: 15,
      total: 0,
    });
    const sortData = ref({
      key: "dotnumber",
      order: "asc",
    });
    const textFilter = ref([]);
    const multipleFilter = ref([{ key: "crossingstatus", value: ["Open"] }]);

    const currentFilters = ref(null);
    const { ExportToCSV } = useSharedFunctions();
    const errorMessage = ref("");
    const isUserGradeInventoryAccess = ref({
      read: false,
    });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const gradeInventoryCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.GRADECROSSINGINVENTORYLIST_READ_ACCESS
      );
      isUserGradeInventoryAccess.value.read = readResponse || false;
    };

    onMounted(async () => {
      await gradeInventoryCheck();
      if (isUserGradeInventoryAccess.value.read) {
        await fetchData();
      }
    });
    const fetchLookupData = (filterText, Filter) => {
      axios
        .post(CONFIG_ENDPOINT.GCB_GRADE_INVENTORY_LOOKUP + filterText, Filter)
        .then((response) => {
          lookupData.value = response.data;
          headers.value.forEach((header, index) => {
            headers.value[index] = {
              ...header,
              sortable: !checkFieldDisabled(header.key, header.filterType),
            };
          });
        })
        .catch((err) => {
          lookupData.value = null;
        });
    };
    async function fetchData() {
      let Filter = {
        dotnumber: null,
        district: null,
        sr: null,
        segment: null,
        street: null,
        county: null,
        city: null,
        bmsKey: null,
        crossingPosition: null,
        crossingProtection: null,
        crossingType: null,
        crossingstatus: null,
        railroadoperator: null,
        crossingOwner: null,
      };
      textFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter.key)) {
          Filter[filter.key] = filter.value;
        }
      });
      multipleFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter.key)) {
          Filter[filter.key] = filter.value;
        }
      });
      let filterText =
        "?page=" +
        (paginationData.value.page - 1) +
        "&size=" +
        paginationData.value.items;
      if (sortData.value) {
        filterText =
          filterText +
          "&sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order;
      }

      if (Filter.dotnumber) {
        filterText = filterText + "&dotnumber=" + Filter.dotnumber;
      }
      loading.value = true;
      axios
        .post(CONFIG_ENDPOINT.GCB_GRADE_INVENTORY + filterText, Filter)
        .then((response) => {
          loading.value = false;
          inventorylist.value = response.data.data;
          paginationData.value.total = response.data.totalElements;
          currentFilters.value = Filter;
          noDataAvailableText.value = "No Data Available";
          fetchLookupData(filterText, Filter);
        })
        .catch((err) => {
          loading.value = false;
          inventorylist.value = [];
          noDataAvailableText.value = "No Data Available";
          paginationData.value = {
            page: 1,
            items: paginationData.value.items,
            total: 0,
          };
        });
    }
    const exportButtonClicked = () => {
      let filterText = "?page=" + 0 + "&size=" + paginationData.value.total;
      if (sortData.value) {
        filterText =
          filterText +
          "&sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order;
      }
      axios
        .post(
          CONFIG_ENDPOINT.GCB_GRADE_INVENTORY + filterText,
          currentFilters.value
        )
        .then((response) => {
          ExportToCSV(
            headers.value,
            response.data.data,
            "Grade Inventory List"
          );
        })
        .catch((err) => {});
    };
    const checkFieldDisabled = (key, filterType) => {
      if (filterType != "auto-complete" || filterType != "multi-select") {
        return false;
      }
      return !lookupData.value[key]?.length;
    };
    const linkClicked = (event) => {
      router.push({ path: "DOT/" + event.value });
    };
    const updatePaginationPerPage = (event) => {
      paginationData.value.page = 1;
      paginationData.value.items = event;
      fetchData();
    };
    const pageChange = (event) => {
      paginationData.value.page = event;
      fetchData();
    };
    const clearAllFilter = (event) => {
      textFilter.value = null;
      multipleFilter.value = [];
      fetchData();
    };
    const textFilterChange = (event) => {
      let index = textFilter.value.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value) {
          textFilter.value[index] = event;
        } else {
          textFilter.value.splice(index, 1);
        }
      } else {
        textFilter.value.push(event);
      }
    };
    const multipleFiltersChange = (event) => {
      let index = multipleFilter.value.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value?.length) {
          multipleFilter.value[index] = event;
        } else {
          multipleFilter.value.splice(index, 1);
        }
      } else {
        multipleFilter.value.push(event);
      }
    };
    const removeError = () => {
      showInvalidDot.value = false;
    };

    const checkIfDotIsValid = (enteredValue) => {
      axios
        .get(CONFIG_ENDPOINT.DOT + enteredValue)
        .then((response) => {
          let dotDetails = response.data;
          if (!dotDetails.dotNumber) {
            showInvalidDot.value = true;
            errorMessage.value =
              "DOT# " + lastEnteredDotValue.value + " does not exist";
          } else if (dotDetails.dotNumber) {
            showInvalidDot.value = false;
            router.push({ path: "DOT/" + enteredValue });
          }
        })
        .catch((err) => {
          if (
            err.response &&
            (err.response.status == 500 || err.response.status === 404)
          ) {
            showInvalidDot.value = true;
            lastEnteredDotValue.value = enteredValue;
            errorMessage.value = err?.response?.data?.businessMessage;
          }
        });
    };
    const submitForm = () => {
      checkIfDotIsValid(dotValue.value?.trim()?.toUpperCase());
    };

    const sortUpdate = (event) => {
      if (event.length) {
        sortData.value = {
          key: event[0].key,
          order: event[0].order,
        };
      } else {
        sortData.value = null;
      }
      fetchData();
    };

    return {
      inventorylist,
      loading,
      headers,
      linkClicked,
      updatePaginationPerPage,
      pageChange,
      paginationData,
      dotValue,
      allowAlphanumericOnly,
      lastEnteredDotValue,
      showInvalidDot,
      checkIfDotIsValid,
      submitForm,
      removeError,
      lookupData,
      textFilter,
      multipleFilter,
      clearAllFilter,
      textFilterChange,
      multipleFiltersChange,
      fetchLookupData,
      sortUpdate,
      sortData,
      exportButtonClicked,
      errorMessage,
      isUserGradeInventoryAccess,
      noDataAvailableText,
    };
  },
};
