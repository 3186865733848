<template>
  <div class="env-infp">
    <div class="env-content">
      <div id="app" class="wrapper-container">
        <div class="m-10 w-95">
          <div class="w-100 d-flex justify-content-between align-items-center">
            <h2
              class="f-size18 f-montserrat text-left"
              style="font-size: 18px; padding-bottom: 0.5em; color: #003e7e"
              id="list-section"
            >
              GCMS - Project MPMS Number: {{ mpmsNo }}, {{ county }} County,
              SR-Sec {{ srSec }}
            </h2>
          </div>
          <FeatureTable
            :headers="headers"
            :items="inventorylist"
            :showExportButton="false"
            :exportFilName="'All Projects'"
            :showClearFilterButton="false"
            :tableHeader="''"
            :itemsPerPage="paginationData?.items"
            :isDownload="true"
            :clickable="true"
            :navigationKey="'mpmsNumber'"
            :linkedPagination="false"
            :totalItems="paginationData.total"
            :currentPage="paginationData?.page"
            :lookupData="lookupData"
            :oldMultipleFilter="multipleFilter"
            :hideFilter="true"
            @textFilterChange="textFilterChange"
            @multipleFiltersChange="multipleFiltersChange"
            @clearAllFilter="clearAllFilter"
            @updatePaginationPerPage="updatePaginationPerPage"
            @pageChange="pageChange"
            @sortUpdate="sortUpdate"
            @linkClicked="downloadFile"
            @deleteButtonClicked="deleteButtonClicked"
          >
          </FeatureTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ViewDocument from "@/modules/shared/scripts/ViewDocument-External.js";
export default ViewDocument;
</script>
