import ViewScopingFieldView from "@/modules/scoping-field-view/components/ViewScopingFieldView.vue";import DatePicker from "@/modules/shared/components/DatePicker.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import { computed, reactive, ref, toRefs } from "vue";

export default {
  name: "ScopingViewDialog",
  components: {
    PrimaryButton,
    SecondaryButton,
    DatePicker,
    ViewScopingFieldView,
  },
  props: {
    id: { type: Number, default: null },
  },
  setup(props) {
    let isSaveClicked = ref(false);
    let isSaveDisabled = ref(true);
    let valueChanged = ref(false);

    const state = reactive({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      id: null,
      itemIndex: null,
      formattedDate: new Date(),
      formData: null,
    });

    const open = (title, message, id) => {
      state.dialog = true;
      state.title = title;
      state.message = message;
      state.id = id;

      return new Promise((resolve, reject) => {
        state.resolve = resolve;
        state.reject = reject;
      });
    };

    const saveData = () => {
      state.resolve({
        temp: "value",
      });
      state.dialog = false;
    };
    const isSaveButtonDisabled = computed({
      get: () => {
        return isSaveDisabled.value;
      },
    });
    const isSaveButtonClicked = computed({
      get: () => {
        return isSaveClicked.value;
      },
    });
    function saveClicked() {
      isSaveClicked.value = true;
    }

    const closeDialog = () => {
      if (valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          state.resolve(false);
          state.dialog = false;
          valueChanged.value = false;
        }
      } else {
        valueChanged.value = false;
        state.resolve(false);
        state.dialog = false;
      }
    };
    const afterModalLeave = () => {
      valueChanged.value = false;
    };
    const valueChangedEvent = (event) => {
      valueChanged.value = event;
    };
    const isUploadButtonDisabled = (event) => {
      isSaveDisabled.value = event;
      if (!event) {
        isSaveClicked.value = false;
      }
    };

    return {
      ...toRefs(state),
      closeDialog,
      open,
      saveData,
      isSaveClicked,
      saveClicked,
      isUploadButtonDisabled,
      isSaveDisabled,
      isSaveButtonDisabled,
      isSaveButtonClicked,
      valueChanged,
      afterModalLeave,
      valueChangedEvent,
    };
  },
};
