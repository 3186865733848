<template>
  <v-dialog
    persistent
    @afterLeave="afterModalLeave"
    v-model="dialog"
    max-width="950px"
    max-height="3000px"
  >
    <div class="dialogheading">
      <div class="w-100 d-flex justify-content-between">
        <div class="w-90 text-center f-size24">
          {{ title }}
        </div>
        <div>
          <v-icon @click="closeDialog" class="cursor-pointer" small
            >mdi-close
          </v-icon>
        </div>
      </div>
      <v-card>
        <v-card-text style="max-height: 75vh; overflow-y: scroll">
          <v-form v-model="isFormValid">
            <v-row class="dialogRow">
              <v-col class="dialogLabeltext1" cols="12">
                <label>DOT# : {{ formData.dotnumber }}</label>
              </v-col>

              <v-col class="dialogLabeltext" cols="12">
                <label>RR Operator: </label> <br />
                <span class="normalText">{{ formData.operator }}</span>
              </v-col>

              <v-col class="dialogLabeltext" cols="12">
                <label>RR Owner: </label><br />
                <span class="normalText">{{ formData.xingownr }}</span>
              </v-col>
            </v-row>

            <v-row class="dialogRow">
              <v-col class="dialogLabeltext1" cols="6">
                <label># of Tracks: </label><br />
                <label class="normalText">{{ formData.numberOfTracks }}</label>
              </v-col>

              <v-col class="dialogLabeltext3" cols="6">
                <label>RR Milepost: </label><br />
                <label class="normalText">|{{ formData.milepost }}|</label>
              </v-col>

              <v-col class="dialogLabeltext3" cols="12">
                <label>Crossing Position: </label><br />
                <label class="normalText">{{ formData.posxing }}</label>
              </v-col>

              <v-col class="dialogLabeltext3" cols="6">
                <label>Location: </label><br />
                <label class="normalText">{{ formData.location }}</label>
              </v-col>
            </v-row>

            <v-row class="editable">
              <v-col class="dialogLabeltext flagger-need" cols="12">
                <label># of Flaggers: </label>
                <label class="asterisk">*</label>
                <v-text-field
                  v-model="formData.flaggernumber"
                  :rules="[requiredRule]"
                  type="text"
                  min="0"
                  max="99"
                  maxlength="2"
                  onkeydown="return ( event.ctrlKey || event.altKey 
                    || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) 
                    || (95<event.keyCode && event.keyCode<106)
                    || (event.keyCode==8) || (event.keyCode==9) 
                    || (event.keyCode>34 && event.keyCode<40) 
                    || (event.keyCode==46) )"
                ></v-text-field>
              </v-col>
              <v-col
                class="dialogLabeltext2"
                :class="{
                  'flagger-need':
                    formData.flaggernumber && formData.flaggernumber >= 0,
                }"
                cols="12"
              >
                <label># of Flagger Days: </label>
                <label
                  v-if="formData.flaggernumber && formData.flaggernumber > 0"
                  class="asterisk"
                  >*</label
                >
                <v-text-field
                  v-model="formData.flaggerdays"
                  :rules="[requiredRule]"
                  type="text"
                  min="1"
                  max="999"
                  maxlength="3"
                  onkeydown="return ( event.ctrlKey || event.altKey 
                    || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) 
                    || (95<event.keyCode && event.keyCode<106)
                    || (event.keyCode==8) || (event.keyCode==9) 
                    || (event.keyCode>34 && event.keyCode<40) 
                    || (event.keyCode==46) )"
                  v-if="
                    formData.flaggernumber &&
                    formData.flaggernumber != 0 &&
                    formData.flaggernumber != '0'
                  "
                ></v-text-field>
                <v-text-field
                  v-model="formData.flaggerdays"
                  type="text"
                  min="1"
                  max="999"
                  maxlength="3"
                  onkeydown="return ( event.ctrlKey || event.altKey 
                    || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) 
                    || (95<event.keyCode && event.keyCode<106)
                    || (event.keyCode==8) || (event.keyCode==9) 
                    || (event.keyCode>34 && event.keyCode<40) 
                    || (event.keyCode==46) )"
                  disabled
                  v-if="
                    !formData.flaggernumber ||
                    formData.flaggernumber === 0 ||
                    formData.flaggernumber === '0'
                  "
                ></v-text-field>
              </v-col>

              <v-col
                class="dialogLabeltext2"
                :class="{
                  'flagger-need':
                    formData.flaggernumber && formData.flaggernumber >= 0,
                }"
                cols="12"
              >
                <div>Select Activity:</div>
                <div
                  v-if="formData.flaggernumber && formData.flaggernumber > 0"
                  class="asterisk"
                >
                  *
                </div>
                <div>
                  <div>
                    <v-chip
                      v-for="(item, index) in formData.activitynames"
                      :key="item"
                      closable
                      @click:close="removeItem(index)"
                    >
                      {{ item }}
                    </v-chip>
                    <v-autocomplete
                      v-model="formData.activitynames"
                      :items="activityList"
                      item-value="activity"
                      item-title="activity"
                      :rules="
                        formData.flaggernumber > 0
                          ? [requiredRule, maxLengthRule999, minLengthRule1]
                          : []
                      "
                      multiple
                      :no-data-text="AUTO_COMPLETE_NO_DATA"
                      :disabled="
                        !formData.flaggernumber && formData.flaggernumber != 0
                      "
                    ></v-autocomplete>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <div class="action">
            <PrimaryButton
              @buttonClicked="saveData"
              :buttonText="'Save'"
              :disabled="isSaveDisabled"
            ></PrimaryButton>
            <SecondaryButton
              @buttonClicked="closeDialog"
              :buttonText="'Cancel'"
              class="ml-2"
            ></SecondaryButton>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>
<script>
import projectFlaggerNeedsDialog from "@/modules/manageproject/scripts/projectFlaggerNeedsDialog.js";

export default projectFlaggerNeedsDialog;
</script>
<style>
@import "@/modules/cost-and-funding/assets/ScopingRailRoadCostsDialog.scss";
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
@import "@/modules/manageproject/assets/projectFlaggerNeedsDialog.scss";
</style>
