<template>
  <v-dialog
    persistent
    @afterLeave="afterModalLeave"
    v-model="dialog"
    max-width="900px"
    max-height="3000px"
  >
    <div class="dialogheading">
      <div class="w-100 d-flex justify-content-between">
        <div class="w-90 text-center f-size24">Manage Status</div>
        <div>
          <v-icon @click="closeDialog" class="cursor-pointer" small
            >mdi-close
          </v-icon>
        </div>
      </div>
      <v-card>
        <v-card-text style="max-height: 450px; overflow-y: scroll">
          <v-row style="margin: 0">
            <v-col cols="2" class="text-left">
              <label><b>Available Options:</b></label>
            </v-col>
            <v-col cols="6" class="district-container">
              <v-card>
                <div class="d-flex flex-column">
                  <v-select
                    class="select-fields"
                    outlined
                    v-model="selectedValue"
                    :items="event?.lookup"
                  ></v-select>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <div class="action">
            <PrimaryButton
              @buttonClicked="saveData"
              :buttonText="'Save'"
              :disabled="!valueChanged"
            ></PrimaryButton>
            <SecondaryButton
              @buttonClicked="closeDialog"
              :buttonText="'Cancel'"
              class="ml-2"
            ></SecondaryButton>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>
<script>
import ChangeProjectStatusDialog from "@/modules/project/scripts/ChangeProjectStatusDialog.js";
export default ChangeProjectStatusDialog;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
.district-container {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.district-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
</style>