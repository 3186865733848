import useDateField from "@/composables/dateField.js";import useIconsFunctions from "@/composables/projectIdentifiersIcons";
import useSharedFunctions from "@/composables/shared";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import DialogComponent from "@/modules/gcmsmain/admin/submenu/components/AssignDistrictsDialog.vue";
import BannerMessage from "@/modules/shared/components/BannerMessage.vue";
import FeatureTable from "@/modules/shared/components/FeatureTable.vue";
import LoadingOverlay from "@/modules/shared/components/LoadingOverlay.vue";
import OnlineHelpPOC from "@/modules/shared/components/OnlineHelpPOC.vue";
import sharedUserService from "@/modules/shared/service/sharedUserService";
import axios from "axios";
import { computed, onMounted, ref } from "vue";
import { RBAC_CONTROLLER } from "../../../../../constants/rbacconstants";
import { userRoleBasedAccessStore } from "../../../../../stores/userRoleBasedAccess";
export default {
  name: "AssignDistricts",
  components: {
    FeatureTable,
    DialogComponent,
    LoadingOverlay,
    BannerMessage,
    OnlineHelpPOC,
  },
  setup() {
    const AssignDistrictsDialog = ref(null);
    const { getIdentifierIcon } = useIconsFunctions();
    const lookupData = ref(null);
    const assignedDistricts = ref([]);
    const loading = ref(true);
    const SuccessBannerData = ref();
    const ErrorBannerData = ref();

    let noDataAvailableText = ref(" ");
    const userData = computed({
      get: () => {
        return sharedUserService.getSharedData();
      },
    });
    let headers = ref([
      {
        title: "User Name",
        filter: [],
        filterType: "single-auto-complete",
        filterSort: "text",
        sortType: "text",
        key: "userName",
      },
      {
        title: "User Roles",
        filter: [],
        filterType: "multi-select",
        key: "userRoles",
      },
      {
        title: "Assigned Districts",
        filter: [],
        filterType: "multi-select",
        key: "assignedDistricts",
        width: "25%",
      },
      {
        title: "Organization",
        filter: [],
        hideFilter: true,
        filterType: "single-auto-complete",
        sortType: "text",
        key: "organization",
      },
      {
        title: "Action",
        key: "action",
        sortable: false,
        columnType: "action",
        width: "5%",
        isOtherButton: true,
        otherButtonText: "Assign District",
      },
    ]);
    const paginationData = ref({
      page: 1,
      items: 50,
      total: 0,
    });
    const sortData = ref({
      key: "",
      order: "",
    });

    const isAssignDistrictUpdateAccess = ref(false);
    const userRoleBasedAccessService = userRoleBasedAccessStore();

    const textFilter = ref([]);
    const multipleFilter = ref([]);
    const { getFormattedDateStringNoTime } = useDateField();
    const currentFilters = ref(null);
    const { ExportToCSV } = useSharedFunctions();
    const getDate = (date) => {
      return getFormattedDateStringNoTime(date);
    };
    onMounted(async () => {
      getAssignDistrictUpdateAccess();
      await fetchData();
    });

    const getAssignDistrictUpdateAccess = async () => {
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.ASSIGN_DISTRICTS_CRTUPD_ACCESS
      );
      isAssignDistrictUpdateAccess.value = updateResponse;
      console.log("updateResponse", updateResponse);
      headers.value[headers.value.length - 1].isOtherButton =
        isAssignDistrictUpdateAccess.value;
    };
    const getLookupData = (filterText, Filter) => {
      axios
        .post(
          CONFIG_ENDPOINT.ASSIGN_DISTRICTS_LOOKUP_DATA + (filterText || ""),
          Filter
        )
        .then((response) => {
          lookupData.value = response.data;
          headers.value.forEach((header, index) => {
            headers.value[index] = {
              ...header,
              sortable: !checkFieldDisabled(header.key, header?.filterType),
            };
          });
        })
        .catch((err) => {
          lookupData.value = null;
        });
    };
    async function fetchData() {
      let Filter = {
        userName: null,
        userRoles: null,
        assignedDistricts: null,
        organization: null,
      };
      textFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter.key) && filter.value) {
          Filter[filter.key] = filter.value;
        }
      });
      multipleFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter?.key) && filter.value) {
          Filter[filter.key] = filter.value;
        }
      });
      let filterText =
        "?page=" +
        (paginationData.value.page - 1) +
        "&size=" +
        paginationData.value.items +
        "&userID=" +
        userData.value?.userID;
      if (sortData.value) {
        filterText =
          filterText +
          "&sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order;
      }
      loading.value = true;
      axios
        .post(CONFIG_ENDPOINT.ASSIGN_DISTRICTS + filterText, Filter)
        .then((response) => {
          setAssignDistrictData(response, Filter, filterText);
        })
        .catch((err) => {
          loading.value = false;
          assignedDistricts.value = [];
          paginationData.value = {
            page: 1,
            items: paginationData.value.items,
            total: 0,
          };
          noDataAvailableText.value = "No Data Available";
        });
    }

    const setAssignDistrictData = (response, Filter, filterText) => {
      loading.value = false;
      assignedDistricts.value = response.data.data;
      assignedDistricts.value.forEach((item) => {
        let icons = "";
        item?.projectIdentifier?.forEach((icon) => {
          icons = icons + getIdentifierIcon(icon);
        });
        item.projectIdentifierIcon = icons;
        item.letDate = getDate(item.letDate);
      });
      paginationData.value.total = response.data.totalElements;
      noDataAvailableText.value = "No Data Available";
      currentFilters.value = Filter;
      getLookupData(filterText, Filter);
    };

    const exportButtonClicked = () => {
      let filterText = "?page=" + 0 + "&size=" + paginationData.value.total;
      if (sortData.value) {
        filterText =
          filterText +
          "&sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order;
      }
      axios
        .post(
          CONFIG_ENDPOINT.ASSIGN_DISTRICTS + filterText,
          currentFilters.value
        )
        .then((response) => {
          setAssignDistrictExportButton(response);
        })
        .catch((err) => {});
    };
    const setAssignDistrictExportButton = (response) => {
      loading.value = false;
      let exportDataList = response.data.data;
      exportDataList.forEach((item) => {
        let icons = "";
        item?.projectIdentifier?.forEach((icon) => {
          icons = icons + getIdentifierIcon(icon);
        });
        item.projectIdentifierIcon = icons;
        item.letDate = getDate(item.letDate);
      });
      ExportToCSV(headers.value, exportDataList, "Assign Districts");
    };
    const checkFieldDisabled = (key, filterType) => {
      if (key === "projectIdentifier" || key != "userName") {
        return true;
      }
      if (
        filterType != "auto-complete" ||
        filterType != "multi-select" ||
        filterType != "single-auto-complete"
      ) {
        return false;
      }
      if (
        lookupData.value[key]?.length === 1 &&
        lookupData.value[key][0] === null
      ) {
        return true;
      }
      return !lookupData.value[key]?.length;
    };
    const updatePaginationPerPage = (event) => {
      paginationData.value.page = 1;
      paginationData.value.items = event;
      fetchData();
    };
    const pageChange = (event) => {
      paginationData.value.page = event;
      fetchData();
    };
    const sortUpdate = (event) => {
      if (event.length) {
        sortData.value = {
          key: event[0].key,
          order: event[0].order,
        };
      } else {
        sortData.value = null;
      }
      fetchData();
    };
    const clearAllFilter = (event) => {
      textFilter.value = [];
      multipleFilter.value = [];
      fetchData();
    };
    const textFilterChange = (event) => {
      let index = textFilter?.value?.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value) {
          textFilter.value[index] = event;
        } else {
          textFilter.value.splice(index, 1);
        }
      } else {
        textFilter.value.push(event);
      }
      fetchData();
    };
    const multipleFiltersChange = (event) => {
      let index = multipleFilter.value?.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value?.length) {
          multipleFilter.value[index] = event;
        } else {
          multipleFilter.value.splice(index, 1);
        }
      } else {
        multipleFilter.value.push(event);
      }
      fetchData();
    };

    const otherButtonClicked = (event) => {
      AssignDistrictsDialog.value
        .open("Assign District", event)
        .then((resolve) => {
          if (resolve) {
            if (resolve.success) {
              ErrorBannerData.value = null;
            } else {
              ErrorBannerData.value = {
                bannerType: "error",
                bannerMessage:
                  resolve?.errorMsg?.response?.data?.businessMessage || "",
                showBanner: true,
                hasList: false,
                errorList: [],
              };
              SuccessBannerData.value = null;
            }
            fetchData();
          }
        });
    };
    const hideSuccessBanner = () => {
      SuccessBannerData.value = null;
    };

    const hideErrorBannerMessage = () => {
      ErrorBannerData.value = null;
    };
    return {
      assignedDistricts,
      loading,
      headers,
      updatePaginationPerPage,
      pageChange,
      sortUpdate,
      paginationData,
      textFilterChange,
      clearAllFilter,
      multipleFiltersChange,
      multipleFilter,
      lookupData,
      currentFilters,
      exportButtonClicked,
      otherButtonClicked,
      userData,
      AssignDistrictsDialog,
      SuccessBannerData,
      ErrorBannerData,
      hideSuccessBanner,
      hideErrorBannerMessage,
      noDataAvailableText,
    };
  },
};
