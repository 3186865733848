<template>
  <v-card-text>
    <ProjectDocuments></ProjectDocuments>
  </v-card-text>
</template>
  <script>
import ProjectDocuments from "@/modules/manageproject/components/ProjectDocuments.vue";

export default {
  name: "ViewProjectDocuments-page",
  components: {
    ProjectDocuments: ProjectDocuments,
  },
};
</script>
  