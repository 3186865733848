import EstimatedProjectFundingInfo from "@/modules/cost-and-funding/components/EstimatedProjectFundingInfo.vue";import EstimatedRailRoadCost from "@/modules/cost-and-funding/components/EstimatedRailroadCost.vue";
import ProgramFundingInfo from "@/modules/cost-and-funding/components/ProgramFundingInfo.vue";
import DialogComponent from "@/modules/cost-and-funding/components/ScopingRailRoadCostsDialog.vue";
import ScopingRailroadCosts from "@/modules/cost-and-funding/components/ScopingRailroadCosts.vue";
import WbsInfo from "@/modules/cost-and-funding/components/WbsInfo.vue";
import { onMounted, ref, watch } from "vue";
import { RBAC_CONTROLLER } from "../../../constants/rbacconstants";
import { userRoleBasedAccessStore } from "../../../stores/userRoleBasedAccess";

export default {
  components: {
    EstimatedProjectFundingInfo,
    EstimatedRailRoadCost,
    ProgramFundingInfo,
    ScopingRailroadCosts,
    WbsInfo,
    DialogComponent,
  },
  name: "CostAndFunding",
  props: {
    id: { type: Number, default: null },
  },
  setup(props) {
    let id = ref();
    watch(
      () => [props.id],
      () => {
        if (props.id) {
          id.value = props.id;
        }
      },
      { deep: true }
    );
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const isUserCostAndFundingAccess = ref({
      read: false,
      update: false,
    });
    const costAndFundingAccessCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.PROJECT_COST_FUNDING_READ_ACCESS
      );
      isUserCostAndFundingAccess.value.read = readResponse || false;
    };
    onMounted(async () => {
      id.value = props.id;
      await costAndFundingAccessCheck();
    });
    return {
      isUserCostAndFundingAccess,
    };
  },
};
