import {  default as allowAlphaOnly,
  default as isNumber,
} from "@/composables/fieldEntry";
import useSharedFunctions from "@/composables/shared";
import {
  greaterThanEqualToZero,
  maxLengthRule1,
  maxLengthRule2,
  maxLengthRule999,
  minLengthRule1,
  minimumRule,
  numericRule,
  phoneRule,
  requiredRule,
  validateEmail,
  zipRule,
} from "@/composables/validationRules";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import AddressInput from "@/modules/shared/components/AddressInput.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import VueAutoComplete from "@/modules/shared/components/VueAutoComplete.vue";
import sharedUserService from "@/modules/shared/service/sharedUserService";
import { useProjectServiceStore } from "@/stores/projectService";

import axios from "axios";
import { computed, onMounted, reactive, ref, toRefs, watch } from "vue";
export default {
  components: { PrimaryButton, SecondaryButton, VueAutoComplete, AddressInput },
  props: {
    formData: {
      type: Object,
      default: {
        sapSuffixId: "",
        sapVendorId: "",
        railroadcompanyid: "",
        suffix: "",
        address1: "",
        address2: "",
        city: "",
        stateCode: "",
        zipcode: "",
      },
    },
    id: { type: String, default: null },
    item: Object, // Item to be edited (if in 'edit' mode)    projectDetails: Object,
    itemIndex: "",
  },
  setup() {
    const { checkAddressInputValidation } = useSharedFunctions();
    let valueChanged = ref(false);
    const originalValue = ref(null);
    const isInitialised = ref(false);
    onMounted(async () => {
      await fetchStates();
    });

    let isFormValid = ref(false);
    const state = reactive({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      dialogType: null,
      formData: {},
      lookupData: null,
      SAPDetails: null,
    });
    let showErrorBanner = ref(false);
    const projectServiceStore = useProjectServiceStore();
    const open = (title, formData, dialogType) => {
      state.dialog = true;
      state.title = title;
      state.formData = formData;
      originalValue.value = JSON.parse(JSON.stringify(state.formData));
      setTimeout(() => {
        isInitialised.value = true;
      }, 1000);
      state.dialogType = dialogType;
      fetchStates();
      return new Promise((resolve, reject) => {
        state.resolve = resolve;
        state.reject = reject;
      });
    };
    const id = ref(null);
    const states = ref([]);
    const userData = computed({
      get: () => {
        return sharedUserService.getSharedData();
      },
    });

    const isAddressFormValid = ref(false);

    watch(
      () => [state.formData],
      () => {
        if (checkAddressInputValidation(state.formData)) {
          isAddressFormValid.value = true;
        } else {
          isAddressFormValid.value = false;
        }
        if (
          isInitialised.value &&
          !objectsAreSame(originalValue.value, state.formData)
        ) {
          valueChanged.value = true;
        } else {
          valueChanged.value = false;
        }
      },
      { deep: true }
    );
    const objectsAreSame = (x, y) => {
      let objectsAreSame = true;
      let keysToCheck = [
        "sapSuffixId",
        "sapVendorId",
        "railroadcompanyid",
        "suffix",
        "address1",
        "address2",
        "city",
        "stateCode",
        "zipcode",
      ];
      for (let key of keysToCheck) {
        if (x[key] !== y[key]) {
          objectsAreSame = false;
          break;
        }
      }
      return objectsAreSame;
    };

    async function fetchStates() {
      let response = projectServiceStore.stateList;
      if (!response) {
        await projectServiceStore.retrieveStateList();
        response = projectServiceStore.stateList;
      }
      states.value = response?.data;
    }

    const saveData = () => {
      let object = {
        sapSuffixId: state.formData.sapSuffixId,
        sapVendorId: state.formData.sapVendorId,
        railroadcompanyid: state.formData.railroadcompanyid,
        suffix: state.formData.suffix,
        address1: state.formData.address1,
        address2: state.formData.address2,
        city: state.formData.city,
        stateCode: state.formData.stateCode,
        zipCode: state.formData.zipcode,
        userID: userData.value.userID,
      };

      if (state.dialogType == "Edit") {
        UpdateSAP(object);
      } else {
        AddSAP(object);
      }
    };
    const AddSAP = (value) => {
      axios
        .post(CONFIG_ENDPOINT.ADD_SAP_SUFFIXES, value)
        .then(() => {
          showErrorBanner.value = false;
          state.resolve({
            type: state.message,
            index: state.itemIndex,
          });

          state.dialog = false;
        })
        .catch((err) => {
          if (err.response.data.businessMessage) {
            showErrorBanner.value = true;
          }
        });
    };

    const UpdateSAP = (value) => {
      axios
        .put(CONFIG_ENDPOINT.UPDATE_SAP_SUFFIXES, value)
        .then(() => {
          showErrorBanner.value = false;
          state.resolve({
            type: state.message,
            index: state.itemIndex,
          });

          state.dialog = false;
        })
        .catch((err) => {
          if (
            err.response.data.businessMessage ==
            "Exception occurred while updating SAP Suffix. Sap Suffix already exists for the railroad."
          ) {
            showErrorBanner.value = true;
          }
        });
    };
    const closeDialog = () => {
      if (isInitialised.value && valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          showErrorBanner.value = false;
          state.resolve(false);
          state.dialog = false;
          valueChanged.value = false;
        }
      } else {
        showErrorBanner.value = false;
        state.resolve(false);
        state.dialog = false;
        valueChanged.value = false;
      }
    };

    const maskZip = () => {
      if (
        state.formData.zipcode.length == 6 &&
        !state.formData.zipcode.includes("-")
      ) {
        state.formData.zipcode =
          state.formData.zipcode.slice(0, 5) +
          "-" +
          state.formData.zipcode.slice(5, 6);
      }
    };

    let typingValues = ref({
      zip: { stoppedTyping: null },
      email: { stoppedTyping: null },
    });

    const fieldValueChange = (field) => {
      typingValues.value[field].stoppedTyping = false;
    };
    const setFieldRule = (field) => {
      typingValues.value[field].stoppedTyping = true;
    };
    const getZipFieldRule = computed({
      get: () => {
        return typingValues.value["zip"].stoppedTyping;
      },
    });

    function createDebounce() {
      let timeout = null;
      return function (fnc, delayMs) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fnc();
        }, delayMs || 1000);
      };
    }

    function keydownAllowNumberOnly(event) {
      if (isLeftUpRightDownBackspaceDeleteTab(event)) {
        return;
      }

      let regex = /\d/;

      if (!regex.test(event.key)) {
        event.preventDefault();
        return false;
      }
    }
    function isLeftUpRightDownBackspaceDeleteTab(event) {
      let key = event.key || event.code;
      return (
        key === "ArrowLeft" ||
        key === 37 ||
        key === "ArrowUp" ||
        key === 38 ||
        key === "ArrowRight" ||
        key === 39 ||
        key === "ArrowDown" ||
        key === 40 ||
        key === "Backspace" ||
        key === 8 ||
        key === "Delete" ||
        key === 46 ||
        key === "Tab" ||
        key === 9
      );
    }

    const filterObject = (item, queryText, itemText) => {
      return item?.toLowerCase()?.startsWith(queryText?.toLowerCase());
    };

    function allowAlphanumericOnly(event) {
      const blockSpecialCharacterRegex = /^[a-z0-9\s]+$/i;
      let key = event.key ? event.key : String.fromCharCode(event.code);
      if (!blockSpecialCharacterRegex.test(key)) {
        event.preventDefault();
        return false;
      }
    }
    const afterModalLeave = () => {
      valueChanged.value = false;
      isInitialised.value = false;
      originalValue.value = JSON.parse(null);
    };
    window.onbeforeunload = function () {
      if (isInitialised.value && valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };

    return {
      ...toRefs(state),
      fieldValueChange,
      setFieldRule,
      getZipFieldRule,
      debounce: createDebounce(),
      isFormValid,
      maxLengthRule1,
      maxLengthRule2,
      maxLengthRule999,
      minimumRule,
      minLengthRule1,
      requiredRule,
      numericRule,
      greaterThanEqualToZero,
      saveData,
      AddSAP,
      UpdateSAP,
      closeDialog,
      open,
      isNumber,
      maskZip,
      validateEmail,
      phoneRule,
      zipRule,
      id,
      states,
      userData,
      allowAlphaOnly,
      keydownAllowNumberOnly,
      projectServiceStore,
      filterObject,
      AddressInput,
      allowAlphanumericOnly,
      showErrorBanner,
      isAddressFormValid,
      afterModalLeave,
    };
  },
};
