import BridgeImage from "@/assets/images/Highway_Bridge.png";import SafetyImage from "@/assets/images/pnghero_train-rail-transport-level-crossing-traffic-sign-road.png";
import useSharedFunctions from "@/composables/shared";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import {
  AUTO_COMPLETE_NO_DATA,
  DROPDOWN_NO_DATA,
  TABLE_NO_DATA_AVAILABLE,
} from "@/constants/displayTexts";
import IconButton from "@/modules/shared/components/IconButton.vue";
import ProjectIdentifierLegend from "@/modules/shared/components/ProjectIdentifierLegend.vue";
import axios from "axios";
import { computed, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "FeatureTable",
  components: { IconButton, ProjectIdentifierLegend },
  props: {
    pageNumber: { default: 1, type: Number },
    headers: { type: Array, default: () => [] },
    items: { type: Array, default: () => [] },
    isDynamicExport: { type: Boolean, default: false },
    showClearFilterButton: { type: Boolean, default: false },
    showExportButton: { type: Boolean, default: false },
    disableExportButton: { type: Boolean, default: false },
    tableHeader: { type: String, default: "" },
    routerText: { type: String, default: "" },
    routerText2: { type: String, default: "" },
    hideFilter: { type: Boolean, default: false },
    passBlankAsNull: { type: Boolean, default: false },
    hidePagination: { type: Boolean, default: false },
    linkedPagination: { type: Boolean, default: false },
    itemsPerPage: { type: Number, default: 10 },
    totalItems: { type: Number, default: 10 },
    clickable: { type: Boolean, default: false },
    isGradeInventory: { type: Boolean, default: false },
    isCreateProject: { type: Boolean, default: false },
    isGradeCrossing: { type: Boolean, default: false },
    isCompletedCrossing: { type: Boolean, default: false },
    navigateToMpmsDetails: { type: Boolean, default: false },
    navigationKey: { type: String, default: "" },
    navigateCompanyName: { type: String, default: "" },
    exportFilName: { type: String, default: "" },
    lookupData: { default: null },
    oldMultipleFilter: { default: null },
    noDataAvailableText: { default: "No Data Available" },
    alignContentTopVertically: { type: Boolean, default: false },
    isRowClickedRouteEvent: { type: Boolean, default: false },
    tableName: { type: String, default: "" },
    showLegends: { type: Boolean, default: false },
    showInformationText: { type: Boolean, default: false },
    showHistorical: { type: Boolean, default: false },
    showFlagIndicator: { type: Boolean, default: false },
  },
  emits: [
    "linkClicked",
    "pageChange",
    "updatePaginationPerPage",
    "sortUpdate",
    "multipleFiltersChange",
    "textFilterChange",
    "clearAllFilter",
    "addButtonClicked",
    "editButtonClicked",
    "deleteButtonClicked",
    "otherButtonClicked",
    "exportButtonClicked",
    "rowClicked",
    "removeArrayItemClicked",
  ],
  watch: {
    sortBy(value) {
      this.updateSort(value);
    },
    totalItems(value) {
      this.updateTotalItems(value);
    },
    items(list) {
      this.updateLoading(false);
      this.updateItems(list);
    },
    pageNumber(newPage) {
      this.pageChangeLinked(newPage);
    },
    itemsPerPage() {
      this.itemPerPageChange();
    },
  },

  setup(props, { emit }) {
    const router = useRouter();
    let items = ref(props?.items || []);
    let itemsPerPage = ref(props.itemsPerPage);
    let totalItems = ref(props.totalItems || 0);
    let pageNumber = ref(1);
    let jumpPage = ref(1);
    let sortBy = ref([]);
    let page = ref(1);
    let multiSearch = ref("");
    let search = ref("");
    let passBlankAsNull = ref(props?.passBlankAsNull);
    let filteredHeaders = ref([]);
    let headerprops = ref({
      "sort-icon": "mdi-unfold-more-horizontal",
    });
    let selectedItem = ref(false);
    let viewAllProjectRoute = ref(CONFIG_ENDPOINT.GCMS_VIEW_ALL_PROJECTS);
    let viewAllTasksRoute = ref(CONFIG_ENDPOINT.GCMS_VIEW_ALL_TASKS);
    let linkedPagination = ref(props?.linkedPagination || false);
    let exportFilName = ref(props?.exportFilName);
    let loading = ref(true);
    let lookupData = ref(props?.lookupData);
    let multipleFilterValue = ref(null);
    let focusTextKey = ref(null);
    let appliedMultipleFilters = ref(false);
    const { toTitleCase, ExportToCSV } = useSharedFunctions();

    let noDataAvailableText = computed(() => props?.noDataAvailableText);
    let alignContentTopVertically = ref(props?.alignContentTopVertically);
    let showLegends = ref(props?.showLegends);
    let showInformationText = ref(props?.showInformationText);
    let showFlagIndicator = ref(props?.showFlagIndicator);
    let showHistorical = ref(props?.showHistorical);

    let filteredData = computed(() => {
      let filteredArray = items.value;
      return filteredArray;
    });

    const filteredDataLength = computed(() => filteredData.value?.length);
    const getPaginationLength = computed(() =>
      Math.ceil(filteredDataLength.value / itemsPerPage.value)
    );

    onMounted(async () => {
      console.log("DATA");
      console.log(filteredData.value);
    });

    const createRouter = (item, index) => {
      selectedItem.value = item;
      if (props?.navigateToMpmsDetails) {
        window.scrollTo(0, 0);
        router.push({
          path: `/GCMS/${selectedItem.value[props?.navigationKey]}`,
        });
      } else if (props?.navigateCompanyName) {
        window.scrollTo(0, 0);
        router.push({
          path: `/GCMS/manage-consulting-companies/${selectedItem.value.companyName}`,
        });
      } else if (props?.isGradeInventory) {
        window.scrollTo(0, 0);
        router.push({
          path: `/GCMS/DOT/${selectedItem.value.dotnumber}`,
        });
      } else if (props?.isCompletedCrossing) {
        router.push({
          path: `/GCMS/DOT/${selectedItem.value.dotNumber}`,
        });
      } else if (props?.isGradeCrossing) {
        window.scrollTo(0, 0);
        router.push({
          path: `/GCMS/${selectedItem.value.mpms}`,
        });
      } else if (props?.isRowClickedRouteEvent) {
        window.scrollTo(0, 0);
        emit("rowClicked", { item: item, index: index });
      } else if (props?.isCreateProject) {
        axios
          .get(
            CONFIG_ENDPOINT.PROJECT_INFO_INFO +
              selectedItem.value.mpmsNumber +
              "?createGradeCrossing=true"
          )
          .then((response) => {
            if (response?.data?.mpmsNumber) {
              window.scrollTo(0, 0);
              router.push({
                path: `/GCMS/createProject/${selectedItem.value.mpmsNumber}`,
              });
            }
          });
      }
    };

    const exportList = () => {
      if (props?.isDynamicExport) {
        emit("exportButtonClicked", true);
      } else {
        ExportToCSV(props?.headers, filteredData.value, exportFilName.value);
      }
    };

    const linkClicked = (header, value, item) => {
      updateLoading(true);
      if (header?.isDownload || header.key === "version") {
        emit("linkClicked", { key: header.key, value: item });
        updateLoading(false);
      } else {
        emit("linkClicked", { key: header.key, value: value });
        updateLoading(false);
      }
    };

    const pageChange = (event) => {
      jumpPage.value = event;
    };

    const itemPerPageChange = () => {
      jumpPage.value = 1;
      pageNumber.value = 1;
    };

    const getPaginationLengthLinked = computed(() => {
      return Math.ceil(totalItems.value / itemsPerPage.value);
    });
    const updateItems = (list) => {
      items.value = list;
    };

    const pageChangeLinked = (event) => {
      jumpPage.value = event;
      updateLoading(true);
      emit("pageChange", event);
    };
    const updatePaginationPerPage = (event) => {
      if (event) {
        itemsPerPage.value = event;
        updateLoading(true);
        emit("updatePaginationPerPage", event);
      }
    };

    const jumpToPageLinkedPagination = (event) => {
      let pageValue = Number(event);
      if (
        pageValue &&
        pageValue <= Math.ceil(totalItems.value / itemsPerPage.value)
      ) {
        pageNumber.value = pageValue;
        updateLoading(true);
      }
    };
    const updateSort = (event) => {
      updateSort.value = event;
      updateLoading(true);
      emit("sortUpdate", event);
    };
    const updateTotalItems = (value) => {
      totalItems.value = value;
    };
    const selectItem = (item) => {
      if (props.clickable.value) {
        selectedItem.value = item;
      }
    };

    const getListByStartsWith = (item, queryText, itemText) => {
      return item.startsWith(queryText);
    };

    const getItems = (key, sortType) => {
      let unique = [...new Set(props?.lookupData?.[key])];
      if (key === "letDate" || key?.toLowerCase()?.includes("date")) {
        return getDateBasedFilterItems(key);
      }
      unique = unique.map((item) => {
        return {
          label:
            passBlankAsNull && item === "null" ? "(Blank)" : item || "(Blank)",
          value: item,
        };
      });
      return unique;
    };
    const getDateBasedFilterItems = (key) => {
      let data = props?.lookupData?.[key]

        ?.map((item) => formatToYearMonth(item))
        ?.filter((value, index, self) => self.indexOf(value) === index);
      data = data?.map((item) => {
        let updatedDate = null;
        if (item) {
          updatedDate = item?.replace(
            item.split("-")[1],
            getMonthNumber(item.split("-")[1])
          );
          updatedDate =
            updatedDate?.split("-")[1] + "" + updatedDate?.split("-")[0];
        }
        return {
          label:
            item && new Date(item) != "Invalid Date"
              ? formatToYearMonth(item)
              : "(Blank)",
          value: updatedDate,
        };
      });
      return data;
    };
    const formatToYearMonth = (dateString) => {
      if (dateString) {
        let date = new Date(dateString);
        let month = date.toLocaleString("default", { month: "long" });
        let year = date.getFullYear();
        return `${year}-${month}`;
      }
      return dateString;
    };
    const getProjectIdentifierList = (key, sortType) => {
      let identifierList = [];
      items.value?.forEach((item) => {
        item[key]?.forEach((identifier) => {
          identifierList.push(identifier);
        });
      });
      let unique = [...new Set(identifierList?.map((item) => item))];
      unique = sortFilterItems(unique, sortType);
      unique = unique.map((item) => {
        return { label: toTitleCase(item) || "(Blank)", value: item };
      });
      return unique;
    };
    const sortFilterItems = (list, type) => {
      if (type == "number") {
        return sortNumber(list);
      } else if (type == "date") {
        return sortDate(list);
      } else if (type === "district") {
        return sortDistrict(list);
      } else {
        return sort(list);
      }
    };

    const sort = (list) => {
      return list?.sort((a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      });
    };

    const sortDate = (list) => {
      return list?.sort((a, b) => {
        if (new Date(a).getTime() < new Date(b).getTime()) return -1;
        if (new Date(a).getTime() > new Date(b).getTime()) return 1;
        return 0;
      });
    };

    const sortNumber = (list) => {
      return list?.sort((a, b) => {
        if (Number(a) < Number(b)) return -1;
        if (Number(a) > Number(b)) return 1;
        return 0;
      });
    };
    const resetOldFilter = (key, index, isClear) => {
      if (!appliedMultipleFilters.value && !isClear) {
        let oldFilter = props?.oldMultipleFilter?.length
          ? props?.oldMultipleFilter?.filter((header) => {
              return header?.key === key;
            })?.[0]?.value
          : [];
        props.headers[index].filter = oldFilter || [];
      }
      if (isClear) {
        props.headers[index].filter = [];
        filterValues([], key);
        updateMultipleFilter(key);
      }
      /* Might need later */
      /*
      filterValues(
        props.headers[index]?.filter,
        props.headers[index].key,
        props.headers[index].filterType,
        props.headers[index]?.filterWith
      ); */
    };
    const updateMultipleFilter = (key) => {
      let sortedMultipleFilter =
        multipleFilterValue.value && key === multipleFilterValue?.value?.key
          ? multipleFilterValue.value
          : null;
      let oldFilter = props?.oldMultipleFilter?.length
        ? props?.oldMultipleFilter?.filter((header) => {
            return header?.key === key;
          })?.[0]?.value
        : null;
      if (
        key === multipleFilterValue?.value?.key &&
        !arraysEqual(oldFilter, sortedMultipleFilter?.value)
      ) {
        updateLoading(true);
        emit("multipleFiltersChange", multipleFilterValue.value);
        appliedMultipleFilters.value = true;
        pageNumber.value = 1;
        pageChangeLinked(1);
      }
    };
    const arraysEqual = (a, b) => {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;
      a = a.sort((a, b) => a.localeCompare(b));
      b = b.sort((a, b) => a.localeCompare(b));

      for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    };

    const sortDistrict = (list) => {
      return list?.sort((first, second) => {
        let a = first?.split("-")?.[0];
        let b = second?.split("-")?.[0];
        if (Number(a) < Number(b)) return -1;
        if (Number(a) > Number(b)) return 1;
        return 0;
      });
    };

    const filterValues = (event, key, filterType, filterWidth) => {
      focusTextKey.value = key;
      multipleFilterValue.value = {
        value: event,
        key: key,
      };
      appliedMultipleFilters.value = false;
      if (filterType && filterType === "single-auto-complete") {
        updateMultipleFilter(key);
      }
    };
    const getMonthNumber = (month) => {
      let selectedMonth = month?.toLowerCase();
      let monthNumber = "";
      switch (selectedMonth) {
        case "january":
          monthNumber = "01";
          break;
        case "february":
          monthNumber = "02";
          break;
        case "march":
          monthNumber = "03";
          break;
        case "april":
          monthNumber = "04";
          break;
        case "may":
          monthNumber = "05";
          break;
        case "june":
          monthNumber = "06";
          break;
        case "july":
          monthNumber = "07";
          break;
        case "august":
          monthNumber = "08";
          break;
        case "september":
          monthNumber = "09";
          break;
        case "october":
          monthNumber = "10";
          break;
        case "november":
          monthNumber = "11";
          break;
        case "december":
          monthNumber = "12";
          break;
        default:
          monthNumber = "0";
      }
      return monthNumber;
    };

    const textFieldFilter = (event, key, filterType, filterWith) => {
      focusTextKey.value = key;
      updateLoading(true);
      emit("textFilterChange", { value: event.target.value, key: key });
      pageNumber.value = 1;
      pageChangeLinked(1);
    };

    const updateLoading = (loader) => {
      loading.value = loader;
    };
    watch(
      () => [props?.lookupData],
      () => {
        props.headers?.map((header) => {
          header.disableFilter = checkFieldDisabled(
            header?.key,
            header.filterType,
            header?.filterSort
          );
        });
      },
      { deep: true }
    );
    const clearAllFilters = () => {
      filteredHeaders.value = [];
      /* Removed for filter issue */
      //multipleFilterValue.value = [];
      props.headers?.map((header) => {
        header.sortable = checkFieldDisabled(
          header?.key,
          header.filterType,
          header?.filterSort
        );
        header.filter =
          header?.filterType === "textbox" ||
          header?.filterType === "single-auto-complete"
            ? null
            : [];
        header.disableFilter = checkFieldDisabled(
          header?.key,
          header.filterType,
          header?.filterSort
        );
      });
      emit("clearAllFilter", null);
    };
    const checkIfFilterExists = () => {
      return props.headers?.filter((header) =>
        header?.filterType === "textbox" ||
        header?.filterType === "single-auto-complete"
          ? header?.filter
          : header?.filter?.length
      );
    };
    function createDebounce() {
      let timeout = null;
      return function (fnc, delayMs) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fnc();
        }, delayMs || 500);
      };
    }

    const checkFieldDisabled = (key, type, sort) => {
      if (!filteredData.value.length || type === "textbox") {
        return false;
      } else if (
        type === "multi-select" ||
        type === "auto-complete" ||
        type === "single-auto-complete"
      ) {
        return !getItems(key, sort)?.length;
      }
    };

    const disableDeleteCheck = (item) => {
      let returnValue = false;

      if (props.tableName == "railroadEstimates") {
        returnValue = item?.status != "Draft";
      }

      return returnValue;
    };

    const getPagesList = () => {
      return Array.from(
        { length: getPaginationLengthLinked.value },
        (_, i) => i + 1
      );
    };

    const getDropdownItemsMaxLength = (key, sortType) => {
      let list = getItems(key, sortType)?.map((item) => item?.label?.length);
      return Math.max(...list) * 8 <= 280
        ? "280px"
        : Math.max(...list) * 8 + "px";
    };

    const checkIfOldFilterIsPresent = (key) => {
      let values =
        props?.oldMultipleFilter?.filter((header) => {
          return header?.key === key;
        })?.[0]?.value || [];
      return values.length;
    };
    const resetFocusTextKey = () => {
      focusTextKey.value = null;
    };
    const checkIfLinkActive = (item, header) => {
      if (header?.key === "fileName") {
        return true;
      } else {
        return !item?.hideLink;
      }
    };
    return {
      disableDeleteCheck,
      getDropdownItemsMaxLength,
      getPagesList,
      checkFieldDisabled,
      checkIfFilterExists,
      updateLoading,
      loading,
      sortBy,
      page,
      multiSearch,
      search,
      filteredHeaders,
      lookupData,
      headerprops,
      selectedItem,
      filteredData,
      selectItem,
      updateSort,
      linkClicked,
      createRouter,
      exportList,
      viewAllProjectRoute,
      viewAllTasksRoute,
      useRouter,
      router,
      pageNumber,
      getPaginationLength,
      itemsPerPage,
      jumpPage,
      pageChange,
      itemPerPageChange,
      updatePaginationPerPage,
      jumpToPageLinkedPagination,
      pageChangeLinked,
      getPaginationLengthLinked,
      linkedPagination,
      updateItems,
      updateTotalItems,
      getItems,
      filterValues,
      textFieldFilter,
      clearAllFilters,
      debounce: createDebounce(),
      updateMultipleFilter,
      DROPDOWN_NO_DATA,
      TABLE_NO_DATA_AVAILABLE,
      AUTO_COMPLETE_NO_DATA,
      focusTextKey,
      resetOldFilter,
      appliedMultipleFilters,
      noDataAvailableText,
      alignContentTopVertically,
      showLegends,
      getProjectIdentifierList,
      checkIfOldFilterIsPresent,
      getListByStartsWith,
      resetFocusTextKey,
      showInformationText,
      BridgeImage,
      SafetyImage,
      showHistorical,
      showFlagIndicator,
      checkIfLinkActive,
    };
  },
};
