<template>
  <div>
    <v-row>
      <v-col class="ml-20">
        <router-link :to="`/GCMS/${id}`">
          &lt; Return to Project Information Page
        </router-link>
      </v-col>
      <v-col class="page-title">
        <h1>Manage PUC Parties of Record</h1>
      </v-col>
      <v-col></v-col>
    </v-row>
  </div>
  <v-card class="p-64">
    <FeatureTable
      :headers="headers"
      :items="partiesOfRecord"
      :showExportButton="false"
      :showClearFilterButton="true"
      :itemsPerPage="paginationData?.items"
      :linkedPagination="true"
      :totalItems="paginationData?.total"
      :lookupData="lookupData"
      :oldMultipleFilter="multipleFilter"
      @updatePaginationPerPage="updatePaginationPerPage"
      @pageChange="pageChange"
      @sortUpdate="sortUpdate"
      @textFilterChange="textFilterChange"
      @multipleFiltersChange="multipleFiltersChange"
      @clearAllFilter="clearAllFilter"
      @editButtonClicked="editButtonClicked"
      @deleteButtonClicked="deleteButtonClicked($event)"
    >
    </FeatureTable>
  </v-card>
  <div class="d-flex justify-content-start m-20">
    <PrimaryButton
      @buttonClicked="addButtonClicked"
      :buttonText="'Add'"
      :disabled="!isPartiesRecordUpdateAccess.update"
    ></PrimaryButton>
  </div>
  <DialogComponent ref="PartiesOfRecordDialog"></DialogComponent>
  <DialogComponent2 ref="deletePartyOfRecordDialogRef"></DialogComponent2>
</template>
<script>
import PartiesOfRecord from "@/modules/manageproject/scripts/PartiesOfRecord.js";
export default PartiesOfRecord;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
</style>