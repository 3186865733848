<template>
  <v-dialog v-model="dialog" max-width="90vw" max-height="3000px">
    <div class="dialogheading">
      <div class="w-100 d-flex justify-content-between">
        <div class="w-90 text-center f-size24">View Previous Versions</div>
        <div>
          <v-icon @click="closeDialog" class="cursor-pointer" small
            >mdi-close
          </v-icon>
        </div>
      </div>
      <v-card>
        <v-card-text style="max-height: 80vh; overflow-y: scroll">
          <v-table class="design-table">
            <thead>
              <tr>
                <th scope="col">File Name</th>
                <th scope="col">Version</th>
                <th scope="col">Document Type</th>
                <th scope="col">Date Uploaded</th>
                <th scope="col">File Size</th>
                <th scope="col">Railroad</th>
                <th scope="col">DOT#</th>
                <th scope="col">Notes</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in filesList" :key="item">
                <td class="is-link">
                  <span @click="downloadFile(item)"
                    ><i
                      class="tableIcons fa fa-download"
                      aria-hidden="true"
                      style="color: #003e7e"
                    ></i
                    ><span>{{ item?.fileName }} </span>
                  </span>
                </td>
                <td>{{ item?.version }}</td>
                <td>{{ item?.documentType }}</td>
                <td>{{ item?.documentUploadDate }}</td>
                <td>{{ item?.fileSize }}</td>
                <td>{{ item?.railRoadName }}</td>
                <td>{{ item?.dotNumber }}</td>
                <td>{{ item?.notes }}</td>
              </tr>
              <tr v-if="!filesList?.length">
                <td colspan="8" class="text-center">No records found</td>
              </tr>
            </tbody>
          </v-table>
        </v-card-text>
        <v-card-actions>
          <div class="action">
            <SecondaryButton
              @buttonClicked="closeDialog"
              :buttonText="'Cancel'"
              class="ml-2"
            ></SecondaryButton>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
  <LoadingOverlay :isLoading="loading"> </LoadingOverlay>
</template>
<script>
import DocumentsVersionDialog from "@/modules/shared/scripts/DocumentsVersionDialog.js";
export default DocumentsVersionDialog;
</script>
<style>
@import "@/modules/projectinformation/assets/projectinfostyle.scss";
@import "@/assets/css/style.scss";
</style>
