<template>
  <div>
    <dynamic-menu :menu-items="menuData" :menuTitle="menuName" />
  </div>
</template>

<script>
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import DynamicMenu from "@/modules/shared/components/DynamicMenu.vue";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import { onMounted, ref } from "vue";
export default {
  components: {
    DynamicMenu,
  },
  setup() {
    const isManagerFlaggerAccess = ref({ read: false });
    const isDistrictResponseAccess = ref({ read: false, update: false });
    const isConditionalRailroadAccess = ref({ read: false, update: false });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const menuName = ref("District Functions");
    const menuData = ref([]);
    onMounted(async () => {
      isManagerFlaggerAccess.value.read =
        await userRoleBasedAccessService.checkUserAccess(
          RBAC_CONTROLLER.MANAGE_FLAGGER_RESOURCES_READ_ACCESS
        );
      if (isManagerFlaggerAccess.value.read) {
        menuData.value.push({
          subtitle: "Manage Railroad Flagger Resources",
          value:
            CONFIG_ENDPOINT.GCMS + CONFIG_ENDPOINT.MANAGE_FLAGGER_AND_RAILROADS,
        });
      }
      isDistrictResponseAccess.value.read =
        await userRoleBasedAccessService.checkUserAccess(
          RBAC_CONTROLLER.DISTRICT_GRADE_CROSSING_INSPECTIONS_READ_ACCESS
        );
      if (!isDistrictResponseAccess.value.read) {
        isDistrictResponseAccess.value.update =
          await userRoleBasedAccessService.checkUserAccess(
            RBAC_CONTROLLER.DISTRICT_GRADE_CROSSING_INSPECTIONS_CRTUPD_ACCESS
          );
      }
      if (
        isDistrictResponseAccess.value.read ||
        isDistrictResponseAccess.value.update
      ) {
        menuData.value.push({
          subtitle: "District Grade Crossing Inspections",
          value: CONFIG_ENDPOINT.COMPLETED_CROSSINGS_INSPECTIONS,
        });
      }
      isConditionalRailroadAccess.value.read =
        await userRoleBasedAccessService.checkUserAccess(
          RBAC_CONTROLLER.VIEW_CONDITIONAL_CERTIFICATIONS_LIST_READ_ACCESS
        );
      if (!isConditionalRailroadAccess.value.read) {
        isConditionalRailroadAccess.value.update =
          await userRoleBasedAccessService.checkUserAccess(
            RBAC_CONTROLLER.VIEW_CONDITIONAL_CERTIFICATIONS_LIST_CRTUPD_ACCESS
          );
      }
      if (
        isConditionalRailroadAccess.value.read ||
        isConditionalRailroadAccess.value.update
      ) {
        menuData.value.push({
          subtitle: "Conditional Railroad Certifications",
          value:
            CONFIG_ENDPOINT.GCMS +
            CONFIG_ENDPOINT.CONDITIONAL_RAILROAD_CERTIFICATIONS,
        });
      }
    });

    return {
      menuName,
      menuData,
    };
  },
};
</script>
<style>
@import "@/modules/gcmsmain/dashboard/assets/MyTasks.scss";
</style>

