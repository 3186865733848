<template>
  <div class="expanderOverLay">
    <AssignDistricts></AssignDistricts>
  </div>
</template>
<script>
import AssignDistricts from "@/modules/gcmsmain/admin/submenu/components/AssignDistricts.vue";
export default {
  name: "AssignDistrics",
  components: {
    AssignDistricts: AssignDistricts,
  },
};
</script>
