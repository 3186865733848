import isNumber from "@/composables/fieldEntry";import {
  greaterThanEqualToZero,
  maxLengthRule1,
  maxLengthRule2,
  maxLengthRule999,
  minLengthRule1,
  minimumRule,
  numericRule,
  phoneRule,
  requiredRule,
  validateEmail,
  zipRule,
} from "@/composables/validationRules";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import VueAutoComplete from "@/modules/shared/components/VueAutoComplete.vue";
import { useSignatureStore } from "@/stores/signature";
import axios from "axios";
import { computed, reactive, ref, toRefs, watch } from "vue";
import { CONFIG_ENDPOINT } from "../../../../../constants/Endpoints";
export default {
  name: "SignatureUpdateDialog",
  components: { PrimaryButton, SecondaryButton, VueAutoComplete },
  props: {
    formData: {
      type: Object,
      default: {
        signatureType: "",
        district: "",
        firstName: "",
        middleInitial: "",
        lastName: "",
        title: "",
        displayTitle: "",
      },
    },
    id: { type: String, default: null },
    item: Object, // Item to be edited (if in 'edit' mode)    projectDetails: Object,
    dialogType: "",
  },
  setup() {
    const signatureStore = useSignatureStore();
    let isFormValid = ref(false);
    const state = reactive({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      dialogType: null,
      formData: {},
      lookupData: null,
      allSignatureList: null,
    });
    let valueChanged = ref(true);
    const originalValue = ref({
      signatureType: "",
      district: "",
      firstName: "",
      middleInitial: "",
      lastName: "",
      title: "",
      displayTitle: "",
    });
    const isInitialised = ref(false);
    const open = (title, formData, dialogType, allSignatureList) => {
      state.dialog = true;
      state.title = title;
      state.formData = formData;
      state.dialogType = dialogType;
      state.allSignatureList = allSignatureList;
      isInitialised.value = true;
      originalValue.value = JSON.parse(
        JSON.stringify({
          signatureType: state?.formData?.signatureType,
          district: state?.formData?.district,
          firstName: state?.formData?.firstName,
          middleInitial: state?.formData?.middleInitial,
          lastName: state?.formData?.lastName,
          title: state?.formData?.title,
          displayTitle: state?.formData?.displayTitle,
        })
      );
      getLookupData();
      return new Promise((resolve, reject) => {
        state.resolve = resolve;
        state.reject = reject;
      });
    };
    const getLookupData = async () => {
      let response = signatureStore.LookupData;
      if (!response) {
        await signatureStore.getLookup();
        response = signatureStore.LookupData;
      }
      state.lookupData = response;
    };

    const saveData = () => {
      let object = {
        signatureType: state.formData.signatureType,
        districtOfficeId: state.formData.district,
        firstName: state.formData.firstName,
        middleInitial: state.formData.middleInitial,
        lastName: state.formData.lastName,
        title: state.formData.title,
        displayTitle: state.formData.displayTitle,
      };
      if (state.dialogType === "Add") {
        PostData(object);
      } else if (state.dialogType === "Edit") {
        object = {
          ...object,
          signatureTemplateId: state.formData.signatureTemplateId,
        };
        PutData(object);
      }
    };
    const PostData = (value) => {
      state.dialog = false;
      axios
        .post(CONFIG_ENDPOINT.ADD_SIGNATURE_TEMPLATE_ENDPOINT, value)
        .then(() => {
          state.resolve(true);
          state.dialog = false;
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const PutData = (value) => {
      state.dialog = false;
      axios
        .post(CONFIG_ENDPOINT.EDIT_SIGNATURE_TEMPLATE_ENDPOINT, value)
        .then(() => {
          state.resolve(true);
          state.dialog = false;
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const closeDialog = () => {
      if (isInitialised.value && valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          state.resolve(false);
          state.dialog = false;
          valueChanged.value = true;
        }
      } else {
        state.resolve(false);
        state.dialog = false;
        valueChanged.value = false;
      }
    };
    const checkIfSignatureAlreadyExists = computed({
      get: () => {
        let matchingSignature = [];
        if (state.dialogType === "Edit") {
          matchingSignature = state.allSignatureList.filter(
            (signature) =>
              signature.title === state.formData.title &&
              signature.district === state.formData.district &&
              signature.signatureType === state.formData.signatureType &&
              signature.signatureTemplateId !=
                state.formData?.signatureTemplateId
          );
        } else {
          matchingSignature = state.allSignatureList.filter(
            (signature) =>
              signature.title === state.formData.title &&
              signature.district === state.formData.district &&
              signature.signatureType === state.formData.signatureType
          );
        }

        return matchingSignature.length;
      },
    });
    watch(
      () => [state.formData],
      () => {
        if (
          isInitialised.value &&
          (originalValue.value?.signatureType !=
            state.formData?.signatureType ||
            originalValue.value?.district != state.formData?.district ||
            originalValue.value?.firstName != state.formData?.firstName ||
            originalValue.value?.middleInitial !=
              state.formData?.middleInitial ||
            originalValue.value?.lastName != state.formData?.lastName ||
            originalValue.value?.title != state.formData?.title ||
            originalValue.value?.displayTitle != state?.formData?.displayTitle)
        ) {
          valueChanged.value = true;
        } else {
          valueChanged.value = false;
        }
      },
      { deep: true }
    );
    const afterModalLeave = () => {
      valueChanged.value = false;
      isInitialised.value = false;
      originalValue.value = JSON.parse(
        JSON.stringify({
          signatureType: state?.formData?.signatureType,
          district: state?.formData?.district,
          firstName: state?.formData?.firstName,
          middleInitial: state?.formData?.middleInitial,
          lastName: state?.formData?.lastName,
          title: state?.formData?.title,
          displayTitle: state?.formData?.displayTitle,
        })
      );
    };
    window.onbeforeunload = function () {
      if (isInitialised.value && valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };
    function allowAlphaOnly(event) {
      const blockSpecialCharacterRegex = /^[A-Z\s]+$/i;
      let key = event.key ? event.key : String.fromCharCode(event.code);
      if (!blockSpecialCharacterRegex.test(key)) {
        event.preventDefault();
        return false;
      }
    }
    return {
      ...toRefs(state),
      isFormValid,
      maxLengthRule1,
      maxLengthRule2,
      maxLengthRule999,
      minimumRule,
      minLengthRule1,
      requiredRule,
      numericRule,
      greaterThanEqualToZero,
      saveData,
      PutData,
      closeDialog,
      open,
      isNumber,
      validateEmail,
      phoneRule,
      zipRule,
      checkIfSignatureAlreadyExists,
      afterModalLeave,
      allowAlphaOnly,
      valueChanged,
    };
  },
};
