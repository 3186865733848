import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import DialogComponent1 from "@/modules/manageproject/components/ContractorBidDialog.vue";
import DialogComponent2 from "@/modules/manageproject/components/railroadEstimatesDocumentDialog.vue";
import DocumentsList from "@/modules/shared/components/DocumentsList.vue";
import IconButton from "@/modules/shared/components/IconButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import { useDocumentStore } from "@/stores/document";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";



import axios from "axios";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
export default {
  components: {IconButton, SecondaryButton, DialogComponent1, DialogComponent2, DocumentsList},
  name: "ContractorBid",
  emits: [],
  setup() {

    const route = useRoute();
    let formData = ref(null);
    const contractorBidDialog = ref(null);
    const contractorBidData = ref([]);
    const documentStore = useDocumentStore();
    const updateDocumentList = ref(false);
    const railroadEstimatesDocumentsDialogRef = ref(null);
    const railroadList = ref([]);
    const id = route.params.id;
    const contractorMap = ref({});

    const isUserContractorBidAccess = ref({
      read: false,
      update: false,
    });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const contractorBidAccessCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.SAFETY_PROJECTS_CONTRACTOR_BID_PAGE_READ_ACCESS
      );
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.SAFETY_PROJECTS_CONTRACTOR_BID_PAGE_CRTUPD_ACCESS
      );
      isUserContractorBidAccess.value.read = readResponse || false;
      isUserContractorBidAccess.value.update = updateResponse || false;
    };
   
    onMounted(async () => {
        await contractorBidAccessCheck();
        if (isUserContractorBidAccess.value.read){
          getContractorBidData();

        }
    });
    async function getContractorBidData() {
      axios
        .get(CONFIG_ENDPOINT.CONTRACTOR_BID_GET + id)
        .then((response) => {
            console.log(response);
            contractorBidData.value = response.data.data;
            createContractorMap()
            console.log("CONTRACTOR BID DATA ");
            console.log(contractorBidData.value);

            for (let index in contractorBidData.value){
              for(let childIndex in contractorBidData.value[index].awardedContractor){
                contractorBidData.value[index].awardedContractor[childIndex].awardedCost = formatCurrency(contractorBidData.value[index].awardedContractor[childIndex].awardedCost);
              }
            }

        
        })
        .catch((err) => {
            console.log(err);
            contractorBidData.value = [];
        });

    }

    const formatCurrency = (string) => {

      return "$ " + parseFloat(string)?.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d{2})?$)/g, "$1,")

    }


    const filesList = ref([]);
    const otherButtonClicked = () => {
      openDialog2(false);
    };

    const updateButtonClicked = (event) => {
      openDialog2(true,event)
    }
    async function fetchEstimateData() {
      updateDocumentList.value = true;
    }

    const updateDocumentListFlag = () => {
      updateDocumentList.value = false;
    };
    const openDialog2 = (isUpdate, event) => {
      formData.value = [];
      railroadEstimatesDocumentsDialogRef.value
        .open(
          isUpdate
            ? "Update Contractor Bid Document"
            : "View/Upload Contractor Bid Documents",
          id,
          railroadList.value,
          isUpdate,
          event
        )
        .then((resolve) => {
          if (resolve) {
            // refresh data of required
            fetchEstimateData();
          }
        });
    };

    const createContractorMap = () => {

        contractorMap.value = {};
        console.log(contractorBidData.value);
        for (let index in contractorBidData.value){
            railroadList.value.push(contractorBidData.value[index].railroadCompany);
            let temp = 0;
            for (let childIndex in contractorBidData.value[index].awardedContractor){
                temp += contractorBidData.value[index].awardedContractor[childIndex].awardedCost;
            }
            contractorMap.value[contractorBidData.value[index].railroadCompany] = formatCurrency(temp);
        }
        console.log("CONTRACTOR MAP");
        console.log(contractorMap.value);
        console.log(railroadList.value);
    }

  
      const openDialog = (type, contractorBid, awarded) => {
        console.log(contractorBid);
        console.log(awarded);
        console.log(Object.keys(contractorBid.awardedContractor).length)
        
        if (type == 'Add'){
            const object = {
                contractorName: "",
                contractorCost: "",
                contractorId: null,
                railroadCompanyId: contractorBid.railroadCompanyId,
            }
            console.log("OBJECT PASSED")
            console.log(object)
            formData.value = object;
            contractorBidDialog.value
            .open(
              "Add Awarded Contractor",
              formData.value,
            )
            .then((resolve) => {
              if (resolve) {
                getContractorBidData()
              }
            });
        }
        else {
            const object = {
                contractorName: awarded.awardedContractorName,
                contractorCost: awarded.awardedCost,
                contractorId: awarded.contractorBidId,
                railroadCompanyId: contractorBid.railroadCompanyId,
            }
    
                formData.value = object;
                contractorBidDialog.value
                .open(
                  "Edit Awarded Contractor",
                  formData.value,
                )
                .then((resolve) => {
                  if (resolve) {
                    getContractorBidData()
                  }
                });
              
        }
        
          
            
      };

    
   
  
    return {
        getContractorBidData,
        id,
        contractorBidData,
        contractorMap,
        contractorBidDialog,
        openDialog,
        formData,
        documentStore,
        otherButtonClicked,
        updateDocumentList,
        updateDocumentListFlag,
        railroadEstimatesDocumentsDialogRef,
        filesList,
        railroadList,
        formatCurrency,
        updateButtonClicked,
        isUserContractorBidAccess,



    };
  },
};
