<template>
  <v-card-text>
    <RailroadProjectTeam
      @BannerUpdate="$emit('BannerUpdate', $event)"
    ></RailroadProjectTeam>
  </v-card-text>
</template>
  <script>
import RailroadProjectTeam from "@/modules/manageproject/components/RailroadProjectTeam.vue";

export default {
  name: "ViewProjectTeam-page",
  components: {
    RailroadProjectTeam: RailroadProjectTeam,
  },
};
</script>