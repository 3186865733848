<template>
  <v-card class="color-lightGrey" v-if="isUserCommentAccess.update">
    <v-card-title
      ><h4>
        <b>{{ sectionTitle }}</b>
      </h4></v-card-title
    >
    <v-card-text>
      <v-form v-model="isCommentsFormValid">
        <v-container class="white-textarea comment-box"
          ><v-textarea
            :counter="commentMaxLength"
            :maxlength="commentMaxLength"
            :persistent-counter="true"
            :rules="[requiredRule]"
            v-model="comment"
            variant="solo"
            @update:modelValue="updateCommentValue"
          ></v-textarea>
          <span
            >{{ commentMaxLength - comment?.length }} characters
            remaining.</span
          >
        </v-container>
        <div class="action primary-buttons">
          <v-btn
            variant="outlined"
            :disabled="
              !isUserCommentAccess.update || !comment || !isCommentsFormValid
            "
            @click="addComment()"
            >Add Comment</v-btn
          >
          <v-btn
            variant="outlined"
            :disabled="
              !isUserCommentAccess.update || !comment || !isCommentsFormValid
            "
            class="ml-5"
            @click="comment = ''"
            >Cancel</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import AddComments from "@/modules/projectinformation/scripts/AddComments.js";
export default AddComments;
</script>
