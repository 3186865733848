<template>
  <v-card-text>
    <mpmsProjects></mpmsProjects>
  </v-card-text>
  
</template>
<script>
import mpmsProjects from "@/modules/gcmsmain/createproject/submenu/components/mpmsProjects.vue";
export default {
  name: "mpmsProjects-page",
  components: {
    mpmsProjects: mpmsProjects,
  },
};
</script>
