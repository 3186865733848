import useDateField from "@/composables/dateField.js";import {
  minConditionLength,
  requiredRule,
} from "@/composables/validationRules";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import DialogComponent from "@/modules/manageproject/components/ViewFundingDialog.vue";
import IconButton from "@/modules/shared/components/IconButton.vue";
import LoadingOverlay from "@/modules/shared/components/LoadingOverlay.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import sharedUserService from "@/modules/shared/service/sharedUserService";
import router from "@/router";
import { useDocumentStore } from "@/stores/document";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import { computed, onMounted, ref, watch } from "vue";
import { onBeforeRouteLeave, useRoute } from "vue-router";
import { CONFIG_ENDPOINT } from "../../../constants/Endpoints";
export default {
  components: {
    PrimaryButton,
    SecondaryButton,
    IconButton,
    DialogComponent,
    LoadingOverlay,
  },
  name: "RailroadCertification",
  props: ["dotId"],
  setup(props, { emit }) {
    const route = useRoute();
    const id = route.params.id;
    const viewFundingDialogRef = ref(null);
    const { getFormattedDateStringNoTime } = useDateField();
    const documentStore = useDocumentStore();
    const loading = ref(false);
    let panel = ref([0, 1, 2, 3, 4, 5]);
    let panelList = ref([
      {
        title: "Right-of-Way",
        list: [],
        rowClick: true,
        routeLink:
          CONFIG_ENDPOINT.GCMS + id + CONFIG_ENDPOINT.PROJECT_INVOLVEMENT_ROUTE,
        header: [
          {
            title: "Does Railroad R/W need to be acquired?",
            key: "railroadRWAcquired",
            isLink: true,
            col: "4",
            isView: false,
          },
          {
            title: "Project Appropriation Type",
            key: "appropriationType",
            col: "4",
            isView: false,
          },
          {
            title: "ROW Clearance Date",
            key: "rowClearanceDate",
            col: "4",
            isView: false,
          },
        ],
      },
      {
        title: "PUC Documents",
        list: [],
        showWarning: true,
        linkText: "View PUC Communications",
        routeLink:
          CONFIG_ENDPOINT.GCMS + id + CONFIG_ENDPOINT.PUC_COMMUNICATIONS,

        /* linkText2: "View Safety Milestone Documents",
        routeLink2:
          CONFIG_ENDPOINT.GCMS + id + CONFIG_ENDPOINT.SAFETY_MILESTONES, */
        header: [
          {
            title: "PUC Activity",
            key: "pucActivityName",
            col: "5",
            isView: false,
          },
          {
            title: "Date",
            key: "pucCommDate",
            col: "5",
            isView: false,
          },
          {
            title: "",
            key: "",
            col: "2",
            isView: true,
          },
        ],
      },
      {
        title: "Agreements",
        list: [],
        /* linkText: "View Railroad Estimate",
        routeLink:*/
        openIds: ["dotnumber", "railroad"],
        rowClick: true,
        routeLink:
          CONFIG_ENDPOINT.GCMS + id + CONFIG_ENDPOINT.RAILROAD_ESTIMATES,
        header: [
          {
            title: "Railroad",
            key: "railroadName",
            col: "4",
            isLink: true,
            isView: false,
          },
          {
            title: "Agreement Type",
            key: "agreementTypeCode",
            col: "2",
            isLink: false,
            isView: false,
          },
          {
            title: "Agreement#",
            key: "agreementNumber",
            col: "2",
            isLink: false,
            isView: false,
          },
          {
            title: "Status",
            key: "",
            col: "2",
            isLink: false,
            isView: false,
          },
          {
            title: "Executed Date",
            key: "",
            col: "2",
            isLink: false,
            isView: false,
          },
        ],
      },
      {
        title: "Flagger Needs",
        list: [],
        rowClick: true,
        openIds: ["dotnumber", "railroad"],
        /* linkText: "View Project Flagger Needs", */
        routeLink:
          CONFIG_ENDPOINT.GCMS + id + CONFIG_ENDPOINT.PROJECT_FLAGGER_NEEDS,
        header: [
          {
            title: "Railroad Name",
            key: "railroad",
            col: "4",
            isLink: true,
            isView: false,
          },
          {
            title: "DOT#",
            key: "dotnumber",
            col: "2",
            isLink: false,
            isView: false,
          },
          {
            title: "Total Flaggers",
            key: "flaggernumber",
            col: "2",
            isLink: false,
            isView: false,
          },
          {
            title: "Total Flagger Days",
            key: "flaggerdays",
            col: "2",
            isLink: false,
            isView: false,
          },
          {
            title: "Activity",
            key: "activitynames",
            col: "2",
            isLink: false,
            isView: false,
          },
        ],
      },
      {
        title: "D-4279A",
        list: [],
        routeLink: "",
        header: [
          {
            title: "Railroad",
            key: "railRoadName",
            col: "",
            isView: false,
          },
          {
            title: "DOT#",
            key: "dotNumber",
            col: "",
            isLink: false,
            isView: false,
          },
          {
            title: "Date",
            key: "documentUploadDate",
            col: "5",
            isView: false,
          },
          {
            title: "",
            key: "",
            col: "",
            isLink: false,
            isView: true,
          },
        ],
      },
      {
        title: "Insurance Requirements and Railroad Specifications",
        list: [],
        routeLink: "",
        header: [
          {
            title: "Documents",
            key: "documentType",
            col: "",
            isView: false,
          },
          {
            title: "Railroad",
            key: "railRoadName",
            col: "",
            isView: false,
          },
          {
            title: "Date Uploaded",
            key: "documentUploadDate",
            col: "",
            isView: false,
          },
          {
            title: "",
            key: "",
            col: "",
            isLink: false,
            isView: true,
          },
        ],
      },
    ]);
    let parentChildData = ref(null);
    let projectDetails = ref(null);
    let originalData = ref(null);
    let isInitialised = ref(false);
    let valueChanged = ref(false);
    let previewRailroadAccess = ref(false);
    let conditionalAccess = ref({ read: false, update: false });
    let railroadCertificateAccess = ref({ read: false, update: false });
    async function getParentChildData() {
      axios
        .get(CONFIG_ENDPOINT.PROJECT_INFO_HIERARCHY + id)
        .then((response) => {
          parentChildData.value = response.data;
        })
        .catch((err) => {
          parentChildData.value = null;
        });
    }
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const generateRailroadCertificateReadAccess = ref(false);
    const checkAccess = async () => {
      generateRailroadCertificateReadAccess.value =
        await userRoleBasedAccessService.checkUserAccess(
          RBAC_CONTROLLER.GENERATE_RAILROAD_CERTIFICATIONS_READ_ACCESS
        );
      previewRailroadAccess.value =
        await userRoleBasedAccessService.checkUserAccess(
          RBAC_CONTROLLER.PREVIEW_RAILROAD_CERTIFICATIONS_READ_ACCESS
        );
      const railroadCertReadAccess =
        await userRoleBasedAccessService.checkUserAccess(
          RBAC_CONTROLLER.RAILROAD_CERTIFICATIONS_PAGE_READ_ACCESS
        );
      const railroadCertUpdateAccess =
        await userRoleBasedAccessService.checkUserAccess(
          RBAC_CONTROLLER.RAILROAD_CERTIFICATIONS_PAGE_CRTUPD_ACCESS
        );

      railroadCertificateAccess.value.read = railroadCertReadAccess || false;
      railroadCertificateAccess.value.update =
        railroadCertUpdateAccess || false;

      const conditionalReadAccess =
        await userRoleBasedAccessService.checkUserAccess(
          RBAC_CONTROLLER.VIEW_CONDITIONAL_CERTIFICATIONS_LIST_READ_ACCESS
        );
      const conditionalUpdateAccess =
        await userRoleBasedAccessService.checkUserAccess(
          RBAC_CONTROLLER.VIEW_CONDITIONAL_CERTIFICATIONS_LIST_CRTUPD_ACCESS
        );
      conditionalAccess.value.read = conditionalReadAccess || false;
      conditionalAccess.value.update = conditionalUpdateAccess || false;
    };
    onMounted(async () => {
      await checkAccess();
      if (railroadCertificateAccess.value.read) {
        getProjectDetails();
        getRightOnWayList();
        getPucDocumentList();
        getFlaggerList();
        getAgreementList();
        getInsuranceRequirementAndRRSpecification();
        getD42790List();
        getParentChildData();
      }
    });
    const getDate = (date) => {
      return getFormattedDateStringNoTime(date);
    };
    const getProjectDetails = () => {
      axios
        .get(CONFIG_ENDPOINT.GCMS_MAIN_PROJECT + id)
        .then((response) => {
          projectDetails.value = setProjectDetails(response?.data);
          setTimeout(() => {
            isInitialised.value = true;
          }, 1000);
          originalData.value = setProjectDetails(response?.data);
        })
        .catch((err) => {
          projectDetails.value = null;
        });
    };
    const companionMpmsProjects = computed(() => {
      return (
        parentChildData.value?.children?.map((child) => ({
          mpmsNumber: child.mpmsNumber,
          isLink: child.gcmsProject?.gcmsProjectFlag === "Y",
        })) ?? []
      );
    });
    const setProjectDetails = (data) => {
      let list = data;
      list = {
        ...list,
        letDate: getDate(list?.letDate),
        noticeToProceedDate: getDate(list?.noticeToProceedDate),
        isConditional: list?.isConditional?.map((condition) => {
          if (condition?.description?.includes("   ")) {
            return {
              ...condition,
              subTitle: condition?.description?.split("   ")[0],
              description: condition?.description?.split("   ")[1],
            };
          } else if (condition?.description?.includes(" – ")) {
            return {
              ...condition,
              subTitle: condition?.description?.split(" – ")[0],
              description: condition?.description?.split(" – ")[1],
            };
          } else if (condition?.description?.includes(" - ")) {
            return {
              ...condition,
              subTitle: condition?.description?.split(" - ")[0],
              description: condition?.description?.split(" - ")[1],
            };
          }
        }),
      };
      return list;
    };
    const isGenerateRailroadCertificateDisabled = computed(
      () =>
        !generateRailroadCertificateReadAccess.value ||
        projectDetails.value?.fedStatusDisabled
    );

    watch(
      () => [
        projectDetails.value?.isConditional,
        projectDetails.value?.readyForDivisionChefReview,
        projectDetails.value?.reasonForConditional,
      ],
      () => {
        if (
          isInitialised.value &&
          (!arraysEqual(
            originalData.value?.isConditional,
            projectDetails.value?.isConditional
          ) ||
            originalData.value?.readyForDivisionChefReview !=
              projectDetails.value?.readyForDivisionChefReview ||
            originalData.value?.reasonForConditional !=
              projectDetails.value?.reasonForConditional)
        ) {
          valueChanged.value = true;
        } else {
          valueChanged.value = false;
        }
      },
      { deep: true }
    );
    const arraysEqual = (a, b) => {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a?.length !== b?.length) return false;

      for (let i = 0; i < a.length; ++i) {
        if (!objectsAreSame(a[i], b[i])) return false;
      }
      return true;
    };
    const objectsAreSame = (x, y) => {
      let objectsAreSame = true;
      for (let propertyName in x) {
        if (x[propertyName] !== y[propertyName]) {
          objectsAreSame = false;
          break;
        }
      }
      return objectsAreSame;
    };
    const getRightOnWayList = () => {
      axios
        .get(CONFIG_ENDPOINT.VIEW_PROJECT_INVOLVEMENT + id)
        .then((response) => {
          panelList.value[0].list = [
            {
              ...response.data,
              railroadRWAcquired:
                response?.data?.railroadRWAcquired === "Y" ? "Yes" : "No",
              appropriationType:
                response?.data?.railroadRWAcquired === "Y"
                  ? response.data.appropriationTypes
                      ?.filter((type) => type?.associationPresent)
                      ?.map((type) => type?.appropType)
                      ?.join(", ")
                  : "",
            },
          ];
        })
        .catch((err) => {
          RightOnWayList.value = [];
        });
    };
    const getPucDocumentList = () => {
      axios
        .get(CONFIG_ENDPOINT.RAILROAD_CERTIFICATE_PUC_API + id)
        .then((response) => {
          let pucList = response?.data?.map((puc) => {
            return {
              ...puc,
              pucCommDate: getDate(puc?.pucCommDate),
              hideViewButton: !puc?.documentId,
            };
          });
          panelList.value[1].list = pucList;
        })
        .catch((err) => {
          panelList.value[1].list = [];
        });
    };
    const getD42790List = () => {
      axios
        .get(CONFIG_ENDPOINT.GET_D42790_GET_API + id)
        .then((response) => {
          let D4279List = response?.data?.data?.map((D4279) => {
            return {
              ...D4279,
              documentUploadDate: getDate(D4279?.documentUploadDate),
              hideViewButton: !D4279?.documentId,
            };
          });
          panelList.value[4].list = D4279List;
        })
        .catch((err) => {
          panelList.value[4].list = [];
        });
    };
    const getFlaggerList = () => {
      axios
        .get(CONFIG_ENDPOINT.MANAGE_PROJECT_FLAGGER_NEEDS + "/" + id)
        .then((response) => {
          panelList.value[3].list = response.data
            ?.map((res) => {
              return { ...res, activitynames: res?.activitynames?.join(", ") };
            })
            ?.filter((data) => data?.railroad)
            ?.sort((a, b) => a.railroad.localeCompare(b.railroad));
        })
        .catch((err) => {
          FlaggerList.value = [];
        });
    };

    const getAgreementList = () => {
      axios
        .get(CONFIG_ENDPOINT.GET_RAILROAD_ESTIMATES_LIST + id)
        .then((response) => {
          panelList.value[2].list = response.data?.filter(
            (res) => res?.status?.toLowerCase() === "accepted"
          );
        })
        .catch((err) => {
          AgreementsList.value = [];
        });
    };
    const getInsuranceRequirementAndRRSpecification = () => {
      axios
        .get(CONFIG_ENDPOINT.GET_INSURANCE_RAILROAD_SPEC + id)
        .then((response) => {
          console.log(
            "getInsuranceRequirementAndRRSpecification",
            response.data
          );
          panelList.value[5].list = response.data.data.map((insuranceData) => {
            return {
              ...insuranceData,
              documentUploadDate: getDate(insuranceData?.documentUploadDate),
              hideViewButton: !insuranceData?.escDocumentId,
            };
          });
        })
        .catch((err) => {
          panelList.value[5].list = [];
        });
    };

    const openViewFundingDialog = () => {
      viewFundingDialogRef.value.open(id).then((resolve) => {
        if (resolve) {
        }
      });
    };

    const saveClicked = () => {
      axios
        .post(CONFIG_ENDPOINT.RAILROAD_CERTIFICATION_POST + id, {
          isConditional: projectDetails.value.isConditional
            ?.filter((condition) => condition.selected)
            ?.map((condition) => condition.title),
          reasonForConditional: projectDetails.value.reasonForConditional,
          readyForDivisionChefReview:
            projectDetails.value.readyForDivisionChefReview,
        })
        .then((response) => {
          emit("BannerUpdate", {
            bannerType: "success",
            bannerMessage: "Saved Successfully.",
            showBanner: true,
          });
          window.scrollTo(0, 0);
          isInitialised.value = false;
          valueChanged.value = false;
          getProjectDetails();
        })
        .catch((err) => {});
    };

    const cancelClicked = () => {
      valueChanged.value = false;
      isInitialised.value = false;
      getProjectDetails();
    };

    let selectedConditionsLength = ref();
    const selectedConditionsLengthCheck = computed({
      get: () => {
        return projectDetails.value?.isConditional?.filter(
          (res) => res.selected
        )?.length;
      },
    });

    const checkIfPUCApprovalOfProject = computed({
      get: () => {
        return projectDetails.value?.isConditional
          ?.filter((res) => res.selected)
          ?.map((res) => res?.description)
          ?.includes("PUC must approve the Application (CO Override)");
      },
    });

    const checkIfPUCApproveConstructionPlans = computed({
      get: () => {
        return projectDetails.value?.isConditional
          ?.filter((res) => res.selected)
          ?.map((res) => res?.description)
          ?.includes(
            "PUC must approve the construction plans prior to bid opening"
          );
      },
    });

    const expandToggle = (type) => {
      if (type === "expand") {
        panel.value = [0, 1, 2, 3, 4, 5];
      } else {
        panel.value = [];
      }
    };

    onBeforeRouteLeave((to, from, next) => {
      if (isInitialised.value && valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          valueChanged.value = false;
          next();
        } else {
          next(false);
        }
      } else {
        valueChanged.value = false;
        next();
      }
    });
    window.onbeforeunload = function () {
      if (isInitialised.value && valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };
    const download = (docId) => {
      if (!docId) {
        console.error("Document is undefined");
        return;
      }
      let endpoint = CONFIG_ENDPOINT.DOWNLOAD_SCOPING_FILE;
      documentStore.downloadFile(docId, id, endpoint);
    };
    const rowClickNavigation = (index, row) => {
      if (index === 0 && row?.railroadRWAcquired) {
        router.push({
          path: panelList.value[index]?.routeLink,
        });
      } else if (index === 3 && row?.dotnumber && row?.railroad) {
        router.push({
          path: panelList.value[index]?.routeLink,
          query: { dotnumber: row?.dotnumber, railroad: row?.railroad },
        });
      } else if (index === 2) {
        router.push({
          path:
            panelList.value[index]?.routeLink +
            "/edit/" +
            row?.railroadCompanyId +
            "/" +
            row?.railroadEstimateId,
        });
      }
    };
    const userData = computed({
      get: () => {
        return sharedUserService.getSharedData();
      },
    });
    const generateRailroadCertificate = (isGenerate) => {
      let payload = {
        mpmsNumber: id,
        previewRailroadCertificate: false,
        userid: userData.value?.userID,
        metaData: {
          docType: "Railroad Certification",
          filename: null,
          description: "Railroad Certification",
          mpmsNumber: id,
        },
      };
      if (isGenerate && generateRailroadCertificateReadAccess.value) {
        loading.value = true;
        generateRailroadCertificateAPICall(payload);
      } else if (!isGenerate && previewRailroadAccess.value) {
        loading.value = true;
        payload = { ...payload, previewRailroadCertificate: true };
        previewRailroadCertificateAPICall(payload);
      }
    };
    const previewRailroadCertificateAPICall = (payload) => {
      axios
        .post(CONFIG_ENDPOINT.RAILROAD_CERTIFICATE_PREVIEW + id, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          let pdfWindow = window.open("");
          pdfWindow.document.write(
            "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
              encodeURI(response.data.data) +
              "'></iframe>"
          );
          loading.value = false;
        })
        .catch((err) => {
          loading.value = false;
        });
    };
    const generateRailroadCertificateAPICall = (payload) => {
      axios
        .post(CONFIG_ENDPOINT.RAILROAD_CERTIFICATE_GENERATE + id, payload, {
          headers: {
            userid: userData.value?.userID,
            password: userData.value?.userID,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          emit("BannerUpdate", {
            bannerType: "success",
            bannerMessage: "Generated Successfully.",
            showBanner: true,
          });
          window.scrollTo(0, 0);
          loading.value = false;
        })
        .catch((err) => {
          loading.value = false;
        });
    };
    const removeBanner = () => {
      emit("BannerUpdate", {
        bannerType: "success",
        bannerMessage: "Generated Successfully.",
        showBanner: false,
      });
    };
    return {
      id,
      panel,
      panelList,
      openViewFundingDialog,
      viewFundingDialogRef,
      projectDetails,
      saveClicked,
      requiredRule,
      minConditionLength,
      selectedConditionsLength,
      selectedConditionsLengthCheck,
      checkIfPUCApprovalOfProject,
      checkIfPUCApproveConstructionPlans,
      expandToggle,
      cancelClicked,
      valueChanged,
      download,
      rowClickNavigation,
      generateRailroadCertificate,
      loading,
      isGenerateRailroadCertificateDisabled,
      removeBanner,
      companionMpmsProjects,
      previewRailroadAccess,
      railroadCertificateAccess,
      conditionalAccess,
    };
  },
};
