import useFieldEntry from "@/composables/fieldEntry";
import { validPUCDocketNumber } from "@/composables/validationRules";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import DialogComponent from "@/modules/dot-details/components/PucDocumentsModal.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import VueTable from "@/modules/shared/components/VueTable.vue";
import sharedUserService from "@/modules/shared/service/sharedUserService";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import { computed, onMounted, ref, toRefs, watch } from "vue";

export default {
  name: "PucDocuments",
  components: {
    VueTable,
    PrimaryButton,
    DialogComponent,
  },
  props: ["dotId", "resetPUC", "tab"],
  emits: ["showBanner"],

  setup(props, context) {
    let docketList = ref([]);
    let panel = ref([0]);
    const docketValue = ref("");
    const tab = ref("part1");
    const isFormValid = ref(false);
    const showInvalidPUC = ref(false);
    const lastEnteredPUCValue = ref("");
    const { allowAlphanumericOnly } = useFieldEntry();
    const links = ref([]);
    const dotId = computed({
      get: () => {
        return props?.dotId;
      },
    });
    const dialogComponent = ref(null);
    const showBannerMessage = (event) => {
      context.emit("showBanner", event);
    };
    const showInvalidPUCFormat = ref(false);
    const pucFormatErrorMsg = ref("");
    const headers = ref([
      {
        name: "Docket #",
        key: "pucDocketNumber",
        columnWidth: "34",
        isLink: true,
        sortable: false,
      },
      {
        name: "MPMS #",
        key: "mpmsNumber",
        columnWidth: "33",
        sortable: false,
      },
      {
        name: "Source",
        key: "sourceId",
        columnWidth: "33",
        sortable: false,
      },
    ]);

    watch(
      () => [props.tab],
      () => {
        if (props.tab === "PucDocuments") {
          fetchData();
        }
      },
      { deep: true }
    );
    const isUserGradeCrossingAccess = ref({
      read: false,
      update: false,
    });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const gradeCrossingAccessCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.GRADE_CROSSING_RECORD_READ_ACCESS
      );
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.GRADE_CROSSING_RECORD_CRTUPD_ACCESS
      );
      isUserGradeCrossingAccess.value.read = readResponse || false;
      isUserGradeCrossingAccess.value.update = updateResponse || false;
    };

    onMounted(async () => {
      await gradeCrossingAccessCheck();
      if (isUserGradeCrossingAccess.value.read) {
        await fetchData();
      }
    });
    async function fetchData() {
      await axios
        .get(`${CONFIG_ENDPOINT.PUCDOCUMENTS}${props.dotId}`)
        .then((response) => {
          docketList.value = response?.data;
        })
        .catch((err) => {
          console.log(err);
        });
    }

    const linkClicked = (event) => {
      links.value = [];
      getPucDocs(event.value);
    };

    const userData = computed({
      get: () => {
        return sharedUserService.getSharedData();
      },
    });

    const checkIfPucIsValid = (enteredValue) => {
      axios
        .post(
          CONFIG_ENDPOINT.PUCDOCUMENTS +
            dotId.value +
            "?docketNumber=" +
            enteredValue +
            "&userID=" +
            userData.value?.userID
        )
        .then((response) => {
          docketValue.value = "";
          docketList.value = response.data;
          headers.value.forEach((header, index) => {
            headers.value[index] = {
              ...header,
            };
          });
        })
        .catch((err) => {
          // writing this invalid scenario if the backend service gives 404
          if (
            err.response &&
            (err.response.status == 500 ||
              err.response.status === 404 ||
              err.response.status === 409)
          ) {
            // API returned a 404 Not Found error
            showInvalidPUC.value = true;
            lastEnteredPUCValue.value = enteredValue;
            docketValue.value = "";
          }
        });
    };
    //http://localhost:9081/gcms-gradecrossing-service/api/puc-documents?docketNumber=A-00116822
    const checkFieldDisabled = (key) => {
      if (key === "projectIdentifier") {
        return true;
      }
      return false;
    };
    const getPucDocs = (enteredValue) => {
      axios
        .get(CONFIG_ENDPOINT.PUCLINKS + "?docketNumber=" + enteredValue)
        .then((response) => {
          links.value = response.data;
          openDialog(enteredValue, response.data);
        })
        .catch((err) => {
          // writing this invalid scenario if the backend service gives 404
          console.log(err);
        });
    };
    const removeError = (event) => {
      showInvalidPUC.value = false;
      if (event.code == "Backspace") {
        return;
      }
    };

    const submitForm = (docketValue) => {
      if (!validPUCDocketNumber(docketValue)) {
        showInvalidPUCFormat.value = true;
        pucFormatErrorMsg.value = validPUCDocketNumber(pucFormatErrorMsg);
      } else {
        checkIfPucIsValid(docketValue);
        showInvalidPUCFormat.value = false;
        pucFormatErrorMsg.value = "";
      }
    };

    const openDialog = (docketValue, data) => {
      dialogComponent.value.open(docketValue, data).then((resolve) => {
        if (resolve) {
          console.log("resolved");
        }
      });
    };

    let { resetPUC } = toRefs(props);

    watch(resetPUC, () => {
      showInvalidPUC.value = false;
    });
    watch(
      () => [docketValue.value],
      () => {
        showInvalidPUCFormat.value = false;
        pucFormatErrorMsg.value = "";
      },
      { deep: true }
    );

    return {
      docketList,
      tab,
      dotId,
      panel,
      showBannerMessage,
      headers,
      linkClicked,
      submitForm,
      checkIfPucIsValid,
      docketValue,
      openDialog,
      allowAlphanumericOnly,
      dialogComponent,
      fetchData,
      showInvalidPUC,
      lastEnteredPUCValue,
      checkFieldDisabled,
      removeError,
      links,
      getPucDocs,
      validPUCDocketNumber,
      isFormValid,
      showInvalidPUCFormat,
      pucFormatErrorMsg,
      isUserGradeCrossingAccess,
    };
  },
};
