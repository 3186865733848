<template>
  <v-card class="p-64">
    <v-card-text>
      <div class="page-title" id="inspection-heading">
        <h1>Inventory Inspection</h1>
      </div> </v-card-text
    ><InspectionR2AddEdit
      v-if="addOrEdit != 'table'"
      :addOrEdit="addOrEdit"
      :dotId="selectedDOT"
      :crossingInspectionId="selectedCrossingInspectionId"
      @resetToTable="resetToTable($event)"
      @showBanner="showBanner"
    ></InspectionR2AddEdit>
    <InspectionsR2Table
      v-if="addOrEdit === 'table' && isInitialised"
      :tab="tab"
      :dotId="dotId"
      :userAccess="userAccess"
      @buttonClicked="buttonClicked($event)"
    ></InspectionsR2Table>
    <div
      class="d-flex justify-content-start m-20"
      v-if="addOrEdit === 'table' && userAccess.update"
    >
      <PrimaryButton
        @buttonClicked="addButtonClicked"
        :buttonText="'Add Inventory Inspection'"
      ></PrimaryButton>
    </div>
  </v-card>
</template>

<script>
import InspectionsR2 from "@/modules/dot-details/scripts/InspectionsR2.js";
export default InspectionsR2;
</script>
<style>
.container {
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
  padding-top: 20px;
}
</style>
