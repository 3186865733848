<template>
  <v-dialog
    persistent
    @afterLeave="afterModalLeave"
    v-model="dialog"
    width="50vw"
    max-width="90vw"
    min-height="50vh"
    max-height="3000px"
  >
    <div class="dialogheading">
      <div class="w-100 d-flex justify-content-between">
        <div class="w-90 text-center f-size24">Add Follower to Project</div>
        <div>
          <v-icon @click="closeDialog" class="cursor-pointer" small>
            mdi-close
          </v-icon>
        </div>
      </div>
      <v-card>
        <v-card-text
          style="min-height: 35vh; max-height: 80vh; overflow-y: scroll"
        >
          <v-form class="mpmsSubheader">
            <div
              class="d-flex align-items-center justify-content-start"
              style="width: 100%"
            >
              <label for="userName" class="createLabel" style="width: 30%"
                ><b class="required"><span>User Name: </span></b></label
              >
              <div
                class="createRow d-flex align-items-center"
                style="margin: 0 !important; width: 70% !important"
              >
                <v-autocomplete
                  v-model="displayName"
                  :items="userNameList"
                  :no-data-text="AUTO_COMPLETE_NO_DATA"
                  item-title="displayName"
                  item-value="userId"
                  :menu-props="{
                    minWidth: getDropdownItemsMaxLength(),
                    width: getDropdownItemsMaxLength(),
                  }"
                ></v-autocomplete>
              </div>
            </div>
            <div
              class="messageRow text-left mt-20 project-invalid-email p-0 color-red"
              v-if="showError"
            >
              {{ errorMessage }}
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <div class="action">
            <PrimaryButton
              :buttonText="'Save'"
              @click="addUser"
              :disabled="!valueChanged"
            ></PrimaryButton>
            <SecondaryButton
              :buttonText="'Cancel'"
              class="ml-2"
              @click="closeDialog"
            ></SecondaryButton>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
  <LoadingOverlay :isLoading="loading"></LoadingOverlay>
</template>
<script>
import AddUserToProjectDialog from "@/modules/manageproject/scripts/AddUserToProjectDialog.js";
export default AddUserToProjectDialog;
</script>
<style>
@import "@/modules/projectinformation/assets/projectinfostyle.scss";
@import "@/assets/css/style.scss";
</style>