<template>
  <v-dialog
    persistent
    @afterLeave="afterModalLeave"
    id="dialog"
    v-model="dialog"
    max-width="950px"
    max-height="4000px"
  >
    <div class="dialogheading">
      <div class="w-100 d-flex justify-content-between">
        <div class="w-90 text-center f-size24">
          {{ title }}
        </div>
        <div>
          <v-icon @click="closeDialog" class="cursor-pointer" small
            >mdi-close
          </v-icon>
        </div>
      </div>
      <v-card v-if="formData">
        <v-card-text style="max-height: 400px; overflow-y: scroll">
          <v-form v-model="isFormValid" class="district-office-form">
            <v-grid>
              <br />
              <v-row class="dialogRowParty" cols="12">
                <v-col class="required dialogLabeltextParty" cols="2">
                  <label>First Name: </label>
                </v-col>
                <v-col class="dialogLabeltextParty" cols="4">
                  <v-text-field
                    v-model="formData.firstName"
                    @keydown="allowAlphaOnly($event)"
                    type="text"
                    min="0"
                    maxlength="50"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="1"></v-col>
                <v-col class="dialogLabeltext" cols="1">
                  <label>MI: </label>
                </v-col>
                <v-col class="dialogLabeltext" cols="1">
                  <v-text-field
                    v-model="formData.middleName"
                    @keydown="allowAlphaOnly($event)"
                    type="text"
                    min="0"
                    maxlength="50"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row class="dialogRowParty" cols="12">
                <v-col class="required dialogLabeltextParty" cols="2">
                  <label>Last Name: </label>
                </v-col>
                <v-col class="dialogLabeltextParty" cols="4">
                  <v-text-field
                    v-model="formData.lastName"
                    @keydown="allowAlphaOnly($event)"
                    type="text"
                    min="0"
                    maxlength="50"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row class="dialogRowParty" cols="12">
                <v-col class="dialogLabeltextParty" cols="2">
                  <label>Organization: </label>
                </v-col>
                <v-col
                  class="dialogLabeltextParty"
                  cols="6"
                  v-if="!formData.firstName && !formData.lastName"
                >
                  <v-text-field
                    v-model="formData.organization"
                    :rules="[requiredRule]"
                    @keydown="allowAlphaOnly($event)"
                    type="text"
                    min="0"
                    maxlength="50"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  class="dialogLabeltextParty"
                  cols="6"
                  v-else-if="
                    (!formData.firstName && formData.lastName) ||
                    (formData.firstName && !formData.lastName)
                  "
                >
                  <v-text-field
                    v-model="formData.organization"
                    :rules="[requiredRule]"
                    @keydown="allowAlphaOnly($event)"
                    type="text"
                    min="0"
                    maxlength="50"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="dialogLabeltextParty" cols="6" v-else>
                  <v-text-field
                    v-model="formData.organization"
                    @keydown="allowAlphaOnly($event)"
                    type="text"
                    min="0"
                    maxlength="50"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row class="dialogRowParty" cols="12">
                <v-col class="dialogLabeltextParty" cols="2">
                  <label>Title: </label>
                </v-col>
                <v-col class="dialogLabeltextParty" cols="6">
                  <v-text-field
                    v-model="formData.title"
                    @keydown="allowAlphaOnly($event)"
                    type="text"
                    min="0"
                    maxlength="100"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <AddressInput
                :formData="formData"
                :lookupData="states"
                :isPage="false"
                :isPartiesOfRecord="true"
              >
              </AddressInput>

              <v-row class="dialogRowParty" cols="12">
                <v-col class="dialogLabeltextParty" cols="2">
                  <label>Email:</label>
                  <label class="asterisk">*</label>
                </v-col>
                <v-col class="dialogLabeltextParty" cols="4">
                  <EmailInput
                    v-model="formData.email"
                    :isRequired="true"
                  ></EmailInput>
                  <v-row v-if="showErrorBanner">
                    <v-col cols="12" class="ml-2">
                      <div
                        class="errorRow"
                        style="padding: 16px 8px 0px 5px !important"
                      >
                        <div class="error">
                          <div>
                            Email must be unique to the Parties of Record on the
                            project
                          </div>
                        </div>
                      </div>
                    </v-col></v-row
                  >
                </v-col>
              </v-row>
            </v-grid>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <div class="action">
            <PrimaryButton
              @buttonClicked="saveData"
              :buttonText="'Save'"
              :disabled="
                !isFormValid ||
                !isAddressFormValid ||
                !isEmailFormValid ||
                !valueChanged
              "
            ></PrimaryButton>
            <SecondaryButton
              @buttonClicked="closeDialog"
              :buttonText="'Cancel'"
              class="ml-2"
            ></SecondaryButton>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>
  <script>
import PartiesOfRecordDialog from "@/modules/manageproject/scripts/PartiesOfRecordDialog.js";

export default PartiesOfRecordDialog;
</script>
  <style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
</style>
  