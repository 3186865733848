<template>
  <v-text-field
    v-model="computedValue"
    type="text"
    min="0"
    maxlength="10"
    @paste.prevent
    onkeydown="return ( event.ctrlKey || event.altKey 
                    || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) 
                    || (95<event.keyCode && event.keyCode<106)
                    || (event.keyCode==8) || (event.keyCode==9) 
                    || (event.keyCode>34 && event.keyCode<40) 
                    || (event.keyCode==46) )"
    :validate-on="'input'"
    :rules="getZipFieldRule ? [requiredRule, zipRule] : []"
    @update:modelValue="
      fieldValueChange();
      debounce(() => {
        console.log('Zip');
        setFieldRule();
      });
    "
  >
  </v-text-field>
</template>
  <script>
import ZIPInput from "@/modules/shared/scripts/ZIPInput.js";
export default ZIPInput;
</script>
  
