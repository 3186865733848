import {  greaterThanEqualToZero,
  maxLengthRule1,
  maxLengthRule2,
  maxLengthRule999,
  minLengthRule1,
  minimumRule,
  requiredRule,
} from "@/composables/validationRules";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import {
  AUTO_COMPLETE_NO_DATA,
  DROPDOWN_NO_DATA,
} from "@/constants/displayTexts";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import VueAutoComplete from "@/modules/shared/components/VueAutoComplete.vue";
import axios from "axios";
import { computed, onMounted, reactive, ref, toRefs, watch } from "vue";
import { useRoute } from "vue-router";
export default {
  components: { PrimaryButton, SecondaryButton, VueAutoComplete },
  props: {
    formData: {
      type: Object,
      default: {
        dotnumber: "",
        railroad: "",
        xingownr: "",
        milepost: "",
        noactivetracks: "",
        posxing: "",
        location: "",
        activitynames: [],
        flaggernumber: "",
        flaggerdays: "",
      },
    },
    id: { type: String, default: null },
    item: Object, // Item to be edited (if in 'edit' mode)    projectDetails: Object,
    itemIndex: "",
  },
  setup() {
    const route = useRoute();
    const mpmsId = route.params.id;
    let isFormValid = ref(false);
    const activityList = ref([]);
    let valueChanged = ref(false);
    const originalValue = ref();
    const isInitialised = ref(false);
    const state = reactive({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      itemIndex: null,
      formData: "OK",
    });
    onMounted(async () => {
      /* if (state?.formData.flaggernumber == 0) {
        state.formData.activitynames = [];
        state.formData.flaggerdays = "";
      } */
      getActivityList();
    });
    function getActivityList() {
      axios
        .get(CONFIG_ENDPOINT.GCMS_MAIN_FLAGGER_ACTIVITY_List)
        .then((res) => {
          activityList.value = res.data;
        })
        .catch((err) => {
          console.log(err);
          activityList.value = [];
        });
    }

    watch(
      () => [state.formData.flaggernumber],
      () => {
        if (state.formData.flaggernumber == 0) {
          state.formData.flaggerdays = null;
          // state.formData.activitynames = [];
        }
      },
      {
        flush: "pre",
        immediate: true,
        deep: true,
      }
    );
    watch(
      () => [state.formData],
      () => {
        if (
          isInitialised.value &&
          (originalValue.value?.dotnumber != state.formData.dotnumber ||
            originalValue.value?.railroad != state.formData?.railroad ||
            originalValue.value?.xingownr != state.formData.xingownr ||
            originalValue.value?.milepost != state.formData.milepost ||
            originalValue.value?.noactivetracks !=
              state.formData.noactivetracks ||
            originalValue.value?.posxing != state.formData.posxing ||
            originalValue.value?.location != state.formData.location ||
            originalValue.value?.activitynames !=
              state.formData.activitynames ||
            originalValue.value?.flaggernumber !=
              state.formData.flaggernumber ||
            originalValue.value?.flaggerdays != state.formData.flaggerdays)
        ) {
          valueChanged.value = true;
        } else {
          valueChanged.value = false;
        }
      },
      { deep: true }
    );

    const open = (title, message, formData, itemIndex) => {
      state.dialog = true;
      state.title = title;
      state.message = message;
      state.formData = formData;
      originalValue.value = state.event?.assignedDistricts
        ? JSON.parse(JSON.stringify(state.event?.assignedDistricts))
        : null;
      setTimeout(() => {
        isInitialised.value = true;
      }, 1000);
      state.itemIndex = itemIndex;
      return new Promise((resolve, reject) => {
        state.resolve = resolve;
        state.reject = reject;
      });
    };
    const isSaveDisabled = computed(() => {
      if (!valueChanged.value) {
        return true;
      }
      if (
        state.formData.flaggernumber === 0 ||
        state.formData.flaggernumber === "0"
      ) {
        return false;
      }
      if (
        state.formData.flaggernumber &&
        Number(state.formData.flaggernumber) > 0
      ) {
        return (
          state.formData.activitynames?.length === 0 ||
          !state.formData.flaggerdays
        );
      }
      return true;
    });
    const removeItem = (index) => {
      state.formData.activitynames.splice(index, 1);
    };
    const saveData = () => {
      PostOrPutData(state.formData.dotnumber);
    };
    const PostOrPutData = (value) => {
      axios
        .put(
          CONFIG_ENDPOINT.MANAGE_PROJECT_FLAGGER_NEEDS +
            CONFIG_ENDPOINT.SLASH +
            mpmsId +
            "/" +
            value,
          {
            ...state.formData,
            dotnumber: state.formData.dotnumber,
            railroad: state.formData?.railroad,
            xingownr: state.formData.xingownr,
            milepost: state.formData.milepost,
            noactivetracks: state.formData.noactivetracks,
            posxing: state.formData.posxing,
            location: state.formData.location,
            activitynames: state.formData.activitynames,
            flaggernumber: state.formData.flaggernumber,
            flaggerdays: state.formData.flaggerdays,
          }
        )
        .then(() => {
          state.resolve({
            type: state.messge,
            data: {
              ...state.formData,
              dotnumber: state.formData.dotnumber,
              railroad: state.formData?.railroad,
              xingownr: state.formData.xingownr,
              milepost: state.formData.milepost,
              noactivetracks: state.formData.noactivetracks,
              posxing: state.formData.posxing,
              location: state.formData.location,
              activitynames: state.formData.activitynames,
              flaggernumber: state.formData.flaggernumber,
              flaggerdays: state.formData.flaggerdays,
            },
            index: state.itemIndex,
          });

          state.dialog = false;
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const closeDialog = () => {
      if (isInitialised.value && valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          valueChanged.value = false;
          state.resolve(false);
          state.dialog = false;
        }
      } else {
        valueChanged.value = false;
        state.resolve(false);
        state.dialog = false;
      }
    };
    const afterModalLeave = () => {
      valueChanged.value = false;
      isInitialised.value = false;
      originalValue.value = null;
    };
    window.onbeforeunload = function () {
      if (isInitialised.value && valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };
    return {
      ...toRefs(state),
      isFormValid: false,
      maxLengthRule1,
      maxLengthRule2,
      maxLengthRule999,
      minimumRule,
      minLengthRule1,
      requiredRule,
      greaterThanEqualToZero,
      activityList,
      removeItem,
      saveData,
      PostOrPutData,
      closeDialog,
      isSaveDisabled,
      open,
      getActivityList,
      DROPDOWN_NO_DATA,
      AUTO_COMPLETE_NO_DATA,
      afterModalLeave,
      isFormValid,
    };
  },
};
