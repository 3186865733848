<template>
  <v-card>
    <v-tabs :value="getCurrentTab" dark align-tabs="start">
      <v-tab
        :class="{
          'v-slide-group-item--active': getCurrentTab === 'projectInformation',
          'v-tab--selected': getCurrentTab === 'projectInformation',
        }"
        @click="
          $event.preventDefault();
          onTabChange('projectInformation');
        "
        value="projectInformation"
        >Project Information</v-tab
      >
      <v-tab
        :class="{
          'v-slide-group-item--active': getCurrentTab === 'locationInformation',
          'v-tab--selected': getCurrentTab === 'locationInformation',
        }"
        @click="
          $event.preventDefault();
          onTabChange('locationInformation');
        "
        value="locationInformation"
        >Location Information</v-tab
      >
      <v-tab
        :class="{
          'v-slide-group-item--active':
            getCurrentTab === 'gradeCrossingAndBridges',
          'v-tab--selected': getCurrentTab === 'gradeCrossingAndBridges',
        }"
        @click="
          $event.preventDefault();
          onTabChange('gradeCrossingAndBridges');
          gradeCrossingClicked;
        "
        value="gradeCrossingAndBridges"
        >Grade Crossings & Bridges</v-tab
      >
      <v-tab
        :class="{
          'v-slide-group-item--active': getCurrentTab === 'miletones',
          'v-tab--selected': getCurrentTab === 'miletones',
        }"
        @click="
          $event.preventDefault();
          onTabChange('miletones');
        "
        value="miletones"
        v-if="
          projectDetails?.projectIdentifier?.includes?.(
            'Highway Bridge project'
          ) ||
          projectDetails?.projectIdentifier?.includes?.(
            'Highway Bridge Project'
          )
        "
        >Milestones</v-tab
      >
      <v-tab
        :class="{
          'v-slide-group-item--active': getCurrentTab === 'costAndFunding',
          'v-tab--selected': getCurrentTab === 'costAndFunding',
        }"
        @click="
          $event.preventDefault();
          onTabChange('costAndFunding');
        "
        value="costAndFunding"
        >Cost & Funding</v-tab
      >
      <v-tab
        :class="{
          'v-slide-group-item--active':
            getCurrentTab === 'designAndConstruction',
          'v-tab--selected': getCurrentTab === 'designAndConstruction',
        }"
        @click="
          $event.preventDefault();
          onTabChange('designAndConstruction');
        "
        value="designAndConstruction"
        >Design & Construction</v-tab
      >
    </v-tabs>

    <v-card-text>
      <div v-if="getCurrentTab === 'projectInformation'">
        <MpmsEnvironmentInfo
          :id="this.projectId"
          :saveClicked="saveClicked"
          :cancelClicked="cancelClicked"
          @previousValue="previousValue"
          @updatedValues="updatedValues"
          @refreshMpmsData="$emit('refreshMpmsData')"
          @commentUpdate="commentUpdate"
        ></MpmsEnvironmentInfo>
        <PA1CallDetails
          v-if="!hidePA1Call"
          :id="this.projectId"
          :projectDetails="this.projectDetails"
        >
        </PA1CallDetails>
        <v-row justify="center" v-if="completeScopingCheck?.warningMessage">
          <div class="railroadError2">
              <div>
                <v-icon icon="mdi-alert"></v-icon>
                {{ completeScopingCheck?.warningMessage }}
              </div>
            </div>
        </v-row>
        <div class="w-100 d-flex justify-content-end mt-20 mb-20">
          <PrimaryButton
            :buttonText="'Save'"
            @buttonClicked="saveMpmsDetails"
            :disabled="cancelClicked || checkPageSaveDisabled"
          ></PrimaryButton>
          <SecondaryButton
            :buttonText="'Cancel'"
            @buttonClicked="cancelMpmsClicked"
            class="ml-2"
          ></SecondaryButton>
          <PrimaryButton
            :buttonText="'Complete Scoping'"
            @buttonClicked="completeScopingClicked"
            :disabled="completeScopingCheck?.scopingCompleted"
            class="ml-2"
          ></PrimaryButton>
        </div>
      </div>
      <div v-if="getCurrentTab === 'locationInformation'">
        <MpmsLocationInfo :id="this.projectId"></MpmsLocationInfo>
      </div>
      <div v-if="getCurrentTab === 'costAndFunding'">
        <CostAndFunding :id="this.projectId"></CostAndFunding>
      </div>
      <div v-if="getCurrentTab === 'designAndConstruction'">
        <DesignAndConstruction :id="this.projectId"></DesignAndConstruction>
      </div>
      <div v-if="getCurrentTab === 'gradeCrossingAndBridges'">
        <GradeCrossingAndBridges
          :id="this.projectId"
          :panel="this.panel"
          @refreshMpmsData="$emit('refreshMpmsData')"
        ></GradeCrossingAndBridges>
      </div>
      <div v-if="getCurrentTab === 'miletones'">
        <MilestonesTab
          @showBanner="emitBanner(true)"
          @MilestonesTabValueChanged="MilestonesTabValueChanged($event)"
        ></MilestonesTab>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import TabsView from "@/modules/projectinformation/scripts/TabsView.js";
export default TabsView;
</script>
