<template>
  <div class="mb-40">
    <div class="page-title">
      <h1>Grade Crossings & Bridges</h1>
    </div>
    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel>
        <v-expansion-panel-title class="pannelColor">
          <div class="panel-header">Project Grade Crossings</div>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <div class="d-flex w-100 justify-content-between align-items-center">
            <div class="d-flex align-items-center w-25">
              <div class="w-7">
                <v-checkbox
                  v-if="!NoRailroadInvolvementDisabled"
                  color="#2a578d"
                  v-model="noRailroadInvolvement"
                  @update:modelValue="updateNoRailroadInvolvement($event)"
                  :disabled="
                    !isUserAccess.update ||
                    NoRailroadInvolvementDisabled ||
                    fedStatusDisabled
                  "
                ></v-checkbox>
                <div
                  style="height: 40px"
                  class="d-flex align-items-center justify-content-center"
                  v-if="NoRailroadInvolvementDisabled"
                >
                  <div class="disabledCheckbox ml-10 mt-20"></div>
                </div>
              </div>
              <span
                :style="{
                  color: NoRailroadInvolvementDisabled ? '#bdbdbd' : 'black',
                }"
                >No Railroad Involvement
              </span>
            </div>
            <div class="d-flex align-items-center w-50">
              <div class="w-7">
                <v-checkbox
                  color="#2a578d"
                  v-model="pendingNewCrossing"
                  @update:modelValue="toggleProjectPendingNewCrossing($event)"
                  :disabled="!isUserAccess.update"
                ></v-checkbox>
              </div>
              <span>Project Pending New Crossing </span>
            </div>
            <div class="w-50 text-right" v-if="isUserAccess.update">
              <router-link :to="`/GCMS/` + id + `/addRailroads`">
                Add Railroads to Impacted Crossings
              </router-link>
            </div>
          </div>
          <div class="mt-50">
            <VueTable
              :tableData="list1"
              :columnData="table1Header"
              :tableHeader="'Crossings Impacted'"
              @linkClicked="linkClicked($event)"
              @addRowClicked="addToVicinityList($event)"
              @otherRowClicked="addToImpactedList($event, 'Nearby')"
              @deleteClicked="(event) => deleteClicked(event, 'Impacted')"
              :disableLinks="!isUserAccess.update"
              :disableActions="!isUserAccess.update"
              :linkIndicator="'Indicates a Railroad has been associated to an impacted crossing. In order to remove an impacted crossing you must first remove the Railroad.'"
            ></VueTable>
          </div>
          <div class="mt-50">
            <VueTable
              :tableData="list3"
              :columnData="table3Header"
              :tableHeader="'Nearby Crossings'"
              @linkClicked="linkClicked($event)"
              @addRowClicked="addToVicinityList($event)"
              @otherRowClicked="addToImpactedList($event, 'Impacted')"
              @deleteClicked="deleteClicked($event, 'Nearby')"
              :disableLinks="!isUserAccess.update"
              :disableActions="!isUserAccess.update"
            ></VueTable>
          </div>
          <div class="mt-50">
            <VueTable
              :tableData="list2"
              :columnData="table2Header"
              :tableHeader="'Crossings Within the Vicinity (Non-Impacted)'"
              @linkClicked="linkClicked($event)"
              @otherRowClicked="addToImpactedList($event, 'Nearby')"
              @addRowClicked="addToImpactedList($event, 'Impacted')"
              @deleteClicked="(event) => deleteClicked(event, 'Vicinity')"
              :disableLinks="!isUserAccess.update"
              :disableActions="!isUserAccess.update"
            ></VueTable>
          </div>
          <AddToImpactedList
            :id="id"
            @refreshData="refreshAddToImpactedList"
            :otherEventsPerformed="otherEventsPerformed"
            @updateOtherEventsPerformed="updateOtherEventsPerformed"
          ></AddToImpactedList>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel class="mt-20">
        <v-expansion-panel-title class="pannelColor">
          <div class="panel-header">Project Bridges</div>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <ProjectBridges :id="id"></ProjectBridges>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
  <LoadingOverlay :isLoading="loading"> </LoadingOverlay>
  <DialogComponent ref="checkboxConfirmationDialog"></DialogComponent>
</template>
<script>
import GradeCrossingAndBridges from "@/modules/grade-crossing-and-bridges/scripts/GradeCrossingAndBridges.js";
export default GradeCrossingAndBridges;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
@import "@/modules/grade-crossing-and-bridges/assets/GradeCrossingAndBridges.scss";
.disabledCheckbox {
  background-color: #e0e0e0 !important;
  width: 17px !important;
  height: 17px !important;
  display: block;
  border: 1px solid #bdbdbd;
  margin: 0 !important;
  padding: 0 !important;
  margin-right: 10px !important;
}
</style>