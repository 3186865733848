import DialogComponent from "@/modules/projectinformation/components/ScopingViewUpdateDialog.vue";import UploadScopingFieldViewDocument from "@/modules/scoping-field-view/components/UploadScopingFieldViewDocument.vue";
import DocumentsList from "@/modules/shared/components/DocumentsList.vue";
import LoadingOverlay from "@/modules/shared/components/LoadingOverlay.vue";
import { useDocumentStore } from "@/stores/document";
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { CONFIG_ENDPOINT } from "../../../constants/Endpoints";

export default {
  name: "ViewScopingFieldView",
  components: {
    DocumentsList,
    UploadScopingFieldViewDocument,
    LoadingOverlay,
    DialogComponent,
  },
  emits: ["uploadFilesCall"],
  props: {
    saveClicked: { type: Boolean, default: null },
  },
  setup(props) {
    const ralroadEstimatesDocumentsDialogRef = ref(null);
    const route = useRoute();
    const documentStore = useDocumentStore();
    const uploadedFiles = ref([]);
    const updateDocumentList = ref(false);
    const inventorylist = ref([]);
    const loading = ref(false);
    const id = ref(route.params.id);
    let UpdateModalOpened = ref(false);
    onMounted(async () => {
      await fetchData();
    });
    async function fetchData() {
      updateDocumentList.value = true;
    }
    const saveClicked = computed({
      get: () => {
        return props?.saveClicked;
      },
    });

    const uploadFiles = async (files) => {
      loading.value = true;
      document.getElementById("list-section").scrollIntoView();
      let id = route.params.id;
      let docType = "Scoping Field View Documents";
      let endpoint = CONFIG_ENDPOINT.UPLOAD_SCOPING_DOCUMENTS;
      await documentStore.uploadDocuments(id, files, docType, endpoint);
      loading.value = false;
      fetchData();
    };

    const uploadedFilesEvent = (files) => {
      console.log("uploadedFiles.value = files", files);
      uploadedFiles.value = files;
    };
    const updateDocumentListFlag = () => {
      updateDocumentList.value = false;
    };
    const updateButtonClicked = (event) => {
      openDialog(event);
    };
    const openDialog = (event) => {
      UpdateModalOpened.value = true;
      ralroadEstimatesDocumentsDialogRef.value
        .open("Update Documents", id, event)
        .then((resolve) => {
          if (resolve) {
            fetchData();
            UpdateModalOpened.value = false;
          }
        })
        .catch(() => {
          UpdateModalOpened.value = false;
        });
    };
    return {
      inventorylist,
      loading,
      fetchData,
      uploadFiles,
      uploadedFilesEvent,
      saveClicked,
      updateDocumentListFlag,
      updateDocumentList,
      id,
      UpdateModalOpened,
      updateButtonClicked,
      ralroadEstimatesDocumentsDialogRef,
    };
  },
};
