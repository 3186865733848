import { requiredRule } from "@/composables/validationRules";import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import AddUserToProjectDialog from "@/modules/manageproject/views/AddUserToProjectDialog.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import VueTable from "@/modules/shared/components/VueTable.vue";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import { getCurrentInstance, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
export default {
  components: { VueTable, PrimaryButton, AddUserToProjectDialog, requiredRule },
  name: "ProjectFollowers",
  setup(props, { emit }) {
    const route = useRoute();
    const id = route.params.id || props.id;
    const projectFollowers = ref([]);
    const errorMessage = ref("");
    const columnData = ref([
      { name: "User Name", key: "userName", columnWidth: "5", alignLeft: true },
      { name: "User Email", key: "email", columnWidth: "5" },
      { name: "User Role(s)", key: "userRoles", columnWidth: "5" },
      { name: "Organization", key: "organization", columnWidth: "5" },
    ]);
    const isUserConditionalRailroadCertificationAccess = ref({
      read: false,
    });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const railroadAccessCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.PROJECT_FOLLOWERS
      );

      isUserConditionalRailroadCertificationAccess.value.read =
        readResponse || false;
    };
    onMounted(async () => {
      await railroadAccessCheck();
      if (isUserConditionalRailroadCertificationAccess.value.read) {
        getProjectFollowers();
      }
    });

    const getProjectFollowers = async () => {
      try {
        const response = await axios.get(
          CONFIG_ENDPOINT.GET_PROJECT_FOLLOWERS + id
        );

        projectFollowers.value = response.data;
      } catch (error) {
        console.log(error);
      }
    };

    const { proxy } = getCurrentInstance();
    const openAddUserDialog = () => {
      const addUserDialog = proxy?.$refs?.addUserDialog;
      if (addUserDialog?.open) {
        addUserDialog.open().then((response) => {
          emit("BannerUpdate", {
            bannerType: "success",
            bannerMessage: response?.data?.data || "Saved Successfully.",
            showBanner: true,
          });
          window.scrollTo(0, 0);
          getProjectFollowers();
        });
      } else {
        console.error("AddUserToProjectDialog component not found.");
      }
    };
    return {
      columnData,
      errorMessage,
      id,
      projectFollowers,
      openAddUserDialog,
      isUserConditionalRailroadCertificationAccess,
      requiredRule,
    };
  },
};
