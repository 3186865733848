import axios from "axios";
import { defineStore } from "pinia";
import { ref } from "vue";
import { CONFIG_ENDPOINT } from "../constants/Endpoints";
export const useDocumentStore = defineStore("document", () => {
  let files = ref({});
  let headerName = ref("Test");
  let isUploadErrorDialogVisible = ref(false);
  let uploadErrorMessage = ref("");
  const resetUpdateErrorMessage = () => {
    uploadErrorMessage.value = "";
    isUploadErrorDialogVisible.value = false;
  };
  const downloadFile = async (docId, id, endpoint) => {
    let temp = "";
    if (isNaN(id - parseFloat(id))) {
      temp = "?dotNumber=";
    } else {
      temp = "?mpmsNumber=";
    }

    axios
      .get(endpoint + docId + temp + id + "&repository=HWY", {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log("Content-Type", "multipart/form-data", res);
        let file = res.data.data;
        if (file.filename && file.content) {
          const fileTypeMapping = {
            ".pdf": "application/pdf",
            ".csv": "text/csv",
            ".xlsx":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ".png": "image/png",
            ".jpg": "image/jpg",
            ".txt": "text/plain",
            ".PNG": "image/PNG",
            ".JPG": "image/JPG",
          };

          let fileExtension = file.filename.slice(
            file.filename.lastIndexOf(".")
          );
          let contentType = fileTypeMapping[fileExtension];

          if (contentType) {
            let a = document.createElement("a");
            a.href = `data:${contentType};base64,${file.content}`;
            a.download = file.filename;
            a.click();
          }
        }
      });
  };

  const uploadDocuments = async (
    id,
    files,
    docType,
    endpoint,
    isDynamicDocumentType = false,
    showPreviewRailroadColumn = false,
    showPreviewDOTColumn = false,
    isUpdate = false,
    updatePayload = {},
    railroadCompanyId = null,
    isRailroadConsultantAgreementId = false
  ) => {
    try {
      const promises = files.value.map(async (file) => {
        let formData = new FormData();
        let json = {};

        if (isUpdate) {
          json = { ...updatePayload, description: `${file.payloadData.notes}` };
          if (isRailroadConsultantAgreementId) {
            json = { ...json, railroadConsultantAgreementId: id };
            delete json?.mpmsNumber;
            delete json?.dotNumber;
            delete json?.railRoadName;
          }
        } else if (!(await isNumeric(id))) {
          json = {
            ...json,
            docType: isDynamicDocumentType
              ? `${file.file.docType}`
              : `${docType}`,
            filename: `${file.file.name}`,
            dotNumber: id,
            description: `${file.payloadData.notes}`,
            documentUploadDate: `${file.payloadData.documentUploadDate}`,
          };
        } else {
          json = {
            ...json,
            docType: isDynamicDocumentType
              ? `${file.file.docType}`
              : `${docType}`,
            filename: `${file.file.name}`,
            mpmsNumber: id,
            description: `${file.payloadData.notes}`,
            documentUploadDate: `${file.payloadData.documentUploadDate}`,
          };
        }
        if (showPreviewRailroadColumn && file.file.railroad) {
          json = { ...json, railRoadName: file.file.railroad };
        }
        if (showPreviewDOTColumn && file.file.dot) {
          json = { ...json, dotNumber: file.file.dot };
        }
        if (railroadCompanyId) {
          json = { ...json, railroadCompanyId: railroadCompanyId };
        }
        if (isRailroadConsultantAgreementId) {
          json = { ...json, railroadConsultantAgreementId: id };
          delete json.mpmsNumber;
        }
        formData.append("file", file.file);
        formData.append("json", JSON.stringify(json));

        return axios.post(endpoint, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      });
      const response = await Promise.all(promises);
      return response;
    } catch (error) {
      console.log(
        "uploadDocuments error",
        error.response,
        error.response.data.businessMessage
      );
      //Test condition
      if (
        error.response.status === 500 &&
        error.response.data.businessMessage
      ) {
        isUploadErrorDialogVisible.value = true;
        uploadErrorMessage.value =
          error.response.data.businessMessage ||
          "We are experiencing issues with the PennDOT document management system. Sorry for the inconvenience. Please Check back later";
      }
    }
  };

  const getRailroadWorkSummaryDocuments = async (
    id,
    doctype,
    sortDetail,
    railroadCompanyId,
    Filter
  ) => {
    let SortData = "";

    if (sortDetail) {
      SortData = "?sort=" + sortDetail.key + "&direction=" + sortDetail.order;
    }
    if (id && railroadCompanyId) {
      try {
        const response = await axios.post(
          CONFIG_ENDPOINT.GET_SCOPINGFIELD_VIEW +
            id +
            doctype +
            SortData +
            "&railroadCompanyId=" +
            railroadCompanyId,
          Filter || null
        );
        return response;
      } catch (error) {
        return null;
      }
    }
  };

  const getDocuments = async (
    id,
    doctype,
    sortDetail,
    Filter,
    isRailroadConsultantAgreement
  ) => {
    let SortData = "";
    if (sortDetail) {
      SortData = "?sort=" + sortDetail.key + "&direction=" + sortDetail.order;
    }
    if (isRailroadConsultantAgreement) {
      try {
        const response = await axios.post(
          CONFIG_ENDPOINT.CONSULTANT_AGREEMENT_GET_DOCUMENT_LIST,
          {
            railroadConsultantAgreementId: id,
          }
        );
        return response;
      } catch (error) {
        return null;
      }
    } else if (id && !(await isNumeric(id))) {
      try {
        const response = await axios.post(
          CONFIG_ENDPOINT.GET_DOT_DOCS_VIEW + id + doctype + SortData,
          Filter || null
        );
        return response;
      } catch (error) {
        return null;
      }
    } else if (id) {
      try {
        const response = await axios.post(
          CONFIG_ENDPOINT.GET_SCOPINGFIELD_VIEW + id + doctype + SortData,
          Filter || null
        );
        return response;
      } catch (error) {
        return null;
      }
    }
    return null;
  };

  const getDocumentsWithFilter = async (id, doctype, sortDetail, Filter) => {
    let SortData = "";
    if (sortDetail) {
      SortData = "?sort=" + sortDetail.key + "&direction=" + sortDetail.order;
    }
    try {
      const response = await axios.post(
        CONFIG_ENDPOINT.GET_DOCUMENTS_FILTERED_API + id + doctype + SortData,
        Filter || null
      );
      return response;
    } catch (error) {
      return null;
    }
  };

  const getExternalDocumentLists = async (
    id,
    doctype,
    railroadID,
    sortDetail
  ) => {
    let SortData = "";

    let docSearchData = {
      mpmsNumber: id,
      railroadID: railroadID,
      docType: doctype,
    };
    if (sortDetail) {
      SortData = "?sort=" + sortDetail.key + "&direction=" + sortDetail.order;
    }
    try {
      const response = await axios.post(
        CONFIG_ENDPOINT.EXTERNAL_DOC_LIST + SortData,
        docSearchData
      );
      console.log("headerName response", response, response?.data);
      headerName.value = response?.data?.[0]?.headerMessage;
      return response;
    } catch (error) {
      return null;
    }
  };
  const isNumeric = (s) => {
    return !isNaN(s - parseFloat(s));
  };
  return {
    files,
    headerName,
    downloadFile,
    uploadDocuments,
    getDocuments,
    getDocumentsWithFilter,
    getExternalDocumentLists,
    isNumeric,
    getRailroadWorkSummaryDocuments,
    resetUpdateErrorMessage,
    isUploadErrorDialogVisible,
    uploadErrorMessage,
  };
});
