<template>
  <v-dialog
    persistent
    @afterLeave="afterModalLeave"
    v-model="dialog"
    max-width="90vw"
    max-height="3000px"
  >
    <div class="dialogheading">
      <div class="w-100 d-flex justify-content-between">
        <div class="w-90 text-center f-size24" id="list-section">
          {{ title }}
        </div>
        <div>
          <v-icon @click="closeDialog" class="cursor-pointer" small
            >mdi-close
          </v-icon>
        </div>
      </div>
      <v-card>
        <v-card-text style="max-height: 80vh; overflow-y: scroll">
          <v-row>
            <v-col cols="12">
              <EstimatedProjectFundingInfo
                :id="id"
              ></EstimatedProjectFundingInfo>
            </v-col>
            <v-col cols="12">
              <ProgramFundingInfo :id="id"></ProgramFundingInfo>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <div class="action">
            <PrimaryButton
              @buttonClicked="closeDialog"
              :buttonText="'Close'"
            ></PrimaryButton>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>
<script>
import ViewFundingDialog from "@/modules/manageproject/scripts/ViewFundingDialog.js";
export default ViewFundingDialog;
</script>
<style>
@import "@/modules/projectinformation/assets/projectinfostyle.scss";
@import "@/assets/css/style.scss";
</style>
