<template>
  <v-dialog
    persistent
    @afterLeave="afterModalLeave"
    v-model="dialog"
    max-width="800px"
    max-height="3000px"
  >
    <div class="dialogheading">
      <div class="w-100 d-flex justify-content-between">
        <div class="w-90 text-center f-size24">{{ title }}</div>
        <div>
          <v-icon @click="closeDialog" class="cursor-pointer" small
            >mdi-close
          </v-icon>
        </div>
      </div>
      <v-card>
        <v-card-text>
          <v-form v-model="isFormValid" class="pa1Form">
            <v-row>
              <v-col class="dialogLabeltext">
                <label>Serial #: </label>
                <label class="asterisk">*</label>

                <v-text-field
                  ref="seialNumber"
                  @blur="validateSerialNumber()"
                  v-if="!editForm"
                  v-model="formData.pa1CallSerialNum"
                  :maxlength="15"
                  @keydown="keydownAllowNumberOnly($event)"
                  @keyup="serialNumberTouched ? validateSerialNumber() : ''"
                  outlined
                >
                </v-text-field>
                <div
                  v-if="serialInvalidMsg && !editForm"
                  style="border-top: 1px solid #b00020"
                >
                  <div
                    class="error2"
                    style="padding-inline-start: 16px; padding-inline-end: 16px"
                  >
                    <div>{{ serialInvalidMsg }}</div>
                  </div>
                </div>
                <br />
                <label v-if="editForm" :style="{ 'font-weight': 'normal' }">
                  {{ formData.pa1CallSerialNum }}</label
                >
              </v-col>

              <v-col class="dialogLabeltext">
                <label>Date: </label>
                <label class="asterisk">*</label>
                <DatePicker
                  :value="formattedDate"
                  :invalidMsg="'PA1Call Date'"
                  :rules="['requiredRule', 'validDateFutureRule']"
                  @dateValidation="checkDateValidation($event)"
                  @input="dateChange($event)"
                ></DatePicker>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6" class="dialogLabeltext">
                <label>County#: </label>
                <label class="asterisk">*</label>

                <v-select
                  v-model="formData.countyCode"
                  :items="countyList"
                  outlined
                  :rules="[requiredRule]"
                  item-title="countyName"
                  item-value="countyId"
                  @update:modelValue="retrieveMunicipalityList($event)"
                ></v-select>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="6" class="dialogLabeltext">
                <label>Municipality: </label>
                <label class="asterisk">*</label>

                <v-select
                  v-model="formData.muniCode"
                  :items="municipalityList"
                  outlined
                  item-title="municipalityName"
                  item-value="municipalityCode"
                  :rules="[requiredRule]"
                  :disabled="!formData.countyCode"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6" class="dialogLabeltext">
                <label>Project Phase: </label>
                <label class="asterisk">*</label>

                <v-select
                  v-model="formData.projectPhase"
                  :items="projectPhaseList"
                  outlined
                  :rules="[requiredRule]"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <div class="action">
            <PrimaryButton
              @buttonClicked="PostOrPutData"
              :disabled="
                !checkFormValidation ||
                serialInvalidMsg ||
                !formData.pa1CallSerialNum
              "
              :buttonText="'Save'"
            ></PrimaryButton>
            <SecondaryButton
              @buttonClicked="closeDialog"
              :buttonText="'Cancel'"
              class="ml-2"
            ></SecondaryButton>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>
<script>
import PA1CallDialog from "@/modules/projectinformation/scripts/PA1CallDialog.js";
export default PA1CallDialog;
</script>
<style>
@import "@/modules/projectinformation/assets/projectinfostyle.scss";
@import "@/assets/css/style.scss";
</style>
