import {
  UserManager,
  WebStorageStateStore,
  InMemoryWebStorage,
} from "oidc-client-ts";
import { useUserStore } from "@/stores/userStore";

const storage = new InMemoryWebStorage();
const userManager = new UserManager({
  authority: "/affwebservices/CASSO/oidc/GCMSWeb",
  client_id: import.meta.env.VITE_APP_CLIENT_ID,
  client_secret: import.meta.env.VITE_APP_CLIENT_SECRET,
  redirect_uri: import.meta.env.VITE_APP_REDIRECT_URI,
  response_type: "code",
  scope: "openid profile",
  automaticSilentRenew: true,
  client_authentication: "client_secret_post",
  post_logout_redirect_uri: "/",
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  stateStore: new WebStorageStateStore({
    store: window.localStorage,
  }),
  revokeTokensOnSignout: true,
  metadata: {
    issuer: "affwebservices/CASSO/oidc/GCMSWeb",
    authorization_endpoint: import.meta.env.VITE_APP_AUTHORIZATION_ENDPOINT,
    token_endpoint: "/affwebservices/CASSO/oidc/GCMSWeb/token",
    jwks_uri: "/affwebservices/CASSO/oidc/GCMSWeb/jwks",
    userinfo_endpoint: "/affwebservices/CASSO/oidc/GCMSWeb/userinfo",
    introspection_endpoint: "/affwebservices/CASSO/oidc/GCMSWeb/introspectt",
    revocation_endpoint: "/affwebservices/CASSO/oidc/GCMSWeb/revoke",
    end_session_endpoint: import.meta.env.VITE_APP_END_SESSION_ENDPOINT,
  },
});

//handle token silent renew error happens when there is no network(offline)
userManager.events.addSilentRenewError((e) => {}); //-- This is for offline specific to BMS

export default {
  login() {
    const originalUrl = window.location.href || "";

    if (
      !originalUrl.includes("affwebservices") &&
      !originalUrl.includes("code")
    ) {
      localStorage.setItem("originalUrl", originalUrl);
    }
    return userManager.signinRedirect();
  },

  logout() {
    const userStore = useUserStore();
    userStore.setUser(null);
    userStore.setIsAuthenticated(false);
    localStorage.removeItem("hashedPass");
    userManager.signoutRedirect();
    localStorage.clear();
    sessionStorage.clear();
    localStorage.removeItem('authToken');
    this.$store.commit('clearUserData');
  },

  async getUser() {
    return await userManager.getUser();
  },

  async setToken() {
    const userStore = useUserStore();
    try {
      const user = await userManager.signinRedirectCallback();
      userStore.setUser(user?.profile);
      userStore.setIsAuthenticated(true);
    } catch (e) {}
  },

  async silentLogin() {
    try {
      await userManager.signinSilent();
    } catch (err) {
      this.login();
    }
  },
};
