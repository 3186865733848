<template>
  <v-card class="p-64 v-card-text">
    <OnlineHelpPOC :helpKey="'DASH_HELP'"></OnlineHelpPOC>
    <div class="allProjectsHeading">Manage Consulting Companies</div>

    <v-card class="p-64">
      <FeatureTable
        :headers="headers"
        :items="activeProjects"
        :showExportButton="true"
        :exportFilName="'Manage Consulting Companies'"
        :showClearFilterButton="true"
        :clickable="true"
        :navigateCompanyName="true"
        :tableHeader="''"
        :itemsPerPage="paginationData?.items"
        :linkedPagination="true"
        :totalItems="paginationData?.total"
        :lookupData="lookupData"
        :oldMultipleFilter="multipleFilter"
        :isDynamicExport="true"
        @updatePaginationPerPage="updatePaginationPerPage"
        @pageChange="pageChange"
        @sortUpdate="sortUpdate"
        @textFilterChange="textFilterChange"
        @multipleFiltersChange="multipleFiltersChange"
        @clearAllFilter="clearAllFilter"
        @exportButtonClicked="exportButtonClicked"
        @linkClicked="linkClicked($event)"
      >
      </FeatureTable>
    </v-card>
  </v-card>
</template>

<script>
import ManageConsultingCompanies from "@/modules/gcmsmain/admin/submenu/scripts/ManageConsultingCompanies.js";
export default ManageConsultingCompanies;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
</style>
