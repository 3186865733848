<template>
  <div>
    <div class="page-title">
      <h1>Design & Construction Milestones</h1>
    </div>
    <v-container
      ><v-row>
        <v-col cols="2">
          <v-row>
            <v-col cols="8" class="text-left"
              ><b>State Project#:</b>
              <tr v-for="item in stateProjects" :key="item.stateProjects">
                <td class="header-data d-flex flex-column text-right">
                  {{ item }}
                </td>
              </tr>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-row>
            <v-col cols="8" class="text-left"
              ><b>Federal Project#: </b>
              <tr v-for="item in fedProjects" :key="item.fedProjects">
                <td class="header-data d-flex flex-column text-right">
                  {{ item }}
                </td>
              </tr>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-row>
            <v-col cols="8" class="text-right"
              ><b>Final Railroad Clearance Date:</b></v-col
            >
            <v-col cols="4" class="text-left header-data">--</v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <v-row>
            <v-col cols="8" class="text-right"
              ><b>Railroad Clearance Certification Date:</b></v-col
            >
            <v-col cols="4" class="text-left header-data">--</v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-table class="design-table">
      <thead>
        <tr>
          <th scope="col">Milestone</th>
          <th scope="col">PE</th>
          <th scope="col">FD</th>
          <th scope="col">Row</th>
          <th scope="col">Con</th>
        </tr>
      </thead>
      <tbody>
        <tr class="sub-header">
          <td colspan="6"><b>Design</b></td>
        </tr>
        <tr v-for="item in design" :key="item.milestone">
          <td class="milestone-column">{{ item?.milestone }}</td>
          <td>{{ item?.pe }}</td>
          <td>{{ item?.fd }}</td>
          <td>{{ item?.row }}</td>
          <td>{{ item?.con }}</td>
        </tr>
        <tr class="sub-header">
          <td colspan="6"><b>ROW & Utilities</b></td>
        </tr>
        <tr v-for="item in rowAndUtilities" :key="item.milestone">
          <td class="milestone-column">{{ item?.milestone }}</td>
          <td>{{ item?.pe }}</td>
          <td>{{ item?.fd }}</td>
          <td>{{ item?.row }}</td>
          <td>{{ item?.con }}</td>
        </tr>
        <tr class="sub-header">
          <td colspan="6"><b>Let Date</b></td>
        </tr>
        <tr v-for="item in letDate" :key="item.milestone">
          <td class="milestone-column">{{ item?.milestone }}</td>
          <td>{{ item?.pe }}</td>
          <td>{{ item?.fd }}</td>
          <td>{{ item?.row }}</td>
          <td>{{ item?.con }}</td>
        </tr>
        <tr class="sub-header">
          <td colspan="6"><b>Construction</b></td>
        </tr>
        <tr v-for="item in construction" :key="item.milestone">
          <td class="milestone-column">{{ item?.milestone }}</td>
          <td>{{ item?.pe }}</td>
          <td>{{ item?.fd }}</td>
          <td>{{ item?.row }}</td>
          <td>{{ item?.con }}</td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>
<script>
import DesignAndConstruction from "@/modules/designandconstruction/scripts/DesignAndConstruction.js";
export default DesignAndConstruction;
</script>
<style>
@import "@/modules/designandconstruction/assets/DesignAndConstruction.scss";
@import "@/assets/css/style.scss";
</style>
