import { AUTO_COMPLETE_NO_DATA } from "@/constants/displayTexts";import { computed, ref } from "vue";
export default {
  components: {},
  props: ["items", "label", "placeholder", "value"],
  setup(props, context) {
    const selectedItems = ref([]);
    const items = computed({
      get: () => {
        return props?.items;
      },
    });
    const label = computed({
      get: () => {
        return props?.label;
      },
    });
    const placeholder = computed({
      get: () => {
        return props?.placeholder;
      },
    });
    const value = computed({
      get: () => {
        if (props?.value) {
          selectedItems.value = props?.value;
        }
        return props?.value;
      },
    });
    const handleInput = (value) => {
      context.emit("input", value);
    };
    return {
      selectedItems,
      items,
      label,
      placeholder,
      value,
      handleInput,
      AUTO_COMPLETE_NO_DATA,
    };
  },
};
