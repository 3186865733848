import useFieldEntry from "@/composables/fieldEntry";import { requiredRule } from "@/composables/validationRules";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import {
  DROPDOWN_NO_DATA,
  TABLE_NO_DATA_AVAILABLE,
} from "@/constants/displayTexts";
import IconButton from "@/modules/shared/components/IconButton.vue";
import axios from "axios";
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "DataTable",
  props: {
    pageNumber: { default: 1, type: Number },
    headers: { type: Array, default: () => [] },
    items: { type: Array, default: () => [] },
    showClearFilterButton: { type: Boolean, default: false },
    showExportButton: { type: Boolean, default: false },
    tableHeader: { type: String, default: "" },
    routerText: { type: String, default: "" },
    hideFilter: { type: Boolean, default: false },
    hidePagination: { type: Boolean, default: false },
    linkedPagination: { type: Boolean, default: false },
    itemsPerPage: { type: Number, default: 10 },
    totalItems: { type: Number, default: 10 },
    clickable: { type: Boolean, default: false },
    isGradeInventory: { type: Boolean, default: false },
    isCreateProject: { type: Boolean, default: false },
    isGradeCrossing: { type: Boolean, default: false },
    navigateToMpmsDetails: { type: Boolean, default: false },
    navigationKey: { type: String, default: "" },
    noDataText: { type: String, default: TABLE_NO_DATA_AVAILABLE },
    shouldInitialSort: { type: Boolean, default: false },
  },
  watch: {
    totalItems(value) {
      this.updateTotalItems(value);
    },
    items(list) {
      this.updateItems(list);
    },
    pageNumber(newPage) {
      if (this.linkedPagination) {
        this.pageChangeLinked(newPage);
      } else {
        this.pageChange(newPage);
      }
    },
    itemsPerPage() {
      this.itemPerPageChange();
    },
  },
  components: { IconButton },
  setup(props, context) {
    onMounted(() => {
      if (props?.shouldInitialSort && !sortBy.value.length) {
        sortBy.value = [{ key: "dateIssued", order: "desc" }];
      }
      props.headers?.forEach((header) => {
        if (header.filter && header?.initialFilter) {
          updateFilters(
            header.filter,
            header.key,
            header.filterType,
            header?.filterWith
          );
        }
        if (header?.initialSort) {
          sortBy.value = [{ key: header.key, order: header.sortOrder }];
        }
      });
    });
    const { keydownAllowNumberOnly } = useFieldEntry();
    const router = useRouter();
    let items = ref(props?.items || []);
    let itemsPerPage = ref(props.itemsPerPage);
    let totalItems = ref(props.totalItems || 0);
    let pageNumber = ref(1);
    let jumpPage = ref(1);
    let sortBy = ref([]);
    let page = ref(1);
    let multiSearch = ref("");
    let search = ref("");
    let filteredHeaders = ref([]);
    let headerprops = ref({
      "sort-icon": "mdi-unfold-more-horizontal",
    });
    let selectedItem = ref(false);
    let viewAllProjectRoute = ref(CONFIG_ENDPOINT.GCMS_VIEW_ALL_PROJECTS);
    let linkedPagination = ref(props?.linkedPagination || false);
    let noDataDisplayText = computed(() => props?.noDataText);
    let focusTextKey = ref(null);

    let tableData = computed(() => {
      const start = (pageNumber.value - 1) * itemsPerPage.value;
      const end = start + itemsPerPage.value;
      const paginated = filteredData.value.slice(start, end);
      return paginated;
    });

    let filteredData = computed(() => {
      let filteredArray = items.value;
      if (filteredHeaders.value?.length) {
        filteredHeaders.value?.forEach((header) => {
          filteredArray = filteredArray.filter((item) => {
            return checkFilter(header, item);
          });
        });
      }
      if (sortBy.value?.length) {
        filteredArray = sortFilteredArray(filteredArray);
      }
      return filteredArray;
    });

    function sortFilteredArray(filteredArray) {
      let sortType = "";
      let headerIndex = props.headers.findIndex(
        (header) => header.key === sortBy.value[0].key
      );
      if (headerIndex >= 0) {
        sortType = props.headers[headerIndex].sortType;
      }
      if (sortBy.value[0].order === "asc") {
        filteredArray = sortAscending(filteredArray, sortType);
      } else if (sortBy.value[0].order === "desc") {
        filteredArray = sortDescending(filteredArray, sortType);
      }
      return filteredArray;
    }

    function sortDescending(filteredArray, sortType) {
      if (sortType === "text") {
        filteredArray.sort((a, b) => {
          return b?.[sortBy.value[0]?.key]?.localeCompare(
            a?.[sortBy.value[0]?.key]
          );
        });
      } else if (sortType === "number") {
        filteredArray.sort((a, b) => {
          return b?.[sortBy.value[0]?.key] - a?.[sortBy.value[0]?.key];
        });
      } else if (sortType === "date") {
        filteredArray.sort((a, b) => {
          return (
            new Date(b?.[sortBy.value[0]?.key]).getTime() -
            new Date(a?.[sortBy.value[0]?.key]).getTime()
          );
        });
      }
      return filteredArray;
    }

    function sortAscending(filteredArray, sortType) {
      if (sortType === "text") {
        filteredArray.sort((a, b) => {
          return a?.[sortBy?.value[0]?.key]?.localeCompare(
            b?.[sortBy.value[0]?.key]
          );
        });
      } else if (sortType === "number") {
        filteredArray.sort((a, b) => {
          return a?.[sortBy.value[0]?.key] - b?.[sortBy.value[0]?.key];
        });
      } else if (sortType === "date") {
        filteredArray.sort((a, b) => {
          return (
            new Date(a?.[sortBy.value[0]?.key]).getTime() -
            new Date(b?.[sortBy.value[0]?.key]).getTime()
          );
        });
      }
      return filteredArray;
    }

    const filteredDataLength = computed(() => filteredData.value?.length);
    const getPaginationLength = computed(() =>
      Math.ceil(filteredDataLength.value / itemsPerPage.value)
    );

    const selectItem = (item) => {
      if (props.clickable.value) {
        selectedItem.value = item;
      }
    };

    const createRouter = (item) => {
      selectedItem.value = item;

      if (props?.navigateToMpmsDetails) {
        window.scrollTo(0, 0);
        router.push({
          path: `/GCMS/${selectedItem.value[props?.navigationKey]}`,
        });
      }
      if (props?.isGradeInventory) {
        window.scrollTo(0, 0);
        router.push({
          path: `/GCMS/DOT/${selectedItem.value.dotnumber}`,
        });
      }
      if (props?.isGradeCrossing) {
        window.scrollTo(0, 0);
        router.push({
          path: `/GCMS/${selectedItem.value.mpms}`,
        });
      }
      if (props?.isCreateProject) {
        axios
          .get(
            CONFIG_ENDPOINT.PROJECT_INFO_INFO +
              selectedItem.value.mpmsNumber +
              "?createGradeCrossing=true"
          )
          .then((response) => {
            if (response?.data?.mpmsNumber) {
              window.scrollTo(0, 0);
              router.push({
                path: `/GCMS/createProject/${selectedItem.value.mpmsNumber}`,
              });
            }
          });
      }
    };

    const clearAllFilters = () => {
      filteredHeaders.value = [];
      props.headers?.map((header) => (header.filter = null));
    };

    const exportList = () => {
      const col = [];
      const column = props.headers?.map((header) => header.key);
      props.headers?.forEach((header) =>
        col.push(getExportHeaderName(header.key))
      );
      let csvContent = col.join(",") + "\n";
      filteredData.value?.forEach((data) => {
        let array = [];
        column?.forEach((key) => {
          let value = data[key];
          value = value?.toString().replace(/"/g, '""');
          if (key?.toLowerCase()?.includes("district")) {
            value = '=""' + value + '""';
          }
          array.push(`"${value}"`);
        });
        csvContent += array.join(",") + "\n";
      });

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "Inventory History.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    };
    const getExportHeaderName = (header) => {
      let heading = "";
      switch (header) {
        case "projectClass":
          heading = "Project Class";
          break;
        case "noticeToProceedDate":
          heading = "Notice to Proceed Date";
          break;
        case "municipality":
          heading = "Municipality";
          break;
        case "pseDate":
          heading = "PS&E Date";
          break;
        case "projectIdentifier":
          heading = "Project Identifier";
          break;
        case "mpmsNumber":
          heading = "MPMS#";
          break;
        case "projectTitle":
          heading = "Project Title";
          break;
        case "stateRoute":
          heading = "SR";
          break;
        case "srSection":
          heading = "Section";
          break;
        case "district":
          heading = "District";
          break;
        case "county":
          heading = "County";
          break;
        case "status":
          heading = "Status";
          break;
        case "letDate":
          heading = "LetDate";
          break;
        case "rrCoordinator":
          heading = "RR Coordinator";
          break;
        default:
          heading = header;
      }
      return heading;
    };

    const textFieldFilter = (value, key, type, filterWith) => {
      focusTextKey.value = key;
      if (value.target.value) {
        filterValues([value.target.value], key, type, filterWith);
      } else {
        filteredHeaders.value = [];
      }
    };

    const checkFilter = (header, item) => {
      let is_available = false;
      if (
        header?.filterType === "multi-select" ||
        header?.filterType === "auto-complete"
      ) {
        is_available = checkMultiSelectFilter(header, item);
      } else if (header?.filterType === "textbox") {
        is_available = checkTextboxFilter(header, item);
      }
      return is_available ? item : null;
    };

    const checkMultiSelectFilter = (header, item) => {
      let is_available = false;
      if (header?.filterKey === "projectIdentifier") {
        header.filterValue?.forEach((filter) => {
          if (item[header?.filterKey]?.includes(filter)) {
            is_available = true;
          }
        });
      } else if (header?.filterWith === "yearMonth") {
        const formattedDate = formatToYearMonth(item[header?.filterKey]);
        is_available = header.filterValue.includes(formattedDate);
      } else {
        header.filterValue?.forEach((filter) => {
          if (item[header?.filterKey] == filter) {
            is_available = true;
          }
        });
      }
      return is_available;
    };

    const resetOldFilter = (key, index, isClear) => {
      if (checkIfOldFilterIsPresent(key) && !isClear) {
        let oldFilter = filteredHeaders.value?.length
          ? filteredHeaders.value?.filter((header) => {
              return header?.filterKey === key;
            })?.[0]?.filterValue
          : [];
        props.headers[index].filter = oldFilter || [];
      }
      if (isClear) {
        props.headers[index].filter = [];
        filterValues([], key);
        /*  updateMultipleFilter(key); */
      }
    };

    const checkTextboxFilter = (header, item) => {
      let is_available = false;
      if (header?.filterWith == "startWith") {
        header.filterValue?.forEach((filter) => {
          let paramsValue = item[header?.filterKey].toString().toLowerCase();
          if (paramsValue.startsWith(filter.toString().toLowerCase())) {
            is_available = true;
          }
        });
      } else if (header?.filterWith === "includes") {
        header.filterValue?.forEach((filter) => {
          let paramsValue = item[header?.filterKey].toString().toLowerCase();
          if (paramsValue.includes(filter.toString().toLowerCase())) {
            is_available = true;
          }
        });
      }
      return is_available;
    };

    const getItems = (key, sortType) => {
      if (key === "projectIdentifier") {
        let unique = getProjectIdentifierList(key, sortType);
        return unique;
      }
      let unique = [
        ...new Set(
          items.value?.map((item) => {
            return item[key];
          })
        ),
      ];
      if (key === "letDate" || key?.toLowerCase()?.includes("date")) {
        getDateBasedFilterItems(key);
      }
      if (key?.toLowerCase()?.includes("district")) {
        unique = sortFilterItems(unique, "district");
      } else {
        unique = sortFilterItems(unique, sortType);
      }
      unique = unique.map((item) => {
        return { label: item || "(Blank)", value: item };
      });
      return unique;
    };
    const getDateBasedFilterItems = (key) => {
      let data = items.value
        ?.sort((a, b) => {
          if (new Date(a?.[key]).getTime() < new Date(b?.[key]).getTime())
            return -1;
          if (new Date(a?.[key]).getTime() > new Date(b?.[key]).getTime())
            return 1;
          return 0;
        })
        ?.map((item) => formatToYearMonth(item[key]))
        .filter((value, index, self) => self.indexOf(value) === index);
      data = data.map((item) => {
        return {
          label:
            item && new Date(item) != "Invalid Date"
              ? formatToYearMonth(item)
              : "(Blank)",
          value: item,
        };
      });
      return data;
    };
    const getProjectIdentifierList = (key, sortType) => {
      let identifierList = [];
      items.value?.forEach((item) => {
        item[key]?.forEach((identifier) => {
          identifierList.push(identifier);
        });
      });
      let unique = [...new Set(identifierList?.map((item) => item))];
      unique = sortFilterItems(unique, sortType);
      unique = unique.map((item) => {
        return { label: item || "(Blank)", value: item };
      });
      return unique;
    };
    const sortFilterItems = (list, type) => {
      if (type == "number") {
        return sortNumber(list);
      } else if (type == "date") {
        return sortDate(list);
      } else if (type === "district") {
        return sortDistrict(list);
      } else {
        return sort(list);
      }
    };

    const sort = (list) => {
      return list?.sort((a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      });
    };

    const sortDate = (list) => {
      return list?.sort((a, b) => {
        if (new Date(a).getTime() < new Date(b).getTime()) return -1;
        if (new Date(a).getTime() > new Date(b).getTime()) return 1;
        return 0;
      });
    };

    const sortNumber = (list) => {
      return list?.sort((a, b) => {
        if (Number(a) < Number(b)) return -1;
        if (Number(a) > Number(b)) return 1;
        return 0;
      });
    };

    const sortDistrict = (list) => {
      return list?.sort((first, second) => {
        let a = first?.split("-")?.[0];
        let b = second?.split("-")?.[0];
        if (Number(a) < Number(b)) return -1;
        if (Number(a) > Number(b)) return 1;
        return 0;
      });
    };

    const filterValues = (selectObjt, key, type, filterWith) => {
      focusTextKey.value = key;
      if (selectObjt.length) {
        if (filteredHeaders.value?.length) {
          props.headers?.map((header) => {
            if (header.key == key) {
              header.filter = selectObjt;
            }
          });
        }
        if (key === "letDate" || key?.toLowerCase()?.includes("date")) {
          filteredData.value = items.value.filter((item) => {
            const formattedDate = formatToYearMonth(item[key]);
            return selectObjt.includes(formattedDate);
          });
        }
        updateFilters(selectObjt, key, type, filterWith);
      } else if (filteredHeaders.value?.length) {
        let index = filteredHeaders.value.findIndex(
          (filter) => filter.filterKey === key
        );
        if (index >= 0 && !selectObjt.length) {
          filteredHeaders.value.splice(index, 1);
        }
      } else {
        filteredHeaders.value = [];
      }
    };

    const updateFilters = (selectObjt, key, type, filterWith) => {
      let index = filteredHeaders.value.findIndex(
        (filter) => filter.filterKey === key
      );
      if (index >= 0) {
        filteredHeaders.value[index] = {
          filterValue: selectObjt,
          filterKey: key,
          filterType: type,
          filterWith: filterWith,
        };
      } else {
        filteredHeaders.value.push({
          filterValue: selectObjt,
          filterKey: key,
          filterType: type,
          filterWith: filterWith,
        });
      }
    };

    const formatToYearMonth = (dateString) => {
      if (dateString) {
        let date = new Date(dateString);
        let month = date.toLocaleString("default", { month: "long" });
        let year = date.getFullYear();
        return `${year}-${month}`;
      }
      return dateString;
    };

    const linkClicked = (key, value) => {
      context.emit("linkClicked", { key: key, value: value });
    };

    function jumpToPage(event) {
      if (
        event.target.value &&
        !isNaN(event.target.value) &&
        event.target.value > 0 &&
        event.target.value <= getPaginationLength.value
      ) {
        pageNumber.value = event.target.value;
      }
    }

    const pageChange = (event) => {
      jumpPage.value = event;
    };

    const itemPerPageChange = () => {
      jumpPage.value = 1;
      pageNumber.value = 1;
    };

    const getPaginationLengthLinked = computed(() => {
      return Math.ceil(totalItems.value / itemsPerPage.value);
    });
    const updateItems = (list) => {
      items.value = list;
    };

    const pageChangeLinked = (event) => {
      jumpPage.value = event;
      context.emit("pageChange", event);
    };
    const updatePaginationPerPage = (event) => {
      if (event) {
        itemsPerPage.value = event;
        context.emit("updatePaginationPerPage", event);
      }
    };

    const jumpToPageLinkedPagination = (event) => {
      let pageValue = event;
      if (
        pageValue &&
        pageValue <= Math.ceil(totalItems.value / itemsPerPage.value)
      ) {
        pageNumber.value = pageValue;
        context.emit("pageChange", pageValue);
      }
    };
    const updateTotalItems = (value) => {
      totalItems.value = value;
    };
    const checkFieldDisabled = (key, type, sort) => {
      if (linkedPagination.value) {
        if (!filteredData.value.length || type === "textbox") {
          return false;
        } else if (type === "multi-select" || type === "auto-complete") {
          return !getItems(key, sort)?.length;
        }
      } else if (!linkedPagination.value) {
        if (!tableData.value.length || type === "textbox") {
          return false;
        } else if (type === "multi-select" || type === "auto-complete") {
          return !getItems(key, sort)?.length;
        }
      }
    };
    const getPagesList = () => {
      return Array.from(
        { length: getPaginationLengthLinked.value },
        (_, i) => i + 1
      );
    };
    const checkIfOldFilterIsPresent = (key) => {
      console.log("filteredHeaders", filteredHeaders.value);
      let values =
        filteredHeaders.value?.filter((header) => {
          return header?.filterKey === key;
        })?.[0]?.filterValue || [];
      return values.length;
    };
    const resetFocusTextKey = () => {
      focusTextKey.value = null;
    };

    return {
      getPagesList,
      checkFieldDisabled,
      sortBy,
      page,
      multiSearch,
      search,
      filteredHeaders,
      headerprops,
      selectedItem,
      filteredData,
      selectItem,
      linkClicked,
      formatToYearMonth,
      filterValues,
      getItems,
      createRouter,
      clearAllFilters,
      exportList,
      textFieldFilter,
      checkFilter,
      checkMultiSelectFilter,
      checkTextboxFilter,
      filteredDataLength,
      viewAllProjectRoute,
      useRouter,
      router,
      pageNumber,
      getPaginationLength,
      tableData,
      itemsPerPage,
      jumpToPage,
      keydownAllowNumberOnly,
      requiredRule,
      jumpPage,
      pageChange,
      itemPerPageChange,
      updatePaginationPerPage,
      jumpToPageLinkedPagination,
      pageChangeLinked,
      getPaginationLengthLinked,
      linkedPagination,
      updateItems,
      updateTotalItems,
      DROPDOWN_NO_DATA,
      TABLE_NO_DATA_AVAILABLE,
      noDataDisplayText,
      focusTextKey,
      resetOldFilter,
      checkIfOldFilterIsPresent,
      resetFocusTextKey,
    };
  },
};
