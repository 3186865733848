<template>
  <div class="expanderOverLay">
    <ViewAllProjects></ViewAllProjects>
  </div>
</template>
<script>
import ViewAllProjects from "@/modules/viewallprojects/components/ViewAllProjects.vue";
export default {
  name: "ViewAllProjects-page",
  components: {
    ViewAllProjects: ViewAllProjects,
  },
};
</script>
