<template>
  <v-text-field
    v-if="isRequired"
    v-model="computedValue"
    type="text"
    :maxlength="300"
    :minlength="6"
    :validate-on="'input'"
    :rules="getEmailFieldRule ? [requiredRule, validateEmail] : []"
    @update:modelValue="
      fieldValueChange();
      debounce(() => {
        console.log('email');
        setFieldRule();
      });
    "
  >
  </v-text-field>
  <v-text-field
    v-if="!isRequired"
    v-model="computedValue"
    type="text"
    :maxlength="300"
    :minlength="6"
    :validate-on="'input'"
    :rules="getEmailFieldRule ? [validateEmail] : []"
    @update:modelValue="
      fieldValueChange();
      debounce(() => {
        console.log('email');
        setFieldRule();
      });
    "
  >
  </v-text-field>
</template>
  <script>
import EmailInput from "@/modules/shared/scripts/EmailInput.js";
export default EmailInput;
</script>
  