<template>
  <v-dialog
    persistent
    @afterLeave="afterModalLeave"
    v-model="dialog"
    width="50vw"
    max-width="90vw"
    min-height="50vh"
    max-height="3000px"
  >
    <div class="dialogheading">
      <div class="w-100 d-flex justify-content-between">
        <div class="w-90 text-center f-size24">
          Add MPMS to Overnight Process
        </div>
        <div>
          <v-icon @click="closeDialog" class="cursor-pointer" small>
            mdi-close
          </v-icon>
        </div>
      </div>
      <v-card>
        <v-card-text
          style="min-height: 35vh; max-height: 80vh; overflow-y: scroll"
        >
          <v-form class="mpmsSubheader">
            <div class="messageRow text-left mb-20">
              If a valid MPMS number is added, the MPMS project will be
              available to create as a GCMS Project on the next business day.
            </div>
            <div
              class="d-flex align-items-center justify-content-start"
              style="width: 100%"
            >
              <label for="mpmsNumber" class="createLabel" style="width: 30%">
                MPMS Number:
              </label>
              <div
                class="createRow d-flex align-items-center"
                style="margin: 0 !important"
              >
                <v-text-field
                  id="mpmsNumber"
                  v-model="mpmsValue"
                  @keydown.enter="saveData"
                  :rules="[mpmsRequiredRule, mpmsLengthRule]"
                  @keydown="keydownAllowNumberOnly($event)"
                  :maxlength="9"
                  class="w-full"
                  variant="outlined"
                ></v-text-field>
              </div>
            </div>
            <div
              class="d-flex align-items-center justify-content-start"
              style="width: 100%"
            >
              <div class="createLabel" style="width: 30%"></div>
              <div
                class="createRow d-flex align-items-center"
                style="width: 70% !important"
              >
                <div
                  class="messageRow text-left mb-20 project-invalid-mpms p-0"
                  v-if="showError"
                >
                  {{ errorMessage }}
                </div>
              </div>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <div class="action">
            <PrimaryButton
              :buttonText="'Save'"
              @click="saveData"
            ></PrimaryButton>
            <SecondaryButton
              :buttonText="'Cancel'"
              class="ml-2"
              @click="closeDialog"
            ></SecondaryButton>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
  <LoadingOverlay :isLoading="loading"> </LoadingOverlay>
</template>
<script>
import addMpmsOvernightProcessDialog from "@/modules/gcmsmain/createproject/submenu/scripts/AddMPMSOvernightProcessDialog.js";

export default {
  components: {
    ...addMpmsOvernightProcessDialog.components,
  },

  setup() {
    return addMpmsOvernightProcessDialog.setup();
  },
};
</script>
<style>
@import "@/modules/projectinformation/assets/projectinfostyle.scss";
@import "@/assets/css/style.scss";
</style>