<template>
  <div
    v-if="
      isUserRailroadWorkSummaryAccess.update ||
      isUserRailroadWorkSummaryAccess.read
    "
  >
    <BannerMessage
      v-if="showSaveBannerMessage"
      :successBanner="true"
      :message="'Successfully Saved Railroad Work Summary.'"
      @hideBanner="hideBanner"
    ></BannerMessage>
    <v-row>
      <v-col cols="12" class="ml-20">
        <router-link :to="`/GCMS/${id}`">
          &lt; Return to Project Information Page
        </router-link>
      </v-col>
      <v-col cols="12" class="page-title">
        <h1>Railroad Work Summary</h1>
      </v-col>
      <v-col> </v-col>
    </v-row>
    <v-row class="m-20 estimateRow">
      <v-col cols="1"> Railroad: <label class="asterisk">*</label> </v-col>
      <v-col cols="3" v-if="staticRailroad">
        <span id="selectedRailroad">{{ selectedRailroad }}</span>
      </v-col>
      <v-col cols="3" v-else>
        <v-select
          :items="railroadList"
          v-model="selectedRailroad"
          :rules="[requiredRule]"
        ></v-select>
      </v-col>
    </v-row>
    <br /><br />
    <div v-if="selectedRailroadDetails">
      <v-form v-model="isFormValid" class="projectInvolvement">
        <div>
          <div class="env-header mb-20">
            <h2 class="locationHeaderText">Project Details</h2>
          </div>
        </div>
        <div>
          <v-row>
            <v-col cols="1"></v-col>
            <v-col cols="2">
              <label>Work Type(s): </label>
            </v-col>
            <v-col cols="6">
              <v-chip
                v-for="(
                  item, index
                ) in selectedRailroadDetails.selectedWorkTypes"
                :key="item"
                class="ma-2"
                closable
                @click:close="removeWorkType(selectedRailroadDetails, index)"
              >
                {{ item }}
              </v-chip>
              <v-autocomplete
                class="w-50"
                v-model="selectedRailroadDetails.selectedWorkTypes"
                :items="selectedRailroadDetails.workTypes"
                multiple
                item-title="workType"
                item-value="workType"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1"></v-col>
            <v-col cols="2">
              <label for="railroadProjectIdentifier">
                Railroad Project Identifier:
              </label>
            </v-col>
            <v-col cols="3">
              <v-text-field
                id="railroadProjectIdentifier"
                v-model="selectedRailroadDetails.railroadProjectIdentifier"
                :maxlength="20"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1"></v-col>
            <v-col cols="2">
              <label for="crossingScope">Crossing Scope:</label>
            </v-col>
            <v-col cols="3">
              <span id="crossingScope">
                {{ selectedRailroadDetails.crossingScopeDesc }}
              </span>
            </v-col>
          </v-row>
          <br /><br />
          <v-row>
            <v-col cols="1"></v-col>
            <v-col cols="2">
              <label for="estimatedConstructionStartDate">
                Construction Start Date:
              </label>
            </v-col>
            <v-col cols="3">
              <span id="estimatedConstructionStartDate">
                {{ selectedRailroadDetails.estimatedConstructionStartDate }}
              </span>
            </v-col>
          </v-row>
        </div>
        <br /><br />
        <div>
          <div class="env-header mb-20">
            <h2 class="locationHeaderText">Crossing Details</h2>
          </div>
        </div>
        <div v-if="crossingDetails.length != 0">
          <v-table class="mt-20" style="width: 100%">
            <thead>
              <tr>
                <th scope="col" style="width: 15%">Crossing</th>
                <th scope="col" style="width: 7%">Crossing Type</th>
                <th scope="col" style="width: 7%">Milepost</th>
                <th scope="col" style="width: 10%">Local Road</th>
                <th scope="col" style="width: 10%">County</th>
                <th scope="col" style="width: 10%">Branch or Line Name</th>
                <th scope="col" style="width: 10%">Map Link</th>
                <th scope="col" style="width: 17%">Planned Work</th>
                <th scope="col" style="width: 12%">Work Order</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="crossing of crossingDetails" :key="crossing">
                <td style="width: 15%">{{ crossing.dotNumber }}</td>
                <td style="width: 7%">{{ crossing.crossingType }}</td>
                <td style="width: 7%">{{ crossing.milePost }}</td>
                <td style="width: 10%">{{ crossing.localRoad }}</td>
                <td style="width: 10%">{{ crossing.county }}</td>
                <td style="width: 10%">{{ crossing.branch }}</td>
                <td style="width: 10%">
                  <a
                    class="color-blue pointer"
                    @click="navigateToGISMap('crossing', crossing.dotNumber)"
                    >View On Map</a
                  >
                </td>
                <td style="width: 17%">
                  <v-select
                    :items="crossing.plannedWorks"
                    v-model="crossing.plannedWork"
                    :rules="[requiredRule]"
                  ></v-select>
                </td>
                <td style="width: 12%" class="notes-field">
                  <v-text-field
                    variant="outlined"
                    style="width: 100%"
                    type="text"
                    v-model="crossing.workOrderNumber"
                  ></v-text-field>
                </td>
              </tr>
            </tbody>
          </v-table>
        </div>
        <div v-else>
          <v-table class="mt-20" style="width: 100%">
            <thead>
              <tr>
                <th scope="col" style="width: 7%">Crossing</th>
                <th scope="col" style="width: 7%">Crossing Type</th>
                <th scope="col" style="width: 7%">Milepost</th>
                <th scope="col" style="width: 10%">Local Road</th>
                <th scope="col" style="width: 10%">County</th>
                <th scope="col" style="width: 10%">Branch or Line Name</th>
                <th scope="col" style="width: 10%">Map Link</th>
                <th scope="col" style="width: 17%">Planned Work</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td :colspan="getNoDataColspan" class="text-center">
                  No Crossings Found.
                </td>
              </tr>
            </tbody>
          </v-table>
        </div>
        <br /><br />
        <br /><br />
        <div>
          <div class="w-100 d-flex justify-content-end mt-20 mb-20">
            <SecondaryButton
              :buttonText="'Cancel'"
              @buttonClicked="cancelSave"
              :disabled="!isUserRailroadWorkSummaryAccess.update"
            ></SecondaryButton>
            <PrimaryButton
              @buttonClicked="saveClicked"
              :buttonText="'Save'"
              class="ml-10"
              :disabled="
                !selectedRailroad ||
                !isUserRailroadWorkSummaryAccess.update ||
                !dataChanged
              "
            ></PrimaryButton>
          </div>
          <div v-if="selectedRailroad">
            <div class="env-header mb-20">
              <h2 class="locationHeaderText">
                Railroad Work Summary Documents
              </h2>
            </div>
            <div>
              <v-row cols="12" v-if="railroadList?.length">
                <v-col cols="12">
                  <div class="view-upload-section">
                    <span
                      class="color-blue underline cursor-pointer"
                      @click="otherButtonClicked()"
                      :disabled="!isUserRailroadWorkSummaryAccess.update"
                      >Upload Railroad Work Summary Documents</span
                    >
                  </div>
                </v-col>
              </v-row>
              <v-grid>
                <v-row justify="center">
                  <v-col cols="11">
                    <DocumentsList
                      :heading="'Railroad Work Summary Document List'"
                      :doctype="'/WRK_SUM_VW'"
                      :id="id"
                      :updateDocumentList="updateDocumentList"
                      :isRailroadWorkSummary="true"
                      @updateDocumentListFlag="updateDocumentListFlag"
                      @otherButtonClicked="updateButtonClicked($event)"
                      :railroadCompanyId="railroadId"
                    ></DocumentsList>
                  </v-col>
                </v-row>
              </v-grid>
            </div>
          </div>
        </div>
        <br /><br />
        <DialogComponent1
          ref="railroadWorkSummaryDocumentDialogRef"
          @updateRailroadWorkSummary="handleChangesSaved"
          :disabled="!isUserRailroadWorkSummaryAccess.update"
        ></DialogComponent1>
      </v-form>
    </div>
  </div>
</template>
<script>
import RailroadWorkSummary from "@/modules/manageproject/scripts/RailroadWorkSummary.js";
export default RailroadWorkSummary;
</script>
<style>
@import "@/assets/css/style.scss";
v-field__input {
  overflow: hidden !important;
}
.v-autocomplete .v-field .v-text-field__prefix,
.v-autocomplete .v-field .v-text-field__suffix,
.v-autocomplete .v-field .v-field__input,
.v-autocomplete .v-field.v-field {
  overflow: hidden !important;
}
</style>