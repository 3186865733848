<template>
  <v-card class="dot-part-form">
    <v-card-text v-if="PartIVDetails">
      <div class="page-title">
        <h1>Physical Characteristics</h1>
      </div>
      <div class="env-infp" v-if="PartIVDetails">
        <v-form v-model="isFormValid" class="env-content">
          <v-row align="center">
            <v-col cols="6">
              <label class="mpms-env-label"
                ><v-row>
                  <v-col cols="1">1. </v-col>
                  <v-col cols="5">
                    <b class="textColumn required">
                      <span>Traffic Lanes Crossing Railroad: </span></b
                    >
                  </v-col>
                  <v-col cols="5">
                    <v-select
                      :rules="[requiredRule]"
                      v-model="
                        PartIVDetails.gradeCrossingPartFourDTO
                          .trafficLanesCrossingRailroad
                      "
                      :items="trafficLanesCrossingRailroadList"
                      outlined
                      :disabled="!isUserGradeCrossingAccess.update"

                    ></v-select> </v-col></v-row
              ></label>
            </v-col>

            <v-col cols="6">
              <label class="mpms-env-label"
                ><v-row>
                  <v-col cols="1">2. </v-col>
                  <v-col cols="5">
                    <b class="textColumn required"
                      ><span>Is Roadway/Pathway Paved? </span></b
                    >
                  </v-col>
                  <v-col cols="5">
                    <v-select
                      :rules="[requiredRule]"
                      v-model="
                        PartIVDetails.gradeCrossingPartFourDTO
                          .roadwayPathwayPaved
                      "
                      :items="yesOrNoList"
                      outlined
                      :disabled="!isUserGradeCrossingAccess.update"

                    ></v-select> </v-col></v-row
              ></label>
            </v-col>
            <v-col cols="6">
              <label class="mpms-env-label"
                ><v-row
                  ><v-col cols="1"> </v-col>
                  <v-col cols="5">
                    <b class="textColumn required"
                      ><span>Number of Lanes: </span></b
                    >
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                      v-model="
                        PartIVDetails.gradeCrossingPartFourDTO.numberOfLanes
                      "
                      maxlength="1"
                      oninput="validity.valid||(value='');"
                      @keydown="keydownAllowNumberOnly($event)"
                      outlined
                      :rules="[requiredRule]"
                      persistent-hint
                      :disabled="!isUserGradeCrossingAccess.update"

                    >
                    </v-text-field> </v-col></v-row
              ></label>
            </v-col>

            <v-col cols="6">
              <label class="mpms-env-label"
                ><v-row>
                  <v-col cols="1">3. </v-col>
                  <v-col cols="5">
                    <b class="textColumn required"
                      ><span>Does Track Run Down a Street? </span></b
                    >
                  </v-col>
                  <v-col cols="5">
                    <v-select
                      :rules="[requiredRule]"
                      v-model="
                        PartIVDetails.gradeCrossingPartFourDTO
                          .trackRunsDownStreet
                      "
                      :items="yesOrNoList"
                      outlined
                      :disabled="!isUserGradeCrossingAccess.update"

                    ></v-select> </v-col></v-row
              ></label>
            </v-col>
            <v-col cols="6">
              <label class="mpms-env-label"
                ><v-row>
                  <v-col cols="1">4. </v-col>
                  <v-col cols="5">
                    <b class="textColumn"
                      ><span
                        >Is Crossing Illuminated? (Street lights within approx.
                        50 feet from nearest rail):
                      </span></b
                    >
                  </v-col>
                  <v-col cols="5">
                    <v-select
                      v-model="
                        PartIVDetails.gradeCrossingPartFourDTO
                          .crossingIlluminated
                      "
                      :items="yesOrNoList"
                      outlined
                      :disabled="!isUserGradeCrossingAccess.update"

                    ></v-select> </v-col></v-row
              ></label>
            </v-col>
            <v-col cols="6">
              <label class="mpms-env-label"
                ><v-row>
                  <v-col cols="1">5. </v-col>
                  <v-col cols="5">
                    <b class="textColumn required"
                      ><span>Crossing Surface (on Main Track): </span></b
                    >
                  </v-col>
                  <v-col cols="5">
                    <div>
                      <v-chip
                        v-for="(item, index) in PartIVDetails
                          .gradeCrossingPartFourDTO.crossingSurfaceOnMainTrack"
                        :key="item"
                        closable
                        @click:close="removeItem(index)"
                        :rules="[requiredRule]"

                      >
                        {{ item }}
                      </v-chip>

                      <VueAutoComplete
                        v-model="
                          PartIVDetails.gradeCrossingPartFourDTO
                            .crossingSurfaceOnMainTrack
                        "
                        :items="crossingSurfaceList"
                        :rules="[requiredRule]"
                        :disabled="!isUserGradeCrossingAccess.update"

                      ></VueAutoComplete>
                      <br />
                      <v-text-field
                        v-if="
                          PartIVDetails.gradeCrossingPartFourDTO.crossingSurfaceOnMainTrack.includes(
                            'Other (Specify)'
                          )
                        "
                        placeholder="Other (Specify)"
                        :rules="[requiredRule]"
                        v-model="
                          PartIVDetails.gradeCrossingPartFourDTO.otherSpecify
                        "
                        @keydown="allowAlphanumericOnly($event)"
                        maxlength="50"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"

                      >
                      </v-text-field>
                    </div> </v-col></v-row
              ></label>
            </v-col>
            <v-col cols="6">
              <label class="mpms-env-label"
                ><v-row>
                  <v-col cols="1"> </v-col>
                  <v-col cols="5">
                    <b class="textColumn">
                      <span
                        >Crossing Surface Installation Date (MMYYYY):
                      </span></b
                    >
                  </v-col>
                  <v-col cols="5">
                    <div>
                      <MonthYearPicker
                        class="date-picker"
                        :value="
                          PartIVDetails.gradeCrossingPartFourDTO
                            .crossingSurfaceInstallationDate
                        "
                        @dateValidation="checkDateValidation($event)"
                        :invalidMsg="'Crossing Surface Installation Date'"
                        @input="crossingSurfaceDateChange($event)"
                        :disabled="!isUserGradeCrossingAccess.update"

                      />
                    </div> </v-col></v-row
              ></label>
            </v-col>
            <v-col cols="6"></v-col>
            <v-col cols="6">
              <label class="mpms-env-label"
                ><v-row>
                  <v-col cols="1"></v-col>
                  <v-col cols="5">
                    <b class="textColumn required"
                      ><span>Crossing Surface Width: </span></b
                    >
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                      :rules="[requiredRule]"
                      v-model="
                        PartIVDetails.gradeCrossingPartFourDTO
                          .crossingSurfaceWidth
                      "
                      maxlength="3"
                      oninput="validity.valid||(value='');"
                      @keydown="keydownAllowNumberOnly($event)"
                      outlined
                      :disabled="!isUserGradeCrossingAccess.update"

                    >
                    </v-text-field> </v-col></v-row
              ></label>
            </v-col>
            <v-col cols="6">
              <label class="mpms-env-label"
                ><v-row>
                  <v-col cols="1">6. </v-col>
                  <v-col cols="5">
                    <b class="textColumn"
                      ><span>Intersecting Roadway within 500 feet? </span></b
                    >
                  </v-col>
                  <v-col cols="5">
                    <v-select
                      v-model="
                        PartIVDetails.gradeCrossingPartFourDTO
                          .intersectingRoadwayWithin500Feet
                      "
                      :items="intersectingRoadwayWithin500FeetList"
                      outlined
                      :disabled="!isUserGradeCrossingAccess.update"

                    ></v-select> </v-col></v-row
              ></label>
            </v-col>
            <v-col cols="6">
              <label class="mpms-env-label"
                ><v-row>
                  <v-col cols="1"></v-col>
                  <v-col cols="5">
                    <b class="textColumn required">
                      <span>Crossing Surface Length: </span>
                    </b>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                      :rules="[requiredRule]"
                      v-model="
                        PartIVDetails.gradeCrossingPartFourDTO
                          .crossingSurfaceLength
                      "
                      maxlength="3"
                      oninput="validity.valid||(value='');"
                      @keydown="keydownAllowNumberOnly($event)"
                      outlined
                      :disabled="!isUserGradeCrossingAccess.update"

                    >
                    </v-text-field> </v-col></v-row
              ></label>
            </v-col>

            <v-col cols="6" v-if="PartIVDetails.gradeCrossingPartFourDTO
            .intersectingRoadwayWithin500Feet == 'Yes'">
              <label class="mpms-env-label"
                ><v-row>
                  <v-col cols="1"></v-col>
                  <v-col cols="5">
                    <b class="textColumn required">
                      <span>If Yes, Approximate Distance (Feet): </span>
                    </b>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                      v-model="
                        PartIVDetails.gradeCrossingPartFourDTO
                          .approximateDistanceToIntersectingRoadway
                      "
                      maxlength="3"
                      :rules="[requiredRule]"
                      oninput="validity.valid||(value='');"
                      @keydown="keydownAllowNumberOnly($event)"
                      outlined
                      :disabled="!isUserGradeCrossingAccess.update"

                    >
                    </v-text-field> </v-col></v-row
              ></label>
            </v-col>

            <v-col cols="6" v-else-if="PartIVDetails.gradeCrossingPartFourDTO
            .intersectingRoadwayWithin500Feet == 'No'">
              <label class="mpms-env-label"
                ><v-row>
                  <v-col cols="1"></v-col>
                  <v-col cols="5">
                    <b class="textColumn required">
                      <span>If Yes, Approximate Distance (Feet): </span>
                    </b>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                      v-model="
                        PartIVDetails.gradeCrossingPartFourDTO
                          .approximateDistanceToIntersectingRoadway
                      "
                      maxlength="3"
                      oninput="validity.valid||(value='');"
                      @keydown="keydownAllowNumberOnly($event)"
                      outlined
                      :disabled="true"

                    >
                    </v-text-field> </v-col></v-row
              ></label>
            </v-col>
            <v-col cols="6">
              <label class="mpms-env-label"
                ><v-row>
                  <v-col cols="1">7. </v-col>
                  <v-col cols="5">
                    <b class="textColumn"
                      ><span>Smallest Crossing Angle: </span></b
                    >
                  </v-col>
                  <v-col cols="5">
                    <v-select
                      v-model="
                        PartIVDetails.gradeCrossingPartFourDTO
                          .smallestCrossingAngle
                      "
                      :items="smallestCrossingAngleRadioButtonList"
                      outlined
                      :disabled="!isUserGradeCrossingAccess.update"

                    ></v-select> </v-col></v-row
              ></label>
            </v-col>
            <v-col cols="6">
              <label class="mpms-env-label"
                ><v-row>
                  <v-col cols="1">8. </v-col>
                  <v-col cols="5">
                    <b class="textColumn"
                      ><span>Is Commercial Power Available? </span></b
                    >
                  </v-col>
                  <v-col cols="5">
                    <v-select
                      v-model="
                        PartIVDetails.gradeCrossingPartFourDTO
                          .commercialPowerAvailable
                      "
                      :items="yesOrNoList"
                      outlined
                      :disabled="!isUserGradeCrossingAccess.update"

                    ></v-select> </v-col></v-row
              ></label>
            </v-col>
          </v-row>
          <ReasonForUpdate
            :class="{ PartIVDetails: true }"
            :isFormValid="checkFormValidation"
            :reasonToUpdateList="reasonToUpdateList"
            :reasonForUpdate="
              PartIVDetails.gradeCrossingPartFourDTO.reasonForUpdate
            "
            :isDisabled="!isFormValid || !isUserGradeCrossingAccess.update || PartIVDetails.gradeCrossingPartFourDTO
            ?.crossingSurfaceOnMainTrack.length == 0 || !valueChanged"
            @valueChange="valueChange($event)"
            @saveClicked="saveClicked()"
            @cancelClicked="cancelClicked()"
            :updatedDataFlagger="updatedDataFlagger"
          ></ReasonForUpdate>
          <v-col
            cols="6"
            v-if="
              updatedDataFlagger &&
              !PartIVDetails.gradeCrossingPartFourDTO.reasonForUpdate
            "
          >
            <label class="mpms-env-label"
              ><v-row
                ><v-col cols="1"> </v-col>
                <v-col cols="5">
                  <b class="textColumn"><span></span></b>
                </v-col>
                <v-col cols="5">
                  <div class="errorRow">
                    <div class="error">
                      <div>Required</div>
                    </div>
                  </div>
                </v-col></v-row
              ></label
            >
          </v-col>
        </v-form>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import PartIVPhysicalChar from "@/modules/dot-details/scripts/PartIVPhysicalChar";
export default PartIVPhysicalChar;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
</style>
