<template>
  <OnlineHelpPOC :helpKey="'DASH_HELP'"></OnlineHelpPOC>
  <div class="allProjectsHeading">District Grade Crossing Inspections</div>
  <v-card class="p-64">
    <FeatureTable
      :headers="headers"
      :items="completedCrossings"
      :showExportButton="true"
      :showClearFilterButton="true"
      :exportFilName="'District Grade Crossing Inspections'"
      :navigateToMpmsDetails="false"
      :isCompletedCrossing="true"
      :itemsPerPage="paginationData?.items"
      :linkedPagination="true"
      :totalItems="paginationData?.total"
      :lookupData="lookupData"
      :oldMultipleFilter="multipleFilter"
      :clickable="true"
      :isDynamicExport="true"
      :noDataAvailableText="noDataAvailableText"
      @updatePaginationPerPage="updatePaginationPerPage"
      @pageChange="pageChange"
      @sortUpdate="sortUpdate"
      @textFilterChange="textFilterChange"
      @multipleFiltersChange="multipleFiltersChange"
      @clearAllFilter="clearAllFilter"
      @linkClicked="linkClicked($event)"
      @exportButtonClicked="exportButtonClicked"
    >
    </FeatureTable>
  </v-card>
</template>

<script>
import CompletedCrossingsInspections from "@/modules/gcmsmain/districtfunctions/scripts/completedCrossingsInspections.js";
export default CompletedCrossingsInspections;
</script>
<style>
.container {
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
  padding-top: 20px;
}
</style>
