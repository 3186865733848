import InspectionR2AddEdit from "@/modules/dot-details/components/InspectionR2AddEdit.vue";import InspectionsR2Table from "@/modules/dot-details/components/InspectionsR2Table.vue";
import FeatureTable from "@/modules/shared/components/FeatureTable.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import sharedUserService from "@/modules/shared/service/sharedUserService";
import { computed, onMounted, ref } from "vue";
import { RBAC_CONTROLLER } from "../../../constants/rbacconstants";
import { userRoleBasedAccessStore } from "../../../stores/userRoleBasedAccess";
export default {
  name: "InspectionsR2",
  components: {
    FeatureTable,
    PrimaryButton,
    InspectionR2AddEdit,
    InspectionsR2Table,
  },
  props: {
    tab: { type: String, default: null },
    dotId: { type: String, default: null },
  },
  emits: ["showBanner"],
  setup(props, { emit }) {
    const tab = ref(props?.tab);
    const addOrEdit = ref("table");
    const selectedDOT = ref();
    const selectedCrossingInspectionId = ref();
    const userData = computed({
      get: () => {
        return sharedUserService.getSharedData();
      },
    });
    const isInitialised = ref(false);
    const userAccess = ref({ read: false, update: false });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    onMounted(async () => {
      await checkAccess();
      isInitialised.value = true;
    });
    const checkAccess = async () => {
      let readAccess = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.INVENTORY_INSPECTION_LIST_READ_ACCESS
      );
      let updateAccess = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.INVENTORY_INSPECTION_LIST_CRTUPD_ACCESS
      );
      userAccess.value.read = readAccess;
      userAccess.value.update = updateAccess;
      console.log("userAccess", updateAccess, readAccess, userAccess);
    };
    const buttonClicked = (event) => {
      document.getElementById("inspection-heading")?.scrollIntoView();
      addOrEdit.value = event?.addOrEdit;
      selectedDOT.value = event?.selectedDOT;
      selectedCrossingInspectionId.value = event?.selectedCrossingInspectionId;
    };
    const resetToTable = ($event) => {
      addOrEdit.value = "table";
      selectedDOT.value = props?.dotId;
      selectedCrossingInspectionId.value = null;
    };
    const addButtonClicked = () => {
      document.getElementById("inspection-heading")?.scrollIntoView();
      addOrEdit.value = "add";
      selectedDOT.value = props?.dotId;
      selectedCrossingInspectionId.value = null;
    };
    const showBanner = (event) => {
      emit("showBanner", event);
    };

    return {
      showBanner,
      addButtonClicked,
      resetToTable,
      userData,
      buttonClicked,
      addOrEdit,
      selectedDOT,
      selectedCrossingInspectionId,
      tab,
      userAccess,
      isInitialised,
    };
  },
};
