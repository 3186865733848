import useMapFunctions from "@/composables/gisMap";import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import VueTable from "@/modules/shared/components/VueTable.vue";
import axios from "axios";
import { onMounted, ref, watch } from "vue";
export default {
  name: "ProjectBridges",
  components: { VueTable },
  props: ["id"],
  setup(props) {
    const { navigateToGISMap } = useMapFunctions();
    let projectBridgeList = ref([]);
    let columnData = ref([
      { name: "Bridge Name", key: "bridgeName" },
      { name: "Structure Type", key: "structureType" },
      { name: "BR-Key", key: "brKey" },
      { name: "SR", key: "sr" },
      { name: "Segment", key: "segment" },
      { name: "Offset", key: "offset" },
      { name: "View on Map", key: "streetViewLink", isLink: true },
    ]);
    const id = ref();
    watch(
      () => [props.id],
      () => {
        if (props.id) {
          id.value = props.id;
          getProjectBridgeList();
        }
      },
      { deep: true }
    );
    onMounted(async () => {
      id.value = props.id;
      if (id.value) {
        await getProjectBridgeList();
      }
    });
    async function getProjectBridgeList() {
      axios
        .get(CONFIG_ENDPOINT.GCB_PROJECT_BRIDGES + "/" + id.value)
        .then((response) => {
          projectBridgeList.value = [];
          projectBridgeList.value = response.data?.map((item) => {
            return { ...item, streetViewLink: "streetViewLink" };
          });
        })
        .catch(() => {
          projectBridgeList.value = [];
        });
    }
    const linkClicked = (event) => {
      navigateToGISMap("project", props?.id);
    };
    return { id, projectBridgeList, columnData, linkClicked };
  },
};
