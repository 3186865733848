<template>
  <v-card class="p-64 v-card-text">
    <OnlineHelpPOC :helpKey="'DASH_HELP'"></OnlineHelpPOC>
    <div class="allProjectsHeading">Consultant Agreement List</div>
    <div v-if="ErrorBannerData">
      <BannerMessage
        v-if="
          ErrorBannerData.bannerType === 'error' && ErrorBannerData.showBanner
        "
        :errorBanner="true"
        :message="ErrorBannerData.bannerMessage"
        :hasList="ErrorBannerData.hasList"
        :errorList="ErrorBannerData.errorList"
        @hideBanner="hideErrorBannerMessage"
      ></BannerMessage>
    </div>
    <v-card class="p-64">
      <FeatureTable
        :headers="headers"
        :items="consultantAgrement"
        :showExportButton="true"
        :exportFilName="'Active Projects'"
        :showClearFilterButton="true"
        :tableHeader="''"
        :routerText="''"
        :itemsPerPage="paginationData?.items"
        :linkedPagination="true"
        :totalItems="paginationData?.total"
        :lookupData="lookupData"
        :oldMultipleFilter="multipleFilter"
        :isDynamicExport="true"
        @updatePaginationPerPage="updatePaginationPerPage"
        @pageChange="pageChange"
        @sortUpdate="sortUpdate"
        @textFilterChange="textFilterChange"
        @multipleFiltersChange="multipleFiltersChange"
        @clearAllFilter="clearAllFilter"
        @exportButtonClicked="exportButtonClicked"
        @editButtonClicked="editButtonClicked"
      >
      </FeatureTable>
      <div class="d-flex justify-content-start m-20">
        <PrimaryButton
          @buttonClicked="addButtonClicked"
          :buttonText="'ADD CONSULTANT AGREEMENT'"
        ></PrimaryButton>
      </div>
    </v-card>
  </v-card>
</template>

<script>
import ConsultantAgreement from "@/modules/gcmsmain/admin/submenu/scripts/ConsultantAgreement.js";
export default ConsultantAgreement;
</script>
<style>
.container {
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
  padding-top: 20px;
}
</style>
