import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import axios from "axios";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useProjectServiceStore = defineStore("projectService", () => {
  const countyMunicipalityMap = ref();
  const projectPhaseList = ref();
  const activeRailroadList = ref();
  const stateList = ref();
  const consultingCompanyMap = ref({});

  const createConsultingCompanyMap = async(input) =>{
    consultingCompanyMap.value = input;
  };

  const retrieveActiveRailroadList = async () => {
    let url = CONFIG_ENDPOINT.ACTIVE_RAILROAD_LIST;
    await axios
      .get(url)
      .then((response) => {
        activeRailroadList.value = response;
        //return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const retrieveMunicipalityCountyList = async () => {
    let url = CONFIG_ENDPOINT.PROJECT_INFO_MUNICIPALITY_COUNTY_LIST;
    await axios
      .get(url)
      .then((response) => {
        countyMunicipalityMap.value = response;
        //return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const retrieveProjectPhaseList = async () => {
    let url = CONFIG_ENDPOINT.PROJECT_INFO_PHASE_LIST;
    await axios
      .get(url)
      .then((response) => {
        projectPhaseList.value = response;
        //return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const retrieveStateList = async () => {
    let url = CONFIG_ENDPOINT.GET_STATES;
    await axios
      .get(url)
      .then((response) => {
        stateList.value = response;
        //return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const retrievePA1 = async (id) => {
    let url = CONFIG_ENDPOINT.PROJECT_INFO_PA1;
    return await axios
      .get(url + id)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deletePA1Call = async (id, serialNum) => {
    let url = CONFIG_ENDPOINT.PROJECT_INFO_PA1;
    return await axios
      .delete(url + id + "/" + serialNum)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addPA1Call = async (id, object) => {
    let url = CONFIG_ENDPOINT.PROJECT_INFO_PA1;
    return await axios
      .post(url + id, object)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updatePA1Call = async (id, object) => {
    let url = CONFIG_ENDPOINT.PROJECT_INFO_PA1;
    return await axios
      .put(url + id, object)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const postSafetyMilestones = async (id, object) => {
    let url = CONFIG_ENDPOINT.SAFETY_MILESTONES_SCOPING_POST;
    return await axios
      .post(url + id, object)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchScopingMilestones = async (id) => {
    let url = CONFIG_ENDPOINT.SAFETY_MILESTONES_SCOPING;
    return await axios
      .get(url + id)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchAuthorizationMilestones = async (id) => {
    let url = CONFIG_ENDPOINT.SAFETY_MILESTONES_AUTHORIZATION;
    return await axios
      .get(url + id)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchContractDevelopmentMilestones = async (id) => {
    let url = CONFIG_ENDPOINT.SAFETY_MILESTONES_CONTRACT_DEVELOPMENT;
    return await axios
      .get(url + id)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchConstructionMilestones = async (id) => {
    let url = CONFIG_ENDPOINT.SAFETY_MILESTONES_CONSTRUCTION;
    return await axios
      .get(url + id)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchCoordinationMilestones = async (id) => {
    let url = CONFIG_ENDPOINT.SAFETY_MILESTONES_COORDINATION;
    return await axios
      .get(url + id)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return {
    countyMunicipalityMap,
    projectPhaseList,
    stateList,
    activeRailroadList,
    consultingCompanyMap,
    retrieveMunicipalityCountyList,
    retrieveProjectPhaseList,
    retrieveStateList,
    retrievePA1,
    deletePA1Call,
    addPA1Call,
    updatePA1Call,
    postSafetyMilestones,
    fetchScopingMilestones,
    fetchAuthorizationMilestones,
    fetchCoordinationMilestones,
    fetchContractDevelopmentMilestones,
    fetchConstructionMilestones,
    retrieveActiveRailroadList,
    createConsultingCompanyMap,
  };
});
