<template>
  <div class="env-infp">
    <div class="env-header">
      <h2 class="locationHeaderText">Project Documents</h2>
    </div>
    <div class="env-content">
      <div id="app" class="wrapper-container">
        <div class="w-100">
          <DocumentsList
            :heading="'Safety Milestones Document List'"
            :doctype="'/SM_VIEW_DT'"
            :id="mpmsId"
            :updateDocumentList="updateDocumentList"
            @updateDocumentListFlag="updateDocumentListFlag"
            @otherButtonClicked="updateButtonClicked($event)"
          >
          </DocumentsList>
          <h2
            class="f-size18 f-montserrat text-left mt-50"
            style="font-size: 18px; padding-bottom: 0.5em; color: #003e7e"
          >
            Upload Safety Milestones Documents
          </h2>
          <p class="text-left" style="padding-bottom: 1px; padding-left: 10px">
            Maximum allowable file size is 500 MB.
          </p>
        </div>
        <div class="w-100">
          <UploadScopingFieldViewDocument
            v-if="!UpdateModalOpened"
            @uploadFilesCall="uploadFiles"
            :isDynamicDocumentType="true"
            :checkMaximumFilesUploaded="true"
            :checkMaxFileSize="true"
            :showPreviewRailroadColumn="true"
            :showPreviewDOTColumn="true"
            :previewDocTypeUrl="'GET_SAFETY_MILESTONES_DOCTYPE_UPLOAD_LIST'"
            :uploadedDocumentsLength="inventorylist.length"
            :mpmsId="mpmsId"
            :isPageUpload="true"
            :isContractorBidSafetyMilestone="true"
          ></UploadScopingFieldViewDocument>
        </div>
      </div>
    </div>
  </div>
  <LoadingOverlay :isLoading="loading"> </LoadingOverlay>
  <DialogComponent ref="ralroadEstimatesDocumentsDialogRef"></DialogComponent>
</template>
<script>
import ViewUploadSafetyMilestoneDocuments from "@/modules/manageproject/scripts/ViewUploadSafetyMilestoneDocuments.js";
export default ViewUploadSafetyMilestoneDocuments;
</script>
<style scoped>
@import "@/modules/manageproject/assets/ViewUploadSafetyMilestoneDocuments.scss";
</style>