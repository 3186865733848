import { requiredRule, validateEmail } from "@/composables/validationRules";import { computed, ref } from "vue";
export default {
  components: {},
  props: {
    modelValue: { default: "" },
    isRequired: { default: true },
  },
  setup(props, context) {
    const stoppedTyping = ref(false);
    const isRequired = ref(props?.isRequired);
    const computedValue = computed({
      get: () => {
        if (!props.modelValue) {
          return "";
        }

        return props.modelValue;
      },

      set: (modifiedValue) => {
        context.emit("update:modelValue", modifiedValue);
      },
    });
    const handleInput = (value) => {
      context.emit("input", value);
    };
    const getEmailFieldRule = computed({
      get: () => {
        return stoppedTyping.value;
      },
    });
    const fieldValueChange = () => {
      stoppedTyping.value = false;
    };
    const setFieldRule = () => {
      stoppedTyping.value = true;
    };

    function createDebounce() {
      let timeout = null;
      return function (fnc, delayMs) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fnc();
        }, delayMs || 1000);
      };
    }
    return {
      computedValue,
      handleInput,
      requiredRule,
      validateEmail,
      fieldValueChange,
      setFieldRule,
      getEmailFieldRule,
      debounce: createDebounce(),
      isRequired,
    };
  },
};
