export const ACCEPTED_RAILROAD_DROPDOWN_DOC_TYPE = [  "Construction Start",
  "Construction Complete",
  "Reimbursement Agreement",
  "Final Invoice",
  "Agreement Closeout",
  "Railroad Plan Response",
  "Executed Agreement",
  "Railroad Estimate of Cost",
  "Railroad Estimate Supporting Documents",
  "Signed Railroad Agreement",
  "Draft Railroad Agreement",
  "Executed Railroad Agreement",
  "Contractor Bid Concurrence in Award Letter"
];
export const ACCEPTED_DOT_DROPDOWN_DOC_TYPE = [
  "Signed Municipality Resolution Letter",
  "Signed Municipality Resolution",
  "D-4279 Railroad Crossing Data for Design",
  "D-4279A Railroad Crossing Data for Contractor",
];
