import { CONFIG_ENDPOINT } from "@/constants/Endpoints";import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import DialogComponent from "@/modules/manageproject/components/projectFlaggerNeedsDialog.vue";
import VueTable from "@/modules/shared/components/VueTable.vue";
import router from "@/router";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
export default {
  components: { VueTable, DialogComponent },
  name: "flaggerActivityAdmin",
  emits: ["linkClicked"],
  setup() {
    let formData = ref(null);
    const flaggerNeedsDialog = ref(null);
    let itemIndex = ref(null);
    const route = useRoute();
    let flaggerNeedsData = ref([]);
    const id = route.params.id;
    const isFlaggerNeedsUpdateAccess = ref({ read: false, update: false });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    let errorMessage = ref(" ");
    let columnData = ref([
      {
        name: "DOT#",
        key: "dotnumber",
        isLink: true,
        alignLeft: true,
        columnWidth: "15",
      },
      { name: "Railroad", key: "railroad" },
      { name: "# Tracks", key: "noactivetracks", columnWidth: "6" },
      { name: "RR Milepost", key: "milepost" },
      { name: "Crossing Position", key: "posxing" },
      {
        name: "Location",
        key: "location",
        columnWidth: "5",
      },
      { name: "Activity", key: "activitynames", notick: true },
      { name: "# Flaggers", key: "flaggernumber", columnWidth: "8" },
      {
        name: "# Flagger Days",
        key: "flaggerdays",
        columnWidth: "10",
      },
      {
        name: "Action",
        key: "",
        isAction: true,
        isAdd: false,
        isEdit: false,
        editDisable: { key: "railroad", value: null },
        isDelete: false,
        columnWidth: "6",
      },
    ]);
    onMounted(async () => {
      await getFlaggerNeedsAccess();
      if (isFlaggerNeedsUpdateAccess.value.read) {
        getFlaggerActivityData();
      } else {
        flaggerNeedsData.value = [];
      }
    });
    const getFlaggerNeedsAccess = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.PROJECT_FLAGGER_NEEDS_PAGE_READ_ACCESS
      );
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.PROJECT_FLAGGER_NEEDS_PAGE_CRTUPD_ACCESS
      );
      isFlaggerNeedsUpdateAccess.value.read = readResponse;
      isFlaggerNeedsUpdateAccess.value.update = updateResponse;
      columnData.value[columnData.value.length - 1].isEdit =
        isFlaggerNeedsUpdateAccess.value.update;
    };
    async function getFlaggerActivityData() {
      axios
        .get(CONFIG_ENDPOINT.MANAGE_PROJECT_FLAGGER_NEEDS + "/" + id)
        .then((response) => {
          for (let index in response.data) {
            response.data[index].activitynames = response.data[
              index
            ].activitynames
              ?.toString()
              ?.replaceAll(",", ", ");
            response.data[index].openDialog =
              response.data[index].railroad === route.query?.railroad &&
              response.data[index].dotnumber === route.query?.dotnumber;
          }
          flaggerNeedsData.value = response.data
            .sort(dynamicSort("railroad"))
            .sort(dynamicSort("dotnumber"));
          errorMessage.value = "No Data Available";
          if (route.query?.railroad && route.query?.dotnumber) {
            let openDialogIndex = flaggerNeedsData.value.findIndex(
              (flagger) => flagger?.openDialog
            );
            openDialog("Edit", {
              index: openDialogIndex,
              item: flaggerNeedsData.value[openDialogIndex],
            });
          }
        })
        .catch((err) => {
          flaggerNeedsData.value = [];
          errorMessage.value = "No Data Available";
        });
    }
    function dynamicSort(property) {
      return function (a, b) {
        if (a[property] < b[property]) {
          return -1;
        } else if (a[property] > b[property]) {
          return 1;
        } else {
          return 0;
        }
      };
    }
    function showWarning() {
      return flaggerNeedsData?.value?.filter((flagger) => flagger.railroad);
    }
    function showWarningWhenNoRailroad() {
      return flaggerNeedsData?.value?.filter((flagger) => !flagger.railroad);
    }
    const handleChangesSaved = (value) => {
      for (const key in value?.data) {
        if (key == "activitynames") {
          const commaSeparatedString = Object.values(value?.data[key]).join(
            ", "
          );
          value.data[key] = commaSeparatedString;
        }
      }
      flaggerNeedsData.value[value?.index] = value?.data;
    };
    const openDialog = (action, item) => {
      let arrayData = [];
      if (action === "Edit") {
        itemIndex.value = item.index;
        for (let index in item) {
          if (item[index].activitynames) {
            arrayData = item[index].activitynames
              .split(",")
              .map((item) => item.trim());
          }
        }
        formData.value = { ...item.item };
        formData.value.activitynames = arrayData;
        flaggerNeedsDialog.value
          .open(
            "Edit Flagger Needs " + item.item.dotnumber,
            "Edit",
            formData.value,
            itemIndex.value
          )
          .then((resolve) => {
            if (resolve) {
              handleChangesSaved(resolve);
            }
            router.push({ path: "/GCMS/" + id + "/projectFlaggerNeeds" });
          });
      }
    };
    const linkClicked = (event) => {
      if (
        event.key === "dotnumber" &&
        isFlaggerNeedsUpdateAccess.value.update
      ) {
        window.scrollTo(0, 0);
        const cleanedDotNumber = event.value
          .replace(/^(DOT# |Nearby DOT# )/, "")
          .trim();
        router.push({ path: "/GCMS/DOT/" + cleanedDotNumber });
      } else if (event.key === "location") {
        window.open(
          "https://www.google.com/maps/place/" + event.value,
          "_blank"
        );
      }
    };
    return {
      flaggerNeedsData,
      columnData,
      handleChangesSaved,
      openDialog,
      flaggerNeedsDialog,
      showWarning,
      linkClicked,
      showWarningWhenNoRailroad,
      id,
      isFlaggerNeedsUpdateAccess,
      errorMessage,
    };
  },
};
