<template>
  <div>
    <dynamic-menu
      :menu-items="menuData"
      :menuTitle="menuName"
      @modalClick="openModal($event)"
    />
  </div>
  <AddMPMSOvernightProcessDialog
    ref="addMPMSOvernightProcessDialogRef"
  ></AddMPMSOvernightProcessDialog>
</template>
<script>
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import DynamicMenu from "@/modules/shared/components/DynamicMenu.vue";
import { onMounted, ref } from "vue";
import AddMPMSOvernightProcessDialog from "@/modules/gcmsmain/createproject/submenu/components/AddMPMSOvernightProcessDialog.vue";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
export default {
  components: {
    DynamicMenu,
    AddMPMSOvernightProcessDialog,
  },
  setup() {
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const addMPMSOvernightProcessDialogRef = ref(null);
    const menuName = ref("Create Projects");
    const menuData = ref([]);
    onMounted(async () => {
      menuData.value.push({
        subtitle: "Create GC Projects",
        value: CONFIG_ENDPOINT.GCMS + CONFIG_ENDPOINT.CREATE_PROJECTS_MENU_URL,
      });
      let hasAccess = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.MPMS_OVERNIGHT_PROCESS_ACCESS
      );
      if (hasAccess) {
        menuData.value.push({
          subtitle: "Add MPMS to Overnight Process",
          value:
            CONFIG_ENDPOINT.GCMS + CONFIG_ENDPOINT.CREATE_PROJECTS_MENU_URL,
          openModal: true,
        });
      }
    });
    const openModal = (event) => {
      const id = null;
      addMPMSOvernightProcessDialogRef.value
        .open("Add MPMS to Overnight Process", null, id)
        .then((resolve) => {
          if (resolve) {
          }
        });
    };
    return {
      menuName,
      menuData,
      openModal,
      addMPMSOvernightProcessDialogRef,
    };
  },
};
</script>
<style>
@import "@/modules/gcmsmain/dashboard/assets/MyTasks.scss";
</style>