import useFieldEntry from "@/composables/fieldEntry";import {
  mpmsLengthRule,
  mpmsRequiredRule,
} from "@/composables/validationRules";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import LoadingOverlay from "@/modules/shared/components/LoadingOverlay.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import axios from "axios";
import { reactive, ref, toRefs } from "vue";
export default {
  components: {
    PrimaryButton,
    SecondaryButton,
    LoadingOverlay,
  },
  setup() {
    const loading = ref(false);
    const mpmsValue = ref("");
    const state = reactive({
      dialog: false,
      resolve: null,
      reject: null,
      id: null,
    });

    const { keydownAllowNumberOnlyWithEnter } = useFieldEntry();
    let valueChanged = ref(false);
    const originalValue = ref();
    const isInitialised = ref(false);
    const open = (title, formData, id) => {
      mpmsValue.value = "";
      state.dialog = true;
      setTimeout(() => {
        isInitialised.value = true;
      }, 1000);
      state.id = id || null;
      return new Promise((resolve, reject) => {
        state.resolve = resolve;
        state.reject = reject;
      });
    };
    const showError = ref(false);
    const errorMessage = ref("");
    const saveData = async () => {
      showError.value = false;
      if (mpmsValue.value) {
        loading.value = true;
        try {
          let res = await axios.post(
            `${CONFIG_ENDPOINT.GCMS_CREATE_OVERNIGHT_PROJECT}/${mpmsValue.value}`,
            {
              mpmsNumber: mpmsValue.value,
            }
          );
          if (
            res.data.businessMessage === "MPMS# has already been added to GCMS"
          ) {
            showError.value = true;
            errorMessage.value = res.data.businessMessage;
          } else {
            state.resolve(false);
            state.dialog = false;
          }
          console.log("saveData success", res);
        } catch (error) {
          console.log("saveData error", error);
        } finally {
          loading.value = false;
        }
      } else {
        state.resolve(false);
      }
    };
    const closeDialog = () => {
      if (isInitialised.value && valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          valueChanged.value = false;
          state.resolve(false);
          state.dialog = false;
          resetForm();
        }
      } else {
        valueChanged.value = false;
        state.resolve(false);
        state.dialog = false;
        resetForm();
      }
    };
    const afterModalLeave = () => {
      valueChanged.value = false;
      isInitialised.value = false;
      originalValue.value = null;
      resetForm();
    };
    const resetForm = () => {
      mpmsValue.value = ""; // Clear the MPMS value input field
    };
    window.onbeforeunload = function () {
      if (isInitialised.value && valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };
    function keydownAllowNumberOnly(event) {
      if (!event.keyCode != 86 && !event.ctrlKey) {
        if (isLeftUpRightDownBackspaceDeleteTab(event)) {
          return;
        }

        let regex = /\d/;

        if (!regex.test(event.key)) {
          event.preventDefault();
          return false;
        }
      }
    }
    function isLeftUpRightDownBackspaceDeleteTab(event) {
      let key = event.key || event.code;
      return (
        key === "ArrowLeft" ||
        key === 37 ||
        key === "ArrowUp" ||
        key === 38 ||
        key === "ArrowRight" ||
        key === 39 ||
        key === "ArrowDown" ||
        key === 40 ||
        key === "Backspace" ||
        key === 8 ||
        key === "Delete" ||
        key === 46 ||
        key === "Tab" ||
        key === 9
      );
    }
    return {
      ...toRefs(state),
      mpmsValue,
      saveData,
      closeDialog,
      open,
      loading,
      afterModalLeave,
      resetForm,
      showError,
      errorMessage,
      mpmsLengthRule,
      mpmsRequiredRule,
      keydownAllowNumberOnly,
      keydownAllowNumberOnlyWithEnter,
    };
  },
};
