<template>
  <v-row>
    <!-- Left corner element -->
    <v-col class="ml-20">
      <router-link :to="`/GCMS/${id}`">
        &lt; Return to Project Information Page
      </router-link>
    </v-col>
    <!-- Centered element -->
    <v-col class="page-title">
      <h1>Railroad Project Team</h1>
    </v-col>
    <v-col> </v-col>
  </v-row>
  <div>
    <div>
      <div class="env-header mb-20">
        <h2 class="locationHeaderText">Railroad Team</h2>
      </div>
    </div>
    <v-form v-model="isFormValid">
      <table class="projectTeam" aria-describedby="projectTeam">
        <tr v-for="(project, index) of projectTeamList" :key="project">
          <th scope="col" class="text-left">
            <b class="required"
              ><span>{{ project?.projectTeamMemberRole }}: </span></b
            >
          </th>
          <th scope="col">
            <v-autocomplete
              style="width: 300px"
              bg-color="white"
              v-model="project.displayName"
              @focus="inputFocus(teamListCode[index])"
              item-title="displayName"
              item-value="displayName"
              :items="apiData[teamListCode[index]]"
              :rules="[requiredRule]"
              @update:modelValue="updateDisplayName($event, index)"
              :disabled="!isUserAccess.update"
            >
            </v-autocomplete>
          </th>
        </tr>
      </table>
    </v-form>
    <div class="action">
      <PrimaryButton
        :disabled="!formChanged || !isFormValid || !isUserAccess.update"
        @buttonClicked="saveChanges"
        :buttonText="'Save'"
      ></PrimaryButton>
      <SecondaryButton
        @buttonClicked="fetchData"
        :buttonText="'Cancel'"
        class="ml-2"
        :disabled="!isUserAccess.update"
      ></SecondaryButton>
    </div>
  </div>
  <LoadingOverlay :isLoading="loading"> </LoadingOverlay>
</template>
<script>
import RailroadProjectTeam from "@/modules/manageproject/scripts/RailroadProjectTeam.js";
export default RailroadProjectTeam;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/modules/manageproject/assets/ProjectTeam.scss";
.sentTable tr:hover {
  background: transparent;
}
</style>