<template>
  <v-dialog v-model="dialogVisible" max-width="500px">
    <v-card>
      <v-card-title>Confirmation</v-card-title>
      <v-card-text> Are you sure you want to proceed? </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="handleYes">Yes</v-btn>
        <v-btn color="error" @click="handleNo">No</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref } from "vue";

const dialogVisible = ref(false);

const handleYes = () => {
  console.log("User clicked Yes");
  dialogVisible.value = false; // Close the dialog
};

const handleNo = () => {
  console.log("User clicked No");
  dialogVisible.value = false; // Close the dialog
};
</script>
