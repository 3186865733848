import { ref } from "vue";export default {
  name: "BannerMessage",
  emit: ["hideBanner"],
  props: {
    successBanner: { type: Boolean, default: false },
    errorBanner: { type: Boolean, default: false },
    hasList: { type: Boolean, default: false },
    message: { type: String, default: null },
    errorList: { type: Array, default: [] },
  },
  setup(props, { emit }) {
    const successBanner = ref(props?.successBanner);
    const errorBanner = ref(props?.errorBanner);
    const hasList = ref(props?.hasList);
    const message = ref(props?.message);
    const hideBannerMessage = () => {
      emit("hideBanner", true);
    };
    return {
      hideBannerMessage,
      successBanner,
      errorBanner,
      hasList,
      message,
    };
  },
};
