<template>
  <div class="mb-40">
    <VueTable
      :tableData="projectBridgeList"
      :columnData="columnData"
      @linkClicked="linkClicked($event)"
    ></VueTable>
  </div>
</template>
<script>
import ProjectBridges from "@/modules/grade-crossing-and-bridges/scripts/ProjectBridges.js";
export default ProjectBridges;
</script>
<style>
@import "@/assets/css/style.scss";
</style>