<template>
  <v-card>
    <v-card-text v-if="PennDotCrossingInfo">
      <v-form v-model="isFormValid">
        <div>
          <div class="env-header mb-20 mt-20">
            <h2 class="locationHeaderText">Status Details</h2>
          </div>
          <div class="env-infp">
            <div class="env-content">
              <v-row>
                <v-col cols="4">
                  <label class="mpms-env-label"
                    ><v-row>
                      <v-col cols="6">
                        <b class="d-flex justify-content-start text-left"
                          ><span>PUC Status: </span></b
                        >
                      </v-col>
                      <v-col cols="5">
                        <v-select
                          v-model="
                            PennDotCrossingInfo.pennDotCrossingDTO.pucStatus
                          "
                          :items="
                            PennDotCrossingInfo.pennDotCrossingLookupData
                              .pucStatus
                          "
                          :disabled="!isUserGradeCrossingAccess.update"

                        ></v-select> </v-col></v-row
                  ></label>
                </v-col>
                <v-col cols="4">
                  <label class="mpms-env-label"
                    ><v-row class="railbank">
                      <v-col cols="2">
                        <b class="d-flex justify-content-start text-left"
                          ><span>Rail Bank: </span></b
                        >
                      </v-col>
                      <v-col cols="2">
                        <v-checkbox-btn
                          class="d-flex justify-content-start text-left"
                          v-model="
                            PennDotCrossingInfo.pennDotCrossingDTO.railBank
                          "
                          :disabled="!isUserGradeCrossingAccess.update"
                        ></v-checkbox-btn> </v-col></v-row
                  ></label>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>

        <div>
          <div class="env-header mb-20 mt-20">
            <h2 class="locationHeaderText">Tracks & Travel Lanes</h2>
          </div>
          <div class="env-infp">
            <div class="env-content">
              <v-row>
                <v-col cols="4">
                  <label class="mpms-env-label"
                    ><v-row>
                      <v-col cols="6">
                        <b
                          class="d-flex justify-content-start text-left required"
                          ><span>Number of Active Tracks: </span></b
                        >
                      </v-col>
                      <v-col cols="4">
                        <v-select
                          :rules="[requiredRule]"
                          v-model="
                            PennDotCrossingInfo.pennDotCrossingDTO
                              .noActiveTracks
                          "
                          :items="
                            PennDotCrossingInfo.pennDotCrossingLookupData
                              .numberOfActiveTracks
                          "
                          @update:modelValue="activeTracksChange"
                          :disabled="!isUserGradeCrossingAccess.update"
                        ></v-select></v-col></v-row
                  ></label>
                </v-col>
                <v-col cols="4">
                  <label class="mpms-env-label"
                    ><v-row>
                      <v-col cols="6">
                        <b
                          class="d-flex justify-content-start text-left required"
                          ><span>Number of Travel Lanes: </span></b
                        >
                      </v-col>
                      <v-col cols="4">
                        <v-select
                          :rules="[requiredRule]"
                          v-model="
                            PennDotCrossingInfo.pennDotCrossingDTO.noTravelLanes
                          "
                          :items="
                            PennDotCrossingInfo.pennDotCrossingLookupData
                              .numberOfTravelLanes
                          "
                          :disabled="!isUserGradeCrossingAccess.update"
                        ></v-select> </v-col></v-row
                  ></label>
                </v-col>
                <v-col cols="4">
                  <label class="mpms-env-label"
                    ><v-row>
                      <v-col cols="6">
                        <b
                          class="d-flex justify-content-start text-left required"
                          ><span>Planning Organization: </span></b
                        >
                      </v-col>
                      <v-col cols="4">
                        {{
                          PennDotCrossingInfo.pennDotCrossingDTO
                            .planningOrganization
                        }}
                      </v-col></v-row
                    ></label
                  >
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
        <div>
          <div class="env-header mb-20 mt-20">
            <h2 class="locationHeaderText">Main Track & Surface</h2>
          </div>
          <div class="env-infp">
            <div class="env-content">
              <v-row>
                <v-col cols="4">
                  <label class="mpms-env-label"
                    ><v-row>
                      <v-col cols="6">
                        <b class="d-flex justify-content-start text-left"
                          ><span>Crossing Surface Material:</span></b
                        >
                      </v-col>
                      <v-col cols="5">
                        {{
                          PennDotCrossingInfo.pennDotCrossingDTO.penndotXSurface
                        }}
                      </v-col></v-row
                    ></label
                  >
                </v-col>
                <v-col cols="4">
                  <label class="mpms-env-label"
                    ><v-row>
                      <v-col cols="6">
                        <b
                          class="d-flex justify-content-start text-left required"
                          ><span>Surface Condition: </span></b
                        >
                      </v-col>
                      <v-col cols="5">
                        <v-select
                          :rules="[requiredRule]"
                          v-model="
                            PennDotCrossingInfo.pennDotCrossingDTO.surfaceCdtn
                          "
                          :items="
                            PennDotCrossingInfo.pennDotCrossingLookupData
                              .surfaceCondition
                          "
                          :disabled="!isUserGradeCrossingAccess.update"
                        ></v-select> </v-col></v-row
                  ></label>
                </v-col>
                <v-col cols="4">
                  <label class="mpms-env-label"
                    ><v-row>
                      <v-col cols="6">
                        <b class="d-flex justify-content-start text-left"
                          ><span>Surface Installed Date: </span></b
                        >
                      </v-col>
                      <v-col cols="5">
                        <DatePicker
                          :value="
                            PennDotCrossingInfo.pennDotCrossingDTO
                              .surfaceInstallDate
                          "
                          :invalidMsg="'Surface Installed Date'"
                          @dateValidation="checkSurfaceDateValidation($event)"
                          @input="surfaceInstalledDatePickerChange($event)"
                          :disabled="!isUserGradeCrossingAccess.update"
                        /> </v-col></v-row
                  ></label>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
        <div v-if="auxilaryTracksSurfaceData?.length">
          <div class="env-header mb-20 mt-20">
            <h2 class="locationHeaderText">Auxiliary Tracks & Surface</h2>
          </div>
          <div class="env-infp">
            <div class="env-content">
              <v-row>
                <v-col
                  cols="4"
                  v-for="(auxilary, index) in auxilaryTracksSurfaceData"
                  :key="index"
                >
                  <v-col>
                    <label class="mpms-env-label"
                      ><v-row>
                        <v-col cols="6">
                          <b
                            class="d-flex justify-content-start text-left required"
                            ><span>Track {{ index + 1 }} Name: </span></b
                          >
                        </v-col>
                        <v-col cols="5">
                          <v-text-field
                            :rules="[requiredRule]"
                            v-model="auxilary.trackName"
                            persistent-hint
                            :maxlength="40"
                            outlined
                            :disabled="!isUserGradeCrossingAccess.update"
                          ></v-text-field>
                          <div v-if="!auxilary.trackName">
                            <div class="error">
                              <div>Required</div>
                            </div>
                          </div>
                        </v-col>
                      </v-row></label
                    >
                  </v-col>
                  <v-col>
                    <label class="mpms-env-label"
                      ><v-row>
                        <v-col cols="6">
                          <b
                            class="d-flex justify-content-start text-left required"
                            ><span>Crossing Surface Material: </span></b
                          >
                        </v-col>
                        <v-col cols="5">
                          <div>
                            <v-chip
                              v-for="item in auxilary.sfMaterial"
                              :key="item.surfaceId"
                              class="ma-2"
                              @click:close="removeItem(auxilary, item)"
                              :disabled="!isUserGradeCrossingAccess.update"
                              :rules="[requiredRule]"

                            >
                              {{ item.crossingSrfCode }}
                              <v-icon
                                small
                                right
                                @click="removeItem(auxilary, item, index)"
                                >mdi-close</v-icon
                              >
                            </v-chip>

                            <v-autocomplete
                              v-model="tempVariables[index]"
                              :rules="[requiredRule]"
                              :items="sfMaterials"
                              item-title="crossingSrfCode"
                              item-value="crossingSrfCode"
                              multiple
                              dense
                              return-object
                              @update:modelValue="setSfDetails(auxilary, index)"
                              :no-data-text="AUTO_COMPLETE_NO_DATA"
                              :disabled="!isUserGradeCrossingAccess.update"
                            >
                              <template #selection="{ values }">
                                <v-chip
                                  v-for="(item, index) in values"
                                  :key="index"
                                ></v-chip> </template
                            ></v-autocomplete>

                            <div v-if="!auxilary.sfMaterial">
                              <div class="error">
                                <div>Required</div>
                              </div>
                            </div>
                            <br />
                            <v-text-field
                              v-if="
                                auxilary.sfMaterial &&
                                auxilary.sfMaterial.some(
                                  (a) => a.crossingSrfCode === 'Other (Specify)'
                                )
                              "
                              placeholder="Other (Specify)"
                              :rules="[requiredRule]"
                              v-model="auxilary.surOther"
                              outlined
                            >
                            </v-text-field>
                            <div
                              v-if="
                                auxilary.sfMaterial &&
                                auxilary.sfMaterial.includes(
                                  'Other (Specify)'
                                ) &&
                                !auxilary.sutOrther
                              "
                            >
                              <div class="error">
                                <div>Required</div>
                              </div>
                            </div>
                          </div>
                        </v-col></v-row
                      ></label
                    >
                  </v-col>
                  <v-col>
                    <label class="mpms-env-label"
                      ><v-row>
                        <v-col cols="6">
                          <b
                            class="d-flex justify-content-start text-left required"
                            ><span>Surface Condition: </span></b
                          >
                        </v-col>
                        <v-col cols="5">
                          <v-select
                            :rules="[requiredRule]"
                            v-model="auxilary.surfaceCondition"
                            :items="
                              PennDotCrossingInfo.pennDotCrossingLookupData
                                .surfaceCondition
                            "
                            :disabled="!isUserGradeCrossingAccess.update"
                          ></v-select>
                          <div v-if="!auxilary.surfaceCondition">
                            <div class="error">
                              <div>Required</div>
                            </div>
                          </div></v-col
                        ></v-row
                      ></label
                    >
                  </v-col>
                  <v-col>
                    <label class="mpms-env-label"
                      ><v-row>
                        <v-col cols="6">
                          <b class="d-flex justify-content-start text-left"
                            ><span>Comments: </span></b
                          >
                        </v-col>
                        <v-col cols="5">
                          <v-textarea
                            :maxlength="200"
                            v-model="auxilary.crossingComments"
                            :disabled="!isUserGradeCrossingAccess.update"
                          ></v-textarea> </v-col></v-row
                    ></label>
                  </v-col>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
        <div>
          <div class="env-header mb-20 mt-20">
            <h2 class="locationHeaderText">Advanced Warning Signs</h2>
          </div>
          <div class="env-infp">
            <div class="env-content">
              <v-row>
                <v-col cols="4">
                  <label class="mpms-env-label"
                    ><v-row>
                      <v-col cols="6">
                        <b
                          class="d-flex justify-content-start text-left required"
                          ><span>Advanced Warning Signs Adequate: </span></b
                        >
                      </v-col>
                      <v-col cols="5">
                        <v-select
                          :rules="[requiredRule]"
                          v-model="
                            PennDotCrossingInfo.pennDotCrossingDTO.advWrnSgnAdqe
                          "
                          :items="yesOrNoList"
                          :disabled="!isUserGradeCrossingAccess.update"
                        ></v-select> </v-col></v-row
                  ></label>
                </v-col>
                <v-col cols="4">
                  <label class="mpms-env-label"
                    ><v-row>
                      <v-col cols="6">
                        <b
                          class="d-flex justify-content-start text-left required"
                          ><span>New Signs Required: </span></b
                        >
                      </v-col>
                      <v-col cols="5">
                        <v-select
                          :rules="[requiredRule]"
                          v-model="
                            PennDotCrossingInfo.pennDotCrossingDTO.newSigns
                          "
                          :items="yesOrNoList"
                          :disabled="!isUserGradeCrossingAccess.update"
                        ></v-select> </v-col></v-row
                  ></label>
                </v-col>
                <v-col cols="4">
                  <label class="mpms-env-label"
                    ><v-row>
                      <v-col cols="6">
                        <b
                          class="d-flex justify-content-start text-left required"
                          ><span>Signs to be Relocated: </span></b
                        >
                      </v-col>
                      <v-col cols="5">
                        <v-select
                          :rules="[requiredRule]"
                          v-model="
                            PennDotCrossingInfo.pennDotCrossingDTO
                              .signsRelocated
                          "
                          :items="yesOrNoList"
                          :disabled="!isUserGradeCrossingAccess.update"
                        ></v-select> </v-col></v-row
                  ></label>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
        <div>
          <div class="env-header mb-20 mt-20">
            <h2 class="locationHeaderText">Pavement Markings</h2>
          </div>
          <div class="env-infp">
            <div class="env-content">
              <v-row>
                <v-col cols="4">
                  <label class="mpms-env-label"
                    ><v-row>
                      <v-col cols="6">
                        <b
                          class="d-flex justify-content-start text-left required"
                          ><span>Pavement Markings Adequate: </span></b
                        >
                      </v-col>
                      <v-col cols="5">
                        <v-select
                          :rules="[requiredRule]"
                          v-model="
                            PennDotCrossingInfo.pennDotCrossingDTO.pvmtMrkAdqe
                          "
                          :items="yesOrNoList"
                          :disabled="!isUserGradeCrossingAccess.update"
                        ></v-select> </v-col></v-row
                  ></label>
                </v-col>
                <v-col cols="4">
                  <label class="mpms-env-label"
                    ><v-row>
                      <v-col cols="6">
                        <b class="d-flex justify-content-start text-left"
                          ><span>Date Pavement Markings Replaced: </span></b
                        >
                      </v-col>
                      <v-col cols="5">
                        <DatePicker
                          :value="
                            PennDotCrossingInfo.pennDotCrossingDTO.pvmtMrkDate
                          "
                          :invalidMsg="'Pavement Markings Replaced Date'"
                          @dateValidation="checkDateValidation($event)"
                          @input="pavamentMarkingsReplacedDateChange($event)"
                          :disabled="!isUserGradeCrossingAccess.update"
                        /> </v-col></v-row
                  ></label>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </v-form>
        <div class="d-flex justify-end">
          <PrimaryButton
            class="saveCrossingButton"
            @buttonClicked="saveClicked()"
            :buttonText="'Save'"
            :disabled=' !isUserGradeCrossingAccess.update || !isValueChanged || !checkFormValidation'
          ></PrimaryButton>
          <SecondaryButton
            @buttonClicked="cancelClicked()"
            :buttonText="'Cancel'"
            :disabled="!isUserGradeCrossingAccess.update"

          ></SecondaryButton>
        </div>
    </v-card-text>
  </v-card>
</template>

<script>
import PennDotCrossingInfo from "@/modules/dot-details/scripts/PennDotCrossingInfo";
export default PennDotCrossingInfo;
</script>
<style scoped>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
</style>
