import useFieldEntry from "@/composables/fieldEntry";import {
  minMax15,
  minimumSerialRule,
  mpmsLengthRule,
  requiredRule,
  validDateFutureRule,
} from "@/composables/validationRules";
import { DROPDOWN_NO_DATA } from "@/constants/displayTexts";
import DatePicker from "@/modules/shared/components/DatePicker.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import { useProjectServiceStore } from "@/stores/projectService";
import moment from "moment";
import { computed, reactive, ref, toRefs, watch } from "vue";

export default {
  components: { PrimaryButton, SecondaryButton, DatePicker },
  props: {
    id: { default: null },
    item: Object, // Item to be edited (if in 'edit' mode)
    projectDetails: Object,
    pa1Details: [],
  },
  setup(props) {
    const { keydownAllowNumberOnly } = useFieldEntry();
    let municipalityList = ref([]);
    let countyList = ref([]);
    let projectPhaseList = ref([]);
    let isFormValid = ref(false);
    let temp = ref(null);
    let myMap = new Map();
    let serialInvalidMsg = ref(null);
    let editForm = ref(false);
    let today = moment(new Date()).format("YYYY-MM-DD");
    let originalValue = ref(null);
    const projectServiceStore = useProjectServiceStore();
    const isInitialised = ref(false);
    const valueChanged = ref(true);

    const state = reactive({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      id: null,
      itemIndex: null,
      formattedDate: new Date(),
      formData: null,
    });

    const open = (title, message, formData, id, itemIndex, formattedDate) => {
      serialNumberTouched.value = false;
      serialInvalidMsg.value = "";
      state.dialog = true;
      state.title = title;
      state.message = message;
      state.formData = formData;
      state.id = id;
      state.itemIndex = itemIndex;
      state.formattedDate = formattedDate;

      retrieveMunicipalityCountyList();
      retrieveProjectPhaseList();

      let realNumber = state.formData.pa1CallSerialNum.replace(/-/gi, "");
      let dashedNumber = realNumber.match(/.{1,11}/g);
      if (state.formData.pa1CallSerialNum?.length > 1) {
        state.formData.pa1CallSerialNum = dashedNumber.join("-");
      }
      if (state.message == "Edit") {
        state.formData.pa1CallDate = state.formattedDate.replaceAll("/", "-");
        editForm.value = true;
      } else {
        editForm.value = false;
      }
      state.formattedDate = moment(state.formData.pa1CallDate).format(
        "YYYY-MM-DD"
      );
      originalValue.value = JSON.parse(JSON.stringify(formData));
      let realNumber2 = originalValue.value.pa1CallSerialNum.replace(/-/gi, "");
      let dashedNumber2 = realNumber2.match(/.{1,11}/g);
      if (originalValue.value.pa1CallSerialNum?.length > 1) {
        originalValue.value.pa1CallSerialNum = dashedNumber2.join("-");
      }
      if (state.message == "Edit") {
        originalValue.value.pa1CallDate = state.formattedDate.replaceAll(
          "/",
          "-"
        );
      }
      state.formattedDate = moment(originalValue.value.pa1CallDate).format(
        "YYYY-MM-DD"
      );
      insertDash();
      setTimeout(() => {
        isInitialised.value = true;
      }, 1000);
      return new Promise((resolve, reject) => {
        state.resolve = resolve;
        state.reject = reject;
      });
    };

    async function retrieveMunicipalityCountyList() {
      let response = projectServiceStore.countyMunicipalityMap;
      if (!response) {
        await projectServiceStore.retrieveMunicipalityCountyList();
        response = projectServiceStore.countyMunicipalityMap;
      }

      countyList.value = response.data;
      countyList.value?.sort((a, b) => {
        if (a?.countyName?.toLowerCase() < b?.countyName?.toLowerCase())
          return -1;
        if (a?.countyName?.toLowerCase() > b?.countyName?.toLowerCase())
          return 1;
        return 0;
      });
      if (state.formData.countyCode || state.formData.muniCode) {
        let Index = response.data.findIndex(
          (county) => county.countyId === state.formData.countyCode
        );
        if (Index >= 0) {
          municipalityList.value = response.data[Index].municipalities;
          sortMunicipality();
        } else {
          municipalityList.value = response.data[0].municipalities;
          sortMunicipality();
        }
      } else {
        municipalityList.value = response.data[0].municipalities;
        sortMunicipality();
      }
    }
    function sortMunicipality() {
      municipalityList.value?.sort((a, b) => {
        if (
          a?.municipalityName?.toLowerCase() <
          b?.municipalityName?.toLowerCase()
        )
          return -1;
        if (
          a?.municipalityName?.toLowerCase() >
          b?.municipalityName?.toLowerCase()
        )
          return 1;
        return 0;
      });
    }
    const retrieveMunicipalityList = (selectedCounty) => {
      let Index = countyList.value.findIndex(
        (county) => county.countyId === selectedCounty
      );
      if (Index >= 0) {
        state.formData.muniCode = "";
        municipalityList.value = countyList.value[Index].municipalities;
      }
    };
    async function retrieveProjectPhaseList() {
      let response = projectServiceStore.projectPhaseList;
      if (!response) {
        await projectServiceStore.retrieveProjectPhaseList();
        response = projectServiceStore.projectPhaseList;
      }
      projectPhaseList.value = response.data.map((item) => item.projectPhase);
      projectPhaseList.value?.sort((a, b) => {
        if (a?.toLowerCase() < b?.toLowerCase()) return -1;
        if (a?.toLowerCase() > b?.toLowerCase()) return 1;
        return 0;
      });
      response.data.map((item) =>
        myMap.set(item.projectPhase, item.projectPhaseDescId)
      );
    }
    const format = (inputDate) => {
      let date = new Date(inputDate + "EDT");
      if (!isNaN(date.getTime())) {
        // Months use 0 index.

        let monthIn2Digit = String(date.getMonth() + 1).padStart(2, "0");
        let dateIn2Digit = String(date.getDate()).padStart(2, "0");
        return monthIn2Digit + "/" + dateIn2Digit + "/" + date.getFullYear();
      }
    };
    const saveData = () => {
      state.formData.pa1CallDate = state.formattedDate;
      state.formData.pa1CallDate = format(state.formData.pa1CallDate);
      let tempInt = state.itemIndex;
      temp.value = {
        pa1CallSerialNum: state.formData.pa1CallSerialNum,
        pa1CallDate: state.formData.pa1CallDate,
        projectPhase: state.formData.projectPhase,
        countyName: state.formData.countyName,
        municipalityName: state.formData.municipalityName,
        mpmsNumber: state.formData.mpmsNumber,
      };
      state.resolve({
        temp: temp.value,
        index: tempInt,
      });
      state.dialog = false;
    };
    async function PostOrPutData() {
      state.formData.pa1CallDate = moment(state.formattedDate)
        .add(1, "d")
        .format("MM/DD/YYYY");
      state.formData.pa1CallSerialNum = state.formData.pa1CallSerialNum.replace(
        "-",
        ""
      );
      if (state.message === "Add") {
        let object = {
          pa1CallSerialNum: state.formData.pa1CallSerialNum,
          pa1CallDate: state.formData.pa1CallDate,
          projectPhase: state.formData.projectPhase,
          projectPhaseDescId: myMap.get(state.formData.projectPhase),
          countyCode: state.formData.countyCode,
          muniCode: state.formData.muniCode,
          mpmsNumber: state.id,
        };
        let response = await projectServiceStore.addPA1Call(state.id, object);

        if (response) {
          state.resolve(response.data);
          let realNumber = state.formData.pa1CallSerialNum.replace(/-/gi, "");
          let dashedNumber = realNumber.match(/.{1,11}/g);

          // Replace the dashed number with the real one
          if (state.formData.pa1CallSerialNum?.length > 1) {
            state.formData.pa1CallSerialNum = dashedNumber.join("-");
          }
          state.dialog = false;
          editForm.value = false;
        }
      } else if (state.message === "Edit") {
        let object = {
          pa1CallSerialNum: state.formData.pa1CallSerialNum,
          pa1CallDate: state.formData.pa1CallDate,
          projectPhase: state.formData.projectPhase,
          projectPhaseDescId: myMap.get(state.formData.projectPhase),
          countyName: state.formData.countyName,
          municipalityName: state.formData.municipalityName,
          countyCode: state.formData.countyCode,
          muniCode: state.formData.muniCode,
          mpmsNumber: state.formData.mpmsNumber,
        };
        let response = await projectServiceStore.updatePA1Call(
          state.id,
          object
        );

        if (response) {
          state.resolve(response.data);
          state.dialog = false;
          editForm.value = false;
        }
      }
    }
    const closeDialog = () => {
      if (isInitialised.value && valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          state.resolve(false);
          state.dialog = false;
          serialNumberTouched.value = false;
          serialInvalidMsg.value = "";
          resetForm();
        }
      } else {
        state.resolve(false);
        state.dialog = false;
        serialNumberTouched.value = false;
        serialInvalidMsg.value = "";
        resetForm();
      }
    };
    watch(
      () => [state?.formData?.pa1CallSerialNum],
      () => {
        if (state?.formData?.pa1CallSerialNum) {
          insertDash();
        }
      },
      { deep: true }
    );
    const objectsAreSame = (x, y) => {
      let objectsAreSame = true;
      let checkKeys = [
        "pa1CallSerialNum",
        "pa1CallDate",
        "countyName",
        "municipalityName",
        "projectPhase",
      ];
      for (const key of checkKeys) {
        if (
          (key === "pa1CallDate" &&
            moment(state.formattedDate).format("YYYY-MM-DD") !==
              moment(y["pa1CallDate"]).format("YYYY-MM-DD")) ||
          x[key] !== y[key]
        ) {
          objectsAreSame = false;
          break;
        }
      }
      return objectsAreSame;
    };
    watch(
      () => [state?.formData, state.formattedDate],
      () => {
        if (
          isInitialised.value &&
          !objectsAreSame(state?.formData, originalValue.value)
        ) {
          valueChanged.value = true;
        } else {
          valueChanged.value = false;
        }
      },
      { deep: true }
    );
    const afterModalLeave = () => {
      valueChanged.value = false;
      isInitialised.value = false;
      originalValue.value = null;
      resetForm();
    };
    window.onbeforeunload = function () {
      if (isInitialised.value && valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };

    const insertDash = () => {
      if (
        state.formData.pa1CallSerialNum?.length >= 11 &&
        state.formData.pa1CallSerialNum[11] != "-"
      ) {
        state.formData.pa1CallSerialNum =
          state.formData.pa1CallSerialNum.replace("-", "");
        state.formData.pa1CallSerialNum =
          state.formData.pa1CallSerialNum.slice(0, 11) +
          "-" +
          state.formData.pa1CallSerialNum.slice(11);
      } else {
        state.formData.pa1CallSerialNum.replace("-", "");
      }

      if (
        originalValue.value.pa1CallSerialNum?.length >= 11 &&
        originalValue.value.pa1CallSerialNum[11] != "-"
      ) {
        originalValue.value.pa1CallSerialNum =
          originalValue.value.pa1CallSerialNum.replace("-", "");
        originalValue.value.pa1CallSerialNum =
          originalValue.value.pa1CallSerialNum.slice(0, 11) +
          "-" +
          originalValue.value.pa1CallSerialNum.slice(11);
      } else {
        originalValue.value.pa1CallSerialNum.replace("-", "");
      }
    };
    const serialNumberTouched = ref(false);

    const validateSerialNumber = () => {
      if (!state.formData.pa1CallSerialNum) {
        serialInvalidMsg.value = "Required";
      } else if (state.formData.pa1CallSerialNum?.length != 15) {
        serialInvalidMsg.value = "Serial # must be 14 digits.";
      } else {
        serialInvalidMsg.value = "";
      }
      serialNumberTouched.value = true;
    };

    const resetForm = () => {
      // validateSerialNumber the form data
      state.formData = {
        pa1CallSerialNum: "",
        pa1CallDate: "",
        projectPhase: "",
        countyName: "",
        municipalityName: "",
      };
      state.formattedDate = "";
    };
    const dateChange = (event) => {
      if (event?.target?.value) {
        state.formattedDate = event.target.value;
      } else if (event) {
        state.formattedDate = event;
      }
    };
    const isDateValidated = ref(false);
    const checkDateValidation = (value) => {
      isDateValidated.value = value;
    };
    const checkFormValidation = computed({
      get: () => {
        return isFormValid.value && isDateValidated.value;
      },
    });

    return {
      ...toRefs(state),
      dateChange,
      checkDateValidation,
      checkFormValidation,
      isDateValidated,
      isFormValid,
      keydownAllowNumberOnly,
      minimumSerialRule,
      format,
      saveData,
      closeDialog,
      PostOrPutData,
      open,
      requiredRule,
      minMax15,
      retrieveMunicipalityCountyList,
      retrieveMunicipalityList,
      retrieveProjectPhaseList,
      validateSerialNumber,
      municipalityList,
      countyList,
      projectPhaseList,
      resetForm,
      editForm,
      mpmsLengthRule,
      insertDash,
      serialInvalidMsg,
      serialNumberTouched,
      today,
      validDateFutureRule,
      DROPDOWN_NO_DATA,
      projectServiceStore,
      afterModalLeave,
    };
  },
};
