<template>
  <div v-if="showBanner">
    <BannerMessage
      :successBanner="true"
      :message="bannerMessage"
      @hideBanner="hideBannerMessage"
    ></BannerMessage>
  </div>
  <div>
    <v-row>
      <v-col class="ml-20">
        <router-link :to="`/GCMS/${id}`">
          &lt; Return to Project Information Page
        </router-link>
      </v-col>
      <v-col class="page-title">
        <h1>Project Documents</h1>
      </v-col>
      <v-col> </v-col>
    </v-row>
    <v-grid>
      <v-row justify="center">
        <v-col cols="11">
          <DocumentListWithFilter
            :heading="'Project Documents List'"
            :doctype="'/PD_VIEW_DT'"
            :id="id"
            :updateDocumentList="updateDocumentList"
            :lookupData="lookupData"
            @updateDocumentListFlag="updateDocumentListFlag"
            @otherButtonClicked="updateButtonClicked($event)"
          >
          </DocumentListWithFilter>
        </v-col>
      </v-row>
    </v-grid>
    <br />
    <v-grid v-if="isProjectDocumentUpdateAccess">
      <v-row justify="center">
        <v-col cols="11">
          <h2
            class="f-size18 f-montserrat text-left mt-50"
            style="font-size: 18px; padding-bottom: 0.5em; color: #003e7e"
          >
            Upload Documents
          </h2>
          <p class="text-left" style="padding-bottom: 1px; padding-left: 10px">
            Maximum allowable file size is 500 MB.
          </p>
          <UploadScopingFieldViewDocument
            v-if="!UpdateModalOpened"
            @uploadFilesCall="uploadFiles"
            :isDynamicDocumentType="true"
            :isRailroadDropdownDisabled="true"
            :showPreviewRailroadColumn="true"
            :checkMaximumFilesUploaded="true"
            :checkMaxFileSize="true"
            :previewDocTypeUrl="'UPLOAD_PROJECT_DOCUMENTS_DOCTYPE_LIST'"
            :mpmsId="id"
            :isPageUpload="true"
            :showPreviewDOTColumn="true"
          ></UploadScopingFieldViewDocument>
        </v-col>
      </v-row>
    </v-grid>
    <br />
  </div>
  <LoadingOverlay :isLoading="loading"> </LoadingOverlay>
  <DialogComponent ref="railroadEstimatesDocumentsDialogRef"></DialogComponent>
</template>
<script>
import ProjectDocuments from "@/modules/manageproject/scripts/ProjectDocuments.js";
export default ProjectDocuments;
</script>
<style>
@import "@/modules/projectinformation/assets/projectinfostyle.scss";
@import "@/assets/css/style.scss";
</style>