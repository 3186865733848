

<template>
    <div class="env-infp mb-20">
      <div class="env-header">
        <h2 class="locationHeaderText">Users</h2>
      </div>
    </div>

      <div class="mt-20">
        <v-card class="p-64">
      <VueTable
        :tableData="userInfo"
        :columnData="columnData"
        :showAddRow="false"
        :showExportButton="true"
        :errorMessage="errorMessage"
        :exportFilName="'Consulting Company Users'"
      >
      </VueTable>
    </v-card>
      </div>

      <br><br><br>



  </template>
  
  <script>
  import ConsultingCompanyUsers from "@/modules/gcmsmain/admin/submenu/scripts/ConsultingCompanyUsers.js";
  export default ConsultingCompanyUsers;
  </script>

  