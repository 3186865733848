import { requiredRule, zipRule } from "@/composables/validationRules";import { computed, ref } from "vue";
export default {
  name: "ZIPInput",
  props: { modelValue: { default: "" } },
  setup(props, context) {
    const stoppedTyping = ref(false);
    const computedValue = computed({
      get: () => {
        if (!props.modelValue) {
          return "";
        }

        if (props.modelValue.length == 6 && !props.modelValue.includes("-")) {
          return (
            props.modelValue.slice(0, 5) + "-" + props.modelValue.slice(5, 6)
          );
        }
        return props.modelValue;
      },

      set: (modifiedValue) => {
        context.emit("update:modelValue", modifiedValue);
      },
    });
    const getZipFieldRule = computed({
      get: () => {
        return stoppedTyping.value;
      },
    });
    const fieldValueChange = () => {
      stoppedTyping.value = false;
    };
    const setFieldRule = () => {
      stoppedTyping.value = true;
    };

    function createDebounce() {
      let timeout = null;
      return function (fnc, delayMs) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fnc();
        }, delayMs || 1000);
      };
    }

    return {
      computedValue,

      fieldValueChange,
      setFieldRule,
      getZipFieldRule,
      requiredRule,
      zipRule,
      debounce: createDebounce(),
    };
  },
};
