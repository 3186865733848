import axios from "axios";
import { defineStore } from "pinia";
import { ref } from "vue";
import { CONFIG_ENDPOINT } from "../constants/Endpoints";
export const useSignatureStore = defineStore("signature", () => {
  const LookupData = ref();

  const getLookup = async () => {
    try {
      const response = await axios.get(
        CONFIG_ENDPOINT.GET_SIGNATURE_ADD_EDIT_LOOKUP
      );
      LookupData.value = response.data;
      return response;
    } catch (error) {
      return null;
    }
  };
  return {
    LookupData,
    getLookup,
  };
});
