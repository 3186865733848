import {
  numericRule,
  reasonForUpdateRequiredRule,
  requiredRule,
} from "@/composables/validationRules";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import ReasonForUpdate from "@/modules/dot-details/components/ReasonForUpdate.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import sharedUserService from "@/modules/shared/service/sharedUserService";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import { computed, onMounted, ref, watch } from "vue";

import { DROPDOWN_NO_DATA } from "@/constants/displayTexts";
let crossingOnStateHighwaySystem = ref([]);
let emergencyServicesRoute = ref([]);
let functionalClassificationOfRoad = ref([]);
let functionalClassificationOfRoadArea = ref([]);
let highwaySpeedLimitStatus = ref([]);
let highwaySystem = ref([]);
let reasonForUpdate = ref([]);
let reasonToUpdateList = ref([]);
let regularlyUsedBySchoolBuses = ref([]);
let PartVDetails = ref(null);
let isFormValid = ref(false);
let updatedDataFlagger = ref(false);
let initialLoad = ref(false);

export default {
  name: "PartVPublicHwyInfo",
  emits: ["showBanner"],
  props: {
    dotId: { default: null },
  },
  components: { PrimaryButton, SecondaryButton, ReasonForUpdate },
  setup(props, context) {
    const dotId = computed({
      get: () => {
        return props?.dotId;
      },
    });
    const isInitialised = ref(false);
    const valueChanged = ref(false);
    let originalValue = ref(null);
    const isUserGradeCrossingAccess = ref({
      read: false,
      update: false,
    });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const gradeCrossingAccessCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.GRADE_CROSSING_RECORD_READ_ACCESS
      );
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.GRADE_CROSSING_RECORD_CRTUPD_ACCESS
      );
      isUserGradeCrossingAccess.value.read = readResponse || false;
      isUserGradeCrossingAccess.value.update = updateResponse || false;
    };

    onMounted(async () => {
      await gradeCrossingAccessCheck();
      if (isUserGradeCrossingAccess.value.read) {
        await getPartVDetails();
      }
    });
    const userData = computed({
      get: () => {
        return sharedUserService.getSharedData();
      },
    });
    async function getPartVDetails() {
      await axios
        .get(CONFIG_ENDPOINT.PARTV + dotId.value)
        .then((response) => {
          PartVDetails.value = response?.data;
          PartVDetails.value.gradeCrossingPartFiveDTO.reasonForUpdate =
            response?.data?.gradeCrossingPartFiveDTO.reasonForUpdate;

          reasonToUpdateList.value = response?.data?.lookupData.reasonForUpdate;

          crossingOnStateHighwaySystem.value =
            PartVDetails.value.lookupData.crossingOnStateHighwaySystem;

          emergencyServicesRoute.value =
            PartVDetails.value.lookupData.emergencyServicesRoute;

          functionalClassificationOfRoad.value =
            PartVDetails.value.lookupData.functionalClassificationOfRoad;

          functionalClassificationOfRoadArea.value =
            PartVDetails.value.lookupData.functionalClassificationOfRoadArea;

          highwaySpeedLimitStatus.value =
            PartVDetails.value.lookupData.highwaySpeedLimitStatus;

          highwaySystem.value =
            PartVDetails.value.lookupData.highwaySystem.sort();

          regularlyUsedBySchoolBuses.value =
            PartVDetails.value.lookupData.regularlyUsedBySchoolBuses;
          initialLoad.value = true;

          originalValue.value = JSON.parse(
            JSON.stringify({ ...PartVDetails.value })
          );

          setTimeout(() => {
            isInitialised.value = true;
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    const handleData = () => {
      let tempUpdateReason =
        PartVDetails.value.lookupData.reasonForUpdate.indexOf(
          PartVDetails.value.gradeCrossingPartFiveDTO.reasonForUpdate
        ) + 14;
      let responseObject = {
        aadtYear: PartVDetails.value.gradeCrossingPartFiveDTO.aadtYear,
        annualAverageDailyTraffic:
          PartVDetails.value.gradeCrossingPartFiveDTO.annualAverageDailyTraffic,
        averageNumberOfBusesPerDay:
          PartVDetails.value.gradeCrossingPartFiveDTO
            .averageNumberOfBusesPerDay,
        crossingOnStateHighwaySystem:
          PartVDetails.value.gradeCrossingPartFiveDTO
            .crossingOnStateHighwaySystem,
        emergencyServicesRoute:
          PartVDetails.value.gradeCrossingPartFiveDTO.emergencyServicesRoute,
        estimatedPercentTrucks:
          PartVDetails.value.gradeCrossingPartFiveDTO.estimatedPercentTrucks,
        functionalClassificationOfRoad:
          PartVDetails.value.gradeCrossingPartFiveDTO
            .functionalClassificationOfRoad,
        functionalClassificationOfRoadArea:
          PartVDetails.value.gradeCrossingPartFiveDTO
            .functionalClassificationOfRoadArea,
        highwaySpeedLimitMPH:
          PartVDetails.value.gradeCrossingPartFiveDTO.highwaySpeedLimitMPH,
        highwaySpeedLimitStatus:
          PartVDetails.value.gradeCrossingPartFiveDTO.highwaySpeedLimitStatus,
        highwaySystem:
          PartVDetails.value.gradeCrossingPartFiveDTO.highwaySystem,
        lrsMilepost: PartVDetails.value.gradeCrossingPartFiveDTO.lrsMilepost,
        lrsRouteID: PartVDetails.value.gradeCrossingPartFiveDTO.lrsRouteID,
        reasonForUpdate:
          PartVDetails.value.gradeCrossingPartFiveDTO.reasonForUpdate,
        regularlyUsedBySchoolBuses:
          PartVDetails.value.gradeCrossingPartFiveDTO
            .regularlyUsedBySchoolBuses,
        userID: userData.value?.userID,
      };
      return responseObject;
    };

    const saveClicked = () => {
      let returnObject = handleData();
      axios
        .put(CONFIG_ENDPOINT.PARTV + props.dotId, returnObject)
        .then(() => {
          isInitialised.value = false;
          valueChanged.value = false;
          context.emit("ValueChanged", valueChanged.value);
          context.emit("showBanner", 'Saved Successfully.');
          getPartVDetails();
        })
        .catch((err) => {
          context.emit("showBanner", err.response.data.businessMessage);
        });
    };
    const cancelClicked = () => {
      isInitialised.value = false;
      valueChanged.value = false;
      context.emit("ValueChanged", valueChanged.value);
      getPartVDetails();
      updatedDataFlagger.value = false;
    };

    const valueChange = (value) => {
      PartVDetails.value.gradeCrossingPartFiveDTO.reasonForUpdate = value;
    };
    function isLeftUpRightDownBackspaceDeleteTab(event) {
      let key = event.key || event.code;
      return (
        key === "ArrowLeft" ||
        key === 37 ||
        key === "ArrowUp" ||
        key === 38 ||
        key === "ArrowRight" ||
        key === 39 ||
        key === "ArrowDown" ||
        key === 40 ||
        key === "Backspace" ||
        key === 8 ||
        key === "Delete" ||
        key === 46 ||
        key === "Tab" ||
        key === 9
      );
    }

    function keydownAllowNumberOnly(event) {
      if (isLeftUpRightDownBackspaceDeleteTab(event)) {
        return;
      }

      let regex = /\d/;

      if (!regex.test(event.key)) {
        event.preventDefault();
        return false;
      }
    }

    function allowAlphanumericOnly(event) {
      const blockSpecialCharacterRegex = /^[a-z0-9\s]+$/i;
      let key = event.key ? event.key : String.fromCharCode(event.code);
      if (!blockSpecialCharacterRegex.test(key)) {
        event.preventDefault();
        return false;
      }
    }

    watch(
      () => [PartVDetails.value],
      () => {
        if (initialLoad.value) {
          initialLoad.value = false;
        } else {
          updatedDataFlagger.value = true;
        }
        if (isInitialised.value && originalValue.value != PartVDetails.value) {
          valueChanged.value = true;
          context.emit("ValueChanged", valueChanged.value);
        } else {
          valueChanged.value = false;
          context.emit("ValueChanged", valueChanged.value);
        }
      },
      { deep: true }
    );
    const updateRegularlyUsedBySchoolBuses = (event) => {
      if (event === "No") {
        PartVDetails.value.gradeCrossingPartFiveDTO.averageNumberOfBusesPerDay =
          "";
      }
    };
    return {
      dotId,
      PartVDetails,
      isFormValid,
      saveClicked,
      requiredRule,
      numericRule,
      crossingOnStateHighwaySystem,
      emergencyServicesRoute,
      functionalClassificationOfRoad,
      functionalClassificationOfRoadArea,
      highwaySpeedLimitStatus,
      highwaySystem,
      reasonForUpdate,
      regularlyUsedBySchoolBuses,
      cancelClicked,
      ReasonForUpdate,
      reasonForUpdateRequiredRule,
      initialLoad,
      updatedDataFlagger,
      valueChange,
      reasonToUpdateList,
      handleData,
      keydownAllowNumberOnly,
      isLeftUpRightDownBackspaceDeleteTab,
      allowAlphanumericOnly,
      DROPDOWN_NO_DATA,
      valueChanged,
      updateRegularlyUsedBySchoolBuses,
      isUserGradeCrossingAccess,
    };
  },
};
