<template>
  <div class="expanderOverLay">
    <ManageConsultingCompanies></ManageConsultingCompanies>
  </div>
</template>
<script>
import ManageConsultingCompanies from "@/modules/gcmsmain/admin/submenu/components/ManageConsultingCompanies.vue";
export default {
  name: "ViewManageConsultingCompanies",
  components: {
    ManageConsultingCompanies: ManageConsultingCompanies,
  },
};
</script>