import useIconsFunctions from "@/composables/projectIdentifiersIcons";import { onMounted, ref } from "vue";
export default {
  props: {
    showHistorical: { type: Boolean, default: false },
  },
  setup(props) {
    const { getIdentifierIcon } = useIconsFunctions();
    const projectIdentifierList = ref([
      "Highway Bridge Project",
      "Safety Project",
      "Emergency Project",
      "Critical Project",
      "Local Project",
      "P3 Project",
    ]);
    /* "Design-Build Project ", */
    onMounted(() => {
      if (props?.showHistorical) {
        projectIdentifierList.value.push("Historical");
      }
    });
    return { projectIdentifierList, getIdentifierIcon };
  },
};
