import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import { reactive, toRefs } from "vue";
export default {
  name: "SharedConfirmationDialog",
  components: { PrimaryButton, SecondaryButton },
  setup() {
    const state = reactive({
      dialog: false,
      resolve: null,
      reject: null,
      heading: null,
      description: null,
      maxWidth: null,
      hideCancelButton: false,
    });
    const open = (heading, description, maxWidth, hideCancelButton) => {
      state.dialog = true;
      state.heading = heading;
      state.description = description;
      state.maxWidth = maxWidth ? Number(maxWidth) : null;
      state.hideCancelButton = hideCancelButton;

      return new Promise((resolve, reject) => {
        state.resolve = resolve;
        state.reject = reject;
      });
    };
    const handleYes = () => {
      state.resolve(true);
      state.dialog = false;
    };

    const handleNo = () => {
      state.resolve(false);
      state.dialog = false;
    };

    return {
      ...toRefs(state),
      open,
      handleYes,
      handleNo,
    };
  },
};
