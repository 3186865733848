<template>
  <div class="expanderOverLay">
    <ManageRailroadCompanies></ManageRailroadCompanies>
  </div>
</template>
<script>
import ManageRailroadCompanies from "@/modules/gcmsmain/admin/submenu/components/ManageRailroadCompanies.vue";
export default {
  name: "ViewManageRailroadCompanies",
  components: {
    ManageRailroadCompanies: ManageRailroadCompanies,
  },
};
</script>
