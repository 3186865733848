import IconButton from "@/modules/shared/components/IconButton.vue";import { computed, ref } from "vue";
import { TABLE_NO_DATA_AVAILABLE } from "../../../constants/displayTexts";
export default {
  name: "DataTableRowHandler",
  components: { IconButton },
  props: ["dragKey", "list", "tableHeader", "dragDisableCheck", "isFirstList"],
  setup(props, context) {
    const isFirstList = ref(props?.isFirstList);
    let dragDisableCheck = computed({
      get: () => {
        return props?.dragDisableCheck || [];
      },
    });
    const hideWithinVicinityDeleteButton = (item) => {
      return item?.["sourceid"] === "Within Vicinity";
    };
    const iconButtonsDisabled = (item) => {
      if (item?.["sourceid"] === "MPMS") {
        return false;
      }
      let disableCheck = null;
      dragDisableCheck.value.map((dragOption) => {
        let isDisabled = false;
        if (dragOption.key === "sourceid") {
          isDisabled = !dragOption.value.includes(item?.[dragOption.key]);
        } else {
          isDisabled = item?.[dragOption.key] === dragOption.value;
        }
        if (disableCheck == null) {
          disableCheck = isDisabled;
        } else {
          disableCheck = disableCheck && isDisabled;
        }
      });
      return disableCheck;
    };
    const checkDraggable = (item) => {
      let dragCheck = null;
      dragDisableCheck.value.map((dragOption) => {
        let isDraggable = false;
        if (dragOption.key === "sourceid") {
          isDraggable = dragOption.value.includes(item?.[dragOption.key]);
        } else {
          isDraggable = item?.[dragOption.key] === dragOption.value;
        }

        if (dragCheck == null) {
          dragCheck = isDraggable;
        } else {
          dragCheck = dragCheck && isDraggable;
        }
      });
      return dragCheck;
    };
    const snackbar = ref(false);
    const doubleClickEvent = (value) => {
      console.log("doubleClickEvent", value);
      navigator.clipboard.writeText(value);
      snackbar.value = true;
    };
    return {
      iconButtonsDisabled,
      dragDisableCheck,
      checkDraggable,
      TABLE_NO_DATA_AVAILABLE,
      hideWithinVicinityDeleteButton,
      doubleClickEvent,
      snackbar,
      isFirstList,
    };
  },
};
