import LoaderImage from "@/assets/images/ajax-loader1.gif";import { computed } from "vue";
export default {
  name: "LoadingOverlay",
  props: ["isLoading"],
  setup(props, context) {
    const isLoading = computed({
      get: () => {
        return props?.isLoading;
      },
    });
    return {
      isLoading,
      LoaderImage,
    };
  },
};
