<template>
  <div
    v-if="projectDetails?.keyMPMSfieldsMessage"
    class="railroadError"
    style="width: 100%; margin: 0"
  >
    <v-icon icon="mdi-alert"></v-icon>
    {{ projectDetails.keyMPMSfieldsMessage }}
  </div>
  <div
    v-if="
      !projectDetails?.federalProjectStatus &&
      projectDetails?.fedStatusMessage !== null
    "
    class="railroadError"
    style="width: 100%; margin: 0"
    :class="{ 'mt-2': projectDetails?.fedStatusMessage }"
  >
    <v-icon icon="mdi-alert"></v-icon>
    {{ projectDetails?.fedStatusMessage }}
  </div>
  <v-card-text>
    <div class="allProjectsHeading , mb-5">
      Create New Grade Crossing Project
    </div>

    <v-card>
      <v-tabs v-model="tab" dark align-tabs="start">
        <v-tab value="projectInformation">Project Information</v-tab>
        <v-tab value="locationInformation">Location Information</v-tab>
      </v-tabs>

      <v-card-text>
        <v-window v-model="tab">
          <v-window-item value="projectInformation">
            <MpmsEnvironmentInfo
              :id="this.projectId"
              :create="true"
            ></MpmsEnvironmentInfo>
          </v-window-item>
          <v-window-item value="locationInformation">
            <MpmsLocationInfo :id="this.projectId"></MpmsLocationInfo>
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>
    <div>
      <v-card-actions>
        <div class="action">
          <SecondaryButton
            @buttonClicked="$router.back()"
            :buttonText="'Cancel'"
            class="ml-10 pr-5"
          ></SecondaryButton>
          <PrimaryButton
            @buttonClicked="saveData()"
            :buttonText="'Create Project'"
          ></PrimaryButton>
        </div>
      </v-card-actions>
    </div>
  </v-card-text>
</template>

<script>
import createProjectTabs from "../scripts/createProjectTabs.js";
export default createProjectTabs;
</script>
