<template>
    <div class="expanderOverLay">
      <ConsultingCompany></ConsultingCompany>
    </div>
  </template>
  <script>
  import ConsultingCompany from "@/modules/gcmsmain/admin/submenu/components/ConsultingCompany.vue";
  export default {
    name: "ViewConsultingCompany",
    components: {
        ConsultingCompany: ConsultingCompany,
    },
  };
  </script>
  