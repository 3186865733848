
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import CurrencyInput from "@/modules/shared/components/CurrencyInput.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import axios from "axios";
import { onMounted, reactive, ref, toRefs, watch } from "vue";
import { useRoute } from "vue-router";



  export default {
    components: { PrimaryButton, SecondaryButton, CurrencyInput },
    props: {
      formData: {
        type: Object,
        default: {
        contractorName: "",
        contractorCost: "",
        contractorId: "",
        railroadCompanyId: "",
        },
      },
      id: { type: String, default: null },
      item: Object, // Item to be edited (if in 'edit' mode)    projectDetails: Object,
      itemIndex: "",
    },
    setup() {
      const route = useRoute();
      let valueChanged = ref(false);
      const originalValue = ref();
      const isInitialised = ref(false);
      const id = route.params.id;
      const showError = ref(false);
      const state = reactive({
        dialog: false,
        resolve: null,
        reject: null,
        message: null,
        title: null,
        itemIndex: null,
        formData: "OK",
      });
      onMounted(async () => {
       
      });

      watch(
        () => [state.formData.contractorCost],
        () => {
          if (typeof state.formData.contractorCost === 'string'){
            console.log('string');
          }
          if (state.formData.contractorCost > 999999999.99){
            showError.value = true;
          }
          else{
            showError.value = false;
          }
        },
        { deep: true }
      );
    
    
  
      const open = (title, formData) => {
        state.dialog = true;
        state.title = title;
        state.formData = formData;
        console.log(state.formData);

        return new Promise((resolve, reject) => {
          state.resolve = resolve;
          state.reject = reject;
        });
      };
     

      const saveData = () => {

        if (state.title == "Add Awarded Contractor"){
          console.log('add');
          axios
          .post(CONFIG_ENDPOINT.CONTRACTOR_BID_POST + state.formData.railroadCompanyId + "/"+ id, 
          {
            "contractorBidId": null,
            "awardedContractorName": state.formData.contractorName,
            "awardedCost": state.formData.contractorCost
        })
          .then((response) => {
              console.log(response);
              state.resolve({
                type: state.message,
                data: {
                  ...state.formData,
                },
              });
    
              state.dialog = false;      
          
          })
          .catch((err) => {
              console.log(err);
          });
        } else{
          console.log('edit');
          axios
          .put(CONFIG_ENDPOINT.CONTRACTOR_BID_PUT + state.formData.railroadCompanyId + "/"+ id, 
            {
              "contractorBidId": state.formData.contractorId,
              "awardedContractorName": state.formData.contractorName,
              "awardedCost": state.formData.contractorCost
          })
          .then((response) => {
              console.log(response);
              state.resolve({
                type: state.message,
                data: {
                  ...state.formData,
                },
              });
    
              state.dialog = false;      
          
          })
          .catch((err) => {
              console.log(err);
          });
        }

       
        };

      const closeDialog = () => {
        if (isInitialised.value && valueChanged.value) {
          const answer = window.confirm(
            "You have unsaved data. Are you sure to leave the page?"
          );
          if (answer) {
            valueChanged.value = false;
            state.resolve(false);
            state.dialog = false;
          }
        } else {
          valueChanged.value = false;
          state.resolve(false);
          state.dialog = false;
        }
      };
      const afterModalLeave = () => {
        valueChanged.value = false;
        isInitialised.value = false;
        originalValue.value = null;
      };
      window.onbeforeunload = function () {
        if (isInitialised.value && valueChanged.value) {
          return "You have unsaved data. Are you sure to leave the page?";
        }
      };
      return {
        ...toRefs(state),
        saveData,
        closeDialog,
        open,
        afterModalLeave,
        id,
        showError,
      };
    },
  };
  