import { CONFIG_ENDPOINT } from "@/constants/Endpoints";import VueTable from "@/modules/shared/components/VueTable.vue";
import axios from "axios";
import { onMounted, ref, watch } from "vue";

const estimatedProjectFunding = ref([]);
const columnData = ref([
  {
    name: "Phase",
    key: "mpmsPhaseCode",
    isBold: true,
    alignLeft: true,
    columnWidth: "10",
  },
  { name: "Total", key: "totalPhaseCost", columnWidth: "18" },
  {
    name: "Federal",
    key: "fedPhaseCost",
    secondLineKey: "fedPhaseCostPercent",
    columnWidth: "18",
  },
  {
    name: "State",
    key: "statePhaseCost",
    secondLineKey: "statePhaseCostPercent",
    columnWidth: "18",
  },
  {
    name: "Local",
    key: "localPhaseCost",
    secondLineKey: "localPhaseCostPercent",
    columnWidth: "18",
  },
  {
    name: "Other",
    key: "otherPhaseCost",
    secondLineKey: "otherPhaseCostPercent",
    columnWidth: "18",
  },
]);

export default {
  name: "EstimatedProjectFundingInfo",
  components: { VueTable },
  props: {
    id: { type: Number, default: null },
  },
  setup(props) {
    onMounted(async () => {
      await getEstimatedProjectFundingData();
    });
    watch(
      () => [props.id],
      () => {
        if (props.id) {
          getEstimatedProjectFundingData();
        }
      },
      { deep: true }
    );
    async function getEstimatedProjectFundingData() {
      await axios
        .get(
          CONFIG_ENDPOINT.COSTANDFUNDING_ESTIMATED_PROJECT_FUNDING_INFO +
            props.id
        )
        .then((response) => {
          estimatedProjectFunding.value = response.data;

          estimatedProjectFunding.value.forEach((project, index) => {
            estimatedProjectFunding.value[index] = {
              ...project,
              totalPhaseCost:
                project?.fedPhaseCost +
                project?.statePhaseCost +
                project?.localPhaseCost +
                project?.otherPhaseCost,
            };
          });
          setEstimatedProjectFundingCosts();
        })
        .catch((err) => {
          estimatedProjectFunding.value = [];
        });
    }
    function setEstimatedProjectFundingCosts() {
      estimatedProjectFunding.value.forEach((project, index) => {
        estimatedProjectFunding.value[index] = {
          ...project,
          totalPhaseCost:
            "$" +
            parseFloat(project?.totalPhaseCost || 0)
              ?.toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          fedPhaseCost:
            "$" +
            parseFloat(project?.fedPhaseCost || 0)
              ?.toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          fedPhaseCostPercent:
            " (" +
            ((project?.fedPhaseCost / project?.totalPhaseCost) * 100
              ? (project?.fedPhaseCost / project?.totalPhaseCost) * 100
              : 0
            ).toFixed(1) +
            "%)",
          statePhaseCost:
            "$" +
            parseFloat(project?.statePhaseCost || 0)
              ?.toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          statePhaseCostPercent:
            " (" +
            ((project?.statePhaseCost / project?.totalPhaseCost) * 100
              ? (project?.statePhaseCost / project?.totalPhaseCost) * 100
              : 0
            ).toFixed(1) +
            "%)",
          localPhaseCost:
            "$" +
            parseFloat(project?.localPhaseCost || 0)
              ?.toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          localPhaseCostPercent:
            " (" +
            ((project?.localPhaseCost / project?.totalPhaseCost) * 100
              ? (project?.localPhaseCost / project?.totalPhaseCost) * 100
              : 0
            ).toFixed(1) +
            "%)",
          otherPhaseCost:
            "$" +
            parseFloat(project?.otherPhaseCost || 0)
              ?.toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          otherPhaseCostPercent:
            " (" +
            ((project?.otherPhaseCost / project?.totalPhaseCost) * 100
              ? (project?.otherPhaseCost / project?.totalPhaseCost) * 100
              : 0
            ).toFixed(1) +
            "%)",
        };
      });
    }
    return {
      estimatedProjectFunding,
      columnData,
    };
  },
};
