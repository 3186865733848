export default () => {  function getFormattedDateStringNoTime(input) {
    if (input && typeof input === "object") {
      return new Date(input).toLocaleDateString(undefined, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    }

    //https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off
    return input && input != null && input?.trim().length > 0
      ? new Date(
          input.replace(/-/g, "/").replace(/T.+/, "")
        ).toLocaleDateString(undefined, {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
      : "";
  }
  function checkValidDate(input) {
    const date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/\d{4}$/;
    return date_regex.test(input);
  }

  const formatDate = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const options = {
      timeZone: "America/New_York",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };
    const formattedDate = date.toLocaleString("en-US", options);
    return formattedDate;
  };
  const formatDateTimewithSeconds = (dateTimeString) => {
    const date = new Date(dateTimeString);
    let dateOptions = { day: "2-digit", month: "2-digit", year: "numeric" };
    let timeOptions = {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    const formattedDate =
      date.toLocaleDateString("en-US", dateOptions) +
      " " +
      date.toLocaleTimeString("en", timeOptions);
    return formattedDate;
  };

  return {
    getFormattedDateStringNoTime,
    checkValidDate,
    formatDate,
    formatDateTimewithSeconds,
  };
};
