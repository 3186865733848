import useSharedFunctions from "@/composables/shared";import DialogComponent from "@/modules/gcmsmain/admin/submenu/components/SignatureUpdateDialog.vue";
import FeatureTable from "@/modules/shared/components/FeatureTable.vue";
import OnlineHelpPOC from "@/modules/shared/components/OnlineHelpPOC.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import sharedUserService from "@/modules/shared/service/sharedUserService";
import { useProjectServiceStore } from "@/stores/projectService";
import axios from "axios";
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { CONFIG_ENDPOINT } from "../../../../../constants/Endpoints";
import { RBAC_CONTROLLER } from "../../../../../constants/rbacconstants";
import { userRoleBasedAccessStore } from "../../../../../stores/userRoleBasedAccess";

export default {
  name: "ManageConsultingCompanies",
  components: { FeatureTable, DialogComponent, PrimaryButton, OnlineHelpPOC },
  setup() {
    const lookupData = ref(null);
    const projectServiceStore = useProjectServiceStore();

    const loading = ref(true);
    const activeProjectsMap = ref({});
    const userData = computed({
      get: () => {
        return sharedUserService.getSharedData();
      },
    });
    let headers = ref([
      {
        title: "Company Name",
        sortable: false,
        key: "companyName",
        filter: null,
        filterType: "single-auto-complete",
        isLink: true,
      },
      {
        title: "Address 1",
        sortable: false,
        key: "address1",
      },
      {
        title: "City",
        sortable: false,
        key: "city",
      },
      {
        title: "State",
        sortable: false,
        key: "state",
      },
      {
        title: "ZIP",
        sortable: false,
        key: "zip",
      },
    ]);
    const paginationData = ref({
      page: 1,
      items: 15,
      total: 0,
    });
    const sortData = ref({
      key: "",
      order: "",
    });
    const textFilter = ref([]);
    const multipleFilter = ref([]);
    const currentFilters = ref(null);
    const activeProjects = ref([]);
    const { ExportToCSV } = useSharedFunctions();
    const router = useRouter();
    const isManageConsultingCompaniesUpdateAccess = ref({
      read: false,
      update: false,
    });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    onMounted(async () => {
      await getManageConsultingCompaniesUpdateAccess();
      await fetchData();
    });
    const getManageConsultingCompaniesUpdateAccess = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.ADMIN_CONSULTANT_LIST_PAGE_READ_ACCESS
      );
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.ADMIN_CONSULTANT_LIST_PAGE_CRTUPD_ACCESS
      );
      isManageConsultingCompaniesUpdateAccess.value.read = readResponse;
      isManageConsultingCompaniesUpdateAccess.value.update = updateResponse;
    };
    const fetchData = async () => {
      let Filter = {
        companyName: null,
      };
      applyFilters(Filter);
      let filterText =
        "?page=" +
        (paginationData.value.page - 1) +
        "&size=" +
        paginationData.value.items;
      if (sortData.value) {
        filterText +=
          "&sort=" + sortData.value.key + "&direction=" + sortData.value.order;
      }
      loading.value = true;
      try {
        const response = await axios.post(
          CONFIG_ENDPOINT.GET_MANAGE_CONSULTING_COMPANIES_ENDPOINT + filterText,
          Filter
        );
        setActiveProjects(response, Filter, filterText);
      } catch (err) {
        loading.value = false;
        activeProjects.value = [];
        paginationData.value.total = 0;
      }
    };
    const applyFilters = (Filter) => {
      textFilter.value.forEach((filter) => {
        if (Object.keys(Filter).includes(filter.key) && filter.value) {
          Filter[filter.key] = filter.value;
        }
      });
      multipleFilter.value.forEach((filter) => {
        if (Object.keys(Filter).includes(filter?.key) && filter.value) {
          Filter[filter.key] = filter.value;
        }
      });
    };
    const setActiveProjects = (response, Filter, filterText) => {
      loading.value = false;
      activeProjects.value = response.data.data;

      for (let project in activeProjects.value) {
        activeProjectsMap.value[activeProjects.value[project].companyName] =
          activeProjects.value[project];
      }
      paginationData.value.total = response.data.totalElements;
      projectServiceStore.createConsultingCompanyMap(activeProjectsMap.value);
      currentFilters.value = Filter;
      getLookupData(filterText, Filter);
    };
    const getLookupData = (filterText, Filter) => {
      let filterText1 =
        "?page=" +
        (paginationData.value.page - 1) +
        "&size=" +
        paginationData.value.items;
      axios
        .post(
          CONFIG_ENDPOINT.GET_MANAGE_CONSULTING_COMPANIES_LOOKUP_ENDPOINT +
            filterText1,
          Filter
        )
        .then((response) => {
          lookupData.value = response.data.data;
          headers.value.forEach((header, index) => {
            headers.value[index] = {
              ...header,
              sortable: !checkFieldDisabled(header.key, header?.filterType),
            };
          });
        })
        .catch(() => {
          lookupData.value = null;
        });
    };
    const exportButtonClicked = () => {
      let filterText = "?page=" + 0 + "&size=" + paginationData.value.total;
      if (sortData.value) {
        filterText +=
          "&sort=" + sortData.value.key + "&direction=" + sortData.value.order;
      }
      axios
        .post(
          CONFIG_ENDPOINT.GET_MANAGE_CONSULTING_COMPANIES_ENDPOINT + filterText,
          currentFilters.value
        )
        .then((response) => {
          setActiveProjectsExportDetails(response);
        })
        .catch(() => {});
    };
    const setActiveProjectsExportDetails = (response) => {
      let exportDataList = response.data.data;
      ExportToCSV(headers.value, exportDataList, "Manage Consulting Companies");
    };
    const checkFieldDisabled = (key, filterType) => {
      if (
        key === "address1" ||
        key === "city" ||
        key === "state" ||
        key === "zip" ||
        key === "companyName"
      ) {
        return true;
      }
      if (
        filterType != "auto-complete" ||
        filterType != "multi-select" ||
        filterType != "single-auto-complete"
      ) {
        return false;
      }
      return !lookupData.value[key]?.length;
    };
    const updatePaginationPerPage = (event) => {
      paginationData.value.page = 1;
      paginationData.value.items = event;
      fetchData();
    };
    const pageChange = (event) => {
      paginationData.value.page = event;
      fetchData();
    };
    const sortUpdate = (event) => {
      if (event.length) {
        sortData.value = {
          key: event[0].key,
          order: event[0].order,
        };
      } else {
        sortData.value = null;
      }
      fetchData();
    };
    const clearAllFilter = () => {
      textFilter.value = [];
      multipleFilter.value = [];
      fetchData();
    };
    const textFilterChange = (event) => {
      let index = textFilter.value.findIndex(
        (filter) => filter.key === event.key
      );
      if (index >= 0) {
        if (event.value && event.value.length > 0) {
          textFilter.value[index] = event;
        } else {
          textFilter.value.splice(index, 1);
        }
      } else if (event.value && event.value.length > 0) {
        textFilter.value.push(event);
      }
      fetchData();
    };
    const multipleFiltersChange = (event) => {
      let index = multipleFilter.value.findIndex(
        (filter) => filter.key === event.key
      );
      if (index >= 0) {
        if (event.value?.length) {
          multipleFilter.value[index] = event;
        } else {
          multipleFilter.value.splice(index, 1);
        }
      } else {
        multipleFilter.value.push(event);
      }
      fetchData();
    };
    const linkClicked = (event) => {
      if (event.key === "companyName") {
        router.push({
          path: `/GCMS/manage-consulting-companies/` + event.value,
        });
      }
      fetchData();
    };
    return {
      activeProjects,
      loading,
      headers,
      paginationData,
      updatePaginationPerPage,
      pageChange,
      sortUpdate,
      textFilterChange,
      clearAllFilter,
      multipleFiltersChange,
      lookupData,
      currentFilters,
      exportButtonClicked,
      userData,
      linkClicked,
      isManageConsultingCompaniesUpdateAccess,
      activeProjectsMap,
    };
  },
};
