<template>
  <v-row>
    <!-- Left corner element -->
    <v-col class="ml-20">
      <router-link :to="`/GCMS/${id}`">
        &lt; Return to Project Information Page
      </router-link>
    </v-col>
    <!-- Centered element -->
    <v-col class="page-title">
      <h1>Project History</h1>
    </v-col>
    <v-col> </v-col>
  </v-row>
  <v-card class="p-64">
    <FeatureTable
      :headers="headers"
      :items="inventorylist"
      :showExportButton="true"
      :exportFilName="'Project History List'"
      :itemsPerPage="paginationData?.items"
      :linkedPagination="true"
      :totalItems="paginationData?.total"
      :lookupData="lookupData"
      :isDynamicExport="true"
      @updatePaginationPerPage="updatePaginationPerPage"
      @pageChange="pageChange"
      @sortUpdate="sortUpdate"
      :showClearFilterButton="true"
      @textFilterChange="textFilterChange"
      @multipleFiltersChange="multipleFiltersChange"
      @clearAllFilter="clearAllFilter"
      :oldMultipleFilter="multipleFilter"
      @exportButtonClicked="exportButtonClicked"
    >
    </FeatureTable>
  </v-card>
</template>
<script>
import ProjectHistory from "@/modules/manageproject/scripts/ProjectHistory.js";
export default ProjectHistory;
</script>
<style>
@import "@/assets/css/style.scss";
</style>