import useDateField from "@/composables/dateField.js";import useFieldEntry from "@/composables/fieldEntry";
import useSharedFunctions from "@/composables/shared";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import FeatureTable from "@/modules/shared/components/FeatureTable.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "ProjectHistory",
  components: { FeatureTable, PrimaryButton },
  emits: ["linkClicked", "updatePaginationPerPage", "pageChange", "sortUpdate"],
  setup() {
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const readOnlyAccess = ref(false);
    const checkAccess = async () => {
      readOnlyAccess.value = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.PROJECT_HISTORY_PAGE_READ_ACCESS
      );
    };
    const lookupData = ref(null);
    let inventorylist = ref([]);
    let loading = ref(true);
    const route = useRoute();
    const id = route.params.id;
    const { allowAlphanumericOnly } = useFieldEntry();
    const { formatDateTimewithSeconds } = useDateField();
    const getDate = (date) => {
      return formatDateTimewithSeconds(date);
    };
    const headers = ref([
      {
        title: "Date/Time",
        filter: [],
        filterType: "multi-select",
        filterSort: "date",
        filterWith: "yearMonth",
        sortOrder: "asc",
        sortType: "date",
        width: "15%",
        key: "date",
      },
      {
        title: "Category",
        filter: [],
        filterType: "multi-select",
        filterSort: "number",
        sortType: "number",
        width: "12%",
        key: "category",
      },
      {
        title: "Action",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
        width: "12%",
        key: "action",
      },
      {
        title: "New Data",
        width: "12%",
        key: "newData",
      },
      {
        title: "User",
        filter: [],
        filterType: "auto-complete",
        filterSort: "text",
        sortType: "text",
        width: "12%",
        key: "user",
      },
      {
        title: "User Role",
        filter: [],
        filterType: "auto-complete",
        filterSort: "text",
        sortType: "text",
        width: "12%",
        key: "userRole",
      },
      {
        title: "Company",
        filter: [],
        filterType: "auto-complete",
        filterSort: "text",
        sortType: "text",
        width: "12%",
        key: "company",
      },
      {
        title: "Project Status",
        filter: [],
        filterType: "auto-complete",
        filterSort: "text",
        sortType: "text",
        width: "12%",
        key: "projectStatus",
      },
    ]);
    const paginationData = ref({
      page: 1,
      items: 15,
      total: 0,
    });
    const sortData = ref({
      key: "date",
      order: "asc",
    });
    const textFilter = ref([]);
    const multipleFilter = ref([]);
    const currentFilters = ref(null);
    const { ExportToCSV } = useSharedFunctions();
    onMounted(async () => {
      await checkAccess();
      await fetchData();
    });
    const fetchLookupData = (filterText, Filter) => {
      axios
        .post(
          CONFIG_ENDPOINT.PROJECT_HISTORY_LOOKUP_DATA + id + filterText,
          Filter
        )
        .then((response) => {
          lookupData.value = response.data;
          headers.value.forEach((header, index) => {
            headers.value[index] = {
              ...header,
              sortable: !checkFieldDisabled(header.key, header.filterType),
            };
          });
        })
        .catch((err) => {
          lookupData.value = null;
        });
    };
    async function fetchData() {
      let Filter = {
        date: null,
        category: null,
        action: null,
        user: null,
        userRole: null,
        company: null,
        projectStatus: null,
      };
      textFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter.key)) {
          Filter[filter.key] = filter.value;
        }
      });
      multipleFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter.key)) {
          Filter[filter.key] = filter.value;
        }
      });
      let filterText =
        "?page=" +
        (paginationData.value.page - 1) +
        "&size=" +
        paginationData.value.items;
      if (sortData.value) {
        filterText =
          filterText +
          "&sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order;
      }
      loading.value = true;
      axios
        .post(CONFIG_ENDPOINT.PROJECT_HISTORY_DATA + id + filterText, Filter)
        .then((response) => {
          loading.value = false;
          inventorylist.value = response.data.data;
          inventorylist.value.forEach((item) => {
            item.date = getDate(item.date);
          });
          paginationData.value.total = response.data.totalElements;
          currentFilters.value = Filter;
          fetchLookupData(filterText, Filter);
        })
        .catch((err) => {
          loading.value = false;
          inventorylist.value = [];
          paginationData.value = {
            page: 1,
            items: paginationData.value.items,
            total: 0,
          };
        });
    }
    const exportButtonClicked = () => {
      let filterText = "?page=" + 0 + "&size=" + paginationData.value.total;
      if (sortData.value) {
        filterText =
          filterText +
          "&sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order;
      }
      axios
        .post(
          CONFIG_ENDPOINT.PROJECT_HISTORY_DATA + id + filterText,
          currentFilters.value
        )
        .then((response) => {
          ExportToCSV(headers.value, response.data.data, "Project History");
        })
        .catch((err) => {});
    };
    const checkFieldDisabled = (key, filterType) => {
      if (filterType != "auto-complete" || filterType != "multi-select") {
        return false;
      }
      return !lookupData.value[key]?.length;
    };
    const linkClicked = (event) => {
      console.log(event);
    };
    const updatePaginationPerPage = (event) => {
      paginationData.value.page = 1;
      paginationData.value.items = event;
      fetchData();
    };
    const pageChange = (event) => {
      paginationData.value.page = event;
      fetchData();
    };
    const clearAllFilter = (event) => {
      textFilter.value = null;
      multipleFilter.value = [];
      fetchData();
    };
    const textFilterChange = (event) => {
      let index = textFilter.value.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value) {
          textFilter.value[index] = event;
        } else {
          textFilter.value.splice(index, 1);
        }
      } else {
        textFilter.value.push(event);
      }
    };
    const multipleFiltersChange = (event) => {
      let index = multipleFilter.value.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value?.length) {
          multipleFilter.value[index] = event;
        } else {
          multipleFilter.value.splice(index, 1);
        }
      } else {
        multipleFilter.value.push(event);
      }
    };
    const sortUpdate = (event) => {
      if (event.length) {
        sortData.value = {
          key: event[0].key,
          order: event[0].order,
        };
      } else {
        sortData.value = null;
      }
      fetchData();
    };
    return {
      inventorylist,
      loading,
      headers,
      linkClicked,
      updatePaginationPerPage,
      pageChange,
      paginationData,
      allowAlphanumericOnly,
      lookupData,
      textFilter,
      multipleFilter,
      clearAllFilter,
      textFilterChange,
      multipleFiltersChange,
      sortUpdate,
      sortData,
      exportButtonClicked,
      fetchData,
      fetchLookupData,
      id,
      getDate,
      readOnlyAccess,
    };
  },
};
