<template>
  <br class="mb-40" />
  <div v-for="(item, index) in milestones" :key="index">
    <div class="safetyMilestone">
      <v-row cols="12">
        <v-col cols="4" class="milestoneCheckboxCol">
          <div class="milestoneCheckboxCol">
            <div v-if="item.checkBoxNotApplicable" class="greyBox">
              <span
                class="mdi mdi-checkbox-blank-outline"
                style="
                  font-size: 30px !important;
                  font-weight: 200 !important;
                  padding-right: 20px;
                  margin-left: -4px;
                  color: black;
                "
              ></span>
            </div>
            <div v-else-if="!item.checkBoxChecked" class="whiteBox">
              <span
                class="mdi mdi-checkbox-blank-outline"
                style="
                  font-size: 30px !important;
                  font-weight: 200 !important;
                  padding-right: 20px;
                  margin-left: -4px;
                  color: black;
                "
              ></span>
            </div>
            <div v-else class="squareOutline">
              <span
                class="mdi mdi-checkbox-outline"
                style="
                  font-size: 30px !important;
                  font-weight: bolder !important;
                  padding-right: 20px;
                  margin-left: -4px;
                  color: green;
                "
              ></span>
            </div>
            <b
              ><span>{{ item.displayName }}</span></b
            >
          </div>
        </v-col>
        <v-col cols="4" class="milestone" v-if="index == 'utilityClearance'">
          <div class="milestoneDatePicker">
            <DatePicker
              :value="item.approvedDate"
              @input="dateChange($event, index, item)"
              :invalidMsg="'Inspection Date'"
              @dateValidation="checkDateValidation($event)"
              :rules="['validDateFutureDate_Inspections']"
              :disabled="item.checkBoxNotApplicable"
            ></DatePicker>
          </div>
        </v-col>
        <v-col cols="4" class="milestone" v-else>
          <span>{{ item.approvedDate }}</span>
        </v-col>
        <v-col cols="4" class="milestone" v-if="item.docId">
          <i
            class="fa fa-arrow-circle-right"
            style="color: rgb(42, 87, 141); font-size: 18px; margin: 8px"
          ></i>
          <b
            ><span class="milestoneView" @click="download(item.docId)"
              >View</span
            ></b
          >
        </v-col>
      </v-row>
    </div>
    <br />
    <br />
  </div>
</template>
  <script>
import ScopingMilestones from "@/modules/manageproject/scripts/ScopingMilestones.js";
export default ScopingMilestones;
</script>
  <style>
@import "@/assets/css/style.scss";
@import "@/modules/manageproject/assets/milestones.scss";
</style>