

<template>
  <div class="env-infp mb-20">
    <div class="env-header">
      <h2 class="locationHeaderText">Railroad Information</h2>
    </div>
  </div>
  <v-form
    v-model="isFormValid"
    v-if="formData"
    class="RailroadCompanyInforForm"
  >
    <div class="mt-20">
      <v-row class="ml-20" cols="12" align="center">
        <v-col cols="2" class="required text-right mb-20"
          ><span>Railroad Name: </span></v-col
        >
        <v-col cols="7">
          <v-text-field
            :rules="[requiredRule]"
            v-model="formData.name"
            outlined
            :maxlength="100"
          ></v-text-field>
        </v-col>
        <v-col cols="2" class="required text-right mb-20"
          ><span>Railroad Code: </span></v-col
        >
        <v-col cols="1">
          <v-text-field
            :rules="[requiredRule]"
            v-model="formData.railroadCompanyCode"
            outlined
            :maxlength="4"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="ml-20" cols="12" align="center">
        <v-col cols="2" class="d-flex justify-content-end text-right mb-20"
          ><span>SAP Vendor#: </span></v-col
        >
        <v-col cols="2">
          <v-text-field
            v-model="formData.sapVendorId"
            @keydown="keydownAllowNumberOnly($event)"
            outlined
            :maxlength="6"
          ></v-text-field>
        </v-col>
        <v-col cols="2"></v-col>
        <v-col cols="2" class="required text-right mb-20"
          ><span>FID: </span></v-col
        >
        <v-col cols="4">
          <v-text-field
            :rules="[requiredRule]"
            outlined
            v-model="formData.federalId"
            @keydown="keydownAllowNumberOnly($event)"
            :maxlength="20"
            :minlength="9"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="ml-20" cols="12" align="center">
        <v-col cols="2" class="d-flex justify-content-end text-right mb-20"
          ><span>Business Partner: </span></v-col
        >
        <v-col cols="1">
          <v-checkbox
            v-if="formData?.isBusinessPartner === 'Y'"
            color="grey"
            :model-value="formData?.isBusinessPartner === 'Y' ? true : false"
            disabled
          >
          </v-checkbox>
          <i
            v-if="formData?.isBusinessPartner != 'Y'"
            class="mdi-checkbox-blank mdi v-icon notranslate v-theme--light v-icon--size-default mb-20 ml-10"
            style="color: grey"
          ></i>
        </v-col>
        <v-col cols="5" class="d-flex justify-content-end text-right mb-20"
          ><span
            >Notification Resource<br />
            Account:
          </span></v-col
        >
        <v-col cols="4">
          <v-text-field
            outlined
            type="email"
            v-model="formData.notificationResourceAccount"
            @keydown.space="(event) => event.preventDefault()"
            :rules="[validateEmail]"
            :validate-on="'blur'"
            :maxlength="300"
            :minlength="6"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="ml-20" cols="12" align="center">
        <!-- Disabled for Add, Enabled for edit -->
        <v-col cols="2" class="d-flex justify-content-end text-right mb-20"
          ><span>Permanent Delete: </span></v-col
        >
        <v-col cols="4">
          <v-checkbox
            v-if="addOrEdit != 'add'"
            color="grey"
            :disabled="addOrEdit === 'add'"
            :model-value="formData.isPermDelete"
          ></v-checkbox>
          <i
            v-if="addOrEdit === 'add'"
            class="mdi-checkbox-blank mdi v-icon notranslate v-theme--light v-icon--size-default mb-20 ml-10"
            style="color: grey"
          ></i>
        </v-col>
        <v-col cols="6"> </v-col>
      </v-row>
      <v-row class="ml-20" cols="12" align="center">
        <v-col cols="2" class="required text-right mb-20"
          ><span>Is Active: </span></v-col
        >
        <v-col cols="4">
          <v-radio-group
            :rules="[requiredRule]"
            inline
            v-model="formData.isActive"
          >
            <v-radio label="Yes" value="Y"></v-radio>
            <v-radio label="No" value="N"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="2" class="d-flex justify-content-end text-right mb-20"
          ><span>BPID: </span></v-col
        >
        <v-col cols="4">
          <v-text-field
            outlined
            @keydown="keydownAllowNumberOnly($event)"
            v-model="formData.bpid"
            :maxlength="6"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <div class="env-infp mb-20 mt-20">
      <div class="env-header">
        <h2 class="locationHeaderText">Corporate Address</h2>
      </div>
      <h4>
        This is the address that will be used for all Railroad communications
      </h4>
    </div>
    <div class="mt-20">
      <AddressInput
        :formData="formData"
        :lookupData="lookupData"
        :isPage="true"
      >
      </AddressInput>
    </div>
    <div class="env-infp mb-20">
      <div class="env-header">
        <h2 class="locationHeaderText">SAP Suffixes</h2>
      </div>
    </div>
    <div class="mt-20">
      <v-row>
        <v-col cols="12">
          <VueTable
            :tableData="sapDetails"
            :columnData="headers"
            :tableHeader="''"
            :showAddRow="true"
            :addButtonText="'ADD SAP Suffix'"
            :errorMessage="'No SAP Suffix added.'"
            :invalidErrorClass="true"
            @editClicked="openDialog('Edit', $event)"
            @addClicked="openDialog('Add')"
            @deleteClicked="deleteSapSuffixCall($event)"
          ></VueTable>
        </v-col>
      </v-row>
    </div>
    <div class="d-flex justify-content-end mt-20">
      <SecondaryButton
        @buttonClicked="CancelButtonClicked"
        :buttonText="'Cancel'"
      ></SecondaryButton>
      <PrimaryButton
        class="ml-20"
        @buttonClicked="saveButtonClicked"
        :disabled="!isFormValid || !isAddressFormValid"
        :buttonText="'Save'"
      ></PrimaryButton>
    </div>
  </v-form>
  <DialogComponent ref="AddEditSAP"></DialogComponent>
  <DialogComponent2 ref="deleteSapSuffixDialogRef"></DialogComponent2>
</template>

<script>
import RailroadCompanyInformationForm from "@/modules/gcmsmain/admin/submenu/scripts/RailroadCompanyInformationForm.js";
export default RailroadCompanyInformationForm;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
.greyBox {
  background-color: darkgrey !important;
  width: 22px !important;
  height: 20px !important;
  padding-right: 20px !important;
  margin-right: 30px !important;
}
::v-deep input[type="checkbox" i]:disabled {
  background-color: darkgrey !important;
}
.v-input--selection-controls__input {
  background: gray !important;
}
</style>
