<template>
  <v-dialog
    persistent
    v-model="dialog"
    :max-width="maxWidth ? maxWidth : '1000' + 'px'"
    max-height="3000px"
  >
    <div class="dialogheading">
      <div class="w-100 d-flex justify-content-between">
        <div class="w-90 text-center f-size24">
          {{ heading }}
        </div>
        <div>
          <v-icon @click="handleNo" class="cursor-pointer" small
            >mdi-close
          </v-icon>
        </div>
      </div>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="text-left">
              <div v-for="(text, index) in description" :key="text">
                <p>
                  {{ text }}
                </p>
                <br v-if="index === 0" />
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <div class="action">
            <PrimaryButton
              @buttonClicked="handleYes"
              :buttonText="'Ok'"
            ></PrimaryButton>
            <SecondaryButton
              v-if="!hideCancelButton"
              @buttonClicked="handleNo"
              :buttonText="'Cancel'"
              class="ml-2"
            ></SecondaryButton>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>
<script>
import SharedConfirmationDialog from "@/modules/shared/scripts/SharedConfirmationDialog.js";
export default SharedConfirmationDialog;
</script>
<style>
@import "@/assets/css/style.scss";
</style>
