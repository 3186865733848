<template>
  <v-card>
    <v-expansion-panels v-model="panel">
      <v-expansion-panel>
        <v-expansion-panel-title class="pannelColor">
          <div class="panel-header">
            FRA Inventory Record
          </div></v-expansion-panel-title
        >

        <v-expansion-panel-text>
          <v-tabs :value="getCurrentTab" dark align-tabs="start">
            <v-tab
              :class="{
                'v-slide-group-item--active': getCurrentTab === 'part1',
                'v-tab--selected': getCurrentTab === 'part1',
              }"
              @click="
                $event.preventDefault();
                onTabChange('part1');
              "
              value="part1"
              >Part I - Loc & Classification</v-tab
            >
            <v-tab
              :class="{
                'v-slide-group-item--active': getCurrentTab === 'part2',
                'v-tab--selected': getCurrentTab === 'part2',
              }"
              @click="
                $event.preventDefault();
                onTabChange('part2');
              "
              value="part2"
              >Part II - Railroad Info
            </v-tab>
            <v-tab
              :class="{
                'v-slide-group-item--active': getCurrentTab === 'part3',
                'v-tab--selected': getCurrentTab === 'part3',
              }"
              @click="
                $event.preventDefault();
                onTabChange('part3');
              "
              value="part3"
              >Part III - Traffic Control Device Info</v-tab
            >
            <v-tab
              :class="{
                'v-slide-group-item--active': getCurrentTab === 'part4',
                'v-tab--selected': getCurrentTab === 'part4',
              }"
              @click="
                $event.preventDefault();
                onTabChange('part4');
              "
              value="part4"
              >Part IV - Physical Characteristics
            </v-tab>
            <v-tab
              :class="{
                'v-slide-group-item--active': getCurrentTab === 'part5',
                'v-tab--selected': getCurrentTab === 'part5',
              }"
              @click="
                $event.preventDefault();
                onTabChange('part5');
              "
              value="part5"
              >Part V - Public HWY Info
            </v-tab>
          </v-tabs>

          <v-card-text>
            <div v-if="getCurrentTab === 'part1'">
              <PartILocClassification
                :dotId="dotId"
                @showBanner="showBannerMessage"
                @ValueChanged="valueChanged($event)"
              ></PartILocClassification>
            </div>
            <div v-if="getCurrentTab === 'part2'">
              <PartIIRailRoadInfo
                :dotId="dotId"
                @ValueChanged="valueChanged($event)"
              ></PartIIRailRoadInfo>
            </div>
            <div v-if="getCurrentTab === 'part3'">
              <PartIIITrafficControl
                :dotId="dotId"
                @showBanner="showBannerMessage"
                @ValueChanged="valueChanged($event)"
              ></PartIIITrafficControl>
            </div>
            <div v-if="getCurrentTab === 'part4'">
              <PartIVPhysicalChar
                :dotId="dotId"
                @showBanner="showBannerMessage"
                @ValueChanged="valueChanged($event)"
              ></PartIVPhysicalChar>
            </div>
            <div v-if="getCurrentTab === 'part5'">
              <PartVPublicHwyInfo
                :dotId="dotId"
                @showBanner="showBannerMessage"
                @ValueChanged="valueChanged($event)"
              ></PartVPublicHwyInfo>
            </div>
          </v-card-text>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel class="mt-20">
        <v-expansion-panel-title class="pannelColor"
          ><div class="panel-header">
            PennDOT Crossing Information
          </div></v-expansion-panel-title
        >
        <v-expansion-panel-text>
          <div class="env-header mt-50 mb-50">
            <PennDotCrossingInfo
              :dotId="dotId"
              @showBanner="showBannerMessage"
            ></PennDotCrossingInfo>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>
<script>
import CrossingDetailsTabView from "@/modules/dot-details/scripts/CrossingDetailsTabView.js";
export default CrossingDetailsTabView;
</script>
<style scoped>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
@import "@/modules/dot-details/assets/crossingParts.scss";
</style>
