<template>
  <v-card-text>
    <pucCommunications
      @refreshData="$emit('refreshMpmsData')"
    ></pucCommunications>
  </v-card-text>
</template>
  <script>
import pucCommunications from "@/modules/manageproject/components/PucCommunication.vue";

export default {
  name: "ViewPucCommunications-page",
  components: {
    pucCommunications: pucCommunications,
  },
};
</script>
  