<template>
  <v-dialog
    persistent
    @afterLeave="afterModalLeave"
    v-model="dialog"
    max-width="90vw"
    max-height="3000px"
  >
    <div class="dialogheading">
      <div class="w-100 d-flex justify-content-between">
        <div class="w-90 text-center f-size24">
          {{ title }}
        </div>
        <div>
          <v-icon @click="closeDialog" class="cursor-pointer" small
            >mdi-close
          </v-icon>
        </div>
      </div>
      <v-card>
        <v-card-text style="max-height: 80vh; overflow-y: scroll">
          <ViewScopingFieldView
            :saveClicked="isSaveButtonClicked"
            @isUploadButtonDisabled="isUploadButtonDisabled"
            @valueChangedEvent="valueChangedEvent($event)"
          ></ViewScopingFieldView>
        </v-card-text>
        <v-card-actions>
          <div class="action">
            <SecondaryButton
              @buttonClicked="closeDialog"
              :buttonText="'Close'"
              class="ml-2"
            ></SecondaryButton>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>
<script>
import ScopingViewDialog from "@/modules/projectinformation/scripts/ScopingViewDialog.js";
export default ScopingViewDialog;
</script>
<style>
@import "@/modules/projectinformation/assets/projectinfostyle.scss";
@import "@/assets/css/style.scss";
</style>
