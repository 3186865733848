import { DROPDOWN_NO_DATA } from "@/constants/displayTexts";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import axios from "axios";
import { CONFIG_ENDPOINT } from "../../../../../constants/Endpoints";

import { reactive, ref, toRefs } from "vue";
export default {
  components: { PrimaryButton, SecondaryButton },
  setup() {
    let title = ref("");
    let confirmationMessage = ref("");
    let railroadCompanyId = ref("");
    let error = ref("");
    let displayError = ref(false);
    const state = reactive({
      dialog: false,
      resolve: null,
      reject: null,
    });

    const open = (message1, message2, id) => {
      state.dialog = true;
      title.value = message1;
      confirmationMessage.value = message2;
      railroadCompanyId.value = id;
      displayError.value = false;
      error.value = "";

      return new Promise((resolve, reject) => {
        state.resolve = resolve;
        state.reject = reject;
      });
    };

    const closeDialog = () => {


      state.resolve(false);
      state.dialog = false;
    };

    const confirmAction = () => {

            axios
              .delete(
                CONFIG_ENDPOINT.DELETE_RAILROAD_CONTACTS +
                  railroadCompanyId.value
              )
              .then((response) => {
                displayError.value = false;
                error.value = "";
                state.resolve(true);
                state.dialog = false;              
            
            })
            .catch((err) => {
                console.log(err);
                displayError.value = true;
                error.value = err.response.data.businessMessage;
            });
            


      
    };
    return {
      closeDialog,
      ...toRefs(state),
      open,
      confirmAction,
      title,
      confirmationMessage,
      ref,
      DROPDOWN_NO_DATA,
      railroadCompanyId,
      error,
      displayError,
    };
  },
};
