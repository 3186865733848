import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import VueTable from "@/modules/shared/components/VueTable.vue";
import { useProjectServiceStore } from "@/stores/projectService";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";


export default {
  name: "ConsultantCompanyInfo",
  components: { VueTable
  },
  emits: ["BannerUpdate", "saveClicked"],
  setup(props, { emit }) {
    const route = useRoute();
    let tab = ref("companyInformation");
    const companyName = route.params.id;
    const companyObject = ref({});
    const projectServiceStore = useProjectServiceStore();
    const companyInfo = ref({});
    const userInfo = ref([]);
    let columnData = ref([
        {
          name: "First Name",
          key: "firstName",
          columnWidth: "5",
          alignLeft: true,
        },
        { name: "Last Name", key: "lastName", columnWidth: "5" },
        { name: "User ID", key: "userID", columnWidth: "5" },
        { name: "Email Address", key: "emailAddress", columnWidth: "5" },
        { name: "Phone Number", key: "phoneNumber", columnWidth: "5" },
      ]);

      const isUserConsultantAccess = ref({
        read: false,
      });
      const userRoleBasedAccessService = userRoleBasedAccessStore();
      const consultantAccessCheck = async () => {
        let readResponse = await userRoleBasedAccessService.checkUserAccess(
          RBAC_CONTROLLER.ADMIN_CONSULTANT_LIST_PAGE_READ_ACCESS
        );

        isUserConsultantAccess.value.read = readResponse || false;
      };

    onMounted(async () => {
      await consultantAccessCheck();
      if (isUserConsultantAccess.value.read){
        fetchData();

      }
    });

   
    const fetchData = async () => {

        let map = await projectServiceStore.consultingCompanyMap;

        if (map){
            companyObject.value = map[companyName];
        }

        axios
        .get(CONFIG_ENDPOINT.GET_CONSULTING_COMPANY_USERS + companyObject.value.orgEsecHashFein)
        .then((response) => {
            userInfo.value = response.data.data;
        })
        .catch((err) => {});
    };
   

    function createDebounce() {
      let timeout = null;
      return function (fnc, delayMs) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fnc();
        }, delayMs || 1000);
      };
    }

    return {
        createDebounce,
        fetchData,
        tab,
        companyObject,
        route,
        companyName,
        companyInfo,
        userInfo,
        columnData,
    
    };
  },
};
