<template>
  <v-dialog
    persistent
    @afterLeave="afterModalLeave"
    v-model="dialog"
    max-width="800px"
    max-height="3000px"
  >
    <div class="dialogheading">
      <div class="w-100 d-flex justify-content-between">
        <div class="w-90 text-center f-size24">
          {{ title }}
        </div>
        <div>
          <v-icon @click="closeDialog" class="cursor-pointer" small
            >mdi-close
          </v-icon>
        </div>
      </div>
      <v-card>
        <v-card-text>
          <v-form v-model="isFormValid" class="scoping-dialog-form">
            <v-row>
              <v-col class="dialogLabeltext" cols="8">
                <label>Railroad: </label>
                <label class="asterisk" v-if="message == 'Add'">*</label>

                <v-autocomplete
                  v-if="message == 'Add'"
                  v-model="formData.railRoad"
                  :items="railRoadList"
                  :rules="[requiredRule]"
                  :no-data-text="AUTO_COMPLETE_NO_DATA"
                ></v-autocomplete>

                <label
                  v-if="message == 'Edit'"
                  :style="{ 'font-weight': 'normal' }"
                >
                  <br />{{ formData.railRoad }}</label
                >
              </v-col>
              <v-col class="dialogLabeltext" cols="4">
                <label>Estimate: </label>
                <label class="asterisk">*</label>

                <v-text-field
                  v-model="formData.estimate"
                  :maxlength="14"
                  type="text"
                  :rules="[
                    requiredRule,
                    greaterThanEqualToZero,
                    tenBillionRule,
                    wholeOrDecimal,
                  ]"
                  @keydown="allowDecimalAndWholeNum($event)"
                  outlined
                  class="inputEstimate"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="dialogLabeltext" cols="12">
                <label>Notes: </label>
                <v-textarea
                  :counter="250"
                  :maxlength="250"
                  :persistent-counter="true"
                  v-model="formData.notes"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <div class="action">
            <PrimaryButton
              @buttonClicked="saveData"
              :disabled="!isFormValid"
              :buttonText="'Save'"
            ></PrimaryButton>
            <SecondaryButton
              @buttonClicked="closeDialog"
              :buttonText="'Cancel'"
              class="ml-2"
            ></SecondaryButton>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>
<script>
import ScopingRailRoadCostsDialog from "@/modules/cost-and-funding/scripts/ScopingRailRoadCostsDialog.js";
export default ScopingRailRoadCostsDialog;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
@import "@/modules/cost-and-funding/assets/ScopingRailRoadCostsDialog.scss";
</style>
