<template>
  <v-row>
    <!-- Left corner element -->
    <v-col class="ml-20">
      <router-link :to="`/GCMS/${id}`">
        &lt; Return to Project Information Page
      </router-link>
    </v-col>
    <!-- Centered element -->
    <v-col class="page-title">
      <h1>Project Flagger Needs</h1>
    </v-col>
    <v-col></v-col>
  </v-row>
  <v-card class="p-64">
    <div
      class="indicator-text"
      v-if="showWarningWhenNoRailroad()?.length && flaggerNeedsData?.length"
    >
      <span>
        Click Edit to enter Flagger Needs for the Railroad(s) associated with a
        Crossing. If the Edit button is disabled, a Railroad has not yet been
        added for the Crossing.
      </span>
    </div>
    <div class="mt-20 ml-20">
      <div
        class="railroadError m-0"
        v-if="showWarningWhenNoRailroad()?.length && flaggerNeedsData?.length"
      >
        <div>
          <v-icon icon="mdi-alert"></v-icon>
          Warning: One or more Impacted Crossings are not yet associated with a
          Railroad
        </div>
      </div>
    </div>
    <VueTable
      :tableData="flaggerNeedsData"
      :columnData="columnData"
      :tableHeader="''"
      :errorMessage="errorMessage"
      @editClicked="openDialog('Edit', $event)"
      @addClicked="openDialog('Add')"
      @linkClicked="linkClicked($event)"
    >
    </VueTable>
  </v-card>
  <DialogComponent
    ref="flaggerNeedsDialog"
    @updateFlaggerNeeds="handleChangesSaved"
  ></DialogComponent>
</template>
<script>
import projectFlaggerNeeds from "@/modules/manageproject/scripts/projectFlaggerNeeds.js";
export default projectFlaggerNeeds;
</script>
<style>
@import "@/assets/css/style.scss";
</style>