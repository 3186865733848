import useDateField from "@/composables/dateField.js";import { validDateFutureDate_Inspections } from "@/composables/validationRules";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import DatePicker from "@/modules/shared/components/DatePicker.vue";
import { useDocumentStore } from "@/stores/document";
import { useProjectServiceStore } from "@/stores/projectService";
import { onMounted, ref, watch } from "vue";
import { onBeforeRouteLeave, useRoute } from "vue-router";

export default {
  name: "ScopingMilestones",
  components: { DatePicker },
  props: ["id", "refreshData"],
  emits: ["saveMilestone", "updateRefreshFlag"],

  setup(props, { emit }, _context) {
    const { getFormattedDateStringNoTime } = useDateField();
    const getDate = (date) => {
      return getFormattedDateStringNoTime(date);
    };
    const id = ref();
    const documentStore = useDocumentStore();
    const projectServiceStore = useProjectServiceStore();
    const route = useRoute();
    const milestones = ref({});

    watch(
      () => [props.id],
      () => {
        if (props.id) {
          id.value = props.id;
        }
      },
      { deep: true }
    );

    watch(
      () => [props.refreshData],
      () => {
        if (props.refreshData) {
          valueChanged.value = false;
          fetchData();
          emit("updateRefreshFlag", false);
        }
      },
      { deep: true }
    );
    onMounted(async () => {
      id.value = props.id;
      fetchData.value = props.fetchData;
      await fetchData();
    });

    async function fetchData() {
      let response = await projectServiceStore.fetchAuthorizationMilestones(
        route.params.id
      );
      if (response) {
        milestones.value = response.data;
        for (let milestone in milestones.value) {
          if (milestones.value[milestone].milestoneId) {
            milestones.value[milestone].approvedDate = milestones.value[
              milestone
            ].approvedDate.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2");
          }
        }
      }
    }

    const isDateValidated = ref(false);
    const checkDateValidation = (value) => {
      isDateValidated.value = value;
    };
    let valueChanged = ref(false);
    const dateChange = (event, index, item) => {
      if (event == "") {
        milestones.value[index].approvedDate = null;
        let object = {
          utilityClearanceid: item.milestoneId,
          utilityClearance: "",
        };
        emit("saveMilestone", object);
      } else if (event) {
        milestones.value[index].approvedDate = event;
        let temp = getDate(milestones.value[index].approvedDate);

        let object = {
          utilityClearanceid: item.milestoneId,
          utilityClearance: temp,
        };
        emit("saveMilestone", object);
      }

      valueChanged.value = true;
    };
    onBeforeRouteLeave((to, from, next) => {
      if (valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          next();
          valueChanged.value = false;
        } else {
          next(false);
        }
      } else {
        next();
        valueChanged.value = false;
      }
    });
    window.onbeforeunload = function () {
      if (valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };

    const download = (docId) => {
      let endpoint = CONFIG_ENDPOINT.DOWNLOAD_SCOPING_FILE;
      let id = route.params.id;
      documentStore.downloadFile(docId, id, endpoint);
    };

    return {
      id,
      milestones,
      validDateFutureDate_Inspections,
      checkDateValidation,
      dateChange,
      getDate,
      download,
    };
  },
};
