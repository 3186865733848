<template>
  <v-overlay
    :model-value="isLoading"
    class="align-center justify-center"
    scroll-strategy="none"
    persistent
  >
    <div
      class="m-10 p-10 d-flex align-items-center"
      style="
        background-color: #d3d3d3;
        background-image: linear-gradient(315deg, #bfddff 0%, #f9f9f9 74%);
      "
    >
      <img alt="Loading logo" :src="LoaderImage" style="height: 40px" />
      <span class="ml-20"> Please wait, data is being processed...</span>
    </div>
  </v-overlay>
</template>
<script>
import LoadingOverlay from "@/modules/shared/scripts/LoadingOverlay.js";
export default LoadingOverlay;
</script>
<style>
@import "@/assets/css/style.scss";
</style>
