<template>
  <div class="d-flex justify-content-center w-100">
    <div class="w-100">
      <h2
        v-if="heading"
        class="f-size18 f-montserrat text-left mt-50"
        style="font-size: 18px; padding-bottom: 0.5em; color: #003e7e"
        id="list-section"
      >
        {{ heading }}
      </h2>

      <FeatureTable
        :headers="headers"
        :items="filesList"
        :showExportButton="false"
        :showClearFilterButton="false"
        :tableHeader="''"
        :itemsPerPage="paginationData?.items"
        :isDownload="true"
        :clickable="true"
        :navigationKey="'mpmsNumber'"
        @sortUpdate="sortUpdate"
        :linkedPagination="false"
        :totalItems="paginationData.total"
        :currentPage="paginationData?.page"
        :hideFilter="true"
        @linkClicked="linkClicked($event)"
        @deleteButtonClicked="deleteButtonClicked"
        @otherButtonClicked="otherButtonClicked"
      >
      </FeatureTable>
    </div>
  </div>
  <LoadingOverlay :isLoading="loading"> </LoadingOverlay>
  <DocumentsVersionDialogComponent
    ref="priorityProjectDialogRef"
  ></DocumentsVersionDialogComponent>
</template>
<script>
import DocumentsList from "@/modules/shared/scripts/DocumentsList.js";
export default DocumentsList;
</script>
<style>
@import "@/assets/css/style.scss";
</style>
