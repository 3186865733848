import useDateField from "@/composables/dateField.js";import useIconsFunctions from "@/composables/projectIdentifiersIcons";
import useSharedFunctions from "@/composables/shared";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import FeatureTable from "@/modules/shared/components/FeatureTable.vue";
import sharedUserService from "@/modules/shared/service/sharedUserService";
import axios from "axios";
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "MyUpcomingTasks",
  components: { FeatureTable },
  setup() {
    const router = useRouter();
    const { getIdentifierIcon } = useIconsFunctions();
    const lookupData = ref(null);
    const activeProjects = ref([]);
    const loading = ref(true);
    let noDataAvailableText = ref(" ");
    let headers = ref([
      {
        title: "",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
        key: "projectIdentifier",
        columnType: "icon",
        icon: "projectIdentifierIcon",
        width: "7%",
      },
      {
        title: "MPMS#",
        filter: null,
        filterType: "single-auto-complete",
        sortType: "number",
        key: "mpmsNumber",
        isLink: true,
        width: "6%!important",
      },
      {
        title: "Project Title",
        key: "projectTitle",
        filterType: "single-auto-complete",
        width: "8%important",
      },
      {
        title: "SR",
        filter: [],
        filterType: "multi-select",
        filterSort: "number",
        sortType: "text",
        key: "stateRoute",
        width: "4%!important",
      },
      {
        title: "SEC",
        key: "srSection",
        width: "4%!important",
      },
      {
        title: "District",
        filter: [],
        filterType: "multi-select",
        filterSort: "number",
        sortType: "text",
        key: "district",
        width: "2%!important",
      },
      {
        title: "County",
        filter: [],
        filterType: "auto-complete",
        filterSort: "text",
        sortType: "text",
        key: "county",
        width: "10%!important",
      },
      {
        title: "Task",
        filter: [],
        filterType: "auto-complete",
        filterSort: "text",
        sortType: "text",
        key: "taskName",
        Width: "4%!important",
      },
      {
        title: "Status",
        filter: [],
        filterType: "multi-select",
        key: "projectStatus",
        width: "5%!important",
      },

      {
        title: "Due Date",
        sortOrder: "asc",
        sortType: "date",
        key: "dueDate",
        columnType: "icon",
        textWithIcon: true,
        icon: "dueDateIconKey",
        width: "11%!important",
      },
      {
        title: "Assigned Team Member",
        key: "assignedTeamMember",
        width: "4%",
      },
      {
        title: "Days Remaining",
        key: "daysRemaining",
        width: "5%!important",
        sortable: true,
      },
    ]);
    const paginationData = ref({
      page: 1,
      items: 15,
      total: 0,
    });
    const sortData = ref({
      key: "",
      order: "",
    });
    const textFilter = ref([]);
    const multipleFilter = ref([]);
    const { getFormattedDateStringNoTime } = useDateField();
    const currentFilters = ref(null);
    const { ExportToCSV } = useSharedFunctions();
    const getDate = (date) => {
      return getFormattedDateStringNoTime(date);
    };
    onMounted(async () => {
      await fetchData();
    });
    const getLookupData = (filterText, Filter) => {
      axios
        .post(
          CONFIG_ENDPOINT.GCMS_MAIN_UPCOMING_PROJECTS_LOOKUP_DATA + filterText,
          Filter
        )
        .then((response) => {
          lookupData.value = response.data;

          headers.value.forEach((header, index) => {
            headers.value[index] = {
              ...header,
              sortable: !checkFieldDisabled(header.key, header?.filterType),
            };
          });
        })
        .catch((err) => {
          lookupData.value = null;
        });
    };
    async function fetchData() {
      let Filter = {
        projectIdentifier: null,
        mpmsNumber: null,
        projectTitle: null,
        stateRoute: null,
        srSection: null,
        district: null,
        county: null,
        taskName: null,
        projectStatus: null,
        dueDate: null,
        assignedTeamMember: null,
        daysRemaining: null,
      };
      textFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter.key) && filter.value) {
          if (filter.key == "projectTitle") {
            Filter[filter.key] = [filter.value];
          } else {
            Filter[filter.key] = filter.value;
          }
        }
      });
      multipleFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter?.key) && filter.value) {
          if (filter.key == "projectTitle") {
            Filter[filter.key] = [filter.value];
          } else {
            Filter[filter.key] = filter.value;
          }
        }
      });
      let filterText =
        "?page=" +
        (paginationData.value.page - 1) +
        "&size=" +
        paginationData.value.items;
      if (sortData.value) {
        filterText =
          filterText +
          "&sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order +
          "&userId=" +
          userData.value?.userID;
      }
      loading.value = true;
      axios
        .post(CONFIG_ENDPOINT.GCMS_MAIN_UPCOMING_PROJECTS + filterText, Filter)
        .then((response) => {
          setMyTasksDetails(response);
          currentFilters.value = Filter;
          getLookupData(filterText, Filter);
        })
        .catch((err) => {
          loading.value = false;
          activeProjects.value = [];
          paginationData.value = {
            page: 1,
            items: paginationData.value.items,
            total: 0,
          };
        });
    }
    const setMyTasksDetails = (response) => {
      loading.value = false;
      activeProjects.value = response.data.data;
      activeProjects.value.forEach((item, index) => {
        let icons = "";
        item?.projectIdentifier?.forEach((icon) => {
          icons = icons + getIdentifierIcon(icon);
        });
        item.projectIdentifierIcon = icons;
        item.dueDate = getDate(item.dueDate);
        item.dueDateIconKey = getDateIcon(item.dueDateIcon);
        noDataAvailableText.value = "No Data Available";
      });
      paginationData.value.total = response.data.totalElements;
      noDataAvailableText.value = "No Data Available";
    };
    const getDateIcon = (icon) => {
      if (icon === "Red Bell") {
        return "<i class='tableIcons fas fa-bell' aria-hidden='true' style='color: #C70000;'></i>";
      } else if (icon === "Yellow Clock") {
        return "<i class='tableIcons far fa-clock circle-icon' aria-hidden='true'></i>";
      } else {
        return "";
      }
    };

    const exportButtonClicked = () => {
      let filterText = "?page=" + 0 + "&size=" + paginationData.value.total;
      if (sortData.value) {
        filterText =
          filterText +
          "&sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order +
          "&userId=" +
          userData.value?.userID;
      }
      axios
        .post(
          CONFIG_ENDPOINT.GCMS_MAIN_UPCOMING_PROJECTS + filterText,
          currentFilters.value
        )
        .then((response) => {
          setMyTasksExportDetails(response);
        })
        .catch((err) => {});
    };
    const setMyTasksExportDetails = (response) => {
      loading.value = false;
      let exportDataList = response.data.data;
      exportDataList.forEach((item) => {
        let icons = "";
        item?.projectIdentifier?.forEach((icon) => {
          icons = icons + getIdentifierIcon(icon);
        });
        item.projectIdentifierIcon = icons;
        item.dueDate = getDate(item.dueDate);
        item.dueDateIconKey = "";
      });
      ExportToCSV(headers.value, exportDataList, "Upcoming Tasks");
    };
    const checkFieldDisabled = (key, filterType) => {
      if (
        [
          "assignedTeamMember",
          "daysRemaining",
          "srSection",
          "projectTitle",
          "projectIdentifier",
          "projectStatus",
        ].includes(key)
      ) {
        return true;
      }
      if (filterType != "auto-complete" || filterType != "multi-select") {
        return false;
      }
      if (
        lookupData.value[key]?.length === 1 &&
        lookupData.value[key][0] === null
      ) {
        return true;
      }
      return !lookupData.value[key]?.length;
    };
    const updatePaginationPerPage = (event) => {
      paginationData.value.page = 1;
      paginationData.value.items = event;
      fetchData();
    };
    const pageChange = (event) => {
      paginationData.value.page = event;
      fetchData();
    };
    const sortUpdate = (event) => {
      if (event.length) {
        sortData.value = {
          key: event[0].key,
          order: event[0].order,
        };
      } else {
        sortData.value = null;
      }
      fetchData();
    };
    const clearAllFilter = (event) => {
      textFilter.value = [];
      multipleFilter.value = [];
      fetchData();
    };
    const userData = computed({
      get: () => {
        return sharedUserService.getSharedData();
      },
    });
    const textFilterChange = (event) => {
      let index = textFilter?.value?.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value) {
          textFilter.value[index] = event;
        } else {
          textFilter.value.splice(index, 1);
        }
      } else {
        textFilter.value.push(event);
      }
    };
    const multipleFiltersChange = (event) => {
      let index = multipleFilter.value?.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value?.length) {
          multipleFilter.value[index] = event;
        } else {
          multipleFilter.value.splice(index, 1);
        }
      } else {
        multipleFilter.value.push(event);
      }
    };
    const rowClicked = (event) => {
      window.scrollTo(0, 0);
      console.log("event", event);
      if (event.item?.navigateTo) {
        router.push({
          path: event.item?.navigateTo,
        });
      } else {
        router.push({
          path: `/GCMS/${event.item?.["mpmsNumber"]}`,
        });
      }
    };
    return {
      activeProjects,
      loading,
      headers,
      updatePaginationPerPage,
      pageChange,
      sortUpdate,
      paginationData,
      textFilterChange,
      clearAllFilter,
      multipleFiltersChange,
      multipleFilter,
      lookupData,
      currentFilters,
      exportButtonClicked,
      rowClicked,
      noDataAvailableText,
    };
  },
};
