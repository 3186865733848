<template>
   <div v-if="isPartiesOfRecord">
    <v-row :class="{ 'ml-20': isPage }" cols="12" align="center">
      <v-col
        cols="2"
        class="required text-left mb-20"
        :class="{ dialogLabeltextParty: !isPage }"
        ><span>Address 1: </span></v-col
      >
      <v-col cols="7">
        <v-text-field
          :rules="[requiredRule]"
          maxle
          outlined
          v-model="formData.address1"
          type="text"
          :maxlength="50"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row :class="{ 'ml-20': isPage }" cols="12" align="center">
      <v-col
        cols="2"
        class="d-flex justify-content-end text-left mb-20"
        :class="{ dialogLabeltextParty: !isPage }"
        ><span>Address 2: </span></v-col
      >
      <v-col cols="7">
        <v-text-field
          maxle
          outlined
          v-model="formData.address2"
          type="text"
          :maxlength="50"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row :class="{ 'ml-20': isPage }" cols="12" align="center">
      <v-col
        cols="2"
        class="required text-left mb-20"
        :class="{ dialogLabeltextParty: !isPage }"
        ><span>City: </span></v-col
      >
      <v-col :cols="isPage ? 2 : 4">
        <v-text-field
          :rules="[requiredRule]"
          maxle
          outlined
          v-model="formData.city"
          :maxlength="50"
        ></v-text-field>
      </v-col>
      <v-col cols="1"></v-col>
      <v-col
        cols="1"
        class="required text-left mb-20"
        :class="{ dialogLabeltextParty: !isPage }"
        ><span>State: </span></v-col
      >
      <v-col :cols="isPage ? 2 : 4">
        <v-autocomplete
          :class="{ 'w-35': !isPage }"
          :items="lookupData"
          v-model="formData.stateCode"
          :rules="[requiredRule]"
          :custom-filter="filterObject"
        ></v-autocomplete>
      </v-col>

      <v-col
        cols="2"
        class="required text-left mb-20"
        :class="{ dialogLabeltextParty: !isPage }"
        ><span>ZIP:</span></v-col
      >
      <v-col :cols="isPage ? 2 : 4">
        <ZIPInput outlined v-model="formData.zipcode"> </ZIPInput>
      </v-col>
    </v-row>
  </div>
  <div v-else-if="formData">
    <v-row :class="{ 'ml-20': isPage }" cols="12" align="center">
      <v-col
        cols="2"
        class="required text-right mb-20"
        :class="{ dialogLabeltextParty: !isPage }"
        ><span>Address 1: </span></v-col
      >
      <v-col cols="7">
        <v-text-field
          :rules="[requiredRule]"
          maxle
          outlined
          v-model="formData.address1"
          type="text"
          :maxlength="50"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row :class="{ 'ml-20': isPage }" cols="12" align="center">
      <v-col
        cols="2"
        class="d-flex justify-content-end text-right mb-20"
        :class="{ dialogLabeltextParty: !isPage }"
        ><span>Address 2: </span></v-col
      >
      <v-col cols="7">
        <v-text-field
          maxle
          outlined
          v-model="formData.address2"
          type="text"
          :maxlength="50"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row :class="{ 'ml-20': isPage }" cols="12" align="center">
      <v-col
        cols="2"
        class="required text-right mb-20"
        :class="{ dialogLabeltextParty: !isPage }"
        ><span>City: </span></v-col
      >
      <v-col :cols="isPage ? 2 : 4">
        <v-text-field
          :rules="[requiredRule]"
          maxle
          outlined
          v-model="formData.city"
          :maxlength="50"
        ></v-text-field>
      </v-col>
      <v-col
        cols="1"
        class="required text-right mb-20"
        :class="{ dialogLabeltextParty: !isPage }"
        ><span>State: </span></v-col
      >
      <v-col :cols="isPage ? 2 : 4">
        <v-autocomplete
          :class="{ 'w-35': !isPage }"
          :items="lookupData"
          v-model="formData.stateCode"
          :rules="[requiredRule]"
          :custom-filter="filterObject"
        ></v-autocomplete>
      </v-col>

      <v-col
        cols="2"
        class="required text-right mb-20"
        :class="{ dialogLabeltextParty: !isPage }"
        ><span>ZIP:</span></v-col
      >
      <v-col :cols="isPage ? 2 : 4">
        <ZIPInput outlined v-model="formData.zipcode"> </ZIPInput>
      </v-col>
    </v-row>
  </div>
</template>
  <script>
import AddressInput from "@/modules/shared/scripts/AddressInput.js";
export default AddressInput;
</script>
  