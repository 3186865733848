<template>
  <div>
    <v-row>
      <v-col class="ml-20">
        <router-link :to="`/GCMS/${id}`">
          &lt; Return to Project Information Page
        </router-link>
      </v-col>
      <v-col class="page-title"> </v-col>
      <v-col> </v-col>
    </v-row>
  </div>
  <div class="allProjectsHeadingTabs">Project Followers</div>
  <div class="d-flex justify-content-end mb-20">
    <PrimaryButton
      @buttonClicked="openAddUserDialog"
      :buttonText="'Add User To Project'"
      v-if="isUserConditionalRailroadCertificationAccess.read"
      class="ml-2"
    ></PrimaryButton>
  </div>
  <v-card class="p-64">
    <VueTable
      :tableData="projectFollowers"
      :columnData="columnData"
      :showAddRow="false"
      :showExportButton="true"
      :errorMessage="errorMessage"
      :exportFilName="'Project Followers'"
    ></VueTable>
  </v-card>
  <AddUserToProjectDialog ref="addUserDialog" :mpmsNumber="id" />
</template>
<script>
import ProjectFollowers from "@/modules/manageproject/scripts/ProjectFollowers.js";
import AddUserToProjectDialog from "@/modules/manageproject/views/AddUserToProjectDialog.vue";
export default {
  components: { AddUserToProjectDialog },
  ...ProjectFollowers,
};
</script>
<style>
@import "@/assets/css/style.scss";
</style>