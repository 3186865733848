<template>
  <v-app-bar class="appHeaderBar">
    <v-row>
      <v-col class="headerCol1">
        <v-row class="rowTitle">
          <div class="headerTitle1">GCMS</div>
          <div class="headerLine">|</div>
          <div class="headerTitle2">GRADE CROSSINGS MANAGEMENT SYSTEM</div>
        </v-row>
        <v-row class="rowId"> </v-row>
      </v-col>
    </v-row>
  </v-app-bar>

  <v-container fluid fill-height>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6">
        <div class="mprs-header-div mt-100">
          <div class="page-title">
            <h1>
                {{ sessionExpired ? 'Your session has expired, please log in again.' : 'You have successfully logged out, redirecting to login page!' }}
            </h1>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import auth from "@/auth";
import { useUserStore } from "@/stores/userStore";
import { computed ,ref ,onMounted} from "vue";
export default {
  setup() {
    const userStore = useUserStore();
    const sessionExpired = ref(false);
    const user = computed(() => userStore.loggedInUser);
    const isAuthenticated = computed(() => userStore.isAuthenticated);
    const logout = async () => {
      localStorage.clear(); // Clear all localStorage data
      sessionStorage.clear(); // Clear all sessionStorage data
      userStore.setUser(null);
      userStore.setIsAuthenticated(false);
      auth.logout();
    };

    onMounted(() => {
      // Example logic to set the sessionExpired flag
      const urlParams = new URLSearchParams(window.location.search);
      sessionExpired.value = urlParams.get('sessionExpired') === 'true';
    });
    setTimeout(() => {
      logout().catch((error) => {
        console.error("Error occurred during logout:", error);
      });
    }, 1500); // 1500 milliseconds = 1.5 seconds
    return {
      user,
      isAuthenticated,
      sessionExpired
    };
  },
};
</script>
