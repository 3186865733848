<template>
  <div class="page-title">
    <h1>Relevant Bridges and Roadways</h1>
  </div>
  <v-row class="locationsHeaderRow">
    <v-col>
      <h1 class="locationHeaderText">Project Locations</h1>
    </v-col>
    <v-col class="mapCol2">
      <PrimaryButton
        :buttonText="'Show My Project on One Map'"
        @buttonClicked="navigateToGISMap('project', id)"
      >
      </PrimaryButton>
    </v-col>
  </v-row>

  <v-card class="p-64">
    <DataTable
      :headers="locationHeaders"
      :items="locationInfo"
      :showExportButton="false"
      :showClearFilterButton="false"
      hidePagination
      hideFilter
      :noDataText="locationInfoLoading"
    >
    </DataTable>
  </v-card>

  <v-row class="locationsHeaderRow">
    <v-col>
      <h1 class="locationHeaderText mt-50">
        Bridge Occupancy License (BOL) Information
      </h1>
    </v-col>
  </v-row>
  <v-card class="p-64">
    <DataTable
      :headers="bolHeaders"
      :items="bolInfo"
      :showExportButton="false"
      :items-per-page="itemsPerPage"
      :noDataText="bolInfoLoading"
    >
    </DataTable>
  </v-card>

  <v-row class="locationsHeaderRow">
    <v-col>
      <h1 class="locationHeaderText mt-50">
        Highway Occupancy Permits (HOP) Information
      </h1>
    </v-col>
  </v-row>
  <v-card class="p-64">
    <HopLocationInfo :id="id"> </HopLocationInfo>
  </v-card>

  <v-row class="locationsHeaderRow">
    <v-col>
      <h1 class="locationHeaderText mt-50">
        UR Highway Occupancy Permits (HOP) Information
      </h1>
    </v-col>
  </v-row>
  <v-card class="p-64">
    <UrHopLocationInfo :id="id"> </UrHopLocationInfo>
  </v-card>
</template>

<script>
import MpmsLocationInfo from "@/modules/locationInformation/scripts/MpmsLocationInfo.js";
export default MpmsLocationInfo;
</script>
<style>
@import "@/modules/locationInformation/assets/locationInformationstyle.scss";
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
</style>
