import useFieldEntry from "@/composables/fieldEntry";
import {
  greaterThanEqualToZero,
  requiredRule,
  tenBillionRule,
  wholeOrDecimal,
} from "@/composables/validationRules";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import {
  AUTO_COMPLETE_NO_DATA,
  DROPDOWN_NO_DATA,
} from "@/constants/displayTexts";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import axios from "axios";
import { reactive, ref, toRefs, watch } from "vue";
export default {
  components: { PrimaryButton, SecondaryButton },
  setup() {
    const { allowDecimalAndWholeNum } = useFieldEntry();
    let railRoadList = ref([]);
    let isFormValid = ref(false);
    let valueChanged = ref(false);
    const originalValue = ref({});
    const isInitialised = ref(false);
    const state = reactive({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      id: null,
      itemIndex: null,
      formData: {},
    });
    const open = (title, message, formData, id, itemIndex) => {
      state.dialog = true;
      state.title = title;
      state.message = message;
      state.formData = {
        ...formData,
        estimate: formData.estimate.replace("$", "").replaceAll(",", ""),
      };
      state.id = id;
      state.itemIndex = itemIndex;
      originalValue.value = JSON.parse(JSON.stringify(state.formData));
      setTimeout(() => {
        isInitialised.value = true;
      }, 1000);
      getRailRoadList();
      return new Promise((resolve, reject) => {
        state.resolve = resolve;
        state.reject = reject;
      });
    };
    async function getRailRoadList() {
      axios
        .get(CONFIG_ENDPOINT.COSTANDFUNDING_ESTIMATED_RAIL_ROADS)
        .then((response) => {
          railRoadList.value = response.data;
          railRoadList.value?.sort((a, b) => {
            if (a?.toLowerCase() < b?.toLowerCase()) return -1;
            if (a?.toLowerCase() > b?.toLowerCase()) return 1;
            return 0;
          });
        })
        .catch(() => {
          railRoadList.value = [];
        });
    }
    const format = (inputDate) => {
      let date = new Date(inputDate);
      if (!isNaN(date.getTime())) {
        // Months use 0 index.
        return (
          date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
        );
      }
    };
    watch(
      () => state.formData,
      (newValue) => {
        if (
          isInitialised.value &&
          !deepCompare(originalValue.value, newValue)
        ) {
          valueChanged.value = true;
        } else {
          valueChanged.value = false;
        }
      },
      { deep: true }
    );
    const deepCompare = (obj1, obj2) => {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    };
    const saveData = () => {
      state.formData.pa1CallDate = format(state.formData.pa1CallDate);
      PostOrPutData();
    };
    const PostOrPutData = () => {
      if (state.message === "Add") {
        axios
          .post(
            CONFIG_ENDPOINT.COSTANDFUNDING_ESTIMATED_SCOPING_RAILROAD_COST +
              state.id,
            {
              railroad: {
                name: state.formData.railRoad,
              },
              notes: state.formData.notes,
              estimate: state.formData.estimate,
            }
          )
          .then(() => {
            state.resolve({
              type: state.message,
              data: {
                railroad: {
                  name: state.formData.railRoad,
                },
                notes: state.formData.notes,
                estimate: state.formData.estimate,
              },
            });
            state.dialog = false;
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (state.message === "Edit") {
        axios
          .put(
            CONFIG_ENDPOINT.COSTANDFUNDING_ESTIMATED_SCOPING_RAILROAD_COST +
              state.id,
            {
              railroad: {
                name: state.formData.railRoad,
              },
              notes: state.formData.notes,
              estimate: state.formData.estimate,
              railroadScopingCostId: state.formData.railroadScopingCostId,
            }
          )
          .then(() => {
            state.resolve({
              type: state.message,
              data: {
                railRoad: state.formData.railRoad,
                notes: state.formData.notes,
                estimate: state.formData.estimate,
              },
              index: state.itemIndex,
            });
            state.dialog = false;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };
    const closeDialog = () => {
      if (isInitialised.value && valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          state.resolve(false);
          state.dialog = false;
          valueChanged.value = false;
        }
      } else {
        state.resolve(false);
        state.dialog = false;
        valueChanged.value = false;
      }
    };
    const afterModalLeave = () => {
      valueChanged.value = false;
      isInitialised.value = false;
      originalValue.value = null;
    };
    window.onbeforeunload = function () {
      if (isInitialised.value && valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };
    return {
      isFormValid,
      railRoadList,
      allowDecimalAndWholeNum,
      format,
      saveData,
      closeDialog,
      PostOrPutData,
      ...toRefs(state),
      open,
      requiredRule,
      tenBillionRule,
      greaterThanEqualToZero,
      wholeOrDecimal,
      DROPDOWN_NO_DATA,
      AUTO_COMPLETE_NO_DATA,
      afterModalLeave,
    };
  },
};
