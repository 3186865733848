<template>
  <div class="d-flex justify-content-center w-100">
    <div class="w-100">
      <div class="env-header mb-20" v-if="heading" id="list-section">
        <h2 class="locationHeaderText">{{ heading }}</h2>
      </div>
      <h2 v-if="!heading" class="mt-20">
        <span class="screen-reader">Section heading</span>
      </h2>
      <FeatureTable
        :headers="headers"
        :items="filesList"
        :showExportButton="false"
        :showClearFilterButton="true"
        :tableHeader="''"
        :itemsPerPage="paginationData?.items"
        :isDownload="true"
        :clickable="true"
        :navigationKey="'mpmsNumber'"
        @sortUpdate="sortUpdate"
        :linkedPagination="false"
        :totalItems="paginationData.total"
        :currentPage="paginationData?.page"
        @linkClicked="linkClicked($event)"
        @deleteButtonClicked="deleteButtonClicked"
        @otherButtonClicked="otherButtonClicked"
        :lookupData="lookupData"
        :oldMultipleFilter="multipleFilter"
        @textFilterChange="textFilterChange"
        @multipleFiltersChange="multipleFiltersChange"
        @clearAllFilter="clearAllFilter"
      >
      </FeatureTable>
    </div>
  </div>
  <LoadingOverlay :isLoading="loading"> </LoadingOverlay>
  <DocumentsVersionDialogComponent
    ref="priorityProjectDialogRef"
  ></DocumentsVersionDialogComponent>
</template>
<script>
import DocumentListWithFilter from "@/modules/shared/scripts/DocumentListWithFilter.js";
export default DocumentListWithFilter;
</script>
<style>
@import "@/assets/css/style.scss";
</style>
