<template>
    <v-card-text>
      <ConditionalRailroadCertifications></ConditionalRailroadCertifications>
    </v-card-text>
  </template>
  <script>
  import ConditionalRailroadCertifications from "@/modules/manageproject/components/ConditionalRailroadCertifications.vue";
  
  export default {
    name: "ViewConditionalRailroadCertification-page",
    components: {
        ConditionalRailroadCertifications: ConditionalRailroadCertifications,
    },
  };
  </script>
  