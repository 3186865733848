<template>
  <div>
    <v-row>
      <v-col cols="3" class="ml-20">
        <router-link :to="`/GCMS/${id}`">
          &lt; Return to Project Information Page
        </router-link>
      </v-col>
      <v-col cols="6" class="page-title">
        <h1>Project Workflow Notifications</h1>
      </v-col>
      <v-col cols="3"> </v-col>
    </v-row>
  </div>
  <div>
    <v-row>
      <v-col cols="1"></v-col>
      <v-col cols="10">
        <v-card class="p-64">
          <VueTable
            :columnData="headers"
            :tableData="notificationList"
            @linkClicked="linkClicked($event)"
            @sendEngineeringAuthorization="sendEngineeringAuth('Send', $event)"
            @sendAllEngineeringAuthorizations="
              sendAllEngineeringAuth('SendAll', $event)
            "
            @sendNTP="sendNTP('Send', $event)"
            @sendAllNTP="sendAllNTP('SendAll', $event)"
          >
          </VueTable>
        </v-card>
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>
  </div>
  <br />
  <LoadingOverlay :isLoading="loading"> </LoadingOverlay>
</template>
<script>
import projectWorkflowNotifications from "@/modules/manageproject/scripts/projectWorkflowNotifications.js";
export default projectWorkflowNotifications;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
</style>