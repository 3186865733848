<template>
  <v-dialog
    persistent
    @afterLeave="afterModalLeave"
    v-model="dialog"
    max-width="90vw"
    max-height="3000px"
  >
    <div class="dialogheading">
      <div class="w-100 d-flex justify-content-between">
        <div class="w-90 text-center f-size24">
          {{ title }}
        </div>
        <div>
          <v-icon @click="closeDialog" class="cursor-pointer" small
            >mdi-close
          </v-icon>
        </div>
      </div>
      <v-card>
        <v-card-text style="max-height: 80vh; overflow-y: scroll">
          <v-form v-model="isFormValid" class="pa1Form priorityForm">
            <v-grid>
              <v-row justify="center">
                <v-col cols="10">
                  <p
                    class="text-left"
                    style="padding-bottom: 1px; padding-left: 10px"
                  >
                    Maximum allowable file size is 500 MB.
                  </p>
                  <UploadScopingFieldViewDocument
                    @uploadFilesCall="uploadFiles"
                    :isDynamicDocumentType="false"
                    :staticDocumentTypeList="
                      updateEvent.documentType
                        ? [updateEvent.documentType]
                        : ['']
                    "
                    :staticDocumentTypeValue="
                      updateEvent.documentType ? updateEvent.documentType : ''
                    "
                    :isRailroadDropdownStatic="true"
                    :staticRailroadDropdownList="
                      updateEvent.railRoadName
                        ? [updateEvent.railRoadName]
                        : ['']
                    "
                    :staticRailroadDropdownValue="
                      updateEvent.railRoadName ? updateEvent.railRoadName : ''
                    "
                    :staticDOTDropdownValue="
                      updateEvent.dotNumber ? updateEvent.dotNumber : ''
                    "
                    :checkMaximumFilesUploaded="true"
                    :checkMaxFileSize="true"
                    :previewDocTypeUrl="'UPLOAD_PROJECT_DOCUMENTS_DOCTYPE_LIST'"
                    :mpmsId="id"
                    @valueChangedEvent="valueChangedEvent($event)"
                    :maximumFilesUploadRange="1"
                    :showDocTypeText="true"
                    :showRailroadTypeText="true"
                  />
                </v-col>
              </v-row>
            </v-grid>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <div class="action">
            <PrimaryButton
              :disabled="checkFormInValid"
              @buttonClicked="closeDialog"
              :buttonText="'Close'"
            ></PrimaryButton>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
  <LoadingOverlay :isLoading="loading"> </LoadingOverlay>
</template>
  <script>
import UpdateGradeCrossingDocuments from "@/modules/dot-details/scripts/UpdateGradeCrossingDocuments.js";
export default UpdateGradeCrossingDocuments;
</script>
  <style>
@import "@/modules/projectinformation/assets/projectinfostyle.scss";
@import "@/assets/css/style.scss";
</style>
 