import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import DialogComponent from "@/modules/gcmsmain/admin/submenu/components/AddEditContactsModal.vue";
import DialogComponent2 from "@/modules/gcmsmain/admin/submenu/components/ContactListDeleteConfirmation.vue";
import FeatureTable from "@/modules/shared/components/FeatureTable.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { CONFIG_ENDPOINT } from "../../../../../constants/Endpoints";


export default {
  name: "ContactList",
  components: {
    FeatureTable,
    DialogComponent,
    DialogComponent2,
    PrimaryButton,
  },
  emits: ["BannerUpdate", "saveClicked"],
  setup() {
    const route = useRoute();
    let loading = ref(true);
    const addOrEdit = route.params.action;
    const railroadId = route.params.id;
    const contactDetails = ref([]);
    const railroadDetails = ref(null);
    const AddEditContact = ref(null);
    const dialogData = ref(null);
    const deleteContactDialogRef = ref(null);

    let tab = ref("railroadInformation");

    let headers = ref([
      {
        title: "Name",
        key: "fullName",
        sortType: "text",
        columnWidth: "20%",
      },
      {
        title: "Email Address",
        key: "email",
        sortable: false,
      },
      {
        title: "Primary Phone",
        key: "primaryPhone",
        sortable: false,
        columnWidth: "150px",
      },
      {
        title: "User ID",
        key: "userId",
        sortable: false,
        columnWidth: "150px",
      },
      {
        title: "Action",
        key: "action",
        sortable: false,
        columnType: "action",
        isDelete: true,
        isEdit: true,
      },
    ]);

    const isUserRailroadContactAccess = ref({
      read: false,
      update: false,
    });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const railroadAccessCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.MANAGE_RAILROAD_COMPANIES_READ_ACCESS
      );
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.MANAGE_RAILROAD_COMPANIES_CRTUPD_ACCESS
      );
      isUserRailroadContactAccess.value.read = readResponse || false;
      isUserRailroadContactAccess.value.update = updateResponse || false;
      loading.value = false;
    };

    onMounted(async () => {
      await railroadAccessCheck();
      if (isUserRailroadContactAccess.value.read){
        fetchData();
      }
    });

    const sortData = ref({
      key: "fullName",
      order: "asc",
    });
    const paginationData = ref({
      page: 1,
      items: 15,
      total: 0,
    });
    const textFilter = ref([]);
    const multipleFilter = ref([]);

    const fetchData = () => {
      let filterText = "";
      if (sortData.value) {
        filterText =
          filterText +
          "?sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order +
          "&mpmsNumber=";
      }
      loading.value = true;

      axios
        .get(
          CONFIG_ENDPOINT.VIEW_RAILROAD_CONTACTS + route.params.id + filterText
        )
        .then((res) => {
          loading.value = false;
          console.log(res);
          railroadDetails.value = res.data;
          let list = res?.data?.contact || [];
          if (list.length) {
            for (let contact in list) {
              list[contact].fullName =
                list[contact].firstName +
                " " +
                list[contact]?.middleName +
                " " +
                list[contact].lastName;
            }
          }
          contactDetails.value = list;
        })
        .catch((err) => {
          console.log(err);
          loading.value = false;
          contactDetails.value = [];
          paginationData.value = {
            page: 1,
            items: paginationData.value.items,
            total: 0,
          };
        });
    };

    const sortUpdate = (event) => {
      if (event.length) {
        sortData.value = {
          key: event[0].key,
          order: event[0].order,
        };
      } else {
        sortData.value = null;
      }
      fetchData();
    };

    const editButtonClicked = (event) => {
      let clone = {};
      clone = { ...event.item, ...clone };
      openDialog("Edit", clone);
    };

    const openDialog = (action, item) => {
      if (action == "Add") {
        dialogData.value = {
          firstName: "",
          middleName: "",
          lastName: "",
          suffix: "",
          primaryPhone: "",
          primaryPhoneExt: "",
          secondaryPhone: "",
          secondaryPhoneExt: "",
          email: "",
          userId: "",
          postUserId: "",
          railroadContactId: "",
          railroadCompanyId: railroadDetails.value.railroadCompanyId,
        };
        AddEditContact.value
          .open("Add Contact", dialogData.value, "Add")
          .then((resolve) => {
            fetchData();
          });
      } else {
        dialogData.value = {
          firstName: item.firstName,
          middleName: item.middleName,
          lastName: item.lastName,
          suffix: item.suffix,
          primaryPhone: item.primaryPhone,
          primaryPhoneExt: item.primaryPhoneExt,
          secondaryPhone: item.secondaryPhone,
          secondaryPhoneExt: item.secondaryPhoneExt,
          email: item.email,
          userId: item.userId,
          postUserId: "",
          railroadContactId: item.railroadContactId,
          railroadCompanyId: railroadDetails.value.railroadCompanyId,
        };
        AddEditContact.value
          .open("Edit Contact", dialogData.value, "Edit")
          .then((resolve) => {
            fetchData();
          });
      }
    };

    const deleteButtonClicked = (item) => {
      console.log(item);
      let title = "Delete Contact";
      let message = "Are you sure you want to delete this Contact?";

      deleteContactDialogRef.value
        .open(title, message, item.railroadContactId)
        .then((resolve) => {
          if (resolve) {
            fetchData();
          }
        });
    };

    const pageChange = (event) => {
      paginationData.value.page = event;
      fetchData();
    };

    const updatePaginationPerPage = (event) => {
      paginationData.value.page = 1;
      paginationData.value.items = event;
      fetchData();
    };

    return {
      addOrEdit,
      railroadId,
      tab,
      headers,
      contactDetails,
      openDialog,
      deleteButtonClicked,
      railroadDetails,
      dialogData,
      DialogComponent,
      AddEditContact,
      loading,
      editButtonClicked,
      sortData,
      textFilter,
      multipleFilter,
      sortUpdate,
      paginationData,
      pageChange,
      updatePaginationPerPage,
      deleteContactDialogRef,
      isUserRailroadContactAccess,
    };
  },
};
