<template>
  <div class="expanderOverLay">
    <MaintainSignatureTemplate></MaintainSignatureTemplate>
  </div>
</template>
<script>
import MaintainSignatureTemplate from "@/modules/gcmsmain/admin/submenu/components/MaintainSignatureTemplate.vue";
export default {
  name: "ViewMaintainSignatureTemplate",
  components: {
    MaintainSignatureTemplate: MaintainSignatureTemplate,
  },
};
</script>
