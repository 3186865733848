<template>
  <div
    class="w-100 d-flex justify-content-center flex-column align-items-center"
  >
    <div class="drag-container">
      <DropZone
        @UpdateFiles="onInputChange"
        :checkMaximumFilesUploaded="checkMaximumFilesUploaded"
        :maximumFilesUploadRange="maximumFilesUploadRange"
        :checkMaxFileSize="checkMaxFileSize"
        :uploadedDocumentsLength="uploadedDocumentsLength"
        :files="files"
      />
    </div>
    <ul class="image-list" id="preview-section">
      <UploadedFilePreview
        :files="files"
        tag="li"
        @updateFiles="updateFiles($event)"
        @remove="removeFile"
        :showPreviewRailroadColumn="showPreviewRailroadColumn"
        :showPreviewDOTColumn="showPreviewDOTColumn"
        :mpmsId="mpmsId"
        :isDynamicDocumentType="isDynamicDocumentType"
        :staticDocumentTypeList="staticDocumentTypeList"
        :staticDocumentTypeValue="staticDocumentTypeValue"
        :previewDocTypeUrl="previewDocTypeUrl"
        :isRailroadDropdownStatic="isRailroadDropdownStatic"
        :staticRailroadDropdownList="staticRailroadDropdownList"
        :staticRailroadDropdownValue="staticRailroadDropdownValue"
        :staticDOTDropdownList="staticDOTDropdownList"
        :staticDOTDropdownValue="staticDOTDropdownValue"
        :showDocTypeText="showDocTypeText"
        :showRailroadTypeText="showRailroadTypeText"
        :isRailroadDropdownDisabled="isRailroadDropdownDisabled"
        :isRailroadWorkSummary="isRailroadWorkSummary"
        :isRailroadWorkSummaryDOTList="isRailroadWorkSummaryDOTList"
        :isContractorBidSafetyMilestone="isContractorBidSafetyMilestone"



      />
    </ul>
    <div class="w-100 d-flex align-items-start justify-content-end mt-20">
      <PrimaryButton
        :buttonText="'Upload Documents'"
        :disabled="disableUpload || !files.length"
        @buttonClicked="onUploadClick"
        type="button"
      ></PrimaryButton>
    </div>
  </div>
</template>


<script>
import UploadScopingFieldViewDocument from "@/modules/scoping-field-view/scripts/UploadScopingFieldViewDocument.js";
export default UploadScopingFieldViewDocument;
</script>


<style scoped>
@import "@/modules/scoping-field-view/assets/UploadScopingFieldViewDocument.scss";
</style>
