<template>
    <br class="mb-40">
    <div v-for="(item, index) in milestones">
        <div v-for="(item, index) in item">

    <div>
            <div class="safetyMilestone" >
            <v-row cols="12">
                <v-col cols="4" class="milestoneCheckboxCol">
                    <div class="milestoneCheckboxCol">
                        <div v-if="item.checkBoxNotApplicable" class="greyBox">
              <span
                class="mdi mdi-checkbox-blank-outline"
                style="
                  font-size: 30px !important;
                  font-weight: 200 !important;
                  padding-right: 20px;
                  margin-left: -4px;
                  color: black;
                "
              ></span>
            </div>
                    <div v-else-if="!item.checkBoxApproved" class="whiteBox">
                        <span class="mdi mdi-checkbox-blank-outline" style='font-size: 30px !important; font-weight: 200 !important;padding-right: 20px;margin-left:-4px; color:black'></span>
                    </div>
                    <div v-else class="squareOutline">
                        <span class="mdi mdi-checkbox-outline" style='font-size: 30px !important; font-weight: bolder !important; padding-right: 20px;margin-left:-4px;color:green;'></span>
                    </div>
                    <b><span>{{ item.displayName }}</span></b>
                </div>
                </v-col>
            </v-row>
        </div>
        <br>  <br>
            <div v-for="(item, index) in item.railRoads"
>
            <div class="railroadMilestone" >
            <v-row cols="12">
                <v-col cols="4" lg="4" sm="8" class="milestoneCheckboxCol">
                    <b><span style="padding-left: 20px !important;">{{ item.displayName }}</span></b>
                </v-col>
                <v-col cols="4" lg="4" sm="2" class="milestone">
                    <span style="padding-right:70px !important">{{item.approvedDate}}</span>
                </v-col>
                <v-col  cols="4" lg="4" sm="2" class="milestone" v-if="item.docID">
                    <i class="fa fa-arrow-circle-right" style="color: rgb(42, 87, 141); font-size: 18px; margin: 8px"></i>
                    <b><span class="milestoneView" @click="download(item.docID)"
                        >View</span></b>
                </v-col>
                <br>
            </v-row>
        </div>
    </div>
    </div>
</div>
    




</div>


  </template>
  <script>
  import AuthorizationMilestones from "@/modules/manageproject/scripts/AuthorizationMilestones.js";
  export default AuthorizationMilestones;
  </script>
  <style>
  @import "@/assets/css/style.scss";
  @import "@/modules/manageproject/assets/milestones.scss"
  </style>