<template>
  <div class="d-flex flex-column date-picker">
    <v-text-field
      class="date-picker"
      variant="outlined"
      light
      :min="minDate"
      :max="maxDate"
      :disabled="disabled"
      type="month"
      v-model="dateValue"
      @input="updateDate"
      :maxlength="6"
    ></v-text-field>
    <div v-if="invalidDate" class="error2 text-left w-100">
      <div>{{ getInvalidMsg }}</div>
    </div>
  </div>
</template>
<script>
import MonthYearPicker from "@/modules/shared/scripts/MonthYearPicker.js";
export default MonthYearPicker;
</script>
<style>
@import "@/assets/css/style.scss";

.date-picker .v-input__details {
  display: none !important;
}
</style>;
