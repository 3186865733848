<template>
  <div>
    <VueTable
      class="costAndFundingTable"
      :tableData="tableData"
      :columnData="columnData"
      :tableHeader="'Scoping Railroad Costs'"
      :tableFooter="tableFooter"
      :showAddRow="true"
      :addButtonText="'Add Railroad Estimate'"
      @editClicked="openDialog('Edit', $event)"
      @deleteClicked="deleteScopingCount($event)"
      @addClicked="openDialog('Add')"
    ></VueTable>
  </div>
  <DialogComponent
    ref="dialogComponent"
    @updateRailRoad="handleChangesSaved"
  ></DialogComponent>
</template>
<script>
import ScopingRailCost from "@/modules/cost-and-funding/scripts/ScopingRailCost.js";
export default ScopingRailCost;
</script>
<style>
@import "@/assets/css/style.scss";
</style>
