<template>
  <v-card-text>
    <addRailroads @BannerUpdate="$emit('BannerUpdate', $event)"></addRailroads>
  </v-card-text>
</template>
<script>
import addRailroads from "@/modules/manageproject/components/AddRailroads.vue";
export default {
  name: "ViewAddRailroads-page",
  components: {
    addRailroads: addRailroads,
  },
};
</script>
