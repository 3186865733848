import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import DialogComponent from "@/modules/cost-and-funding/components/ScopingRailRoadCostsDialog.vue";
import VueTable from "@/modules/shared/components/VueTable.vue";
import axios from "axios";
import { computed, getCurrentInstance, onMounted, ref, watch } from "vue";
const instance = getCurrentInstance();
const ScopingRailRoadCostData = ref([]);
const ScopingRailRoadCostInput = ref([]);
const ScopingRailRoadCostLookup = ref([]);
const refresh = ref(false);
const tableData = ref([
  {
    key: "railRoad",
    value: "",
  },
  {
    key: "notes",
    value: "",
  },
  {
    key: "estimate",
    value: "",
  },
  {
    key: "estimateValue",
    value: "",
  },
]);
const itemIndex = ref(null);
const formData = ref({ railRoad: "", notes: "", estimate: "" });
const tableFooter = ref([
  {
    colspan: 2,
    key: "total",
    value: "TOTALS",
    isBold: true,
  },
  {
    colspan: 2,
    key: "totalEstimate",
    value: "",
  },
]);
const columnData = ref([
  { name: "Railroad", key: "railRoad", isBold: true, alignLeft:true },
  { name: "Notes", key: "notes", alignLeft:true },
  { name: "Estimate", key: "estimate" },
  {
    name: "Action",
    key: "",
    isAction: true,
    isAdd: false,
    isEdit: true,
    isDelete: true,
  },
]);

export default {
  components: {
    DialogComponent,
    VueTable,
  },
  name: "ScopingRailCost",
  props: {
    id: { type: Number, default: null },
  },
  setup(props) {
    const dialogComponent = ref(null);
    const id = computed({
      get: () => {
        return props?.id;
      },
    });
    onMounted(async () => {
      await getScopingRailRoadCostsData();
    });
    watch(
      () => [props.id],
      () => {
        if (props.id) {
          getScopingRailRoadCostsData();
        }
      },
      { deep: true }
    );
    async function getScopingRailRoadCostsData() {
      axios
        .get(
          CONFIG_ENDPOINT.COSTANDFUNDING_ESTIMATED_SCOPING_RAILROAD_COST +
            id.value
        )
        .then((response) => {
          ScopingRailRoadCostLookup.value = response.data.railroadNameLookup;

          tableData.value = response.data.scopingRailroadDTOList;

          for (
            let i = 0;
            i < response.data.scopingRailroadDTOList?.length;
            i++
          ) {
            tableData.value[i].railRoad =
              response.data.scopingRailroadDTOList[i].railroad.name;
            tableData.value[i].estimateValue = tableData.value[i].estimate;
            tableData.value[i].estimate =
              "$" +
              parseFloat(tableData.value[i].estimate)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }

          setTotals();
        })
        .catch((err) => {
          ScopingRailRoadCostLookup.value = [];

          tableData.value = [];
          setTotals();
        });
    }
    const handleChangesSaved = () => {
      getScopingRailRoadCostsData();
      setTotals();
    };

    const setTotals = () => {
      let sum = 0;
      for (let i = 0; i < tableData.value?.length; i++) {
        sum += tableData.value[i].estimateValue;
      }

      tableFooter.value[1].value =
        "$" +
        parseFloat(sum)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const openDialog = (action, item) => {
      if (action === "Add") {
        dialogComponent.value
          .open(
            "Add Scoping Railroad Costs",
            "Add",
            {
              railRoad: "",
              notes: "",
              estimate: "",
            },
            id.value,
            itemIndex.value
          )
          .then((resolve) => {
            if (resolve) {
              handleChangesSaved();
            }
          });
      } else if (action === "Edit") {
        itemIndex.value = item.index;
        dialogComponent.value
          .open(
            "Edit Scoping Railroad Costs",
            "Edit",
            { ...item.item },
            id.value,
            itemIndex.value
          )
          .then((resolve) => {
            if (resolve) {
              handleChangesSaved();
            }
          });
      }
    };

    const deleteScopingCount = (item) => {
      axios
        .delete(
          CONFIG_ENDPOINT.COSTANDFUNDING_ESTIMATED_SCOPING_RAILROAD_COST +
            props.id +
            "/" +
            tableData.value[item].railroadScopingCostId
        )
        .then(() => {
          handleChangesSaved();
        })
        .catch((err) => {
          console.log(err);
        });
      setTotals();
    };
    return {
      ScopingRailRoadCostData,
      itemIndex,
      formData,
      tableFooter,
      getScopingRailRoadCostsData,
      handleChangesSaved,
      setTotals,
      openDialog,
      deleteScopingCount,
      columnData,
      instance,
      dialogComponent,
      ScopingRailRoadCostInput,
      tableData,
      ScopingRailRoadCostLookup,
      refresh,
    };
  },
};
