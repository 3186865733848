<template>
  <div>
    <div
      :class="{
        messageBanner2: successBanner,
        errorMessageBanner: errorBanner,
      }"
    >
      <div class="messageRow">
        <i
          v-if="successBanner"
          class="tableIcons fa fa-check-circle"
          style="margin-left: 18px"
        ></i>
        <i
          v-if="errorBanner"
          class="tableIcons fa fa-exclamation-circle"
          style="margin-left: 18px"
        ></i>
        <span
          ><b>{{ message }}</b>
          <div class="ml-30" v-if="hasList">
            <ul>
              <li v-for="error of errorList" :key="error">{{ error }}</li>
            </ul>
          </div>
        </span>
      </div>
      <span @click="hideBannerMessage()"
        ><i class="fa fa-times tableIcons" style="cursor: pointer"></i
      ></span>
    </div>
  </div>
</template>
<script>
import BannerMessage from "@/modules/shared/scripts/BannerMessage.js";
export default BannerMessage;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
</style>
