import { RBAC_CONTROLLER } from "@/constants/rbacconstants";import DialogComponent2 from "@/modules/manageproject/components/deleteRailroadEstimateModal.vue";
import DialogComponent1 from "@/modules/manageproject/components/railroadEstimatesDocumentDialog.vue";
import FeatureTable from "@/modules/shared/components/FeatureTable.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import router from "@/router";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { CONFIG_ENDPOINT } from "../../../constants/Endpoints";

export default {
  components: {
    PrimaryButton,
    SecondaryButton,
    FeatureTable,
    DialogComponent1,
    DialogComponent2,
  },
  name: "railroadEstimates",
  props: [],
  setup() {
    const route = useRoute();
    const id = route.params.id;
    const railroadEstimatesList = ref([]);
    const loading = ref(true);
    const railroadEstimatesDocumentsDialogRef = ref(null);
    const deleteEstimateDialogRef = ref(null);
    let headers = ref([
      {
        title: "Agreement#",
        sortType: "text",
        key: "agreementNumber",
      },
      {
        title: "Agreement Type",
        sortType: "text",
        key: "agreementTypeCode",
        width: "20% !important",
      },
      {
        title: "Railroad Name",
        sortType: "text",
        key: "railroadName",
        width: "30% !important",
      },
      {
        title: "Estimate Status",
        sortType: "text",
        key: "status",
        width: "12% !important",
      },
      {
        title: "Action",
        key: "action",
        sortable: false,
        columnType: "action",
        isEdit: true,
        isOtherButton: true,
        otherButtonText: "Review",
        isDelete: true,
        width: "10%",
      },
    ]);
    const paginationData = ref({
      page: 1,
      items: 15,
      total: 0,
    });
    const sortData = ref({
      key: "RailroadCompany.name",
      order: "asc",
    });
    const isUserEditRailroadEstimateAccess = ref({
      read: false,
      update: false,
    });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const editRailroadEstimateAccessCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.RAILROADESTIMATES_PAGE_READ_ACCESS
      );
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.RAILROADESTIMATES_PAGE_CRTUPD_ACCESS
      );
      isUserEditRailroadEstimateAccess.value.read = readResponse || false;
      isUserEditRailroadEstimateAccess.value.update = updateResponse || false;
    };

    onMounted(async () => {
      await editRailroadEstimateAccessCheck();
      if (isUserEditRailroadEstimateAccess.value.read) {
        await fetchData();
      }
    });

    async function fetchData() {
      let filterText = "";
      if (sortData.value) {
        filterText =
          filterText +
          "?sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order;
      }
      loading.value = true;
      axios
        .get(CONFIG_ENDPOINT.GET_RAILROAD_ESTIMATES_LIST + id + filterText)
        .then((response) => {
          loading.value = false;
          railroadEstimatesList.value = response.data;

          headers.value.forEach((header, index) => {
            headers.value[index] = {
              ...header,
              sortable: !checkFieldDisabled(header.key),
            };
          });
        })
        .catch((err) => {
          loading.value = false;
          railroadEstimatesList.value = [];
        });
    }

    const checkFieldDisabled = (key) => {
      return key === "action";
    };
    const sortUpdate = (event) => {
      if (event.length) {
        sortData.value = {
          key: event[0].key,
          order: event[0].order,
        };
      } else {
        sortData.value = null;
      }
      fetchData();
    };
    const addButtonClicked = (event) => {
      const cid = null;
      const eid = 0;
      router.push({
        path: "/GCMS/" + id + "/railroad-estimates/edit/" + cid + "/" + eid,
      });
    };
    const editButtonClicked = (event) => {
      router.push(
        "/GCMS/" +
          id +
          "/railroad-estimates/edit/" +
          event?.item?.railroadCompanyId +
          "/" +
          event?.item?.railroadEstimateId
      );
    };

    const otherButtonClicked = (event) => {
      console.log(event);
    };
    const formData = ref([]);
    const openDialog = (railroadName) => {
      formData.value = [];

      railroadEstimatesDocumentsDialogRef.value
        .open("View/Upload Railroad Estimate Documents", id, railroadName)
        .then((resolve) => {
          if (resolve) {
            // refresh data of required
          }
        });
    };

    const deleteEstimate = (event) => {
      axios
        .delete(
          CONFIG_ENDPOINT.RAILROAD_FORCE_ACCOUNT_ESTIMATE_EDIT_RAILROAD +
            id +
            "/" +
            event.railroadCompanyId +
            "/" +
            event.railroadEstimateId
        )
        .then((response) => {
          fetchData();
        });
    };

    const deleteButtonClicked = (event) => {
      let title = "Delete Railroad Estimate";
      let message = "Are you sure you want to delete this Railroad Estimate?";

      deleteEstimateDialogRef.value.open(title, message).then((resolve) => {
        if (resolve) {
          deleteEstimate(event);
        }
      });
    };

    return {
      railroadEstimatesList,
      loading,
      headers,
      sortUpdate,
      editButtonClicked,
      otherButtonClicked,
      paginationData,
      id,
      route,
      openDialog,
      railroadEstimatesDocumentsDialogRef,
      DialogComponent1,
      addButtonClicked,
      deleteButtonClicked,
      deleteEstimateDialogRef,
      DialogComponent2,
      deleteEstimate,
      isUserEditRailroadEstimateAccess,
    };
  },
};
