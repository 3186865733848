<template>
  <v-dialog
    persistent
    @afterLeave="afterModalLeave"
    v-model="dialog"
    max-width="1200px"
    max-height="3000px"
  >
    <div class="dialogheading">
      <div class="w-100 d-flex justify-content-between">
        <div class="w-90 text-center f-size24">
          {{ title }}
        </div>
        <div>
          <v-icon @click="closeDialog" class="cursor-pointer" small
            >mdi-close
          </v-icon>
        </div>
      </div>
      <v-card>
        <v-card-text style="max-height: 450px; overflow-y: scroll">
          <v-row style="margin: 0">
            <v-col cols="12">
              <v-table class="design-table">
                <thead>
                  <tr>
                    <th scope="col" class="w-10"></th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone</th>
                    <th scope="col">GCMS User ID</th>
                    <th scope="col">GCMS User Role</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="contacts of event?.contact" :key="contacts">
                    <td class="w-10">
                      <v-checkbox-btn v-model="contacts.checked" class="pe-2">
                      </v-checkbox-btn>
                    </td>
                    <td>{{ contacts.fullName }}</td>
                    <td>{{ contacts.email }}</td>
                    <td>{{ contacts.primaryPhone }}</td>
                    <td>{{ contacts.userID }}</td>
                    <td>{{ contacts.userRole }}</td>
                  </tr>
                  <tr v-if="!event?.contact">
                    <td colspan="6" class="text-center">
                      No Contacts have been identified for this Railroad. Please
                      go to the Maintain Railroad Admin page to add one or more
                      Contacts.
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <div class="action">
            <PrimaryButton
              @buttonClicked="saveData"
              :buttonText="'Save'"
            ></PrimaryButton>
            <SecondaryButton
              @buttonClicked="closeDialog"
              :buttonText="'Cancel'"
              class="ml-2"
            ></SecondaryButton>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>
<script>
import AddRailroadsContactsDialog from "@/modules/manageproject/scripts/AddRailroadsContactsDialog.js";

export default AddRailroadsContactsDialog;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
.district-container {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.district-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
</style>