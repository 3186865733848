export default () => {  function toTitleCase(str) {
    return str?.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  function ExportToCSV(headers, list, exportFilName) {
    const col = [];
    const column = headers?.map((header) => header.key);
    headers?.forEach((header) =>
      col.push(getExportHeaderName(headers, header.key))
    );
    let csvContent = col.join(",") + "\n";
    list?.forEach((data) => {
      let array = [];
      column?.forEach((key) => {
        let value = data[key] || "";
        value = value?.toString();
        if (
          key?.toLowerCase()?.includes("district") ||
          key?.toLowerCase()?.includes("segment") ||
          key?.toLowerCase() === "srno" ||
          key?.toLowerCase() === "sr" ||
          key?.toLowerCase()?.includes("stateroute")
        ) {
          value = '=""' + value + '""';
        }
        array.push(`"${value || ""}"`);
      });
      csvContent += array.join(",") + "\n";
    });

    const blob = new Blob([csvContent], {
      type: "text/csv;charset=utf-8;",
    });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportFilName + ".csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
  const getExportHeaderName = (headers, header) => {
    let heading = "";
    headers?.forEach((tableHeader) => {
      if (tableHeader.key === "projectIdentifier") {
        heading = "Project Identifier";
      } else if (tableHeader.key === header) {
        heading = tableHeader.title || tableHeader?.name;
      }
    });
    return heading;
  };

  const checkAddressInputValidation = (formData) => {
    return (
      formData.address1 &&
      formData.city &&
      formData.stateCode &&
      formData.zipcode &&
      /\d{5}(?:-\d{4})?$/.test(formData.zipcode)
    );
  };
  const checkValidZipcode = (formData) => {
    return formData.zipcode && /\d{5}(?:-\d{4})?$/.test(formData.zipcode);
  };
  const checkValidPhoneCode = (phoneNumber) => {
    return phoneNumber && phoneNumber.length == 14;
  };
  const checkValidEmailcode = (formData) => {
    let emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+ "))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return formData.email && emailRegex.test(formData.email);
  };
  const districtOfficeEmailCheck = (formData) => {
    let email =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+ "))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let requiredEmailTest =
      email.test(formData?.notificationEmail) &&
      ((formData?.emails?.[0]?.dcmEmail &&
        email.test(formData?.emails?.[0]?.dcmEmail)) ||
        (formData?.dcmEmail1 && email.test(formData?.dcmEmail1)));
    if (
      formData?.emails?.[1]?.dcmEmail ||
      formData?.dcmEmail2 ||
      formData?.emails?.[2]?.dcmEmail ||
      formData?.dcmEmail3
    ) {
      let dcEmail =
        (formData?.emails?.[1]?.dcmEmail &&
          email.test(formData?.emails?.[1]?.dcmEmail)) ||
        (formData?.dcmEmail2 && email.test(formData?.dcmEmail2)) ||
        (formData?.emails?.[2]?.dcmEmail &&
          email.test(formData?.emails?.[2]?.dcmEmail)) ||
        (formData?.dcmEmail3 && email.test(formData?.dcmEmail3));

      return requiredEmailTest && dcEmail;
    }
    return requiredEmailTest;
  };
  return {
    toTitleCase,
    ExportToCSV,
    checkAddressInputValidation,
    checkValidZipcode,
    districtOfficeEmailCheck,
    checkValidEmailcode,
    checkValidPhoneCode,
  };
};
