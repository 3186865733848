<template>
  <v-row>
    <!-- Left corner element -->
    <v-col class="ml-20">
      <router-link :to="`/GCMS/${id}`">
        &lt; Return to Project Information Page
      </router-link>
    </v-col>
    <!-- Centered element -->
    <v-col class="page-title">
      <h1>Railroad Plan Review</h1>
    </v-col>
    <v-col> </v-col>
  </v-row>
  <div>
    <div>
      <div class="env-header mb-20">
        <h2 class="locationHeaderText">Project Plans</h2>
      </div>
      <v-row cols="12">
        <v-col cols="12">
          <div class="w-100 text-center mt-20">
            <span
              v-if="
                isUserRailroadPlanAccess.update &&
                !(
                  projectPlanSendData?.plan30Enable &&
                  projectPlanSendData?.plan60Enable &&
                  projectPlanSendData?.plan90Enable &&
                  projectPlanSendData?.plan100Enable
                )
              "
              class="color-blue underline cursor-pointer"
              @click="uploadButtonClicked(true)"
              >Upload Project Plan</span
            >
          </div>
        </v-col>
      </v-row>
      <v-grid>
        <v-row justify="center">
          <v-col cols="10">
            <DocumentsList
              v-if="isUserRailroadPlanAccess.read"
              :heading="''"
              :doctype="'/PP_UPLD_DT'"
              :id="id"
              :updateDocumentList="updateDocumentList"
              @updateDocumentListFlag="updateDocumentListFlag"
              :isUpdateActionButton="true"
              @otherButtonClicked="updateButtonClicked($event, true)"
            >
            </DocumentsList>
            <DocumentsList v-if="!isUserRailroadPlanAccess.read">
            </DocumentsList>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="10">
            <table class="sentTable" aria-describedby="sentTable">
              <tr>
                <th scope="col"></th>
                <th scope="col">
                  <PrimaryButton
                    :buttonText="'Send 30% Plans'"
                    @buttonClicked="sendClicked('30')"
                    :disabled="
                      !projectPlanSendData?.plan30Enable ||
                      !isUserRailroadPlanAccess.update
                    "
                  ></PrimaryButton>
                </th>
                <th scope="col">
                  <PrimaryButton
                    :buttonText="'Send 60% Plans'"
                    @buttonClicked="sendClicked('60')"
                    :disabled="
                      !projectPlanSendData?.plan60Enable ||
                      !isUserRailroadPlanAccess.update
                    "
                  ></PrimaryButton>
                </th>
                <th scope="col">
                  <PrimaryButton
                    :buttonText="'Send 90% Plans'"
                    @buttonClicked="sendClicked('90')"
                    :disabled="
                      !projectPlanSendData?.plan90Enable ||
                      !isUserRailroadPlanAccess.update
                    "
                  ></PrimaryButton>
                </th>
                <th scope="col">
                  <PrimaryButton
                    :buttonText="'Send 100% Plans'"
                    @buttonClicked="sendClicked('100')"
                    :disabled="
                      !projectPlanSendData?.plan100Enable ||
                      !isUserRailroadPlanAccess.update
                    "
                  ></PrimaryButton>
                </th>
              </tr>
              <tr>
                <th scope="col">Date Sent:</th>
                <th scope="col">{{ projectPlanSendData?.plan30Date }}</th>
                <th scope="col">
                  {{ projectPlanSendData?.plan60Date }}
                </th>
                <th scope="col">
                  {{ projectPlanSendData?.plan90Date }}
                </th>
                <th scope="col">
                  {{ projectPlanSendData?.plan100Date }}
                </th>
              </tr>
            </table>
          </v-col>
        </v-row>
      </v-grid>
    </div>
    <div>
      <div class="env-header mb-30 mt-50">
        <h2 class="locationHeaderText">Railroad Responses</h2>
      </div>
      <v-row cols="12">
        <v-col cols="12">
          <div class="w-100 text-center mt-20">
            <span
              v-if="isUserRailroadPlanAccess.update"
              class="color-blue underline cursor-pointer"
              @click="uploadButtonClicked(false)"
              >Upload Railroad Responses
            </span>
          </div>
        </v-col>
      </v-row>
      <v-grid>
        <v-row justify="center">
          <v-col cols="10">
            <DocumentsList
              v-if="isUserRailroadPlanAccess.read"
              :heading="''"
              :doctype="'/RR_RESP_DT'"
              :id="id"
              :updateDocumentList="updateRailroadResourceDocumentList"
              @updateDocumentListFlag="updateRailroadResourceFlag"
              :isUpdateActionButton="true"
              @otherButtonClicked="updateButtonClicked($event, false)"
            >
            </DocumentsList>
            <DocumentsList v-if="!isUserRailroadPlanAccess.read">
            </DocumentsList>
          </v-col>
        </v-row>
      </v-grid>
    </div>
    <DialogComponent ref="uploadRailroadProjectPlanDialogRef"></DialogComponent>

    <LoadingOverlay :isLoading="loading"> </LoadingOverlay>
  </div>
</template>
  <script>
import RailroadPlanReview from "@/modules/manageproject/scripts/RailroadPlanReview.js";
export default RailroadPlanReview;
</script>
  
  <style>
@import "@/assets/css/style.scss";
@import "@/modules/manageproject/assets/railroadEstimates.scss";
.sentTable tr:hover {
  background: transparent;
}
</style>
  