<template>
  <v-dialog v-model="dialog" max-width="800px" max-height="3000px">
    <div class="dialogheading">
      <div class="w-100 d-flex justify-content-between">
        <div class="w-90 text-center f-size24">
          Remove Railroad From Crossing
        </div>
        <div>
          <v-icon @click="closeDialog" class="cursor-pointer" small
            >mdi-close
          </v-icon>
        </div>
      </div>
      <v-card>
        <v-card-text>
          <div class="impactedWarning" v-if="lastCrossingMessage != ''">
            {{ lastCrossingMessage }}
            <br />
            <br />
          </div>
          <div class="impactedMessage">
            {{ message }}
          </div>
          <br />
        </v-card-text>
        <v-card-actions>
          <div class="action">
            <PrimaryButton
              @buttonClicked="removeRailroad"
              :buttonText="'YES'"
            ></PrimaryButton>
            <SecondaryButton
              @buttonClicked="closeDialog"
              :buttonText="'NO'"
              class="ml-2"
            ></SecondaryButton>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>
<script>
import RemoveRailroadModal from "@/modules/manageproject/scripts/RemoveRailroadModal.js";
export default RemoveRailroadModal;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
@import "@/modules/cost-and-funding/assets/ScopingRailRoadCostsDialog.scss";
</style>
