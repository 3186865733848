import useDateField from "@/composables/dateField.js";import useIconsFunctions from "@/composables/projectIdentifiersIcons";
import useSharedFunctions from "@/composables/shared";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import FeatureTable from "@/modules/shared/components/FeatureTable.vue";
import OnlineHelpPOC from "@/modules/shared/components/OnlineHelpPOC.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import sharedUserService from "@/modules/shared/service/sharedUserService";
import router from "@/router";
import axios from "axios";
import { computed, onMounted, ref } from "vue";

export default {
  name: "ConsultantAgreement",
  components: { FeatureTable, OnlineHelpPOC, PrimaryButton },
  setup() {
    const { getIdentifierIcon } = useIconsFunctions();
    const lookupData = ref(null);
    const consultantAgrement = ref([]);
    const loading = ref(true);
    const userData = computed({
      get: () => {
        return sharedUserService.getSharedData();
      },
    });
    let headers = ref([
      {
        title: "Railroad Name",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
        key: "railroadCompanyName",
        isLink: true,
      },
      {
        title: "Consultant",
        sortType: "text",
        key: "consultantUserName",
        width: "25%",
      },
      {
        title: "Consulting Company",
        filter: [],
        filterType: "multi-select",
        filterSort: "number",
        sortType: "text",
        key: "consultantCompany",
      },
      {
        title: "Project Scope",
        sortType: "text",
        key: "scopeOfProject",
      },
      {
        title: "End Date",
        sortType: "date",
        key: "endDate",
      },
      {
        title: "Is Active",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
        key: "isActive",
      },
      {
        title: "Status",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
        key: "status",
      },
      {
        title: "Action",
        key: "action",
        columnType: "action",
        sortable: false,
        isAction: true,
        width: "20% !important",
        isEdit: true,
        isOtherButton: true,
        otherButtonText: "Review",
      },
    ]);
    const paginationData = ref({
      page: 1,
      items: 15,
      total: 0,
    });
    const sortData = ref({
      key: "",
      order: "",
    });
    const textFilter = ref([]);
    const multipleFilter = ref([]);
    const { getFormattedDateStringNoTime } = useDateField();
    const currentFilters = ref(null);
    const { ExportToCSV } = useSharedFunctions();
    const getDate = (date) => {
      return getFormattedDateStringNoTime(date);
    };
    onMounted(async () => {
      await fetchData();
    });

    const getLookupData = (filterText, Filter) => {
      let filterText1 =
        "?page=" +
        (paginationData.value.page - 1) +
        "&size=" +
        paginationData.value.items +
        "&userID=" +
        userData.value?.userID +
        "&isUserProject=" +
        true;
      if (sortData.value) {
        filterText1 =
          filterText1 +
          "&sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order;
      }

      axios
        .post(
          CONFIG_ENDPOINT.CONSULTANT_AGREEMENT_LIST_LOOKUP + filterText1,
          Filter
        )
        .then((response) => {
          lookupData.value = response.data.data;

          headers.value.forEach((header, index) => {
            headers.value[index] = {
              ...header,
              sortable: !checkFieldDisabled(header.key, header?.filterType),
            };
          });
        })
        .catch((err) => {
          lookupData.value = null;
        });
    };
    async function fetchData() {
      let Filter = {
        railroadCompanyName: null,
        consultantUserName: null,
        consultantCompany: null,
        scopeOfProject: null,
        endDate: null,
        isActive: null,
        status: null,
      };
      textFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter.key) && filter.value) {
          Filter[filter.key] = filter.value;
        }
      });
      multipleFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter?.key) && filter.value) {
          Filter[filter.key] = filter.value;
        }
      });
      let filterText =
        "?page=" +
        (paginationData.value.page - 1) +
        "&size=" +
        paginationData.value.items +
        "&userID=" +
        userData.value?.userID;
      if (sortData.value) {
        filterText =
          filterText +
          "&sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order;
      }
      loading.value = true;
      axios
        .post(CONFIG_ENDPOINT.CONSULTANT_AGREEMENT_LIST + filterText, Filter)
        .then((response) => {
          setActiveProjects(response, Filter, filterText);
        })
        .catch((err) => {
          loading.value = false;
          consultantAgrement.value = [];
          paginationData.value = {
            page: 1,
            items: paginationData.value.items,
            total: 0,
          };
        });
    }
    const setActiveProjects = (response, Filter, filterText) => {
      loading.value = false;
      consultantAgrement.value = response.data.data;
      consultantAgrement.value.forEach((item) => {
        let icons = "";
        item?.projectIdentifier?.forEach((icon) => {
          icons = icons + getIdentifierIcon(icon);
        });
        item.projectIdentifierIcon = icons;
        item.endDate = getDate(item.endDate);
      });
      paginationData.value.total = response.data.totalElements;
      currentFilters.value = Filter;

      getLookupData(filterText, Filter);
    };
    const exportButtonClicked = () => {
      let filterText = "?page=" + 0 + "&size=" + paginationData.value.total;
      if (sortData.value) {
        filterText =
          filterText +
          "&sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order +
          "&userID=" +
          userData.value?.userID;
      }
      axios
        .post(
          CONFIG_ENDPOINT.CONSULTANT_AGREEMENT_LIST + filterText,
          currentFilters.value
        )
        .then((response) => {
          setActiveProjectsExportDetails(response);
        })
        .catch((err) => {});
    };
    const setActiveProjectsExportDetails = (response) => {
      loading.value = false;
      let exportDataList = response.data.data;
      exportDataList.forEach((item) => {
        let icons = "";
        item?.projectIdentifier?.forEach((icon) => {
          icons = icons + getIdentifierIcon(icon);
        });
        item.projectIdentifierIcon = icons;
        item.endDate = getDate(item.endDate);
      });
      ExportToCSV(headers.value, exportDataList, "Consultant Agreements");
    };
    const checkFieldDisabled = (key, filterType) => {
      if (
        key === "projectIdentifier" ||
        key === "action" ||
        key === "consultantUserName"
      ) {
        return true;
      }
      if (
        filterType != "auto-complete" ||
        filterType != "multi-select" ||
        filterType != "single-auto-complete"
      ) {
        return false;
      }
      if (
        lookupData.value[key]?.length === 1 &&
        lookupData.value[key][0] === null
      ) {
        return true;
      }
      return !lookupData.value[key]?.length;
    };
    const updatePaginationPerPage = (event) => {
      paginationData.value.page = 1;
      paginationData.value.items = event;
      fetchData();
    };
    const pageChange = (event) => {
      paginationData.value.page = event;
      fetchData();
    };
    const sortUpdate = (event) => {
      if (event.length) {
        sortData.value = {
          key: event[0].key,
          order: event[0].order,
        };
      } else {
        sortData.value = null;
      }
      fetchData();
    };
    const clearAllFilter = (event) => {
      textFilter.value = [];
      multipleFilter.value = [];
      fetchData();
    };
    const textFilterChange = (event) => {
      let index = textFilter?.value?.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value && event.value.length > 0) {
          // Ensure value is not empty
          textFilter.value[index] = event;
        } else {
          textFilter.value.splice(index, 1);
        }
      } else if (event.value && event.value.length > 0) {
        textFilter.value.push(event);
      }
      fetchData(); // Continue to make API call only if the filter has valid values
    };
    const multipleFiltersChange = (event) => {
      let index = multipleFilter.value?.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value?.length) {
          multipleFilter.value[index] = event;
        } else {
          multipleFilter.value.splice(index, 1);
        }
      } else {
        multipleFilter.value.push(event);
      }
      fetchData();
    };
    const addButtonClicked = () => {
      console.log("addButtonClicked");
      router.push({
        path: "/GCMS/consultant-agreement/add/null",
      });
      scrollTo(0, 0);
    };
    const editButtonClicked = (event) => {
      console.log("editButtonClicked", event);
      router.push({
        path:
          "/GCMS/consultant-agreement/edit/" +
          event?.item?.railroadConsultantAgreementId,
      });
      scrollTo(0, 0);
    };
    return {
      editButtonClicked,
      consultantAgrement,
      loading,
      headers,
      updatePaginationPerPage,
      pageChange,
      sortUpdate,
      paginationData,
      textFilterChange,
      clearAllFilter,
      multipleFiltersChange,
      multipleFilter,
      lookupData,
      currentFilters,
      exportButtonClicked,
      userData,
      addButtonClicked,
    };
  },
};
