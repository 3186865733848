import ConsultantCompanyInfo from "@/modules/gcmsmain/admin/submenu/components/ConsultantCompanyInfo.vue";
import ConsultingCompanyUsers from "@/modules/gcmsmain/admin/submenu/components/ConsultingCompanyUsers.vue";

import { ref } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "ConsultingCompany",
  components: { ConsultantCompanyInfo, ConsultingCompanyUsers },
  setup() {
    const route = useRoute();
    const companyName = route.params.id;
    const tab = ref("companyInformation");

    return {
     route,
     companyName,
     tab,
    };
  },
};
