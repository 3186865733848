import {
  numericRule,
  requiredRule,
  zipRule,
} from "@/composables/validationRules";
import ZIPInput from "@/modules/shared/components/ZIPInput.vue";
import { computed, ref } from "vue";

export default {
  components: {ZIPInput},
  props: ["formData", "lookupData", "isPage"],
  setup(props, context) {
    let typingValues = ref({
      zip: { stoppedTyping: null },
    });
    const isPage = computed({
      get: () => {
        return props?.isPage;
      },
    });
    const lookupData = computed({
      get: () => {
        if (props?.lookupData) {
          return props?.lookupData;
        }
      },
    });
    const formData = computed({
      get: () => {
        console.log("props?.formData", props?.formData);
        if (props?.formData) {
          return props?.formData;
        }
      },
    });
  
    const fieldValueChange = (field) => {
      typingValues.value[field].stoppedTyping = false;
    };
    const setFieldRule = (field) => {
      typingValues.value[field].stoppedTyping = true;
    };
    const getZipFieldRule = computed({
      get: () => {
        return typingValues.value["zip"].stoppedTyping;
      },
    });

    function createDebounce() {
      let timeout = null;
      return function (fnc, delayMs) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fnc();
        }, delayMs || 1000);
      };
    }

   
    function isLeftUpRightDownBackspaceDeleteTab(event) {
      let key = event.key || event.code;
      return (
        key === "ArrowLeft" ||
        key === 37 ||
        key === "ArrowUp" ||
        key === 38 ||
        key === "ArrowRight" ||
        key === 39 ||
        key === "ArrowDown" ||
        key === 40 ||
        key === "Backspace" ||
        key === 8 ||
        key === "Delete" ||
        key === 46 ||
        key === "Tab" ||
        key === 9
      );
    }

    const filterObject = (item, queryText, itemText) => {
      return item?.toLowerCase()?.startsWith(queryText?.toLowerCase());
    };

    return {
      requiredRule,
      formData,
      fieldValueChange,
      setFieldRule,
      getZipFieldRule,
      numericRule,
      debounce: createDebounce(),
      isLeftUpRightDownBackspaceDeleteTab,
      filterObject,
      typingValues,
      lookupData,
      isPage,
      zipRule,
    };
  },
};
