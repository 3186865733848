
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import DialogComponent1 from "@/modules/manageproject/components/RailroadWorkSummaryDocumentDialog.vue";
import DocumentsList from "@/modules/shared/components/DocumentsList.vue";
import { useDocumentStore } from "@/stores/document";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { CONFIG_ENDPOINT } from "../../../constants/Endpoints";

export default {
  components: {
    DialogComponent1,
    DocumentsList,
  },
  name: "RailroadDocuments",
  props: ["dotId", "railroad", "railroadMap", "railroadList"],
  setup(props, { emit }) {
    const documentStore = useDocumentStore();
    const updateDocumentList = ref(false);
    const railroadWorkSummaryDocumentDialogRef = ref(null);
    const filesList = ref([]);
    const railroadId = ref(null);
    const selectedRailroad = ref(null);
    const railroadMap = ref({})
    const railroadList = ref([]);
    const crossingDetails = ref([]);
    const dotNumbersList = ref([]);



    const otherButtonClicked = () => {
      openDialog(false);
    };
    const updateDocumentListFlag = () => {
      updateDocumentList.value = false;
    };

    async function fetchEstimateData() {
      updateDocumentList.value = true;
    }

    const route = useRoute();
    const id = route.params.id;
   

    const isUserRailroadWorkSummaryAccess = ref({
      read: false,
      update: false,
    });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const railroadAccessCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.RAILROAD_WORK_SUMMARY_READ_ACCESS
      );
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.RAILROAD_WORK_SUMMARY_PAGE_CRTUPD_ACCESS
      );
      isUserRailroadWorkSummaryAccess.value.read = readResponse || false;
      isUserRailroadWorkSummaryAccess.value.update = updateResponse || false;
    };

    onMounted(async () => {
      await railroadAccessCheck();
      if (isUserRailroadWorkSummaryAccess.value.read) {
        railroadMap.value = props.railroadMap;
        selectedRailroad.value = props.railroad;
        railroadId.value = railroadMap.value[selectedRailroad.value];
        fetchEstimateData();
        fetchCrossingDetails()

      }
    });

    watch(
      () => [props.railroad],
      () => {
     
        if (props.railroad) {
          selectedRailroad.value = props.railroad;
          railroadId.value = railroadMap.value[props.railroad];
          railroadList.value = props.railroadList;
          fetchEstimateData();
          fetchCrossingDetails()


        }
      },
      { deep: true }
    );

    function fetchCrossingDetails() {
      axios
        .get(
          CONFIG_ENDPOINT.RAILROAD_WORK_SUMMARY_CROSSING_DETAILS +
            id +
            "/" +
            railroadId.value
        )
        .then((response) => {
          crossingDetails.value = response.data.data;
          dotNumbersList.value = [];
          for (let index in crossingDetails.value) {
            dotNumbersList.value.push(
              crossingDetails.value[index].dotNumber.replace("DOT# ", "")
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }



    const updateButtonClicked = (event) => {
      openDialog(true, event);
    };

    const openDialog = (isUpdate, event) => {
      railroadWorkSummaryDocumentDialogRef.value
        .open(
          isUpdate
            ? "Update Document"
            : "View/Upload Railroad Work Summary Documents",
          id,
          selectedRailroad,
          railroadList.value,
          isUpdate,
          event,
          dotNumbersList.value, 
          railroadId.value
        )
        .then((resolve) => {
          if (resolve) {
            fetchEstimateData();
          }
        });
    };

    return {
      documentStore,
      updateDocumentList,
      railroadWorkSummaryDocumentDialogRef,
      filesList,
      updateDocumentListFlag,
      otherButtonClicked,
      id,
      updateButtonClicked,
      fetchEstimateData,
      isUserRailroadWorkSummaryAccess,
      railroadId,
      railroadMap,
      selectedRailroad,
      railroadList,
      crossingDetails,
      dotNumbersList,
    };
  },
};