<template>
  <div class="expanderOverLay">
    <CompletedCrossingsInspections></CompletedCrossingsInspections>
  </div>
</template>
<script>
import CompletedCrossingsInspections from "@/modules/gcmsmain/districtfunctions/components/completedCrossingsInspections.vue";
export default {
  name: "viewCompletedCrossings-page",
  components: {
    CompletedCrossingsInspections: CompletedCrossingsInspections,
  },
};
</script>
