import { requiredRule } from "@/composables/validationRules";import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import axios from "axios";
import { reactive, ref, toRefs, watch } from "vue";
export default {
  components: { PrimaryButton, SecondaryButton },
  props: {
    formData: {
      type: Object,
      default: {
        activity: "",
        status: "",
      },
    },
    id: { type: String, default: null },
    item: Object, // Item to be edited (if in 'edit' mode)    projectDetails: Object,
    itemIndex: "",
    previousName: { type: String, default: null },
  },
  setup() {
    let isFormValid = ref(false);
    let showWarning = ref(false);
    let tempName = ref("");
    let statusList = ref(["Active", "Inactive"]);
    let activityChanged = ref(false);
    let originalActivity = ref("");
    let originalStatus = ref("");
    let valueChanged = ref(true);
    const state = reactive({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      itemIndex: null,
      formData: "OK",
    });

    const open = (title, message, formData, itemIndex) => {
      state.dialog = true;
      state.title = title;
      state.message = message;
      state.formData = formData;
      state.itemIndex = itemIndex;
      originalActivity.value = state.formData.activity;
      originalStatus.value = state.formData.status;
      return new Promise((resolve, reject) => {
        state.resolve = resolve;
        state.reject = reject;
      });
    };
    const saveData = () => {
      let tempInt = state.itemIndex;
      PostOrPutData(tempInt);
    };
    const PostOrPutData = (value) => {
      if (state.message === "Add") {
        axios
          .post(CONFIG_ENDPOINT.GCMS_MAIN_FLAGGER_ACTIVITY, {
            activity: state.formData.activity,
            status: state.formData.status,
          })
          .then(() => {
            state.resolve({
              type: state.message,
              data: {
                activity: state.formData.activity,
                status: state.formData.status,
              },
            });
            showWarning.value = false;
            state.dialog = false;
          })
          .catch((err) => {
            tempName.value = state.formData.activity;
            showWarning.value = true;
          });
      } else if (state.message === "Edit") {
        if (
          originalActivity.value !== state.formData.activity ||
          originalStatus.value !== state.formData.status
        ) {
          activityChanged.value = true;
        } else {
          activityChanged.value = false;
        }
        if (
          (originalActivity.value !== state.formData.activity ||
            originalStatus.value !== state.formData.status) &&
          activityChanged.value
        ) {
          axios
            .put(
              CONFIG_ENDPOINT.GCMS_MAIN_FLAGGER_ACTIVITY +
                CONFIG_ENDPOINT.SLASH +
                value +
                "/" +
                activityChanged.value,
              {
                activity: state.formData.activity,
                oldActivity: originalActivity.value,
                status: state.formData.status,
              }
            )
            .then(() => {
              state.resolve({
                type: state.message,
                data: {
                  activity: state.formData.activity,
                  status: state.formData.status,
                },
                index: state.itemIndex,
              });
              showWarning.value = false;
              state.dialog = false;
            })
            .catch((err) => {
              tempName.value = state.formData.activity;
            });
        } else {
          showWarning.value = true;
          tempName.value = state.formData.activity;
        }
      }
    };
    const closeDialog = () => {
      if (valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          state.resolve(false);
          state.dialog = false;
          tempName.value = "";
          showWarning.value = false;
          valueChanged.value = false;
          state.formData.activity = originalActivity.value;
          state.formData.status = originalStatus.value;
        } else {
          next(false);
          valueChanged.value = false;
        }
      } else {
        state.resolve(false);
        state.dialog = false;
        tempName.value = "";
        showWarning.value = false;
      }
    };
    watch(
      () => [state.formData],
      () => {
        if (
          originalActivity.value !== state.formData.activity ||
          originalStatus.value !== state.formData.status
        ) {
          valueChanged.value = true;
        } else {
          valueChanged.value = false;
        }
      },
      { deep: true }
    );
    const afterModalLeave = () => {
      state.formData.activity = originalActivity.value;
      state.formData.status = originalStatus.value;
      valueChanged.value = false;
    };
    window.onbeforeunload = function () {
      if (valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };
    return {
      ...toRefs(state),
      open,
      isFormValid,
      showWarning,
      tempName,
      statusList,
      saveData,
      PostOrPutData,
      closeDialog,
      requiredRule,
      activityChanged,
      originalActivity,
      afterModalLeave,
    };
  },
};
