<template>
  <div class="mb-40" v-if="isUserCostAndFundingAccess.read">
    <div class="page-title">
      <h1>Project Funding Information</h1>
    </div>
    <v-row>
      <v-col cols="6">
        <EstimatedProjectFundingInfo :id="id"></EstimatedProjectFundingInfo>
      </v-col>
      <v-col cols="6">
        <ProgramFundingInfo :id="id"></ProgramFundingInfo>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <WbsInfo :id="id"></WbsInfo>
      </v-col>
    </v-row>
    <div class="page-title mt-20">
      <h1>Railroad Costs</h1>
    </div>
    <v-row>
      <v-col cols="12">
        <EstimatedRailRoadCost :id="id"></EstimatedRailRoadCost>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <ScopingRailroadCosts :id="id"></ScopingRailroadCosts>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import CostAndFunding from "@/modules/cost-and-funding/scripts/CostAndFunding.js";
export default CostAndFunding;
</script>
<style>
@import "@/assets/css/style.scss";
</style>