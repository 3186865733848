import useSharedFunctions from "@/composables/shared";import IconButton from "@/modules/shared/components/IconButton.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import { computed, onMounted, ref } from "vue";
import { TABLE_NO_DATA_AVAILABLE } from "../../../constants/displayTexts";

export default {
  name: "VueTable",
  components: { SecondaryButton, IconButton, PrimaryButton },
  props: [
    "tableData",
    "columnData",
    "tableFooter",
    "tableHeader",
    "showAddRow",
    "addButtonText",
    "errorMessage",
    "invalidErrorClass",
    "showItalics",
    "instruction",
    "linkIndicator",
    "disableActions",
    "showExportButton",
    "isDynamicExport",
    "exportFilName",
  ],
  setup(props, context) {
    const { ExportToCSV } = useSharedFunctions();
    let showItalics = ref(props?.showItalics);
    let exportFilName = ref(props?.exportFilName);
    let tableData = computed({
      get: () => {
        return props?.tableData || [];
      },
    });
    let columnData = computed({
      get: () => {
        return props?.columnData || [];
      },
    });
    let tableFooter = computed({
      get: () => {
        return props?.tableFooter || [];
      },
    });
    let tableHeader = computed({
      get: () => {
        return props?.tableHeader || "";
      },
    });
    let showExportButton = computed({
      get: () => {
        return props?.showExportButton || "";
      },
    });
    let instruction = computed({
      get: () => {
        return props?.instruction || "";
      },
    });
    let linkIndicator = computed({
      get: () => {
        return props?.linkIndicator || "";
      },
    });
    let showAddRow = computed({
      get: () => {
        return props?.showAddRow || false;
      },
    });
    let addButtonText = computed({
      get: () => {
        return props?.addButtonText || "";
      },
    });
    let errorMessage = computed({
      get: () => {
        return props?.errorMessage || TABLE_NO_DATA_AVAILABLE;
      },
    });
    let invalidErrorClass = computed({
      get: () => {
        return props?.invalidErrorClass || false;
      },
    });
    let disableActions = computed({
      get: () => {
        return props?.disableActions || false;
      },
    });

    const addRowClicked = (item) => {
      context.emit("addRowClicked", item);
    };
    const otherRowClicked = (item) => {
      context.emit("otherRowClicked", item);
    };
    const addClicked = (item) => {
      context.emit("addClicked", item);
    };
    const editClicked = (item, index) => {
      context.emit("editClicked", { item: item, index: index });
    };
    const deleteClicked = (index) => {
      context.emit("deleteClicked", index);
    };
    const sendEngineeringAuthorization = (item, index) => {
      context.emit("sendEngineeringAuthorization", {
        item: item,
        index: index,
      });
    };

    const sendAllEngineeringAuthorizations = (item, index) => {
      context.emit("sendAllEngineeringAuthorizations", {
        item: item,
        index: index,
      });
    };

    const sendNTP = (item, index) => {
      context.emit("sendNTP", {
        item: item,
        index: index,
      });
    };
    const sendAllNTP = (item, index) => {
      context.emit("sendAllNTP", {
        item: item,
        index: index,
      });
    };

    const linkClicked = (key, value) => {
      context.emit("linkClicked", { key: key, value: value });
    };
    const checkEditDisabled = (item, column) => {
      if (column.editDisable) {
        return item[column.editDisable.key] === column.editDisable.value;
      }
      return false;
    };

    const isSendAllButtonEnabled = computed({
      get: () => {
        return props?.tableData.filter((item) => {
          return item.sendEnable;
        })?.length;
      },
    });
    const isNTPSendAllButtonEnabled = computed({
      get: () => {
        return props?.tableData.filter((item) => {
          return item.ntpSendEnable;
        })?.length;
      },
    });

    onMounted(async () => {});
    const exportList = () => {
      if (props?.isDynamicExport) {
        emit("exportButtonClicked", true);
      } else {
        ExportToCSV(props?.columnData, props?.tableData, exportFilName.value);
      }
    };
    return {
      otherRowClicked,
      addRowClicked,
      addClicked,
      editClicked,
      deleteClicked,
      linkClicked,
      tableData,
      columnData,
      tableFooter,
      tableHeader,
      showAddRow,
      addButtonText,
      errorMessage,
      checkEditDisabled,
      sendEngineeringAuthorization,
      sendAllEngineeringAuthorizations,
      isSendAllButtonEnabled,
      isNTPSendAllButtonEnabled,
      sendNTP,
      sendAllNTP,
      invalidErrorClass,
      showItalics,
      instruction,
      linkIndicator,
      disableActions,
      exportList,
      showExportButton,
    };
  },
};
