<template>
  <div class="env-infp mb-20">
    <div class="railroadContactHeader">
      <h2 style="padding-right: 15px">Railroad Name:</h2>
      <v-text style="font-size: 16px">{{ railroadDetails?.name }}</v-text>
    </div>
  </div>
  <br />
  <div class="env-infp mb-20">
    <div class="env-header">
      <h2>Contacts</h2>
    </div>
  </div>
  <v-card class="p-64">
    <FeatureTable
      :items="contactDetails"
      :headers="headers"
      :hideFilter="true"
      @sortUpdate="sortUpdate"
      @editButtonClicked="editButtonClicked"
      @deleteButtonClicked="deleteButtonClicked"
      :hidePagination="true"
      @updatePaginationPerPage="updatePaginationPerPage"
      @pageChange="pageChange"
      :linkedPagination="true"
      :itemsPerPage="paginationData?.items"
      :totalItems="paginationData?.total"
      :isEditDisabled="!isUserRailroadContactAccess.update"

    ></FeatureTable>
  </v-card>
  <div class="d-flex justify-content-start m-20">
    <PrimaryButton v-if="isUserRailroadContactAccess.update"
      @buttonClicked="openDialog('Add', {})"
      :buttonText="'Add'"
    ></PrimaryButton>
  </div>

  <DialogComponent ref="AddEditContact"></DialogComponent>
  <DialogComponent2 ref="deleteContactDialogRef"></DialogComponent2>
</template>
  
  <script>
import ContactList from "@/modules/gcmsmain/admin/submenu/scripts/ContactList.js";
export default ContactList;
</script>
  <style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
</style>
  