import DialogComponent from "@/modules/projectinformation/components/ProjectPriorityDocumentUpdate.vue";import UploadScopingFieldViewDocument from "@/modules/scoping-field-view/components/UploadScopingFieldViewDocument.vue";
import DatePicker from "@/modules/shared/components/DatePicker.vue";
import DocumentsList from "@/modules/shared/components/DocumentsList.vue";
import LoadingOverlay from "@/modules/shared/components/LoadingOverlay.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import { useDocumentStore } from "@/stores/document";
import { computed, reactive, ref, toRefs, watch } from "vue";
import { CONFIG_ENDPOINT } from "../../../constants/Endpoints";
export default {
  name: "PriorityProjectDialog",
  components: {
    PrimaryButton,
    SecondaryButton,
    DatePicker,
    UploadScopingFieldViewDocument: UploadScopingFieldViewDocument,
    DialogComponent,
    DocumentsList,
    LoadingOverlay,
  },
  props: {
    id: { type: Number, default: null },
    item: Object, // Item to be edited (if in 'edit' mode)
    projectDetails: Object,
    pa1Details: [],
  },
  setup(props) {
    const ralroadEstimatesDocumentsDialogRef = ref(null);
    let isFormValid = ref(false);
    let priorityList = ref(["Emergency", "Critical", "Normal"]);
    const documentStore = useDocumentStore();
    const filesList = ref([]);
    const loading = ref(false);
    const updateDocumentList = ref(false);
    let UpdateModalOpened = ref(false);
    const state = reactive({
      dialog: false,
      resolve: null,
      reject: null,
      title: null,
      id: null,
      itemIndex: null,
      formattedDate: new Date(),
      formData: null,
    });

    let valueChanged = ref(false);
    const originalValue = ref();
    const isInitialised = ref(false);
    const open = (title, formData, id) => {
      state.dialog = true;
      state.title = title;
      state.formData = formData;
      setTimeout(() => {
        isInitialised.value = true;
      }, 1000);
      state.id = id;
      return new Promise((resolve, reject) => {
        state.resolve = resolve;
        state.reject = reject;
      });
    };

    watch(
      () => [state.formData],
      () => {
        if (isInitialised.value) {
          valueChanged.value = true;
        } else {
          valueChanged.value = false;
        }
      },
      { deep: true }
    );
    const saveData = () => {
      state.resolve(state.formData);
      state.dialog = false;
    };
    const closeDialog = () => {
      if (isInitialised.value && valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          valueChanged.value = false;
          state.resolve(false);
          state.dialog = false;
          resetForm();
        }
      } else {
        valueChanged.value = false;
        state.resolve(false);
        state.dialog = false;
        resetForm();
      }
    };
    const afterModalLeave = () => {
      valueChanged.value = false;
      isInitialised.value = false;
      originalValue.value = null;
      resetForm();
    };
    const valueChangedEvent = (event) => {
      valueChanged.value = event;
    };
    window.onbeforeunload = function () {
      if (isInitialised.value && valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };

    const resetForm = () => {
      state.formData = {
        projectPriority: "",
        projectPriorityReason: "",
        documentsList: [],
      };
    };

    const uploadFiles = async (files) => {
      loading.value = true;
      document.getElementById("list-section").scrollIntoView();
      let docType = "Emergency Proclamation";
      let endpoint = CONFIG_ENDPOINT.UPLOAD_SCOPING_DOCUMENTS;
      await documentStore.uploadDocuments(state.id, files, docType, endpoint);
      loading.value = false;
      fetchData();
    };
    async function fetchData() {
      updateDocumentList.value = true;
    }
    const checkFormInValid = computed({
      get: () => {
        if (
          !state.formData.projectPriority ||
          !state.formData.projectPriorityReason
        ) {
          return true;
        } else if (
          state.formData.projectPriority === "Emergency" &&
          !uploadedFilesLength.value
        ) {
          return true;
        }
        return false;
      },
    });
    const updateDocumentListFlag = () => {
      updateDocumentList.value = false;
    };

    const uploadedFilesLength = ref(0);
    const filesLength = (length) => {
      console.log(length);
      uploadedFilesLength.value = length;
    };
    const updateButtonClicked = (event) => {
      openDialog(event);
    };
    const openDialog = (event) => {
      UpdateModalOpened.value = true;
      ralroadEstimatesDocumentsDialogRef.value
        .open("Update Documents", state.id, event)
        .then((resolve) => {
          if (resolve) {
            fetchData();
            UpdateModalOpened.value = false;
          }
        })
        .catch(() => {
          UpdateModalOpened.value = false;
        });
    };
    return {
      ...toRefs(state),
      isFormValid,
      saveData,
      closeDialog,
      open,
      priorityList,
      uploadFiles,
      filesList,
      loading,
      fetchData,
      checkFormInValid,
      updateDocumentList,
      updateDocumentListFlag,
      filesLength,
      uploadedFilesLength,
      afterModalLeave,
      valueChangedEvent,
      UpdateModalOpened,
      updateButtonClicked,
      ralroadEstimatesDocumentsDialogRef,
    };
  },
};
