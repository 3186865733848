<template>
  <v-table class="mt-20" style="width: 100%">
    <thead>
      <tr>
        <th scope="col" style="width: 25%">File Name</th>
        <th scope="col" style="width: 10%">Document Type</th>
        <th scope="col">Date Uploaded</th>
        <th scope="col">File Size (MB)</th>
        <th scope="col" style="width: 15%">Railroad</th>
        <th scope="col" style="width: 10%">DOT</th>
        <th scope="col" style="width: 30%">Notes</th>
        <th scope="col" style="width: 10%">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="file of files" :key="file.id">
        <td>{{ file.file.name }}</td>
        <td style="width: 30%">
          <v-select
            v-if="isDynamicDocumentType"
            :items="documentTypeList"
            v-model="file.payloadData.docType"
            @update:modelValue="updatePayload($event, file, 'docType')"
            :rules="[requiredRule]"
          ></v-select>
          <v-select
            v-if="!isDynamicDocumentType && !showDocTypeText"
            :items="staticDocumentTypeList"
            :value="staticDocumentTypeValue"
            @update:modelValue="updatePayload($event, file, 'docType')"
          ></v-select>
          <span v-if="!isDynamicDocumentType && showDocTypeText">{{
            staticDocumentTypeValue
          }}</span>
        </td>
        <td>{{ getDate(file.payloadData.documentUploadDate) }}</td>
        <td>{{ file.payloadData.fileSize }}</td>

        <td style="width: 15%">
          <v-select
            v-if="
              showPreviewRailroadColumn &&
              !isRailroadDropdownStatic &&
              showRailroadDropdown(file, file.file.docType) &&
              file.file.docType == 'Contractor Bid Concurrence in Award Letter'
            "
            :items="safetyMilestoneDropdownList"
            v-model="file.payloadData.railroad"
            @update:modelValue="updatePayload($event, file, 'railroad')"
            :rules="[requiredRule]"
            :disabled="false"
          ></v-select>
          <v-select
            v-else-if="
              showPreviewRailroadColumn &&
              !isRailroadDropdownStatic &&
              showRailroadDropdown(file, file.file.docType)
            "
            :items="railroadDropdownList"
            v-model="file.payloadData.railroad"
            @update:modelValue="updatePayload($event, file, 'railroad')"
            :rules="[requiredRule]"
            :disabled="false"
          ></v-select>
          <v-select
            v-if="
              showPreviewRailroadColumn &&
              isRailroadDropdownStatic &&
              !showRailroadTypeText
            "
            :items="staticRailroadDropdownList"
            :value="staticRailroadDropdownValue"
            @update:modelValue="updatePayload($event, file, 'railroad')"
            :disabled="false"
          ></v-select>
          <span v-if="isRailroadDropdownStatic && showRailroadTypeText">{{
            staticRailroadDropdownValue
          }}</span>
        </td>

        <td style="width: 15%">
          <v-label style="opacity:initial !important; font-size:14px"
            v-if="staticDOTDropdownValue"
           
          >{{ staticDOTDropdownValue }}</v-label>
          <v-select
            v-else-if="
              showPreviewDOTColumn && showDOTDropdown(file, file.file.docType)
            "
            :items="dotDropdownList"
            v-model="file.payloadData.dot"
            @update:modelValue="updatePayload($event, file, 'dot')"
            :rules="[requiredRule]"
          ></v-select>
        </td>
        <td style="width: 20%" class="notes-field">
          <v-text-field
            variant="outlined"
            style="width: 100%"
            type="text"
            @change="onNoteChange($event, file)"
            v-model="file.payloadData.notes"
            :maxlength="50"
          ></v-text-field>
        </td>
        <td style="width: 10%">
          <IconButton
            @buttonClicked="$emit('remove', file)"
            :buttonText="'remove'"
            :iconButton="true"
            :iconName="'mdi-close'"
            class="pl-0"
          ></IconButton>
        </td>
      </tr>
      <tr v-if="!files.length">
        <td :colspan="getNoDataColspan" class="text-center">
          No Documents Found.
        </td>
      </tr>
    </tbody>
  </v-table>
</template>

<script>
import UploadedFilePreview from "@/modules/shared/scripts/UploadedFilePreview.js";
export default UploadedFilePreview;
</script>
<style >
.notes-field .v-text-field .v-field--no-label input,
.v-text-field .v-field--active input {
  padding: 0 5px !important;
}
</style>
<style scoped>
@import "@/modules/shared/assets/UploadedFilePreview.scss";
</style>
