<template>
    <v-card-text>
      <ProjectHistory></ProjectHistory>
    </v-card-text>
  </template>
  <script>
  import ProjectHistory from "@/modules/manageproject/components/ProjectHistory.vue";
  
  export default {
    name: "ViewProjectHistory-page",
    components: {
        ProjectHistory: ProjectHistory,
    },
  };
  </script>
  