<template>
  <v-menu open-on-hover>
    <template v-slot:activator="{ props }">
      <v-btn
        class="dashboardMenuBar"
        :class="{ headeritem: styleset }"
        v-bind="props"
      >
        {{ menuTitle.toUpperCase() }}
        <i class="fa fa-caret-down iconcolor"></i>
      </v-btn>
    </template>
    <v-list :items="menuItems">
      <v-list-item
        class="subheader"
        v-for="(menu, index) in menuItems"
        :key="index"
        >{{ menu.title }}
        <v-list-item-content>
          <v-list-item-title
            class="submenu highlight-on-hover"
            :active-class="hoverClass"
            @click="
              menu?.openModal
                ? $emit('modalClick', true)
                : $router.push(menu.value)
            "
          >
            {{ menu.subtitle }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    menuItems: Array, // Array of menu items and submenus
    menuTitle: String,
    styleset: String,
  },
  emits: ["modalClick"],
  data() {
    return {
      hoverClass: "title-hovered",
    };
  },
};
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/modules/shared/assets/menu.scss";
</style>


