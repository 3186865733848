<template>
    <v-row>
      <!-- Left corner element -->
      <v-col class="ml-20">
        <router-link :to="`/GCMS/${id}`">
          &lt; Return to Project Information Page
        </router-link>
      </v-col>
      <!-- Centered element -->
      <v-col class="page-title">
      <h1>Contractor Bid</h1>
      </v-col>
      <v-col></v-col>
    </v-row>


      <div>
        <div class="env-header mb-20">
          <h2 class="locationHeaderText">Contractor Bid Details</h2>
        </div>
      </div>
      <br>


      <div>

        <div v-for="(contractorBid, index) in contractorBidData" :key="contractorBid">
            <div>
                <v-row style="padding-left: 50px;">
                    <v-coL>
                        <v-row style="display:flex; align-items: center;">
                          <v-label class="mpms-env-label" style="opacity:initial !important; padding-right:10px; font-size:16px"
                          ><b>Railroad Name: </b> </v-label>
                          <v-label class="mpms-env-label" style="opacity:initial !important; font-size:16px"
                              >{{ contractorBid?.railroadCompany }} </v-label>
                        </v-row>
                    </v-coL>
                </v-row>
                <div v-if="contractorBid?.awardedContractor.length > 0">
                <br><br><br>
              </div>
                <div v-if="contractorBid?.awardedContractor.length > 0">
                <div v-for="(awarded,index) in contractorBid?.awardedContractor" :key="awarded">
                    <v-row style="padding-left: 150px; display:flex; align-items: center;">
                        <v-col>
                            <v-row style="display:flex; align-items: center;">
                              <v-label class="mpms-env-label" style="opacity:initial !important; padding-right:10px"
                              ><b>Awarded Contractor Name:   </b> </v-label>
                              <v-label class="mpms-env-label" style="opacity:initial !important"
                              >{{ awarded?.awardedContractorName }} </v-label>
                            </v-row>
                        </v-col>
                        <v-col>
                            <v-row style="display:flex; align-items: center;">
                              <label class="mpms-env-label" style="opacity:initial !important; padding-right:10px"
                              ><b>Awarded Contractor Cost:   </b> </label>
                              <v-label class="mpms-env-label" style="opacity:initial !important"
                              >{{ awarded?.awardedCost }} </v-label>
                            </v-row>
                        </v-col>

                        <v-col>
                            <IconButton
                                @buttonClicked="openDialog('Edit',contractorBid,awarded)"
                                :buttonText="'Edit'"
                                :iconButton="true"
                                :iconName="'mdi-pencil'"
                                :disabled="!isUserContractorBidAccess.update"
                            ></IconButton>                        
                        </v-col>
                    </v-row>
                    <br>
                </div>
              </div>

              <div v-if="contractorBid?.awardedContractor.length > 0">
                <br>
                <v-row style="padding-left: 150px;">
                        <v-col>
                            
                        </v-col>
                       
                        <v-col>
                            <v-row style="display:flex; align-items: center;">
                              <v-label class="mpms-env-label" style="opacity:initial !important; padding-right: 10px; padding-left:35px;"
                              ><b>Total Contract Value:   </b> </v-label>
                              <v-label class="mpms-env-label" style="opacity:initial !important"
                              >{{ contractorMap[contractorBid?.railroadCompany] }}</v-label>
                            </v-row>
                        </v-col>
                        <v-col></v-col>
                    </v-row>
                </div>

                <br><br>
                <v-row style="padding-left: 50px;">
                        <div>
                            <SecondaryButton
                            @buttonClicked="openDialog('Add',contractorBid, {})"
                            :buttonText="'Add Awarded Contractor'"
                            :disabled="!isUserContractorBidAccess.update"

                            ></SecondaryButton>
                        </div>
                </v-row>
            </div>
            <br><br><br>
            <br>
        </div>

      </div>





      <br><br>
      <div>
        <div class="env-header mb-20">
          <h2 class="locationHeaderText">Contractor Bid Documents</h2>
        </div>

        <div>
        <v-row cols="12">
          <v-col cols="12">
            <div class="view-upload-section">
              <span
                class="color-blue underline cursor-pointer"
                @click="otherButtonClicked()"
                >Upload Contractor Bid Documents</span
              >
            </div>
          </v-col>
        </v-row>
        <v-grid>
          <v-row justify="center">
            <v-col cols="11">
              <DocumentsList
                :heading="'Contractor Bid Document List'"
                :doctype="'/CN_BID_VIW'"
                :id="id"
                :updateDocumentList="updateDocumentList"
                @updateDocumentListFlag="updateDocumentListFlag"
                @otherButtonClicked="updateButtonClicked($event)"
               
              >
              </DocumentsList>
            </v-col>
          </v-row>
        </v-grid>
      </div>
      </div>
  <br><br>
    <DialogComponent1
    ref="contractorBidDialog"
    @updateContractorBid="handleChangesSaved"
  ></DialogComponent1>
  <DialogComponent2
      ref="railroadEstimatesDocumentsDialogRef"
    ></DialogComponent2>
   
  </template>
  <script>
  import ContractorBid from "@/modules/manageproject/scripts/ContractorBid.js";
  export default ContractorBid;
  </script>
  
  <style>
  @import "@/assets/css/style.scss";
  </style>
  