import useFieldEntry from "@/composables/fieldEntry";import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import VueTable from "@/modules/shared/components/VueTable.vue";
import sharedUserService from "@/modules/shared/service/sharedUserService";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import { computed, onMounted, ref, watch } from "vue";
export default {
  name: "AddToImpactedList",
  components: { VueTable, SecondaryButton, PrimaryButton },
  emits: ["refreshData", "updateOtherEventsPerformed"],
  props: ["id", "otherEventsPerformed"],
  setup(props, context) {
    const { allowAlphanumericOnly } = useFieldEntry();
    const id = computed({
      get: () => props?.id,
    });
    let errorMessage = ref("");
    let successMessage = ref(false);
    let searchClicked = ref(false);
    let searchDotValue = ref("");
    let ImapctedDotList = ref([]);
    let columnData = ref([
      { name: "DOT#", key: "dotNumber" },
      { name: "RR Owner", key: "xingownr" },
      { name: "BR-Key", key: "brKey" },
      { name: "RR Milepost", key: "milepost" },
      { name: "Crossing Status", key: "crossingstatus" },
      { name: "Crossing Position", key: "posxing" },
      { name: "Lat/Long", key: "latlong" },
    ]);
    const userData = computed(() => sharedUserService.getSharedData());
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const isUserAccess = ref({
      read: false,
      update: false,
    });
    const accessCheck = async () => {
      const readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.PROJECT_CROSSINGS_AND_BRIDGES_READ_ACCESS
      );
      const updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.PROJECT_CROSSINGS_AND_BRIDGES_CRTUPD_ACCESS
      );
      isUserAccess.value.read = readResponse || false;
      isUserAccess.value.update = updateResponse || false;
    };
    watch(
      () => [props?.otherEventsPerformed],
      () => {
        if (props?.otherEventsPerformed) {
          resetSearch();
          context.emit("updateOtherEventsPerformed", false);
        }
      },
      { deep: true }
    );
    onMounted(async () => {
      await accessCheck();
    });
    const changeDotValue = () => {
      searchClicked.value = false;
    };
    const searchDot = () => {
      searchClicked.value = true;
      axios
        .get(
          CONFIG_ENDPOINT.GCB_CROSSING +
            CONFIG_ENDPOINT.SLASH +
            searchDotValue.value.trim()
        )
        .then((response) => {
          ImapctedDotList.value = [];
          if (response.data?.dotNumber) {
            ImapctedDotList.value = [response.data];
          }
          searchDotValue.value = "";
          errorMessage.value = "";
        })
        .catch((error) => {
          ImapctedDotList.value = [];
          if (error?.response?.data?.message) {
            errorMessage.value = error?.response?.data?.message;
          } else if (error?.response?.data?.businessMessage) {
            errorMessage.value = error?.response?.data?.businessMessage;
          } else {
            errorMessage.value = "query did not return a unique result: 2";
          }
          searchDotValue.value = "";
        });
    };
    const addToImpactedList = () => {
      if (isUserAccess.value.update && ImapctedDotList.value?.length) {
        let dotNumber = ImapctedDotList.value[0].dotNumber;
        axios
          .post(CONFIG_ENDPOINT.GCB_CROSSING + "/" + id.value, {
            ...ImapctedDotList.value[0],
            impacted: "Impacted",
            sourceid: userData.value?.userID,
          })
          .then(() => {
            ImapctedDotList.value = [];
            successMessage.value = `#${dotNumber} is added to impacted list`;

            errorMessage.value = `#${dotNumber} is added to impacted list`;
            context.emit("refreshData");
          })
          .catch((error) => {
            if (error?.response?.data?.message) {
              errorMessage.value = error.response.data.message;
            } else if (error?.response?.data?.businessMessage) {
              errorMessage.value = error?.response?.data?.businessMessage;
            }
            successMessage.value = false;
            ImapctedDotList.value = [];
          });
      }
    };
    const addToNearbyList = () => {
      if (isUserAccess.value.update && ImapctedDotList.value?.length) {
        let dotNumber = ImapctedDotList.value[0].dotNumber;
        axios
          .post(CONFIG_ENDPOINT.GCB_CROSSING + "/" + id.value, {
            ...ImapctedDotList.value[0],
            impacted: "Nearby",
            sourceid: userData.value?.userID,
          })
          .then(() => {
            ImapctedDotList.value = [];
            successMessage.value = `#${dotNumber} is added to Nearby Crossings list`;
            errorMessage.value = `#${dotNumber} is added to Nearby Crossings list`;
            context.emit("refreshData");
          })
          .catch((error) => {
            if (error?.response?.data?.message) {
              errorMessage.value = error.response.data.message;
            } else if (error?.response?.data?.businessMessage) {
              errorMessage.value = error?.response?.data?.businessMessage;
            }
            successMessage.value = false;
            ImapctedDotList.value = [];
          });
      }
    };
    const clear = () => {
      searchDotValue.value = "";
      ImapctedDotList.value = [];
      successMessage.value = false;
      errorMessage.value = false;
    };
    const resetSearch = () => {
      searchClicked.value = false;
      searchDotValue.value = "";
      ImapctedDotList.value = [];
      successMessage.value = false;
      errorMessage.value = false;
    };
    return {
      id,
      errorMessage,
      successMessage,
      searchClicked,
      searchDotValue,
      ImapctedDotList,
      columnData,
      changeDotValue,
      searchDot,
      addToImpactedList,
      addToNearbyList,
      clear,
      allowAlphanumericOnly,
      isUserAccess,
    };
  },
};
