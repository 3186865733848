<template>
  <v-footer class="footerBar">
    <v-row>
      <v-col class="leftCol">
        <img
          alt="KeyStoneLogo"
          src="https://www.penndot.pa.gov/PublishingImages/PennDOT-keystone-logo.svg"
          class="logo"
        />
        <div>
          <a class="footerItem" :href="constants?.pennDotUrl" target="_blank"
            >PENNDOT
          </a>
        </div>
        <div class="footerItem">|</div>
        <div>
          <a
            class="footerItem"
            :href="constants?.accessibilityUrl"
            target="_blank"
            >ACCESSIBILITY
          </a>
        </div>
        <div class="footerItem">|</div>

        <div>
          <a
            class="footerItem"
            :href="constants?.privacyPolicyUrl"
            target="_blank"
            >PRIVACY POLICY
          </a>
        </div>
        <div class="footerItem">|</div>

        <div>
          <a
            class="footerItem"
            :href="constants?.pennsylvaniaUrl"
            target="_blank"
            >PENNSYLVANIA</a
          >
        </div>
      </v-col>
      <v-col class="rightCol">
        <div class="footerItemRight">
          Release {{ constants?.releaseVersion }}
        </div>
        <div class="footerItemRight">
          Copyright © Pennsylvania Department of Transportation. All rights
          reserved.
        </div>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import axios from "axios";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";

export default {
  name: "PageFooter",
  data() {
    return {
      constants: null,
    };
  },
  mounted() {
    axios
      .get(CONFIG_ENDPOINT.GCMS_MAIN_PROJECT + "gcms")
      .then((response) => (this.constants = response.data))
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>
<style>
@import "@/modules/gcmsmain/gcmsmainpage/assets/footer.scss";
</style>
