<template>
  <v-card-text>
    <ProjectFollowers
      @BannerUpdate="$emit('BannerUpdate', $event)"
    ></ProjectFollowers>
  </v-card-text>
</template>
    <script>
import ProjectFollowers from "@/modules/manageproject/components/ProjectFollowers.vue";

export default {
  name: "ViewProjectFollowers-page",
  components: {
    ProjectFollowers: ProjectFollowers,
  },
};
</script>
    