import { CONFIG_ENDPOINT } from "@/constants/Endpoints";import VueTable from "@/modules/shared/components/VueTable.vue";
import axios from "axios";
import { onMounted, ref, watch } from "vue";

const WBSInfoData = ref([]);
const columnData = ref([
  { name: "Phase", key: "mpmsPhaseCode", isBold: true, alignLeft: true },
  { name: "WBS#", key: "wbsNumber" },
  { name: "Status", key: "status" },
  { name: "WBS Estimate Amount", key: "wbsEstimate" },
  { name: "WBS Actual Amount", key: "wbsActual" },
]);

export default {
  name: "WbsInfo",
  components: { VueTable },
  props: {
    id: { type: Number, default: null },
  },
  setup(props) {
    onMounted(async () => {
      await getWBSInfoData();
    });
    watch(
      () => [props.id],
      () => {
        if (props.id) {
          getWBSInfoData();
        }
      },
      { deep: true }
    );
    async function getWBSInfoData() {
      axios
        .get(CONFIG_ENDPOINT.COSTANDFUNDING_ESTIMATED_WBS_INFO + props.id)
        .then((response) => {
          WBSInfoData.value = response.data;
          WBSInfoData.value.forEach((wbsInfo, index) => {
            WBSInfoData.value[index] = {
              ...wbsInfo,
              wbsEstimate:
                "$" +
                (wbsInfo?.wbsEstimate
                  ? parseFloat(wbsInfo?.wbsEstimate)
                      ?.toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : 0),
              wbsActual:
                "$" +
                (wbsInfo?.wbsActual
                  ? parseFloat(wbsInfo?.wbsActual)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : 0),
            };
          });
        })
        .catch((err) => {
          WBSInfoData.value = [];
        });
    }
    return {
      WBSInfoData,
      columnData,
    };
  },
};
