<template>
  <OnlineHelpPOC :helpKey="'DASH_HELP'"></OnlineHelpPOC>
  <div class="allProjectsHeading">Flagger Activity Admin Table</div>
  <v-card class="d-flex w-100 justify-content-center">
    <VueTable
      class="w-70"
      :tableData="flaggerActivityData"
      :columnData="columnData"
      :tableHeader="''"
      :showAddRow="flaggerAccess ? true : false"
      :addButtonText="'Add Activity'"
      @editClicked="openDialog('Edit', $event)"
      @addClicked="openDialog('Add')"
    ></VueTable>
  </v-card>
  <DialogComponent
    ref="flaggerAdminActivityDialog"
    @updateFlaggerActivity="handleChangesSaved"
  ></DialogComponent>
</template>
<script>
import flaggerActivityAdmin from "@/modules/gcmsmain/admin/submenu/scripts/flaggerActivityAdmin.js";
export default flaggerActivityAdmin;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/modules/gcmsmain/admin/submenu/assets/flaggerActivityAdmin.scss";
</style>

