<template>
  <v-card>
    <v-card-text>
      <div class="page-title">
        <h1>Railroad Information</h1>
      </div>
      <v-form>
        <div v-for="(row, rowIndex) in PartIIDetails" :key="rowIndex">
          <div v-for="(col, colIndex) in row" :key="colIndex">
            <div class="env-header mb-20 mt-20" v-if="col.heading">
              <h2 class="locationHeaderText">
                {{ col.heading }}
              </h2>
            </div>
            <div class="env-infp">
              <div class="env-content">
                <v-row>
                  <v-col
                    cols="6"
                    v-for="(col1, col1Index) in col.data"
                    :key="col1Index"
                  >
                    <label class="mpms-env-label"
                      ><v-row>
                        <v-col
                          cols="1.5"
                          class="numberColumnParII"
                          >{{ col1.number }}</v-col
                        >
                        <v-col cols="5">
                          <b class="textColumn"
                            ><span>{{ col1.label }}<span v-if="col1.label != 'If less than One movement per day?' && col1.label != 'How many trains per Week?' && col1.label != 'Is Track Signaled?'">:</span></span></b
                          >
                        </v-col>
                        <v-col cols="5"> {{ col1.value }} </v-col></v-row
                      ></label
                    >
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import PartIIRailRoadInfo from "@/modules/dot-details/scripts/PartIIRailRoadInfo";
export default PartIIRailRoadInfo;
</script>
<style scoped>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
</style>
