<template>
  <v-card class="dot-part-form">
    <v-card-text>
      <div class="page-title">
        <h1>Inventory Inspections</h1>
      </div>
      <div class="env-header mb-20">
        <h2 class="locationHeaderText">Add New Crossing Inspection</h2>
      </div>
      <div class="env-infp">
        <div class="env-content">
          <v-row>
            <v-col cols="8">
              <label class="mpms-env-label"
                ><v-row>
                  <v-col class="numberColumn" cols="3">
                    <b class="textColumn required"
                      ><span>Inspection Date: </span></b
                    >
                  </v-col>
                  <v-col cols="2">
                    <DatePicker
                      :value="newInspection.inspectionDate"
                      @input="dateChange($event)"
                      :invalidMsg="'Inspection Date'"
                      @dateValidation="checkDateValidation($event)"
                      :rules="[
                        'requiredRule',
                        'validDateFutureDate_Inspections',
                      ]"
                      :disabled="!isUserInspectionAccess.update"
                    ></DatePicker>
                  </v-col> </v-row
              ></label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8">
              <label class="mpms-env-label"
                ><v-row>
                  <v-col class="numberColumn" cols="3">
                    <b class="textColumn required"
                      ><span>Crossing Changes: </span></b
                    >
                  </v-col>
                  <v-col cols="5">
                    <v-row>
                      <v-radio-group
                        inline
                        v-model="newInspection.crossingChanges"
                        :rules="[requiredRule]"
                        :disabled="!isUserInspectionAccess.update"

                      >
                        <v-radio label="Yes" value="Yes" :disabled="!isUserInspectionAccess.update"
                        ></v-radio>
                        <v-radio label="No" value="No" :disabled="!isUserInspectionAccess.update"
                        ></v-radio>
                      </v-radio-group>
                    </v-row>
                  </v-col> </v-row
              ></label>
            </v-col> </v-row
          ><v-row>
            <v-col cols="8">
              <label class="mpms-env-label"
                ><v-row>
                  <v-col class="numberColumn" cols="3">
                    <b class="textColumn required"
                      ><span>Inspected By: </span></b
                    >
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                      v-model="newInspection.inspectedBy"
                      maxlength="75"
                      :rules="[requiredRule]"
                      @keydown="preventNumericInput($event)"
                      :disabled="!isUserInspectionAccess.update"

                    ></v-text-field>
                  </v-col> </v-row
              ></label>
            </v-col> </v-row
          ><v-row>
            <v-col cols="8">
              <label class="mpms-env-label"
                ><v-row>
                  <v-col class="numberColumn" cols="3">
                    <b class="textColumn"><span>Comments: </span></b>
                  </v-col>
                  <v-col cols="5" class="inspectionComment">
                    <v-container class="white-textarea comment-box"
                      ><v-textarea
                        v-if="newInspection.crossingChanges != 'Yes'"
                        :counter="commentMaxLength"
                        :maxlength="commentMaxLength"
                        :persistent-counter="true"
                        v-model="newInspection.comments"
                        :spellcheck="true"
                        variant="solo"
                        :disabled="!isUserInspectionAccess.update"

                      ></v-textarea>
                      <v-textarea
                        v-if="newInspection.crossingChanges == 'Yes'"
                        :counter="commentMaxLength"
                        :maxlength="commentMaxLength"
                        :persistent-counter="true"
                        v-model="newInspection.comments"
                        :spellcheck="true"
                        :rules="[requiredRule]"
                        variant="solo"
                        :disabled="!isUserInspectionAccess.update"

                      ></v-textarea>

                      <span v-if="newInspection.comments"
                        >{{
                          commentMaxLength - newInspection.comments?.length
                        }}
                        characters remaining.</span
                      >
                    </v-container>
                  </v-col>
                </v-row></label
              >
            </v-col>
            <div class="action">
              <PrimaryButton
                @buttonClicked="saveData"
                :disabled="!finalValidation || !checkFormValidation || !isUserInspectionAccess.update"
                :buttonText="'Save'"
              ></PrimaryButton>
              <SecondaryButton
                @buttonClicked="cancelData"
                :buttonText="'Cancel'"
                class="ml-2"
                :disabled="!finalValidation || !checkFormValidation || !isUserInspectionAccess.update"

              ></SecondaryButton>
            </div>
          </v-row>
        </div>
      </div>
    </v-card-text>
  </v-card>
  <v-card class="dot-part-form">
    <div class="pl-5 pr-5">
    <br>
    <div class="env-header mb-20">
        <h2 class="locationHeaderText">Previous Inventory Inspections</h2>
      </div>
    <FeatureTable
      :headers="headers"
      :items="projectList"
      :tableHeader="''"
      :clickable="true"
      :itemsPerPage="paginationData?.items"
      :linkedPagination="true"
      :totalItems="paginationData?.total"
      @updatePaginationPerPage="updatePaginationPerPage"
      @pageChange="pageChange"
      @sortUpdate="sortUpdate"
      :hideFilter="true"
    >
    </FeatureTable>
  </div>
  </v-card>

</template>

<script>
import Inspections from "../scripts/Inspections.js";
export default Inspections;
</script>
