<template>
  <v-app>
    <div>
      <HeaderPage />
    </div>

    <v-container fluid fill-height class="d-flex flex-column mt-70">
      <v-row class="flex-grow-1" align="center" justify="center">
        <v-col cols="12" sm="10" md="8" lg="6">
          <div class="mprs-header-div mt-100">
            <div class="page-title">
              <h1 class="mt-50">
                404 - Page Not Found
                <p>Sorry, the page you are looking for does not exist.</p>
              </h1>
              <b class="d-flex justify-center text-center">
                <router-link to="/GCMS" style="color: #003e7e;">Go to the Home page</router-link>
              </b>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" class="mt-70">
        <!-- Additional content can go here if needed -->
      </v-row>
    </v-container>

    <PageFooter class="footer" />
  </v-app>
</template>
<script>
import PageFooter from "@/modules/gcmsmain/gcmsmainpage/components/footerPage.vue";
import HeaderPage from "@/modules/gcmsmain/gcmsmainpage/components/headerPage.vue";
export default {
  name: "NotFound",
  components: {
    PageFooter,
    HeaderPage,
  },
};
</script>

<style scoped>
@import "@/assets/css/main.scss";
@import "@/assets/css/color.scss";
@import "@/assets/css/style.scss";

.page-title {
  text-align: center; /* Center text inside the title */
}

.mt-350 {
  margin-top: 395px;
}
</style>
