
<template>
  <v-card-text>
    <RailroadCertification
      @BannerUpdate="$emit('BannerUpdate', $event)"
    ></RailroadCertification>
  </v-card-text>
</template>
  <script>
import RailroadCertification from "@/modules/manageproject/components/RailroadCertification.vue";

export default {
  name: "ViewRailroadCertification-page",
  components: {
    RailroadCertification: RailroadCertification,
  },
};
</script>