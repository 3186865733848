import DesignImage from "@/assets/images/Design.png";import BridgeImage from "@/assets/images/Highway_Bridge.png";
import HistoricalImage from "@/assets/images/Historical.png";
import LocalImage from "@/assets/images/Local.png";
import SafetyImage from "@/assets/images/pnghero_train-rail-transport-level-crossing-traffic-sign-road.png";
export default () => {
  const getIdentifierIcon = (identifier) => {
    let icon = "";
    switch (identifier) {
      case "Critical Project":
      case "Critical":
        icon =
          "<i class='tableIdentifierIcons fa fa-exclamation-triangle' aria-hidden='true' style='color:#F8A311;'></i>";
        break;
      case "Emergency Project":
      case "Emergency":
        icon =
          "<i class='tableIdentifierIcons fa fa-exclamation-circle' aria-hidden='true' style='color: #C70000;'></i>";
        break;
      case "Design-Build Project ":
        icon = `<img src="` + DesignImage + `" class='tableIdentifierIcons' />`;
        break;
      case "Local Project":
        icon = `<img src="` + LocalImage + `" class='tableIdentifierIcons' />`;
        break;
      case "Safety project":
      case "Safety Project":
        icon = `<img src="` + SafetyImage + `" class="tableIdentifierIcons"/>`;
        break;
      case "Highway Bridge Project":
      case "Highway Bridge project":
        icon = `<img src="` + BridgeImage + `" class='tableIdentifierIcons' />`;
        break;
      case "P3 Project":
        icon =
          "<i class='tableIdentifierIcons fa fa-users' aria-hidden='true' style='color:#000000;' ></i>";
        break;
      case "Historical":
        icon =
          `<img src="` + HistoricalImage + `" class='tableIdentifierIcons' />`;
        break;
    }
    return icon;
  };
  return {
    getIdentifierIcon,
  };
};
