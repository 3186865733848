<template>
  <div class="env-infp">
    <div class="env-content">
      <div id="app" class="wrapper-container">
        <div class="m-10 w-95">
          <DocumentsList
            :heading="'Scoping Field View Document List'"
            :doctype="'/SCPFLD_DTY'"
            :id="id"
            :updateDocumentList="updateDocumentList"
            @updateDocumentListFlag="updateDocumentListFlag"
            @otherButtonClicked="updateButtonClicked($event)"
          >
          </DocumentsList>
          <h2
            class="f-size18 f-montserrat text-left mt-50"
            style="font-size: 18px; padding-bottom: 0.5em; color: #003e7e"
          >
            Upload Scoping Field View Documents
          </h2>
          <p class="text-left" style="padding-bottom: 1px; padding-left: 10px">
            Maximum allowable file size is 500 MB.
          </p>
        </div>
        <div class="w-95">
          <UploadScopingFieldViewDocument
            v-if="!UpdateModalOpened"
            @uploadFilesCall="uploadFiles"
            @isUploadButtonDisabled="$emit('isUploadButtonDisabled', $event)"
            @uploadedFiles="uploadedFilesEvent"
            @valueChangedEvent="$emit('valueChangedEvent', $event)"
            :isDynamicDocumentType="false"
            :staticDocumentTypeList="['Scoping Field View Document']"
            :staticDocumentTypeValue="'Scoping Field View Document'"
            :previewDocTypeUrl="'GET_DOCUMENT_TYPES_UPLOAD_LIST'"
            :saveClicked="saveClicked"
          ></UploadScopingFieldViewDocument>
        </div>
      </div>
    </div>
  </div>
  <LoadingOverlay :isLoading="loading"> </LoadingOverlay>
  <DialogComponent ref="ralroadEstimatesDocumentsDialogRef"></DialogComponent>
</template>

<script>
import ViewScopingFieldView from "@/modules/scoping-field-view/scripts/ViewScopingFieldView.js";
export default ViewScopingFieldView;
</script>

<style scoped>
@import "@/modules/scoping-field-view/assets/ViewScopingFieldView.scss";
</style>
