import DialogComponent from "@/modules/projectinformation/components/PA1CallDialog.vue";import VueTable from "@/modules/shared/components/VueTable.vue";
import { useProjectServiceStore } from "@/stores/projectService";
import { computed, onMounted, ref, watch } from "vue";
import { RBAC_CONTROLLER } from "../../../constants/rbacconstants";
import { userRoleBasedAccessStore } from "../../../stores/userRoleBasedAccess";
export default {
  components: { DialogComponent, VueTable },
  name: "PA1-list",
  props: {
    id: { default: null },
    item: Object, // Item to be edited (if in 'edit' mode)
    projectDetails: Object,
    formattedDate: { type: String, default: null },
  },
  setup(props) {
    const pa1DialogComponent = ref(null);
    const projectServiceStore = useProjectServiceStore();
    const id = ref();
    const item = computed({
      get: () => {
        return props?.item;
      },
    });
    const projectDetails = computed({
      get: () => {
        return props?.projectDetails;
      },
    });
    const formattedDate = computed({
      get: () => {
        return props?.formattedDate;
      },
    });
    let itemIndex = ref(null);
    let headers = ref([
      {
        name: "Serial#",
        key: "pa1CallSerialNum",
        value: "pa1CallSerialNum",
      },
      { name: "Date", key: "pa1CallDate", value: "pa1CallDate" },
      { name: "Project Phase", key: "projectPhase", value: "projectPhase" },
      { name: "County", key: "countyName", value: "countyName" },
      {
        name: "Municipality",
        key: "municipalityName",
        value: "municipalityName",
      },
      {
        name: "Action",
        key: "",
        isAction: true,
        isAdd: false,
        isEdit: true,
        isDelete: true,
      },
    ]);
    let pa1Details = ref([]);
    let formData = ref({
      pa1CallSerialNum: "",
      pa1CallDate: "",
      projectPhase: "",
      countyName: "",
      municipalityName: "",
      mpmsNumber: "",
    });
    let initialLoad = ref("false");
    watch(
      () => [props.id],
      () => {
        if (props.id) {
          id.value = props.id;
          retrievePA1();
        }
      },
      { deep: true }
    );
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const isUserPa1Access = ref({
      read: false,
      update: false,
    });
    const pa1AccessCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.PA1CALL_READ_ACCESS
      );
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.PA1CALL_CRTUPD_ACCESS
      );
      isUserPa1Access.value.read = readResponse || false;
      isUserPa1Access.value.update = updateResponse || false;
      headers.value[5] = {
        name: "Action",
        key: "",
        isAction: isUserPa1Access.value.update,
        isAdd: isUserPa1Access.value.update,
        isEdit: isUserPa1Access.value.update,
        isDelete: isUserPa1Access.value.update,
      };
    };
    onMounted(async () => {
      id.value = props.id;
      retrievePA1();
      await pa1AccessCheck();
    });
    async function retrievePA1() {
      let response = await projectServiceStore.retrievePA1(props.id);

      if (response) {
        pa1Details.value = response.data;
        for (let pa1 of pa1Details.value) {
          let realNumber = pa1.pa1CallSerialNum.replace(/-/gi, "");
          let dashedNumber = realNumber.match(/.{1,11}/g);
          pa1.pa1CallSerialNum = dashedNumber.join("-");
        }
      }
    }

    const openDialog = (action, item) => {
      if (action === "Add") {
        retrievePA1();
        // Reset the form and set the dialog title and action
        pa1DialogComponent.value
          .open(
            "Add PA1 Call",
            "Add",
            {
              countyName: projectDetails.value.county,
              municipalityName: "",
              pa1CallSerialNum: "",
              pa1CallDate: "",
              projectPhase: "",
              mpmsNumber: "",
            },
            id.value,
            itemIndex.value,
            new Date()
          )
          .then((resolve) => {
            if (resolve) {
              retrievePA1();
            }
          });
      } else if (action === "Edit") {
        itemIndex.value = item.index;
        // Set the form data with the selected item's values and set the dialog title and action
        pa1DialogComponent.value
          .open(
            "Edit PA1 Call",
            "Edit",
            { ...item.item },
            id.value,
            itemIndex.value,
            item.item.pa1CallDate
          )
          .then((resolve) => {
            if (resolve) {
              retrievePA1();
            }
          });
      }
    };
    async function deletePA1Call(index) {
      pa1Details.value[index].pa1CallSerialNum = pa1Details.value[
        index
      ].pa1CallSerialNum.replace("-", "");
      let response = projectServiceStore.deletePA1Call(
        props.id,
        pa1Details.value[index].pa1CallSerialNum
      );
      if (response) {
        pa1Details.value.splice(index, 1);
      }
    }
    return {
      headers,
      pa1Details,
      formData,
      initialLoad,
      item,
      formattedDate,
      openDialog,
      deletePA1Call,
      pa1DialogComponent,
      isUserPa1Access,
    };
  },
};
