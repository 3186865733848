<template>
  <div class="expanderOverLay">
    <ConsultantAgreement></ConsultantAgreement>
  </div>
</template>
<script>
import ConsultantAgreement from "@/modules/gcmsmain/admin/submenu/components/ConsultantAgreement.vue";
export default {
  name: "viewConsultantAgreement",
  components: {
    ConsultantAgreement: ConsultantAgreement,
  },
};
</script>
