 
 <template>
  <v-card class="p-64">
    <v-card-text>
      <div class="page-title">
        <h1>Grade Crossing Documents</h1>
        <div>
          <br /><br />
          <v-grid>
            <v-row justify="center">
              <v-col cols="11">
                <DocumentsList
                  :doctype="'/GC_VIEW_DT'"
                  :id="id"
                  :updateDocumentList="updateDocumentList"
                  @updateDocumentListFlag="updateDocumentListFlag"
                  @otherButtonClicked="updateButtonClicked($event)"
                  
                ></DocumentsList>
              </v-col>
            </v-row>
          </v-grid>
          <br />
          <v-grid v-if="isProjectDocumentUpdateAccess">
            <v-row justify="center">
              <v-col cols="11">
                <h2
                  class="f-size18 f-montserrat text-left mt-50"
                  style="font-size: 18px; padding-bottom: 0.5em; color: #003e7e"
                >
                  Upload Grade Crossing Documents
                </h2>
                <p
                  class="text-left"
                  style="padding-bottom: 1px; padding-left: 10px"
                >
                  Maximum allowable file size is 500 MB.
                </p>
                <UploadScopingFieldViewDocument
                  v-if="!UpdateModalOpened"
                  @uploadFilesCall="uploadFiles"
                  :isDynamicDocumentType="true"
                  :isRailroadDropdownDisabled="true"
                  :showPreviewRailroadColumn="true"
                  :checkMaximumFilesUploaded="true"
                  :checkMaxFileSize="true"
                  :previewDocTypeUrl="'UPLOAD_GC_DOCUMENTS_DOCTYPE_LIST'"
                  :staticDOTDropdownList="[id]"
                  :staticDOTDropdownValue="id"
                  :mpmsId="id"
                  :isPageUpload="true"
                  :showPreviewDOTColumn="true"
                ></UploadScopingFieldViewDocument>
              </v-col>
            </v-row>
          </v-grid>
          <br />
        </div>
      </div>
      <LoadingOverlay :isLoading="loading"> </LoadingOverlay>
      <DialogComponent
        ref="railroadEstimatesDocumentsDialogRef"
      ></DialogComponent>
    </v-card-text>
  </v-card>
</template>
  <script>
import GradeCrossingDocuments from "@/modules/dot-details/scripts/GradeCrossingDocuments.js";
export default GradeCrossingDocuments;
</script>
  <style>
@import "@/modules/projectinformation/assets/projectinfostyle.scss";
@import "@/assets/css/style.scss";
</style>